import {
    dangerColor
} from "../../material-kit-pro-react.jsx";

import paymentMethodPageStyle from "../../payment/paymentMethodPageStyle";
const dashboardTabAccountPaymentsStyle = theme => ({
    ...paymentMethodPageStyle(theme),
    main: {
        padding: "0px",
        [theme.breakpoints.up("xs")]: {
            paddingLeft: "2px"
        }
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
    },
    formControl: {
        marginBottom: "5px"
    },
    changeButton: {
        alignSelf: "flex-end",
        paddingLeft: "0px",
        "& .MuiButton-root": {
            marginBottom: "0px",
            maxWidth: "calc(100% - 2px)"
        },
        "& .info_tooltip_icon": {
            position: "absolute",
            top: "50px",
            left: "47px",
            marginLeft: "0px"
        }
    },
    saveButton: {
        alignSelf: "flex-start",
        marginTop: "20px",
    },
    gridContainer: {
        marginLeft: "0px",
        marginRight: "0px",
        "& .MuiGrid-item:first-child": {
            paddingLeft: "0px",
            paddingRight: "8px"
        },
        "& .MuiGrid-item:last-child": {
            paddingRight: "0px"
        }
    },
    cardMargin: {
        marginBottom: "5px"
    },
    removeButton: {
        float: "right"
    },
    hidden: {
        display: "none"
    },
    monthlyContainer: {
        marginTop: theme.spacing(2),
        "& .MuiGrid-item": {
            alignSelf: "center",
        },
        "& button": {
            marginTop: "0px"
        }
    },
    billingLink: {
        display: "block",
        textAlign: "left"
    },
    fixCard: {
        color: dangerColor[0]
    }
});

export default dashboardTabAccountPaymentsStyle;