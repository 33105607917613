import React from 'react';
import PropTypes from "prop-types";
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from '../../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
//Tooltip modals
import ManageSearchPageTooltipModal from "../../../components/ToolTips/Dashboard/ManageSearchPage/ManageSearchPageTooltipModal";
import ViewTypeTooltipModal from "../../../components/ToolTips/Dashboard/ManageSearchPage/ViewTypeTooltipModal";
import { ReactComponent as FeedViewIcon } from "../../../../assets/icons/feed_view-2.svg";
import { ReactComponent as ListViewIcon } from "../../../../assets/icons/list_view-1.svg";
import instagramIcon from "../../../../assets/img/instagram-icon.png";
import youtubeIcon from "../../../../assets/img/youtube-icon.png";

import MaterialIcon from "@mdi/react";
import { mdiViewGrid, mdiGrid, mdiInformationOutline, mdiViewDashboard, mdiGestureTapButton, mdiLink } from '@mdi/js';

import dashboardTabProfileSearchStyle from "../../../../assets/jss/dashboard/profile/dashboardTabProfileSearchStyle.js";
import { Grid } from '@material-ui/core';

const DashboardTabProfileSearch = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        let { user } = this.store.getState();
        let viewType = "list";
        let showButtons = false;
        let contentPlatforms = [];
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("defaultLayout")){
            viewType = user.guestPreferences.defaultLayout;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("contentPlatforms")){
            contentPlatforms = user.guestPreferences.contentPlatforms;
        }

        this.state = {
            platform: this.props.platform,
            cancelToken: null,
            viewType: viewType,
            showButtons: showButtons,
            contentPlatforms: contentPlatforms,
            loading: false,
            saving: false,
            tooltips: {
                manageSearchPage: false,
                viewType: false,
            },
            paymentData: this.props.paymentData,
            subscription: this.props.subscription,
            platforms: this.props.platforms,
            editSearch: false
        };

        this.handleViewOptions = this.handleViewOptions.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription,
            platforms: props.platforms
        })
    }
    handleViewOptions(viewType){
        this.setState({
            viewType: viewType
        });
    }
    handleShowButtons(){
        const showButtons = !this.state.showButtons;
        this.setState({
            showButtons: showButtons,
        });
    }
    handlePlatformOptions(userPlatform){
        let { contentPlatforms } = this.state;
        let index = contentPlatforms.indexOf(userPlatform.contentPlatformId);
        if(index === -1){
            contentPlatforms.push(userPlatform.contentPlatformId);
        }else{
            contentPlatforms.splice(index, 1);
        }
        this.setState({
            contentPlatforms: contentPlatforms
        });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    getViewTypeLabel(){
        const { viewType } = this.state;
        let label = "";
        switch(viewType){
            case "stream":
                label = "Stream";
                break;
            case "list":
                label = "List View";
                break;
            case "grid":
                label = "Large Grid";
                break;
            case "threecolumngrid":
                label = "Small Grid";
                break;
            case "card":
                label = "Cards";
                break;
            default:
                break;
        }
        return label;
    }
    saveChanges(){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        const { showButtons, viewType, contentPlatforms } = this.state;
        this.setState({
            cancelToken: cancelToken,
            saving: true
        });

        if(authorized){
            user.guestPreferences.showButtons = showButtons;
            user.guestPreferences.defaultLayout = viewType;
            user.userPreferences.searchViewResults = viewType;
            user.guestPreferences.contentPlatforms = contentPlatforms;
            if(user.userPreferences.hasOwnProperty("onboarding")){
                user.userPreferences.onboarding.step6Complete = true;
            }
            Api.updateUser(user.userId, user, cancelToken).then((data) => {
                if(data.numberOfResponses > 0){
                    this.setState({saving: false, editSearch: false});
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }
    }
    cancelChanges(){
        const { user } = this.store.getState();
        let viewType = "list";
        let showButtons = false;
        let contentPlatforms = [];
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("defaultLayout")){
            viewType = user.guestPreferences.defaultLayout;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("contentPlatforms")){
            contentPlatforms = user.guestPreferences.contentPlatforms;
        }
        this.setState({
            viewType: viewType,
            showButtons: showButtons,
            contentPlatforms: contentPlatforms,
            editSearch: false
        });
    }
    getContentPlatformsLabel(){
        const { contentPlatforms, platforms } = this.state;
        if(contentPlatforms.length <= 0){
            return "Don't show any content feed.";
        }
        let labelArray = [];
        platforms.map((userPlatform) => {
            if(contentPlatforms.indexOf(userPlatform.contentPlatformId) !== -1){
                switch(userPlatform.type){
                    case "instagramAPI":
                        labelArray.push("Instagram Posts");
                        break;
                    case "instagramDemo":
                        labelArray.push("Instagram Demo");
                        break;
                    case "youtubeAPI":
                        labelArray.push("YouTube Videos");
                        break;
                    case "webLink":
                        labelArray.push("Web links");
                        break;
                    default:
                        break;
                }
            }
            return null;
        });
        return ("Show: "+labelArray.join(", "));
    }
    editSearch(status = true){
        this.setState({editSearch: status});
    }
    render() {
        const { classes } = this.props;
        const { viewType, tooltips, showButtons, saving, contentPlatforms, editSearch, platforms } = this.state;
        const { user, authorized } = this.store.getState();
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        if(this.props.isActiveTab !== true){
            return <></>;
        }
        return (
            <div className={classes.mainContainer}>
                <h4 className={classes.title}>
                    Setup Your Home Page
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className={" MuiSvgIcon-root info_tooltip_icon"} onClick={() => this.onTooltip('manageSearchPage', true)} />
                        :
                        <></>
                    }
                </h4>
                {
                    editSearch ?
                        <GridContainer justify="space-between" className={classes.buttonsMargin+" "+classes.buttons}>
                            <Button color="white" onClick={() => this.cancelChanges()}>
                                <ArrowBack />
                                Cancel
                            </Button>
                            <Button color="success" onClick={() => this.saveChanges()}>
                                {
                                    saving === false ?
                                        <>
                                            <SaveIcon /> Save
                                        </>
                                    :
                                    <LoaderComponent align="center" saving={true} color="white" />
                                }
                            </Button>
                        </GridContainer>
                    :
                        <GridContainer justify="flex-end" className={classes.buttonsMargin+" "+classes.buttons}>
                            <Button color="blue" onClick={() => this.editSearch()}>
                                Edit
                            </Button>
                        </GridContainer>
                }
                <p className={classes.mobileCenter}>Choose the default layout that will be seen by anonymous guests.</p>
                <Grid className={classes.grid}>
                    <GridItem className={classes.guestViewOptions}>
                        <Button disabled={editSearch === false} className={(viewType === "stream"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("stream")}>
                            <FeedViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                        </Button>
                        <Button disabled={editSearch === false} className={(viewType === "list"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("list")}>
                            <ListViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                        </Button>
                        <Button disabled={editSearch === false} className={(viewType === "grid"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("grid")}>
                            <MaterialIcon path={mdiViewGrid} className="MuiSvgIcon-root" />
                        </Button>
                        <Button disabled={editSearch === false} className={(viewType === "threecolumngrid"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("threecolumngrid")}>
                            <MaterialIcon path={mdiGrid} className="MuiSvgIcon-root" />
                        </Button>
                        {
                            showBetaFeatures ?
                                <Button className={(viewType === "card"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("card")}>
                                    <MaterialIcon path={mdiViewDashboard} className="MuiSvgIcon-root" />
                                </Button>
                            :
                            <></>
                        }
                    </GridItem>
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className={classes.featuredContentTooltip + " MuiSvgIcon-root info_tooltip_icon"} onClick={() => this.onTooltip('viewType', true)} />
                        :
                        <></>
                    }
                    <p className={classes.viewTypeLabel}>{this.getViewTypeLabel()}</p>
                </Grid>
                <p className={classes.mobileCenter}>Do you want to show buttons?</p>
                <Grid className={classes.grid}>
                    <GridItem className={classes.showButtonOption}>
                        <Button disabled={editSearch === false} className={(showButtons === true?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleShowButtons()}>
                            <MaterialIcon path={mdiGestureTapButton} className={classes.customIcon + " MuiSvgIcon-root"}/>
                        </Button>
                    </GridItem>
                    <p className={classes.viewTypeLabel}>
                        {
                            showButtons ?
                                "Show Buttons"
                            :
                                "Do Not Show Buttons"
                        }
                    </p>
                </Grid>
                {
                    platforms.length > 0 ?
                        <>
                            <p className={classes.mobileCenter}>Choose What Content Platforms to show on your home page</p>
                            <Grid className={classes.grid}>
                                <GridItem className={classes.platformOptions}>
                                    {
                                        platforms.map((userPlatform) => {
                                            if(userPlatform.type !== "webLink" && userPlatform.type !== "instagramAPI" && userPlatform.type !== "instagramDemo" && userPlatform.type !== "youtubeAPI"){
                                                return null;
                                            }
                                            return (
                                                <Button disabled={editSearch === false} key={userPlatform.contentPlatformId} className={(contentPlatforms.indexOf(userPlatform.contentPlatformId) !== -1?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handlePlatformOptions(userPlatform)}>
                                                    {
                                                        (userPlatform.type === "instagramAPI" || userPlatform.type === "instagramDemo") ?
                                                            <img src={instagramIcon} className="instagram-icon" alt="instagram"/>
                                                        : (userPlatform.type === "webLink") ?
                                                            <MaterialIcon path={mdiLink} className={classes.customIcon+" MuiSvgIcon-root"} />
                                                        : (userPlatform.type === "youtubeAPI") ?
                                                            <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                                        :
                                                        <></>
                                                    }
                                                </Button>
                                            )
                                        })
                                    }
                                </GridItem>
                                <p className={classes.viewTypeLabel}>{this.getContentPlatformsLabel()}</p>
                            </Grid>
                        </>
                    :
                    <></>
                }
                <Grid className={classes.grid}>
                    <p className={classes.mobileCenter}>The Buttons and Content Feed will be shown on the home page until a search is performed.  All content is ordered by Publish Date.</p>
                </Grid>
                {
                    editSearch ?
                        <GridContainer justify="space-between" className={classes.buttons}>
                            <Button color="white" onClick={() => this.cancelChanges()}>
                                <ArrowBack />
                                Cancel
                            </Button>
                            <Button color="success" onClick={() => this.saveChanges()}>
                                {
                                    saving === false ?
                                        <>
                                            <SaveIcon /> Save
                                        </>
                                    :
                                    <LoaderComponent align="center" saving={true} color="white" />
                                }
                            </Button>
                        </GridContainer>
                    :
                        <GridContainer justify="flex-end" className={classes.buttons}>
                            <Button color="blue" onClick={() => this.editSearch()}>
                                Edit
                            </Button>
                        </GridContainer>
                }
                {
                    tooltips.viewType ?
                        <ViewTypeTooltipModal open={tooltips.viewType} store={this.store} onClose={() => this.onTooltip('viewType', false)} />
                    :
                    <></>
                }
                {
                    tooltips.manageSearchPage ?
                        <ManageSearchPageTooltipModal open={tooltips.manageSearchPage} store={this.store} onClose={() => this.onTooltip('manageSearchPage', false)} />
                    :
                    <></>
                }
            </div>
        )
    }
}

DashboardTabProfileSearch.defaultProps = {
    featuredResponse: [],
    response: [],
    featuredContentLoaded: false,
    contentLoaded: false,
    testMode: false,
    platforms: []
};

DashboardTabProfileSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    featuredResponse: PropTypes.array,
    response: PropTypes.array,
    featuredContentLoaded: PropTypes.bool,
    contentLoaded: PropTypes.bool,
    testMode: PropTypes.bool,
    platforms: PropTypes.array
};

export default withStyles(dashboardTabProfileSearchStyle)(DashboardTabProfileSearch);
