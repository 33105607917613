import Config from '../../../../Config'
import axios from 'axios'
import queryString from 'querystring'
import Cookie from './Cookie'
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

const MyAuthStorage =  {
    getKey(key){
        const keyArray = key.split(".");
        if(keyArray.length <= 1){
            return key;
        }
        key = keyArray.splice(0, (keyArray.length - 1));
        return keyArray[0];
    },
    getKeys(){
        return ['idToken', 'accessToken', 'refreshToken', 'clockDrift', 'LastAuthUser'];
    },
    setItem(key, value){
        key = this.getKey(key);
        Cookie.write(key, value);
    },
    getItem(key){
        key = this.getKey(key);
        let value = Cookie.read(key);
        if(!value || value === "null"){
            value = null;
        }
        return value;
    },
    removeItem(key){
        key = this.getKey(key);
        Cookie.dispose(key);
    },
    clear(){
        this.getKeys().map((key) => {
            Cookie.dispose(key);
            return null;
        });
    }
};

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: Config.getIdentityPoolId(), 
        // REQUIRED - Amazon Cognito Region
        region: Config.getRegion(), 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: Config.getUserPoolId(),
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: Config.getNativeClientId(),
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        storage: MyAuthStorage
    },
    federationTarget: 'COGNITO_USER_POOLS'
});

export default {
    validateCode(code, redirectUri){
        return new Promise(function(resolve, reject){
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': Config.getXApiKey(),    
            };
            const ApiUrl = Config.getApiUrl()+"identity/auth/code";
            const requestData = {
                'code': code,
                'redirect_uri': redirectUri,
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers
            }).then(res => {
                resolve(res.data.response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    refreshToken(refreshToken){
        return new Promise(function(resolve, reject){
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': Config.getXApiKey(),    
            };
            const ApiUrl = Config.getApiUrl()+"identity/auth/refresh";
            const requestData = {
                'refresh_token': refreshToken,
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers
            }).then(res => {
                resolve(res.data.response);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateTokens(data, federated = null){
        if(data.hasOwnProperty("access_token")){
            const newData = {
                accessToken: data.access_token,
                expires_in: data.expires_in,
                idToken: data.id_token,
            };
            if(data.hasOwnProperty("refresh_token")){
                newData['refreshToken'] = data.refresh_token;
            }
            data = newData;
        }
        Cookie.write("accessToken",data.accessToken);
        Cookie.write("idToken",data.idToken);
        if(data.hasOwnProperty("refreshToken")){
            Cookie.write("refreshToken",data.refreshToken);
            if(federated !== null){
                Cookie.write("federated",1);
            }
        }

        let expire = new Date();
        expire.setSeconds(expire.getSeconds()+(data.expires_in));
        Cookie.write("expires_in",expire.getTime());
    },
    hasAccessToken: function(){
        if(Cookie.read("accessToken") && Cookie.read("accessToken").length > 0){
            return true;
        }
        return false;
    },
    getCookieData(){
        const hostname = window.location.hostname;
        const cookie = {
            "identifiers": {}
        };
        cookie.identifiers[hostname] = {
            "cookies": Cookie.getAll()
        };
        return encodeURIComponent(JSON.stringify(cookie));
    },
    getUserInfo(onValidateSuccess,onValidateFail, defaultAudience = false){
        let audience = Config.getNativeClientId();
        const federated = Cookie.read("federated");
        if(federated && parseInt(federated) === 1){
            audience = Config.getClientId();
        }
        const that = this;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': Cookie.read("idToken"),
            'x-api-key': Config.getXApiKey(),
            'Auth_Audience': audience,
            'Access-Control-Allow-Origin': Config.getRedirectUri(),
            'GG_Ids': this.getCookieData()
        };
        if(localStorage.getItem("auth_identity") !== null){
            headers['Auth_Identity'] = localStorage.getItem("auth_identity");
        }
        axios({
            url: Config.getUserInfoUrl()+"?buttons=true&contentPlatforms=true",
            method: "GET",
            headers: headers
        }).then(res => {
            onValidateSuccess(res.data);
        }).catch((error, config, code, request, response) => {
            if(error.message === "Token is expired"){
                that.refreshToken(Cookie.read("refreshToken")).then( data => {
                    that.updateTokens(data);
                    that.getUserInfo(onValidateSuccess,onValidateFail);
                }).catch(err => {
                    onValidateFail(error, config, code, request, response);
                });
            }else{
                onValidateFail(error, config, code, request, response);
            }            
        });
    },
    hasUserEmail: function(){
        if(Cookie.read("user_email") && Cookie.read("user_email").length > 0){
            return true;
        }
        return false;
    },
    getUserEmail(){
        if(Cookie.read("user_email") && Cookie.read("user_email").length > 0){
            return Cookie.read("user_email");
        }
        return "";
    },
    tokenNeedsRefresh(){
        let expire = new Date(parseInt(Cookie.read("expires_in")));
        const currentDate = new Date();
        if(currentDate >= expire){
            return true;
        }
        return false;
    },
    isLoggedIn(){
        try{
            if(/*!this.hasUserEmail() &&*/ !this.hasAccessToken()){
                return false;
            }
            //const that = this;
            let isValid = false;
            if(this.hasAccessToken()){
                //Refresh token will happen later in app flow
                // if(this.tokenNeedsRefresh()){
                //     this.refreshToken(Cookie.read("refreshToken"),function(data){
                //         Cookie.write("accessToken",data.accessToken);
                //         Cookie.write("idToken",data.idToken);
                //         Cookie.write("refreshToken",data.refreshToken);

                //         let expire = new Date();
                //         expire.setSeconds(expire.getSeconds()+data.expires_in);
                //         Cookie.write("expires_in",expire.getTime());
                //         isValid = true;
                //     },function(err){
                //         that.logout(); 
                //         return false;
                //     });
                // }
            }

            if(this.hasUserEmail() || this.hasAccessToken()){
                return true;
            }

            return isValid;
        }catch(e){
            this.logout(); 
            return false; 
        }
    },
    validateToken(){
        const federated = Cookie.read("federated");
        if(!federated || parseInt(federated) === 0){
            return this.validateNativeToken();
        }
        const that = this;
        return new Promise(function(resolve, reject){
            try{
                if(!that.hasAccessToken()){
                    return false;
                }
                if(that.tokenNeedsRefresh()){
                    that.refreshToken(Cookie.read("refreshToken")).then( data => {
                        that.updateTokens(data, true);
    
                        that.getUserInfo(function(data){
                            if(that.isValidUserData(data,false)){
                                return resolve(data);
                            }
                            return resolve(false);
                        },function(err){
                            if(err.message !== "Network Error" && err.message !== "Request aborted"){
                                return resolve(false);
                            }
                        });
                    }).catch(err => {
                        if(err.message !== "Network Error" && err.message !== "Request aborted"){
                            return resolve(false);
                        }
                    });
                }else{
                    that.getUserInfo(function(data){
                        if(that.isValidUserData(data,false)){
                            return resolve(data);
                        }
                        return resolve(false);
                    },function(err){
                        if(err.message !== "Network Error" && err.message !== "Request aborted"){
                            return resolve(false);
                        }
                    });
                }
            }catch(err){
                console.log(err);
                if(err.message !== "Network Error" && err.message !== "Request aborted"){
                    return resolve(false);
                }
            }
        });        
    },
    validateNativeToken(){
        const that = this;
        return new Promise(function(resolve, reject){
            try{
                if(!that.hasAccessToken()){
                    return resolve(false);
                }
                Auth.currentSession().then(currentUserSession => {
                    Cookie.write("accessToken",currentUserSession.getAccessToken().getJwtToken());
                    Cookie.write("idToken",currentUserSession.getIdToken().getJwtToken());
                    Cookie.write("refreshToken",currentUserSession.getRefreshToken().getToken());
                    Cookie.write("expires_in",(currentUserSession.getIdToken().getExpiration()*1000));

                    that.getUserInfo(function(data){
                        if(that.isValidUserData(data,false)){
                            return resolve(data);
                        }
                        return resolve(false);
                    },function(err){
                        if(err.message !== "Network Error" && err.message !== "Request aborted"){
                            return resolve(false);
                        }
                    });
                }).catch(err => {
                    return resolve(false);
                });
            }catch(err){
                if(err.message !== "Network Error" && err.message !== "Request aborted"){
                    return resolve(false);
                }
            }
        });        
    },
    isValidUserData(data,saveUserEmail = false){
        try{
            let isValid = false;
            data.response.map(object => {
                if(object.userId !== "anonymous"){
                    isValid = true;
                }
                if(saveUserEmail){
                    Cookie.write("user_email",object.userEmail);
                }
                return isValid;
            });
            return isValid;
        }catch(e){return false;}
    },
    logout: function(component = null){
        Cookie.dispose("accessToken");
        Cookie.dispose("idToken");
        Cookie.dispose("refreshToken");
        Cookie.dispose("user_email");
        Cookie.dispose("federated");
        Cookie.dispose("expires_in");
        Cookie.dispose("user_content_id");
        localStorage.removeItem("member_info");
        localStorage.removeItem("auth_identity");
        localStorage.removeItem("auth_username");
        
        // Auth.signOut().then(data => {
        //     if(component && component.hasMounted()){
        //         component.logout();
        //     }
        // }).catch(err => {
        //     console.log(err);
        // });
    },
    registerPGUser(data,onValidateSuccess,onValidateFail){
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'oauth_consumer_key': Config.getPGConsumerKey(),
            'oauth_consumer_secret': Config.getPGConsumerSecret(),
            'Access-Control-Allow-Origin': document.location.hostname
        };
        axios({
            url: Config.getPGApiUrl()+"member",
            method: "POST",
            data: queryString.stringify(data),
            headers: headers
        }).then(res => {
            onValidateSuccess(res.data);
        }).catch(err => {
            onValidateFail(err);
        });
    },
    signIn: function(email,password,component){
        const that = this;
        Auth.signIn(email, password).then(user => {
            const currentUserSession = user.getSignInUserSession();
            const authData = {
                "accessToken": currentUserSession.getAccessToken().getJwtToken(),
                "idToken": currentUserSession.getIdToken().getJwtToken(),
                "refreshToken": currentUserSession.getRefreshToken().getToken(),
                "expires_in": (currentUserSession.getIdToken().getExpiration()*1000)
            };

            const currentDate = new Date();
            const expireDate = new Date(authData.expires_in);            
            authData.expires_in = (expireDate.getTime() - currentDate.getTime())/1000;
            that.updateTokens(authData);

            that.getUserInfo(function(data){
                component.onValidateSuccess(data);
            },function(err){
                component.onValidateFail(err);
            });            
        }).catch(err => {
            component.onValidateFail(err);
        });
    },
    signUp: function(email, password, component){
        Auth.signUp({
            username: email,
            password: password,
            attributes: { email: email},
            validationData: []
        })
        .then(data => {
            component.onValidateSuccess(data);
        })
        .catch(err => {
            component.onValidateFail(err);
        });        
        
    },
    confirmSignUp: function(email, code, component){
        Auth.confirmSignUp(email, code).then(data => {
            component.onValidateConfirmSuccess(data);
        }).catch(err => {
            component.onValidateFail(err);
        });
    },
    resendSignUp: function(email,component){
        Auth.resendSignUp(email).then(() => {
            if(typeof(component.onResendSignUpSuccess) === "function"){
                component.onResendSignUpSuccess();
            }
        }).catch(e => {
            if(typeof(component.onResendSignUpFail) === "function"){
                component.onResendSignUpFail(e);
            }
            console.log(e);
        });
    },
    idleRefreshToken(){
        if(!this.isLoggedIn()){
            return;
        }
        if(typeof(window.idleRefreshTokenTimer) === "number"){
            clearInterval(window.idleRefreshTokenTimer);
        }
        const intervalTime = (new Date(parseInt(Cookie.read("expires_in"))) - new Date());
        if(intervalTime <= 0){
            return;
        }
        const that = this;
        window.idleRefreshTokenTimer = setInterval(function(){
            that.validateToken().then((isValidToken) => {
                if(isValidToken !== false){
                    that.idleRefreshToken();
                }
            });
        },intervalTime);
    },
    deleteUser(){
        return new Promise(function(resolve, reject){
            Auth.currentAuthenticatedUser().then(CognitoUser => {
                CognitoUser.deleteUser(data => {
                    resolve(data);
                });
            }).catch(err => {
                return reject(err);
            });
        });
    },
    refreshUserTokens(){
        const that = this;
        if(!that.hasAccessToken()){
            return false;
        }
        return new Promise(function(resolve, reject){
            try{
                const federated = Cookie.read("federated");
                if(!federated || parseInt(federated) === 0){
                    Auth.currentAuthenticatedUser().then(user => {
                        const refreshToken = user.signInUserSession.refreshToken;
                        user.refreshSession(refreshToken, function(error, currentUserSession){
                            Cookie.write("accessToken",currentUserSession.getAccessToken().getJwtToken());
                            Cookie.write("idToken",currentUserSession.getIdToken().getJwtToken());
                            Cookie.write("refreshToken",currentUserSession.getRefreshToken().getToken());
                            Cookie.write("expires_in",(currentUserSession.getIdToken().getExpiration()*1000));
                            return resolve(true);
                        })                        
                    }).catch(err => {
                        return resolve(false);
                    });
                }else{
                    that.refreshToken(Cookie.read("refreshToken")).then( data => {
                        that.updateTokens(data, true);
                        return resolve(true);
                    }).catch(err => {
                        if(err.message !== "Network Error" && err.message !== "Request aborted"){
                            return resolve(false);
                        }
                    });
                }
            }catch(err){
                if(err.message !== "Network Error" && err.message !== "Request aborted"){
                    return resolve(false);
                }
            }
        });        
    },
};