import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import axios from 'axios';
import { helper } from '../../../../assets/js/utils/Element';
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from "../../Loader";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridItem from "../../Grid/GridItem.jsx";
import CustomInput from "../../CustomInput/CustomInput.jsx";
import Switch from "@material-ui/core/Switch";
import { ReactComponent as LinktreeIcon } from "../../../../assets/icons/linktree-platform-icon.svg";
import linktreeWizardModalStyle from "../../../../assets/jss/dashboard/editmode/linktreeWizardModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LinktreeWizardModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const authCode = helper.getParam("code");
        let userStep = (authCode && authCode !== "null");
        this.state = {
            imageUrl: null,
            platform: null,
            loading: false,
            cancelToken: null,
            userStep: userStep,
            advancedOptions: false,
            saving: false,

            username: "",
            usernameState: "",
            linktreeButtons: true
        };
    }
    createPlatform(){
        const { cancelToken, linktreeButtons, username } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        var requestData = {
            type: "linktree",
            linktreeUsername: username.replace(/\s/g, ""),
            linktreeButtons: linktreeButtons
        };
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source});
        Api.createPlatform(requestData, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                this.updateUserOnboarding(data.response[0]);
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({saving: false});
            }
        });
    }
    checkUserName(username){
        this.setState({loading: true});
        var url = "https://linktr.ee/"+username;
        helper.scrapeUrl(url).then(data => {
            let state = {
                loading: false
            }
            if(data.hasOwnProperty("imageUrl")){
                state['imageUrl'] = data.imageUrl;
            }
            this.setState(state);
        }).catch(err => {
            this.setState({loading: false});
        });
    }
    handleToggle(name) {
        this.setState({[name]: !this.state[name]});
    }
    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        var regex = /[@/]/g;
        // var regex = /[!$%^&*()+|~=`;'"{}[:;<>?,@#\]]/g;
        if(regex.test(event.target.value)){
            this.setState({ [stateName]: this.state[stateName] });
            return;
        }
        let state = { [stateName]: event.target.value };
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    state[stateName + "State"] = "success";
                } else {
                    state[stateName + "State"] = "error";
                }
                break;
            default:
                break;
        }
        this.setState(state);
        // const errorStateName = (stateName + "State");
        // if(stateName === "username" && state.hasOwnProperty("usernameState") && state[errorStateName] === "success"){
        //     this.checkUserName(event.target.value);
        // }
    }
    updateUserOnboarding(platform){
        const { user } = this.props;
        if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
            user.guestPreferences.contentPlatforms = [];
        }
        if(!user.hasOwnProperty("contentPlatforms")){
            user.contentPlatforms = [];
        }
        user.guestPreferences.contentPlatforms.push(platform.contentPlatformId);
        user.contentPlatforms.push(platform);
        setTimeout(() => {
            this.props.onSuccess(user);
        }, 5000);
    }
    render() {
        const { classes } = this.props;
        const { linktreeButtons, saving } = this.state;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="linktreeWizard-slide-title"
                aria-describedby="linktreeWizard-slide-description"
            >
                <DialogTitle
                    id="linktreeWizard-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                        disabled={saving}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent id="linktreeWizard-slide-description" className={classes.modalBody}>
                    <LinktreeIcon className={classes.linktreeIcon} />
                    <h4 className={classes.modalTitle}>What is your Linktree Username?</h4>
                    <CustomInput
                        id="outlined-linktreeUsername"
                        success={this.state.usernameState === "success"}
                        error={this.state.usernameState === "error"}
                        labelText="Linktr.ee Username (do not enter the @)"
                        inputProps={{
                            value: this.state.username,
                            onChange: event => this.change(event, "username", "length", 3),
                            name: "linktreeUsername",
                        }}                                    
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl
                        }}
                    />
                    <p className={classes.socialLinkPreview}>
                        {"https://linktr.ee/"+this.state.username}
                    </p>
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.optionsGridItem}>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={linktreeButtons}
                                    onChange={ (e) => this.handleToggle("linktreeButtons")}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label={(linktreeButtons ? "Create linktr.ee links as buttons" : "Create linktr.ee links as web links")}
                            />
                        </div>
                    </GridItem>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    <Button onClick={() => this.props.onClose()} color="white">Nevermind</Button>
                    <Button className={classes.saveButton} onClick={() => this.createPlatform()} color="blue">
                        {
                            saving ?
                                <LoaderComponent align="center" />
                            :
                                "Import these links"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

LinktreeWizardModal.defaultProps = {
    open: false
};
LinktreeWizardModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool
};
export default withStyles(linktreeWizardModalStyle)(LinktreeWizardModal);
