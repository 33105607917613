import React from 'react';
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import adButtonModalStyle from "../../../../assets/jss/dashboard/editmode/adButtonModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AdButtonModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="adButtonModal-modal-slide-title"
                aria-describedby="adButtonModal-modal-slide-description"
            >
                <DialogTitle
                    id="adButtonModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>What is this button?</h4>                            
                </DialogTitle>
                <DialogContent
                    id="adButtonModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        <h3 className={classes.title}>We may insert our own button on free accounts</h3>
                        <p>In order to offer this application free to you and your audience, we will insert a few small ad buttons or search results.</p>
                        <p>The ads will always be simple and clean, but allow us to continue to offer this service to you completely FREE!</p>
                        <p>However, if you don't want to have ads displayed on your page, you can upgrade <Link to={"/dashboard/account/upgrade?return="+window.location.pathname+encodeURIComponent(window.location.search)}>here.</Link></p>                 
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <div>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="info"
                            round
                        >
                            Sounds Good
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

AdButtonModal.defaultProps = {
    open: false
}
AdButtonModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(adButtonModalStyle)(AdButtonModal);