import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../../../../components/Loader";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NoticeCheckNewPosts = class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            secondsWait: true
        }
        this.store = this.props.store;        
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.setState({ 
                secondsWait: false
            });
        },3500);
    }
    render() {
        const { classes } = this.props;
        const { secondsWait } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="youtubeLink-modal-slide-title"
                aria-describedby="youtubeLink-modal-slide-description"
            >
                {
                    secondsWait === false ?
                        <DialogTitle
                            id="youtubeLink-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                            >
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>We are on it! Give us a few minutes to look for new YouTube videos.</h4> 
                        </DialogTitle>
                    :
                    <></>
                }
                {
                    secondsWait ?
                        <DialogContent
                            id="youtubeLink-modal-slide-description"
                            className={classes.modalBody}
                            >
                                <LoaderComponent align="center" color="success" />
                        </DialogContent>
                    :
                    <></>
                }
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        secondsWait === false ?
                            <Button
                                onClick={() => this.props.onClose()}
                                color="info"
                                round
                            >
                                OK
                            </Button>
                        :
                        <></>
                    }
                    
                </DialogActions>
            </Dialog>
        )
    }
}

NoticeCheckNewPosts.defaultProps = {
    open: false
}
NoticeCheckNewPosts.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(instagramNoticeStyle)(NoticeCheckNewPosts);