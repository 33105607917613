/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import MaterialIcon from "@mdi/react";
import { mdiLoading } from '@mdi/js';

import defaultImage from "../../../assets/img/default-avatar.png";

class PictureUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: this.props.file,
            imagePreviewUrl: defaultImage
        };
    }
    componentDidMount(){
        if(this.state.file !== null){
          if(typeof(this.state.file) === "string"){
              this.setState({
                  imagePreviewUrl: this.state.file
              });
              return;
          }
          let reader = new FileReader();
          reader.onloadend = () => {
              this.setState({
                imagePreviewUrl: reader.result
              });
          };
          reader.readAsDataURL(this.state.file);
        }
    }
    componentWillReceiveProps(props){
        if(props.file === null){
            this.setState({
                file: null,
                imagePreviewUrl: defaultImage
            });
            return;
        }
        if(typeof(props.file) === "string"){
            this.setState({
                file: props.file,
                imagePreviewUrl: props.file
            });
            return;
        }
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
              file: props.file,
              imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(props.file);
    }
    handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if(this.props.onChange !== null){
            this.props.onChange({
                file: file,
            });
            return;
        }
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    };
    handleSubmit = e => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    render() {
        const { showLoading, label, error, success } = this.props;
        return (
            <div className={"picture-container "+(error?"picture-container-error":'')+(success?"picture-container-success":'')}>
                <div className={"picture "+(showLoading ? 'picture_loading':'')}>
                    <img
                        src={this.state.imagePreviewUrl}
                        className="picture-src"
                        alt="..."
                    />
                    <input type="file" onChange={e => this.handleImageChange(e)} />
                    {
                        showLoading ?
                            <MaterialIcon path={mdiLoading} className="MuiSvgIcon-root" />
                        :
                            <></>
                    }
                </div>
                <h6 className="description">{label}</h6>
            </div>
        );
    }
}

PictureUpload.defaultProps = {
    onChange: null,
    file: null,
    showLoading: false,
    label: "Choose Picture",
    error: false,
    success: false
};
PictureUpload.propTypes = {
    onChange: PropTypes.func,
    showLoading: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool
};

export default PictureUpload;
