import React from 'react';
import PropTypes from "prop-types";
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import AnalyticsTabBasic from "../analytics/Basic";
import NavPills from "../../../components/NavPills/NavPills.jsx";

import MaterialIcon from "@mdi/react";
import { mdiChartAreaspline } from '@mdi/js';

import alalyticsTabProfileStyle from "../../../../assets/jss/dashboard/tabs/alalyticsTabProfileStyle.js";
const history = createBrowserHistory();

const AnalyticsTabProfile = class extends React.Component {
    constructor(props){
        super(props);
       
        this.store = this.props.store;
        this.history = this.props.history;
        this.navPills = React.createRef();
        let subTab = this.props.params.subTab;
        if(subTab > 3){
            subTab = 0;
        }
        this.state = {
            defaultTab: subTab,
            match: this.props.match,
        };

        this.onTabChange = this.onTabChange.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/analytics/basic");
                break;
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    render() {
        const { classes, subscription, paymentData } = this.props;
        const { defaultTab } = this.state;
        // const { user, authorized } = this.store.getState();
        
        if(this.props.isActiveTab !== true){
            return <></>;
        }

        // var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    alignCenter
                    color="primary"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    disableSwipeable={true}
                    tabs={[
                        {
                            tabButton: "",
                            tabImageIcon: <MaterialIcon path={mdiChartAreaspline} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <AnalyticsTabBasic isActiveTab={(defaultTab === 0)} subscription={subscription} paymentData={paymentData} store={this.store} history={this.history} />
                            ),
                            className: "tab-basic",
                        },
                    ]}
                />
            </div>
        )
    }
}

AnalyticsTabProfile.defaultProps = {
    subscription: null,
    paymentData: null
}
AnalyticsTabProfile.propTypes = {
    subscription: PropTypes.object,
    paymentData: PropTypes.object.isRequired
}

export default withStyles(alalyticsTabProfileStyle)(AnalyticsTabProfile);
