import {
    dangerColor,
} from "../../material-kit-pro-react.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";
  
const processingModalStyle = theme => ({
    ...modalStyle(theme),
    modalCustom: {
        maxWidth: "300px",
        borderRadius: "50px"
    },
    loading: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .sd_loader": {
            height: "30px",
            width: "30px"
        },
        "& p": {
            marginBottom: "0px",
            marginLeft: "10px",
            textAlign: "center"
        }
    },
    error: {
        color: dangerColor[0],
        "& svg": {
            height: "30px",
            width: "30px"
        }
    }
});
  
export default processingModalStyle;
  