import React from 'react'
import {Helmet} from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"
import Link from "@material-ui/core/Link";

import PrivacyStyle from "../../../assets/jss/help/privacyStyle";

const Privacy = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container+" "+classes.main}>
                <h5 className={classes.textCenter}>PRIVACY POLICY</h5>
                <p>CloudPremise,LLC d/b/a Smearch on behalf of its affiliates and subsidiaries (collectively, “<b>CloudPremise</b>,” “<b>us</b>,” “<b>we</b>,” or “<b>our</b>”) has prepared this Privacy Policy (“<b>PrivacyPolicy</b>”) to describe to you, or if you represent an entity or other organization, that entity or organization (in either case, “<b>you</b>,” or “<b>your</b>”) our online and offline practices regarding the personal information that CloudPremise collects, uses, and shares on behalf of end users (“<b>Users</b>”) of our online platform throughwhich users can catalog and manage their social media posts and view and interact with the social media content of others (the “<b>Platform</b>”) accessed through the website located at https://searchmysocial.media, and any other websites operated by CloudPremise (each, a “<b>Site</b>”) or using mobile applications provided by and on behalf of CloudPremise (each, an “<b>Application</b>,” and each Site and Application treated as a part of the “<b>Platform</b>” for purposes of this Privacy Policy).  Capitalized terms not defined in this Privacy Policy, shall have the meanings as defined in the CloudPremise Terms of Service, located at https://searchmysocial.media/help/terms.</p>
                <p>CloudPremise reserves the right, at any time, to modify this Privacy Policy.  If we make revisions that change the way we collect, use, or share personal information, we will post those changes in this Privacy Policy.  You should review this Privacy Policy periodically so that you keep up to date on our most current policies and practices.  We will note the effective date of the latest version of our Privacy Policy at the end of this Privacy Policy.  Your continued use of the Platform following posting of changes constitutes your acceptance of such changes.</p>
                <div className={classes.privacyContentLists}>
                    <ol>
                        <li>
                            <h5>COLLECTION OF PERSONAL INFORMATION</h5>
                            <ol>
                                <li>
                                    <p><b>Personal Information</b>. “<b>Personal information</b>” means information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked to, directly or indirectly, a particular consumer or household.  The following are categories (with non-exhaustive examples) of personal information we may collect about you.</p>
                                    <div className="privacy-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="categories-column">Categories</th>
                                                    <th className="examples-column">Examples</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>A. Individual Identifiers and Demographic Information</td>
                                                    <td>A real name, alias, postal address, unique personal identifier, online identifier (including social media handles and other social media information), Internet Protocol address, email address, account name, or other similar identifiers.  Email correspondence you send to us or information from your camera or device.</td>
                                                </tr>
                                                <tr>
                                                    <td>B. Sensitive Personal Information</td>
                                                    <td>financial or credit/debit card account information</td>
                                                </tr>
                                                <tr>
                                                    <td>C. Geolocation Data</td>
                                                    <td>Precise physical location or movements.</td>
                                                </tr>
                                                <tr>
                                                    <td>D. Commercial Information</td>
                                                    <td>Records of social media content and posts, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, survey or promotional information,</td>
                                                </tr>
                                                <tr>
                                                    <td>E. Internet or Network Activity</td>
                                                    <td>Browsing history, search history, information on an individual’s interaction with a website, application, or advertisement, and your interactions with third parties and other Users.</td>
                                                </tr>
                                                <tr>
                                                    <td>F. Inferences Drawn from Personal Information</td>
                                                    <td>Profile reflecting a person’s preferences, characteristics, behavior, attitudes, or aptitudes.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>Personal information does not include:</p>
                                    <ul>
                                        <li><p><u>Publicly Available Data</u> - Publicly available information from government records.</p></li>
                                        <li><p><u>Deidentified or Aggregate Information</u> – “<b>Deidentified Information</b>” means information that cannot reasonably identify, relate to, describe, be capable of being associated with, or be linked, directly or indirectly, to a particular individual, and for which CloudPremise has implemented technical safeguards and business processes that prohibit reidentification of the individual. “<b>Aggregate Information</b>” means information that relates to a group or category of individuals, from which individual identities have been removed, that is not linked or reasonably linkable to any individual or household, including via a device. We own all Deidentified Information and Aggregate Information, andmay use and shared Deidentified Information and Aggregate Information in our discretion.</p></li>
                                        <li><p><u>Excluded Information</u> – Certain laws require separate privacy notices or are exempt from general personal information privacy policy disclosure requirements.  Such laws include health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) or clinical trial data; the Gramm-Leach-Bliley Act (GLBA); Fair Credit Reporting Act (FCRA); and the Driver’s Privacy Protection Act of 1994.</p></li>
                                        <li><p><u>Feedback</u> - All comments, feedback, suggestions, ideas, and other submissions (“Ideas”) disclosed, submitted, or offered to CloudPremise in connection with the use of the Platform shall be the exclusive property of CloudPremise. Such Ideas will be non-confidential and CloudPremise shall have no obligations to you, contractual or otherwise. You agree that CloudPremise may use, sell, exploit and disclose the Ideas in any manner, for any purpose whatsoever, commercial or otherwise, without restriction, without attribution and without compensation to you.</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p><b>Sources of Personal Information</b>. We obtain the categories of personal information listed above on or through our Platform from the following categories of sources:</p>
                                    <ul>
                                        <li><p><u>Personal Information You Provide</u>. CloudPremise collects personal information when you voluntarily submit it to us.  For example, we may collect or receive personal information from Users when a User creates a User Account; edits Registration Information;signs up to receive promotional communications; uses or accesses a Service or Content (including a third party service and/or User Content) available through the Platform; participates in one of our promotional sweepstakes, contests, or surveys; posts a review; submits a request to our customer service team; interacts with our social media pages; or otherwise interact with us or other Users or other User Content through the Platform.</p></li>
                                        <li><p><u>Automatically Collected Personal Information</u>. In addition to personal information that we may receive directly from you, CloudPremise indirectly collects other information from you automatically through the Platform. For example, we receive personal information from Users when a User visits and navigates our Platform on any device; installs and uses the Application; enables location-based features on the Application. We may also collect personal information about Users over time and across different websites, apps, and devices when a User accesses the Platform.  Third parties also collect personal information this way on our Platform. CloudPremise collects some personal information automatically using cookies or other online tracking technologies as described in our Cookies Policy, available at https://searchmysocial.media/help/cookies.</p></li>
                                        <li><p><u>Personal Information Obtained from Third Parties</u>. In addition to the personal information that we collect from you directly and automatically, CloudPremise may receive personal information about you from other third party sources, including from other Users.  For example, we receive personal information from our business partners, social media sites, or companies that provide personal information to supplement what we already know about Users.  We may merge or combine such personal information with the personal information we collect from you directly or automatically.</p></li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h5>USE OF PERSONAL INFORMATION</h5>
                            <p>CloudPremise’s primary purpose in collecting personal information is to provide the Services and Content that you request on the Platform.  CloudPremise may also use personal information for various purposes, including without limitation to:</p>
                            <ul className="direct-ul">
                                <li><p><u>To Provide Our Products and Services</u>. This could include fulfilling your requests for products or services. It could also include processing purchases or other transactions.  For example, if you share your personal information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry.  If you provide your personal information to purchase a product or service, we will use such information to process your payment and facilitate delivery.  We may also save your personal information to facilitate new product or service orders, or process returns.</p></li>
                                <li><p><u>To Improve Our Products and Services</u>. We use personal information to understand and analyze the usage trends and preferences of our Users to make our Platform, Services, or Content better, diagnose technical issues, prevent fraud, and develop new features and functionality.</p></li>
                                <li><p><u>To Understand Your Interests</u>. We use your personal information to personalize your experience on the Platform and to deliver information about products and services relevant to your interests.  Our Platform make suggestions for content that may be interesting to you and others. We use the data you provide and the data we have about other users, to make these recommendations.</p></li>
                                <li><p><u>To Process Your Payment for Products and Services</u>. We use personal information to facilitate payment for products and Services that you select through our Platform.</p></li>
                                <li><p><u>To Respond to Your Requests or Questions</u>. We use personal information to provide you with support and to respond to your requests for customer support, including to investigate and address your concerns and monitor and improve our responses.  This may also include responding to your feedback.</p></li>
                                <li><p><u>To Determine Your Eligibility and Set Up Your Account</u>. We use your personal information to verify your identity or determine your eligibility for some of our products, services, and promotions. For example, this may include verifying your age, date of birth, and place of residence.  If you set up an Account on the Platform, we will use the Registration Information you provide in relation to that Account creation to create, maintain, customize, and secure your Account.</p></li>
                                <li><p><u>To Market Products or Services</u>. We provide you with information about products, services, or promotions.  For example, we may use cookies or other online tracking technologies to provide customized advertisements, content, and information; monitor and analyze the effectiveness of marketing activities; and track your entries, submissions, and status in any promotions.  These might be third party offers we think you might find interesting.  These communications may come in the form of interest-based advertising using information gathered across multiple websites, devices, or other platforms.</p></li>
                                <li><p><u>To Secure the Platform</u>. We use personal information to maintain the safety, security, and integrity of our Platform, products and services, databases and other technology assets, business, and other Users.</p></li>
                                <li><p><u>To Communicate with You</u>. We may communicate with you about your Account or our relationship. We may also contact you about this Privacy Policy or the Terms of Service.</p></li>
                                <li><p><u>As Required by Law</u>. We use personal information to respond to requests from law enforcement and as required by applicable law, court order, or government investigation.</p></li>
                            </ul>
                        </li>
                        <li>
                            <h5>SHARING OF PERSONAL INFORMATION</h5>
                            <p>In addition to the specific situations discussed elsewhere in this Privacy Policy, CloudPremise may share your personal information in the following circumstances:</p>
                            <ol>
                                <li><p><b>Related Companies and M&A Transactions</b>. We may share your personal information with companies that are affiliated with us (that is, that control, are controlled by, or are under common control with us). In addition, if we sell all or part of its business or make a sale or transfer of assets or is otherwise involved in a merger or business transfer, we may transfer your personal information to a third party as part of that transaction, including at the negotiation stage.</p></li>
                                <li><p><b>Consent</b>. We may ask if you would like us to share your personal information with other unaffiliated third parties who are not described elsewhere in this Privacy Policy, and we may do so with your consent.</p></li>
                                <li><p><b>Legal Compliance</b>. We may disclose personal information in response to subpoenas, warrants, or court orders, in connection with any legal process, or to comply with relevant laws.  We may also share your personal information in order to establish or exercise our rights; to defend against a legal claim; to investigate, prevent, or take action regarding possible illegal activities or fraud; to protect the safety and security of other Users; or to prevent a violation of our Terms of Service.</p></li>
                                <li><p><b>Service Providers</b>. We may share your personal information with third parties who perform services on our behalf that are necessary for the orderly operation of the Platform. Among other things service providers may help us perform website hosting, app design, maintenance services, database management, web analytics, app analytics, billing, payment processing, fraud protection, credit risk reduction, marketing, or any other use set out in this Privacy Policy.  Access to your personal information by these service providers is limited to the information reasonably necessary for the service provider to perform its limited function.  We require our service providers to keep the personal information that they are provided with confidential and to comply with all applicable laws.</p></li>
                                <li><p><b>Behavioral-Based Advertising</b>. We participate in behavioral-based advertising.  This means that a third party may use technology (e.g., a cookie) to collect information about your use of our Platform so that we can provide advertising about products and services tailored to your interest.  That advertising may appear either on our Platform, or on other websites.</p></li>
                                <li><p><b>Partner or Client Offerings</b>. We may jointly offer events, promotions, or any other product or service offerings with third party partners or our clients, which may be your employer.  The personal information that you submit through an event, promotion, or other product or service offering may be combined and transmitted with the Registration Information related to your Account.  Third party partners or clients may collection information directly from you, which may be combined with personal information disclosed by us.  If you decide to request, enter into, or participate in an event, promotion, or other product or service offering that is offered by us and identified as a joint effort with a third-party partner or client, the information that you provide may be shared with us and with that identified third party.</p></li>
                                <li><p><b>Social Networking</b>. The Platform may offer you the ability to share your personal information through a social networking website (e.g., Facebook, Twitter), using such site’s integrated tools (e.g., Facebook “Like” button, or Twitter “Tweet” button).  The use of such integrated tools enables you to share personal information about yourself with other individuals or the public, depending on the settings that you have established with such social networking site.  For more information about the purpose and scope of data collection and use in connection with such social networking site or a site’s integrated tools, please visit the privacy policies of the entities that provide these social networking sites.</p></li>
                                <li><p><b>Linked Technologies</b>. The Platform may contain links to third party sites and networks, including retailers and advertisers. However, please be aware that CloudPremise is not responsible for and cannot control the terms of service or privacy policies of such other linked technologies. We encourage you to be aware when you leave the Platform, and to read the applicable agreements and privacy policies for each and every linked technologies. The Agreement applies solely to this Platform. CloudPremise is not responsible for and makes no representations or warranties regarding linked technologies, including without limitation, the content, accuracy, opinions, functionality, or services provided in such linked technologies. Inclusion of any linked technologies on the Platform does not imply approval or endorsement by CloudPremise. If You decide to access any linked technologies, then You do so at your own risk.</p></li>
                                <li><p><b>Public Forum and Messaging</b>. The Platform may provide you with the opportunity to post comments, messages, or reviews in a public forum.  If you decide to submit personal information at these locations, that information will be available to other Users of the Platform.</p></li>
                                <li><p><b>Referrals</b>. Users may recommend other Users to join the Platform by providing their contact information or selecting friends from a social networking site.  Users may also provide information about other Users of the Platform such as reviews of the services or products that he or she received, booking or scheduling information, or reference information.</p></li>
                            </ol>
                        </li>
                        <li>
                            <h5>GOOGLE DATA</h5>
                            <p>In addition to the specific situations discussed elsewhere in this Privacy Policy, CloudPremise may collect, use, access, or share your Google information in the following circumstances:</p>
                            <ol>
                                <li><p><b>Account Creation and Authentication</b>. We may provide you the choice to create or access your Account using your Google credentials. This process, referred to as "Social Sign-On" allows us limited access to your Google account for the purposes of establishing or accessing your Account. We may receive your Google email address, photo, or other public profile information. This information will only be used for user account access, and for other services that you may opt-in to. This information will not be provided to third parties without your express consent.</p></li>
                                <li><p><b>Content Management</b>. We may provide you the choice to link to Google Services, such as YouTube, for the purposes of creating and/or associating your User Content with Google Services. This process uses Internet best-practices, referred to as "OAuth", to allow us to access your Google Services on your behalf. The information we receive from Google will not ever be provided to third parties, and you remain in control of your Google Services at all times.</p></li>
                                <li><p><b>Account Maintenance</b>. We may provide you the choice to link to Google Services (for Account Creation and Authentication and/or Content Management) for which we may securely store a security token, referred to as a "OAuth Token" to access your Google Services at any time, on your behalf. This security token is provided to us by Google, and we store this security token with the same level of security as other sensitive data associated with your Account. We use industry best practices to access, store, and retrieve your Google security tokens. Your security tokens will never be shared with third parties.</p></li>
                                <li><p><b>Google Privacy Policy</b>. Use of Google Data includes acceptance of the Google Privacy Policy located at <a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a> </p></li>
                                <li><p><b>Google Security Settings</b>. To see or modify your Google security settings navigate to <a href='https://security.google.com/settings/security/permissions'>https://security.google.com/settings/security/permissions</a> </p></li>

                                
                            </ol>
                        </li>
                        <li>
                            <h5>SECURITY OF PERSONAL INFORMATION </h5>
                            <p>No method of transmission over the Internet, or method of electronic storage, is fully secure.  While we use reasonable efforts to protect your personal information from the risks presented by unauthorized access or acquisition, we cannot guarantee the security of your personal information.  In the event that we are required by law to inform you of any unauthorized access or acquisition of your personal information we may notify you electronically, in writing, or by telephone, if permitted to do so by law.</p>
                        </li>
                        <li>
                            <h5>YOUR CHOICES</h5>
                            <p>You can make the following choices regarding your personal information:</p>
                            <ol>
                                <li><p><b>Access to Your Personal Information</b>. You may request access to your personal information by contacting us as described below.  We will grant you reasonable access to the data that we have about you as required by law.</p></li>
                                <li><p><b>Changes to Your Personal Information</b>. We rely on you to update and correct the personal information contained in your Account.  Note that we may keep historical information in our backup files as permitted by law. If our Platform does not permit you to update or correct certain personal information,please contact us as described below.</p></li>
                                <li><p><b>Deletion of Your Personal Information</b>. Typically, we retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. You may, however, request that we delete your personal information by contacting us contacting us as described below.  We will grant a request to delete information as required by law, but you should note that in many situations we must keep your personal information to comply with our legal obligations, resolve disputes, enforce our agreements, or for another one of our business purposes.  Except as provided above, we will delete, aggregate, or de-identify all of your personal information as described in this subsection within the timeframes required by law.</p></li>
                            </ol>
                        </li>
                        <li>
                            <h5>OPTING OUT</h5>
                            <p>You can make the following choices to opt out of certain activities regarding your personal information:</p>
                            <ol>
                                <li><p><b>Promotional E-mails</b>. You may choose to provide us with your e-mail address for the purpose of allowing us to send newsletters, surveys, offers, and other promotional materials related to our Platform, as well as targeted offers from third parties.  You can stop receiving promotional e-mails by clicking the “unsubscribe” links in the e-mails or by contacting us as described below.  If you decide not to receive promotional e-mails, we may still send you service-related communications, such as those about your Account, to fulfill orders for products and service you have requested, or deliver notifications directly to you through the Platform.</p></li>
                                <li><p><b>Behavioral-Based Advertising</b>. We participate in behavioral-based advertising.  This means that a third party may use technology (e.g., a cookie) to collect information about your use of our Platform so that we can provide advertising about products and services tailored to your interest.  That advertising may appear either on our Platform, or on other websites.  If you wish to limit third parties’ collection of information about your use of our Platform, you can opt-out of such at the <Link href="http://optout.aboutads.info/" target="_blank">Digital Advertising Alliance</Link> or <Link href="http://optout.networkadvertising.org/" target="_blank">Network Advertising Initiative</Link> in the U.S., the <Link href="https://youradchoices.ca/choices/" target="_blank">Digital Advertising Alliance of Canada</Link> in Canada, or the <Link href="http://www.youronlinechoices.eu/" target="_blank">European Digital Advertising Alliance</Link> in Europe.Mobile app users may opt out of receiving behavioral-based advertising in mobile apps provided by participating members of the Digital Advertising Alliance by installing the <Link href="http://www.aboutads.info/appchoices" target="_blank">AppChoices mobile app</Link>. PLEASE NOTE THAT OPTING-OUT OF BEHAVIORAL ADVERTISING DOES NOT MEAN THAT YOU WILL NOT RECEIVE ADVERTISING WHILE USING THE PLATFORM. IT WILL, HOWEVER, EXCLUDE YOU FROM INTEREST-BASED ADVERTISING CONDUCTED THROUGH PARTICIPATING NETWORKS, AS PROVIDED BY THEIR POLICIES AND CHOICE MECHANISMS.</p></li>
                                <li><p><b>Do-Not-Track</b>. Some web browsers and devices permit you to broadcast a preference that you not be “tracked” online.  At this time we do not modify your experience based upon whether such a signal is broadcast.</p></li>
                            </ol>
                        </li>
                        <li>
                            <h5>INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION </h5>
                            <p>CloudPremise is based in the United States of America.  If you are from a country outside of the United States of America with laws governing data collection, use, and disclosure that may differ from U.S. law and you provide personal information to us, please note that any personal information that you provide to us may be transferred to the United States of America.  By providing your personal information, where applicable law permits, you hereby specifically and expressly consent to such transfer and processing and the collection, use, and disclosure set forth herein or in the Terms of Service related to the use of and access to the Platform.</p>
                        </li>
                        <li>
                            <h5>RESIDENTS OF THE EUROPEAN ECONOMIC AREA</h5>
                            <p>The following applies to individuals in the European Economic Area (EEA).</p>
                            <ol>
                                <li>
                                    <p><b>Lawful basis for processing</b>. CloudPremiseis required to inform you of the lawful basis of our processing of your personal information, which are described in the table below.  If you have questions about the lawful basis of how we process your personal information, contact us at the address listed in the section titled “CONTACTING US” below.</p>
                                    <div className="privacy-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="categories-column processing-column">Processing Purpose</th>
                                                    <th className="examples-column lawful-column">Lawful Basis</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>Collection of Sensitive Personal Information.</li>
                                                            <li>To Improve Our Products and Services.</li>
                                                            <li>To Market Products or Services.</li>
                                                            <li>To Use Your Google Data</li>
                                                        </ul>
                                                    </td>
                                                    <td><u>Processing is based on your consent</u>. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated on the Platform.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>To Provide Our Products and Services.</li>
                                                            <li>To Communicate with You.</li>
                                                            <li>To Understand Your Interests.</li>
                                                            <li>To Process Your Payment for Products and Services.</li>
                                                            <li>To Respond to Your Requests or Questions.</li>
                                                            <li>To Determine Your Eligibility and Set Up Your Account.</li>
                                                            <li>To Use Your Google Data</li>
                                                        </ul>
                                                    </td>
                                                    <td><u>Processing is necessary for the performance of a contract</u>.  You are subject to a contract with us and we need to use your personal information to provide products and services.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>To Secure the Platform.</li>
                                                            <li>As Required by Law.</li>
                                                        </ul>
                                                    </td>
                                                    <td><u>Processing is necessary to comply with our legal obligations</u>.</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>To Improve Our Products and Services.</li>
                                                            <li>To Understand Your Interests.</li>
                                                            <li>To Market Products or Services.</li>
                                                            <li>To Secure the Platform.</li>
                                                            <li>To Use Personal Information as Otherwise Described in this Privacy Policy.</li>
                                                        </ul>
                                                    </td>
                                                    <td><u>Processing activities constitute our legitimate interests</u>.  We consider and balance the potential impact on your rights before we process your personal information for our legitimate interests.  We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent, or we are otherwise required to perform a contract or by law).</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>We will use your personal information only for the purposes for which we collected it, unless we reasonably determine we need to use it for another reason and that reason is compatible with the original purpose.For example, we consider deidentification, aggregation, and anonymization of personal information to be compatible with the purposes listed above and in your interest, because the deidentification, aggregation, and anonymization of such information reduces the likelihood of improper disclosure of that information.</p>
                                    <p>PLEASE NOTE WE MAY PROCESS YOUR PERSONAL INFORMATION WITHOUT YOUR KNOWLEDGE OR CONSENT, IN COMPLIANCE WITH THE ABOVE RULES, WHERE THIS IS REQUIRED OR PERMITTED BY LAW.</p>
                                </li>
                                <li>
                                    <p><b>Retention</b>. We retain your personal information:</p>
                                    <ul>
                                        <li><p>For so long as your Account is active or as needed to provide you with the Platform or to fulfill our contractual obligations;</p></li>
                                        <li><p>As necessary to comply with our legal obligations, resolve disputes, and enforce our agreements; and</p></li>
                                        <li><p>For so long as is necessary for the purposes for which we collected such personal information.</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p><b>Your Rights</b>. European data protection laws give you certain rights regarding your personal information.You may ask us to take the following actions in relation to your personal information that we hold:</p>
                                    <ul>
                                        <li><p><u>Access</u>. Provide you with information about our processing of your personal information and give you access to your personal information.</p></li>
                                        <li><p><u>Transfer</u>. Transfer a machine-readable copy of your personal information to you or a third party of your choice.</p></li>
                                        <li><p><u>Correct</u>. Update or correct inaccuracies in your personal information.</p></li>
                                        <li><p><u>Restrict</u>. Restrict the processing of your personal information.</p></li>
                                        <li><p><u>Object</u>. Object to our reliance on our lawful basis as the basis of our processing of your personal information that impacts your rights.</p></li>
                                        <li><p><u>Delete</u>. Delete your personal information.</p></li>
                                    </ul>
                                    <p>When we receive your request, we may ask you to verify your identity before we can act on your request. We may withhold information where we are required by law to do so or if the search for that information would require disproportionate effort or have a disproportionate effect to, for example, the cost of providing the information, the time it would take to retrieve the data, or how difficult it may be to obtain the information requested.</p>
                                    <p>If you are a resident EEA and would like to exercise any of these rights, please submit your request to the address listed in the section titled “CONTACTING US” below.  If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection authority in your jurisdiction.  You can find your data protection regulator <Link href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">here</Link>.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h5>RESIDENTS OF CALIFORNIA</h5>
                            <p>The following applies to residents of California.</p>
                            <ol>
                                <li><p><b>Notice of Disclosure for Direct Marketing</b>. Under California Civil Code sections 1798.83-1798.84, California residents who have an established business relationship with CloudPremise are entitled to ask us for a notice describing what categories of personal information we share with third parties for their direct marketing purposes. This notice will identify the categories of information shared with and will include a list of the third parties with which it is shared, along with their names and addresses. If you are a California resident and would like a copy of this notice, please submit your request to the address listed in the section titled “CONTACTING US” below.</p></li>
                            </ol>
                        </li>
                        <li>
                            <h5>EXERCISING YOUR PRIVACY RIGHTS</h5>
                            <p>When exercising the rights or options described in this Privacy Policy, the following guidelines apply:</p>
                            <ol>
                                <li><p><b>No Fee Usually Required</b>. You will not have to pay a fee to access your personal information (or to exercise any of the other rights).  However, we may charge a reasonable fee or decline to comply with your request if your request is clearly unfounded, repetitive, or excessive.</p></li>
                                <li><p><b>What We May Need from You</b>. When exercising your rights or otherwise assisting you, we may need to request specific information from you to help us confirm your identity.  This is a security measure to ensure we do not disclose personal information to any person who is not entitled to receive it.   We may also contact you to ask you for further information in relation to your request to speed up our response.</p></li>
                                <li><p><b>Time to Respond</b>. We try to respond to all legitimate requests within 30 days of your request.  Occasionally it may take us longer than 30 days to respond, for instance if your request is particularly complex or you have made a number of requests.  In this case, we will notify you of the delay, and may continue to update you regarding the progress of our response.</p></li>
                                <li><p><b>No discrimination</b>. You will not be subject to discrimination as a result of exercising the rights described herein.  In some cases, when you exercise one of your rights, we will be unable to comply with the request due to legal obligations or otherwise, or we will be unable to provide you certain products or services.  These responses are not discrimination and our reasons for declining your request or ceasing services will be provided at that time.</p></li>
                                <li><p><b>Authorized Agent</b>. You may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide the requester’s proof of identification and the authorized agent’s proof of identification.</p></li>
                            </ol>
                        </li>
                        <li>
                            <h5>CONTACTING US</h5>
                            <p>If you have any questions or concerns about our Privacy Policy or any other privacy or security issue, please contact us at:</p>
                            <div className="contact-us-detail">
                                <p>CloudPremise LLC d/b/a Smearch</p>
                                <p>Attn: Smearch Privacy Agent</p>
                                <p>1800 Wazee St., 3rd Floor</p>
                                <p>Denver, CO 80202</p>
                                <Link href="mailto:datasecurity@smearch.com">datasecurity@smearch.com</Link>
                            </div>
                            <p>Last Revised: August 23, 2020</p>
                        </li>
                    </ol>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(PrivacyStyle)(Privacy);
