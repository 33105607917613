import React from 'react';
import { createBrowserHistory } from 'history';
import Api from '../../../../assets/js/utils/Api';
import WebLinkEditWizard from "../../../pages/dashboard/platforms/WebLinkEditWizard";
import ContentDeleteModal from "../Content/ContentDeleteModal";
// import LoaderComponent from '../../Loader';
import CoverPhoto from "./CoverPhoto";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';

import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Close from "@material-ui/icons/Close";
import Button from "../../../components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link";
import ChangeUserLevelModal from "../../../components/Dashboard/ChangeUserLevelModal";
import ButtonBase from '@material-ui/core/ButtonBase';

import MaterialIcon from "@mdi/react";
import { mdiMagnifyClose, mdiMagnify, mdiArrowDownBoldOutline, mdiArrowUpBoldOutline, mdiCursorDefaultClickOutline, mdiEye } from '@mdi/js';

import listViewStyle from "../../../../assets/jss/dashboard/content/listViewStyle";
import defaultImage from "../../../../assets/img/image_placeholder.jpg";

const history = createBrowserHistory();

class ListView extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.primaryColor = null;

        let contentId = null;
        if(this.props.match.params.hasOwnProperty("contentId")){
            contentId = this.props.match.params.contentId;
        }

        this.state = {
            response: this.props.response,
            editMode: false,
            contentId: contentId,
            content: null,
            deleteMode: false,
            deleteKey: null,
            disabledArrows: false,
            changeUserLevel: false,
            paymentData: this.props.paymentData,
            subscription: this.props.subscription,
            isButtonPlatform: this.props.isButtonPlatform
        };

        this.deleteCatalog = this.deleteCatalog.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.onPhotoResize = this.onPhotoResize.bind(this);
        this.upgradeUserLevel = this.upgradeUserLevel.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
    }
    componentWillReceiveProps(props){
        if(props.response.length !== this.state.response.length){
            this.setState({
                response: props.response,
                contentType: props.contentType,
                paymentData: props.paymentData,
                subscription: props.subscription,
                isButtonPlatform: props.isButtonPlatform
            });
        }
    }
    getCoverPhoto(content){
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlProfile;
    }
    deleteModal(content, key){
        this.setState({deleteMode: true, content: content, deleteKey: key});
    }
    deleteCatalog(){
        const content = this.state.content;
        const key = this.state.deleteKey;
        this.state.response.splice(key,1);
        this.setState({
            response: this.state.response,
            content: null,
            deleteKey: null,
            deleteMode: false
        });
        
        Api.deleteContent(content.contentId);
    }
    editContent(e, content){
        e.preventDefault();
        const { contentPlatformId, contentId } = content;
        history.push("/dashboard/platform/"+contentPlatformId+"/"+contentId);
        this.setState({editMode: true, content: content});

        let scrollPosition = document.documentElement.scrollTop;
        if(scrollPosition <= 0){
            scrollPosition = document.body.scrollTop;
        }
        document.documentElement.setAttribute("data-scroll", scrollPosition);
        if(this.props.onEdit){
            this.props.onEdit();
        }
    }
    onWizardClose(platform, newContent = null, afterCreate, afterEdit, afterDelete){
        if(afterEdit === true){
            if(newContent !== null){
                const response = this.state.response;
                response.map((content, key) => {
                    if(content.contentId === newContent.contentId){
                        response[key] = newContent;
                    }
                    return null;
                });
                this.setState({response: response});
            }            
            this.props.onWizardClose(platform, newContent, false, afterEdit, afterDelete);
        }else{
            setTimeout(function(){
                document.body.scrollTop = 0;
                const scrollPositionSaved = parseFloat(document.documentElement.getAttribute("data-scroll"));
                window.scrollTo(0,scrollPositionSaved);
                document.documentElement.removeAttribute("data-scroll");
            },5);
            this.props.onWizardClose(platform, newContent, false, afterEdit, afterDelete);
        }
        this.setState({editMode: false, content: null});
    }
    getTitle(content){
        if(!content.hasOwnProperty("title")){
            return null
        }
        if(content.title.length <= 20){
            return content.title;
        }
        return content.title.substr(0,20).trim()+"...";
    }
    getLocation(content){
        if(content.location.length <= 20){
            return content.location;
        }
        return content.location.substr(0,20).trim()+"...";
    }
    toggleBoolean(content, key, attrib){
        if(!content.hasOwnProperty(attrib)){
            return;
        }
        let response = this.state.response;
        content[attrib] = !content[attrib];
        response[key] = content;
        this.setState({
            response: response,
        });

        if(attrib === "isFeatured" && this.props.onFeatured){
            this.props.onFeatured(content);
        }
        if(attrib === "isFeatured"){
            // content.featuredSequence = 100
        }
        Api.updateContent(content.contentId, content).then((data) => {
            if(data.numberOfResponses > 0){
                if(attrib === "isFeatured" && content[attrib] === true){
                    const newContent = data.response[0];
                    let index = null;
                    response.map((item, key) => {
                        if(item.contentId === content.contentId){
                            index = key;
                        }
                        return null;
                    });
                    if(index !== null){
                        response[index] = newContent;
                        this.setState({
                            response: response,
                        });
                    }
                }
            }
        }).catch(err => {
            //Handle Error
        });
    }
    onDeleteModalClose(){
        this.setState({deleteMode: false, deleteKey: null, content: null});
    }
    moveUp(oldKey, content){
        if(oldKey === 0){
            return;
        }
        const { response } = this.state;
        const previousKey = oldKey-1;
        let newResponse = [];
        response.map((newContent, key) => {
            if(key === previousKey){
                newResponse.push(content);
            }
            if(newContent.contentId !== content.contentId){
                newResponse.push(newContent);
            }
            return null;
        });
        this.setState({response: newResponse});
        this.props.onChnageSequence(newResponse);
        let downContent = null;
        let featuredSequence = null;
        if(typeof newResponse[oldKey] !== 'undefined'){
            downContent = newResponse[oldKey];
            featuredSequence = downContent.featuredSequence;
            downContent.featuredSequence = content.featuredSequence;
        }
        content.featuredSequence = featuredSequence;
        this.updateSorting(content, downContent);
    }
    moveDown(oldKey, content){
        const { response } = this.state;
        if((oldKey+1) === response.length){
            return;
        }

        const nextKey = oldKey+1;
        let newResponse = [];
        response.map((newContent, key) => {
            if(newContent.contentId !== content.contentId){
                newResponse.push(newContent);
            }
            if(key === nextKey){
                newResponse.push(content);
            }
            return null;
        });
        this.setState({response: newResponse});
        this.props.onChnageSequence(newResponse);
        let upContent = null;
        let featuredSequence = null;
        if(typeof newResponse[oldKey] !== 'undefined'){
            upContent = newResponse[oldKey];
            featuredSequence = upContent.featuredSequence;
            upContent.featuredSequence = content.featuredSequence;
        }
        content.featuredSequence = featuredSequence;        
        this.updateSorting(upContent, content);
    }
    updateSorting(upContent, downContent){
        this.setState({
            disabledArrows: true
        });
        Api.updateContent(upContent.contentId, upContent, true).then((data) => {
            this.setState({
                disabledArrows: false
            });
        }).catch(err => {
            this.setState({
                disabledArrows: false
            });
        });

        Api.updateContent(downContent.contentId, downContent, true).then((data) => {
            this.setState({
                disabledArrows: false
            });
        }).catch(err => {
            this.setState({
                disabledArrows: false
            });
        });
    }
    onPhotoResize(newContent){
        const response = this.state.response;
        response.map((content, key) => {
            if(content.contentId === newContent.contentId){
                response[key] = newContent;
            }
            return null;
        });
        this.setState({response: response});
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        this.primaryColor = palette.custom.main;
        return palette.custom.main;
    
    }
    isOverlay(key){
        const { maxFeatured, contentType } = this.props;
        const { user } = this.store.getState();
        if(contentType !== "featured"){
            return false;
        }
        if(key >= maxFeatured){
            return true;
        }
        if((user.userLevel === 0 && key >= 6) || (user.userLevel === 1 && key >= 12)){
            return true;
        }
        return false;
    }
    isUpgradeNeeded(key){
        const { contentType } = this.props;
        const { user } = this.store.getState();
        if(contentType !== "featured"){
            return false;
        }
        if((user.userLevel === 0 && key >= 6) || (user.userLevel === 1 && key >= 12)){
            return true;
        }
        return false;
    }
    upgradeUserLevel(e){
        e.preventDefault();
        this.changeUserLevel();
    }
    changeUserLevel(changeUserLevel = true){
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    publishDate(date){
        date = new Date(date);
        if(date === "Invalid Date"){
            return <></>;
        }
        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        var yyyy = date.getFullYear();
     
        return (MM + "/" + dd + "/" + yyyy);
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    getButtonStyles(){
        const { user } = this.store.getState();
        let primaryColor = this.getPrimaryColor();
        const { fontSize, buttonStyle } = user.guestPreferences;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")
        };
        return buttonStyles;
    }
    renderAnalytics(content, dFlex = true){
        const { classes } = this.props;
        const { user } = this.store.getState();
        if(user.userLevel <= 0){
            return null;
        }
        return (
            <div className={classes.analyticsData+" "+(dFlex ? classes.analyticsFlexData : "")}>
                <ul>
                    <li>
                        <span>{content.statLifetimeViews}</span><MaterialIcon path={mdiEye} className={"MuiSvgIcon-root"} />
                    </li>
                    <li>
                        <span>{content.statLifetimeClicks}</span><MaterialIcon path={mdiCursorDefaultClickOutline} className={"MuiSvgIcon-root"} />
                    </li>
                </ul>
            </div>
        );
    }
    render(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const { editMode, content, response, deleteMode, disabledArrows, changeUserLevel, paymentData, subscription, isButtonPlatform } = this.state;
        if(editMode && content !== null){
            return (
                <WebLinkEditWizard match={this.props.match} platform={this.props.platform} store={this.store} onDeleteContent={this.props.onDeleteContent} onWizardClose={this.onWizardClose} content={content} />
            )
        }
        const buttonStyles = this.getButtonStyles();
        return (
            <GridItem lg={12}>
                {response.map((content, key) => (
                    <GridContainer key={content.contentId} className={(isButtonPlatform === true ? classes.buttonView : "")+" "+classes.gridSpacing+" listViewItemRow"}>
                        {
                            this.isOverlay(key) ?
                                <div className={classes.backgroundOverlay}>
                                    <p>
                                        {
                                            this.isUpgradeNeeded(key) ?
                                                <>
                                                    <Link href="/dashboard/account/upgrade" onClick={this.upgradeUserLevel} className={classes.dropdownLink}>Upgrade</Link> to feature more content
                                                </>
                                            :
                                            <></>
                                        }
                                    </p>
                                </div>
                            :
                                <></>
                        }
                        {
                            isButtonPlatform === true ?
                                <>
                                    <ButtonBase className={classes.buttonIcon+" "+classes.buttonBase+(user.guestPreferences.buttonStyle === "rounded" ? " rounded" : "")} style={buttonStyles}>
                                        <CoverPhoto content={content} onClick={(e) => this.editContent(e, content)} onPhotoResize={this.onPhotoResize} showArrows={false} primaryColor={this.getPrimaryColor()} />
                                        <Link href={user.username+"/"+content.contentId} onClick={(e) => this.editContent(e, content)} underline="none" style={buttonStyles}>{content.title}</Link>
                                    </ButtonBase>
                                    {
                                        (authorized && user.userId === content.ownerId) ?
                                            <div className={classes.options+" options"}>
                                                {
                                                    this.props.isFeaturedContent === true ?
                                                        <div className={classes.optionsRow}>
                                                            <Button justIcon size="sm" color="blue" className={classes.icon} onClick={(e) => this.editContent(e, content)}>
                                                                <Edit />
                                                            </Button>
                                                        </div>
                                                    :
                                                    <>
                                                        <div className={classes.optionsRow+" optionsrow"}>
                                                            <Button justIcon size="sm" color="blue" className={classes.icon} onClick={(e) => this.editContent(e, content)}>
                                                                <Edit />
                                                            </Button>
                                                            <Button justIcon size="sm" color={content.isSearchable?"primary":"white"} className={classes.starIcon+ " "+(content.isSearchable ? "" : classes.searchableIcon)} onClick={(e) => this.toggleBoolean(content, key, "isSearchable")}>
                                                                {
                                                                    content.isSearchable ?
                                                                        <MaterialIcon path={mdiMagnify} className={"MuiSvgIcon-root"} />
                                                                    :
                                                                        <MaterialIcon path={mdiMagnifyClose} className={"MuiSvgIcon-root"} />
                                                                }
                                                            </Button>
                                                            <Button size="sm" justIcon color="danger" className={classes.icon} onClick={() => this.deleteModal(content, key)}>
                                                                <Close />
                                                            </Button>
                                                        </div>
                                                        {this.renderAnalytics(content, false)}
                                                    </>
                                                }    
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                            :
                                <>
                                    <GridItem xs={3} sm={4} md={4} lg={4} className={classes.gridItem+" "+classes.gridItemResponsive+" "+(this.props.showArrows?classes.gridItemResponsiveWithArrows:"")}>
                                        {
                                            this.props.showArrows ?
                                                <span className={classes.arrows}>
                                                    <Button justIcon className={((key === 0 || disabledArrows) ? "disableArrow":"")} disabled={disabledArrows} onClick={() => this.moveUp(key, content)}>
                                                        <MaterialIcon path={mdiArrowUpBoldOutline} className={"MuiSvgIcon-root"} />
                                                    </Button>
                                                    <Button justIcon className={(((key+1) === response.length || disabledArrows) ?"disableArrow":"")} disabled={disabledArrows} onClick={() => this.moveDown(key, content)}>
                                                        <MaterialIcon path={mdiArrowDownBoldOutline} className={"MuiSvgIcon-root"} />
                                                    </Button>
                                                </span>
                                            :
                                            <></>
                                        }
                                        <CoverPhoto content={content} onClick={(e) => this.editContent(e, content)} onPhotoResize={this.onPhotoResize} showArrows={this.props.showArrows} primaryColor={this.getPrimaryColor()} />
                                    </GridItem>
                                    <GridItem xs={9} sm={8} md={8} lg={8} className={classes.gridItem+" "+classes.optionsGridItem+" "+(this.props.showArrows?classes.optionsGridItemWithArrows:"")}>
                                        
                                        <h3 className={classes.title} onClick={(e) => this.editContent(e, content)}>{this.getTitle(content)}</h3>
                                        {
                                            content.hasOwnProperty("location") && content.location.length > 0 ?
                                                <p className={classes.location}>{this.getLocation(content)}</p>
                                            :
                                            <></>
                                        }
                                        {this.renderAnalytics(content, true)}
                                        {
                                            (authorized && user.userId === content.ownerId) ?
                                                <div className={classes.options}>
                                                    {
                                                        this.props.isFeaturedContent === true ?
                                                            <div className={classes.optionsRow}>
                                                                <Button justIcon size="sm" color="blue" className={classes.icon} onClick={(e) => this.editContent(e, content)}>
                                                                    <Edit />
                                                                </Button>
                                                            </div>
                                                        :
                                                        <>
                                                            <div className={classes.optionsRow}>
                                                                <Button justIcon size="sm" color="blue" className={classes.icon} onClick={(e) => this.editContent(e, content)}>
                                                                    <Edit />
                                                                </Button>
                                                                <Button justIcon size="sm" color={content.isSearchable?"primary":"white"} className={classes.starIcon+ " "+(content.isSearchable ? "" : classes.searchableIcon)} onClick={(e) => this.toggleBoolean(content, key, "isSearchable")}>
                                                                    {
                                                                        content.isSearchable ?
                                                                            <MaterialIcon path={mdiMagnify} className={"MuiSvgIcon-root"} />
                                                                        :
                                                                            <MaterialIcon path={mdiMagnifyClose} className={"MuiSvgIcon-root"} />
                                                                    }
                                                                </Button>
                                                                <Button size="sm" justIcon color="danger" className={classes.icon} onClick={() => this.deleteModal(content, key)}>
                                                                    <Close />
                                                                </Button>
                                                            </div>
                                                            <div className={classes.publishDate}>
                                                                {
                                                                    content.sequenceDateTime ?
                                                                        <p>{this.publishDate(content.sequenceDateTime)}</p>
                                                                    :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </>
                                                    }    
                                                </div>
                                            :
                                            <></>
                                        }
                                    </GridItem> 
                                </>
                        }                       
                    </GridContainer>
                ))}
                <ContentDeleteModal open={deleteMode} onClose={() => this.onDeleteModalClose()} onSuccess={() => this.deleteCatalog()} />
                {
                    changeUserLevel ?
                        <ChangeUserLevelModal upgrade={true} redirectUrl="/dashboard/profile/featured" subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                    :
                        <></>
                }
            </GridItem>
        );
    }
}

ListView.defaultProps = {
    onOpen: function(){ },
    showStar: true,
    onFeatured: function(){ },
    showArrows: false,
    onEdit: function(){ },
    isFeaturedContent: false,
    onChnageSequence: function(){ },
    contentType: "default",
    maxFeatured: 6
}
ListView.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    showStar: PropTypes.bool,
    onFeatured: PropTypes.func,
    showArrows: PropTypes.bool,
    onEdit: PropTypes.func,
    isFeaturedContent: PropTypes.bool,
    onChnageSequence: PropTypes.func,
    contentType: PropTypes.string,
    maxFeatured: PropTypes.number
};
  
export default withTheme(withStyles(listViewStyle)(ListView));