import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import Config from '../../../../Config';
import Api from '../../../assets/js/utils/Api';
import LoaderComponent from '../../components/Loader';

import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button.jsx";
import Warning from "../../components/Typography/Warning.jsx";
import Table from "../../components/Table/Table.jsx";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

import useStyles from "../../../assets/jss/catalog/homePageStyle";
import { useTableStyles } from "../../../assets/jss/catalog/homePageStyle";

export default function CatalogHome(props) {
    const store = props.store;
    const classes = useStyles();
    const tableClases = useTableStyles();
    const { user, authorized } = store.getState();
    const [state, setState] = React.useState({
        response: [],
        loading: true,
        cancelToken: null,
        contentLoaded: false
    });
    React.useEffect(() => {
        return function cleanup() {
            if (state.cancelToken) {
                state.cancelToken.cancel('Request Cancelled')
            }
        };
    });

    if(authorized && state.cancelToken === null && state.contentLoaded === false){
        loadCatalog();
    }
    function loadCatalog(){
        const ApiUrl = Config.getApiUrl()+"data/content/user/"+user.userId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        setState({
            ...state,
            cancelToken: source
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            updateResponse(res.data.response);
        }).catch(err => {
            
        });
    }
    function updateResponse(response){
        setState({
            ...state,
            response: response,
            loading: false,
            contentLoaded: true,
            cancelToken: null
        });
    }
    function deleteCatalog(content, key){
        state.response.splice(key,1);
        setState({
            ...state, 
            response: state.response
        });
        
        Api.deleteContent(content.contentId).then((data) => {
            setState({...state, redirect: true});
        }).catch(err => {
            console.log(err);
        });
    }
    function getTableActions(content, key){
        let buttons = [];
        if(!authorized || user.userId !== content.ownerId){
            return buttons;
        }
        buttons.push(
            <Link to={"/"+content.contentId+"/edit"} key={0}>
                <Button justIcon size="sm" color="success">
                    <Edit />
                </Button>
            </Link>            
        );
        buttons.push(
            <Button justIcon size="sm" color="danger" key={1} onClick={() => deleteCatalog(content, key)}>
                <Close />
            </Button>
        );
        return buttons;
    }
    function getTableData(){
        let tableData = [];
        state.response.map((content,key) => {
            let tableRow = [];
            if(authorized && user.userId === content.ownerId){
                tableRow.push(<Link to={"/"+content.contentId+"/edit"}>{content.title}</Link>)
            }else{
                tableRow.push(content.title)
            }            
            tableRow.push(content.topic)
            tableRow.push(getTableActions(content, key))
            tableData.push(tableRow);
            return null;
        });
        return tableData;
    }
    return (
        <Container className="profile-edit-page-holder">
            <Grid item className={classes.container}>
                <h3 className={classes.title}>
                    Search Catalog
                </h3>
                {
                    state.response.length > 0 ?
                        <Table
                            classes={tableClases}
                            tableHead={[
                                "Title",
                                "Topic",
                                "Actions"
                            ]}
                            tableData={getTableData()}
                        />
                    :
                    <>
                    {
                        state.loading ?
                            <LoaderComponent align="left" />
                        :
                            <div>
                                <Warning>Your catalog is empty.</Warning>
                            </div>
                    }
                    </>
                        
                }
                {
                    authorized ?
                        <Link to={"/profile/"+user.userId+"/catalog/new"}>
                            <Button type="button" color="primary" round >
                                <AddIcon className={classes.icons} />Add New
                            </Button>
                        </Link>
                    :
                    <></>
                }
                
            </Grid>
            <Helmet>
                <title>Search My Social App Search Catalog</title>
            </Helmet>
        </Container>
    )
}