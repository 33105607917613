import React from 'react'
import { Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Api from '../../../../assets/js/utils/Api';
import AuthApi from '../../../../assets/js/utils/Auth';
import Cookie from "../../../../assets/js/utils/Cookie";
import LoaderComponent from '../../Loader'
import { helper } from '../../../../assets/js/utils/Element'
import axios from 'axios';
import Config from '../../../../../Config';
import StreamView from "../../Search/StreamView";
import StreamViewCard from "../../Search/StreamViewCard";
import GridView from "../../Search/GridView";
import ListView from "../../Search/ListView";
import ThreeColumnGrid from "../../Search/ThreeColumnGrid";
import ButtonsView from "./ButtonsView";
import ContentAffiliateModal from "../../Search/ContentAffiliateModal";
import SearchTerms from "../../Dashboard/Editmode/SearchTerms";
import Link from "@material-ui/core/Link";
// import { getBoundsOfDistance } from "geolib";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
// import Warning from "../../components/Typography/Warning.jsx";
// import PlaceIcon from "@material-ui/icons/Place";
import Slide from "@material-ui/core/Slide";
import CustomAutocomplete from "../../CustomInput/CustomAutocomplete";
// import instagramIcon from "../../../../assets/img/instagram-icon.png";
// import youtubeIcon from "../../../../assets/img/youtube-icon.png";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from "@material-ui/icons/Close";
import SearchableContentTypes from "../../../pages/dashboard/platforms/Searchable/ContentTypes";
import InstagramWizardModal from "./InstagramWizardModal";
import YoutubeWizardModal from "./YoutubeWizardModal";
import LinktreeWizardModal from "./LinktreeWizardModal";
import InstagramApiModal from "../InstagramApiModal.jsx"
import YoutubeApiModal from "../YoutubeApiModal.jsx"
import LinktreeModal from "../LinktreeModal.jsx";
import AdButtonModal from "./AdButtonModal";

import { ReactComponent as FeedViewIcon } from "../../../../assets/icons/feed_view-2.svg";
import { ReactComponent as ListViewIcon } from "../../../../assets/icons/list_view-1.svg";
// import { ReactComponent as ShoppingBagIcon } from "../../../../assets/icons/shopping_bag.svg";

import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import CustomSelectSocialIcons from "../../CustomInput/CustomSelectSocialIcons";
import { ReactSortable } from "react-sortablejs";
import Menu from "@material-ui/icons/Menu";
import SocialIcon from "./SocialIcon";

import MaterialIcon from "@mdi/react";
import { mdiViewGrid, mdiGrid, mdiViewDashboard } from '@mdi/js';

import searchFormStyle from "../../../../assets/jss/dashboard/editmode/searchFormStyle";
import defaultImage from "../../../../assets/img/dg1.jpg";
import {
    primaryColor,
} from "../../../../assets/jss/material-kit-pro-react.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
  
Transition.displayName = "Transition";
const history = createBrowserHistory();
// let _that = this;

class SearchForm extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;
        this.checkElementsInViewTimeout = null;
        this.userUpdateTimeout = null;
        const { authorized } = this.store.getState();
        this.user = this.props.user;
        let viewType = this.getViewType(this.user);
        let filterTypes = [0,1,2,3];
        if(!authorized){
            filterTypes = [1,2,3];
        }
        let platforms = [];
        if(this.user.hasOwnProperty("contentPlatforms")){
            platforms = this.user.contentPlatforms;
        }
        // _that = this;

        const query = helper.getParam("q");

        let filters = {
            allFilter: true,
            shoppableFilter: false,
            linksFilter: false,
            instagramFilter: false,
            youtubeFilter: false,
            locationFilter: false
        };
        if(this.user.guestPreferences.hasOwnProperty("filters")){
            filters =  this.user.guestPreferences.filters;
            if(!filters.shoppableFilter && !filters.instagramFilter && !filters.youtubeFilter && !filters.locationFilter){
                filters['allFilter'] = true;
            }
        }
        const plateformsState = this.getPlatformsDefaultState(this.user.contentPlatforms);
        let add = null;
        if(this.props.match.params.hasOwnProperty("contentPlatformId")){
            add = this.props.match.params.contentPlatformId;
        }

        let { previewFeed, previewFeedLastKey, showSearchTerms } = this.store.getState();
        let contentLoaded = true;
        if(previewFeed === null){
            previewFeed = [];
            contentLoaded = false;
        }

        const platformWizard = localStorage.getItem("preview_platform_wizard");
        const authCode = helper.getParam("code");
        let instagramModal = false;
        let youtubeModal = false;
        if(platformWizard === "instagram"){
            instagramModal = (authCode && authCode !== "null");
        }
        if(platformWizard === "youtube"){
            youtubeModal = (authCode && authCode !== "null");
        }


        //From Dashboard Home Component
        const openModal = (this.user.userPreferences.dashboardTourComplete === false && this.user.userPreferences.showTours === true);
        this.onboardingParam = helper.getParam("onboarding");
        this.isLoginParam = helper.getParam("login");
        if(this.isLoginParam && this.isLoginParam !== "null"){
            this.onboardingParam = this.isLoginParam;
        }

        let instagramApiModal = false;
        if(this.onboardingParam !== "null" && openModal === false){
            instagramApiModal = true;
        }
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(allowInstagram === this.user.userId){
            instagramApiModal = false;
        }
        if((this.user.userPreferences.hasOwnProperty("showInstagram") && !this.user.userPreferences.showInstagram) || plateformsState.hasInstagramLinkProfile){
            instagramApiModal = false;
        }
        
        let youtubeApiModal = false;
        const allowYoutube = Cookie.read("platform_wizard_youtube");
        if(this.onboardingParam !== "null" && instagramApiModal === false && openModal === false && allowYoutube !== this.user.userId){
            youtubeApiModal = true;
        }
        if((this.user.userPreferences.hasOwnProperty("showYouTube") && !this.user.userPreferences.showYouTube)  || plateformsState.hasYoutubeApiProfile){
            youtubeApiModal = false;
        }

        let linktreePromptModal = false;
        const allowLinktree = Cookie.read("platform_wizard_linktree");
        if(this.onboardingParam !== "null" && instagramApiModal === false && youtubeApiModal === false && openModal === false && allowLinktree !== this.user.userId){
            linktreePromptModal = true;
        }
        if((this.user.userPreferences.hasOwnProperty("showLinktree") && !this.user.userPreferences.showLinktree) || plateformsState.hasLinktreeProfile){
            linktreePromptModal = false;
        }

        const socialIcons = Api.getSocialIcons();

        this.state = {
            values: {
                q: (query !== "null" ? query: ""),
                radius: '1',
                radiusType: 'Mi',
                viewType: viewType,
                filterTypes: filterTypes,
                location: "",
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
                pageSize: 20,
                ...filters,
            },
            userLocation: {
                latitude: 0.0,
                longitude: 0.0,
            },
            loading: false,
            response: [],
            cancelToken: null,
            contentLoaded: contentLoaded,
            showFilter: false,
            showAdvancedSearch: false,
            showModal: false,
            currentContent: false, 
            currentKey: 0,
            defaultFilters: !authorized,
            featuredLinks: true,
            contentFeedResponse: previewFeed,
            slidePosition: "left",
            userSearched: (query !== "null" ? true : false),
            featuredLastKey: previewFeedLastKey,
            featuredLoadingMore: false,
            showClearSearch: false,
            searchTerms: [],
            affiliateMode: false,
            platforms: platforms,
            isOptionsOpen: false,
            hasFilterChange: false,
            hasOpenFilter: false,
            openStatus: false,
            startSearch: false,
            user: this.user,
            showSearchTerms: showSearchTerms,
            addContent: parseInt(add),
            ...plateformsState,
            instagramModal: instagramModal,
            youtubeModal: youtubeModal,
            linktreeModal: false,
            refresh: this.props.refresh,
            instagramApiModal: instagramApiModal,
            youtubeApiModal: youtubeApiModal,
            linktreePromptModal: linktreePromptModal,
            adButtonModal: false,
            editSocialIcons: false,
            socialIconValues: Object.assign({}, this.user),
            socialIcons: socialIcons,
            remainingSocialIcons: socialIcons,
            selectedSocialIcons: socialIcons,
            sortableSocialIcons: this.prepareSortableSocialIcons(this.user.socialIcons),
            socialIconsValidation: {
                instagramUrl: "",
                facebookUrl: "",
                pinterestUrl: "",
                tiktokUrl: "",
                youtubeUrl: "",
                contactEmail: "",
                twitterUrl: "",
                linkedinUrl: "",
                snapchatUrl: "",
                soundcloudUrl: "",
                whatsappNumber: "",
                twitchUrl: "",
                patreonUrl: "",
                spotifyUrl: "",
                amazonLiveUrl: "",
                amazonShopUrl: "",
                appleMusicUrl: "",
                itunesUrl: "",
                googlePlayUrl: "",
                mediumUrl: "",
                likeToKnowItUrl: "",
            },
        }

        this.onSearchChange = this.onSearchChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleViewOptions = this.handleViewOptions.bind(this);
        this.handleFilterOptions = this.handleFilterOptions.bind(this);
        this.handleFilterOption = this.handleFilterOption.bind(this);
        this.onSearchResultOpen = this.onSearchResultOpen.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.loadFeed = this.loadFeed.bind(this);
        this.onFeaturedScroll = this.onFeaturedScroll.bind(this);
        this.onAffiliateModalClose = this.onAffiliateModalClose.bind(this);
        this.filterOptionsOnSearch = this.filterOptionsOnSearch.bind(this);
        this.onEnterSubmit = this.onEnterSubmit.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onPhotoResize = this.onPhotoResize.bind(this);
        this.onPlatformWizardClose = this.onPlatformWizardClose.bind(this);
        this.onPlatformWizardSuccess = this.onPlatformWizardSuccess.bind(this);
        this.handleSocialIcon = this.handleSocialIcon.bind(this);
        this.onSocialIconsSort = this.onSocialIconsSort.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);

        this.feedInterval = null;
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        window.removeEventListener("scroll", this.onFeaturedScroll);

        if(this.feedInterval !== null){
            clearInterval(this.feedInterval);
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            user: props.user,
            values: {
                ...this.state.values,
                viewType: this.getViewType(props.user),
            },
            socialIconValues: Object.assign({}, props.user),
        });
    }
    // static getDerivedStateFromProps(props, state) {
    //     return {
    //         user: props.user,
    //         values: {
    //             ...state.values,
    //             viewType: _that.getViewType(props.user),
    //         }
    //     };
    // }
    componentDidMount(){
        const query = this.state.values.q;
        if(query && query.trim().length > 0){
            this.searchCatalog();
        }else{
            const { previewFeed } = this.store.getState();
            if(previewFeed === null){
                this.loadFeed();
            }

            // this.loadFeed();
        }
        window.addEventListener("scroll", this.onFeaturedScroll);

        // this.feedInterval = setInterval(() => {
        //     this.loadFeed();
        // }, 5000);
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.startSearch === true && this.state.userSearched === false){
            this.searchCatalog();
        }
    }
    getViewType(user){
        let viewType = 1;
        if(user.hasOwnProperty("guestPreferences")){
            const userPref = user.guestPreferences;
            const defaultViewType = userPref.defaultLayout;
            viewType = 1;
            if(defaultViewType === "stream"){
                viewType = 0;
            }else if(defaultViewType === "list"){
                viewType = 1;
            }else if(defaultViewType === "grid"){
                viewType = 2;
            }else if(defaultViewType === "threecolumngrid"){
                viewType = 3;
            }else if(defaultViewType === "card"){
                viewType = 4;
            }
        }
        return viewType;
    }
    onFeaturedScroll(){
        const viewMore = document.getElementById("featuredContentViewMore");
        if(!viewMore){
            return;
        }
        if(!this.state.featuredLinks){
            return;
        }
        let scrollPosition = document.documentElement.scrollTop;
        if(scrollPosition <= 0){
            scrollPosition = document.body.scrollTop;
        }

        let elementPostionY = 0;
        if (typeof (viewMore.offsetParent) != 'undefined') {
            elementPostionY = viewMore.offsetTop;
        } else {
            elementPostionY = viewMore.y;
        }

        if (elementPostionY <= scrollPosition + (window.innerHeight - 100)) {
            this.loadFeed(true);
        }
    }
    clearContents(e){
        if(!this.state.values.q){
            return null;
        }

        this.clearSearch();
    }
    handleSubmit(e){
        e.preventDefault();
        this.setState({startSearch: true});
        this.searchCatalog();
    }
    onEnterSubmit(e){
        e.preventDefault();
        if(e.nativeEvent.keyCode === 13){
            this.setState({startSearch: true, openStatus: false, isOptionsOpen: false});
            this.searchCatalog();
        }
    }
    handleChange(event, name) {
        this.setState({
            values:{
                ...this.state.values,
                [name]: event.target.value
            },
            userSearched: false,
        });
    };
    handleLocationChange(location, userLocation = false){
        let userLocationObj = this.state.userLocation;
        if(userLocation === true){
            userLocationObj = {
                latitude: location.latitude,
                longitude: location.longitude,
            }
        }
        this.setState({
            values: {
                ...this.state.values,
                googlePlacesId: location.googlePlacesId,
                latitude: location.latitude,
                longitude: location.longitude,
                location: location.location,
                googlePlacesResponse: location
            },
            userLocation: userLocationObj,
            userSearched: !this.state.showAdvancedSearch
        });
    }
    toggleAdvancedSearch(){
        this.setState({
            showAdvancedSearch: (!this.state.showAdvancedSearch),
            userSearched: false
        });
    }
    toggleFilter(){
        if(this.state.showFilter){
            const { contentFeedResponse, featuredLastKey, user } = this.state;
            this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, false);
            return;
        }
        this.setState({
            // values: {
            //     ...this.state.values,
            //     shoppableFilter: false,
            //     linksFilter: false,
            //     instagramFilter: false,
            //     youtubeFilter: false,
            //     locationFilter: false,
            //     allFilter: true
            // },
            showFilter: (!this.state.showFilter),
            showAdvancedSearch: false,
            hasOpenFilter: (!this.state.hasOpenFilter),
        });
    }
    searchCatalog(){
        const { values } = this.state;
        const { googlePlacesResponse } = values;
        let radius = values.radius;
        if(values.radiusType === "Mi"){
            // radius = parseInt(radius)*1.60934;
        }
        let searchData = {
            q: values.q.replace("All Topics",""),
            u: this.state.user.userId,
        }

        //Advanced Filters
        if(values.filterTypes.includes(0)){
            searchData['featured'] = true;
        }

        if(values.filterTypes.includes(1)){
            searchData['links'] = true;
        }

        if(values.filterTypes.includes(2)){
            searchData['lists'] = true;
        }


        // new search params
        
        let ctArray = [];
        if(values.youtubeFilter === true){
            ctArray.push("youtube");
        }
        if(values.instagramFilter === true){
            ctArray.push("instagram_post");
        }
        if(values.linksFilter === true){
            ctArray.push("link");
        }
        if(ctArray.length > 0){
            searchData['ct'] = ctArray.join(',');
        }

        if(values.shoppableFilter === true){
            searchData['shopping'] = true;
        }

        if(this.state.showAdvancedSearch){
            searchData['r'] = radius;
            searchData['rt'] = values.radiusType.toLowerCase();
            if(googlePlacesResponse.latitude !== 0.0){
                searchData['sl'] = googlePlacesResponse.latitude+","+googlePlacesResponse.longitude;

                const southEastAngle = 135;
                const northWestAngle = 315;
                let meterRadius = values.radius;
                if(values.radiusType === "Mi"){
                    meterRadius = parseInt(radius)*1.60934;
                }
                var myLatLng = new window.google.maps.LatLng(googlePlacesResponse.latitude,googlePlacesResponse.longitude);
                const southEast = helper.computeOffset(myLatLng, meterRadius, southEastAngle);
                const northWest = helper.computeOffset(myLatLng, meterRadius, northWestAngle);
                searchData['b'] = ["'"+northWest.lat()+","+northWest.lng()+"'","'"+southEast.lat()+","+southEast.lng()+"'"];
            }

            // const bounds = getBoundsOfDistance(
            //     { latitude: googlePlacesResponse.latitude, longitude: googlePlacesResponse.longitude },
            //     radius
            // );
            // const topBounds = bounds[0];
            // const bottomBounds = bounds[1];
            // searchData['b'] = ["'"+topBounds.latitude+","+topBounds.longitude+"'","'"+bottomBounds.latitude+","+bottomBounds.longitude+"'"];
        }

        const userLocation = this.state.userLocation;
        if(userLocation !== 0.0){
            searchData['ul'] = userLocation.latitude+","+userLocation.longitude;
        }
        history.push("/dashboard/editmode/search?q="+searchData['q']);
        
        const ApiUrl = Config.getApiUrl()+"data/search?"+helper.serialize(searchData);
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({
            cancelToken: source,
            loading: true,
            response: [],
            contentLoaded: false,
            featuredLinks: false,
            userSearched: true,
            startSearch: false
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            that.updateResponse(res.data.response);
            setTimeout(function(){
                helper.checkElementInViewPort();
            },500);
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                that.updateResponse([]);
            }            
        });
    }
    updateResponse(response){
        let values = {
            ...this.state.values
        }
        if(this.state.hasFilterChange === false){
            values = {
                ...this.state.values,
                allFilter: true
            }
        }
        this.setState({
            response: response,
            loading: false,
            contentLoaded: true,
            cancelToken: null,
            showClearSearch: true,
            showFilter: true,
            // userSearched: false,
            values: values
        });
    }
    goBack(){
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/user/home"} />
        }
    }
    handleViewOptions(param, key){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        if(authorized){
            user.userPreferences.searchViewResults = "stream";
            if(key === 1){
                user.userPreferences.searchViewResults = "list";
            }
            if(key === 2){
                user.userPreferences.searchViewResults = "grid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 4){
                user.userPreferences.searchViewResults = "card";
            }
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }

        this.setState({
            values: {
                ...this.state.values,
                viewType: key,
                cancelToken: cancelToken
            }
        });

        setTimeout(function(){
            helper.checkElementInViewPort();
        },500);
    }
    handleFilterOption(event, name){
        let { values } = this.state;
        if(name === "allFilter" && values.allFilter === true){
            return;
        }
        if(name === "allFilter" && values.allFilter === false){
            this.setState({
                values: {
                    ...this.state.values,
                    shoppableFilter: false,
                    linksFilter: false,
                    instagramFilter: false,
                    youtubeFilter: false,
                    locationFilter: false,
                    [name]: !values[name],
                },
                hasFilterChange: true,
                showAdvancedSearch: false,
            })
        }else{
            values = {
                ...this.state.values,
                allFilter: false,
                [name]: !values[name],
            };
            const state = {
                values: values,
                hasFilterChange: true,
            };
            if(values.shoppableFilter === false && values.linksFilter === false && values.instagramFilter === false 
                && values.youtubeFilter === false && values.locationFilter === false){
                state.values.allFilter = true;
            }
            this.setState(state);
        }
        
        if(this.userUpdateTimeout !== null){
            clearTimeout(this.userUpdateTimeout);
        }
        this.userUpdateTimeout = setTimeout(() => {
            this.userUpdateTimeout = null;
            values = this.state.values;
            const user = this.state.user;
            if(values.allFilter){
                user.guestPreferences['filters'] = {
                    shoppableFilter: false,
                    linksFilter: false,
                    instagramFilter: false,
                    youtubeFilter: false,
                    locationFilter: false,
                };
            }else{
                user.guestPreferences['filters'] = {
                    shoppableFilter: values.shoppableFilter,
                    linksFilter: values.linksFilter,
                    instagramFilter: values.instagramFilter,
                    youtubeFilter: values.youtubeFilter,
                    locationFilter: values.locationFilter,
                };
            }

            const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
            this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms);
        }, 3000);
    }
    handleFilterOptions(param, filterTypes){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        if(authorized){
            user.userPreferences.searchFilterLinks = false;
            user.userPreferences.searchFilterInstagram = false;
            user.userPreferences.searchFilterYoutube = false;
            if(filterTypes.indexOf(1) !== -1){
                user.userPreferences.searchFilterLinks = true;
            }
            if(filterTypes.indexOf(2) !== -1){
                user.userPreferences.searchFilterInstagram = true;
            }
            if(filterTypes.indexOf(3) !== -1){
                user.userPreferences.searchFilterYoutube = true;
            }
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }
        
        
        this.setState({
            values: {
                ...this.state.values,
                filterTypes: filterTypes,
                cancelToken: cancelToken
            },
            defaultFilters: false
        })
    }
    getSearchResults(){
        if(this.state.featuredLinks === true){
            return this.state.contentFeedResponse;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        let searchHits = [];
        const filterTypes = this.state.values.filterTypes;
        const defaultFilters = this.state.defaultFilters;
        data.response.hit.map(hit => {
            if(hit.hasOwnProperty("content")){
                if(!defaultFilters && filterTypes.includes(0) === true && hit.content.isFeatured !== true){
                    //return null;
                }
                searchHits.push(hit.content);
            }
            return null;
        });
        return searchHits;
    }
    filterResults(searchResults){
        const { user } = this.store.getState();
        let userPreferences = user.userPreferences;
        if(userPreferences.searchFilterLinks || userPreferences.searchFilterInstagram || userPreferences.searchFilterYoutube){
            let searchResultsArray = [];
            searchResults.map((searchResult, key) => {
                if(searchResult.hasOwnProperty("type")){
                    if(
                        (userPreferences.searchFilterLinks && searchResult.type === "link") ||
                        (userPreferences.searchFilterInstagram && searchResult.type === "instagram_post") || 
                        (userPreferences.searchFilterYoutube && searchResult.type === "youtube")
                    ){
                        searchResultsArray[key] = searchResult;
                    }
                }
                return null;
            });
            return searchResultsArray;
        }        
        return searchResults;
    }
    filterPlatformResults(searchResults){
        const { user } = this.state;
        let guestPreferences = user.guestPreferences;
        if(!guestPreferences.hasOwnProperty("contentPlatforms")){
            guestPreferences['contentPlatforms'] = [];
        }
        const { contentPlatforms } = guestPreferences;
        if(contentPlatforms.length <= 0){
            return [];
        }
        let platforms = [];
        if(user.hasOwnProperty("contentPlatforms")){
            platforms = user.contentPlatforms;
        }
        let searchResultsArray = [];
        searchResults.map((searchResult, key) => {
            const contentPlatform = platforms.find(x => x.contentPlatformId === searchResult.contentPlatformId);
            if(typeof(contentPlatform) === "object" && ((contentPlatform.hasOwnProperty("isActive") && !contentPlatform.isActive)
             || (contentPlatform.hasOwnProperty("isDeleted") && contentPlatform.isDeleted))){
                return null;
            }
            if(
                (searchResult.hasOwnProperty("isDeleted") && searchResult.isDeleted) ||
                (searchResult.hasOwnProperty("isSearchable") && !searchResult.isSearchable)
            ){
                return null;
            }

            if(searchResult.hasOwnProperty("contentPublishedDateTime") && searchResult.contentPublishedDateTime.length > 0){
                const startDiff = new Date(searchResult.contentPublishedDateTime) - new Date();
                if(startDiff > 0){
                    return null;
                }
            }
            if(searchResult.hasOwnProperty("contentExpiredDateTime") && searchResult.contentExpiredDateTime.length > 0){
                const startDiff = new Date(searchResult.contentExpiredDateTime) - new Date();
                if(startDiff < 0){
                    return null;
                }
            }
            
            if(contentPlatforms.indexOf(searchResult.contentPlatformId) !== -1){
                searchResultsArray[key] = searchResult;
            }
            return null;
        });
        return searchResultsArray;
    }
    renderResults(searchResults){
        // const { authorized } = this.store.getState();
        // const authorizedUser = this.store.getState().user;
        // var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        // if(showBetaFeatures){
        //     searchResults = this.filterResults(searchResults);
        // }

        searchResults = this.filterPlatformResults(searchResults);

        const { hasWebLinkProfile, hasInstagramLinkProfile, hasYoutubeApiProfile, webLinkPlatform } = this.state;
        const platformsData = {
            hasWebLinkProfile: hasWebLinkProfile,
            webLinkPlatform: webLinkPlatform,
            hasInstagramLinkProfile: hasInstagramLinkProfile,
            hasYoutubeApiProfile: hasYoutubeApiProfile
        };

        let view = null;
        switch(this.state.values.viewType){
            case 0:
                view = <StreamView platformLinks={true} user={this.state.user} store={this.store} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} onPhotoResize={this.onPhotoResize} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData}
                onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} />
                break;
            case 1:
                view = <ListView platformLinks={true} user={this.state.user} store={this.store} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} onPhotoResize={this.onPhotoResize} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData}
                onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} />
                break;
            case 2:
                view = <GridView platformLinks={true} user={this.state.user} store={this.store} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} onPhotoResize={this.onPhotoResize} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData}
                onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} />
                break;
            case 3:
                view = <ThreeColumnGrid platformLinks={true} user={this.state.user} store={this.store} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} onPhotoResize={this.onPhotoResize} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData}
                onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} />
                break;
            case 4:
                view = <StreamViewCard platformLinks={true} user={this.state.user} response={searchResults} onOpen={this.onSearchResultOpen} store={this.store} platforms={this.state.platforms} primaryColor={this.props.primaryColor} onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData} onPhotoResize={this.onPhotoResize} />
                break;
            default:
                view = <StreamView platformLinks={true} user={this.state.user} store={this.store} response={searchResults} onOpen={this.onSearchResultOpen} primaryColor={this.props.primaryColor} onPhotoResize={this.onPhotoResize} 
                onAddPlatform={(type) => this.onAddPlatform(type)} onPlatformClose={(name) => this.onPlatformClose(name)} onLinkClick={(platform) => this.onPlatformSelect(platform)} platformsData={platformsData}
                onEdit={(key,newContent) => this.onEdit(key,newContent)} onDelete={(key, content) => this.onDelete(key, content)} />
                break;
        }
        return view;
    }
    allowDisclose(currentContent){
        if(
            (currentContent.isSponsored && (currentContent.hasOwnProperty("url") && currentContent.url.trim().length > 0)) ||
            (currentContent.isSponsored && (currentContent.hasOwnProperty("affiliateUrl") && currentContent.affiliateUrl.trim().length > 0))
        ){
            return true;
        }
        if(currentContent.hasOwnProperty("discountCode") && currentContent.discountCode.trim().length > 0){
            return true;
        }
        return false;
    }
    sendClickActivity(content, url, type = null){
        var requestData = [];
        let activityType = "content_url_clicked";
        if(type === "secondary"){
            activityType = "content_affiliate_clicked";
        }
        requestData.push({
            'activityType': activityType,
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_viewed",
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_clicked",
            'contentId': content.contentId
        });
        Api.sendActivity(requestData);
    }
    onSearchResultOpen(show = true, currentContent = false, currentKey = 0, button = false){
        // if(currentContent){
        //     if(this.state.user.guestPreferences.linkBehavior === "clickThrough" || button){
        //         if(this.allowDisclose(currentContent)){
        //             this.setState({affiliateMode: true, currentContent: currentContent, currentKey: currentKey});
        //         }else if(currentContent.url || currentContent.affiliateUrl){
        //             if(currentContent.hasOwnProperty("affiliateUrl") && currentContent.affiliateUrl.length > 0){
        //                 this.sendClickActivity(currentContent, currentContent.affiliateUrl, "secondary");
        //                 window.open(currentContent.affiliateUrl,"_blank");
        //             }else{
        //                 this.sendClickActivity(currentContent, currentContent.url);
        //                 window.open(currentContent.url,"_blank");
        //             }
        //         }
        //         return;
        //     }
        // }
        // this.setState({showModal: show, currentContent: currentContent, currentKey: currentKey});
        // let htmlOverflow = "";
        // if(show === true){
        //     htmlOverflow = "overflow_hidden";
        //     let scrollPosition = document.documentElement.scrollTop;
        //     if(scrollPosition <= 0){
        //         scrollPosition = document.body.scrollTop;
        //     }
        //     document.documentElement.setAttribute("data-scroll", scrollPosition);
        // }
        // setTimeout(function(){
        //     document.documentElement.className = htmlOverflow;
        //     document.body.scrollTop = 0;
        // },(show?300:0));
        // if(show === false && this.props.onPopupClose){
        //     this.props.onPopupClose();
        // }
        // if(show === false && document.documentElement.hasAttribute("data-scroll")){
        //     setTimeout(function(){
        //         document.body.scrollTop = 0;
        //         const scrollPositionSaved = parseFloat(document.documentElement.getAttribute("data-scroll"));
        //         window.scrollTo(0,scrollPositionSaved);
        //         document.documentElement.removeAttribute("data-scroll");
        //     },5);
        // }
        
    }
    getCoverPhoto(content){
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlMain;
    }
    loadFeed(viewMore = false){
        const { user, searchedUser } = this.store.getState();
        if(user.username.toLowerCase() !== this.state.user.username.toLowerCase() && searchedUser === false){
            return;
        }
        const requestData = {
            pageSize: 12,
            feed: true
        };
        if(viewMore && this.state.featuredLastKey !== null){
            requestData['startKey'] = this.state.featuredLastKey;
        }

        const ApiUrl = Config.getApiUrl()+"data/content/user/"+this.state.user.userId+"?"+helper.serialize(requestData);
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source, featuredLinks: true, loading: (!viewMore), featuredLoadingMore: viewMore});
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            const data = res.data;
            let lastKey = null;
            if(data.hasOwnProperty("lastKey")){
                lastKey = data.lastKey;
            }
            
            // const { previewFeed } = this.store.getState();
            const oldResponse = (viewMore ? that.state.contentFeedResponse : []);
            const response = oldResponse.concat(data.response);
            // if(previewFeed === null){
            //     this.store.dispatch({type: 'UPDATE_STATE',state: {
            //         previewFeed: response,
            //         previewFeedLastKey: lastKey
            //     }});
            //     this.history.push("/dashboard/editmode/search");
            // }else{
                that.setState({
                    contentFeedResponse: response,
                    loading: false,
                    contentLoaded: true,
                    cancelToken: null,
                    featuredLastKey: lastKey,
                    featuredLoadingMore: false
                });
            // }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                that.setState({
                    contentFeedResponse: [],
                    loading: false,
                    contentLoaded: true,
                    cancelToken: null
                });
            }            
        });
    }
    clearSearch(){
        if(this.state.hasOpenFilter === true){
            let showAdvancedSearch = false;
            if(this.state.showAdvancedSearch === true){
                showAdvancedSearch = true;
            }
            this.setState({
                values: {
                    ...this.state.values,
                    q: ""
                },
                showFilter: true,
                showAdvancedSearch: showAdvancedSearch,
                contentLoaded: true,
                response: [],
                featuredLinks: true,
                showClearSearch: false,
                userSearched: false,
                startSearch: false
            });
            history.push("/"+this.state.user.username);

            return;
        }
        this.setState({
            values: {
                ...this.state.values,
                shoppableFilter: false,
                linksFilter: false,
                instagramFilter: false,
                youtubeFilter: false,
                locationFilter: false,
                allFilter: true,
                q: ""
            },
            showFilter: false,
            showAdvancedSearch: false,
            contentLoaded: true,
            response: [],
            featuredLinks: true,
            showClearSearch: false,
            userSearched: false,
            startSearch: false
        });
        history.push("/"+this.state.user.username);
    }
    sendActivity(content){
        var requestData = [];
        requestData.push({
            'activityType': "content_viewed",
            'contentId': content.contentId
        });
        requestData.push({
            'activityType': "content_clicked",
            'contentId': content.contentId
        });
        Api.sendActivity(requestData);
    }
    onEdit(key, newContent, buttons = false){
        if(newContent === null){
            return;
        }
        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        if(buttons){
            const { user } = this.props;
            let buttons = [];
            if(user.hasOwnProperty("buttons")){
                buttons = user.buttons;
            }
            buttons[key] = newContent;
            user['buttons'] = buttons;
            this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms, true);
            return;
        }
        if(this.state.featuredLinks === true){
            contentFeedResponse[key] = newContent;
            this.setState({contentFeedResponse: contentFeedResponse});
            return;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        const searchHits = data.response.hit;
        searchHits.map((hit, newKey) => {
            if(hit.hasOwnProperty("content") && hit.content.contentId === newContent.contentId){
                searchHits[newKey].content = newContent;
            }
            return null;
        });

        const newResponse = this.state.response;
        newResponse[0].response.hit = searchHits;
        this.setState({response: newResponse});
    }
    onDelete(key, content, buttons = false){
        if(key === null){
            return;
        }
        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        if(buttons){
            const { user } = this.props;
            let buttons = [];
            if(user.hasOwnProperty("buttons")){
                buttons = user.buttons;
            }
            buttons.splice(key,1);
            user['buttons'] = buttons;
            setTimeout(() => {
                this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms, true);
            },500);
            return;
        }
        if(this.state.featuredLinks === true){
            contentFeedResponse.splice(key,1);
            this.setState({contentFeedResponse: contentFeedResponse});
            return;
        }
        if(this.state.response.length <= 0){
            return null;
        }
        const data = this.state.response[0];
        if(data.response.found <= 0){
            return null;
        }
        const searchHits = data.response.hit;
        searchHits.map((hit, newKey) => {
            if(hit.hasOwnProperty("content") && hit.content.contentId === content.contentId){
                searchHits.splice(newKey,1);
            }
            return null;
        });
        
        const newResponse = this.state.response;
        newResponse[0].response.hit = searchHits;
        this.setState({response: newResponse});
    }
    onSearchChange(event, value){
        if(value){
            this.setState({
                values :{
                    ...this.state.values,
                    q: value
                },
                startSearch: true,
                userSearched: false
            })
        }else{
            this.clearSearch();
        }
    }
    onSearchInputChange(e, value, name) {
        this.setState({
            values:{
                ...this.state.values,
                [name]: value
            },
            startSearch: false,
            userSearched: false
        });
        this.filterOptionsOnSearch(value);
    }
    filterOptionsOnSearch(value, returnStatus = false){
        const { searchTerms } = this.state;
        
        var filteredOptions = [];
        searchTerms.map((option) => {
            if(option.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                filteredOptions.push(option);
            }
            return null;
        });
        
        let isOptionsOpen = true;
        if(filteredOptions.length === 0){
            isOptionsOpen = false;
        }

        if(returnStatus){
            return isOptionsOpen;
        }

        this.setState({
            isOptionsOpen: isOptionsOpen,
            openStatus: isOptionsOpen
        })
    }
    onOpenOptions(e){
        const { q } = this.state.values;
        let status = true;
        if(q && q !== "null" && q.length > 0){
            status = this.filterOptionsOnSearch(q, true);
        }
        const { searchTerms } = this.state;
        if((typeof(searchTerms) === "object" && searchTerms.length <= 0) || (typeof(searchTerms) === "string" && searchTerms.length <= 0)){
            status = false;
        }
        this.setState({
            isOptionsOpen: status,
            openStatus: status
        })
    }
    onCloseOptions(e){
        this.setState({
            isOptionsOpen: false,
            openStatus: false
        })
    }
    onAffiliateModalClose(){
        this.setState({affiliateMode: false});
    }
    loadUserPlatforms(){
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source});
        Api.getPlatforms({}, source, this.state.user.userId).then(data => {
            that.setState({
                platforms: data.response,
            });
        });
    }
    getViewOptionsDropdown(){
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        let options = [
            <Button type="button" color="transparent" justIcon>
                <FeedViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <ListViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiViewGrid} className="MuiSvgIcon-root" />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiGrid} className="MuiSvgIcon-root" />
            </Button>
        ];
        if(showBetaFeatures){
            options.push(
                <Button type="button" color="transparent" justIcon>
                    <MaterialIcon path={mdiViewDashboard} className="MuiSvgIcon-root" />
                </Button>
            );
        }
        return options;
    }
    getPrimaryColor(primaryColor){
        const { palette } = this.props.theme;
        return palette.custom.main;
    }
    onBlurOpenOptions(e){
        this.setState({
            openStatus: true
        })
    }
    onSearchTermsModal(status = true){
        this.setState({showSearchTerms: status});
    }
    onSearchTermsModalSuccess(values){
        const valuesCopy = Object.assign({}, values);
        let regularTagsStr = valuesCopy.topSearchTerms.toString();
        valuesCopy.topSearchTerms = regularTagsStr;

        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        this.props.onUserUpdate({
            values: valuesCopy
        }, contentFeedResponse, featuredLastKey, showSearchTerms);

        this.setState({showSearchTerms: false});
    }
    hasLinktreePlatform(){
        const { user } = this.props;
        if(!user.hasOwnProperty("contentPlatforms") || typeof(user.contentPlatforms) !== "object"){
            return false;
        }
        let hasPlatform = false;
        user.contentPlatforms.map((platform) => {
            if(platform.type === "linktree"){
                hasPlatform = true;
            }
            return null;
        });
        return hasPlatform;
    }
    onAddPlatform(type = 'linktree'){
        localStorage.setItem("preview_redirect", "1");
        localStorage.setItem("preview_platform_wizard", type);
        this.setState({
            [type+"Modal"]: true
        });
    }
    onPlatformWizardClose(type){
        this.setState({
            [type+"Modal"]: false
        });
        history.push("/dashboard/editmode/search");
        localStorage.removeItem("preview_redirect");
        localStorage.removeItem("preview_platform_wizard");
    }
    onPlatformWizardSuccess(user, type){
        let state = {
            ...this.state,
            [type+"Modal"]: false,
            user: user
        };
        if(type === "youtube"){
            state['hasYoutubeApiProfile'] = true;
        }else if(type === "instagram"){
            state['hasInstagramLinkProfile'] = true;
        }else if(type === "linktree"){
            state['hasLinktreeProfile'] = true;
        }
        localStorage.removeItem("preview_redirect");
        localStorage.removeItem("preview_platform_wizard");
        const promptState = this.onPlatformWizardSuccessPrompt(state);
        state = {
            ...state,
            ...promptState
        };
        this.setState(state);
        history.push("/dashboard/editmode/search");
        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms);

        setTimeout(() => {
            this.onRefreshPage();
        }, 500);
    }
    onPlatformClose(name = 'showLinktree'){
        let { user } = this.props;
        user.userPreferences[name] = false;
        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, (showSearchTerms ? showSearchTerms : null));
    }
    getPlatformsDefaultState(platforms){
        const { match } = this.props;
        let contentPlatformId = null;
        if(match.params.hasOwnProperty("contentPlatformId")){
            contentPlatformId = match.params.contentPlatformId;
        }
        let defaultPlatform = {
            contentPlatformId: null
        };
        let hasInstagramProfile = false;
        let hasLinktreeProfile = false;
        let hasLinkinProfile = false;
        let hasInstagramLinkProfile = false;
        let hasYoutubeApiProfile = false;
        let hasButtonProfile = false;
        let hasWebLinkProfile = false;
        let webLinkPlatform = null;
        let instagramPlatform = null;
        let linktreePlatform = null;
        let instagramApiPlatform = null;
        let youtubeApiPlatform = null;
        let buttonPlatform = null;
        platforms.map(userPlatform => {
            if(userPlatform.type === "webLink"){
                hasWebLinkProfile = true;
                webLinkPlatform = userPlatform;
            }
            if(userPlatform.type === "instagram"){
                hasInstagramProfile = true;
                instagramPlatform = userPlatform;
            }
            if(userPlatform.type === "linktree"){
                hasLinktreeProfile = true;
                linktreePlatform = userPlatform;
            }
            if(userPlatform.type === "youtubeAPI"){
                hasYoutubeApiProfile = true;
                youtubeApiPlatform = userPlatform;
            }
            if(userPlatform.type === "linkinprofile"){
                hasLinkinProfile = true;
            }
            if(userPlatform.type === "instagramAPI"){
                hasInstagramLinkProfile = true;
                instagramApiPlatform = userPlatform;
            }
            if(userPlatform.type === "button"){
                hasButtonProfile = true;
                buttonPlatform = userPlatform;
            }
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });

        const state = {
            platforms: platforms,
            hasInstagramProfile: hasInstagramProfile,
            hasLinktreeProfile: hasLinktreeProfile,
            hasLinkinProfile: hasLinkinProfile,
            hasYoutubeApiProfile: hasYoutubeApiProfile,
            hasButtonProfile: hasButtonProfile,
            hasWebLinkProfile: hasWebLinkProfile,
            platform: defaultPlatform,
            webLinkPlatform: webLinkPlatform,
            instagramPlatform: instagramPlatform,
            hasInstagramLinkProfile: hasInstagramLinkProfile,
            linktreePlatform: linktreePlatform,
            instagramApiPlatform: instagramApiPlatform,
            youtubeApiPlatform: youtubeApiPlatform,
            buttonPlatform: buttonPlatform
        };
        return state;
    }
    onPlatformSelect(platform){
        this.setState({platform: platform, type: "", addContent: 1});
        history.push("/dashboard/editmode/search/"+platform.contentPlatformId);
    }
    onWizardClose(platform, content, afterCreate = false, afterEdit = false, afterDelete = false){
        const { user } = this.props;
        
        if(afterCreate === true){
            content.ownerId = user.userId;
            let state = {
                addContent: 0,
                contentId: null,
                editMode: false,
                lastKey: null,
                contentLoaded: true,
            };
            const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
            if(platform.type === "button"){
                let buttons = [];
                if(user.hasOwnProperty("buttons")){
                    buttons = user.buttons;
                }
                user['buttons'] = [
                    content,
                    ...buttons
                ];
                user.userPreferences['onboarding']['step3Complete'] = true;
                this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms);
            }else{
                state['contentFeedResponse'] = [
                    content,
                    ...this.state.contentFeedResponse,
                ];
                this.props.onUserUpdate({values: user}, state.contentFeedResponse, featuredLastKey, showSearchTerms);
            }
            this.setState(state);
            if(this.state.contentFeedResponse <= 0){
                this.loadFeed();  
            }
        }else{
            this.setState({
                addContent: 0,
                contentId: null,
                editMode: false,
                lastKey: null,
                contentLoaded: true,
            });
            this.loadFeed();
        }
        history.push("/dashboard/editmode/search");
    }
    onPhotoResize(newContent, type = null){
        if(type !== null){
            const { user } = this.props;
            let buttons = [];
            if(user.hasOwnProperty("buttons")){
                buttons = user.buttons;
            }
            buttons.map((content, key) => {
                if(content.contentId === newContent.contentId){
                    buttons[key] = newContent;
                }
                return null;
            });
            user['buttons'] = buttons;
            const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
            this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms);
            return;
        }
        const response = this.state.contentFeedResponse;
        response.map((content, key) => {
            if(content.contentId === newContent.contentId){
                response[key] = newContent;
            }
            return null;
        });
        this.setState({contentFeedResponse: response});
    }
    onRefreshPage(){
        //Get Logged in user data
        this.setState({refresh: true});
        this.loadFeed();
        AuthApi.validateToken().then((data) => {
            this.setState({refresh: false});
            if(typeof(data) === "object" && data.numberOfResponses > 0){
                const newUser = Api.prepareMemberObject(data.response[0]);
                const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
                this.props.onUserUpdate({values: newUser}, contentFeedResponse, featuredLastKey, showSearchTerms, true);
            }
        }).catch(err => {
            this.setState({refresh: false});
        });
    }
    handleInstagramModal(open){
        const { user, hasYoutubeApiProfile, hasLinktreeProfile } = this.state;
        if(open === null){
            Cookie.duration = 1;
            Cookie.write("platform_wizard_instagram", user.userId);
            Cookie.duration = false;
        }
        if(open === false || open === null){
            const allowYoutube = Cookie.read("platform_wizard_youtube");
            const allowLinktree = Cookie.read("platform_wizard_linktree");
            if(allowYoutube !== user.userId && (!user.userPreferences.hasOwnProperty("showYouTube") || user.userPreferences.showYouTube)){
                this.setState({ instagramApiModal: false, youtubeApiModal: (!hasYoutubeApiProfile) });
                return;
            }else if(allowLinktree !== user.userId && (!user.userPreferences.hasOwnProperty("showLinktree") || user.userPreferences.showLinktree)){
                this.setState({ instagramApiModal: false, linktreePromptModal: (!hasLinktreeProfile) });
            }
            this.setState({ instagramApiModal: false });
            return;
        }
        this.setState({ instagramApiModal: false, instagramModal: true });
        localStorage.setItem("preview_redirect", "1");
        localStorage.setItem("preview_platform_wizard", "instagram");
    }
    handleYoutubeModal(open){
        const { user, hasLinktreeProfile } = this.state;
        if(open === null){
            Cookie.duration = 1;
            Cookie.write("platform_wizard_youtube", user.userId);
            Cookie.duration = false;
        }
        if(open === false || open === null){
            const allowLinktree = Cookie.read("platform_wizard_linktree");
            if(allowLinktree !== user.userId && (!user.userPreferences.hasOwnProperty("showLinktree") || user.userPreferences.showLinktree)){
                this.setState({ instagramApiModal: false, youtubeApiModal: false, linktreePromptModal: (!hasLinktreeProfile) });
                return;
            }
            this.setState({ instagramApiModal: false, youtubeApiModal: false });
            return;
        }
        this.setState({ youtubeApiModal: false, youtubeModal: true });
        localStorage.setItem("preview_redirect", "1");
        localStorage.setItem("preview_platform_wizard", "youtube");
    }
    handleLinktreeModal(open){
        const { user } = this.store.getState();
        if(open === null){
            Cookie.duration = 1;
            Cookie.write("platform_wizard_linktree", user.userId);
            Cookie.duration = false;
        }
        if(open === false || open === null){
            this.setState({ linktreeModal: false, linktreePromptModal: false });
            return;
        }
        this.setState({ linktreePromptModal: false, linktreeModal: true });
        localStorage.setItem("preview_redirect", "1");
        localStorage.setItem("preview_platform_wizard", "linktree");
    }
    onPlatformWizardSuccessPrompt(oldState){
        const { user, hasInstagramLinkProfile, hasYoutubeApiProfile, hasLinktreeProfile } = oldState;
        let state = {};
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        const allowYoutube = Cookie.read("platform_wizard_youtube");
        const allowLinktree = Cookie.read("platform_wizard_linktree");
        if(!hasInstagramLinkProfile && allowInstagram !== user.userId && (!user.userPreferences.hasOwnProperty("showInstagram") || user.userPreferences.showInstagram)){
            state['instagramApiModal'] = true;
        }else if(!hasYoutubeApiProfile && allowYoutube !== user.userId && (!user.userPreferences.hasOwnProperty("showYouTube") || user.userPreferences.showYouTube)){
            state['youtubeApiModal'] = true;
        }else if(!hasLinktreeProfile && allowLinktree !== user.userId && (!user.userPreferences.hasOwnProperty("showLinktree") || user.userPreferences.showLinktree)){
            state['linktreePromptModal'] = true;
        }
        return state;
    }
    onAdButtonClick(e){
        e.preventDefault();
        this.setState({adButtonModal: true});
    }
    onAdButtonModalClose(){
        this.setState({adButtonModal: false});
    }
    onButtonsSort(buttons){
        const { user } = this.props;
        user['buttons'] = buttons;
        const { contentFeedResponse, featuredLastKey, showSearchTerms } = this.state;
        this.props.onUserUpdate({values: user}, contentFeedResponse, featuredLastKey, showSearchTerms);
        setTimeout(() => {
            this.onRefreshPage();
        }, 100);
    }
    renderSocialIcon(type){
        const socialIconsValues = this.state.user.socialIcons;
        return <SocialIcon renderType="icon" type={type} socialIcons={socialIconsValues} />;
    }
    updateSocialIconsStatus(userSocialIcons = null, returnIcons = false){
        const { socialIcons } = this.state;
        const { user } = this.store.getState();
        if(userSocialIcons === null){
            userSocialIcons = user.socialIcons;
        }

        let socialIconsWithStatus = [];
        socialIcons.map((socialIcon) => {
            let status = false;
            let itemName = socialIcon.itemValue;
            if(userSocialIcons.hasOwnProperty(socialIcon.itemValue) === true && userSocialIcons[itemName] !== "" && userSocialIcons[itemName] !== null){
                status = true;
            }
            socialIconsWithStatus.push({
                itemValue: socialIcon.itemValue,
                itemLabel: socialIcon.itemLabel,
                status: status,
            });

            return null;
        });

        if(returnIcons){
            return socialIconsWithStatus;
        }

        this.setState({
            socialIcons: socialIconsWithStatus
        })

    }
    handleSocialIcon(event){
        let value = event.target.value;
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === value)[0];
        socialIcon.status = true;
        const values = {
            ...this.state.socialIconValues, 
            socialIcons: {
                ...this.state.socialIconValues.socialIcons,
                [socialIcon.itemValue]: null,
            }
        };

        this.setState({
            ...this.state,
            socialIconValues: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    renderSocialFields(){
        const { classes } = this.props;
        const { sortableSocialIcons } = this.state;
        let socialIconsRendered = [];
        sortableSocialIcons.map((socialIcon, key) => {
            if(socialIcon.id === "blogUrl"){
                return null;
            }
            socialIconsRendered.push(
                <li key={key}>
                    <span className={classes.sortIcon+" social_icons_sort"}><Menu /></span>
                    {this.renderSocialField(socialIcon.id)}
                </li>
            );
            return null;
        });
        return socialIconsRendered;
    }
    renderSocialField(type){
        const { socialIconValues, socialIconsValidation } = this.state;
        return <SocialIcon renderType="field" type={type} onClear={(name) => this.onSocialIconClear(name)} values={socialIconValues} socialIconsValidation={socialIconsValidation} handleLinkChange={(e, name) => this.handleLinkChange(e, name)} />;
    }
    renderSocialIconsField(){
        const { classes } = this.props;
        const { socialIcons, editSocialIcons, socialIconValues } = this.state;
        let socialIconsCopy = Object.assign({}, socialIconValues.socialIcons);
        if(socialIconsCopy.hasOwnProperty("blogUrl")){
            delete socialIconsCopy['blogUrl'];
        }
        const defaultOpen = (editSocialIcons && Object.keys(socialIconsCopy).length <= 0);
        return(
            <div className={classes.addSocialIcon}>
                <CustomSelectSocialIcons 
                    formControlProps={{
                        fullWidth: true
                    }}
                    selectProps={{
                        onChange: (e) => this.handleSocialIcon(e),
                        value: ""
                    }}
                    labelText={"+ Add Another Social icon"}
                    inputProps={{
                        name: "type",
                        id: "outlined-type",
                        className: classes.alignLeft,
                    }}
                    items={socialIcons}
                    itemLabel="itemLabel"
                    itemValue="itemValue"
                    defaultOpen={(defaultOpen)}
                    color={this.props.primaryColor}
                />
            </div>
        )
    }
    renderSocialIcons(){
        const socialIcons =  this.state.user.socialIcons;
        let socialIconsRendered = [];
        for (const socialIcon in socialIcons){
            const icon = this.renderSocialIcon(socialIcon);
            if(icon === null || socialIcon === "blogUrl"){
                continue;
            }
            socialIconsRendered.push(<li key={socialIcon}>
                {icon}
            </li>)
        }
        return socialIconsRendered;
    }
    onSocialIconsSort(socialIcons){
        const socialIconsOld = this.state.socialIconValues.socialIcons;
        let socialIconsNewOrder = {};
        socialIcons.map((socialIcon) => {
            socialIconsNewOrder[socialIcon.id] = socialIconsOld[socialIcon.id];
            return null;
        });
        if(socialIconsOld.hasOwnProperty("blogUrl")){
            socialIconsNewOrder['blogUrl'] = socialIconsOld.blogUrl;
        }
        this.setState({
            sortableSocialIcons: socialIcons,
            socialIconValues: {
                ...this.state.socialIconValues,
                socialIcons: socialIconsNewOrder
            }
        });
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        
        let domain = this.getDomainName(name);
        if((name === "tiktokUsername" || name === "mediumUrl") && value.substr(0,1) === "@"){
            value = value.substr(1);
        }
        try{
            new URL(value); 
        }catch(e){
            if((name !== "contactEmail" && name !== "whatsappNumber")  && 
                value.indexOf("http://") === -1 && value.indexOf("https://") === -1
            ){
                value = helper.prepareSocialUrl(domain,value)
            }
        }

        let domainUrl = helper.prepareSocialUrl(domain,'');
        if(value === domainUrl){
            value = null;
        }
        
        const values = {
            ...this.state.socialIconValues, 
            socialIcons: {
                ...this.state.socialIconValues.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            socialIconValues: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    prepareSortableSocialIcons(socialIcons){
        let sortableSocialIcons = [];
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            sortableSocialIcons.push({
                id: socialIcon,
                value: socialIcons[socialIcon],
                selected: false,
                chosen: false,
                filtered: false
            })
        }
        return sortableSocialIcons;
    }
    editSocialIcons(status = true){
        let state = {
            editSocialIcons: status,
            editName: false,
            editWebsite: false,
            editBio: false,
            editSearchTerms: false,
        };
        if(!status){
            const { user } = this.store.getState();
            state['socialIconValues'] = {
                ...this.state.socialIconValues,
                socialIcons: user.socialIcons
            };
        }
        this.setState(state);
    }
    validateSocialIconsForm(){
        const { socialIcons } = this.state.socialIconValues;
        if(typeof(socialIcons) !== "object"){
            return;
        }
        let isValid = true;
        let validation = this.state.socialIconsValidation;
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            validation[socialIcon] = "success";
            const value = socialIcons[socialIcon];
            if(!value || value === "" || value === "https://"){
                validation[socialIcon] = "error";
                isValid = false;
            }
        }
        this.setState({socialIconsValidation: validation});
        return isValid;
    }
    onSuccess(type = null){
        const isValid = this.validateSocialIconsForm();
        if(!isValid){
            return;
        }
        this.setState({
            editSocialIcons: false,
        });
        const { socialIconValues } = this.state;
        const valuesCopy = Object.assign({}, socialIconValues);
        this.props.onUserUpdate({values: valuesCopy});
    }
    onSocialIconClear(name){
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === name)[0];
        socialIcon.status = false;

        let socialIcons = this.state.socialIconValues.socialIcons;
        if(socialIcons.hasOwnProperty(name)){
            delete socialIcons[name];
        }
        this.setState({
            ...this.state,
            socialIconValues: {
                ...this.state.socialIconValues, 
                socialIcons: socialIcons
            },
            sortableSocialIcons: this.prepareSortableSocialIcons(socialIcons)
        });
    }
    getDomainName(name){
        return helper.getSocialDomainName(name);
    }
    render(){
        const { classes, user, paymentData, subscription } = this.props;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        const state = this.state;
        const { contentLoaded, loading, values, currentContent, featuredLinks, userSearched, showClearSearch, affiliateMode, 
            openStatus, showSearchTerms, addContent, platform, hasButtonProfile,
            hasLinktreeProfile, buttonPlatform, featuredLastKey, featuredLoadingMore, instagramModal, youtubeModal, linktreeModal,
            refresh, hasInstagramLinkProfile, hasYoutubeApiProfile, adButtonModal, editSocialIcons, sortableSocialIcons } = state;
        let { youtubeApiModal, instagramApiModal, linktreePromptModal } = this.state;
        if(hasInstagramLinkProfile){
            instagramApiModal = false;
        }
        if(hasYoutubeApiProfile){
            youtubeApiModal = false;
        }
        if(hasLinktreeProfile){
            linktreePromptModal = false;
        }
        const { username } = user;
        let searchResults = this.getSearchResults();
        let maxFeatured = -1;
        let showButtons = false;
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("maxFeatured")){
            maxFeatured = parseInt(user.guestPreferences.maxFeatured);
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(searchResults !== null){
            if(this.state.featuredLinks){
                // if(user.userLevel === 0){
                //     searchResults = searchResults.slice(0, 6);
                // }else if(user.userLevel === 1){
                //     searchResults = searchResults.slice(0, 12);
                // }
                if(maxFeatured > 0){
                    searchResults = searchResults.slice(0, maxFeatured);
                }
            }
        }
        let buttons = null;
        if(user.hasOwnProperty("buttons")){
            buttons = user.buttons;
        }
        const { primaryColor, buttonStyle, theme, fontSize } = user.guestPreferences;
        let isRounded = false;
        if(buttonStyle === "rounded"){
            isRounded = true;
        }
        let isDark = false;
        if(theme === "dark"){
            isDark = true;
        }
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/app/icon_trans393x551.png";
        const buttonStyles = {
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
        };

        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        let socialIconsRendered = null;
        if(!editSocialIcons){
            socialIconsRendered = this.renderSocialIcons();
        }

        return (
            <Grid item className={classes[template]}>
                <Button round justIcon color="primary" onClick={(e) => this.onRefreshPage(e)} className={classes.refreshPage+" "+(refresh ? classes.spinning : "")}><RefreshIcon /></Button>
                {
                    template === "simple" ?
                        <GridContainer justify="flex-start" alignItems="center" className={(isDark ? classes.dark : classes.light)}>
                            <GridItem xs={12} lg={9} className={classes.marginAuto}>
                            {
                                editSocialIcons ?
                                    <GridContainer justify="flex-start" alignItems="center" className={classes.gridSocialContainerSpacing+" "+classes.socialIconsAddButton} style={buttonStyles}>
                                        <GridItem className={classes.userAvatarContainer+" "+classes.socialLinksContent}>
                                            <ReactSortable
                                                tag="ul"
                                                handle=".social_icons_sort"
                                                direction="horizontal"
                                                dragoverBubble={true}
                                                animation={150}
                                                className={classes.sortableList+" "+classes.sortableListEnabled} 
                                                list={sortableSocialIcons}
                                                setList={this.onSocialIconsSort}
                                            >
                                                {this.renderSocialFields()}
                                            </ReactSortable>
                                            {this.renderSocialIconsField()}
                                            <div className={classes.iconsContainer}>
                                                <Button justIcon onClick={() => this.editSocialIcons(false)} color="danger">
                                                    <Close />
                                                </Button>
                                                <Button justIcon onClick={() => this.onSuccess("socialIcons")} color="success">
                                                    <Save />
                                                </Button>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                :
                                <GridContainer justify="flex-start" alignItems="center" className={classes.gridSocialContainerSpacing+" "+classes.socialIconsAddButton} onClick={() => this.editSocialIcons()} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                                    <GridItem className={classes.userAvatarContainer+" "+classes.socialIconsContainer}>
                                        {
                                            socialIconsRendered.length > 0 ?
                                                <ul>
                                                    {socialIconsRendered}
                                                </ul>
                                            :
                                            <p style={buttonStyles}>Click to add your Social Icons</p>
                                        }
                                    </GridItem>
                                </GridContainer>
                            }
                            </GridItem>
                        </GridContainer>
                    :
                    <></>
                }
                <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing+" "+(isDark ? classes.dark : classes.light)}>
                    <form method="GET" action={"/"+username+"/results"} onSubmit={this.handleSubmit} className={classes.form} >
                        <GridItem xs={12} lg={9} className={classes.marginAuto}>
                            <GridContainer alignItems="center" justify="space-between" className={classes.searchFormContainer+" "+(showSearchTerms ? classes.searchTermsActive : "")}>
                                <GridItem className={"form-grid"}>
                                    <CustomAutocomplete 
                                        options={this.state.searchTerms}
                                        value={values.q ? values.q : ""}
                                        labelText={"Search Settings"}
                                        onClear={() => this.clearSearch()}
                                        showClear={showClearSearch}
                                        disabledFirst={true}
                                        onChange={this.onSearchChange}
                                        onInputChange={(e, value) => this.onSearchInputChange(e, value, 'q')}
                                        onOpenOptions={(e) => this.onOpenOptions(e)}
                                        onCloseOptions={(e) => this.onCloseOptions(e)}
                                        autocompleteProps={{
                                            onKeyUp: (e) => this.onEnterSubmit(e),
                                            open: openStatus,
                                            disablePortal: true,
                                            readOnly: true,
                                            disabled: true
                                        }}
                                        inputProps={{
                                            endAdornment: null, 
                                        }}
                                        popperClass={"autosuggest-popper"}
                                        color={primaryColor}
                                        startAdornment={
                                            (
                                                <InputAdornment 
                                                    className={classes.startornment}
                                                >
                                                    <Button justIcon simple onClick={this.toggleFilter} className={"searchButton filterButton"}>
                                                        <img src={logoUrl} alt={user.username} />
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.autocomplete+" "+classes.searchFormInput+" search-terms-input "+(isRounded ? "round-input": "")+" "+(showBetaFeatures ? "show-beta-features" : "hide-beta-features")+" "+(showSearchTerms ? "options-open" : "")
                                        }}
                                    />
                                    <div className={classes.autocompleteOverlay} onClick={() => this.onSearchTermsModal((!showSearchTerms))}></div>
                                </GridItem>
                            </GridContainer>
                            {
                                showSearchTerms ?
                                    <div className={classes.searchTermsContainer+" "+(isRounded ? "round": "")} style={{borderColor: this.getPrimaryColor(primaryColor)}}>
                                        <SearchTerms open={showSearchTerms} onClose={() => this.onSearchTermsModal(false)} onSuccess={(values) => this.onSearchTermsModalSuccess(values)} user={user} />  
                                    </div>
                                :
                                <></>
                            }
                        </GridItem>
                        {/* <GridItem xs={12} lg={9} className={classes.filtersGrid+" "+classes.marginAuto +" "+(state.showFilter ?'':classes.hide) }>
                            <p>Which filters do you want to offer?</p>
                            <ul>
                                <li>
                                    <Button color="transparent" simple round={isRounded} onClick={(e) => this.handleFilterOption(e,'allFilter')} className={(values.allFilter ? "active" : "")}>
                                        All
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'locationFilter')} className={(values.locationFilter ? "active" : "")}>
                                        <PlaceIcon />
                                    </Button>
                                </li>
                                {
                                    showBetaFeatures ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'shoppableFilter')} className={(values.shoppableFilter ? "active" : "")}>
                                                <ShoppingBagIcon className={classes.materialIcon+" "+classes.customIcon+" MuiSvgIcon-root"} />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'instagramFilter')} className={(values.instagramFilter ? "active" : "")}>
                                        <img src={instagramIcon} alt="instagram" className="instagram-icon" />
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'youtubeFilter')} className={(values.youtubeFilter ? "active" : "")}>
                                        <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'linksFilter')} className={(values.linksFilter ? "active" : "")}>
                                        <MaterialIcon path={mdiLink} className={classes.materialIcon} />
                                    </Button>
                                </li>
                            </ul>
                        </GridItem> */}
                    </form>
                </GridContainer>              
                <GridContainer justify="center" alignItems="center" className={classes.gridContainerSpacing}>
                    <GridItem xs={12} lg={9} className={classes.marginAuto}>
                        {
                            contentLoaded ?
                                (searchResults && searchResults.length > 0) || (buttons !== null && featuredLinks) ?
                                    featuredLinks ?
                                       <></>
                                    :
                                    <h4 className={classes.title}>Search Results</h4> 
                                :
                                userSearched ?
                                        state.showAdvancedSearch && values.location.length > 0 ?
                                            <h4 className={classes.title}>{user.username} has no search results containing "{values.q}" within {values.radius} {values.radiusType}(s) of {values.location}</h4> 
                                        :
                                            <h4 className={classes.title}>{user.username} has no search results containing "{values.q}"</h4> 
                                    :
                                    <></>
                            :
                            <></>
                        }
                        {
                            showBetaFeatures && user.userLevel <= 0 ?
                                <Button className={classes.platformButton+" "+(isDark ? classes.freeAdButtonDark : "")+" "+classes.freeAdButton+" "+buttonStyle} color={this.getPrimaryColor()} style={buttonStyles}>
                                    Get Your Own Search My Social Account - Free Forever!
                                    <Link href="/dashboard/editmode/search" onClick={(e) => this.onAdButtonClick(e)} className={classes.whyThisAd}>
                                        What’s this?
                                    </Link>
                                </Button>
                            :
                            <></>
                        }
                        {
                            showButtons ?
                                <>
                                    {
                                        hasButtonProfile ?
                                            <Button onClick={() => this.onPlatformSelect(buttonPlatform)} className={classes.platformButton+" "+(isDark ? classes.platformButtonDark : "")} color="white" style={buttonStyles}>
                                                <AddIcon />
                                                Add a new button
                                            </Button>
                                        :
                                        <></>
                                    }
                                    {
                                        hasLinktreeProfile === false && user.userPreferences.showLinktree ?
                                            <>
                                                <CloseIcon className={classes.closeLinktreeIcon} onClick={(e) => this.onPlatformClose('showLinktree')} />
                                                <Button onClick={(e) => this.onAddPlatform("linktree")} className={classes.platformButton+" "+(isDark ? classes.platformButtonDark : "")} color="white" style={buttonStyles}>
                                                    <AddIcon />
                                                    Import Your Linktree
                                                </Button>
                                            </>
                                        :
                                        <></>
                                    }
                                </>
                            :
                            <></>
                        }
                        {
                            showButtons && buttons !== null && featuredLinks ?
                                <ButtonsView user={this.state.user} store={this.store} response={buttons} onOpen={(show, currentContent, key) => this.onSearchResultOpen(show, currentContent, key, true)} 
                                primaryColor={this.props.primaryColor} onEdit={(key,newContent) => this.onEdit(key,newContent, true)} onDelete={(key, content) => this.onDelete(key, content, true)} onPhotoResize={this.onPhotoResize}
                                onDropSuccess={(buttons) => this.onButtonsSort(buttons)} refresh={refresh} />
                            :
                            <></>
                        }
                        {
                            contentLoaded ?
                                searchResults ?
                                    <>
                                        
                                        {this.renderResults(searchResults)}
                                        {
                                            featuredLastKey !== null && featuredLoadingMore === false && featuredLinks === true && (maxFeatured === -1 || maxFeatured > searchResults.length) ?
                                                <Button className={classes.loadMore} color={primaryColor} id="featuredContentViewMore" round onClick={(e) => this.loadFeed(true)}>
                                                    <RefreshIcon className={classes.icons} />View More
                                                </Button>
                                            :
                                                featuredLoadingMore === true && featuredLinks === true ?
                                                    <LoaderComponent align="center" />
                                                :
                                                <></>
                                        }
                                    </>
                                :
                                <></>
                            :
                            <>
                            {
                                loading ?
                                    <LoaderComponent align="center" color={primaryColor} />
                                :
                                <></>
                            }
                            </>
                        }
                    </GridItem> 
                </GridContainer>
                {
                    affiliateMode ?
                        <ContentAffiliateModal content={currentContent} open={affiliateMode} onClose={() => this.onAffiliateModalClose()} user={user} />  
                    :
                    <></>
                }
                {
                    (addContent === 1) ?
                        <SearchableContentTypes searchPreview={true} subscription={subscription} paymentData={paymentData} store={this.store} history={this.history} match={this.props.match} onWizardClose={this.onWizardClose} platform={platform} type={platform.type}/>
                    :
                    <></>
                }
                {
                    instagramModal ?
                        <InstagramWizardModal open={instagramModal} store={this.store} user={this.state.user} onClose={() => this.onPlatformWizardClose("instagram")} onSuccess={(user) => this.onPlatformWizardSuccess(user, "instagram")} />
                    :
                    <></>
                }
                {
                    youtubeModal ?
                        <YoutubeWizardModal open={youtubeModal} store={this.store} user={this.state.user} onClose={() => this.onPlatformWizardClose("youtube")} onSuccess={(user) => this.onPlatformWizardSuccess(user, "youtube")} />
                    :
                    <></>
                }
                {
                    linktreeModal ?
                        <LinktreeWizardModal open={linktreeModal} store={this.store} user={this.state.user} onClose={() => this.onPlatformWizardClose("linktree")} onSuccess={(user) => this.onPlatformWizardSuccess(user, "linktree")} />
                    :
                    <></>
                }
                {
                    instagramApiModal ?
                        <InstagramApiModal open={instagramApiModal} onClick={event => this.handleInstagramModal(true)} onClose={event => this.handleInstagramModal(false)} onNever={event => this.handleInstagramModal(null)} />
                    :
                    <></>
                }
                {
                    youtubeApiModal ?
                        <YoutubeApiModal open={youtubeApiModal} onClick={event => this.handleYoutubeModal(true)} onClose={event => this.handleYoutubeModal(false)} onNever={event => this.handleYoutubeModal(null)} />
                    :
                    <></>
                }
                {
                    linktreePromptModal ?
                        <LinktreeModal open={linktreePromptModal} onClick={event => this.handleLinktreeModal(true)} onClose={event => this.handleLinktreeModal(false)} onNever={event => this.handleLinktreeModal(null)} />
                    :
                    <></>
                }
                {
                    adButtonModal ?
                        <AdButtonModal open={adButtonModal} onClose={() => this.onAdButtonModalClose()} />
                    :
                    <></>
                }
            </Grid>
        )
    }
}

SearchForm.defaultProps = {
    onPopupClose: function(){ },
    onCopy: function(){ },
    primaryColor: primaryColor,
    subscription: null,
    paymentData: null,
    refresh: false
}
SearchForm.propTypes = {
    classes: PropTypes.object,
    onPopupClose: PropTypes.func,
    onCopy: PropTypes.func,
    primaryColor: PropTypes.string,
    subscription: PropTypes.object,
    paymentData: PropTypes.object,
    refresh: PropTypes.bool
};
  
export default withTheme(withStyles(searchFormStyle)(SearchForm));