/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    container,
    defaultFont,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    transition,
    boxShadow,
    drawerWidth,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb
} from "../../../jss/material-kit-pro-react.jsx";

const headerStyle = theme => ({
    appBar: {
        display: "flex",
        border: "0",
        padding: "0.625rem 0",
        marginBottom: "20px",
        color: grayColor[15],
        width: "100%",
        backgroundColor: whiteColor,
        boxShadow:
        "0 4px 18px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 7px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.15)",
        transition: "all 150ms ease 0s",
        alignItems: "center",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        position: "relative",
        paddingBottom: "1px",
        paddingTop: "1px"
    },
    absolute: {
        position: "absolute",
        top: "auto"
    },
    fixed: {
        position: "fixed",
        zIndex: 1106,
        "&:not(.header_transparent)": {
            zIndex: 1106
        }
    },
    container: {
        ...container,
        minHeight: "50px",
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexWrap: "nowrap"
    },
    title: {
        letterSpacing: "unset",
        "&,& a": {
            ...defaultFont,
            minWidth: "unset",
            lineHeight: "30px",
            fontSize: "18px",
            borderRadius: "3px",
            textTransform: "none",
            whiteSpace: "nowrap",
            color: "inherit",
            "&:hover,&:focus": {
                color: "inherit",
                background: "transparent"
            },
            fontFamily: '"Gotham-Medium", "Roboto", "Helvetica", "Arial", sans-serif'
        }
    },
    appResponsive: {
        margin: "20px 10px",
        marginTop: "0px",
        "& .MuiList-root": {
            display: "block"
        },
        "& .MuiListItem-root": {
            width: "100%",
            "& .MuiButton-root": {
                width: "calc(100% - 30px)",
                marginTop: "8px",
                textAlign: "left",
                marginLeft: "15px",
                marginBottom: "8px",
                display: "block"
            }
        },
    },
    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.46)"
    },
    info: {
        backgroundColor: infoColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.46)"
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.46)"
    },
    warning: {
        backgroundColor: warningColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.46)"
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.46)"
    },
    rose: {
        backgroundColor: roseColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.46)"
    },
    transparent: {
        backgroundColor: "transparent !important",
        boxShadow: "none",
        paddingTop: "25px",
        color: infoColor[0]
    },
    dark: {
        color: whiteColor,
        backgroundColor: grayColor[9] + " !important",
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(grayColor[9]) +
        ", 0.46)"
    },
    white: {
        border: "0",
        padding: "0.625rem 0",
        marginBottom: "20px",
        color: grayColor[15],
        backgroundColor: whiteColor + " !important",
        boxShadow:
        "0 4px 18px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 7px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.15)"
    },
    drawerPaper: {
        border: "none",
        bottom: "0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        width: drawerWidth,
        ...boxShadow,
        position: "fixed",
        display: "block",
        top: "0",
        height: "100vh",
        right: "0",
        left: "auto",
        visibility: "visible",
        borderTop: "none",
        textAlign: "left",
        paddingRight: "0px",
        paddingLeft: "0",
        ...transition,
        overflowY: "auto"
    },
    hidden: {
        width: "100%"
    },
    collapse: {
        [theme.breakpoints.up("md")]: {
        display: "flex !important",
        MsFlexPreferredSize: "auto",
        flexBasis: "auto"
        },
        WebkitBoxFlex: "1",
        MsFlexPositive: "1",
        flexGrow: "1",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center"
    },
    closeButtonDrawer: {
        position: "absolute",
        right: "8px",
        top: "9px",
        zIndex: "1"
    },
    closeButtonDrawerIcon: {
        color: infoColor[0]
    },
    brand: {
        marginRight: "20px",
        "& img": {
            maxHeight: "49px"
        }
    },
    bottomBar: {
        position: "fixed",
        bottom: "0px",
        left: "0px",
        right: "0px",
        maxWidth: "none",
        minHeight: "40px",
        zIndex: 100,
        transition: "all 0.3s ease",
        "& .MuiList-root": {
            textAlign: "center",
            display: "flex"
        },
        "& .MuiListItem-root": {
            width: "auto !important",
            float: "none",
            display: "flex",
            flexBasis: "100%",
            justifyContent: "center",
            "&:after": {
                display: "none !important"
            },
            "& .MuiButton-root": {
                marginTop: "0px",
                marginBottom: "0px"
            },
            "& .MuiLink-root": {
                height: "41px"
            }
        }
    },
    drawerModal: {
        zIndex: "1290 !important"
    },
    hasAdminBar: {
        [theme.breakpoints.up("md")]: {
            paddingTop: "20px"
        }
    },
    adminBar: {
        position: "absolute",
        left: "0px",
        right: "0px",
        textAlign: "center",
        top: "0px",
        background: whiteColor,
        color: grayColor[1],
        fontSize: "14px",
        lineHeight: "20px",
        overflow: "hidden"
    },
    logout: {
        position: "absolute",
        right: "0px",
        cursor: "pointer",
        paddingRight: "10px",
        color: primaryColor[0]
    }
});

export default headerStyle;
