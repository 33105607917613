import { makeStyles } from '@material-ui/core/styles';

import {
    whiteColor,
} from "../material-kit-pro-react.jsx";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },
    title: {
        margin: theme.spacing(1, 0, 1),
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
}));

const useTableStyles = makeStyles(theme => ({
    tableCell: {
        "&:last-child": {
            textAlign: "right"
        }
    }
}));

export { useTableStyles, useStyles };
export default useStyles;