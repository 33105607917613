import {
    title,
    blackColor,
    grayColor,
    hexToRgb,
    whiteColor,
    primaryColor
} from "../../material-kit-pro-react.jsx";

const listViewStyle = theme => ({
    gridSpacing: {
        position: "relative",
        marginTop: theme.spacing(2),
        marginLeft: "0px",
        marginRight: "0px",
        [theme.breakpoints.down('xs')]: {
            minHeight: "70px"
        }
    },
    gridItem: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0px"
        },
    },
    gridItemResponsive: {
        paddingLeft: "0px",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "55px",
            paddingRight: "5px",
            flexBasis: "18%",
            "& img": {
                width: "50px",
                height: "50px",
                objectFit: "cover"
            }
        }
    },
    gridItemResponsiveWithArrows: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: "80px",
            flexBasis: "unset"
        }
    },
    optionsGridItem: {
        paddingRight: "105px",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "calc( 100% - 55px )",
            flexBasis: "unset"
        }
    },
    optionsGridItemWithArrows: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: "calc( 100% - 80px )",
            flexBasis: "unset"
        }
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        cursor: "pointer",
        textOverflow: "ellipsis",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: "0px"
        }
    },
    coverPhoto: {
        maxWidth: "100%",
        cursor: "pointer",
    },
    coverPhotoWithArrows: {
        width: "calc( 100% - 43px )"
    },
    icon: {
        margin: "0px 5px"
    },
    starIcon: {
        margin: "0px 5px",
        padding: "0px",
        "& svg": {
            width: "22px !important",
            height: "22px !important"
        }
    },
    location: {
        textAlign: "left",
        fontSize: "13px"
    },
    options: {
        position: "absolute",
        right: "0px",
        top: "0px",
        zIndex: 1,
    },
    optionsRow: {
        marginBottom: "10px",
        [theme.breakpoints.down('xs')]: {
            "& button": {
                marginRight: "0px"
            },
            marginBottom: "5px",
        }
    },
    arrows: {
        display: "inline-flex",
        verticalAlign: "middle",
        flexDirection: "column",
        "& button": {
            background: "transparent !important",
            boxShadow: "none !important",
            color: blackColor+" !important",
            minWidth: "0px",
            minHeight: "0px",
            width: "32px",
            height: "32px",
            marginRight: "10px",
            padding: "0px",
            [theme.breakpoints.down('xs')]: {
                width: "17px",
                height: "17px",
                marginRight: "5px",
            }
        },
        "& button.disableArrow": {
            color: grayColor[0]+" !important",
        }
    },
    loaderImage: {
        position: "relative",
        backgroundColor: grayColor[23],
        "& .sd_loader": {
            width: "80px !important",
            height: "80px !important",
            [theme.breakpoints.down('xs')]: {
                width: "50px !important",
                height: "50px !important",
            }
        },
        "& .MuiCircularProgress-root": {
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "20px !important",
                height: "20px !important",
            }
        }
    },
    loaderImageWithArrows: {
        display: "inline-flex",
        verticalAlign: "middle",
    },
    backgroundOverlay: {
        backgroundColor: "rgba(" + hexToRgb(grayColor[0]) + ", 0.7)",
        width: "calc(100% - 120px)",
        height: "100%",
        position: "absolute",
        zIndex: "2",
        top: "0px",
        left: "40px",
        border: "2px solid",
        "& p": {
            color: whiteColor,
            textAlign: "center",
            fontSize: "20px",
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
            margin: "auto",
            height: "24px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "17px",
            },
            [theme.breakpoints.down("sx")]: {
                fontSize: "15px",
            },
            "& button": {
                padding: "0px",
                fontSize: "20px",
                fontWeight: "bold",
                textTransform: "none",
                color: primaryColor[0]+" !important",
                margin: "0px",
                lineHeight: "normal",
            }
        },
        [theme.breakpoints.down('xs')]: {
            left: "20px",
            width: "calc(100% - 90px)",
            "& p":{
                maxWidth: "150px",
                fontSize: "16px",
                height: "45px",
                "& button": {
                    fontSize: "16px !Important"
                }
            }
        }
    },
    searchableIcon: {
        color: blackColor+" !important",
        border: "0px !important",
        boxShadow: "none !important"
    },
    publishDate: {
        textAlign: "right",
        marginRight: "5px",
        marginLeft: "5px",
        "& p": {
            fontSize: "13px",
            lineHeight: "normal"
        }
    },
    buttonView: {
        width: "100% !important",
        height: "auto !important",
        minHeight: "0px !important",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "12px",
            margin: "0px",
            flex: "auto",
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        "& .options": {
            position: "static",
            maxWidth: "130px",
            width: "100%",
            textAlign: "right",
            "& p":{
                lineHeight: "normal"
            },
            [theme.breakpoints.down('xs')]: {            
                maxWidth: "106px",
            },
        },
        "& $analyticsData": {
            [theme.breakpoints.up('sm')]: {            
                marginLeft: "15px",
            },
        }
    },
    buttonBase: {
        height: "inherit",
        width: "100%",
        flex: "auto",
        flexDirection: 'unset',
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
    },
    buttonIcon: {
        "& a": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img, & >div": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        },
        "&.rounded": {
            borderRadius: "30px",
            "& img, & >div": {
                borderRadius: "100%",
            }
        },
        [theme.breakpoints.down('xs')]: {
            "& a": {
                paddingLeft: "45px",
                paddingRight: "45px",
            },
            "& img, & >div": {
                width: "36px",
                height: "36px",
                left: "5px"
            },
        }
    },
    analyticsData: {
        marginRight: "5px",
        marginLeft: "5px",
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0px",
            "& li": {
                lineHeight: "14px",
                "& span": {
                    marginRight: "6px",
                    fontSize: "13px"
                },
                "& svg": {
                    width: "18px",
                    height: "18px"
                },
                "& *": {
                    display: "inline-block",
                    verticalAlign: "middle"
                }
            }
        }
    },
    analyticsFlexData: {
        display: "flex",
        "& li + li": {
            marginLeft: "10px"
        }
    }
});

export default listViewStyle;