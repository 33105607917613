
import React from "react";
import { createBrowserHistory } from 'history';
import PropTypes from "prop-types";
import Cookie from "../../../../assets/js/utils/Cookie";
import Api from '../../../../assets/js/utils/Api';
import Config from '../../../../../Config';
import { helper } from '../../../../assets/js/utils/Element'
import LoaderComponent from '../../../components/Loader'
import ListView from "../../../components/Dashboard/Content/ListView";
// import WebLinkWizard from "./WebLinkWizard";
import WebLinkEditWizard from "./WebLinkEditWizard";
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Warning from "../../../components/Typography/Warning.jsx";
import AddIcon from '@material-ui/icons/Add';
import Close from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
// import Close from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import NoticeZeroContent from "./InstagramLinkSteps/NoticeZeroContent";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent.jsx";
import NoticeCheckNewPosts from "./InstagramLinkSteps/NoticeCheckNewPosts";
import NoticeCheckNewVideos from "./YoutubeLinkSteps/NoticeCheckNewPosts";
import NoticeZeroLinktreeContent from "./linktreeSteps/NoticeZeroContent";
import NoticeZeroWeblinkContent from "./contents/NoticeZeroContent";
import NoticeZeroButtonContent from "./contents/NoticeZeroButtonContent";
import NoticeZeroYouTubeContent from "./YoutubeLinkSteps/NoticeZeroContent";
import PlatformDeleteModal from "../../../components/Dashboard/PlatformDeleteModal";
import SearchableContentTypes from "../platforms/Searchable/ContentTypes";

//Tooltip modals
import NewWebLinkTooltipModal from "../../../components/ToolTips/Dashboard/ContentPlatforms/NewWebLinkTooltipModal";
import SearchLinkTooltipModal from "../../../components/ToolTips/Dashboard/ContentPlatforms/Platforms/SearchLinkTooltipModal";

import platformContentStyle from "../../../../assets/jss/dashboard/platforms/platformContentStyle";
import { Grid } from "@material-ui/core";
const history = createBrowserHistory();

class PlatformContent extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        const query = helper.getParam("q");
        let add = helper.getParam("new");
        if(add === "null" || add === ""){
            add = 0;
        }
        if(this.props.addContent === 1){
            add = 1;
        }
        let contentId = null;
        if(this.props.match.params.hasOwnProperty("contentId")){
            contentId = this.props.match.params.contentId;
        }
        this.state = {
            platform: this.props.platform,
            cancelToken: null,
            response: [],
            contentId: contentId,
            values: {
                q: (query !== "null" ? query: ""),
                contentPlatformId: this.props.platform.contentPlatformId,
                pageSize: 10,
                showAnalytics: true
            },
            loading: false,
            contentLoaded: false,
            addContent: parseInt(add),
            lastKey: null,
            loadingMore: false,
            editMode: false,
            tooltips: {
                newWebLink: false,
                searchLinkEditButton: false
            },
            zeroContentModal: false,
            checkNewPostsModal: false,
            checkNewVideosModal: false,
            linktreeNoContentModal: false,
            weblinkZeroContentModal: false,
            buttonZeroContentModal: false,
            youtubeZeroContentModal: false,
            deletePlatformModal: false,
            deletingPlatform: false
        };

        this.startSearch = this.startSearch.bind(this);
        this.addNewContent = this.addNewContent.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);
        this.updatePlatformContent = this.updatePlatformContent.bind(this);
        this.onNewPostsNoticeClose = this.onNewPostsNoticeClose.bind(this);
        this.onPlatformReauth = this.onPlatformReauth.bind(this);
        this.onEditSettings = this.onEditSettings.bind(this);
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        window.removeEventListener("scroll", this.onScroll);
    }
    componentDidMount(){
        if(this.state.addContent !== 1 && this.state.contentId === null){
            this.startSearch();
        }

        window.addEventListener("scroll", this.onScroll);
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.platform.contentPlatformId !== prevState.platform.contentPlatformId){
            if(this.state.contentId === null){
                this.startSearch();
            }            
        }
    }
    componentWillReceiveProps(props){
        let addContent = this.state.addContent;
        if(props.addContent === 1){
            addContent = 1;
        }
        let response = [];
        let contentLoaded = false;
        let values = {
            q: "",
            contentPlatformId: props.platform.contentPlatformId
        };
        if(props.platform.contentPlatformId === this.state.platform.contentPlatformId){
            response = this.state.response;
            if(response.length > 0){
                contentLoaded = true;
            }            
            values = this.state.values;
        }
        this.setState({
            platform: props.platform,
            response: response,
            contentLoaded: contentLoaded,
            values: values,
            addContent: addContent,
        });
    }
    onScroll(){
        const viewMore = document.getElementById("contentViewMore");
        if(!viewMore){
            return;
        }
        let scrollPosition = document.documentElement.scrollTop;
        if(scrollPosition <= 0){
            scrollPosition = document.body.scrollTop;
        }

        let elementPostionY = 0;
        if (typeof (viewMore.offsetParent) != 'undefined') {
            elementPostionY = viewMore.offsetTop;
        } else {
            elementPostionY = viewMore.y;
        }

        if (elementPostionY <= scrollPosition + (window.innerHeight - 100)) {
            this.startSearch(true);
        }
    }
    getPlatformUrl(){
        const { platform } = this.state;
        let url = "https://"+platform.type+".com/";
        if(platform.type === "instagramAPI" || platform.type === "instagramDemo"){
            url = "https://instagram.com/";
        }else if(platform.type === "linktree"){
            url = "https://"+platform.type+".ee/";
        }else if(platform.type === "youtubeAPI"){
            url = "";
        }
        switch(platform.type){
            case "instagramAPI":
                url = url+platform.instagramHandle;
                break;
            case "instagramDemo":
                url = url+platform.instagramHandle;
                break;
            case "linktree":
                url = url+platform.linktreeUsername;
                break;
            case "youtubeAPI":
                url = url+platform.channelTitle;
                break;
            default:
                url = "/";
                break;
        }
        return url;
    }
    handleChange(event, name) {
        this.setState({
            values:{
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    startSearch(viewMore = false){
        const { platform } = this.state;
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source, loading: (!viewMore), loadingMore: viewMore, contentLoaded: viewMore});
        let requestData = this.state.values;
        history.push("/dashboard/platform/"+platform.contentPlatformId+"/?q="+requestData['q']);
        if(viewMore && this.state.lastKey !== null){
            requestData['startKey'] = this.state.lastKey;
        }

        if(requestData['q'].length > 0){
            const ApiUrl = Config.getApiUrl()+"data/search?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: source.token
            }).then(res => {
                let lastKey = null;
                if(res.data.hasOwnProperty("lastKey")){
                    lastKey = res.data.lastKey;
                }
                const oldResponse = (viewMore ? that.state.response:[]);
                const response = oldResponse.concat(that.getSearchResults(res.data.response));
                that.updateResponse(response, lastKey);
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    that.updateResponse([]);
                }            
            });
        }else{
            if(platform.type === "button"){
                requestData['buttons'] = true;
            }
            Api.getContent(requestData, source, user.userId).then(data => {
                
                let lastKey = null;
                if(data.hasOwnProperty("lastKey")){
                    lastKey = data.lastKey;
                }
                const oldResponse = (viewMore ? that.state.response:[]);
                const response = oldResponse.concat(data.response);
                that.updateResponse(response, lastKey);
                if(response.length <= 0){
                    if(platform.type === "instagramAPI"){
                        this.setState({
                            zeroContentModal: true,
                        });
                    }
                    if(platform.type === "linktree"){
                        this.setState({
                            linktreeNoContentModal: true
                        });
                    }
                    if(platform.type === "webLink"){
                        this.setState({
                            weblinkZeroContentModal: true
                        });
                    }
                    if(platform.type === "button"){
                        this.setState({
                            buttonZeroContentModal: true
                        })
                    }
                    if(platform.type === "youtubeAPI"){
                        this.setState({
                            youtubeZeroContentModal: true
                        });
                    }
                }
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    that.updateResponse([]);
                }
            });
        }        
    }
    updateResponse(response, lastKey = null){
        this.setState({
            response: response,
            loading: false,
            cancelToken: null,
            contentLoaded: true,
            lastKey: lastKey,
            loadingMore: false
        });

        const dataScroll = document.documentElement.getAttribute("data-scroll");
        if(response.length > 0 && dataScroll){
            setTimeout(function(){
                document.body.scrollTop = 0;
                const scrollPositionSaved = parseFloat(dataScroll);
                window.scrollTo(0,scrollPositionSaved);
                document.documentElement.removeAttribute("data-scroll");
            },5);
        }
    }
    addNewContent(event){
        event.preventDefault();
        this.setState({
            addContent: 1
        });

        let firstButton = false;
        if(this.state.platform.type === "button" && this.state.response.length === 0){
            firstButton = true;
        }
        history.push("/dashboard/platform/"+this.state.platform.contentPlatformId+"/?new=1"+(firstButton ? "&button=1" : ""));
    }
    onWizardClose(platform, content, afterCreate = false, afterEdit = false, afterDelete = false){
        const { user } = this.store.getState();
        
        if(afterCreate === true){
            content.ownerId = user.userId;
            this.setState({
                addContent: 0,
                contentId: null,
                editMode: false,
                lastKey: null,
                contentLoaded: true,
                response: [
                    content,
                    ...this.state.response,
                ]
            });
            if(this.state.response <= 0){
                this.startSearch();  
            }
        }else if(afterEdit === true || afterDelete === true){
            this.setState({
                addContent: 0,
                contentId: null,
                editMode: false,
                lastKey: null,
                contentLoaded: true,
            });
            if(this.state.response <= 0 || afterDelete === true){
                this.startSearch();  
            }
        }else{
            this.setState({
                addContent: 0,
                contentId: null,
                editMode: false,
                lastKey: null,
                contentLoaded: true,
            });

            const requestData = this.state.values;
            history.push("/dashboard/platform/"+this.state.platform.contentPlatformId+"/?q="+requestData['q']);
        }
        if(afterCreate){
            this.props.onWebLinkAdded();
            return;
        }
    }
    clearSearch(){
        this.setState({
            values: {
                ...this.state.values,
                q: ""
            },
            contentLoaded: false,
            response: [],
        });
    }
    getSearchResults(response){
        if(response.length <= 0){
            return [];
        }
        const data = response[0];
        if(data.response.found <= 0){
            return [];
        }
        let searchHits = [];
        data.response.hit.map(hit => {
            if(hit.hasOwnProperty("content")){
                searchHits.push(hit.content);
            }
            return null;
        });
        return searchHits;
    }
    onEdit(){
        this.setState({editMode: true});
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    onNoticeClose(){
        this.setState({
            zeroContentModal: false,
            linktreeNoContentModal: false,
            weblinkZeroContentModal: false,
            buttonZeroContentModal: false,
            youtubeZeroContentModal: false,
        });
    }
    onNewPostsNoticeClose(){
        this.setState({
            checkNewPostsModal: false,
            checkNewVideosModal: false
        });
    }
    updatePlatformContent(e, stateName = 'checkNewPostsModal'){
        e.preventDefault();
        const { platform } = this.state;
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, [stateName]: true});
        Api.updatePlatform(platform, source, user.userId, true).then(data => {
              //Silent
        }).catch(err => {
            //Silent
        });
    }
    isTokenExpired(){
        const { platform } = this.state;
        if(!platform.hasOwnProperty("error")){
            return false;
        }
        if(platform.error === true){
            return true;
        }
        return false;
    }
    onPlatformReauth(e){
        e.preventDefault();
        const { platform } = this.state;
        Cookie.write("platform_credentials", 1);
        Cookie.write("platform_id", platform.contentPlatformId);
        const authUrl = Config.getInstagramAuthUrl();
        window.location.href = authUrl;
    }
    onEditSettings(e){
        e.preventDefault();
        const { platform } = this.state;
        this.props.onPlatformEdit(platform);
    }
    getSearchInputLabel(){
        const { platform } = this.state;
        let label = null;
        switch(platform.type){
            case "instagram":
            case "instagramAPI":
                label = "Instagram Account";
                break;
            case "linktree":
                label = "Linktree Account";
                break;
            case "youtubeAPI":
                label = "YouTube Channel";
                break;
            default:
                break;
        }
        return label;
    }
    deletePlatformModal(status = true){
        this.setState({deletePlatformModal: status});
    }
    deletePlatformModalSuccess(deleted = false){
        const { platform } = this.state;
        if(deleted){
            this.setState({deletePlatformModal: false});
            this.props.onPlatformDelete(platform);
            return;
        }
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, deletingPlatform: true});
        Api.deletePlatform(platform, source, user.userId).then(data => {
            this.setState({cancelToken: null, deletingPlatform: false});
        }).catch(err => {
            //Silent
        });
    }
    render() {
        const { classes, paymentData, subscription } = this.props;
        const { platform, loading, contentLoaded, response, addContent, contentId, lastKey, loadingMore, editMode, tooltips, 
            zeroContentModal, checkNewPostsModal, checkNewVideosModal, linktreeNoContentModal, weblinkZeroContentModal, buttonZeroContentModal,  
            youtubeZeroContentModal, deletePlatformModal, deletingPlatform } = this.state;
        const { user } = this.store.getState();
        if(contentId !== null){
            return (
                <WebLinkEditWizard match={this.props.match} store={this.store} onWizardClose={this.onWizardClose} platform={platform} />
            )
        }
        if(addContent === 1){
            return (
                <SearchableContentTypes subscription={subscription} paymentData={paymentData} store={this.store} history={this.history} match={this.props.match} onWizardClose={this.onWizardClose} platform={platform} type={platform.type}/>
            )
        }
        return (
            <div className={classes.mainContainer}>
                <GridItem xs={12} sm={12} md={10} lg={8} className={classes.mainGridItem} >
                    {
                        platform.type === "instagramDemo" || platform.type === "instagramAPI" || platform.type === "linktree" || platform.type === "youtubeAPI" ?
                            <div className={classes.platformDetail}>
                                {
                                    platform.type === "instagramAPI" ?
                                        <Link onClick={this.onEditSettings} href={"/dashboard/platformwizard/instagramlink/"+platform.contentPlatformId} className={classes.editSettings}>Edit Settings</Link>
                                    :
                                    <></>
                                }
                                {
                                    platform.type === "youtubeAPI" ?
                                        <Link onClick={this.onEditSettings} href={"/dashboard/platformwizard/youtube/"+platform.contentPlatformId} className={classes.editSettings}>Edit Settings</Link>
                                    :
                                    <></>
                                }
                                <CustomInput
                                    labelText={this.getSearchInputLabel()}
                                    inputProps={{
                                        value: this.getPlatformUrl(),
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                />
                                {
                                    platform.type === "instagramAPI" || platform.type === "youtubeAPI" || platform.type === "instagramDemo" ?
                                        <Button size="sm" justIcon color="danger" className={classes.platformDeleteButton} onClick={() => this.deletePlatformModal()}>
                                            <Close />
                                        </Button>
                                    :
                                    <></>
                                }
                            </div>
                        :
                        <></>
                    }
                    {/* {
                        platform.type === "instagramAPI" && this.isTokenExpired() ?
                            <SnackbarContent
                                message={
                                    <div className={classes.snackbarContent}>
                                        <p>We are having trouble accessing your Instagram account. <Link onClick={this.onPlatformReauth} href={"/dashboard/platformwizard/instagramlink/"+platform.contentPlatformId}>Click Here</Link> to fix it</p>                                        
                                    </div>
                                }
                                color="danger"
                            />
                        :
                        <></>
                    } */}
                    {
                        platform.type === "instagramAPI" && this.isTokenExpired() === false ?
                            <SnackbarContent
                                message={
                                    <div className={classes.snackbarContent}>
                                        <p>Check for new Instagram Posts immediately!</p>
                                        <Link underline="none" href="/" onClick={this.updatePlatformContent}>Click Here</Link>
                                    </div>
                                }
                                color="success"
                            />
                        :
                        <></>
                    }
                    {
                        platform.type === "youtubeAPI" && this.isTokenExpired() === false ?
                            <SnackbarContent
                                message={
                                    <div className={classes.snackbarContent}>
                                        <p>Check for new YouTube Videos immediately!</p>
                                        <Link underline="none" href="/" onClick={(e) => this.updatePlatformContent(e, 'checkNewVideosModal')}>Click Here</Link>
                                    </div>
                                }
                                color="success"
                            />
                        :
                        <></>
                    }
                    {
                        platform.type !== "button" ?
                            <GridContainer className={classes.gridContainer}>
                                <GridItem xs={7} sm={8} md={8} lg={8} className={classes.searchGridItem}>
                                    <CustomInput
                                        labelText="Search"
                                        onClear={() => this.clearSearch()}
                                        inputProps={{
                                            name: "searchPlatform",
                                            value: this.state.values.q,
                                            onChange: (e) => this.handleChange(e, 'q'),
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={5} sm={4} md={4} lg={4} className={classes.searchButtonGridItem} id="startMySearch">
                                    <Button color="primary" round onClick={() => this.startSearch()}>Search</Button>                            
                                </GridItem>
                            </GridContainer>
                        :
                        <></>
                    }
                    {
                        platform.type === "webLink" || platform.type === "button" ?
                            <Grid>
                                <Link href={"/dashboard/platform/"+platform.contentPlatformId+"?new=1"+(platform.type === "button" && response.length === 0 ? "&button=1": "")} underline="none" onClick={this.addNewContent}>
                                    <Button color="primary" round>
                                        <AddIcon className={classes.icons} />
                                        {
                                            platform.type === "webLink" ?
                                                "Add A New Web Link"
                                            :
                                                "Add A New Button"
                                        }
                                    </Button>
                                </Link>
                                {
                                    user.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('newWebLink', true)} />
                                    :
                                    <></>
                                }
                            </Grid>
                        :
                        <></>
                    }
                    <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing+" "+classes.gridContainer}>
                    {
                        contentLoaded ?
                            response.length > 0 ?
                                <>
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('searchLinkEditButton', true)} />
                                        :
                                        <></>
                                    }
                                    <ListView match={this.props.match} isButtonPlatform={ platform.type === "button" ? true : false } response={response} store={this.store} onEdit={this.onEdit} onWizardClose={this.onWizardClose} platform={platform} history={this.history} />
                                    {
                                        lastKey !== null && loadingMore === false && editMode === false ?
                                            <Button id="contentViewMore" className={classes.loadMore} color="primary" round onClick={(e) => this.startSearch(true)}>
                                                <RefreshIcon className={classes.icons} />View More
                                            </Button>
                                        :
                                            loadingMore === true ?
                                                <LoaderComponent align="center" />
                                            :
                                            <></>
                                    }
                                </>
                                
                            :
                            <GridItem>
                                <Warning>Nothing found.</Warning>
                            </GridItem>
                        :
                        <>
                        {
                            loading ?
                                <LoaderComponent align="left" />
                            :
                            <></>
                        }
                        </>
                    }
                    </GridContainer>
                    {
                        platform.type === "webLink" ?
                            <></>
                        :
                        <></>
                    }                    
                </GridItem>
                {
                    tooltips.newWebLink ?
                        <NewWebLinkTooltipModal open={tooltips.newWebLink} store={this.store} onClose={() => this.onTooltip('newWebLink', false)} />
                    :
                    <></>
                }
                {
                    tooltips.searchLinkEditButton ?
                        <SearchLinkTooltipModal open={tooltips.searchLinkEditButton} store={this.store} onClose={() => this.onTooltip('searchLinkEditButton', false)} />
                    :
                    <></>
                }     
                {
                    platform.type === "instagramAPI" ?
                        <NoticeZeroContent store={this.store} onClose={this.onNoticeClose} open={zeroContentModal} />                      
                    :
                        <></>
                }
                {
                    platform.type === "linktree" ?
                        <NoticeZeroLinktreeContent store={this.store} onClose={this.onNoticeClose} open={linktreeNoContentModal} />                      
                    :
                        <></>
                }
                {
                    platform.type === "webLink" ?
                        <NoticeZeroWeblinkContent store={this.store} onClose={this.onNoticeClose} open={weblinkZeroContentModal} />                      
                    :
                        <></>
                }
                {
                    platform.type === "button" ?
                        <NoticeZeroButtonContent store={this.store} onClose={this.onNoticeClose} open={buttonZeroContentModal} />                      
                    :
                        <></>
                }
                {
                    platform.type === "youtubeAPI" ?
                        <NoticeZeroYouTubeContent store={this.store} onClose={this.onNoticeClose} open={youtubeZeroContentModal} />                      
                    :
                        <></>
                }
                {
                    checkNewPostsModal ?
                        <NoticeCheckNewPosts store={this.store} onClose={this.onNewPostsNoticeClose} open={checkNewPostsModal} />                      
                    :
                        <></>
                }
                {
                    checkNewVideosModal ?
                        <NoticeCheckNewVideos store={this.store} onClose={this.onNewPostsNoticeClose} open={checkNewVideosModal} />                      
                    :
                        <></>
                }
                {
                    deletePlatformModal ?
                        <PlatformDeleteModal open={deletePlatformModal} wait={deletingPlatform} platform={platform} onClose={() => this.deletePlatformModal(false)} onSuccess={(deleted) => this.deletePlatformModalSuccess(deleted)} />                      
                    :
                        <></>
                }
            </div>
        );
    }
}

PlatformContent.defaultProps = {
    onPlatformEdit: function(){ }
}
PlatformContent.propTypes = {
    onPlatformEdit: PropTypes.func
}

export default withStyles(platformContentStyle)(PlatformContent);
