import React from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import Config from '../../../../Config';
import LoaderComponent from '../../components/Loader';
import { helper } from '../../../assets/js/utils/Element';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from "../../components/CustomButtons/Button";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Edit from "@material-ui/icons/Edit";
import Warning from "../../components/Typography/Warning.jsx";

import useStyles from "../../../assets/jss/catalog/viewPageStyle";

export default function CatalogView(props) {
    const store = props.store;
    const { user, authorized, topics } = store.getState();
    const { contentId } = props.match.params;
    const classes = useStyles();
    const [values, setValues] = React.useState({
        contentId: '',
        url: '',
        title: '',
        topic: '',
        keywords: '',
        affiliateUrl: '',
        discountCode: '',
        privateNotes: '',
        publicDescription: '',
        ownerId: '',
    });

    const [state, setState] = React.useState({
        open: false,
        cancelToken: null,
        notFound: false
    });

    React.useEffect(() => {
        return function cleanup() {
            if (state.cancelToken) {
                state.cancelToken.cancel('Request Cancelled')
            }
        };
    });

    if(state.cancelToken === null){
        loadContent();
    }
    
    function handleSubmit(e){
        e.preventDefault();        
    }
    function loadContent(){
        const ApiUrl = Config.getApiUrl()+"data/content/"+contentId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        setState({
            ...state,
            cancelToken: source
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0){
                setValues(res.data.response[0]);
            }else{
                setState({...state, notFound: true});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    function prepareUrl(href){
        if (!href.match(/^[a-zA-Z]+:\/\//)){
            href = 'http://' + href;
        }
        return href;
    }
    function goBack(){
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/profile/"+user.userId+"/catalog"} />
        }
    }
    function copyDiscountCode(code){
        helper.copyTextToClipboard(code);
        setState({
            ...state,
            open: true,
        });
    }
    function cancel(){
        setState({
            ...state,
            open: false,
        });
    }    
    return (
        <Container className="profile-edit-page-holder">
            <Grid item className={classes.container} xs={12} md={8} lg={8}>
                <GridItem xs={12} sm={8} md={8} lg={6}>
                    <h3 className={classes.title}>View Search Item</h3>
                </GridItem>                
                {
                    values.title.length > 0 ?
                        <form onSubmit={handleSubmit}>
                            <GridItem xs={12} sm={8} md={8} lg={6}>                             
                                <CustomInput
                                    id="outlined-title"
                                    labelText="Title"                                    
                                    inputProps={{
                                        value: values.title,
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                <FormControl className={classes.selectFormControl} fullWidth>
                                    <InputLabel
                                        htmlFor="outlined-topic"
                                        className={classes.selectLabel}
                                    >
                                        Topic
                                    </InputLabel>                                
                                    <Select
                                        id="outlined-topic"
                                        label="Topic"
                                        value={values.topic}
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            classes: { paper: classes.selectPaper }
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        inputProps={{
                                            name: "topic",
                                            id: "outlined-topic",
                                            disabled: true
                                        }}
                                    >
                                        {topics.map((topic, key) => (
                                            <MenuItem
                                                key={key} 
                                                value={topic.topicName}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                            >
                                                {topic.topicName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            {
                                values.publicDescription.trim().length > 0 ?
                                    <GridItem xs={12} sm={8} md={8} lg={6}>                             
                                        <CustomInput
                                            id="outlined-publicDescription"
                                            labelText="Public Description"                                    
                                            inputProps={{
                                                value: values.publicDescription,
                                                disabled: true,
                                                multiline: true,
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                :
                                <></>
                            }
                            
                            
                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                <GridContainer alignItems="center">
                                    <GridItem xs={8} sm={8} md={8} lg={8}>                                   
                                        <CustomInput
                                            id="outlined-url"
                                            labelText="Url"                                    
                                            inputProps={{
                                                value: values.url,
                                                disabled: true,
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3} lg={3}>
                                        <a href={prepareUrl(values.url)} rel="noopener noreferrer" target="_blank" className={classes.iconButton}>
                                            <Button type="button" color="primary" round className={classes.button}>Go</Button>
                                        </a>
                                    </GridItem>
                                </GridContainer>                                
                            </GridItem>
                            {
                                values.discountCode.trim().length > 0 ?
                                    <GridItem xs={12} sm={8} md={8} lg={6}> 
                                        <GridContainer alignItems="center">
                                            <GridItem xs={8} sm={8} md={8} lg={8}> 
                                                <CustomInput
                                                    id="outlined-discountCode"
                                                    labelText="Discount Code"                                    
                                                    inputProps={{
                                                        value: values.discountCode,
                                                        disabled: true,
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3}>
                                                <Button
                                                    color="primary"
                                                    className={classes.iconButton}
                                                    aria-label="Copy to Clipboard"
                                                    component="span"
                                                    round
                                                    onClick={() => copyDiscountCode(values.discountCode)}
                                                    justIcon
                                                    >
                                                    <CopyIcon />
                                                </Button>
                                            </GridItem>
                                        </GridContainer>                                
                                    </GridItem>
                                :
                                    <></>
                            }                            
                            {
                                values.affiliateUrl.trim().length > 0 ?
                                    <GridItem xs={12} sm={8} md={8} lg={6}>
                                        <GridContainer alignItems="center">
                                            <GridItem xs={8} sm={8} md={8} lg={8}> 
                                                <CustomInput
                                                    id="outlined-affiliateUrl"
                                                    labelText="Affiliate Url"                                    
                                                    inputProps={{
                                                        value: values.affiliateUrl,
                                                        disabled: true,
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3}>
                                                <a href={prepareUrl(values.affiliateUrl)} rel="noopener noreferrer" target="_blank" className={classes.iconButton}>
                                                    <Button type="button" color="primary" round className={classes.button}>Go</Button>
                                                </a>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                :
                                <></>
                            }                            
                                                                                    
                            {
                                (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") ?
                                    <>                                        
                                        <GridItem xs={12} sm={8} md={8} lg={6}>                             
                                            <CustomInput
                                                id="outlined-contentId"
                                                labelText="Content Id"                                    
                                                inputProps={{
                                                    value: values.contentId,
                                                    disabled: true,
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={8} md={8} lg={6}>                             
                                            <CustomInput
                                                id="outlined-ownerId"
                                                labelText="Owner Id"                                    
                                                inputProps={{
                                                    value: values.ownerId,
                                                    disabled: true,
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>                                        
                                    </>
                                :
                                <></>
                            }
                            <GridItem xs={12} sm={8} md={8} lg={6}> 
                                <Button type="button" color="primary" round onClick={goBack} className={classes.button}>
                                    <ArrowBackIcon className={classes.icons} />Back
                                </Button>
                                {
                                    authorized && user.userId === values.ownerId ?
                                        <Link component={RouterLink} to={"/"+contentId+"/edit"}>
                                            <Button type="button" color="primary" round className={classes.button}>
                                                <Edit className={classes.icons} />Edit
                                            </Button>
                                        </Link>
                                    :
                                    <></>
                                }
                                                                
                            </GridItem>
                        </form>
                    :
                    <>
                    {
                        state.notFound ?
                            <>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <Warning>Search item not found.</Warning>                                
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}> 
                                    <Button type="button" color="primary" round onClick={goBack} className={classes.button}>
                                        <ArrowBackIcon className={classes.icons} />Back
                                    </Button>                    
                                </GridItem>
                            </>
                        :
                            <LoaderComponent align="left" />
                    }
                    </>
                        
                }
            </Grid>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={state.open}
                autoHideDuration={1500}
                onClose={cancel}
                ContentProps={{
                'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Copied to Clipboard</span>}
                action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={cancel}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
            <Helmet>
                <title>Search My Social App Search Catalog Create</title>
            </Helmet>
        </Container>
    )
}
