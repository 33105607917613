/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { whiteColor } from "../../material-kit-pro-react";

const customImageUploadStyle = {
    preview: {
        cursor: "pointer"
    },
    imageUpload: {
        "& .thumbnail, .img-circle": {
            position: "relative"
        },
        "& .loading_icon": {
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            bottom: "0px",
            margin: "auto",
            animation: "customSpin 1.3s infinite linear",
            display: "none",
            color: whiteColor
        },
        "& .picture_loading": {
            "&:before": {
                content: "' '",
                backgroundColor: "rgba(0, 0, 0, 0.35)",
                position: "absolute",
                width: "100%",
                height: "100%"
            },
            "& .loading_icon": {
                display: "block"
            }
        }
    }
};

export default customImageUploadStyle;
