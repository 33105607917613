import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from '../../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
//Tooltip Modals
import DefaultViewTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/DefaultViewTooltipModal";
import ShowTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/ShowTooltipModal";
import ShowAnalyticsModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/ShowAnalyticsModal";
import ApplicationSattingTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/ApplicationSattingTooltipModal";
import ShowBetaTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/ShowBetaTooltipModal";
import LinkBehaviorTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/LinkBehaviorTooltipModal";
import LoginAsOptOutTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/LoginAsOptOutTooltipModal";
import FullstoryOptOutTooltipModal from "../../../components/ToolTips/Dashboard/ApplicationSettings/FullstoryOptOutTooltipModal";

import dashboardTabSettingsApplicationStyle from "../../../../assets/jss/dashboard/settings/dashboardTabSettingsApplicationStyle.js";

const DashboardTabApplicationSetting = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user, authUsername } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.allowBeta = false;
        if(values.userPreferences.allowBeta || authUsername !== null){
            this.allowBeta = true;
        }

        if(!values.userPreferences.hasOwnProperty("loginAsOptOut")){
            values.userPreferences.loginAsOptOut = false;
        }
        if(!values.userPreferences.hasOwnProperty("fullstoryOptOut")){
            values.userPreferences.fullstoryOptOut = false;
        }

        this.state = {
            values: values,
            saving: false,
            editSetting: false,
            tooltips: {
                defaultView: false,
                showToolTips: false,
                applicationSetting: false,
                showBetaFeatures: false,
                linkBehavior: false,
                loginAsOptOut: false,
                fullstoryOptOut: false,
                showAnalytics: false
            }
        };

        this.handlePrefChange = this.handlePrefChange.bind(this);
        this.handleGuestPrefChange = this.handleGuestPrefChange.bind(this);
        this.handlePrefViewOptionChange = this.handlePrefViewOptionChange.bind(this);
        this.handleLinkBehaviorChange = this.handleLinkBehaviorChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.editSetting = this.editSetting.bind(this);
    }
    handlePrefChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                userPreferences: {
                    ...this.state.values.userPreferences,
                    [name]: event.target.checked
                }                
            }
        });
    }
    handleGuestPrefChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    [name]: event.target.checked
                }                
            }
        });
    }
    handlePrefViewOptionChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                userPreferences: {
                    ...this.state.values.userPreferences,
                    [name]: event.target.value
                }                
            }
        });
    }
    handleLinkBehaviorChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    [name]: event.target.value
                }                
            }
        });
    }
    handleVisibilityChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                [name]: event.target.value === "true"
            }
        });
    }
    editSetting(editSetting = true){
        this.setState({editSetting: editSetting});
    }
    handleSubmit(event){
        event.preventDefault();
        const { user } = this.store.getState();
        this.setState({saving: true, editSetting: false});
        Api.updateUser(user.userId, this.state.values).then((data) => {
            this.setState({saving: false});
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser }});
                this.history.push("/dashboard/settings");
            }
        }).catch(err => {
            this.setState({saving: false});
        });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { values, saving, tooltips, editSetting } = this.state;
        const { authorized, user } = this.store.getState();
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const disabledSettings = (editSetting === false);
        return (
            <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                <h4 className={classes.title}>
                    Application Settings
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('applicationSetting', true)} />
                        :
                        <></>
                    }
                </h4>
                <form onSubmit={this.handleSubmit} noValidate>
                    <GridItem className={classes.buttonsGridContainer}>
                        {
                            editSetting === true ?
                                <div className={classes.saveButtons}>
                                    <Button type="button" color="white" onClick={() => this.editSetting(false)}>
                                        <ArrowBackIcon className={classes.arrow} /> Cancel
                                    </Button>
                                    <Button type="submit" color="success" >
                                        <SaveIcon className={classes.icons} /> Save
                                    </Button>
                                </div>
                            :
                                editSetting === false && saving === false ?
                                    <div className={classes.editButton+" "+classes.editButtonBottom}>
                                        <Button color="blue" onClick={() => this.editSetting()}>Edit</Button>
                                    </div>
                                :
                                saving ? <LoaderComponent align="left" /> : <></>
                        }
                        
                    </GridItem>
                    {
                        showBetaFeatures ?
                            <>
                                <GridItem>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showLabels}
                                            onChange={(e) => this.handlePrefChange(e, "showLabels")}
                                            value="1"
                                            disabled={disabledSettings}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Labels"
                                    />
                                </GridItem>
                                <GridItem>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showSidebarControl}
                                            onChange={(e) => this.handlePrefChange(e, "showSidebarControl")}
                                            value="1"
                                            disabled={disabledSettings}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Sidebar Control"
                                    />
                                </GridItem>
                                <GridItem>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showTips}
                                            onChange={(e) => this.handlePrefChange(e, "showTips")}
                                            value="1"
                                            disabled={disabledSettings}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Tool Tips"
                                    />
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('showToolTips', true)} />
                                </GridItem>
                                <GridItem>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showAnalytics}
                                            onChange={(e) => this.handlePrefChange(e, "showAnalytics")}
                                            value="1"
                                            disabled={disabledSettings}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Analytics"
                                    />
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('showAnalytics', true)} />
                                </GridItem>
                            </>
                        :
                        <></>
                    }
                    {
                        (authorized && user.hasOwnProperty("userPreferences") && this.allowBeta) ?
                        <GridItem>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={values.userPreferences.showBetaFeatures}
                                    onChange={(e) => this.handlePrefChange(e, "showBetaFeatures")}
                                    value="1"
                                    disabled={disabledSettings}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                                }
                                classes={{
                                label: classes.label,
                                root: classes.labelRoot
                                }}
                                label="Show Beta Features"
                            />
                            {
                                user.userPreferences.showTips ?
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('showBetaFeatures', true)} />
                                :
                                <></>
                            }
                        </GridItem>
                        :
                        <></>
                    }
                    {
                        showBetaFeatures ?
                        <>
                            <GridItem>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={values.guestPreferences.loginAsOptOut}
                                        onChange={(e) => this.handleGuestPrefChange(e, "loginAsOptOut")}
                                        value="1"
                                        disabled={disabledSettings}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Customer Support Login Opt-Out"
                                />
                                {
                                    user.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('loginAsOptOut', true)} />
                                    :
                                    <></>
                                }
                            </GridItem>
                            <GridItem>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={values.userPreferences.fullstoryOptOut}
                                        onChange={(e) => this.handlePrefChange(e, "fullstoryOptOut")}
                                        value="1"
                                        disabled={disabledSettings}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Customer Support Screen Recordings Opt-Out"
                                />
                                {
                                    user.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('fullstoryOptOut', true)} />
                                    :
                                    <></>
                                }
                            </GridItem>    
                        </>
                        :
                        <></>
                    }
                    {
                        showBetaFeatures ? 
                            <GridItem>
                                <div className={classes.inputLabel}>
                                    <label>Filter Options</label>
                                </div>
                                <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={values.userPreferences.searchFilterLinks}
                                            onChange={(e) => this.handlePrefChange(e, "searchFilterLinks")}
                                            value="1"
                                            name="searchFilterResults"
                                            icon={<Check className={classes.uncheckedIcon}/>}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            disabled={disabledSettings}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label="Links"
                                    />
                                </div>
                                <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={values.userPreferences.searchFilterInstagram}
                                            onChange={(e) => this.handlePrefChange(e, "searchFilterInstagram")}
                                            value="1"
                                            name="searchFilterResults"
                                            icon={<Check className={classes.uncheckedIcon}/>}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            disabled={disabledSettings}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label="Instagram Post"
                                    />
                                </div>
                                <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={values.userPreferences.searchFilterYoutube}
                                            onChange={(e) => this.handlePrefChange(e, "searchFilterYoutube")}
                                            value="1"
                                            name="searchFilterResults"
                                            icon={<Check className={classes.uncheckedIcon}/>}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            disabled={disabledSettings}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label="Youtube Video"
                                    />
                                </div>
                            </GridItem>
                        :
                        <></>
                    }
                    
                    {/* <GridItem>
                        <div className={classes.inputLabel}>
                            <label>Your Default View Options on All Search Pages 
                                {
                                    user.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('defaultView', true)} />
                                    :
                                    <></>
                                }
                            </label>
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.userPreferences.searchViewResults === "stream"}
                                    onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                    value="stream"
                                    name="searchViewResults"
                                    aria-label="Stream View"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Stream View"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.userPreferences.searchViewResults === "list"}
                                    onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                    value="list"
                                    name="searchViewResults"
                                    aria-label="List View"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="List View"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.userPreferences.searchViewResults === "grid"}
                                    onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                    value="grid"
                                    name="searchViewResults"
                                    aria-label="Large Grid"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Large Grid"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.userPreferences.searchViewResults === "threecolumngrid"}
                                    onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                    value="threecolumngrid"
                                    name="searchViewResults"
                                    aria-label="Small Grid"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Small Grid"
                            />
                        </div>
                        {
                            showBetaFeatures ?
                                <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                    <FormControlLabel
                                        control={
                                        <Radio
                                            checked={values.userPreferences.searchViewResults === "card"}
                                            onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                            value="card"
                                            name="searchViewResults"
                                            aria-label="Card View"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                            disabled={disabledSettings}
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                        }
                                        classes={{label: classes.label, root: classes.labelRoot}}
                                        label="Card View"
                                    />
                                </div>
                            :
                            <></>
                        }
                    </GridItem> */}
                    <GridItem>
                        <div className={classes.inputLabel}>
                            <label>Link Behavior When Clicking Your Search Results
                                {
                                    user.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('linkBehavior', true)} />
                                    :
                                    <></>
                                }
                            </label>
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.guestPreferences.linkBehavior === "showPreview" || !values.guestPreferences.linkBehavior}
                                    onChange={(e) => this.handleLinkBehaviorChange(e, "linkBehavior")}
                                    value="showPreview"
                                    name="linkBehavior"
                                    aria-label="Show Preview Panel"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Show Preview Panel"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.guestPreferences.linkBehavior === "clickThrough"}
                                    onChange={(e) => this.handleLinkBehaviorChange(e, "linkBehavior")}
                                    value="clickThrough"
                                    name="linkBehavior"
                                    aria-label="Click Through Immediately"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Click Through Immediately"
                            />
                        </div>
                    </GridItem>
                    <GridItem>
                        <div className={classes.inputLabel}>
                            <label>Search Page Visibility Setting
                            </label>
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.isPrivate}
                                    onChange={(e) => this.handleVisibilityChange(e, "isPrivate")}
                                    value="true"
                                    name="isPrivate"
                                    aria-label="Private Mode"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Private Mode"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                <Radio
                                    checked={values.isPrivate !== true}
                                    onChange={(e) => this.handleVisibilityChange(e, "isPrivate")}
                                    value="false"
                                    name="isPrivate"
                                    aria-label="Public Mode"
                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                    disabled={disabledSettings}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot
                                    }}
                                />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label="Public Mode"
                            />
                        </div>
                    </GridItem>
                    <GridItem className={classes.buttonsGridContainer}>
                        {
                            editSetting === true ?
                                <div className={classes.saveButtons}>
                                    <Button type="button" color="white" onClick={() => this.editSetting(false)}>
                                        <ArrowBackIcon className={classes.arrow} /> Cancel
                                    </Button>
                                    <Button type="submit" color="success" >
                                        <SaveIcon className={classes.icons} /> Save
                                    </Button>
                                </div>
                            :
                                editSetting === false && saving === false ?
                                    <div className={classes.editButton+" "+classes.editButtonBottom}>
                                        <Button color="blue" onClick={() => this.editSetting()}>Edit</Button>
                                    </div>
                                :
                                saving ? <LoaderComponent align="left" /> : <></>
                        }
                        
                    </GridItem>
                </form>
                {
                    tooltips.defaultView ?
                        <DefaultViewTooltipModal open={tooltips.defaultView} store={this.store} onClose={() => this.onTooltip('defaultView', false)} />
                    :
                    <></>
                }
                {
                    tooltips.showToolTips ?
                        <ShowTooltipModal open={tooltips.showToolTips} store={this.store} onClose={() => this.onTooltip('showToolTips', false)} />
                    :
                    <></>
                }
                {
                    tooltips.applicationSetting ?
                        <ApplicationSattingTooltipModal open={tooltips.applicationSetting} store={this.store} onClose={() => this.onTooltip('applicationSetting', false)} />
                    :
                    <></>
                }
                {
                    tooltips.showBetaFeatures ?
                        <ShowBetaTooltipModal open={tooltips.showBetaFeatures} store={this.store} onClose={() => this.onTooltip('showBetaFeatures', false)} />
                    :
                    <></>
                }
                {
                    tooltips.linkBehavior ?
                        <LinkBehaviorTooltipModal open={tooltips.linkBehavior} store={this.store} onClose={() => this.onTooltip('linkBehavior', false)} />
                    :
                    <></>
                }
                {
                    tooltips.loginAsOptOut ?
                        <LoginAsOptOutTooltipModal open={tooltips.loginAsOptOut} store={this.store} onClose={() => this.onTooltip('loginAsOptOut', false)} />
                    :
                    <></>
                }
                {
                    tooltips.fullstoryOptOut ?
                        <FullstoryOptOutTooltipModal open={tooltips.fullstoryOptOut} store={this.store} onClose={() => this.onTooltip('fullstoryOptOut', false)} />
                    :
                    <></>
                }
                {
                    tooltips.showAnalytics ?
                        <ShowAnalyticsModal open={tooltips.showAnalytics} store={this.store} onClose={() => this.onTooltip('showAnalytics', false)} />
                    :
                    <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabSettingsApplicationStyle)(DashboardTabApplicationSetting);