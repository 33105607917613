import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../CustomButtons/Button.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import CustomTagsInput from "../../CustomInput/CustomTagsInput.jsx";
import Slide from "@material-ui/core/Slide";
import searchTermsStyle from "../../../../assets/jss/dashboard/editmode/searchTermsStyle";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import instagramIcon from "../../../../assets/img/instagram-icon.png";
import youtubeIcon from "../../../../assets/img/youtube-icon.png";
import PlaceIcon from "@material-ui/icons/Place";
import { ReactComponent as FeedViewIcon } from "../../../../assets/icons/feed_view-2.svg";
import { ReactComponent as ListViewIcon } from "../../../../assets/icons/list_view-1.svg";
import { ReactComponent as ShoppingBagIcon } from "../../../../assets/icons/shopping_bag.svg";

import MaterialIcon from "@mdi/react";
import { mdiLink, mdiViewGrid, mdiGrid, mdiViewDashboard } from '@mdi/js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SearchTerms = class extends React.Component {
    constructor(props){
        super(props);

        const user = this.props.user;
        let filters = {
            allFilter: true,
            shoppableFilter: false,
            linksFilter: false,
            instagramFilter: false,
            youtubeFilter: false,
            locationFilter: false
        };
        if(user.guestPreferences.hasOwnProperty("filters")){
            filters =  Object.assign({}, user.guestPreferences.filters);
            filters['allFilter'] = false;
            if(!filters.shoppableFilter && !filters.instagramFilter && !filters.youtubeFilter && !filters.locationFilter){
                filters['allFilter'] = true;
            }
            if(filters.shoppableFilter && filters.instagramFilter && filters.youtubeFilter && filters.locationFilter){
                filters = {
                    allFilter: true,
                    shoppableFilter: false,
                    linksFilter: false,
                    instagramFilter: false,
                    youtubeFilter: false,
                    locationFilter: false
                };
            }
        }

        let viewType = "list";
        let showButtons = false;
        let contentPlatforms = [];
        let platforms = [];
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("defaultLayout")){
            viewType = user.guestPreferences.defaultLayout;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("contentPlatforms")){
            contentPlatforms = user.guestPreferences.contentPlatforms;
        }
        if(user.hasOwnProperty("contentPlatforms")){
            platforms = user.contentPlatforms;
        }

        this.state = {
            values: this.prepareValues(this.props.user),
            data: filters,
            open: false,
            tagInputValue: "",
            defaultTab: 1,
            viewType: viewType,
            showButtons: showButtons,
            contentPlatforms: contentPlatforms,
            platforms: platforms,
        };

        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleTags = this.handleTags.bind(this);
    }
    prepareValues(user){
        let values = Object.assign({}, user);
        if(!values.hasOwnProperty("topSearchTerms")){
            values.topSearchTerms = [];
        }else{
            let topSearchTermsStr = "";
            if(typeof(values.topSearchTerms) === "string"){
                topSearchTermsStr = values.topSearchTerms.trim();
                let topSearchTermslen = topSearchTermsStr.length;
                if(topSearchTermslen <= 0){
                    values.topSearchTerms = [];
                }else{
                    values.topSearchTerms = values.topSearchTerms.split(',');
                }
            }
        }
        return values;
    }
    handleTags = regularTags => {
        let { topSearchTerms } = this.state.values;
        if(typeof(topSearchTerms) === "object" && regularTags.length > topSearchTerms.length){
            const lastIndex = regularTags[regularTags.length - 1];
            topSearchTerms = [lastIndex].concat(topSearchTerms);
        }else{
            topSearchTerms = regularTags;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: topSearchTerms,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.values.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: [tagValue, ...topSearchTerms],
            },
            tagInputValue: ""
        })
    }
    renderSearchTerms(){
        const { values } = this.state;
        if(!values.hasOwnProperty("topSearchTerms")){
            return null;
        }
        const { user } = this.props;
        const color = user.guestPreferences.primaryColor;
        let searchTerms = [];
        values.topSearchTerms.map((term, key) => {
            searchTerms.push(
                <li key={key}>
                    <span className={"react-tagsinput-tag "+(color)}>
                        {term}
                        <a href="/" onClick={(e) => this.removeSearchTerm(e, term)}>{" "}</a>
                    </span>
                </li>
            );
            return null;
        });
        return searchTerms;
    }
    removeSearchTerm(e, term){
        e.preventDefault();
        const { topSearchTerms } = this.state.values;
        const index = topSearchTerms.indexOf(term);
        if(index > -1){
            topSearchTerms.splice(index, 1);
        }
        this.setState({
            values: {
                ...this.state.values,
                topSearchTerms: topSearchTerms
            }
        })
    }
    onTabChange(currentTab){
        this.setState({
            defaultTab: currentTab
        });
    }
    handleFilterOption(event, name){
        let { data } = this.state;
        if(name === "allFilter" && data.allFilter === true){
            return;
        }
        if(name === "allFilter" && data.allFilter === false){
            this.setState({
                data: {
                    ...this.state.data,
                    shoppableFilter: false,
                    linksFilter: false,
                    instagramFilter: false,
                    youtubeFilter: false,
                    locationFilter: false,
                    [name]: !data[name],
                },
                hasFilterChange: true,
                showAdvancedSearch: false,
            })
        }else{
            data = {
                ...this.state.data,
                allFilter: false,
                [name]: !data[name],
            };
            const state = {
                data: data,
                hasFilterChange: true,
            };
            if(data.shoppableFilter === false && data.linksFilter === false && data.instagramFilter === false 
                && data.youtubeFilter === false && data.locationFilter === false){
                state.data.allFilter = true;
            }
            this.setState(state);
        }
    }
    onSuccess(){
        const { values, data, viewType, showButtons, contentPlatforms } = this.state;
        if(data.allFilter){
            values.guestPreferences['filters'] = {
                shoppableFilter: true,
                linksFilter: true,
                instagramFilter: true,
                youtubeFilter: true,
                locationFilter: true,
            };
        }else{
            values.guestPreferences['filters'] = {
                shoppableFilter: data.shoppableFilter,
                linksFilter: data.linksFilter,
                instagramFilter: data.instagramFilter,
                youtubeFilter: data.youtubeFilter,
                locationFilter: data.locationFilter,
            };
        }

        values.guestPreferences['defaultLayout'] = viewType;
        values.guestPreferences['showButtons'] = showButtons;
        values.guestPreferences['contentPlatforms'] = contentPlatforms;
        this.props.onSuccess(values);
    }
    handleViewOptions(viewType){
        this.setState({
            viewType: viewType
        });
    }
    handleShowButtons(){
        const showButtons = !this.state.showButtons;
        this.setState({
            showButtons: showButtons,
        });
    }
    getContentPlatformsLabel(){
        const { contentPlatforms, platforms } = this.state;
        if(contentPlatforms.length <= 0){
            return "Don't show any content feed.";
        }
        let labelArray = [];
        platforms.map((userPlatform) => {
            if(contentPlatforms.indexOf(userPlatform.contentPlatformId) !== -1){
                switch(userPlatform.type){
                    case "instagramAPI":
                        labelArray.push("Instagram Posts");
                        break;
                    case "instagramDemo":
                        labelArray.push("Instagram Demo");
                        break;
                    case "youtubeAPI":
                        labelArray.push("YouTube Videos");
                        break;
                    case "webLink":
                        labelArray.push("Web links");
                        break;
                    default:
                        break;
                }
            }
            return null;
        });
        return ("Show: "+labelArray.join(", "));
    }
    getViewTypeLabel(){
        const { viewType } = this.state;
        let label = "";
        switch(viewType){
            case "stream":
                label = "Stream";
                break;
            case "list":
                label = "List View";
                break;
            case "grid":
                label = "Large Grid";
                break;
            case "threecolumngrid":
                label = "Small Grid";
                break;
            case "card":
                label = "Cards";
                break;
            default:
                break;
        }
        return label;
    }
    handlePlatformOptions(userPlatform){
        let { contentPlatforms } = this.state;
        let index = contentPlatforms.indexOf(userPlatform.contentPlatformId);
        if(index === -1){
            contentPlatforms.push(userPlatform.contentPlatformId);
        }else{
            contentPlatforms.splice(index, 1);
        }
        this.setState({
            contentPlatforms: contentPlatforms
        });
    }
    render() {
        const { classes, user } = this.props;
        const { values, defaultTab, data, viewType, showButtons, platforms, contentPlatforms } = this.state;
        var showBetaFeatures = (user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const { buttonStyle, theme } = user.guestPreferences;
        let isRounded = false;
        if(buttonStyle === "rounded"){
            isRounded = true;
        }
        let isDark = false;
        if(theme === "dark"){
            isDark = true;
        }
        return (
            <div className={(isDark ? classes.dark : "")+" "+(isRounded ? classes.roundTabs : "")}>
                <div className={classes.tabs}>
                    <Button onClick={() => this.onTabChange(1)} className={defaultTab === 1 ? "activeTab" : ""} color={defaultTab === 1 ? "custom" : "white"}>
                        Feed
                    </Button>
                    <Button onClick={() => this.onTabChange(2)} className={defaultTab === 2 ? "activeTab" : ""} color={defaultTab === 2 ? "custom" : "white"}>
                        Filters
                    </Button>
                    <Button onClick={() => this.onTabChange(3)} className={defaultTab === 3 ? "activeTab" : ""} color={defaultTab === 3 ? "custom" : "white"}>
                        Search Terms
                    </Button>
                </div>
                <div className={classes.tabsContent}>
                    <div className={classes.feedContainer+" "+(defaultTab !== 1 ? classes.hidden : "")}>
                        <Grid className={classes.layout}>
                            <span>Layout</span>
                            <GridItem className={classes.guestViewOptionsContainer}>
                                <GridItem className={classes.guestViewOptions}>
                                    <Button className={(viewType === "stream"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("stream")}>
                                        <FeedViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                                    </Button>
                                    <Button className={(viewType === "list"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("list")}>
                                        <ListViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                                    </Button>
                                    <Button className={(viewType === "grid"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("grid")}>
                                        <MaterialIcon path={mdiViewGrid} className="MuiSvgIcon-root" />
                                    </Button>
                                    <Button className={(viewType === "threecolumngrid"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("threecolumngrid")}>
                                        <MaterialIcon path={mdiGrid} className="MuiSvgIcon-root" />
                                    </Button>
                                    {
                                        showBetaFeatures ?
                                            <Button className={(viewType === "card"?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handleViewOptions("card")}>
                                                <MaterialIcon path={mdiViewDashboard} className="MuiSvgIcon-root" />
                                            </Button>
                                        :
                                        <></>
                                    }
                                </GridItem>
                                <p className={classes.viewTypeLabel}>{this.getViewTypeLabel()}</p>
                            </GridItem>
                        </Grid>
                        
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showButtons}
                                        onChange={(e) => this.handleShowButtons()}
                                        name="showButtons"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label={(showButtons ? "Show Buttons" : "Do Not Show Buttons")}
                            />
                        </div>
                        {
                            platforms.length > 0 ?
                                <Grid className={classes.platforms}>
                                    <p className={classes.mobileCenter}>Content</p>
                                    <GridItem className={classes.platformOptionsContainer}>
                                        <GridItem className={classes.platformOptions}>
                                            {
                                                platforms.map((userPlatform) => {
                                                    if(userPlatform.type !== "webLink" && userPlatform.type !== "instagramAPI" && userPlatform.type !== "instagramDemo" && userPlatform.type !== "youtubeAPI"){
                                                        return null;
                                                    }
                                                    return (
                                                        <Button key={userPlatform.contentPlatformId} className={(contentPlatforms.indexOf(userPlatform.contentPlatformId) !== -1?classes.activeViewOption:"")} type="button" color="transparent" justIcon onClick={() => this.handlePlatformOptions(userPlatform)}>
                                                            {
                                                                (userPlatform.type === "instagramAPI" || userPlatform.type === "instagramDemo") ?
                                                                    <img src={instagramIcon} className="instagram-icon" alt="instagram"/>
                                                                : (userPlatform.type === "webLink") ?
                                                                    <MaterialIcon path={mdiLink} className={classes.customIcon+" MuiSvgIcon-root"} />
                                                                : (userPlatform.type === "youtubeAPI") ?
                                                                    <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                                                :
                                                                <></>
                                                            }
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </GridItem>
                                        <p className={classes.viewTypeLabel}>{this.getContentPlatformsLabel()}</p>
                                    </GridItem>
                                </Grid>
                            :
                            <></>
                        }
                    </div>
                    <div className={classes.filtersContainer+" "+(defaultTab !== 2 ? classes.hidden : "")}>
                        <GridItem xs={12} lg={9} className={classes.filtersGrid}>
                            <p>Which filters do you want to offer?</p>
                            <ul>
                                <li>
                                    <Button color="transparent" simple round={isRounded} onClick={(e) => this.handleFilterOption(e,'allFilter')} className={(data.allFilter ? "active" : "")}>
                                        All
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'locationFilter')} className={(data.locationFilter ? "active" : "")}>
                                        <PlaceIcon />
                                    </Button>
                                </li>
                                {
                                    showBetaFeatures ?
                                        <li>
                                            <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'shoppableFilter')} className={(data.shoppableFilter ? "active" : "")}>
                                                <ShoppingBagIcon className={classes.materialIcon+" "+classes.customIcon+" MuiSvgIcon-root"} />
                                            </Button>
                                        </li>
                                    :
                                    <></>
                                }
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'instagramFilter')} className={(data.instagramFilter ? "active" : "")}>
                                        <img src={instagramIcon} alt="instagram" className="instagram-icon" />
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'youtubeFilter')} className={(data.youtubeFilter ? "active" : "")}>
                                        <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                    </Button>
                                </li>
                                <li>
                                    <Button color="transparent" justIcon round={isRounded} onClick={(e) => this.handleFilterOption(e,'linksFilter')} className={(data.linksFilter ? "active" : "")}>
                                        <MaterialIcon path={mdiLink} className={classes.materialIcon} />
                                    </Button>
                                </li>
                            </ul>
                        </GridItem>
                    </div>
                    <div className={classes.searchTermsContainer+" "+(defaultTab !== 3 ? classes.hidden : "")}>
                        <CustomTagsInput
                            tagProps={{
                                value: values.topSearchTerms,
                                onChange: this.handleTags,
                            }}
                            inputProps={{
                                placeholder: "Add a search term",
                                onBlur: this.handleTagsChange,
                                onChange: this.handleTagInput,
                                value: this.state.tagInputValue,
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            color={(user.userLevel >= 1 ? user.guestPreferences.primaryColor: "primary")}
                        />
                        <ul className={"react-tagsinput "+classes.searchTerms}>
                            {this.renderSearchTerms()}
                        </ul>
                    </div>
                </div>
                <div className={classes.iconsContainer}>
                    <Button justIcon onClick={() => this.props.onClose()} color="danger">
                        <Close />
                    </Button>
                    <Button justIcon onClick={() => this.onSuccess(values)} color="success">
                        <Save />
                    </Button>
                </div>
            </div>
        );
    }
};

SearchTerms.defaultProps = {
  open: false
};
SearchTerms.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(searchTermsStyle)(SearchTerms);
