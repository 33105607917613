import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customSelectStyle from "../../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = theme => ({
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...customSelectStyle,
    checkboxLabelCustom: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        },
        "& label": {
            color: "inherit"
        },
        "& span": {
            marginLeft: "20px",
        }
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.state = {
            allStates: this.props.allStates,
            ownedByMe: false,
            isPublic: false,
            notifyMe: false,
            ownedByMeState: "",
            isPublicState: ""
        };
    }
    componentWillReceiveProps(props){
        this.setState({allStates: props.allStates});
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        const { ownedByMe, isPublic } = this.state;
        if(ownedByMe === false || isPublic === false){
            this.setState({
                ownedByMeState: ownedByMe?"success":"error",
                isPublicState: isPublic?"success":"error"
            });
        }
        return (ownedByMe && isPublic);
    }
    handleToggle(name) {
        let newState = {
            [name+"State"]: this.state[name] ?"error":"success",
            [name]: !this.state[name]
        };
        this.setState(newState);
    };
    render() {
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const { allStates, ownedByMe, ownedByMeState, isPublic, isPublicState } = this.state;
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>Did we get it right?</h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        {
                            allStates.hasOwnProperty("account") ?
                                <CustomInput
                                    labelText="Instagram Handle"
                                    inputProps={{
                                        value: "https://instagram.com/"+allStates.account.username.replace(/\s/g, ""),
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                />
                            :
                            <></>
                        }
                        
                        {
                            allStates.hasOwnProperty("robot") ?
                                <>
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.searchable}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Make All Content Immediately Searchable"
                                        />
                                    </div>
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.hashtags}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Add all hashtags from the post caption to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.useFirstLink}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Automatically use the first link found as the Click-Through Url"
                                        />
                                    </div>
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.includeFirstCommentHashtags}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to add hashtags from the first comment to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.addRecognition}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to add recognized media to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.attemptGeoTag}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to Geo-Tag the posts"
                                        />
                                    </div>
                                    {
                                        showBetaFeatures ?
                                            <>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.retrieveAllMedia}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to retrieve all post media"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importGuides}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to Import Guides"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importReels}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to Import Reels"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importIGTV}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to import IGTV"
                                                    />
                                                </div>
                                            </>
                                        :
                                            <></>
                                    }
                                    {/* <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.featureNewInstagramPosts}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Automatically feature all new posts"
                                        />
                                    </div> */}
                                    {
                                        showBetaFeatures ?
                                            <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                <FormControlLabel
                                                    control={
                                                    <Checkbox
                                                        checked={allStates.robot.onlySmearchTags}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        disabled={true}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                            disabled: classes.disabledCheckboxAndRadio
                                                        }}
                                                    />
                                                    }
                                                    classes={{ label: classes.label, root: classes.labelRoot }}
                                                    label="Only upload posts with the #smearch or #searchme hashtags"
                                                />
                                            </div>
                                        :
                                        <></>
                                    }
                                    {/* <div className={classes.checkboxLabelCustom} >
                                        <label>
                                            How Often Should this Robot look for new content?
                                            <span>{allStates.robot.robotFrequencyLabel}</span>
                                        </label>
                                    </div>                                     */}
                                </>
                            :
                            <></>
                        }

                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("ownedByMe")}
                                    checked={ownedByMe}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    success={(ownedByMeState === "success" ? ownedByMeState: "")}
                                    error={(ownedByMeState === "error" ? ownedByMeState: "")}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label+" "+(ownedByMeState === "error"?classes.labelError:""), root: classes.labelRoot }}
                                label="This instagram account is owned by me"
                            />
                        </div>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("isPublic")}
                                    checked={isPublic}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    success={(isPublicState === "success" ? isPublicState: "")}
                                    error={(isPublicState === "error" ? isPublicState: "")}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label+" "+(isPublicState === "error"?classes.labelError:""), root: classes.labelRoot }}
                                label="This instagram account is not a private account"
                            />
                        </div>
                        {/* {
                            authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures ?
                                <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => this.handleToggle("notifyMe")}
                                            checked={notifyMe}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot,
                                                disabled: classes.disabledCheckboxAndRadio,
                                            }}
                                        />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label="Notify me about the beta feature to import historical Instagram posts"
                                    />
                                </div>
                            :
                            <></>
                        } */}
                    </GridItem>
                </GridContainer>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step3.defaultProps = {
    onMount: function(){ }
}
Step3.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step3);
