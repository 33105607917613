import {
    grayColor
} from "../../material-kit-pro-react.jsx";

const dashboardTabProfileEditStyle = theme => ({
    main: {
        padding: "0px",
        "& input[disabled]": {
            "-webkit-text-fill-color": grayColor[13],
            color: grayColor[13],
        }
    },
    imageUpload: {
        "& button.removeImage": {
            display: "none !important"
        },
        "& img": {
            maxWidth: "200px"
        },
        "& .ImageUploadButtons, & .thumbnail": {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
        "& .fileinput": {
            display: "block"
        },
        "& .info_tooltip_icon": {
            display: "block",
            margin: "0px auto"
        }
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
        textAlign: "center"
    },
    saveButtons: {
        display: "flex",
        justifyContent: "space-between",
    },
    viewInfo: {
        "& .MuiFormControl-root": {
            marginBottom: "0px"
        },
        "& .MuiInputBase-root.Mui-disabled": {
            background: "transparent",            
        }
    },
    socialLinks: {
        marginTop: theme.spacing(2),
    },
    socialGridItem: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    responsiveIconsGrid: {
        paddingRight: "0px",
        [theme.breakpoints.down('xs')]: {
            flexGrow: 0,
            maxWidth: "77%",
            flexBasis: "77%"
        }
    },
    socialLinkPreview: {
        fontSize: "12px",
        width: "100%"
    },
    socialLinksContent: {
        "& .MuiGrid-container": {
            marginLeft: "0px",
            marginRight: "0px"
        }
    },
    customIcon: {
        width: "41px",
        height: "41px",
        fontSize: "20px",
        minWidth: "41px",
        margin: ".3125rem 1px",
        verticalAlign: "bottom",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
        }
    },
    tagsTitle: {
        "& h6": {
            color: "#AAA !important",
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.42857",
            textTransform: "CAPITALIZE",
        }
    },
    selectFormControl: {
        margin: "17px 0 17px 0",
        position: "relative",
        "& label": {
            color: "#AAA !important",
            cursor: "text",
            zIndex: "1",
            fontSize: "14px",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "400",
            lineHeight: "1.42857",
            letterSpacing: "unset",
        },
        "& .MuiInput-underline:before , & .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "1px dotted "+grayColor[11],
        },
    },
    demographicsContent:{
        marginTop: theme.spacing(1),
        "& .private_icon": {
            cursor: "auto",
        },
        "& .MuiInputLabel-shrink": {
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        },
        "& .MuiFormLabel-root.MuiFormLabel-filled:not(.Mui-error)":{
            color: grayColor[12]+ " !important",
        },
    },
    "@media (max-width: 450px)": {
        lineBreak: {
            display: "block"
        }
    },
    editButton: {
        display: "flex",
        justifyContent: "flex-end"
    },
    editButtonBottom: {
        marginTop: "18px"
    },
});

export default dashboardTabProfileEditStyle;