import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import hideSetupProgressModal from "../../../assets/jss/dashboard/hideSetupProgressModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const HideSetupProgressModal = class extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.props.onClose()}
        aria-labelledby="deleteaccount-slide-title"
        aria-describedby="deleteaccount-slide-description"
      >
        <DialogContent
          id="deleteaccount-slide-description"
          className={classes.modalBody}
        >
          <p>Are you sure you want to hide this?</p>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button onClick={() => this.props.onSuccess()} color="danger" round>Yes</Button>
          <Button onClick={() => this.props.onClose()} color="info" round>No</Button>
        </DialogActions>
        
      </Dialog>
    );
  }
};

HideSetupProgressModal.defaultProps = {
  open: false,
};
HideSetupProgressModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(hideSetupProgressModal)(HideSetupProgressModal);
