import {
    dangerColor
} from "../../material-kit-pro-react.jsx";

const dashboardTabAccountSettingsStyle = theme => ({
    main: {
        padding: "0px",
        [theme.breakpoints.up("xs")]: {
            paddingLeft: "2px"
        }
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
    },
    formControl: {
        marginBottom: "5px"
    },
    changeButton: {
        alignSelf: "flex-end",
        paddingLeft: "0px",
        "& .MuiButton-root": {
            marginBottom: "0px",
            maxWidth: "calc(100% - 2px)"
        },
        "& .info_tooltip_icon": {
            position: "absolute",
            top: "50px",
            left: "47px",
            marginLeft: "0px"
        }
    },
    gridContainer: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px",
            marginRight: "0px",
            "& > .MuiGrid-item": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        }
    },
    cancelSubscription: {
        color: dangerColor[0]
    }
});

export default dashboardTabAccountSettingsStyle;