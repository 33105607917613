import customSelectStyle from "../../material-kit-pro-react/customSelectStyle.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";
import { 
    whiteColor, 
    blackColor, 
    grayColor, 
    defaultFont, 
    hexToRgb,
    primaryColor
} from "../../material-kit-pro-react.jsx";

const searchFormStyle = theme => ({
    ...customSelectStyle,
    ...modalStyle(theme),
    title: {
        marginBottom: "10px",
        marginTop: "-8px",
        fontSize: "14px"
    },
    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important",
        textAlign: "center"
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2)
    },
    form: {
        width: "100%",
        marginTop: "0px",
        marginBottom: "0px",
        paddingLeft: "4px",
        paddingRight: "4px",
        "& >div": {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "15px",
            paddingRight: "15px",
        }
    },
    startornment: {
        cursor: "pointer",
        height: "54px",
        position: "absolute",
        left: "0px",
        top: "0px",
        zIndex: "1",
        maxHeight: "none"
    },
    endornment: {
        cursor: "pointer",
        height: "auto",
        position: "absolute",
        right: "0px",
        top: "15px",
    },
    alignLeft: {
        textAlign: "left"
    },
    gridList: {
        width: "100%",
    },
    dropdownButton: {
        width: "auto !important",
        "& img": {
            maxWidth: "20px",
            width: "100%"
        }
    },
    hide: {
        display: "none"
    },
    dropdownGridItem: {
        display: "inline-flex",
        width: "auto",
        "& li": {
            display: "inline-flex",
            padding: "0px",
            "& button": {
                margin: "0px"
            }
        },
        "& li:hover": {
            backgroundColor: "transparent",
            color: grayColor[8],
            boxShadow: "none"
        }
    },
    fixedSlickDots: {
        position: "fixed",
        left: "0px",
        bottom: "0px",
        right: "0px"
    },
    slickDots: {
        position: "static"
    },
    coverPhoto: {
        width: "100%",
        borderRadius: "6px 6px 0px 0px"
    },
    modalRootCustom: {
        padding: "0px",
        marginBottom: "0px",
        [theme.breakpoints.down('xs')]: {
            margin: "16px 10px",
            marginBottom: "0px !important",
        }
    },
    modalPaperCustom: {
        marginTop: "16px !important",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important",
            marginLeft: "0px",
        }
    },
    modalCustom: {
        marginBottom: theme.spacing(2),
        overflowX: "hidden"
    },
    paperScrollPaperCustom: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            display: "block",
        }
    },
    scrollBodyCustom: {
        padding: "0px"
    },
    modalBodyCustom: {
        padding: "0px"
    },
    modalHeaderCustom: {
        padding: "0px !important",
        position: "absolute",
        zIndex: 5,
        right: "0px"
    },
    modalCloseButtonCustom: {
        background: whiteColor,
        margin: "0px",
        color: blackColor,
        opacity: 0.8,
        float: "none",
        "&:hover": {
            background: whiteColor,
            color: blackColor,
            opacity: 1,
        },
        "&:focus": {
            background: whiteColor,
            color: blackColor,
            opacity: 1,
        }
    },
    carousel: {
        "& button.slick-arrow.slick-next, button.slick-arrow.slick-prev": {
            transform: "none",
            top: "232px",
            bottom: "auto",
            width: "36px",
            height: "36px",
            margin: "auto",
            fontSize: "inherit",
            lineHeight: "inherit",
            color: whiteColor,
            boxShadow: "none",
            borderRadius: "100%",
            opacity: 1,
            background: "transparent",
            "& svg": {
                width: "36px",
                height: "36px",
                marginRight: "0px"
            }
        },
        "& button.slick-arrow.slick-next": {
            right: theme.spacing(1)
        },
        "& button.slick-arrow.slick-prev": {
            left: theme.spacing(1)
        },
        "& button.slick-arrow.slick-next:hover, button.slick-arrow.slick-prev:hover": {
            background: "transparent",
            boxShadow: "none"
        },
        "& button.slick-arrow.slick-disabled": {
            display: "none !important"
        },
        "& .slick-next:before, .slick-prev:before": {
            display: "none"
        },
        "& .slick-dots": {
            display: "block",
            top: "509px",
            bottom: "auto"
        },
        "& .slick-dots li, .slick-dots li button": {
            width: "14px",
            height: "14px"
        },
        "& .slick-dots li button::before": {
            border: "2px solid #000",
            borderRadius: "100%",
            boxShadow: "none",
        },
        "& .slick-dots li.slick-active button:before": {
            width: "10px",
            height: "10px",
            backgroundColor: blackColor,
            top: "0px"
        }
    },
    filterOptionsDropdown: {
        [theme.breakpoints.down('xs')]: {
            transform: "none !important",
            top: "50px !important"
        }
    },
    viewOptionsDropdown: {
        [theme.breakpoints.down('xs')]: {
            transform: "none !important",
            top: "50px !important",
            left: "auto !important",
            right: "0px !important"
        }
    },
    viewOptionsDropdownBeta: {
        "& .MuiPaper-root": {
            minWidth: "257px"
        }
    },
    clearSearch: {
        padding: "10px 12px",
        marginTop: "0px",
        marginBottom: "0px",
        marginRight: "0px",
        [theme.breakpoints.down("xs")]: {
            padding: "8px 8px",
        },
        "& svg": {
            color: whiteColor,
            margin: "0px"
        }
    },
    labelRoot: {
        ...defaultFont,
        color: grayColor[12] + " !important",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "1.42857",
        top: "10px",
        letterSpacing: "unset",
        textAlign: "left"
    },
    selectFormControlCustom: {
        margin: "0px 1px 5px 0px !important"
    },
    cancelIcon: {
        position: "absolute",
        top: "15px",
        right: "50px",
        cursor: "pointer",
        zIndex: "4",
    },
    mobileWithinSpacing: {
        "@media (max-width: 480px)": {
            paddingRight: "0px",     
        }
    },
    autocomplete: {
        paddingTop: "0px"
    },
    locationSearch: {
        "& .MuiFormControl-root": {
            marginBottom: "0px"
        }
    },
    searchFormContainer: {
        marginTop: theme.spacing(2),
        flexWrap: "nowrap",
        "& button": {
            margin: "0px",
            backgroundColor: whiteColor,
            border: "1px solid "+grayColor[11],
            color: blackColor,
            "&:hover, &:active, &:focus": {
                backgroundColor: whiteColor,
                color: blackColor,
            }
        },
        "& .form-grid": {
            // paddingLeft: "0px",
            // paddingRight: "0px"
        },
        "& .filter-grid": {
            paddingRight: "3px",
        },
        "& .search-grid": {
            paddingLeft: "3px",
        }
    },
    searchFormIcon: {
        display: "inline-flex",
        width: "auto",
        "& img": {
            maxWidth: "20px",
            width: "100%"
        },
        "& svg": {
            width: "26px",
            height: "26px"
        }
    },
    searchFormInput: {
        marginBottom: "0px",
        [theme.breakpoints.down("xs")]: {
            paddingRight: '1px',
        },
        "& label": {
            left: "50px",
            top: "-4px",
            color: theme.palette.custom.main+" !important",
            "&.Mui-focused": {
                transform: "translate(0, 24px) scale(1)",
                transition: "none",
                transformOrigon: "unset"
            },
            "&.MuiFormLabel-filled" :{
                display: "none",
            }
        },
        "& .MuiTextField-root, & [class*='MuiTextField-root-']": {
            backgroundColor: whiteColor,
            boxShadow:
                    "0 2px 2px 0 rgba(" +
                    hexToRgb(theme.palette.custom.main) +
                    ", 0.14), 0 3px 1px -2px rgba(" +
                    hexToRgb(theme.palette.custom.main) +
                    ", 0.2), 0 1px 5px 0 rgba(" +
                    hexToRgb(theme.palette.custom.main) +
                    ", 0.12)",
            border: "1px solid "+theme.palette.custom.main,
            "& .MuiAutocomplete-inputRoot, [class*='MuiAutocomplete-inputRoot-']": {
                paddingTop: "10px",
                paddingBottom: "11px",
                marginTop: "0px",
                paddingRight: "70px",
                paddingLeft: "50px",
                "&:before, &:after": {
                    border: "none !important",
                }
            },
        },
        "& svg": {
            top: "16px",
            "&.cancel-icon": {
                right: "70px",
            }
        },
        "&.hide-beta-features": {
            "& .MuiAutocomplete-inputRoot": {
                paddingRight: "46px",
            },
            "& .cancel-icon": {
                right: "46px",
            }
        },
        "& .searchButton": {
            border: "none",
            padding: "0px",
            width: "auto",
            height: "auto",
            backgroundColor: "transparent !Important",
            borderRadius: "0px",
            "& img": {
                maxWidth: "20px",
                width: "100%"
            },
            "& svg": {
                width: "26px",
                height: "26px",
                top: "0px",
            },
            "&.filterButton": {
                borderLeft: "none",
                borderRight: "1px solid "+theme.palette.custom.main,
                marginLeft: "0px",
                "& img": {
                    maxWidth: "20px",
                    width: "auto",
                    maxHeight: "26px",
                },
            }
        },
        "& .autosuggest-popper": {
            top: "54px",
            width: "100% !important",
            [theme.breakpoints.down("xs")]: {
                paddingRight: '1px'
            },
            "&>div": {
                backgroundColor: "transparent",
                boxShadow: "none",
                margin: "0px",
                borderRadius: "0px",
                "& ul": {
                    backgroundColor: whiteColor,
                    color: blackColor,
                    borderRadius: "0px",
                    margin: "0px",
                    border: "1px solid "+theme.palette.custom.main,
                    borderTop: "none",
                }
            }
        },
        "&.round-input": {
            "& .MuiTextField-root, [class*='MuiTextField-root-']": {
                borderRadius: "30px",
            },
            "&.options-open":{
                "& .MuiTextField-root, [class*='MuiTextField-root-']": {
                    borderRadius: "30px 30px 0px 0px",
                    borderBottom: "0px",
                    "&:after": {
                        content: '""',
                        borderBottom: "1px solid "+theme.palette.custom.main,
                        margin: "0 14px",
                    }
                },
            },
            "& .autosuggest-popper": {
                "&>div": {
                    borderRadius: "0px 0px 30px 30px",
                    "& ul": {
                        borderRadius: "0px 0px 30px 30px",
                        borderTop: "none",
                    }
                }
            }
        },
    },
    filtersGrid: {
        marginTop: theme.spacing(1),
        "& p": {
            textAlign: "left"
        },
        "& ul":{
            display: "flex",
            listStyleType: "none",
            paddingLeft: "0px",
            marginBottom: theme.spacing(0),
            paddingBottom: theme.spacing(1),
            borderBottom: "1px solid "+grayColor[11],
            "& li": {
                margin: "0px 3px",
                "&:first-child": {
                    marginLeft: "0px",
                },
                "&:last-child": {
                    marginRight: "0px",
                }
            },
            "& button": {
                margin: "0px",
                padding: "0px",
                minWidth: "0px",
                lineHeight: "0px",
                maxWidth: "100%",
                height: "41px",
                width: "41px",
                color: grayColor[15],
                "&:hover": {
                    color: grayColor[15],
                },
                "&.active": {
                    border: "1px solid "+grayColor[12]
                },
                "& i": {
                    lineHeight: "41px !important",
                    fontSize: "20px !important"
                },
                "& svg": {
                    height: "24px",
                    width: "24px",
                    display: "block"
                },
                "& img": {
                    width: "20px",
                    display: "block",
                    "&.youtube-icon": {
                         width: "25px",
                    }
                },
            },
            "& .active button": {
                backgroundColor: primaryColor[0],
                boxShadow:
                    "0 2px 2px 0 rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.14), 0 3px 1px -2px rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.2), 0 1px 5px 0 rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.12)",
                color: whiteColor,
                "&:hover": {
                    color: whiteColor,
                },
            },
        },
    },
    advanceSearch: {
        marginTop: theme.spacing(1),
        "& .custom-select": {
            marginBottom: "0px !important"
        }
    },
    dark: {
        "& $searchFormContainer": {
            "& label": {
                // color: whiteColor,
            },
            "& .MuiTextField-root, [class*='MuiTextField-root-']": {
                backgroundColor: blackColor,
                "& .MuiAutocomplete-inputRoot, [class*='MuiAutocomplete-inputRoot-']": {
                    "& input": {
                        color: whiteColor
                    }
                },
            },
            "& .autosuggest-popper": {
                "&>div ul": {
                    backgroundColor: blackColor,
                    color: whiteColor
                }
            },
            "& svg": {
                color: whiteColor
            },
            "& button": {
                backgroundColor: grayColor[0],
                color: whiteColor,
                "&:hover, &:active, &:focus": {
                    backgroundColor: grayColor[0],
                    color: whiteColor,
                    borderRadius: "0px",
                }
            },
        },
        "& $filtersGrid": {
            "& button": {
                fill: whiteColor,
                color: whiteColor,
                "&:hover": {
                    color: whiteColor
                }
            },
            "& ul": {
                // borderBottom: "1px solid "+whiteColor,
            }
        },
        "& $advanceSearch": {
            "& label, & .MuiSelect-select, & svg": {
                color: whiteColor+" !Important" ,
            }
        },
        "& $searchTermsContainer": {
            background: blackColor,
            "& p, input, input::placeholder, input::-webkit-input-placeholder, input:-ms-input-placeholder": {
                color: whiteColor
            }
        },
        "& $gridSocialContainerSpacing": {
            color: whiteColor,
            border: "1px dashed "+whiteColor,
            "& input, & textarea": {
                color: whiteColor
            },
            "& svg:last-child": {
                fill: whiteColor
            },
            "& label": {
                color: whiteColor
            },
            background: blackColor+" !important",
            "& .medium-icon": {
                "& > span:before": {
                    content: "' '",
                    display: "block",
                    background: whiteColor,
                    height: "12px",
                    width: "12px",
                    position: "absolute",
                    zIndex: "-1",
                    left: "0px",
                    right: "0px",
                    top: "0px",
                    bottom: "0px",
                    margin: "auto"
                },
                "& img": {
                    border: "1px solid "+grayColor[1],
                    borderRadius: "100%",
                    padding: "1px",
                    zIndex: 1
                }
            },
            "& .tiktok-icon img": {
                background: grayColor[1],
                borderRadius: "12px",
                padding: "1px"
            },
            "& $sortableList": {
                "& .medium-icon": {
                    "& > span:before": {
                        height: "24px",
                        width: "24px",
                    },
                },
            }
        },
        "& $gridSocialContainerSpacing p": {
            color: whiteColor
        }
    },
    loadMore: {
        visibility: "hidden",
    },
    autocompleteOverlay: {
        position: "absolute",
        width: "calc( 100% - 31px)",
        height: "54px",
        top: "0px",
        background: "transparent",
        cursor: "pointer",
        zIndex: "2"
    },
    platformButton: {
        width: "calc( 100% + 22px)",
        marginLeft: "-11px",
        padding: "19.6px 8px",
        textTransform: "none",
        fontWeight: "bold",
        marginBottom: "16px",
        marginTop: "2px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "8px",
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            width: "100%"
        },
        borderStyle: "dashed",
        color: blackColor,
        "&:hover, &:focus": {
            color: blackColor,
            borderStyle: "dashed",
        }
    },
    platformButtonDark: {
        background: blackColor,
        color: whiteColor,
        borderColor: whiteColor,
        "&:hover, &:focus": {
            background: blackColor,
            color: whiteColor,
            borderColor: whiteColor,
        }
    },
    closeLinktreeIcon: {
        position: "absolute !important",
        right: "3px",
        width: "28px",
        height: "28px",
        padding: "3px",
        cursor: "pointer",
        zIndex: "1",
        marginTop: "5px",
        [theme.breakpoints.down("xs")]: {
            right: "18px"
        }
    },
    searchTermsActive: {
        "& .form-grid": {
            
        },
        "& [class*='MuiTextField-root']": {
            boxShadow: "none"
        },
        "& .round-input": {
            borderRadius: "30px 30px 0px 0px",
            "& [class*='MuiTextField-root']": {
                borderRadius: "30px 30px 0px 0px",
                borderBottom: "0px"
            },
            "& [class*='MuiTextField-root']:after": {
                content: '""',
                borderBottom: "1px solid "+theme.palette.custom.main,
                margin: "0 14px",
            }
        }
    },
    searchTermsContainer: {
        background: whiteColor,
        padding: "0px 15px 15px 15px",
        border: "1px solid",
        borderTop: "0px",
        "&.round": {
            borderRadius: "0px 0px 30px 30px",
        }
    },
    refreshPage: {
        position: "absolute",
        right: "15px",
        top: "-247px",
        [theme.breakpoints.down("sm")]: {
            top: "-217px",
        },
        "@media screen and (max-width: 830px)": {
            right: '35px'
        },
        [theme.breakpoints.down("xs")]: {
            top: "-122px",
        }
    },
    spinning: {
        animation: "customSpin 1.3s infinite linear"
    },
    freeAdButton: {
        border: "0px",
        color: whiteColor+" !important",
        whiteSpace: "normal",
        "&.rounded $whyThisAd": {
            paddingRight: "17px !important",
            width: "107px",
        }
    },
    freeAdButtonDark: {
        color: theme.palette.custom.buttonText+" !important",
        "& $whyThisAd": {
            color: theme.palette.custom.buttonText+" !important",
        }
    },
    whyThisAd: {
        position: "absolute",
        width: "90px",
        right: "0px",
        bottom: "0px",
        padding: "2px 5px !important",
        color: whiteColor+" !important",
        fontWeight: "normal",
        fontStyle: "italic",
        fontSize: "12px",
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            padding: "1px 5px !important",
        }
    },
    classic: {
        //No Change
    },
    simple: {
        "& $refreshPage": {
            right: "45px",
            top: "-92px",
            [theme.breakpoints.down("sm")]: {
                right: "30px",
                top: "-72px"
            },
            [theme.breakpoints.down("xs")]: {
                right: "45px",
                top: "-82px"
            }
        },
        "& $gridSocialContainerSpacing": {
            background: whiteColor
        }
    },
    gridSocialContainerSpacing: {
        marginTop: "20px",
        textAlign: "center",
        border: "1px dashed "+grayColor[0],
        padding: theme.spacing(2),
        cursor: "pointer",
        color: grayColor[1],
        marginLeft: "-15px",
        marginRight: "-15px",
        [theme.breakpoints.up("md")]: {
            marginLeft: "-11px",
            marginRight: "-11px"
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: "0px",
            marginRight: "0px",
            padding: theme.spacing(1)
        },
        "& p": {
            marginBottom: "0px"
        }
    },
    userAvatarContainer: {
        maxWidth: "none",
        flexBasis: "auto",
        paddingRight: "0px",
        paddingLeft: "0px",
        margin: "0px auto"
    },
    socialLinksContent: {
        "& [class*='MuiGrid-container']": {
            marginLeft: "0px",
            marginRight: "0px"
        }
    },
    sortableList: {
        paddingLeft: "0px",
        "& li": {
            listStyleType: "none",
            paddingLeft: "30px",
        }
    },
    sortableListEnabled: {
        "& li > span": {
            height: "100px",
            cursor: "move"
        }
    },
    sortIcon: {
        position: "absolute",
        left: "0px",
        height: "65px",
        display: "flex",
        alignItems: "center",
        paddingRight: "5px"
    },
    iconsContainer: {
        textAlign: "right",
        width: "100%",
        "& button": {
            marginLeft: "5px"
        }
    },
    socialIconsContainer: {
        "& ul": {
            padding: "0px",
            marginBottom: "0px"
        },
        "& li": {
            display: "inline-block",
            height: "33px",
            width: "33px",
            verticalAlign: "middle",
            "& svg": {
                width: "25px",
                height: "25px",
                fontSize: "20px",
                minWidth: "25px",
                margin: ".3125rem 1px",
            },
            "&:hover svg": {
                boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
            },
            "& button": {
                minWidth: "25px",
                width: "25px",
                height: "25px",
                "& i": {
                    lineHeight: "28px !important",
                    fontSize: "16px !important"
                }
            }
        },
        "& p": {
            color: blackColor,
            fontWeight: "bold"
        }
    },
    addSocialIcon: {
        "& >div": {
            marginRight: "0px !Important",
        },
        "& *, & *:before, & *:after": {
            border: "none !important"
        },
        "& label": {
            transform: "none !Important",
            fontWeight: "bold",
            color: theme.palette.custom.main+" !important",
            textDecoration: "underline"
        },
        "& svg": {
            display: "none"
        },
        "& [class*='MuiInputBase-root']": {
            marginTop: "0px",
            zIndex: "1"
        }
    },
    socialIconsAddButton: {
        willChange: "box-shadow, transform",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.12)",
        "&:hover,&:focus": {
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.2)"
        },
    }
});

export default searchFormStyle;