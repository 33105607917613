import {
    
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "../tabs/dashboardSubTabs";

const dashboardTabProfileStyle = theme => ({
    ...dashboardSubTabs(theme),
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
    },
});

export default dashboardTabProfileStyle;