import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StripeCard from "../../../components/Stripe/Card";
import {StripeProvider, Elements} from 'react-stripe-elements';
import axios from 'axios';
import Api from '../../../../assets/js/utils/Api';
import Config from '../../../../../Config';
import { helper } from "../../../../assets/js/utils/Element";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import PaymentIcon from "@material-ui/icons/Payment";
import LoaderComponent from "../../../components/Loader";
import Danger from "../../../components/Typography/Danger";
import Success from "../../../components/Typography/Success";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import customSelectStyle from "../../../../assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import paymentMethodPageStyle from "../../../../assets/jss/payment/paymentMethodPageStyle";

import {
    infoColor,
    cardTitle,
    dangerColor
} from "../../../../assets/jss/material-kit-pro-react.jsx";
const style = theme => ({
    ...paymentMethodPageStyle(theme),
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    cardTitle,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    formControl: {
        marginBottom: "5px",
    },
    tagsFormControl: {
        paddingTop: "5px"
    },
    selectFormControl: {
        "& .info_tooltip_icon": {
            marginLeft: "5px",
            color: "#495057"
        },
        "& .private_icon": {
            cursor: "auto",
        },
        "& .MuiInputLabel-shrink": {
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        }
    },
    packagePrice: {
        textAlign: "center",
        overflow: "hidden",
        "& span": {
            fontWeight: "bold"
        },
        "& .monthly_price": {
            marginRight: "10px"
        },
        "& .yearly_price": {
            marginLeft: "10px"
        }
    },
    cardInfoText: {
        marginBottom: "0px",
        marginTop: "5px"
    },
    textInfo: {
        color: infoColor[0] + " !important"
    },
    card: {
        marginTop: "0px",
        "& h1": {
            marginTop: "0px"
        },
        "& ul": {
            marginBottom: "0px",
            maxWidth: "425px",
            "& li": {
                padding: "6px 0px"
            }
        }
    },
    cardBody: {
        paddingRight: "0px !Important",
        paddingLeft: "0px !important"
    },
    alignRight: {
        textAlign: "right",
        "& p, & label": {
            textAlign: "left"
        },
        "& p.informationSafe": {
            textAlign: "right",
        }
    },
    requiredCard: {
        color: dangerColor[0],
        textAlign: "center !important"
    },
    applyButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        const { user } = this.props.store.getState();
        let { packages } = this.props.store.getState();
        // if(authorized && (!user.hasOwnProperty("userPreferences") || !user.userPreferences.showBetaFeatures)){
        //     packages = [packages[0]];
        // }
        this.store = this.props.store;
        let username = user.username;
        if(username === user.userId || username.trim().length <= 0){
            username = "";
        }
        let userLevel = 0;
        let userLevelLabel = "";
        let features = [];
        let userPackage = null;
        let filteredPackages = [];
        if(packages.length > 0){
            packages.map(packageObj => {
                if(packageObj.hasOwnProperty("legacy") && packageObj.legacy){
                    return null;
                }
                filteredPackages.push(packageObj);
                return null;
            });
            const defaultPackage = this.getDefaultUserPackage(filteredPackages);
            userLevel = defaultPackage.level;
            userLevelLabel = defaultPackage.name;
            features = defaultPackage.features;
            userPackage = defaultPackage;

            if(user.hasOwnProperty("userLevel") && defaultPackage.level === 0){
                userLevel = user.userLevel;
                filteredPackages.map(packageObj => {
                    if(user.userLevel === packageObj.level){
                        userLevelLabel = packageObj.name;
                        features = packageObj.features;
                        userPackage = packageObj;
                    }
                    return null;
                });
            }
        }

        let topSearchTerms;
        if(!user.hasOwnProperty("topSearchTerms")){
            topSearchTerms = [];
        }else{
            let topSearchTermsStr = user.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                topSearchTerms = [];
            }else{
                topSearchTerms = user.topSearchTerms.split(',');
            }
        }

        this.billingDuration = ["Monthly", "Annually"];

        this.state = {
            defaultUsername: username,
            username: username,
            usernameState: username.trim().length > 0 ? "success":"",
            userLevel: userLevel,
            userLevelLabel: userLevelLabel,
            paymentToken: "",
            paymentLast4Digits: "",
            paymentExpirationDate: "",
            stripeData: null,
            discountCode: "",
            cancelToken: null,
            cancelTokenUsername: null,
            saving: false,
            validatingUsername: false,
            requiredCard: false,
            showTips: false,
            tooltips: {
                showToolTips: false,
                informationSafe: false
            },
            bio: user.bio,
            topSearchTerms: topSearchTerms,
            tagInputValue: "",
            billingFrequency: "Monthly",
            features: features,
            userPackage: userPackage,
            blogUrl: user.blogUrl,
            editCard: false,
            cardSuccess: false,
            showAuthorize: false,
            savedMethod: false,
            validatingCouponCode: false,
            coupon: null,
            isCouponValid: null //null for not checked, false for invalid and true for invalid
        };
        this.validateUsernameTimeout = null;
        this.packages = filteredPackages;
        this.onMethodChange = this.onMethodChange.bind(this);
        this.validateUsername = this.validateUsername.bind(this);
        this.handlePrefChange = this.handlePrefChange.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editCard = this.editCard.bind(this);
        this.authorize = this.authorize.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
        this.applyCoupon = this.applyCoupon.bind(this);
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.props.onMount(that.state);
        },100);
        this.loadPaymentMethod();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        if (this.state.cancelTokenUsername) {
            this.state.cancelTokenUsername.cancel('Request Cancelled')
        }
    }
    componentDidUpdate(prevProps, prevState){
        const { userLevel, paymentLast4Digits, coupon } = this.state;
        if(userLevel !== prevState.userLevel || paymentLast4Digits !== prevState.paymentLast4Digits || coupon !== prevState.coupon){
            if(this.state.userLevel !== 0 && coupon !== null && coupon.percent_off === 100){
                this.props.onIsValidChange(false);
                this.setState({requiredCard: false});
                return;
            }            
            if(this.state.userLevel !== 0 && this.state.paymentLast4Digits.length <= 0){
                this.props.onIsValidChange(true);
            }else{
                this.props.onIsValidChange(false);
            }
        }
    }
    sendState() {
        return this.state;
    }
    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
          case "length":
            if (this.verifyLength(event.target.value, stateNameEqualTo)) {
              this.setState({ [stateName + "State"]: "success" });
            } else {
              this.setState({ [stateName + "State"]: "error" });
            }
            break;
          default:
            break;
        }
        if(stateName === "userLevel"){
            let userLevelLabel = "";
            const userLevel = event.target.value;
            this.packages.map(packageObj => {
                if(userLevel === packageObj.level){
                    userLevelLabel = packageObj.name;
                }
                return null;
            });
            this.setState({ [stateName]: event.target.value, userLevelLabel: userLevelLabel });
        }else{
            let newState = { [stateName]: event.target.value };
            if(stateName === "username"){
                newState['usernameState'] = "error";
                newState['validatingUsername'] = true;
            }
            this.setState(newState);
        }
    }
    isValidated() {
        if(this.state.saving){
            return false;
        }
        const { coupon, userLevel, paymentLast4Digits } = this.state;
        if(userLevel !== 0 && coupon !== null && coupon.percent_off === 100){
            window.scrollTo(0,0);
            this.updateUserOnboarding();
            return true;
        }
        if(userLevel !== 0 && paymentLast4Digits.length <= 0){
            this.setState({requiredCard: true});
            return false;
        }
        window.scrollTo(0,0);
        this.updateUserOnboarding();
        return true;
    }
    onMethodChange(cardDetails){
        // const source = axios.CancelToken.source(); 
        this.setState({
            ...this.state,
            paymentToken: cardDetails.paymentToken,
            paymentLast4Digits: cardDetails.paymentLast4Digits,
            paymentExpirationDate: cardDetails.paymentExpirationDate,
            stripeData: cardDetails.stripeData,
            // cancelToken: source,
            // saving: true,
            requiredCard: false,
            authorize: false,
            cardSuccess: true,
            showAuthorize: false,
            savedMethod: false
        });
        // Api.createPaymentMethod(cardDetails, source).then((data) => {
        //     this.setState({
        //         cancelToken: null,
        //         saving: false,
        //     });
        // }).catch(err => {
        //     if(err && err.message !== "Request Cancelled"){
        //         this.setState({
        //             cancelToken: null,
        //             saving: false,
        //             requiredCard: true
        //         });
        //     }
        // });
    }
    validateUsername(){
        if(this.state.cancelTokenUsername){
            this.state.cancelTokenUsername.cancel('Cancelled');
        }
        if(this.state.username.length <= 0){
            this.setState({
                usernameState: "error",
                validatingUsername: false
            });
            return;
        }
        if(this.state.username === this.state.defaultUsername){
            this.setState({
                usernameState: "success",
                validatingUsername: false
            });
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            validatingUsername: true,
            cancelTokenUsername: source
        });        
        Api.checkUsername(this.state.username, source).then((data) => {
            this.setState({
                cancelTokenUsername: null,
                validatingUsername: false,
                usernameState: "error"
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelTokenUsername: null,
                    validatingUsername: false,
                    usernameState: "success"
                });
            }
        });
    }
    handlePrefChange(event, name) {
        this.setState({
            [name]: event.target.checked
        });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    handleTags = regularTags => {
        this.setState({
            topSearchTerms: regularTags,
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            topSearchTerms: [...topSearchTerms, tagValue],
            tagInputValue: ""
        })
    }
    handleChange(event, stateName) {
        let userLevelLabel = "";
        const userLevel = event.target.value;
        let features = [];
        let userPackage = this.state.userPackage;
        this.packages.map(packageObj => {
            if(userLevel === packageObj.level){
                userLevelLabel = packageObj.name;
                features = packageObj.features;
                userPackage = packageObj;
            }
            return null;
        });
        this.setState({ 
            [stateName]: event.target.value, 
            userLevelLabel: userLevelLabel, 
            features: features,
            userPackage: userPackage
        });
    }
    billingFrequencyChange(tabId){
        this.setState({
            billingFrequency: (tabId === 0 ? "Monthly" : "Annually")
        });
    }
    onCardChange(){
        this.setState({showAuthorize: true, cardSuccess: false});
    }
    editCard(){
        this.setState({showAuthorize: true, cardSuccess: false});
    }
    authorize(){
        this.setState({authorize: true, requiredCard: false});
    }
    getUserTitle(){
        const { allStates } = this.props;
        const { authorized, user } = this.store.getState();
        if(allStates.hasOwnProperty("profile")){
            const profile = allStates.profile;
            let userName = "";
            if(profile.hasOwnProperty("firstname")){
                userName = profile.firstname;
            }
            if(profile.hasOwnProperty("lastname") && profile.lastname.length > 0){
                userName += " "+profile.lastname;
            }
            return userName;
        }
        if(!authorized){
            return "";
        }
        let userName = "";
        if(user.hasOwnProperty("firstName")){
            userName = user.firstName;
        }
        if(user.hasOwnProperty("lastName") && user.lastName.length > 0){
            userName += " "+user.lastName;
        }
        return userName;
    }
    loadPaymentMethod(){
        const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source();
        const that = this;
        that.setState({
            cancelToken: source
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0 && res.data.response[0].hasOwnProperty("paymentLast4Digits")){
                const response = res.data.response[0];
                that.setState({
                    paymentToken: response.paymentToken,
                    paymentLast4Digits: response.paymentLast4Digits,
                    paymentExpirationDate: response.paymentExpirationDate,
                    cancelToken: null,
                    showAuthorize: false,
                    savedMethod: true
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onDiscountCodeClear(){
        this.setState({
            discountCode: "",
            isCouponValid: null,
            coupon: null
        });
    }
    applyCoupon(){
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source,
            validatingCouponCode: true,
            isCouponValid: null,
            coupon: null
        });
        Api.checkCoupon(this.state.discountCode, source).then((stripeCoupon) => {
            let validCoupon = true;
            if(stripeCoupon.redeem_by && (stripeCoupon.redeem_by*1000) <= Date.now()){
                validCoupon = false;
            }
            if(stripeCoupon.max_redemptions && stripeCoupon.max_redemptions <= stripeCoupon.times_redeemed){
                validCoupon = false;
            }
            if(validCoupon){
                this.setState({
                    cancelToken: null,
                    validatingCouponCode: false,
                    isCouponValid: true,
                    coupon: stripeCoupon
                });
            }else{
                const errorMessage =  { code : 403, message : "Invalid Coupon" };
                throw errorMessage;
            }
            
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    validatingCouponCode: false,
                    isCouponValid: false,
                    coupon: null
                });
            }
        });
    }
    getDefaultUserPackage(packages){
        const refLink = helper.getParam("refLink");
        if(refLink === "cta_premium" && packages.length > 1){
            return packages[1];
        }
        if(refLink === "cta_premiumplus" && packages.length > 2){
            return packages[2];
        }
        return packages[0];
    }
    updateUserOnboarding(){
        const { user } = this.store.getState();
        if(user.userPreferences.hasOwnProperty("onboarding") && user.userPreferences.onboarding.hasOwnProperty("account") && 
            user.userPreferences.onboarding.account){
            return;
        }
        if(!user.userPreferences.hasOwnProperty("onboarding")){
            user.userPreferences['onboarding'] = {};
        }
        user.userPreferences.onboarding['account'] = true;
        Api.updateUser(user.userId, user).then((data) => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    render() {
        const { classes } = this.props;
        const { requiredCard, userPackage, features, userLevel, billingFrequency, cardSuccess, showAuthorize, authorize, 
            paymentLast4Digits, validatingCouponCode, isCouponValid, coupon  } = this.state;
        const { user } = this.props.store.getState();
        const cardElement = classNames({
            [classes.cardElement]: true,
            [classes.cardSuccess]: cardSuccess
        });
        classes.cardElement = cardElement;
        return (
            <div>
                <h4 className={classes.infoText}>Choose Your Features</h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText="Setup Your Account"
                            showPrivateIcon={true}
                            showInfoTooltip={user.userPreferences.showTips}
                            onShowInfo={() => this.onTooltip('userLevel', true) }
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"userLevel"),
                                value: userLevel
                            }}
                            inputProps={{
                                name: "userLevel",
                                id: "outlined-userLevel",
                            }}
                            items={this.packages}
                            itemLabel="name"
                            itemValue="level"
                        />
                        {
                            userPackage !== null ?
                                <>
                                    <NavPills
                                        alignCenter
                                        color="rose"
                                        tabs={[
                                        {
                                            tabButton: "monthly"
                                        },
                                        {
                                            tabButton: "yearly"
                                        }
                                        ]}
                                        onChange={(tabId) => this.billingFrequencyChange(tabId)}
                                    />
                                    <Card plain pricing className={classes.card}>
                                        <CardBody pricing className={classes.cardBody}>
                                            {/* <h6 className={classNames(classes.cardCategory, classes.textInfo)}>
                                                {userLevelLabel}
                                            </h6> */}
                                            <h1 className={classes.cardTitle}>
                                                {
                                                    billingFrequency.toLowerCase() === "monthly" ?
                                                        <>
                                                            <small>$</small>{" "+userPackage.pricing.monthlyAmount+" "} <small>/mo</small>
                                                        </>
                                                    :
                                                        <>
                                                            <small>$</small>{" "+userPackage.pricing.annualAmount+" "} <small>/year</small>
                                                        </>
                                                }
                                            </h1>
                                            <ul>
                                                {
                                                    features.map(feature => {
                                                        return <li key={feature.name}>{feature.name}</li>
                                                    })
                                                }
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </>
                            :
                            <></>
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        {
                            this.state.userLevel !== 0 ?
                                <>
                                    <CustomInput
                                        id="outlined-discountCode"
                                        labelText="Do You Have a Discount Code?"
                                        inputProps={{
                                            value: this.state.discountCode,
                                            required: true,
                                            onChange: event => this.change(event, "discountCode", "length", 3),
                                            name: "discountCode",
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        onClear={() => this.onDiscountCodeClear()}
                                    />
                                    <div>
                                        {
                                            validatingCouponCode ?
                                                <LoaderComponent align="right" />
                                            :
                                            <div className={classes.alignRight}>
                                                {
                                                    isCouponValid === true ?
                                                        <Success>This code is valid</Success>
                                                    :
                                                        isCouponValid === false ?
                                                            <Danger>This code is invalid</Danger>
                                                        :
                                                        <></>
                                                }
                                                <Button color="primary" round onClick={this.applyCoupon} className={classes.applyButton}>
                                                    Apply
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </>
                            :
                            <></>
                        }
                    </GridItem>
                    {
                        this.state.userLevel !== 0 ?
                            <GridItem xs={12} sm={12} md={12} lg={10} className={classes.alignRight}>
                                {
                                    paymentLast4Digits && paymentLast4Digits.length > 0 && showAuthorize === false ?
                                        <CustomInput
                                            id="outlined-existingCard"
                                            labelText="Existing Card"                                    
                                            inputProps={{
                                                value: "xxxx xxxx xxxx "+paymentLast4Digits,
                                                disabled: true,
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.formControl+" fs-block "
                                            }}
                                        />
                                    :
                                        <>
                                            <p className={classes.cardInfoText}>Enter Your Credit Card Information.  Your Card will not be charged yet.</p>
                                            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_CLIENT_KEY}>
                                                <Elements>
                                                    <StripeCard classes={classes} store={this.store} success={cardSuccess} authorize={authorize} renderCardOnly={true}  
                                                    error={requiredCard} onChange={this.onMethodChange} onCardChange={this.onCardChange} allowGoBack={false} userTitle={this.getUserTitle()} />
                                                </Elements>                
                                            </StripeProvider>
                                        </>
                                }
                                {
                                    showAuthorize === true ?
                                        <Button color="primary" type="submit" round onClick={this.authorize} className={classes.button}>
                                            <PaymentIcon className={classes.icons} />Authorize
                                        </Button>
                                    :
                                        (paymentLast4Digits && paymentLast4Digits.length > 0) ?
                                            <Button color="info" round onClick={() => this.editCard()}>Change Payment Method</Button> 
                                        :
                                        <></>                   
                                }
                                {
                                    requiredCard ?
                                        showAuthorize ?
                                            <p className={classes.requiredCard}>Press authorize to validate your credit card</p>
                                        :
                                        <p className={classes.requiredCard}>Make sure to enter your credit card and click the Authorize button</p>
                                    :
                                    <></>
                                }
                                {
                                    coupon !== null && coupon.percent_off === 100 ?
                                        <p className={classes.optionalCard}>The currently applied discount is valid for 100% discount and therefore adding a credit card is optional at this time.</p>
                                    :
                                    <></>
                                }
                            </GridItem>
                        :
                        <></>
                    }
                </GridContainer>
            </div>
        );
    }
}

Step2.defaultProps = {
    onMount: function(){ },
    onIsValidChange: function(){ }
}
Step2.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func,
  onIsValidChange: PropTypes.func
};

export default withStyles(style)(Step2);
