import React from 'react'
import {Helmet} from 'react-helmet'
import { createBrowserHistory } from 'history';
import Config from '../../../../Config';
import Cookie from "../../../assets/js/utils/Cookie";
import AuthApi from "../../../assets/js/utils/Auth";
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import withStyles from "@material-ui/core/styles/withStyles"
import DashboardTabHome from "./tabs/Home";
import DashboardTabPlatforms from "./tabs/Platforms";
import DashboardTabProfile from "./tabs/Profile";
import DashboardTabSettings from "./tabs/Settings";
import DashboardTabAccount from "./tabs/Account";
import AnalyticsTabProfile from "./tabs/Analytics"
import DashboardTabHelp from "./tabs/Help";
import DashboardTabAdmin from "./tabs/Admin";
import DashboardTabPreview from "./tabs/Preview";
import SetupProgress from "../../components/SetupProgress/SetupProgress";
import Warning from "../../components/Typography/Warning";
import Button from "../../components/CustomButtons/Button.jsx";
import Link from "@material-ui/core/Link";
import StyleBox from "../../components/Dashboard/Editmode/StyleBox";
import Tour from "../../components/Dashboard/Tour.jsx";
import TourModal from "../../components/Dashboard/TourModal.jsx";
import LoadingModal from "../../components/Dashboard/LoadingModal";
// import InstagramApiModal from "../../components/Dashboard/InstagramApiModal.jsx"
// import YoutubeApiModal from "../../components/Dashboard/YoutubeApiModal.jsx"

//Tooltip modals
import SearchLinkTooltipModal from "../../components/ToolTips/Dashboard/Home/SearchLinkTooltipModal";


import NavPills from "../../components/NavPills/NavPills.jsx";

// @material-ui/icons
import Home from "@material-ui/icons/Home";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Save from "@material-ui/icons/Save";
import Menu from "@material-ui/icons/Menu";

import { ReactComponent as SettingIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as RobotIcon } from "../../../assets/icons/bots.svg";
import { ReactComponent as CirclesIcon } from "../../../assets/icons/circle.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import { ReactComponent as HelpIcon } from "../../../assets/icons/help.svg";
// import { ReactComponent as ManageAccountIcon } from "../../../assets/icons/manage_acc.svg";


import MaterialIcon from "@mdi/react";
import { mdiLayersTripleOutline, mdiChartAreaspline, mdiAccountCardDetailsOutline, mdiAlphaABoxOutline, mdiPencil } from '@mdi/js';

import dashboardHomePageStyle from "../../../assets/jss/dashboard/dashboardHomePageStyle.js";
import christian from "../../../assets/img/default-avatar.png";
import GridItem from '../../components/Grid/GridItem';
const history = createBrowserHistory();

const DashboardHome = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        const { user, paymentData, subscription, openStyleBox } = this.store.getState();
        this.history = this.props.history;
        this.navPills = React.createRef();
        this.bodyClass = helper.getBodyClass(this.history.location.pathname);


        //From Dashboard Home Component
        const openModal = (user.userPreferences.dashboardTourComplete === false && user.userPreferences.showTours === true);
        this.onboardingParam = helper.getParam("onboarding");
        this.isLoginParam = helper.getParam("islogin");

        let instagramApiModal = false;
        if(this.onboardingParam !== "null" && openModal === false){
            instagramApiModal = true;
        }
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(allowInstagram === user.userId){
            instagramApiModal = false;
        }
        let youtubeApiModal = false;
        const allowYoutube = Cookie.read("platform_wizard_youtube");
        if(this.onboardingParam !== "null" && instagramApiModal === false && openModal === false && allowYoutube !== user.userId){
            youtubeApiModal = true;
        }

        this.state = {
            defaultTab: this.props.tab,
            wizardOpen: false,
            match: this.props.match,            
            showCopyNotice: false,
            onboarding: this.prepareOnboarding(),
            tooltips: {
                username: false
            },
            subscriptionNotice: null,
            cardNotice: null,
            instagramTokenNotice: null,
            youtubeTokenNotice: null,
            paymentData: paymentData,
            cancelToken: null,
            subscription: subscription,
            platforms: user.contentPlatforms,
            openStyleBox: openStyleBox,
            user: user,
            savedUser: user,
            styleChanged: false,
            userChanged: false,

            //From Dashboard Home Component
            openModal: openModal,
            startTour: false,
            showTours: null,
            instagramApiModal: instagramApiModal,
            youtubeApiModal: youtubeApiModal,
            forcePasswordResetModal: false
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.onPlatformWizard = this.onPlatformWizard.bind(this);
        this.onHomeLinkClick = this.onHomeLinkClick.bind(this);
        this.copyUsername = this.copyUsername.bind(this);
        this.cancelNotice = this.cancelNotice.bind(this);
        this.onProgressChange = this.onProgressChange.bind(this);
        this.onSetupAccountHide = this.onSetupAccountHide.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.onSubscriptionNotice = this.onSubscriptionNotice.bind(this);
        this.onCardExpiredNotice = this.onCardExpiredNotice.bind(this);
        this.onInstagramTokenNotice = this.onInstagramTokenNotice.bind(this);
        this.onYoutubeTokenNotice = this.onYoutubeTokenNotice.bind(this);
        this.onPaymentDataChanged = this.onPaymentDataChanged.bind(this);
        this.onUserUpdate = this.onUserUpdate.bind(this);
        this.onStyleChange = this.onStyleChange.bind(this);


        //From Dashboard Home Component
        this.handleTourPopup = this.handleTourPopup.bind(this);
        this.handleTour = this.handleTour.bind(this);
        this.onTourClose = this.onTourClose.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized){
            const location = this.history.location;
            const loginRequired = "/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }

        if(authorized){
            const { paymentData, subscription } = this.store.getState();
            const source = axios.CancelToken.source();
            this.setState({cancelToken: source});
            if(paymentData.paymentLast4Digits.length <= 0){
                this.loadPaymentMethod(source);
            }
            if(subscription === null){
                this.getSubscription(source);
            }
            // this.loadUserPlatforms(source);
            this.tryResetUserPassword();
        }
    }
    componentWillUnmount(){
        if(this.state.cancelToken){
            this.state.cancelToken.cancel('Request Cancelled');
        }
    }
    getSubscription(source){
        Api.getSubscription(source).then((data) => {
            this.setState({subscription: data});
            const { user } = this.store.getState();
            if(user.userLevel !== data.userLevel){
                window.location.reload();
            }
        }).catch(err => {
            
        });
    }
    loadPaymentMethod(source){
        const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
        let headers = Config.getApiHeaders();
        const that = this;
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0 && res.data.response[0].hasOwnProperty("paymentLast4Digits")){
                const response = res.data.response[0];
                that.setState({
                    paymentData: {
                        paymentToken: response.paymentToken,
                        paymentLast4Digits: response.paymentLast4Digits,
                        paymentExpirationDate: response.paymentExpirationDate,
                        existingCardLoaded: true,
                    },
                    cancelToken: null,
                });
            }
        });
    }
    loadUserPlatforms(source){
        const { user } = this.store.getState();
        const that = this;
        const requestData = {};
        Api.getPlatforms(requestData, source, user.userId).then(data => {
            that.setState({
                platforms: data.response,
                cancelToken: null,
            });
        });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                [name]: status
            }
        });
    }
    onTabChange(tab, forceChange = false){
        const{ userChanged, defaultTab, paymentData, subscription, user } = this.state;
        // if(tab === defaultTab){
        //     tab = 0;
        // }
        
        let url = "/dashboard/editmode/search";
        switch(tab){
            case 1:
                url = "/dashboard/editmode/search";
                break;
            case 2:
                url = "/dashboard/profile";
                break;
            case 3:
                url = "/dashboard/admin";
                break;
            case 4:
                url = "/dashboard/platforms";
                break;
            case 5:
                url = "/dashboard/analytics";
                break;
            case 6:
                url = "/dashboard/circles";
                break;
            case 7:
                url = "/dashboard/robots";
                break;
            case 8:
                url = "/dashboard/account";
                break;
            case 9:
                url = "/dashboard/settings";
                break;
            case 10:
                url = "/dashboard/help";
                break;
            default:
                break;
        }

        if(defaultTab === 1 && userChanged){
            this.history.push(url+window.location.search);
            this.store.dispatch({type: 'UPDATE_STATE', state: {
                user: user,
                paymentData: paymentData,
                subscription: subscription,
            }});
        }else{
            this.setState({
                defaultTab: tab,
                match: {
                    params: {}
                }
            });
            history.push(url);
        }
        
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });

            window.fbq('track', 'PageView');
        },1000);
        this.bodyClass = helper.getBodyClass(history.location.pathname);
        if(tab === 0 || forceChange){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    onPlatformWizard(wizardOpen = true){
        this.setState({wizardOpen: wizardOpen});
    }
    onHomeLinkClick(event, tab){
        this.navPills.current.handleChange(event, tab);
    }
    copyUsername(){
        const { user } = this.store.getState();
        const username = Config.getFrontEndUrl()+"/"+user.username;
        helper.copyTextToClipboard(username);
        this.setState({
            showCopyNotice: true,
        });
    }
    cancelNotice(){
        this.setState({
            showCopyNotice: false,
        });
    }
    getTabs(){
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { match, defaultTab, paymentData, subscription, platforms, user } = this.state;
        let defaultAvatar = christian;
        if(user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlIcon;
        }
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        var showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);
        let tabs = [
            {
                tabButton: "",
                tabIcon: Home,
                tabContent: (
                    <DashboardTabHome isActiveTab={(defaultTab === 0)} onLinkClick={this.onHomeLinkClick} store={this.store} history={this.history} />
                )
            },
            {
                tabButton: (showLabels ? "Edit" : ""),
                tabImageIcon: (
                    <MaterialIcon path={mdiPencil} className={classes.materialIcon+" MuiSvgIcon-root"} />
                ),
                tabContent: null,
                className: "tab-preview-profile",
            },
            {
                tabButton: (showLabels ? "Profile" : ""),
                tabImageIcon: (
                    <img src={defaultAvatar} alt={user.username} className={classes.tabImageIcon} />
                ),
                tabContent: (
                    <DashboardTabProfile subscription={subscription} paymentData={paymentData} platforms={platforms} isActiveTab={(defaultTab === 2)} store={this.store} history={this.history} match={match} params={this.props} />
                ),
                className: "tab-my-profile",
            },
            {
                tabButton: (showLabels ? "Admin" : ""),
                tabImageIcon: (
                    <MaterialIcon path={mdiAlphaABoxOutline} className={classes.materialIcon+" MuiSvgIcon-root"} />
                ),
                tabContent: (
                    <DashboardTabAdmin isActiveTab={(defaultTab === 3)} store={this.store} history={this.history} match={match} params={this.props} />
                ),
                className: "tab-admin-home",
                hidden: (authorized === false || user.isAdmin !== true),
            },
            {
                tabButton: (showLabels ? "Content" : ""),
                tabImageIcon: (
                    <MaterialIcon path={mdiLayersTripleOutline} className={classes.materialIcon+" MuiSvgIcon-root"} />
                ),
                tabContent: (
                    <DashboardTabPlatforms subscription={subscription} paymentData={paymentData} platforms={platforms} isActiveTab={(defaultTab === 4)} store={this.store} history={this.history} onTabChange={this.onTabChange} match={match} onPlatformWizard={this.onPlatformWizard} onInstagramTokenNotice={this.onInstagramTokenNotice} onYoutubeTokenNotice={this.onYoutubeTokenNotice} />
                ),
                className: "tab-my-add-manage",
            },
            {
                tabButton: (showLabels ? "Analytics" : ""),
                tabImageIcon: (
                    <MaterialIcon path={mdiChartAreaspline} className={classes.materialIcon+" MuiSvgIcon-root"} />
                ),
                tabContent: (
                    <AnalyticsTabProfile subscription={subscription} paymentData={paymentData} isActiveTab={(defaultTab === 5)} store={this.store} history={this.history} match={match} params={this.props} />
                ),
                className: "tab-my-analytics",
                hidden: (user.userLevel === 0 || showBetaFeatures === false),
            }
        ];
        tabs.push({
            tabButton: (showLabels ? "Circles" : ""),
            hidden: !showBetaFeatures,
            tabImageIcon: (
                <CirclesIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            ),
            tabContent: (
                <GridItem>
                    <h4 className={classes.title}>Manage Your Circles</h4>
                    <br />
                    COMING SOON! 
                </GridItem>
            ),
            className: "tab-my-circles",
        });

        tabs.push({
            tabButton: (showLabels ? "Robots" : ""),
            hidden: true,
            tabImageIcon: (
                <RobotIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            ),
            tabContent: (
                <GridItem>
                    <h4 className={classes.title}>Manage Your Robots</h4>
                    <br />
                    COMING SOON! 
                </GridItem>
            ),
            className: "tab-my-robots",
        });
        tabs.push({
            tabButton: (showLabels ? "Account" : ""),
            tabImageIcon: (
                <MaterialIcon path={mdiAccountCardDetailsOutline} className={classes.materialIcon+" MuiSvgIcon-root"} />
            ),
            tabContent: (
                <DashboardTabAccount subscription={subscription} paymentData={paymentData} isActiveTab={(defaultTab === 8)} store={this.store} history={this.history} onTabChange={this.onTabChange} match={match} params={this.props} onSubscriptionNotice={this.onSubscriptionNotice} onCardExpiredNotice={this.onCardExpiredNotice} onPaymentDataChanged={this.onPaymentDataChanged} />
            ),
            className: "tab-my-account",
        });
        tabs.push({
            tabButton: (showLabels ? "Settings" : ""),
            tabImageIcon: (
                <SettingIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            ),
            tabContent: (
                <DashboardTabSettings store={this.store} history={this.history} match={match} params={this.props} />
            ),
            className: "tab-my-settings",
        });
        tabs.push({
            tabButton: (showLabels ? "Help" : ""),
            tabImageIcon: (
                <HelpIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            ),
            tabContent: (
                <DashboardTabHelp store={this.store} history={this.history} match={match} params={this.props} />                                    
            ),
            className: "tab-my-help",
        });
        return tabs;
    }
    prepareOnboarding(){
        const { onboarding, authorized, user } = this.store.getState();
        if(!authorized || onboarding.length <= 0){
            return [];
        }
        let steps = [];
        let preferences = {};
        if(user.userPreferences.hasOwnProperty("onboarding")){
            preferences = user.userPreferences.onboarding;
        }

        onboarding.map((step) => {
            var prefKey = "step"+step.step+"Complete";
            const status = (preferences.hasOwnProperty(prefKey)?preferences[prefKey]:false);
            steps.push({
                stepLabel: step.stepLabel,
                id: step.step,
                status: status,
            });
            return null;
        });
        return steps;
    }
    onProgressChange(newSteps){
        const { user } = this.store.getState();
        let preferences = {};
        if(user.userPreferences.hasOwnProperty("onboarding")){
            preferences = user.userPreferences.onboarding;
        }
        newSteps.map((step) => {
            var prefKey = "step"+step.id+"Complete";
            preferences[prefKey] = step.status;
            return null;
        });
        user.userPreferences['onboarding'] = preferences;

        Api.updateUser(user.userId, user).then((data) => {
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser }});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onSetupAccountHide(){
        const { user } = this.store.getState();
        if(user.userPreferences.hasOwnProperty("onboarding")){
            user.userPreferences.onboarding.hide = true;
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    let newUser = Api.prepareMemberObject(data.response[0]);
                    this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser }});
                    this.history.push("/dashboard/editmode/search");
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    logout(){
        this.history.push("/logout");
    }
    onSubscriptionNotice(notice){
        this.setState({subscriptionNotice: notice});
    }
    onCardExpiredNotice(notice){
        this.setState({cardNotice: notice});
    }
    onInstagramTokenNotice(notice){
        this.setState({instagramTokenNotice: notice});
    }
    onYoutubeTokenNotice(notice){
        this.setState({youtubeTokenNotice: notice});
    }
    onPaymentDataChanged(data){
        this.setState({
            paymentData: {
                paymentToken: data.paymentToken,
                paymentLast4Digits: data.paymentLast4Digits,
                paymentExpirationDate: data.paymentExpirationDate,
                stripeData: data.stripeData,
                hasError: data.hasError
            },
            cardNotice: null
        });
    }
    toggleStyleBox(){
        this.setState({
            openStyleBox: !this.state.openStyleBox
        });
    }
    onUserUpdate(state, previewFeed = null, previewFeedLastKey = null, showSearchTerms = null, justStoreUpdate = false){
        // if(showSearchTerms === false || justStoreUpdate){
        //     let newState = {
        //         showSearchTerms: showSearchTerms,
        //         paymentData: this.state.paymentData,
        //         subscription: this.state.subscription,
        //         openStyleBox: this.state.openStyleBox
        //     };
        //     if(previewFeed !== null){
        //         newState['previewFeed'] = previewFeed;
        //         newState['previewFeedLastKey'] = previewFeedLastKey;
        //     }
        //     this.store.dispatch({type: 'UPDATE_STATE',state: newState});
        //     return;
        // }
        let newState = {user: state.values, styleChanged: false, userChanged: true, savedUser: state.values};
        if(previewFeed !== null){
            newState['previewFeed'] = previewFeed;
            newState['previewFeedLastKey'] = previewFeedLastKey;
        }
        if(showSearchTerms !== null){
            newState['showSearchTerms'] = showSearchTerms;
        }
        this.setState(newState);

        if(showSearchTerms === false || justStoreUpdate){
            return;
        }

        const user = Api.prepareMemberObject(state.values);
        Api.updateUser(user.userId, user).then((data) => {
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                // let newState = {
                //     user: newUser,
                //     paymentData: this.state.paymentData,
                //     subscription: this.state.subscription,
                //     openStyleBox: this.state.openStyleBox
                // };
                // if(previewFeed !== null){
                //     newState['previewFeed'] = previewFeed;
                //     newState['previewFeedLastKey'] = previewFeedLastKey;
                // }
                // if(showSearchTerms !== null){
                //     newState['showSearchTerms'] = showSearchTerms;
                // }
                // this.history.push(window.location.pathname);
                // this.store.dispatch({type: 'UPDATE_STATE',state: newState});
                this.setState({user: newUser, savedUser: newUser, styleChanged: false});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onStyleChange(state){
        this.setState({user: state.values, styleChanged: true});
    }
    onStyleSave(){
        this.setState({openStyleBox: false});
        this.onUserUpdate({values: this.state.user});
    }
    onStyleCancel(){
        const { savedUser } = this.state;
        this.setState({user: savedUser, styleChanged: false, openStyleBox: false});
    }


    //From Dashboard Home Component
    handleTourPopup(showTours = null) {
        this.setState({ openModal: false });
        const { user } = this.store.getState();
        user.userPreferences.dashboardTourComplete = true;
        Api.updateUser(user.userId, user);        
        if(showTours !== null){
            user.userPreferences.showTours = showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
        }
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
            this.history.push("/dashboard/editmode/search?onboarding=1");
        }else{
            this.history.push("/dashboard/editmode/search");
        }
    }
    handleTour(showTours = null) {
        this.setState({
            openModal: false,
            startTour: true,
            showTours: showTours
        });
        const { user } = this.store.getState();
        user.userPreferences.dashboardTourComplete = true;
        Api.updateUser(user.userId, user);

        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
            history.push("/dashboard/editmode/search?onboarding=1");
        }else{
            history.push("/dashboard/editmode/search");
        }
    }
    onTourClose(){
        if(this.state.showTours !== null){
            const { user } = this.store.getState();
            user.userPreferences.dashboardTourComplete = true;
            user.userPreferences.showTours = this.state.showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});

            const allowInstagram = Cookie.read("platform_wizard_instagram");
            if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
                this.history.push("/dashboard/editmode/search?onboarding=1");
            }else{
                this.history.push("/dashboard/editmode/search");
            }
        }
    }
    // handleInstagramModal(open){
    //     const { user } = this.store.getState();
    //     if(open === null){
    //         Cookie.write("platform_wizard_instagram", user.userId);
    //     }
    //     if(open === false || open === null){
    //         const allowYoutube = Cookie.read("platform_wizard_youtube");
    //         if(allowYoutube !== user.userId){
    //             this.setState({ instagramApiModal: false, youtubeApiModal: true });
    //             return;
    //         }
    //         this.setState({ instagramApiModal: false });
    //         this.history.push("/dashboard/editmode/search");
    //         return;
    //     }
    //     this.setState({ instagramApiModal: false });
    //     this.history.push("/dashboard/platformwizard/instagramlink");
    // }
    // handleYoutubeModal(open){
    //     const { user } = this.store.getState();
    //     if(open === null){
    //         Cookie.write("platform_wizard_youtube", user.userId);
    //     }
    //     if(open === false || open === null){
    //         this.setState({ instagramApiModal: false, youtubeApiModal: false });
    //         this.history.push("/dashboard/editmode/search");
    //         return;
    //     }
    //     this.setState({ youtubeApiModal: false });
    //     this.history.push("/dashboard/platformwizard/youtube");
    // }
    tryResetUserPassword(){
        const { user } = this.store.getState();
        if(!user.hasOwnProperty("cognitoRandomPassword") || user.cognitoRandomPassword === null || user.cognitoRandomPassword.length <= 0 ||
        !user.hasOwnProperty("cognitoUser") || user.cognitoUser === null || user.cognitoUser.length <= 0){
            return;
        }

        const email = user.userEmail;
        const idToken = Cookie.read("idToken");
        const accessToken = Cookie.read("accessToken");
        const refreshToken = Cookie.read("refreshToken");
        const expiresIn = Cookie.read("expires_in");
        let federated = Cookie.read("federated");
        if(federated !== "1"){
            federated = null;
        }
        const authData = {
            accessToken: accessToken,
            expires_in: parseInt(expiresIn),
            idToken: idToken,
            refreshToken: refreshToken
        };
        Auth.signIn(email, user.cognitoRandomPassword).then(userSignIn => {
            if(userSignIn.challengeName === 'NEW_PASSWORD_REQUIRED'){
                Auth.completeNewPassword(
                    userSignIn,
                    helper.makeid(8),
                    {
                      email: email,
                    }
                ).then(newUser => {
                    Auth.signOut();
                    setTimeout(() => {
                        AuthApi.updateTokens(authData, federated);
                        this.setState({forcePasswordResetModal: false});
                        user['cognitoRandomPassword'] = '';
                        user['cognitoForcePasswordReset'] = false;
                        this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
                    }, 100);
                }).catch(e => {
                    this.setState({forcePasswordResetModal: false});
                });
            }
        }).catch(err => {
            this.setState({forcePasswordResetModal: false});
        });
        this.setState({forcePasswordResetModal: true});
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { user } = this.state;
        const { defaultTab, wizardOpen, onboarding, tooltips, subscriptionNotice, cardNotice, instagramTokenNotice, 
            youtubeTokenNotice, openStyleBox, match, paymentData, subscription, styleChanged, forcePasswordResetModal } = this.state;
        let { openModal, startTour, /*instagramApiModal, youtubeApiModal*/ } = this.state;
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);
        if(!authorized){
            return <></>
        }
        if(user.hasOwnProperty("isSuspended") && user.isSuspended){
            return (
                <div className={classes.container}>
                    <div className={classes.main+" "+(wizardOpen?classes.wizard:"")}>
                        <div className={classes.suspended}>
                            <Warning>Your account is currently suspended.</Warning>
                            <Button color="primary" round onClick={() => this.logout()}>OK</Button>
                        </div>
                    </div>
                </div>
            )
        }

        const startPlatformTour = helper.getParam("startTour");
        if(startPlatformTour && startPlatformTour.length > 0 && startPlatformTour !== "null"){
            openModal = true;
        }

        // let defaultCover = null;
        // if(this.state.user.hasOwnProperty("coverMedia")){
        //     defaultCover = user.coverMedia.downloadUrlMain;
        // }
        
        return (
            <div>
                {
                    subscriptionNotice !== null ?
                        <GridItem className={classes.notice}>
                            {subscriptionNotice}
                        </GridItem>
                    :
                    <></>
                }
                {
                    cardNotice !== null ?
                        <GridItem className={classes.notice}>
                            {cardNotice}
                        </GridItem>
                    :
                    <></>
                }
                {
                    instagramTokenNotice !== null ?
                        <GridItem className={classes.notice}>
                            {instagramTokenNotice}
                        </GridItem>
                    :
                    <></>
                }
                {
                    youtubeTokenNotice !== null ?
                        <GridItem className={classes.notice}>
                            {youtubeTokenNotice}
                        </GridItem>
                    :
                    <></>
                }
                <div className={classes.container+" "+(showBetaFeatures?"":classes.hideBetaFeatures)}>
                    <GridItem className={classes.searchLink}>
                        <CustomInput
                            labelText="Your Unique Search Engine Link"
                            id="info-username"
                            formControlProps={{
                                fullWidth: true
                            }}
                            classes={{
                                formControl:"my-search-link",
                            }}
                            inputProps={{
                                value: Config.getFrontEndUrl()+"/"+user.username,
                                disabled: true,
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment+" my-search-link-copy"} onClick={this.copyUsername}>
                                        <CopyIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                                    </InputAdornment>
                                )
                            }}
                            showInfoTooltip={user.userPreferences.showTips}
                            onShowInfo={() => this.onTooltip('username', true)}
                        />
                        <Link className={classes.overlayUsernameLink} href={Config.getFrontEndUrl()+"/"+user.username}>{Config.getFrontEndUrl()+"/"+user.username}</Link>
                    </GridItem>
                    <div className={classes.main+" "+(wizardOpen?classes.wizard:"")+" "+(defaultTab === 1 ? classes.profilePreview : "")}>
                        <NavPills
                            ref={this.navPills}
                            horizontal={{
                                tabsGrid: { xs: 12, sm: 12, md: 5, className: "navPillsGridItem "+classes.navPillsTabsGrid+" "+(showLabels ? "showLabels" : "") },
                                contentGrid: { xs: 12, sm: 12, md: 12, className: classes.navPillsContentGrid }
                            }}
                            alignCenter
                            color="primary"
                            active={defaultTab}
                            onChange={this.onTabChange}
                            tabs={this.getTabs()}
                            disableSwipeable={true}
                        />
                        {
                            defaultTab === 1 ?
                                <div className={classes.previewTabContent}>
                                    <DashboardTabPreview isActiveTab={(defaultTab === 1)} user={user} onUserUpdate={this.onUserUpdate} store={this.store} history={this.history} match={match} search={this.props.search} subscription={subscription} paymentData={paymentData} />
                                </div>
                            :
                            <></>
                        }
                    </div>
                    {
                        defaultTab === 0 && user.userPreferences.onboarding.hide !== true ?
                            <div className={classes.progressSetup+" "+classes.main+" "+(wizardOpen?classes.wizard:"")}>
                                <SetupProgress steps={onboarding} onChange={this.onProgressChange} onHide={this.onSetupAccountHide} />
                            </div>
                        :
                        <></>
                    }
                    {
                        tooltips.username ?
                            <SearchLinkTooltipModal open={tooltips.username} store={this.store} onClose={() => this.onTooltip('username', false)} />
                        :
                        <></>
                    }               
                    <Helmet 
                        htmlAttributes={{
                            class: this.bodyClass
                        }}
                        title={process.env.REACT_APP_TITLE}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.showCopyNotice}
                        autoHideDuration={1500}
                        onClose={this.cancelNotice}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Your link has been copied</span>}
                        action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.cancelNotice}
                        >
                            <CloseIcon />
                        </IconButton>,
                        ]}
                    />
                </div>
                {
                    defaultTab === 1 ?
                        <>
                            <div className={classes.styleBox+" "+(openStyleBox ? classes.openStyleBox : "")}>
                                <div className={classes.styleBoxButtons+" "+(openStyleBox ? classes.openStyleBoxButtons : "")}>
                                    <Button color="primary" onClick={() => this.toggleStyleBox()}>
                                        <Menu />
                                        Style Settings
                                    </Button>
                                    {
                                        styleChanged ?
                                            <>
                                                <Button justIcon onClick={() => this.onStyleCancel()} color="danger" className={classes.styleBoxIconButtons}>
                                                    <CloseIcon />
                                                </Button>
                                                <Button justIcon onClick={() => this.onStyleSave()} color="success" className={classes.styleBoxIconButtons}>
                                                    <Save />
                                                </Button>
                                            </>
                                        :
                                        <></>
                                    }
                                </div>
                                <StyleBox openStyleBox={openStyleBox} store={this.store} user={this.state.user} history={this.history} onStyleChange={this.onStyleChange} />
                            </div>
                            {
                                // defaultCover !== null ?
                                //     <img src={defaultCover} alt="User Cover" className="user_cover_photo" />
                                // :
                                // <></>
                            }
                        </>
                    :
                    <></>
                }
                <TourModal
                    open={openModal}
                    onClose={this.handleTourPopup}
                    onClick={this.handleTour}
                    store={this.store}
                />
                {(startTour === true) ? <Tour run={startTour} onClose={this.onTourClose} /> : <></>}
                {/* <InstagramApiModal open={instagramApiModal} onClick={event => this.handleInstagramModal(true)} onClose={event => this.handleInstagramModal(false)} onNever={event => this.handleInstagramModal(null)} />
                {
                    youtubeApiModal ?
                        <YoutubeApiModal open={youtubeApiModal} onClick={event => this.handleYoutubeModal(true)} onClose={event => this.handleYoutubeModal(false)} onNever={event => this.handleYoutubeModal(null)} />
                    :
                    <></>
                } */}
                <LoadingModal open={forcePasswordResetModal} message="Please wait while we upgrade your experience." />
            </div>
        )
    }
}

export default withStyles(dashboardHomePageStyle)(DashboardHome);
