import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../../../../components/Loader";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const EditNotice = class extends React.Component {
    render() {
        const { classes, saving, error } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="instagramLink-modal-slide-title"
                aria-describedby="instagramLink-modal-slide-description"
            >
                {
                    saving === false ?
                        <DialogTitle
                            id="instagramLink-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                            >
                                {
                                    error ?
                                        <h4 className={classes.modalTitle+" "+classes.textCenter}>An error occured during update.</h4> 
                                    :
                                        <h4 className={classes.modalTitle+" "+classes.textCenter}>Your Instagram settings were updated successfully!</h4> 
                                }
                        </DialogTitle>
                    :
                    <></>
                }
                {
                    saving ?
                        <DialogContent
                            id="instagramLink-modal-slide-description"
                            className={classes.modalBody}
                            >
                                <LoaderComponent align="center" color="success" />
                        </DialogContent>
                    :
                    <></>
                }
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false || error === true ?
                            <Button
                                onClick={() => this.props.onClose()}
                                color="info"
                                round
                            >
                                OK
                            </Button>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

EditNotice.defaultProps = {
    open: false,
    error: false
}
EditNotice.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    error: PropTypes.bool
}
export default withStyles(instagramNoticeStyle)(EditNotice);