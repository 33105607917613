import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Config from '../../../../../Config';
import axios from 'axios';
import { helper } from '../../../../assets/js/utils/Element';
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from "../../Loader";
import Close from "@material-ui/icons/Close";
import Link from '@material-ui/core/Link';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import GridItem from "../../Grid/GridItem.jsx";
import youtubeIcon from "../../../../assets/img/youtube-platform-icon.png";
import youtubeWizardModalStyle from "../../../../assets/jss/dashboard/editmode/youtubeWizardModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const YoutubeWizardModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const authCode = helper.getParam("code");
        let userStep = (authCode && authCode !== "null");
        this.state = {
            imageUrl: null,
            platform: null,
            loading: false,
            cancelToken: null,
            userStep: userStep,
            advancedOptions: false,
            saving: false,

            allContentSearchable: true,
            attemptGeoTag: true,
            showOnHomeFeed: true
        };
    }
    componentDidMount(){
        const authCode = helper.getParam("code");
        if(authCode && authCode !== "null"){
            this.createPlatform(authCode);
        }
    }
    createPlatform(authCode){
        const { cancelToken } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        var requestData = {
            code: decodeURIComponent(authCode),
            type: "youtubeAPI",
            status: "Authorize Platform",
            allContentSearchable: true,
            attemptGeoTag: true
        };
        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source});
        Api.createPlatform(requestData, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                this.setState({platform: data.response[0], loading: false});
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({loading: false, userStep: false});
            }
        });
    }
    onPlatformAuth(){
        const authUrl = Config.getGoogleAuthUrl();
        window.location.href = authUrl;
    }
    onPlatformReAuth(e){
        e.preventDefault();
        const authUrl = Config.getGoogleAuthUrl();
        const { platform } = this.state;
        const { user } = this.store.getState();
        if(platform !== null && platform.status === "Platform Authorized"){
            const source = axios.CancelToken.source();
            Api.deletePlatform(platform, source, user.userId).then((data) => { 
                window.location.href = authUrl;
            }).catch(err => {
                console.log(err);
            });
        }else{
            window.location.href = authUrl;
        }
    }
    handleToggle(name) {
        let newState = {
            [name]: !this.state[name]
        };
        this.setState(newState);
    };
    renderAuthStep(){
        const { classes } = this.props;
        return(
            <>
                <DialogContent id="youtubeWizard-slide-description" className={classes.modalBody}>
                    <img src={youtubeIcon} alt="youtube" className={classes.platformIcon} />
                    <h4 className={classes.modalTitle}>Your browser is about to be re-directed to a Google/YouTube login page.</h4>
                    <ul className={classes.checkList}>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to your password.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to change your account.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to post on your account.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-valid-customizable">&#x2713;</span>
                            <span>We DO have limited access to import your videos and descriptions into Search My Social to quickly load your account.</span>
                        </li>
                    </ul>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    <Button onClick={() => this.props.onClose()} color="white">No Thanks</Button>
                    <Button className={""} onClick={() => this.onPlatformAuth()} color="blue">Let's Do It!</Button>
                </DialogActions>
            </>
        );
    }
    showAdvancedOptions(e){
        e.preventDefault();
        this.setState({advancedOptions: true});
    }
    handleFinish(){
        let { platform, allContentSearchable, attemptGeoTag } = this.state;
        platform.status = "Submit Platform";
        platform.allContentSearchable = allContentSearchable;
        platform.attemptGeoTag = attemptGeoTag;
        
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source});
        
        Api.updatePlatform(platform, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                this.updateUserOnboarding(data.response[0]);
            }
        }).catch(err => {
            console.log(err);
        });    
    }
    updateUserOnboarding(platform){
        const { user } = this.props;
        const { showOnHomeFeed } = this.state;
        let updateUser = false;
        if(!user.hasOwnProperty("contentPlatforms")){
            user.contentPlatforms = [];
        }
        user.contentPlatforms.push(platform);

        if(showOnHomeFeed){
            if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
                user.guestPreferences.contentPlatforms = [];
            }
            user.guestPreferences.contentPlatforms.push(platform.contentPlatformId);
            updateUser = true;
        }
        if(user.userPreferences.hasOwnProperty("onboarding") || updateUser){
            user.userPreferences.onboarding.step5Complete = true;
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    setTimeout(() => {
                        this.props.onSuccess(user);
                    }, 3000);
                }
            }).catch(err => {
                console.log(err);
            });
        }else{
            setTimeout(() => {
                this.props.onSuccess(user);
            }, 5000);
        }
    }
    getChannelTitle(){
        const { platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelTitle")){
            return platform.pendingData.channelTitle;
        }
        return null;
    }
    getChannelDescription(){
        const { platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelDescription")){
            return platform.pendingData.channelDescription;
        }
        return null;
    }
    onCancelPlatform(){
        const { platform } = this.state;
        const { user } = this.store.getState();
        if(platform !== null && platform.status === "Platform Authorized"){
            const source = axios.CancelToken.source();
            Api.deletePlatform(platform, source, user.userId).then((data) => { 
                //Success
            }).catch(err => {
                console.log(err);
            });
        }
        this.props.onClose();
    }
    renderUserStep(){
        const { classes } = this.props;
        const { loading, advancedOptions, saving } = this.state;
        const channelTitle = this.getChannelTitle();
        const authUrl = Config.getGoogleAuthUrl();
        const { allContentSearchable, attemptGeoTag } = this.state;
        return(
            <>
                <DialogContent id="youtubeWizard-slide-description" className={classes.modalBody}>
                    <img src={youtubeIcon} alt="youtube" className={classes.platformIcon} />
                    
                    {
                        loading ?
                            <LoaderComponent align="center" />
                        :
                        <>
                            <h4 className={classes.modalTitle}>YouTube has authorized the following channel:</h4>
                            {
                                channelTitle && channelTitle.length > 0 ?
                                    <div className={classes.preview}>
                                        <div>
                                            <h4>Channel Name:</h4>
                                            <p>{channelTitle}</p>
                                        </div>
                                        <div>
                                            <h4>Channel Description:</h4>
                                            <p className={classes.description}>{this.getChannelDescription()}</p>
                                        </div>
                                        <Link href={authUrl} className={classes.wrongLink} onClick={(e) => this.onPlatformReAuth(e)}>
                                            Wrong Account?
                                        </Link>
                                        {
                                            advancedOptions === false ?
                                                <div className={classes.advancedOptions}>
                                                    <Link href={"/"} onClick={(e) => this.showAdvancedOptions(e)}>
                                                        Advanced Options
                                                    </Link>
                                                </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                :
                                <></>
                            }
                            {
                                advancedOptions ?
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.optionsGridItem}>
                                        {/* <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("showOnHomeFeed")}
                                                    checked={showOnHomeFeed}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label={(
                                                    showOnHomeFeed ?
                                                        "Show Instagram Content on Home Feed"
                                                    :
                                                    "Do NOT show Instagram Content on Home Feed"
                                                )}
                                            />
                                        </div> */}
                                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("allContentSearchable")}
                                                    checked={allContentSearchable}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Make All Content Immediately Searchable"
                                            />
                                        </div>
                                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("attemptGeoTag")}
                                                    checked={attemptGeoTag}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Add Geo-Tag information to your search result keywords"
                                            />
                                        </div>
                                        <p className={classes.smallNotice}>(You can always change these settings later)</p>
                                    </GridItem>
                                :
                                <></>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    {
                        loading === false ?
                            <>
                                <Button disabled={saving} onClick={() => this.onCancelPlatform()} color="white">Nevermind</Button>
                                <Button className={classes.saveButton} onClick={() => this.handleFinish()} color="blue">
                                    {
                                        saving ?
                                            <LoaderComponent align="center" />
                                        :
                                            "Link this account"
                                    }
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
            </>
        );
    }
    render() {
        const { classes } = this.props;
        const { userStep, saving } = this.state;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="youtubeWizard-slide-title"
                aria-describedby="youtubeWizard-slide-description"
            >
                <DialogTitle
                    id="youtubeWizard-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                        disabled={saving}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                {
                    userStep ?
                        this.renderUserStep()
                    :
                    this.renderAuthStep()
                }
            </Dialog>
        );
    }
};

YoutubeWizardModal.defaultProps = {
    open: false
};
YoutubeWizardModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool
};
export default withStyles(youtubeWizardModalStyle)(YoutubeWizardModal);
