import {
    whiteColor
} from "../../material-kit-pro-react.jsx";

import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const dashboardTabSettingsApplicationStyle = theme => ({
    ...customCheckboxRadioSwitch,
    main: {
        padding: "0px"
    },
    title: {
        margin: theme.spacing(1, 1, 1, 1),
        fontWeight:"bold",
    },
    buttonsGridContainer: {
        marginTop: theme.spacing(2)
    },
    buttonSpacing: {
        marginLeft: "10px",
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    inputLabel: {
        margin: theme.spacing(1, 0, 1),
    },
    saveButtons: {
        display: "flex",
        justifyContent: "space-between"
    },
    editButton: {
        display: "flex",
        justifyContent: "flex-end"
    }
});

export default dashboardTabSettingsApplicationStyle;