import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const TokenErrorNotice = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="youtubeTokenError-modal-slide-title"
                aria-describedby="youtubeTokenError-modal-slide-description"
            >
                <DialogContent
                    id="youtubeTokenError-modal-slide-description"
                    className={classes.modalBody}
                    >
                        There as an error obtaining permission from YouTube. Would you like to try again?
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button
                        onClick={() => this.props.onSuccess()}
                        color="info"
                        round
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        color="info"
                        round
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

TokenErrorNotice.defaultProps = {
    open: false,
}
TokenErrorNotice.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(instagramNoticeStyle)(TokenErrorNotice);