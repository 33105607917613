import Cookie from './app/assets/js/utils/Cookie';

export default {
    FB_APP_ID: "550186362162847",
    GOOGLE_CLIENT_ID: "368231056525-n3nlvvpjbo257u5tm2ahhkg159fqcsre.apps.googleusercontent.com",
    X_API_KEY: "XIHscYkVIZ8k0NDODleMR9a0wKcuR0Dn2xFwvqlb",
    GOOGLE_ANALYTICS_ID: "UA-85612507-5",
    REACT_APP_LOG_LEVEL: "DEBUG",
    REACT_APP_CLOUDFRONT_IMAGES: "https://int-cdn1-us-west-2.smearch.com",
    REACT_APP_INSTAGRAM_API_APP_ID: "1010791169294224",
    REACT_APP_CHROME_EXT: null,
    REACT_APP_SMS_SUPPORT_LINK: "https://searchmysocial.zendesk.com/hc/en-us",
    REACT_APP_FRONT_END_URL: "https://int-app.searchmysocial.com",
    cognito: {
        APP_CLIENT_ID: "2d4vnfe37grbmuk3icuvrf2i92",
        APP_CLIENT_SECRET: "",
        APP_NATIVE_CLIENT_ID:"4dkjr218d6584dupk043opblaj",
        IDENTITY_POOL_ID: "us-west-2:ccd1d2a9-c830-4139-a164-5233cbafdb43",
        USER_POOL_ID: "us-west-2_u7XG67txh",
        REGION: "us-west-2",
        AUTH_URL: "https://int-auth.searchmysocial.com",
        API_URL: "https://int-api.searchmysocial.com/v1/",
    },
    getClientId(){
        try{
            if(process.env.REACT_APP_AWS_USERPOOL_CLIENT_ID){
                return process.env.REACT_APP_AWS_USERPOOL_CLIENT_ID;
            }
        }catch(e){  }
        return this.cognito.APP_CLIENT_ID;
    },
    getClientSecret(){
        try{
            if(process.env.REACT_APP_AWS_USERPOOL_CLIENT_SECRET){
                return process.env.REACT_APP_AWS_USERPOOL_CLIENT_SECRET;
            }
        }catch(e){  }
        return this.cognito.APP_CLIENT_SECRET;
    },
    getNativeClientId(){
        try{
            if(process.env.REACT_APP_AWS_USERPOOL_NATIVE_CLIENT_ID){
                return process.env.REACT_APP_AWS_USERPOOL_NATIVE_CLIENT_ID;
            }
        }catch(e){  }
        return this.cognito.APP_NATIVE_CLIENT_ID;
    },
    getAuthUrl(){
        try{
            if(process.env.REACT_APP_AUTH_URL){
                return process.env.REACT_APP_AUTH_URL;
            }
        }catch(e){  }
        return this.cognito.AUTH_URL;
    },
    getApiUrl(){
        try{
        if(process.env.REACT_APP_API_URL){
            return process.env.REACT_APP_API_URL;
        }
        }catch(e){  }
        return this.cognito.API_URL;
    },
    getRedirectUri(returnUrl = ""){
        return "https://"+document.location.host+returnUrl;
    },
    getLoginUrl(returnUrl = ""){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"login?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getSignupUrl(returnUrl = ""){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"signup?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getLogoutUrl(){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"logout?client_id="+CLIENT_ID+"&logout_uri="+this.getRedirectUri()+"/logout";
        return AUTH_URL;
    },
    getTokenUrl(){
        const AUTH_URL = this.getAuthUrl()+"oauth2/token";
        return AUTH_URL;
    },
    getUserInfoUrl(){
        const AUTH_URL = this.getApiUrl()+"data/user/me";
        return AUTH_URL;
    },
    getProvierClientId(){
        try{
            if(process.env.REACT_APP_PRO_CLIENT_ID){
                return process.env.REACT_APP_PRO_CLIENT_ID;
            }
        }catch(e){  }
        return this.cognito.APP_PRO_CLIENT_ID;
    },
    getProviderClientSecret(){
        try{
            if(process.env.REACT_APP_PRO_CLIENT_SECRET){
                return process.env.REACT_APP_PRO_CLIENT_SECRET;
            }
        }catch(e){  }
        return this.cognito.APP_PRO_CLIENT_SECRET;
    },
    getProviderLoginUrl(provider){
        const CLIENT_ID = this.getProvierClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri()+"&identity_provider="+provider;
        return AUTH_URL;
    },
    getGoogleAnalyticsId(){
        try{
            if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID){
                return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
            }
        }catch(e){  }
        return this.GOOGLE_ANALYTICS_ID;
    },
    getXApiKey(){
        try{
            if(process.env.REACT_APP_X_API_KEY){
                return process.env.REACT_APP_X_API_KEY;
            }
        }catch(e){  }
        return this.X_API_KEY;
    },
    getFbAppId(){
        try{
            if(process.env.REACT_APP_FB_APP_ID){
                return process.env.REACT_APP_FB_APP_ID;
            }
        }catch(e){  }
        return this.FB_APP_ID;
    },
    getGoogleClientId(){
        try{
            if(process.env.REACT_APP_GOOGLE_CLIENT_ID){
                return process.env.REACT_APP_GOOGLE_CLIENT_ID;
            }
        }catch(e){  }
        return this.GOOGLE_CLIENT_ID;
    },
    getUserPoolId(){
        try{
            if(process.env.REACT_APP_USER_POOL_ID){
                return process.env.REACT_APP_USER_POOL_ID;
            }
        }catch(e){  }
        return this.cognito.USER_POOL_ID;
    },
    getRegion(){
        try{
            if(process.env.REACT_APP_REGION){
                return process.env.REACT_APP_REGION;
            }
        }catch(e){  }
        return this.cognito.REGION;
    },
    getIdentityPoolId(){
        try{
            if(process.env.REACT_APP_IDENTITY_POOL_ID){
                return process.env.REACT_APP_IDENTITY_POOL_ID;
            }
        }catch(e){  }
        return this.cognito.IDENTITY_POOL_ID;
    },
    getPGApiUrl(){
        try{
            if(process.env.REACT_APP_PG_API_URL){
                return process.env.REACT_APP_PG_API_URL;
            }
        }catch(e){  }
        return this.REACT_APP_PG_API_URL;
    },
    getPGConsumerKey(){
        try{
            if(process.env.REACT_APP_PG_CONSUMER_KEY){
                return process.env.REACT_APP_PG_CONSUMER_KEY;
            }
        }catch(e){  }
        return this.REACT_APP_PG_CONSUMER_KEY;
    },
    getPGConsumerSecret(){
        try{
            if(process.env.REACT_APP_PG_CONSUMER_SECRET){
                return process.env.REACT_APP_PG_CONSUMER_SECRET;
            }
        }catch(e){  }
        return this.REACT_APP_PG_CONSUMER_SECRET;
    },
    getPGApiHeaders(){
        const headers = {
            'Accept': 'application/json',
            'oauth_consumer_key': this.getPGConsumerKey(),
            'oauth_consumer_secret': this.getPGConsumerSecret(),
            'Access-Control-Allow-Origin': document.location.origin
        };
        return headers
    },
    getLogType(){
        try{
            if(process.env.REACT_APP_LOG_LEVEL){
                return process.env.REACT_APP_LOG_LEVEL;
            }
        }catch(e){  }
        return this.REACT_APP_LOG_LEVEL;
    },
    getApiHeaders(){
        let id_token = Cookie.read("idToken");
        if(!id_token || id_token === null){
            id_token = "anonymous";
        }
        let audience = this.getNativeClientId();
        const federated = Cookie.read("federated");
        if(federated && parseInt(federated) === 1){
            audience = this.getClientId();
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': document.location.origin,
            'Authorization': id_token,
            'x-api-key': this.getXApiKey(),
            'Auth_Audience': audience,
            'GG_Ids': Cookie.getCookieData()
        };
        if(localStorage.getItem("auth_identity") !== null){
            headers['Auth_Identity'] = localStorage.getItem("auth_identity");
        }
        return headers;
    },
    getCdnUrl(){
        try{
            if(process.env.REACT_APP_CLOUDFRONT_IMAGES){
                return process.env.REACT_APP_CLOUDFRONT_IMAGES;
            }
        }catch(e){  }
        return this.REACT_APP_CLOUDFRONT_IMAGES;
    },
    getInstagramAppId(){
        try{
            if(process.env.REACT_APP_INSTAGRAM_API_APP_ID){
                return process.env.REACT_APP_INSTAGRAM_API_APP_ID;
            }
        }catch(e){  }
        return this.REACT_APP_INSTAGRAM_API_APP_ID;
    },
    getInstagramAuthUrl(){
        const CLIENT_ID = this.getInstagramAppId();
        const AUTH_URL = "https://www.instagram.com/oauth/authorize?client_id="+CLIENT_ID+"&scope=user_profile,user_media&response_type=code&redirect_uri="+this.getRedirectUri()+"/dashboard/platformwizard/instagramlink";
        return AUTH_URL;
    },
    getChromeExtensionUrl(){
        try{
            if(process.env.REACT_APP_CHROME_EXT){
                return process.env.REACT_APP_CHROME_EXT;
            }
        }catch(e){  }
        return this.REACT_APP_CHROME_EXT;
    },
    getYoutubeRedirectUrl(){
        const redirectUrl = document.location.origin+"/dashboard/platformwizard/youtube";
        return redirectUrl;
    },
    getGoogleApisClientId(){
        try{
            if(process.env.REACT_APP_GOOGLEAPIS_CLIENTID){
                return process.env.REACT_APP_GOOGLEAPIS_CLIENTID;
            }
        }catch(e){  }
        return this.GOOGLE_CLIENT_ID;
    },
    getGoogleAuthScope(){
        try{
            if(process.env.REACT_APP_GOOGLEAPIS_SCOPE){
                return process.env.REACT_APP_GOOGLEAPIS_SCOPE;
            }
        }catch(e){  }
        return "https://www.googleapis.com/auth/youtube.readonly";
    },
    getGoogleAuthUrl(scope = null){
        if(scope === null){
            scope = this.getGoogleAuthScope();
        }
        const CLIENT_ID = this.getGoogleApisClientId();
        const endPoint = "https://accounts.google.com/o/oauth2/v2/auth";
        const AUTH_URL = endPoint+"?response_type=code&access_type=offline&prompt=consent&client_id="+CLIENT_ID+"&redirect_uri="+this.getYoutubeRedirectUrl()+"&scope="+scope;
        return AUTH_URL;
    },
    getRecaptchaKey(){
        try{
            if(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY){
                return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
            }
        }catch(e){  }
        return "";
    },
    isRecaptchaEnabled(){
        const key = this.getRecaptchaKey();
        if(!key || typeof(key) !== "string"){
            return false;
        }
        if(key.length <= 5){
            return false;
        }
        return true;
    },
    getAmazonLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=LoginWithAmazon&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getGoogleLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=Google&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getFacebookLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=Facebook&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getSupportUrl(){
        try{
          if(process.env.REACT_APP_SMS_SUPPORT_LINK){
            return process.env.REACT_APP_SMS_SUPPORT_LINK;
          }
        }catch(e){  }
        return this.REACT_APP_SMS_SUPPORT_LINK;
    },
    isHostedAuthEnabled(){
        try{
            if(process.env.REACT_APP_AWS_HOSTED_AUTH && process.env.REACT_APP_AWS_HOSTED_AUTH === "true"){
              return true;
            }
            return false;
        }catch(e){  }
        return false;
    },
    isHoneypotEnabled(){
        try{
            if(process.env.REACT_APP_SIGNUP_DETECT_BOTS && process.env.REACT_APP_SIGNUP_DETECT_BOTS === "true"){
              return true;
            }
            return false;
        }catch(e){  }
        return false;
    },
    getFrontEndUrl(){
        try{
          if(process.env.REACT_APP_FRONT_END_URL){
            return process.env.REACT_APP_FRONT_END_URL;
          }
        }catch(e){  }
        return this.REACT_APP_FRONT_END_URL;
    },
    getFromEmails(){
        try{
          if(process.env.REACT_APP_FROM_EMAILS){
            return process.env.REACT_APP_FROM_EMAILS.split(",");
          }
        }catch(e){  }
        return [];
    },
};