import {
    successColor,
    dangerColor,
    grayColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const loadingModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    statusIcon: {
        textAlign: "center",
        "& svg": {
            width: "300px !important",
            height: "300px !important",
            color: successColor[0],
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
        }
    },
    errorIcon: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
        "& svg": {
            width: "200px !important",
            height: "200px !important",
            color: dangerColor[0],
            [theme.breakpoints.down("xs")]: {
                width: "130px !important",
                height: "130px !important",
            },
        }
    },
    contactBilling: {
        display: "block",
        textAlign: "right",
        marginTop: "30px",
        textDecoration: "underline"
    },
    modalCloseButtonLogin: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        "& svg": {
            marginRight: "0px"
        }
    },
});
  
export default loadingModalStyle;
  