import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Api from '../../../assets/js/utils/Api';
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import tourModalStyle from "../../../assets/jss/dashboard/tourModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContentTourModal = class extends React.Component {
    constructor(props) {
    super(props);

    this.store = this.props.store;
    this.history = this.props.history;
    const { user } = this.store.getState();

    this.state = {
        showTours: user.userPreferences.showTours
    };
    this.handlePrefChange = this.handlePrefChange.bind(this);
    }
    handlePrefChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.checked,
        });
        
        const { user } = this.store.getState();
        user.userPreferences.showTours = event.target.checked;
        Api.updateUser(user.userId, user);    
    }
    render() {
        const { classes } = this.props;
        return (
        <Dialog
            classes={{
            root: classes.modalRoot,
            paper: classes.modal
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.props.onClose(this.state.showTours)}
            aria-labelledby="tour-modal-slide-title"
            aria-describedby="tour-modal-slide-description"
        >
            <DialogTitle
            id="tour-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            >
            <h4 className={classes.modalTitle}>This is where you manage your content</h4>
            </DialogTitle>
            <DialogContent
            id="tour-modal-slide-description"
            className={classes.modalBody}
            >
            <p>Do you want us to show you around?</p>
            </DialogContent>
            <DialogActions
            className={classes.modalFooter + " " + classes.justifyContentCenter}
            >
            <Button onClick={() => this.props.onClose(this.state.showTours)} color="white">
                No Thanks
            </Button>
            <Button onClick={() => this.props.onClick(this.state.showTours)} color="blue">
                Yes Please
            </Button>
            </DialogActions>
            <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal + " " +classes.customCheckBox}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.showTours}
                        onChange={(e) => this.handlePrefChange(e, "showTours")}
                        value="1"
                        name="showTours"
                        icon={<Check className={classes.uncheckedIcon}/>}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label="Show guided tour the first time I use a feature"
                />
            </div>
        </Dialog>
        );
    }
};

ContentTourModal.defaultProps = {
  open: false
};
ContentTourModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(tourModalStyle)(ContentTourModal);
