import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Config from '../../../../../Config';
import axios from 'axios';
import { helper } from '../../../../assets/js/utils/Element';
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from "../../Loader";
import Close from "@material-ui/icons/Close";
import Link from '@material-ui/core/Link';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import GridItem from "../../Grid/GridItem.jsx";
import instagramIcon from "../../../../assets/img/instagram-platform-icon.png";
import instagramWizardModalStyle from "../../../../assets/jss/dashboard/editmode/instagramWizardModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const InstagramWizardModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const authCode = helper.getParam("code");
        let userStep = (authCode && authCode !== "null");
        const { user } = this.store.getState();
        const userLevel = user.userLevel;
        this.state = {
            imageUrl: null,
            platform: null,
            loading: false,
            cancelToken: null,
            userStep: userStep,
            advancedOptions: false,
            saving: false,

            searchable: true,
            hashtags: true,
            attemptGeoTag: userLevel < 1 ? false : true,
            includeFirstCommentHashtags: userLevel < 1 ? false : true,
            retrieveAllMedia: userLevel < 1 ? false : true,
            addRecognition: userLevel < 1 ? false : true,
            importIGTV: userLevel < 1 ? false : true,
            importReels: userLevel < 1 ? false : true,
            importGuides: userLevel < 1 ? false : true,
            robotFrequency: "0",
            onlySmearchTags: false,
            propsReceived: false,
            useFirstLink: true,
            showOnHomeFeed: true,
            instagramRequest: false
        };
    }
    componentDidMount(){
        const authCode = helper.getParam("code");
        if(authCode && authCode !== "null"){
            this.createPlatform(authCode);
        }
    }
    createPlatform(authCode){
        const { cancelToken } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        var requestData = {
            code: decodeURIComponent(authCode),
            type: "instagramAPI",
            status: "Authorize Platform",
        };
        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source});
        Api.createPlatform(requestData, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                this.setState({platform: data.response[0], loading: false}, () => {
                    const username = this.getUsername();
                    if(username !== null){
                        this.getInstagramUserImage(username);
                    }
                });
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({loading: false, userStep: false});
            }
        });
    }
    getInstagramUserImage(username){
        if(this.state.instagramRequest){
            return;
        }
        this.setState({loading: true});
        var url = "https://www.instagram.com/"+username+"/";
        helper.scrapeUrl(url).then(data => {
            let state = {
                loading: false,
                instagramRequest: true
            }
            if(data.hasOwnProperty("imageUrl")){
                state['imageUrl'] = data.imageUrl;
            }
            this.setState(state);
        }).catch(err => {
            this.setState({loading: false, instagramRequest: true});
        });
    }
    onPlatformAuth(){
        const instagramAuthUrl = Config.getInstagramAuthUrl();
        window.location.href = instagramAuthUrl;
    }
    onPlatformReAuth(e){
        e.preventDefault();
        const instagramAuthUrl = Config.getInstagramAuthUrl();
        const { platform } = this.state;
        const { user } = this.store.getState();
        if(platform !== null && platform.status === "Platform Authorized"){
            const source = axios.CancelToken.source();
            Api.deletePlatform(platform, source, user.userId).then((data) => { 
                window.location.href = instagramAuthUrl;
            }).catch(err => {
                console.log(err);
            });
        }else{
            window.location.href = instagramAuthUrl;
        }
    }
    handleToggle(name) {
        let newState = {
            [name]: !this.state[name]
        };
        if(name === "hashtags" && !this.state[name] === false){
            newState['includeFirstCommentHashtags'] = false;
        }
        this.setState(newState);
    };
    renderAuthStep(){
        const { classes } = this.props;
        return(
            <>
                <DialogContent id="instagramWizard-slide-description" className={classes.modalBody}>
                    <img src={instagramIcon} alt="instagram" />
                    <h4 className={classes.modalTitle}>Your browser is about to be re-directed to the Instagram login page.</h4>
                    <ul className={classes.checkList}>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to your password.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to change your account.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-notValid-customizable">&#x2716;</span>
                            <span>We do NOT have access to post on your account.</span>
                        </li>
                        <li>
                            <span  className="validation-error-symbol passwordCheck-valid-customizable">&#x2713;</span>
                            <span>We DO have limited access to import your images and captions into Search My Social to quickly load your account.</span>
                        </li>
                    </ul>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    <Button onClick={() => this.props.onClose()} color="white">No Thanks</Button>
                    <Button className={""} onClick={() => this.onPlatformAuth()} color="blue">Let's Do It!</Button>
                </DialogActions>
            </>
        );
    }
    getUsername(){
        const { platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("instagramHandle")){
            return platform.pendingData.instagramHandle;
        }
        return null;
    }
    showAdvancedOptions(e){
        e.preventDefault();
        this.setState({advancedOptions: true});
    }
    handleFinish(){
        const instagramHandle = this.getUsername();
        let { platform } = this.state;
        const state = this.state;
        const values = {
            type: "instagramAPI",
            robotFrequency: parseInt(state.robotFrequency),
            allContentSearchable: state.searchable,
            instagramHandle: instagramHandle,
            addHashtagsToKeywords: state.hashtags,
            attemptGeoTag: state.attemptGeoTag,
            onlySmearchTags: state.onlySmearchTags,
            useFirstLink: state.useFirstLink,
            includeFirstCommentHashtags: state.includeFirstCommentHashtags,
            instagramBeta: true,
            instagramAccessToken: state.instagramAccessToken,
            retrieveAllMedia: state.retrieveAllMedia,
            addRecognition: state.addRecognition,
            importIGTV: state.importIGTV,
            importReels: state.importReels,
            importGuides: state.importGuides,
        };
        platform = Object.assign(platform, values);
        platform.status = "Submit Platform";
        
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source});
        
        Api.updatePlatform(platform, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                this.updateUserOnboarding(data.response[0]);
            }
        }).catch(err => {
            console.log(err);
        });    
    }
    updateUserOnboarding(platform){
        const { user } = this.props;
        const { showOnHomeFeed } = this.state;
        let updateUser = false;
        if(!user.hasOwnProperty("contentPlatforms")){
            user.contentPlatforms = [];
        }
        user.contentPlatforms.push(platform);

        if(showOnHomeFeed){
            if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
                user.guestPreferences.contentPlatforms = [];
            }
            user.guestPreferences.contentPlatforms.push(platform.contentPlatformId);
            updateUser = true;
        }
        if(user.userPreferences.hasOwnProperty("onboarding") || updateUser){
            user.userPreferences.onboarding.step4Complete = true;
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    setTimeout(() => {
                        this.props.onSuccess(user);
                    }, 3000);
                }
            }).catch(err => {
                console.log(err);
            });
        }else{
            setTimeout(() => {
                this.props.onSuccess(user);
            }, 5000);
        }
    }
    onCancelPlatform(){
        const { platform } = this.state;
        const { user } = this.store.getState();
        if(platform !== null && platform.status === "Platform Authorized"){
            const source = axios.CancelToken.source();
            Api.deletePlatform(platform, source, user.userId).then((data) => { 
                //Success
            }).catch(err => {
                console.log(err);
            });
        }
        this.props.onClose();
    }
    renderUserStep(){
        const { classes } = this.props;
        const { loading, imageUrl, advancedOptions, saving, instagramRequest } = this.state;
        const username = this.getUsername();
        const authUrl = Config.getInstagramAuthUrl();
        const { authorized, user } = this.store.getState();
        const { searchable, hashtags, attemptGeoTag, includeFirstCommentHashtags, onlySmearchTags, retrieveAllMedia, 
            addRecognition, importIGTV, useFirstLink, importReels, importGuides } = this.state;
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return(
            <>
                <DialogContent id="instagramWizard-slide-description" className={classes.modalBody}>
                    <img src={instagramIcon} alt="instagram" />
                    
                    {
                        loading ?
                            <LoaderComponent align="center" />
                        :
                        <>
                            <h4 className={classes.modalTitle}>Instagram has authorized the following account:</h4>
                            {
                                username && username.length > 0 ?
                                    <div className={classes.preview}>
                                        <div>
                                            <span className={classes.previewImg}>
                                                {
                                                    imageUrl !== null ?
                                                        <img src={imageUrl} alt={username} />
                                                    :
                                                        instagramRequest === false ?
                                                            <LoaderComponent align="center" />
                                                        :
                                                        <></>
                                                }
                                            </span>
                                            <span className={classes.previewUsername}>{username}</span>
                                        </div>
                                        <Link href={authUrl} className={classes.wrongLink} onClick={(e) => this.onPlatformReAuth(e)}>
                                            Wrong Account?
                                        </Link>
                                        {
                                            advancedOptions === false ?
                                                <div className={classes.advancedOptions}>
                                                    <Link href={"/"} onClick={(e) => this.showAdvancedOptions(e)}>
                                                        Advanced Options
                                                    </Link>
                                                </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                :
                                <></>
                            }
                            {
                                advancedOptions ?
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.optionsGridItem}>
                                        {/* <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("showOnHomeFeed")}
                                                    checked={showOnHomeFeed}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label={(
                                                    showOnHomeFeed ?
                                                        "Show Instagram Content on Home Feed"
                                                    :
                                                    "Do NOT show Instagram Content on Home Feed"
                                                )}
                                            />
                                        </div> */}
                                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("searchable")}
                                                    checked={searchable}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Make All Content Immediately Searchable"
                                            />
                                        </div>
                                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("hashtags")}
                                                    checked={hashtags}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Add all hashtags from the post caption to your search result keywords"
                                            />
                                        </div>
                                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("useFirstLink")}
                                                    checked={useFirstLink}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Automatically use the first link found as the Click-Through Url"
                                            />
                                        </div>
                                        <div className={classes.labelText}>
                                            <label>
                                                Pro Features
                                            </label>
                                        </div>
                                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("includeFirstCommentHashtags")}
                                                    checked={includeFirstCommentHashtags}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    // disabled={(hashtags === false)}
                                                    disabled={(user.userLevel < 1 ? true : false)}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot,
                                                        disabled: classes.disabledCheckboxAndRadio,
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Attempt to add hashtags from the first comment to your search result keywords"
                                            />
                                        </div>
                                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("addRecognition")}
                                                    checked={addRecognition}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    disabled={(user.userLevel < 1 ? true : false)}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot,
                                                        disabled: classes.disabledCheckboxAndRadio,
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Attempt to add recognized media to your search result keywords"
                                            />
                                        </div>
                                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => this.handleToggle("attemptGeoTag")}
                                                    checked={attemptGeoTag}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    disabled={(user.userLevel < 1 ? true : false)}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot,
                                                        disabled: classes.disabledCheckboxAndRadio,
                                                    }}
                                                />
                                                }
                                                classes={{ label: classes.label, root: classes.labelRoot }}
                                                label="Attempt to Geo-Tag the posts"
                                            />
                                        </div>
                                        {
                                            showBetaFeatures ?
                                                <>
                                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.handleToggle("retrieveAllMedia")}
                                                                checked={retrieveAllMedia}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                disabled={(user.userLevel < 1 ? true : false)}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot,
                                                                    disabled: classes.disabledCheckboxAndRadio,
                                                                }}
                                                            />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label="Attempt to retrieve all post media"
                                                        />
                                                    </div>
                                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.handleToggle("importGuides")}
                                                                checked={importGuides}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                disabled={(user.userLevel < 1 ? true : false)}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot,
                                                                    disabled: classes.disabledCheckboxAndRadio,
                                                                }}
                                                            />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label="Attempt to Import Guides"
                                                        />
                                                    </div>
                                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.handleToggle("importReels")}
                                                                checked={importReels}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                disabled={(user.userLevel < 1 ? true : false)}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot,
                                                                    disabled: classes.disabledCheckboxAndRadio,
                                                                }}
                                                            />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label="Attempt to Import Reels"
                                                        />
                                                    </div>
                                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.handleToggle("importIGTV")}
                                                                checked={importIGTV}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                disabled={(user.userLevel < 1 ? true : false)}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot,
                                                                    disabled: classes.disabledCheckboxAndRadio,
                                                                }}
                                                            />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label="Attempt to import IGTV"
                                                        />
                                                    </div>
                                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.handleToggle("onlySmearchTags")}
                                                                checked={onlySmearchTags}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                disabled={(user.userLevel < 1 ? true : false)}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot,
                                                                    disabled: classes.disabledCheckboxAndRadio,
                                                                }}
                                                            />
                                                            }
                                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                                            label="Only upload posts with the #smearch or #searchme hashtags"
                                                        />
                                                    </div>
                                                </>
                                            :
                                            <></>
                                        }
                                        <p className={classes.smallNotice}>(You can always change these settings later)</p>
                                    </GridItem>
                                :
                                <></>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    {
                        loading === false ?
                            <>
                                <Button disabled={saving} onClick={() => this.onCancelPlatform()} color="white">Nevermind</Button>
                                <Button className={classes.saveButton} onClick={() => this.handleFinish()} color="blue">
                                    {
                                        saving ?
                                            <LoaderComponent align="center" />
                                        :
                                            "Link this account"
                                    }
                                </Button>
                            </>
                        :
                        <></>
                    }
                </DialogActions>
            </>
        );
    }
    render() {
        const { classes } = this.props;
        const { userStep, saving } = this.state;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="instagramWizard-slide-title"
                aria-describedby="instagramWizard-slide-description"
            >
                <DialogTitle
                    id="instagramWizard-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                        disabled={saving}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                {
                    userStep ?
                        this.renderUserStep()
                    :
                    this.renderAuthStep()
                }
            </Dialog>
        );
    }
};

InstagramWizardModal.defaultProps = {
    open: false
};
InstagramWizardModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool
};
export default withStyles(instagramWizardModalStyle)(InstagramWizardModal);
