import {
    container,
    main,
    mainRaised,
} from "../material-kit-pro-react.jsx";

const circlesHomePageStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    container: {
        ...container,
        padding: theme.spacing(2)
    },
    videoTitle:{
        marginTop:"20px"
    }
});

export default circlesHomePageStyle;