import {
    cardTitle,
    whiteColor,
    grayColor,
    primaryColor,
} from "../../material-kit-pro-react.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";
  
const adButtonModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0.75rem"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    socialLineButton: {
        "&, &:hover": { color: whiteColor },
        marginLeft: "5px",
        marginRight: "5px"
    },
    cardLoginHeader: {
        marginTop: "-40px",
        padding: "20px 0",
        width: "100%",
        marginBottom: "15px"
    },
    cardLoginBody: {
        paddingTop: "0",
        paddingBottom: "0"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        display: "block",
        textAlign: "center",
        "& > div": {
            marginTop: "0px !important",
            marginLeft: "0px !important"
        }
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    modalCloseButtonLogin: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        "& a": {
            color: primaryColor[0]
        }
    },
    title: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "20px"
    }
});
  
export default adButtonModalStyle;
  