import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customSelectStyle from "../../../../../assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const style = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    formControl: {
        marginBottom: "5px"
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    },
    selectLabelNowrap: {
        whiteSpace: "nowrap"
    }
});

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.state = {
            allContentSearchable: true,
            attemptGeoTag: true,
            propsReceived: false,
            showOnHomeFeed: true
        };
    }
    componentWillReceiveProps(props){
        const { platform } = props;
        const { propsReceived } = this.state;
        if(propsReceived){
            return;
        }
        if(platform === null || typeof(platform) !== "object"){
            return;
        }
        if(platform.status === "Platform Authorized"){
            return;
        }
        this.setState({
            allContentSearchable: platform.allContentSearchable,
            attemptGeoTag: platform.attemptGeoTag,
            propsReceived: true
        });
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.props.onMount(that.state);
        },100);
    }
    sendState() {
        return this.state;
    }
    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }
    handleToggle(name) {
        let newState = {
            [name]: !this.state[name]
        };
        this.setState(newState);
    };
    isValidated() {
        return true;
    }
    render() {
        const { classes } = this.props;
        const { allContentSearchable, attemptGeoTag } = this.state;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        {/* <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("showOnHomeFeed")}
                                    checked={showOnHomeFeed}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label={(
                                    showOnHomeFeed ?
                                        "Show Instagram Content on Home Feed"
                                    :
                                    "Do NOT show Instagram Content on Home Feed"
                                )}
                            />
                        </div> */}
                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("allContentSearchable")}
                                    checked={allContentSearchable}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Make All Content Immediately Searchable"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("attemptGeoTag")}
                                    checked={attemptGeoTag}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Add Geo-Tag information to your search result keywords"
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Step2.defaultProps = {
    onMount: function(){ },
    platform: null
}
Step2.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func,
  platform: PropTypes.object
};

export default withStyles(style)(Step2);
