import React from 'react';
import { Helmet } from 'react-helmet';
import Api from '../../../../assets/js/utils/Api';
import { helper } from "../../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CustomColorPicker from "../../../components/CustomInput/CustomColorPicker.jsx";

import paintIcon from "../../../../assets/img/paint-icon.png";
import CachedIcon from "@material-ui/icons/Cached";

import { primaryColor as defaultPrimaryColor } from "../../../../assets/jss/material-kit-pro-react.jsx";
import styleBoxStyle from "../../../../assets/jss/dashboard/editmode/styleBoxStyle.js";

const StyleBox = class extends React.Component {
    constructor(props){
        super(props);
       
        this.store = this.props.store;
        this.history = this.props.history;
        this.primaryColor = null;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(this.props.user);

        let coverPhoto = null;
        let coverMediaId = null;
        if(values.hasOwnProperty("coverMedia")){
            coverPhoto = values.coverMedia.downloadUrlProfile;
            coverMediaId = values.coverMedia.mediaId;
        }
        let isCustomColor = helper.isCustomColor(values.guestPreferences.primaryColor);
        let customColor = defaultPrimaryColor[0];
        if(isCustomColor){
            customColor = values.guestPreferences.primaryColor;
        }

        let viewType = "list";
        let showButtons = false;
        let contentPlatforms = [];
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("defaultLayout")){
            viewType = user.guestPreferences.defaultLayout;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("contentPlatforms")){
            contentPlatforms = user.guestPreferences.contentPlatforms;
        }

        this.state = {
            values: values,
            isSearchPage: true,
            searchTerm: null,
            customColor: customColor,
            coverPhoto: coverPhoto,
            coverPhotoDataUrl: null,
            coverMediaId: coverMediaId,
            fonts: Api.getFonts(),
            viewType: viewType,
            showButtons: showButtons,
            contentPlatforms: contentPlatforms,
            openColorPicker: false,
            anchorEl: null,
            openStyleBox: this.props.openStyleBox
        };

        this.onStyleChangeTimeout = null;
    }
    static getDerivedStateFromProps(props, state) {
        if (props.openStyleBox !== state.openStyleBox) {
            return {
                openStyleBox: props.openStyleBox,
                values: Api.prepareMemberObject(props.user)
            };
        }
        return null;
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        this.primaryColor = palette.custom.main;
        return palette.custom.main;
    }
    handleGuestPrefChange(event, name, value = null, customColor = false) {
        if(value === null){
            value = event.target.value;
        }
        let state = {
            ...this.state,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    [name]: value
                }                
            }
        };
        if(customColor){
            state['customColor'] = value;
        }
        this.setState(state, () => {
            if(name === "layoutTransparency"){
                if(this.onStyleChangeTimeout){
                    clearTimeout(this.onStyleChangeTimeout);
                }
                this.onStyleChangeTimeout = setTimeout(() => {
                    this.props.onStyleChange(state);
                },500);
            }else{
                this.props.onStyleChange(state);
            }
        });
    }
    onColorPickerClose(){
        this.setState({openColorPicker: false, anchorEl: null});
    }
    colorPicker(e){
        const state = {
            ...this.state,
            openColorPicker: true,
            anchorEl: e.currentTarget,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    primaryColor: this.state.customColor
                }                
            }
        };
        this.setState(state, () => {
            this.props.onStyleChange(state);
        });
    }
    handleViewOptions(viewType){
        const state = {
            ...this.state,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    defaultLayout: viewType
                },
                userPreferences: {
                    ...this.state.values.userPreferences,
                    searchViewResults: viewType
                }
            },
            viewType: viewType
        };
        this.setState(state, () => {
            this.props.onStyleChange(state);
        });
    }
    getTemplateLabel(template){
        let label = "Classic Template";
        switch(template){
            case 'simple':
                label = "Simple Template";
                break;
            default:
                break;
        }
        return label;
    }
    getTemplateName(template){
        let name = "simple";
        switch(template){
            case 'simple':
                name = "classic";
                break;
            case 'classic':
                name = "simple";
                break;
            default:
                break;
        }
        return name;
    }
    render() {
        const { classes, user } = this.props;
        const { values, fonts, openColorPicker, anchorEl, customColor, openStyleBox } = this.state;
        const { primaryColor, buttonStyle, fontFamily, fontSize, layoutTransparency } = values.guestPreferences;
        let isCustomColor = helper.isCustomColor(primaryColor);

        let theme = "light"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("theme") && user.guestPreferences.theme === "dark"){
            theme = "dark";            
        }

        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }
        const nextTemplate = this.getTemplateName(template);

        let backgroundCss = {}
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("layoutTransparency")){
            const { layoutTransparency } = user.guestPreferences;
            let backgroundOpacity = 1-(layoutTransparency/100);
            backgroundCss.backgroundColor= "rgb(255, 255, 255, "+backgroundOpacity+")";
            if(theme === "dark"){
                backgroundCss.backgroundColor= "rgb(1, 1, 1, "+backgroundOpacity+")";
            }
            
            if(backgroundOpacity === 0){
                backgroundCss.boxShadow= "none"
            }
        }

        let headLinks = [];
        headLinks.push({
            rel: "stylesheet",
            href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Raleway:wght@400;500;700&family=Courier+Prime:wght@400;500;700&family=Belleza:wght@400;500;700&family=Sorts+Mill+Goudy:wght@400;500;700&family=Questrial:wght@400;500;700&family=Julius+Sans+One:wght@400;500;700&family=Coming+Soon:wght@400;500;700&display=swap"
        });


        return (
            <div className={classes.styleBoxContent}>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12}>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal+" "+classes.customSwitchBox}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={true}
                                        onChange={(e) => this.handleGuestPrefChange(e, "template", nextTemplate)}
                                        name="template"
                                        icon={(
                                            <Button justIcon color="primary">
                                                <CachedIcon className={classes.uncheckedIconCustom}/>
                                            </Button>
                                        )}
                                        checkedIcon={(
                                            <Button justIcon color="primary">
                                                <CachedIcon className={classes.checkedIconCustom} />
                                            </Button>
                                        )}
                                        classes={{
                                            switchBase: classes.switchBaseCustom,
                                            checked: classes.switchCheckedCustom,
                                            thumb: classes.switchIconCustom,
                                            track: classes.switchBarCustom
                                        }}
                                    />
                                }
                                classes={{label: classes.label, root: classes.labelRoot+" "+classes.themeLabelRoot}}
                                label={this.getTemplateLabel(template)}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                {
                    template !== "simple" || openStyleBox === false ?
                        <GridContainer alignItems="center" className={classes.gridContainer+" "+classes.rangerSlider}>
                            <GridItem xs={4}>
                                <label className={classes.customLabel}>
                                    Transparency
                                </label>
                            </GridItem>
                            <GridItem xs={8}>
                                <div className="slidecontainer">
                                    <input type="range" min="0" max="100" value={layoutTransparency} className="slider" id="myRange" onChange={(e) => this.handleGuestPrefChange(e, "layoutTransparency")} />
                                </div>
                            </GridItem>
                        </GridContainer>
                    :
                    <></>
                }
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={5} sm={4}>
                        <ul className={classes.fontSize}>
                            <li className={"large"+(fontSize === "large"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e,"fontSize", "large")}>
                                <span style={{fontFamily: fontFamily}}>Aa</span>
                            </li>
                            <li className={"small"+(fontSize === "small"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e,"fontSize", "small")}>
                                <span style={{fontFamily: fontFamily}}>Aa</span>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem xs={7} sm={8}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Font"
                            showInfoTooltip={values.userPreferences.showTips}
                            onShowInfo={() => this.onTooltip('fontFamily', true) }
                            selectProps={{
                                onChange: (e) => this.handleGuestPrefChange(e,"fontFamily"),
                                value: values.guestPreferences.fontFamily
                            }}
                            inputProps={{
                                name: "fontFamily",
                                id: "outlined-fontFamily",
                            }}
                            applyFont={true}
                            items={fonts}
                            itemLabel="fontName"
                            itemValue="fontFamily"
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={6}>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal+" "+classes.customSwitchBox}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={theme === "dark"}
                                        onChange={(e) => this.handleGuestPrefChange(e, "theme", (theme === "dark" ? "light" : "dark"))}
                                        name="theme"
                                        icon={(
                                            <Button justIcon color="primary">
                                                <CachedIcon className={classes.uncheckedIconCustom}/>
                                            </Button>
                                        )}
                                        checkedIcon={(
                                            <Button justIcon color="primary">
                                                <CachedIcon className={classes.checkedIconCustom} />
                                            </Button>
                                        )}
                                        classes={{
                                            switchBase: classes.switchBaseCustom,
                                            checked: classes.switchCheckedCustom,
                                            thumb: classes.switchIconCustom,
                                            track: classes.switchBarCustom
                                        }}
                                    />
                                }
                                classes={{label: classes.label, root: classes.labelRoot+" "+classes.themeLabelRoot}}
                                label={(theme === "dark" ? "Dark" : "Light")}
                            />
                        </div>
                        <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={buttonStyle === "rounded"}
                                        onChange={(e) => this.handleGuestPrefChange(e, "buttonStyle", (buttonStyle === "rounded" ? "rectangle" : "rounded"))}
                                        name="buttonStyle"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label={(buttonStyle === "rounded" ? "Rounded Buttons" : "Square Buttons")}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={6}>
                        <ul className={classes.primaryColor}>
                            <li className={(primaryColor === "primary"?"active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "primary")}>
                                <span></span>
                            </li>
                            <li className={classes.grayColor+(primaryColor === "gray"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "gray")}>
                                <span></span>
                            </li>
                            <li className={classes.customInfo+(primaryColor === "customInfo"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customInfo")}>
                                <span></span>
                            </li>
                            <li className={classes.customRose+(primaryColor === "customRose"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customRose")}>
                                <span></span>
                            </li>
                            <li className={classes.customSuccess+(primaryColor === "customSuccess"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customSuccess")}>
                                <span></span>
                            </li>
                            <li className={classes.colorPicker+(isCustomColor === true ? " active":"")}>
                                <span style={{backgroundColor: customColor}} onClick={(e) => this.colorPicker(e)}>
                                    <img src={paintIcon} alt="color picker" />
                                </span>
                                <CustomColorPicker 
                                    open={openColorPicker} 
                                    onChange={(color) => this.handleGuestPrefChange(null, 'primaryColor', color, true)} 
                                    onClose={() => this.onColorPickerClose()}
                                    color={customColor}
                                    anchorEl={anchorEl}
                                /> 
                            </li>
                        </ul>
                    </GridItem>
                </GridContainer>
                <Helmet 
                    link={headLinks}
                />
            </div>
        )
    }
}

StyleBox.defaultProps = {
    subscription: null,
    paymentData: null,
    openStyleBox: false,
    onStyleChange: function(){ }
}
StyleBox.propTypes = {
    subscription: PropTypes.object,
    paymentData: PropTypes.object,
    openStyleBox: PropTypes.bool,
    onStyleChange: PropTypes.func
}

export default withTheme(withStyles(styleBoxStyle)(StyleBox));
