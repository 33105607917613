import {
    container,
    main,
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "../tabs/dashboardSubTabs";

const dashboardTabAdminStyle = theme => ({
    main,
    container: {
        ...container,
        padding: theme.spacing(2),
        "& .navPillsContentWrapper": {
            marginTop: "0px",
        },
        "& .navPillsTabContent": {
            paddingLeft: "2px !important",
            paddingRight: "0px !important"
        }
    },
    ...dashboardSubTabs(theme),
});

export default dashboardTabAdminStyle;