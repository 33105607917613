import React from "react";
import axios from 'axios';
import Api from '../../../assets/js/utils/Api';
import Config from "../../../../Config";
import { helper } from "../../../assets/js/utils/Element";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import PaymentProcessingModal from "../../components/Stripe/PaymentProcessingModal";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import NavPills from "../NavPills/NavPills.jsx";
import Close from "@material-ui/icons/Close";
import PaymentIcon from "@material-ui/icons/Payment";
import StripeCard from "../Stripe/Card";
import CustomInput from "../CustomInput/CustomInput.jsx";
import {StripeProvider, Elements} from 'react-stripe-elements';
import LoaderComponent from "../Loader";
import Danger from "../Typography/Danger";
import Success from "../Typography/Success";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import changeUserLevelModalStyle from "../../../assets/jss/dashboard/changeUserLevelModalStyle";

import UpgradeStep2 from "./UserLevelWizard/UpgradeStep2";
import UpgradeStep3 from "./UserLevelWizard/UpgradeStep3";
import DowngradeStep1 from "./UserLevelWizard/DowngradeStep1";
import DowngradeStep2 from "./UserLevelWizard/DowngradeStep2";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeUserLevelModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { upgrade } = this.props;
        const { user, packages, authUsername } = this.props.store.getState();

        let userLevel = 0;
        let userLevelLabel = "";
        let features = [];
        let userPackage = null;
        let filteredPackages = [];
        if(packages.length > 0){
            userLevel = packages[0].level;
            userLevelLabel = packages[0].name;
            features = packages[0].features;
            packages.map((packageObj, key) => {
                if(user.hasOwnProperty("userLevel")){
                    userLevel = user.userLevel;
                    if(user.userLevel === packageObj.level){
                        userLevelLabel = packageObj.name;
                        features = packageObj.features;
                        userPackage = packageObj;

                        if(upgrade && packageObj.level < 1 && typeof(packages[key+1]) !== "undefined"){
                            const nextPackage = packages[key+1];
                            userLevel = nextPackage.level;
                            userLevelLabel = nextPackage.name;
                            features = nextPackage.features;
                            userPackage = nextPackage;
                        }
                        filteredPackages.push(packageObj);
                        return null;
                    }
                }
                if(packageObj.hasOwnProperty("legacy") && packageObj.legacy && authUsername === null){
                    return null;
                }
                filteredPackages.push(packageObj);
                return null;
            });
            

            if(user.hasOwnProperty("userLevel")){
                userLevel = user.userLevel;
                filteredPackages.map((packageObj, key) => {
                    if(user.userLevel === packageObj.level){
                        userLevelLabel = packageObj.name;
                        features = packageObj.features;
                        userPackage = packageObj;

                        if(upgrade && packageObj.level < 1 && typeof(filteredPackages[key+1]) !== "undefined"){
                            const nextPackage = filteredPackages[key+1];
                            userLevel = nextPackage.level;
                            userLevelLabel = nextPackage.name;
                            features = nextPackage.features;
                            userPackage = nextPackage;
                        }
                    }
                    return null;
                });
            }            
        }
        const { paymentData } = this.props;
        let { redirectUrl } = this.props;
        if(redirectUrl === null){
            redirectUrl = "/dashboard/account";
        }

        this.state = {
            userLevel: userLevel,
            userLevelLabel: userLevelLabel,
            features: features,
            userPackage: userPackage,
            changePackage: false,
            step: 1,
            discountCode: "",
            upgradeDetails: null,
            paymentToken: paymentData.paymentToken,
            paymentLast4Digits: paymentData.paymentLast4Digits,
            paymentExpirationDate: paymentData.paymentExpirationDate,
            cardSuccess: (paymentData.paymentLast4Digits.length > 0 ? true: false),
            subscription: this.props.subscription, 
            showAuthorize: false,
            authorize: false,
            editCard: false,
            saving: false, 
            openProcessingModal: false,
            updateUserInfo: false,
            errorMessage: null,
            billingFrequency: "Monthly",
            validatingCouponCode: false,
            coupon: null,
            isCouponValid: null, //null for not checked, false for invalid and true for invalid
            savingCard: false,
            redirectUrl: redirectUrl
        };

        this.newUserObject = null;
        this.packages = filteredPackages;
        this.handleChange = this.handleChange.bind(this);
        this.changePackage = this.changePackage.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleUpgradeStep1 = this.handleUpgradeStep1.bind(this);
        this.handleUpgradeStep2 = this.handleUpgradeStep2.bind(this);
        this.handleUpgradeStep3 = this.handleUpgradeStep3.bind(this);
        this.handleDowngradeStep1 = this.handleDowngradeStep1.bind(this);
        this.handleDowngradeStep2 = this.handleDowngradeStep2.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
        this.editCard = this.editCard.bind(this);
        this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this);
        this.authorize = this.authorize.bind(this);
        this.onProcessingClose = this.onProcessingClose.bind(this);
        this.applyCoupon = this.applyCoupon.bind(this);
    }
    componentWillReceiveProps(props){
        const { paymentData, subscription } = props;
        this.setState({
            paymentToken: paymentData.paymentToken,
            paymentLast4Digits: paymentData.paymentLast4Digits,
            paymentExpirationDate: paymentData.paymentExpirationDate, 
            cardSuccess: (paymentData.paymentLast4Digits.length > 0 ? true: false),
            subscription: subscription
        });
    }
    handleChange(event, stateName) {
        let userLevelLabel = "";
        const { authUsername } = this.props.store.getState();
        const levelKey = event.target.value;
        let features = [];
        let userPackage = this.state.userPackage;
        this.packages.map((packageObj, key) => {
            if(packageObj.hasOwnProperty("legacy") && packageObj.legacy && authUsername === null){
                return null;
            }
            if(levelKey === key){
                userLevelLabel = packageObj.name;
                features = packageObj.features;
                userPackage = packageObj;
            }
            return null;
        });
        this.setState({ 
            [stateName]: event.target.value, 
            userLevelLabel: userLevelLabel, 
            features: features,
            userPackage: userPackage
        });
    }
    handleInputChange(event, stateName) {
        this.setState({ 
            [stateName]: event.target.value, 
        });
    }
    changePackage(change = true){
        const { paymentLast4Digits, coupon } = this.state;
        if(coupon !== null && coupon.percent_off === 100){
            return this.setState({changePackage: change});
        }

        if(paymentLast4Digits && paymentLast4Digits.length > 0){
            this.setState({changePackage: change});
        }else{
            this.setState({
                requiredCard: true,
                // errorMessage: "Please enter your card details and authorize."
            });
        }
        
    }
    updateMaxFeatured(user){
        const { subscription } = this.state;
        if(subscription === null || !subscription.hasOwnProperty("userLevel")){
            return user;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("maxFeatured")){
            switch(subscription.userLevel){
                case 0:
                    user.guestPreferences.maxFeatured = "6";
                    break;
                case 1:
                    user.guestPreferences.maxFeatured = "12";
                    break;
                case 2:
                    user.guestPreferences.maxFeatured = "100";
                    break;
                default:
                    user.guestPreferences.maxFeatured = "6";
                    break;
            }
        }
        return user;
    }
    cancel(updateUser = false){
        const { step, billingFrequency, subscription } = this.state;
        this.setState({
            changePackage: false,
            upgradeDetails: null,
            step: 1
        });
        if(this.props.onClose){
            this.props.onClose();
        }

        if(updateUser && step > 1){
            let { user } = this.store.getState();
            if(this.newUserObject !== null){
                user = Api.prepareMemberObject(this.newUserObject);
            }
            user.paymentFrequency = billingFrequency;
            if(subscription !== null && subscription.hasOwnProperty("userLevel")){
                user.userLevel = subscription.userLevel;
            }
            user = this.updateMaxFeatured(user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
            const { redirectUrl } = this.state;
            this.history.push(redirectUrl);
        }
        
    }
    handleUpgradeStep1(state){
        this.setState({
            step: 2,
            upgradeDetails: state
        });
    }
    handleUpgradeStep2(){
        this.setState({
            step: 3,
        });
        this.handleSubscription();
    }
    handleUpgradeStep3(){
        const { billingFrequency, subscription } = this.state;
        let { user } = this.store.getState();
        if(this.newUserObject !== null){
            user = Api.prepareMemberObject(this.newUserObject);
        }
        user.paymentFrequency = billingFrequency;
        if(subscription !== null && subscription.hasOwnProperty("userLevel")){
            user.userLevel = subscription.userLevel;
        }

        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            if(returnUrl === "search"){
                window.location.href = Config.getFrontEndUrl()+"/"+user.username;
            }else{
                this.cancel();
                user = this.updateMaxFeatured(user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
                this.history.push(decodeURIComponent(returnUrl));
            }
            return;
        }

        const { redirectUrl } = this.state;
        this.history.push(redirectUrl);
    }
    onBack(step){
        let state = {
            changePackage: (step === 1 ? false : this.state.changePackage)
        };
        if(step !== 1){
            --step;
        }
        state['step'] = step;
        this.setState(state);
    }
    renderUpgrade(){
        const { step, userPackage, paymentLast4Digits, billingFrequency, discountCode, coupon } = this.state;
        const upgradeDetails = {
            paymentLast4Digits: paymentLast4Digits,
            billingType: billingFrequency
        };
        let content = null;
        switch(step){
            case 1:
                content = <UpgradeStep2 store={this.store} userPackage={userPackage} upgradeDetails={upgradeDetails} onClose={this.cancel} onSuccess={this.handleUpgradeStep2} onBack={() => this.onBack(step)} discountCode={discountCode} coupon={coupon} />;
                break;
            case 3:
                content = <UpgradeStep3 store={this.store} userPackage={userPackage} onClose={this.handleUpgradeStep3} onBack={() => this.onBack(step)} />;
                break;
            default:
                content = null;
                break;
        }
        return content;
    }
    handleDowngradeStep1(state = null){
        const { userPackage, subscription } = this.state;
        if(state === null){
            this.cancel();
            return;
        }
        this.setState({
            step: 2,
            upgradeDetails: state
        });
        if(userPackage.level === 0){
            const source = axios.CancelToken.source();
            if(subscription !== null){
                Api.deleteSubscription({}, source).then(data => {
                    this.finishLevelChange(true);
                }).catch(err => {

                });
            }else{
                this.finishLevelChange(true);
            }
        }else{
            this.handleSubscription();
        }
    }
    handleDowngradeStep2(){
        const { billingFrequency, subscription } = this.state;
        let { user } = this.store.getState();
        if(this.newUserObject !== null){
            user = Api.prepareMemberObject(this.newUserObject);
        }
        user.paymentFrequency = billingFrequency;
        if(subscription !== null && subscription.hasOwnProperty("userLevel")){
            user.userLevel = subscription.userLevel;
        }
        
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            if(returnUrl === "search"){
                window.location.href = Config.getFrontEndUrl()+"/"+user.username;
            }else{
                this.cancel();
                user = this.updateMaxFeatured(user);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
                this.history.push(decodeURIComponent(returnUrl));
            }
            return;
        }
        const { redirectUrl } = this.state;
        this.history.push(redirectUrl);
    }
    renderDowngrade(){
        const { step, userPackage, billingFrequency } = this.state;
        let content = null;
        switch(step){
            case 1:
                content = <DowngradeStep1 billingFrequency={billingFrequency} store={this.store} userPackage={userPackage} onClose={this.cancel} onSuccess={this.handleDowngradeStep1} onBack={() => this.onBack(step)} />;
                break;
            case 2:
                content = <DowngradeStep2 store={this.store} userPackage={userPackage} onClose={this.handleDowngradeStep2} onBack={() => this.onBack(step)} />;
                break;
            default:
                content = null;
                break;
        }
        return content;
    }
    onMethodChange(deleteSubscription = false){
        this.setState({
            step: 1,
            openProcessingModal: false,
            changePackage: false
        });

        if(deleteSubscription){
            const source = axios.CancelToken.source();
            this.setState({
                cancelToken: source,
                subscription: null
            });
            Api.deleteSubscription({immediate: true}, source).then(data => {
                
            }).catch(err => {

            });
        }
    }
    finishLevelChange(finishFree = false){
        // const { billingFrequency } = this.state;
        // const { user } = this.store.getState();
        // let values = Object.assign({}, user);
        // if(!finishFree){
        //     values.paymentFrequency = billingFrequency;
        // }
        // Api.updateUser(user.userId, values).then((data) => {
        //     this.setState({
        //         openProcessingModal: false
        //     });
            
        //     if(data.numberOfResponses > 0){
        //         this.newUserObject = data.response[0];
        //     }
        // }).catch(err => {
        //     console.log(err);
        // });

        this.setState({
            saving: false,
            updateUserInfo: finishFree
        });

        setTimeout(() => {
            this.setState({
                openProcessingModal: false,
            });
        },1000);
    }
    handleSubscription(){
        const { subscription, userPackage, billingFrequency, coupon } = this.state;
        const source = axios.CancelToken.source(); 
        this.setState({
            cancelToken: source, 
            openProcessingModal: true,
            saving: true
        });
        const requestData = {
            "userLevel": userPackage.level,
	        "billingFrequency": billingFrequency
        }
        // let price = userPackage.pricing.annualAmount;
        // if(billingFrequency === "Monthly"){
        //     price = userPackage.pricing.monthlyAmount;
        // }
        if(coupon !== null){
            requestData['couponId'] = coupon.id;
            // if(coupon.hasOwnProperty("percent_off") && coupon.percent_off !== null && coupon.percent_off > 0){
            //     price = price - ((coupon.percent_off/100)*price);
            // }
        }

        if(subscription === null){
            Api.createSubscription(requestData, source).then((data) => {
                if(data.subscriptionStatus === "active"){
                    // this.fbPixelTrackPrice(price);
                    this.setState({subscription: data});
                    this.finishLevelChange();
                }else{
                    this.setState({subscription: data, saving: false});
                }
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        saving: false,
                        errorMessage: err.message,
                        subscription: null
                    });
                }
            });
        }else{
            Api.updateSubscription(requestData, source).then((data) => {
                if(data.subscriptionStatus === "active"){
                    // this.fbPixelTrackPrice(price);
                    this.setState({subscription: data});
                    this.finishLevelChange();
                }else{
                    this.setState({subscription: data, saving: false});
                }
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        saving: false,
                        errorMessage: err.message,
                        subscription: null
                    });
                }
            });
        }
    }
    fbPixelTrackPrice(price){
        try{
            if('fbq' in window){
                window.fbq('track', 'Subscribe', {
                    value: price,
                    currency: 'USD',
                });
            }
        }catch(e){ console.log(e); }
    }
    billingFrequencyChange(tabId){
        this.setState({
            billingFrequency: (tabId === 0 ? "Monthly" : "Annually")
        });
    }
    onCardChange(){
        this.setState({showAuthorize: true, cardSuccess: false});
    }
    editCard(){
        this.setState({showAuthorize: true, cardSuccess: false});
    }
    cancelEditCard(){
        this.setState({
            showAuthorize: false, 
            cardSuccess: true,
            requiredCard: false
        });
    }
    onPaymentMethodChange(cardDetails){
        const source = axios.CancelToken.source();
        this.setState({
            savingCard: true
        });
        let details = {
            stripeData: cardDetails.stripeData
        };

        Api.createPaymentMethod(details, source).then((data) => {
            this.setState({
                savingCard: false,
                paymentToken: cardDetails.paymentToken,
                paymentLast4Digits: cardDetails.paymentLast4Digits,
                paymentExpirationDate: cardDetails.paymentExpirationDate,
                requiredCard: false,
                authorize: false,
                cardSuccess: true,
                showAuthorize: false,
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    requiredCard: true,
                    savingCard: false,
                    errorMessage: err.message,
                });
            }
        });
    }
    authorize(){
        this.setState({
            requiredCard: false,
            errorMessage: null,
            authorize: true
        });
    }
    onProcessingClose(){
        this.setState({
            step: 1,
            openProcessingModal: false,
            changePackage: false
        });
    }
    onDiscountCodeClear(){
        this.setState({
            discountCode: "",
            isCouponValid: null,
            coupon: null
        });
    }
    applyCoupon(){
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source,
            validatingCouponCode: true,
            isCouponValid: null,
            coupon: null
        });
        Api.checkCoupon(this.state.discountCode, source).then((stripeCoupon) => {
            let validCoupon = true;
            if(stripeCoupon.redeem_by && (stripeCoupon.redeem_by*1000) <= Date.now()){
                validCoupon = false;
            }
            if(stripeCoupon.max_redemptions && stripeCoupon.max_redemptions <= stripeCoupon.times_redeemed){
                validCoupon = false;
            }
            if(validCoupon){
                this.setState({
                    cancelToken: null,
                    validatingCouponCode: false,
                    isCouponValid: true,
                    coupon: stripeCoupon
                });
            }else{
                const errorMessage =  { code : 403, message : "Invalid Coupon" };
                throw errorMessage;
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    validatingCouponCode: false,
                    isCouponValid: false,
                    coupon: null
                });
            }
        });
    }
    onFree(){
        const source = axios.CancelToken.source();
        this.setState({
            updateUserInfo: true,
            cancelToken: source
        });
        Api.deleteSubscription({immediate: true}, source).then(data => {
            this.finishLevelChange(true);
        }).catch(err => {

        });
    }
    render() {
        const { classes } = this.props;
        const { features, userLevel, userPackage, changePackage, subscription, saving, openProcessingModal, updateUserInfo, 
            errorMessage, billingFrequency, paymentLast4Digits, authorize, cardSuccess, showAuthorize, requiredCard,
            validatingCouponCode, isCouponValid, coupon, savingCard } = this.state;
        const { user } = this.props.store.getState();

        if(openProcessingModal){
            return <PaymentProcessingModal updateUserInfo={updateUserInfo} onMethodChange={() => this.onMethodChange(true)} onFree={() => this.onFree()} open={openProcessingModal} saving={saving} errorMessage={errorMessage} subscription={subscription} onClose={this.onProcessingClose} coupon={coupon} />
        }
       
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot+" "+classes.modalRootCustom,
                    paper: classes.modal+" "+classes.modalCustom,
                    scrollPaper: classes.paperScrollPaperCustom,
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.cancel()}
                aria-labelledby="userLevel-slide-title"
                aria-describedby="userLevel-slide-description"
            >
                <DialogTitle
                    id="userLevel-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            changePackage ?
                                userLevel >= user.userLevel ?
                                <h4 className={classes.modalTitle}>Upgrade your Account</h4>
                                :
                                <h4 className={classes.modalTitle}>Downgrade your Account</h4>
                            :
                            <h4 className={classes.modalTitle}>Change Your Account Level</h4>
                        }
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.cancel(true)}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                </DialogTitle>
                <DialogContent
                    id="userLevel-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            changePackage ?
                                userLevel >= user.userLevel ?
                                    this.renderUpgrade()                                    
                                :
                                    this.renderDowngrade()
                            :
                            <>
                             <FormControl className={classes.selectFormControl} fullWidth>
                                    <InputLabel
                                        htmlFor="outlined-userLevel"
                                        className={classes.selectLabel}
                                    >
                                        Account Level
                                    </InputLabel>                                
                                    <Select
                                        id="outlined-userLevel"
                                        onChange={ event => this.handleChange(event, "userLevel")}
                                        value={this.state.userLevel}
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            classes: { paper: classes.selectPaper }
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        inputProps={{
                                            name: "userLevel",
                                            id: "outlined-userLevel",
                                        }}
                                    >
                                        {this.packages.map((packageObj, key) => (
                                            <MenuItem
                                                key={key} 
                                                value={key}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                            >
                                                {packageObj.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <NavPills
                                    alignCenter
                                    color="rose"
                                    active={(billingFrequency.toLowerCase() === "monthly" ? 0 : 1)}
                                    tabs={[
                                    {
                                        tabButton: "monthly"
                                    },
                                    {
                                        tabButton: "yearly"
                                    }
                                    ]}
                                    onChange={(tabId) => this.billingFrequencyChange(tabId)}
                                />
                                <Card plain pricing className={classes.card}>
                                    <CardBody pricing>
                                        <h1 className={classes.cardTitle}>
                                            {
                                                billingFrequency.toLowerCase() === "monthly" ?
                                                    <>
                                                        <small>$</small>{" "+userPackage.pricing.monthlyAmount+" "} <small>/mo</small>
                                                    </>
                                                :
                                                    <>
                                                        <small>$</small>{" "+userPackage.pricing.annualAmount+" "} <small>/year</small>
                                                    </>
                                            }
                                        </h1>
                                        <ul>
                                            {
                                                features.map(feature => {
                                                    return <li key={feature.name}>{feature.name}</li>
                                                })
                                            }
                                        </ul>
                                    </CardBody>
                                </Card>
                                {
                                    userLevel !== 0 ?
                                        <>
                                            <CustomInput
                                                id="outlined-couponCode"
                                                labelText="Do You Have a Discount Code?"
                                                inputProps={{
                                                    value: this.state.discountCode,
                                                    required: true,
                                                    onChange: event => this.handleInputChange(event, "discountCode"),
                                                    name: "discountCode",
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                onClear={() => this.onDiscountCodeClear()}
                                            />
                                            <div>
                                                {
                                                    validatingCouponCode ?
                                                        <LoaderComponent align="right" />
                                                    :
                                                    <div className={classes.alignRight}>
                                                        {
                                                            isCouponValid === true ?
                                                                <Success>This code is valid</Success>
                                                            :
                                                                isCouponValid === false ?
                                                                    <Danger>This code is invalid</Danger>
                                                                :
                                                                <></>
                                                        }
                                                        <Button color="primary" round onClick={this.applyCoupon} className={classes.applyButton}>
                                                            Apply
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    userLevel !== 0 ?
                                        <div className={classes.alignRight}>
                                            <p>Enter Your Credit Card Information.  Your card will not be charged yet.</p>
                                            {
                                                paymentLast4Digits && paymentLast4Digits.length > 0 && showAuthorize === false ?
                                                    <CustomInput
                                                        id="outlined-existingCard"
                                                        labelText="Existing Card"                                    
                                                        inputProps={{
                                                            value: "xxxx xxxx xxxx "+paymentLast4Digits,
                                                            disabled: true,
                                                        }}                                    
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            className: classes.formControl+" fs-block "
                                                        }}
                                                    />
                                                :
                                                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_CLIENT_KEY}>
                                                    <Elements>
                                                        <StripeCard classes={classes} store={this.store} authorize={authorize} renderCardOnly={true} loading={savingCard} errorMessage={errorMessage} 
                                                        success={cardSuccess} error={requiredCard} onChange={this.onPaymentMethodChange} allowGoBack={false} onCardChange={this.onCardChange} />
                                                    </Elements>
                                                </StripeProvider>
                                            }
                                            {
                                                showAuthorize === true ?
                                                    <>
                                                        <Button color="primary" type="submit" round onClick={this.authorize} className={classes.button}>
                                                            <PaymentIcon className={classes.icons} />Authorize
                                                        </Button>
                                                        {
                                                            (paymentLast4Digits && paymentLast4Digits.length > 0) ?
                                                                <Button color="info" round onClick={() => this.cancelEditCard()}>Cancel</Button>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                :
                                                    (paymentLast4Digits && paymentLast4Digits.length > 0) ?
                                                        <Button color="info" round onClick={() => this.editCard()}>Change</Button> 
                                                    :
                                                    <></>                   
                                            }
                                        </div>
                                    :
                                    <></>
                                }
                            </>
                        }
                        {
                            requiredCard ?
                                showAuthorize ?
                                    <p className={classes.requiredCard}>Press authorize to validate your credit card</p>
                                :
                                <p className={classes.requiredCard}>Make sure to enter your credit card and click the Authorize button</p>
                            :
                            <></>
                        }
                   
                </DialogContent>
                {
                    changePackage === false ?
                        <DialogActions
                            className={classes.modalFooter + " " + classes.justifyContentCenter}
                            >
                            <Button onClick={() => this.cancel()} color="white" >
                                <ArrowBackIcon className={classes.arrow} /> Cancel
                            </Button>
                            <Button color="blue" onClick={() => this.changePackage()} className={classes.changeLevelButton}>
                                Next
                            </Button>
                        </DialogActions>
                    :
                    <></>
                }
            </Dialog>
        );
    }
};

ChangeUserLevelModal.defaultProps = {
  open: false,
  redirectUrl: null,
  upgrade: false
};
ChangeUserLevelModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  redirectUrl: PropTypes.string,
  upgrade: PropTypes.bool
};
export default withStyles(changeUserLevelModalStyle)(ChangeUserLevelModal);
