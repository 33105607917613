/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
// core components
import Button from "../../components/CustomButtons/Button.jsx";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check"; 
import instagramIcon from "../../../assets/img/instagram-icon.png";
import tiktokIcon from "../../../assets/img/tiktok-icon.png";
import snapchatIcon from "../../../assets/img/snapchat-icon.png";
import soundcloudIcon from "../../../assets/img/soundcloud-icon.png";
import whatsappIcon from "../../../assets/img/whatsapp-icon.png";
import twitchIcon from "../../../assets/img/twitch-icon.png";
import patreonIcon from "../../../assets/img/patreon-icon.png";
import spotifyIcon from "../../../assets/img/spotify-icon.png";
import amazonLiveIcon from "../../../assets/img/amazon-live-icon.png";
import amazonShopIcon from "../../../assets/img/amazon-shop-icon.png";
import appleMusicIcon from "../../../assets/img/apple-music-icon.png";
import itunesIcon from "../../../assets/img/itunes-icon.png";
import googlePlayIcon from "../../../assets/img/gogole-play-icon.png";
import mediumIcon from "../../../assets/img/medium-icon.png";
import likeToKnowItIcon from "../../../assets/img/like-to-know-icon.png";


import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as PinterestIcon } from "../../../assets/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline } from '@mdi/js';

import customSelectSocialIconsStyle from "../../../assets/jss/material-kit-pro-react/components/customSelectSocialIconsStyle.jsx";

class CustomSelectSocialIcons extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            open: this.props.defaultOpen
        };

        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    onOpen(e){
        this.setState({open: true});
        if(this.props.onOpen){
            this.props.onOpen(e);
        }
    }
    onClose(e){
        this.setState({open: false});
        if(this.props.onClose){
            this.props.onClose(e);
        }
    }
    onChange(e){
        if(this.props.inputProps.onChange){
            this.props.inputProps.onChange(e);
        }
    }
    onShowInfo(){
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    getItemIcon(type){
        const { classes } = this.props;
        let icon = "";
        switch(type){
            case "instagramUrl":
                icon = <div className={classes.icons}>
                        <img src={instagramIcon} className={classes.icon+" icon"} alt="instagram"/>
                    </div>
                break;
            case "facebookUrl":
                icon = <div className={classes.icons}>
                        <FacebookIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "pinterestUrl":
                icon = <div className={classes.icons}>
                        <PinterestIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "tiktokUrl":
                    icon = <div className={classes.icons}>
                            <img src={tiktokIcon} alt="tiktok" className={classes.icon+" icon"}/>
                        </div>
                    break;
            case "youtubeUrl":
                icon = <div className={classes.icons}>
                        <Button color="youtube" justIcon className={classes.icon+" icon"}>
                            <i className="fab fa-youtube" />
                        </Button>
                    </div>
                break;
            case "contactEmail":
                icon = <div className={classes.icons}>
                        <MailIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "twitterUrl":
                    icon = <div className={classes.icons}>
                            <Button color="twitter" justIcon className={classes.icon+" icon"}>
                                <i className="fab fa-twitter" />
                            </Button>
                        </div>
                    break;
            case "linkedinUrl":
                icon = <div className={classes.icons}>
                        <LinkedinIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "snapchatUrl":
                icon = <div className={classes.icons}>
                        <img src={snapchatIcon} alt="snapchat icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "soundcloudUrl":
                icon = <div className={classes.icons}>
                        <img src={soundcloudIcon} alt="soundcloud icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "whatsappNumber":
                icon = <div className={classes.icons}>
                        <img src={whatsappIcon} alt="whatsapp icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "twitchUrl":
                icon = <div className={classes.icons}>
                        <img src={twitchIcon} alt="twitch icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "patreonUrl":
                icon = <div className={classes.icons}>
                        <img src={patreonIcon} alt="patreon icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "spotifyUrl":
                icon = <div className={classes.icons}>
                        <img src={spotifyIcon} alt="spotify icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "amazonLiveUrl":
                icon = <div className={classes.icons}>
                        <img src={amazonLiveIcon} alt="amazon live icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "amazonShopUrl":
                icon = <div className={classes.icons}>
                        <img src={amazonShopIcon} alt="amazon shop icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "appleMusicUrl":
                icon = <div className={classes.icons}>
                        <img src={appleMusicIcon} alt="apple music icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "itunesUrl":
                icon = <div className={classes.icons}>
                        <img src={itunesIcon} alt="itunes icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "googlePlayUrl":
                icon = <div className={classes.icons}>
                        <img src={googlePlayIcon} alt="google play icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "mediumUrl":
                icon = <div className={classes.icons}>
                        <img src={mediumIcon} alt="medium icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "likeToKnowItUrl":
                icon = <div className={classes.icons}>
                        <img src={likeToKnowItIcon} alt="likeToKnowIt icon" className={classes.icon+" icon"}/>
                    </div>
                break;
            default:
                icon = <></>;
                break;
        }
        return icon;
    }
    renderItems(){
        const { classes, items, itemLabel, itemValue } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const selectedClasses = classNames({
            [" " + classes.selectMenuItemSelectedMultiple]: true,
            [classes[color]]: true
        });
        let itemsArray = [];
        items.map((item, key) => {
            if(item.status === true){
                return null;
            }
            let label = "";
            let value = "";
            if(typeof(item) === "object"){
                label = item[itemLabel];
                value = item[itemValue];
            }else{
                label = item;
                value = item;
            }
            itemsArray.push(<MenuItem
                key={key} 
                value={value}
                classes={{
                    root: classes.selectMenuItem+ " " + classes.selectMenuItemWithIcon + " " + classes[color],
                    selected: selectedClasses
                }}
            >   
                { this.getItemIcon(value) }
                {label}
            </MenuItem>);
            return null;
        });
        return itemsArray;
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            showInfoTooltip,
            showPrivateIcon,
            selectProps
        } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const { open } = this.state;

        const dropdownItems = this.renderItems();
        if(dropdownItems.length <= 0){
            return null;
        }
        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true,
            [classes.labelRootDisabled]: inputProps.disabled ? true : false,
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames({
                [formControlProps.className]: true,
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,

            });
        } else {
            formControlClasses = classNames({
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,
            });
        }

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                        {
                            showPrivateIcon ?
                                <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            :
                            <></>
                        }
                        {
                            showInfoTooltip ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                            :
                            <></>
                        }
                    </InputLabel>
                ) : null}
                <Select {...selectProps}
                    inputProps={inputProps}
                    MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper }
                    }}
                    classes={{
                        select: classes.select
                    }}
                    open={open}
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                >
                    {dropdownItems}
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null }
            </FormControl>
        )
    }
}

CustomSelectSocialIcons.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    selectProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary",
    itemLabel: "",
    itemValue: "",
    defaultOpen: false
}

CustomSelectSocialIcons.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    // ]),
    selectProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    itemLabel: PropTypes.string,
    itemValue: PropTypes.string,
    defaultOpen: PropTypes.bool
};

export default withStyles(customSelectSocialIconsStyle)(CustomSelectSocialIcons);
