import {
    container,
    main,
    mainRaised,
} from "../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const tourStyle = theme => ({
    ...customCheckboxRadioSwitch,
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
        [theme.breakpoints.down('xs')]:{
            textAlign:"center"
        }
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    container: {
        ...container,
        padding: "0px !important"
    },
    videoTitle:{
        marginTop:"20px"
    },
    btnContact:{
         [theme.breakpoints.down('xs')]:{
            textAlign:"center"
        }
    },
    formGridItem: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    checkboxLabel:{
        '& .MuiFormControlLabel-label':{
            textDecoration:"underline"
        },
        '& .MuiFormControlLabel-root':{
            display:"none"
        }
    },
    formControlLabel:{
        color: "#3C4858",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        transition: "0.3s ease all",
        fontWeight: "400",
        lineHeight: "1.428571429",
        paddingLeft: "0",
        textDecoration:"underline",
        marginTop:"10px",
    }
});

export default tourStyle;