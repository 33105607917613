import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from '@material-ui/core/FormControl';
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customSelectStyle from "../../../../../assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const style = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    formControl: {
        marginBottom: "5px"
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    },
    selectLabelNowrap: {
        whiteSpace: "nowrap"
    },
    labelText: {
        marginBottom: theme.spacing(2),
    }
});

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        const { user } = this.store.getState();
        let userLevel = user.userLevel;

        this.state = {
            searchable: true,
            hashtags: true,
            attemptGeoTag: userLevel < 1 ? false : true,
            includeFirstCommentHashtags: userLevel < 1 ? false : true,
            retrieveAllMedia: userLevel < 1 ? false : true,
            addRecognition: userLevel < 1 ? false : true,
            importIGTV: userLevel < 1 ? false : true,
            importReels: userLevel < 1 ? false : true,
            importGuides: userLevel < 1 ? false : true,
            onlySmearchTags: false,
            robotFrequency: "0",
            robotFrequencyLabel: "Just this one time - Free!",
            // featureNewInstagramPosts: true,
            useFirstLink: true
        };

        this.hookTypes = [
            {
                type: "0",
                label: "Just this one time - Free!"
            },
            {
                type: "1",
                label: "Every Minute - $$$$"
            },
            {
                type: "60",
                label: "Every Hour - $$$"
            },
            {
                type: "1440",
                label: "Every Day - $$"
            },
            {
                type: "10080",
                label: "Every Week - $"
            }
        ]
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.props.onMount(that.state);
        },100);
    }
    sendState() {
        return this.state;
    }
    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }
    handleToggle(name) {
        let newState = {
            [name]: !this.state[name]
        };
        if(name === "hashtags" && !this.state[name] === false){
            newState['includeFirstCommentHashtags'] = false;
        }
        this.setState(newState);
    };
    change(event, stateName, type, stateNameEqualTo) {
        event.preventDefault();
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        let robotFrequencyLabel = "Just this one time - Free!";
        const hookTypeValue = event.target.value;
        this.hookTypes.map(hookType => {
            if(hookTypeValue === hookType.type){
                robotFrequencyLabel = hookType.label;                
            }
            return null;
        });
        this.setState({ [stateName]: hookTypeValue, robotFrequencyLabel: robotFrequencyLabel });
    }
    isValidated() {
        return true;
    }
    render() {
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const { searchable, hashtags, /*robotFrequency, featureNewInstagramPosts,*/ attemptGeoTag, includeFirstCommentHashtags, 
            onlySmearchTags, retrieveAllMedia, addRecognition, importIGTV, useFirstLink, importReels, importGuides } = this.state;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("searchable")}
                                    checked={searchable}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Make All Content Immediately Searchable"
                            />
                        </div>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("hashtags")}
                                    checked={hashtags}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Add all hashtags from the post caption to your search result keywords"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("useFirstLink")}
                                    checked={useFirstLink}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Automatically use the first link found as the Click-Through Url"
                            />
                        </div>
                        {/* <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("featureNewInstagramPosts")}
                                    checked={featureNewInstagramPosts}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Automatically feature all new posts"
                            />
                        </div> */}
                        <div className={classes.labelText}>
                            <label>
                                Pro Features
                            </label>
                        </div>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("includeFirstCommentHashtags")}
                                    checked={includeFirstCommentHashtags}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    // disabled={(hashtags === false)}
                                    disabled={(user.userLevel < 1 ? true : false)}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Attempt to add hashtags from the first comment to your search result keywords"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("addRecognition")}
                                    checked={addRecognition}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    disabled={(user.userLevel < 1 ? true : false)}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Attempt to add recognized media to your search result keywords"
                            />
                        </div>
                        <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("attemptGeoTag")}
                                    checked={attemptGeoTag}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    disabled={(user.userLevel < 1 ? true : false)}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label="Attempt to Geo-Tag the posts"
                            />
                        </div>
                        {
                            showBetaFeatures ?
                                <>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggle("retrieveAllMedia")}
                                                checked={retrieveAllMedia}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={(user.userLevel < 1 ? true : false)}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to retrieve all post media"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggle("importGuides")}
                                                checked={importGuides}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={(user.userLevel < 1 ? true : false)}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to Import Guides"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggle("importReels")}
                                                checked={importReels}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={(user.userLevel < 1 ? true : false)}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to Import Reels"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggle("importIGTV")}
                                                checked={importIGTV}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={(user.userLevel < 1 ? true : false)}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to import IGTV"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggle("onlySmearchTags")}
                                                checked={onlySmearchTags}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Only upload posts with the #smearch or #searchme hashtags"
                                        />
                                    </div>
                                </>
                            :
                                <></>
                        }
                        
                        {/* <FormControl className={classes.selectFormControl} fullWidth>
                            <InputLabel
                                htmlFor="outlined-instagramrobot"
                                className={classes.selectLabel+" "+classes.selectLabelNowrap}
                            >
                                How Often Should this Robot look for new posts?
                            </InputLabel>
                            <Select
                                id="outlined-instagramrobot"
                                onChange={(e) => this.change(e, 'robotFrequency')}
                                value={robotFrequency}
                                MenuProps={{
                                    className: classes.selectMenu,
                                    classes: { paper: classes.selectPaper }
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                inputProps={{
                                    name: "instagramrobot",
                                }}
                            >
                                {this.hookTypes.map((hookType, key) => (
                                    <MenuItem
                                        key={key} 
                                        value={hookType.type}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                    >
                                        {hookType.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Step2.defaultProps = {
    onMount: function(){ }
}
Step2.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step2);
