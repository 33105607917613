/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Config from "../../../../../Config";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const style = theme => ({
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...customSelectStyle,
    center: {
        textAlign: "center",
        "& .card_details > label": {
            display: "none !important"
        }
    },
    bold: {
        fontWeight: "bold"
    },
    overflowHidden: {
        overflow: "hidden"
    },
    nextButton: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    centerStep2: {
        textAlign: "center",
        "& p": {
            marginBottom: "0px"
        }
    },
    marginTopStep2: {
        marginTop: theme.spacing(2)
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.props.allStates;
    }
    componentWillReceiveProps(props){
        this.setState(props.allStates);
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        window.scrollTo(0,0);
        return true;
    }
    renderDiscount(coupon){
        if(!coupon.hasOwnProperty("percent_off") || coupon.percent_off === null){
            return null;
        }
        let discount = "";
        switch(coupon.duration){
            case "repeating":
                discount = coupon.percent_off+"% Discount for "+coupon.duration_in_months+" Months";
                break;
            case "once":
                discount = coupon.percent_off+"% Discount this month";
                break;
            default:
                discount = coupon.percent_off+"% Discount Forever";
                break;
        }
        return discount;
    }
    render() {
        const { classes } = this.props;
        let userPackage = null;
        let account = null;
        if(this.state.hasOwnProperty("account")){
            account = this.state.account;
            userPackage = account.userPackage;
        }
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>Almost There… Please Review</h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={6}>
                    {
                        this.state.hasOwnProperty("profile") ?
                            <>                                
                                <CustomInput
                                    labelText="First Name"
                                    id="info-firstname"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: this.state.profile.firstname,
                                        disabled: true,
                                    }}
                                />
                                {
                                    this.state.profile.lastname && this.state.profile.lastname.trim().length > 0 ?
                                        <CustomInput
                                            labelText="Last Name"
                                            id="info-lastname"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.profile.lastname,
                                                disabled: true,
                                            }}
                                        />
                                    :
                                        <></>
                                }
                                <CustomInput
                                    labelText="Your Unique Search Engine Link"
                                    id="info-username"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: Config.getFrontEndUrl()+"/"+this.state.profile.username,
                                        disabled: true,
                                    }}
                                />
                            </>
                        :
                        <></>
                    }
                    {
                        this.state.hasOwnProperty("about") ?
                            <div classes="fs-block">                                
                                <CustomInput
                                    labelText="Birthdate"
                                    id="info-birthdate"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: this.state.about.birthdate,
                                        disabled: true,
                                    }}
                                    showPrivateIcon={true}
                                />
                                {
                                    this.state.about.pronouns && this.state.about.pronouns.trim().length > 0 ?
                                        <CustomInput
                                            labelText="Pronouns"
                                            id="info-pronouns"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.about.pronouns,
                                                disabled: true,
                                            }}
                                            showPrivateIcon={true}
                                        />
                                    :
                                    <></>
                                }
                                <CustomInput
                                    labelText="Country"
                                    id="info-country"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: this.state.about.countryLabel,
                                        disabled: true,
                                    }}
                                    showPrivateIcon={true}
                                />
                                {
                                    this.state.about.stateProvince.length > 0 ?
                                        <CustomInput
                                            labelText="State/Province"
                                            id="info-state-province"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.about.stateProvinceLabel,
                                                disabled: true,
                                            }}
                                            showPrivateIcon={true}
                                        />
                                    :
                                    <></>
                                }
                                {
                                    this.state.about.city && this.state.about.city.trim().length > 0 ?
                                        <CustomInput
                                            labelText="City"
                                            id="info-city"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.about.city,
                                                disabled: true,
                                            }}
                                            showPrivateIcon={true}
                                        />
                                    :
                                    <></>
                                }
                                {
                                    this.state.about.postalCode && this.state.about.postalCode.trim().length > 0 ?
                                        <CustomInput
                                            labelText="Postal Code"
                                            id="info-postalcode"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.about.postalCode,
                                                disabled: true,
                                            }}
                                            showPrivateIcon={true}
                                        />
                                    :
                                    <></>
                                }
                            </div>
                        :
                        <></>
                    }
                    {
                        account !== null ?
                            <>
                                <div className={classes.marginTopStep2}>
                                    <div className={classes.centerStep2}>
                                        <p>You have chosen your account to be:</p>
                                        <span className={classes.bold}>{account.userLevelLabel}</span>
                                    </div>
                                    {
                                        account.userLevel !== 0 ?
                                            <>
                                                {
                                                    account.paymentLast4Digits.length > 0 ?
                                                        <div className={"fs-block "+classes.centerStep2+" "+classes.marginTopStep2}>
                                                            <p>Your Payment Method</p>
                                                            <span className={classes.bold}>xxxx-xxxx-xxxx-{account.paymentLast4Digits}</span>
                                                        </div>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    account.coupon !== null ?
                                                        <>
                                                            <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                                                                <p>The following discount code will be applied</p>
                                                                <span className={classes.bold}>{account.discountCode}</span>
                                                            </div>
                                                            <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                                                                <p>Your discount is:</p>
                                                                <p className={classes.bold}>{this.renderDiscount(account.coupon)}</p>
                                                            </div>
                                                        </>
                                                    :
                                                    <></>
                                                }
                                                <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                                                    {
                                                        account.coupon !== null ?
                                                                account.billingFrequency.toLowerCase() === "monthly" ?
                                                                    <>
                                                                        <p>You have chosen monthly billing. After your discount code expires your recurring monthly charge will be:</p>
                                                                        <span className={classes.bold}>{userPackage.pricing.monthlyDescription}</span>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <p>You have chosen annual billing. After your discount code expires your recurring annually charge will be:</p>
                                                                        <span className={classes.bold}>{userPackage.pricing.annualDescription}</span>
                                                                    </>
                                                        :
                                                            account.billingFrequency.toLowerCase() === "monthly" ?
                                                                <>
                                                                    <p>You have chosen monthly billing.  Your recurring monthly charge will be:</p>
                                                                    <span className={classes.bold}>{userPackage.pricing.monthlyDescription}</span>
                                                                </>
                                                            :
                                                                <>
                                                                    <p>You have chosen annual billing.  Your recurring annually charge will be:</p>
                                                                    <span className={classes.bold}>{userPackage.pricing.annualDescription}</span>
                                                                </>
                                                    }
                                                </div>
                                                {
                                                    account.coupon !== null && account.coupon.percent_off === 100 ?
                                                        <p className={classes.bold+" "+classes.centerStep2+" "+classes.marginTopStep2}>Your Credit Card will not be charged until a balance is due.</p>
                                                    :
                                                    <p className={classes.bold+" "+classes.centerStep2+" "+classes.marginTopStep2}>Your Credit Card will be charged</p>
                                                }
                                            </>
                                        :
                                        <></>
                                    }
                                </div>
                            </>
                        :
                        <></>
                    }
                    </GridItem>
                </GridContainer>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step3.defaultProps = {
    onMount: function(){ }
}
Step3.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step3);
