import React from 'react';
import classNames from "classnames";
import Api from '../../../../assets/js/utils/Api';
import StripeCard from "../../../components/Stripe/Card";
import {StripeProvider, Elements} from 'react-stripe-elements';
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Accordion from "../../../components/Accordion/Accordion.jsx";
import PaymentIcon from "@material-ui/icons/Payment";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import DeletePaymentMethodModal from "../../../components/Dashboard/DeletePaymentMethodModal";
import ChangeBillingTypeModal from "../../../components/Dashboard/ChangeBillingTypeModal";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline } from '@mdi/js';
//Tooltip Modals
import AddPaymentMethodTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountPayment/AddPaymentMethodTooltipModal";
import ChangeBillingTypeTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountPayment/ChangeBillingTypeTooltipModal";

import dashboardTabAccountPaymentsStyle from "../../../../assets/jss/dashboard/account/dashboardTabAccountPaymentsStyle.js";

const DashboardTabAccountPayments = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: values,
            saving: false,
            user: user,
            paymentToken: this.props.paymentData.paymentToken,
            paymentLast4Digits: this.props.paymentData.paymentLast4Digits,
            paymentExpirationDate: this.props.paymentData.paymentExpirationDate,
            hasError: this.props.paymentData.hasError,
            stripeData: null,
            cancelToken: null,
            editCard: false,
            authorize: false,
            cardSuccess: false,
            showAuthorize: false,
            deletePaymentModal: false,
            billingType: false,
            errorMessage: "",
            existingCardLoaded: this.props.paymentData.existingCardLoaded,
            tooltips: {
                addPaymentMethod: false,
                changeBillingType: false,
            }
        };

        this.saveUserInfo = this.saveUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMethodChange = this.onMethodChange.bind(this);
        this.editCard = this.editCard.bind(this);
        this.authorize = this.authorize.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deleteMethod = this.deleteMethod.bind(this);
        this.changeBillingType = this.changeBillingType.bind(this);
        this.handleBillingTypeClick = this.handleBillingTypeClick.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.cancelEditCard = this.cancelEditCard.bind(this);
        this.fixCardInfo = this.fixCardInfo.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            paymentToken: props.paymentData.paymentToken,
            paymentLast4Digits: props.paymentData.paymentLast4Digits,
            paymentExpirationDate: props.paymentData.paymentExpirationDate,
            existingCardLoaded: props.paymentData.existingCardLoaded,
            hasError: props.paymentData.hasError,
        })
    }
    saveUserInfo(){
        this.setState({editInfo: false, savingInfo: true});
        this.updateUser();
    }
    updateUser(){
        const { user } = this.store.getState();
        Api.updateUser(user.userId, this.state.values).then((data) => {
            if(data.numberOfResponses > 0){
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0], redirect: true }});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    editCard(){
        this.setState({editCard: true});
    }
    cancelEditCard(){
        this.setState({editCard: false});
    }
    authorize(){
        this.setState({authorize: true});
    }
    onMethodChange(cardDetails){
        const source = axios.CancelToken.source();
        this.setState({
            paymentToken: cardDetails.paymentToken,
            paymentLast4Digits: cardDetails.paymentLast4Digits,
            paymentExpirationDate: cardDetails.paymentExpirationDate,
            stripeData: cardDetails.stripeData,
            hasError: cardDetails.hasError,
            requiredCard: false,
            authorize: false,
            cardSuccess: true,
            saving: true,
            cancelToken: source
        });
        this.props.onPaymentDataChanged(cardDetails);
        Api.createPaymentMethod(cardDetails, source).then((data) => {
            this.setState({
                cancelToken: null,
                saving: false,
                editCard: false
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    saving: false,
                    requiredCard: true,
                    errorMessage: err.message,
                });
            }
        });
    }
    saveCard(){
        if(this.state.paymentLast4Digits.length <= 0){
            return;
        }
        const source = axios.CancelToken.source(); 
        const cardDetails = {
            paymentToken: this.state.paymentToken,
            paymentLast4Digits: this.state.paymentLast4Digits,
            paymentExpirationDate: this.state.paymentExpirationDate,
            stripeData: this.state.stripeData
        };
        this.setState({cancelToken: source, saving: true});
        Api.createPaymentMethod(cardDetails, source).then((data) => {
            this.setState({
                cancelToken: null,
                saving: false,
                editCard: false
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelToken: null,
                    saving: false,
                    requiredCard: true,
                    errorMessage: err.message,
                });
            }
        });
    }
    removeCard(){
        this.deleteMethod();
    }
    deleteMethod(){
        const source = axios.CancelToken.source(); 
        this.setState({
            cancelToken: source,
            deletePaymentModal: false
        });
        Api.deletePaymentMethod(source).then((data) => {
            this.setState({
                paymentToken: "",
                paymentLast4Digits: "",
                paymentExpirationDate: "",
                cancelToken: null
            });
        }).catch(err => {
            
        });
        let cardDetails = {
            paymentToken: "",
            paymentLast4Digits: "",
            paymentExpirationDate: "",
            hasError: false
        }
        this.props.onPaymentDataChanged(cardDetails);

    }
    onCardChange(){
        this.setState({showAuthorize: true});
    }
    handleDeleteClick(deletePayment = true){
        this.setState({deletePaymentModal: deletePayment});
    }
    changeBillingType(){
        this.setState({billingType: false});
    }
    handleBillingTypeClick(billingType = true){
        this.setState({billingType: billingType});
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    fixCardInfo(event){
        event.preventDefault();
        this.setState({editCard: true});
    }
    render() {
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const { editCard, requiredCard, paymentLast4Digits, errorMessage, saving,
            authorize, cardSuccess, showAuthorize, deletePaymentModal, billingType, tooltips, hasError } = this.state;
        const cardElement = classNames({
            [classes.cardElement]: true,
            [classes.cardSuccess]: cardSuccess,
            [classes.cardMargin]: true
        });
        classes.cardElement = cardElement;

        return (
            <GridItem className={classes.main} xs={12} sm={8} md={8} lg={6}>
                <h4 className={classes.title}>Payment Settings</h4>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} sm={12} md={9} lg={9}>
                        <div className={editCard?"":classes.hidden}>
                            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_CLIENT_KEY}>
                                <Elements>
                                    <StripeCard classes={classes} store={this.store} authorize={authorize} renderCardOnly={true} errorMessage={errorMessage} loading={saving}
                                    success={cardSuccess} error={requiredCard} onChange={this.onMethodChange} allowGoBack={false} onCardChange={this.onCardChange} showPrivateIcon={true} />
                                </Elements>
                            </StripeProvider>
                        </div>
                        {
                            editCard === true ?
                                <>
                                    {
                                        showAuthorize === true ?
                                            <>
                                                <Button color="primary" type="submit" round onClick={this.authorize} className={classes.button}>
                                                    <PaymentIcon className={classes.icons} />Authorize
                                                </Button>
                                                {
                                                    (paymentLast4Digits && paymentLast4Digits.length > 0) ?
                                                        <Button color="primary" round onClick={() => this.cancelEditCard()} className={classes.button+" "+classes.removeButton}>Cancel</Button>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        :
                                            (paymentLast4Digits && paymentLast4Digits.length > 0) ?
                                                <Button color="primary" round onClick={() => this.cancelEditCard()}>Cancel</Button>
                                            :
                                            <></>
                                    }                                
                                </>
                            :
                                paymentLast4Digits && paymentLast4Digits.length > 0 ?
                                    <>
                                        <CustomInput
                                            id="outlined-existingUserCard"
                                            labelText={
                                                <span>
                                                    Existing Card<MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                                </span>
                                            }
                                            inputProps={{
                                                value: "xxxx xxxx xxxx "+paymentLast4Digits,
                                                disabled: true,
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.formControl+" fs-block "
                                            }}
                                        />
                                        {
                                            hasError ?
                                                <p className={classes.fixCard}>Your credit card information needs to be updated.   <Link href="/" onClick={this.fixCardInfo}>Click Here</Link> to Fix It</p>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                <Grid>
                                    <Button color="info" round onClick={() => this.editCard()}>Add Payment Method</Button>
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('addPaymentMethod', true)} />
                                        :
                                        <></>
                                    } 
                                </Grid>
                                                                                        
                        }
                        
                        {
                            (paymentLast4Digits && paymentLast4Digits.length > 0) || editCard === true ?
                                editCard === false ?
                                    <>
                                        <Button color="info" round onClick={() => this.editCard()}>Change Payment Method</Button>
                                    </>                                    
                                :
                                <></>
                                    
                            :
                            <></>
                        }
                    </GridItem>                    
                </GridContainer>
                {
                    (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                        <>
                            <h3 className={classes.title}>Historical Payments</h3>
                            <Accordion
                                active={0}
                                activeColor="rose"
                                collapses={[
                                    {
                                    title: "August 21, 2019",
                                    content: "Payment Details"
                                    },
                                    {
                                    title: "July 21, 2019",
                                    content: "Payment Details"
                                    },
                                    {
                                    title: "June 21, 2019",
                                    content: "Payment Details"
                                    },
                                ]}
                            />
                        </>
                    :
                        <></>
                }
                <Link className={classes.billingLink} href="mailto:support@smearch.com" underline="always">Billing Support?</Link>
                <ChangeBillingTypeModal open={billingType} onSuccess={this.changeBillingType} onClose={() => this.handleBillingTypeClick(false)} />
                <DeletePaymentMethodModal open={deletePaymentModal} onSuccess={this.deleteMethod} onClose={() => this.handleDeleteClick(false)} />
                {
                    tooltips.addPaymentMethod ?
                        <AddPaymentMethodTooltipModal open={tooltips.addPaymentMethod} store={this.store} onClose={() => this.onTooltip('addPaymentMethod', false)} />
                    :
                    <></>
                }
                {
                    tooltips.changeBillingType ?
                        <ChangeBillingTypeTooltipModal open={tooltips.changeBillingType} store={this.store} onClose={() => this.onTooltip('changeBillingType', false)} />
                    :
                    <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabAccountPaymentsStyle)(DashboardTabAccountPayments);
