import {
    container,
    main,
    mainRaised,
} from "../material-kit-pro-react.jsx";

const contactStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    container: {
        ...container,
        padding: "0px !important",
        overflow:"hidden"
    },
    videoTitle:{
        marginTop:"20px"
    },
    btnContact:{
         [theme.breakpoints.down('xs')]:{
            textAlign:"center"
        }
    },
    formGridItem: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    changeButton: {
        alignSelf: "flex-end",
        paddingLeft: "0px",
        "& .MuiButton-root": {
            marginBottom: "12px"
        }
    },
    submitButton: {
        display: "flex",
        justifyContent: "flex-end"
    }
});

export default contactStyle;