import {
    blackColor,
    whiteColor,
    hexToRgb,
    primaryColor,
    grayColor,
    roseColor,
    successColor,
    infoColor,
    primaryBoxShadow,
    successBoxShadow,
    infoBoxShadow,
    roseBoxShadow
} from "../../material-kit-pro-react.jsx";

import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const styleBoxStyle = theme => ({
    ...customCheckboxRadioSwitch,
    gridItem: {
        paddingLeft: "1px",
        paddingRight: "1px"
    },
    previewLabel: {
        color: blackColor,
        top: "23px",
        marginLeft: "-60px",
        position: "absolute",
        zIndex: 1,
        background: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
        transform: "rotate(-45deg)",
        height: "30px",
        width: "200px",
        textAlign: "center",
        border: "1px solid "+blackColor,
        lineHeight: "30px",
        [theme.breakpoints.down("sm")]: {
            height: "28px",
            lineHeight: "28px",
            top: "13px",
            marginLeft: "-72px",
            fontSize: "14px"
        }
    },
    styleBox: {
        position: "absolute",
        bottom: "-275px",
        zIndex: "1110",
        width: "500px",
        transition: "all 0.3s ease",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    openStyleBox: {
        bottom: "0px"
    },
    styleBoxButton: {
        margin: "0px !important",
        border: "0px !important"
    },
    styleBoxContent: {
        background: whiteColor,
        borderRadius: "0px 4px 0px 0px",
        padding: theme.spacing(1, 2),
        borderTop: "1px solid "+primaryColor[0],
        borderLeft: "1px solid "+primaryColor[0],
        borderRight: "1px solid "+primaryColor[0],
        marginTop: "-1px",
        [theme.breakpoints.down("sm")]: {
            borderRadius: "0px",
            borderLeft: "0px",
            borderRight: "0px",
        }
    },
    rangerSlider: {
        textAlign: "center",
        "& .slidecontainer": {
            maxWidth: "400px",
            width: "100%",
            margin: theme.spacing(0, "auto", 0)
        },
        "& .slider": {
            "-webkit-appearance": "none",
            width: "100%",
            height: "10px",
            borderRadius: "5px",
            background: "#d3d3d3",
            outline: "none",
            opacity: "1",
            "-webkit-transition": ".2s",
            transition: "opacity .2s",
            "&::-webkit-slider-thumb": {
                "-webkit-appearance": "none",
                appearance: "none",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            },
            "&::-moz-range-thumb": {
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            }
        },
    },
    customLabel: {
        margin: theme.spacing(1, 1, 2, 0),
    },
    fontSize: {
        listStyle: "none",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "center",
        marginBottom: "0px",
        marginTop: theme.spacing(2),
        "& li": {
            display: "inline-block",
            verticalAlign: "middle",
            margin: theme.spacing(0, 1),
            cursor: "pointer",
            padding: "6px",
            "& span": {
                display: "block",
            },
            "&.large":{
                "& span": {
                    width: "30px",
                    height: "30px",
                    lineHeight: "30px",
                    fontSize: "23px",
                }
            },
            "&.small":{
                "& span": {
                    width: "18px",
                    height: "18px",
                    lineHeight: "18px",
                    fontSize: "16px",
                }
            },
            "&.active": {
                border: "2px solid "+blackColor,
                padding: "4px"
            }
        }
    },
    guestViewOptions: {
        width: "auto",
        textAlign: "center",
        maxWidth: "220px",
        boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& button:hover": {
            backgroundColor: "transparent",
            color: grayColor[8],
        },
        // "& button:focus": {
        //     backgroundColor: "rgba(0, 0, 0, 0.14)",
        //     color: grayColor[8],
        // },
        float: "none",
        display: "inline-block"
    },
    activeViewOption: {
        backgroundColor: grayColor[11]+" !important",
        color: grayColor[8]+" !important",
    },
    grid: {
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    viewTypeLabel: {
        fontWeight: "bold",
        fontSize: "14px"
    },
    showButtonOption: {
        width: "75px",
        border: "1px dashed "+blackColor,
        padding: "3px",
        textAlign: "center",
        marginBottom: theme.spacing(2),
        display: "inline-block"
    },
    buttons: {
        margin: "0px"
    },
    layout: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    gridContainer: {
        display: "flex",
        "& .MuiGrid-item + .MuiGrid-item": {
            paddingLeft: "0px"
        },
        "& .MuiFormControlLabel-root": {
            marginRight: "0px"
        }
    },
    primaryColor: {
        paddingLeft: "0px",
        paddingRight: "0px",
        marginTop: theme.spacing(1),
        "& li": {
            "& span": {
                width: "25px",
                height: "25px",
                display: "block",
                background: primaryColor[0],
                ...primaryBoxShadow,
                [theme.breakpoints.down("sm")]: {
                    width: "20px",
                    height: "20px",
                },
            },            
            display: "inline-block",
            marginRight: "5px",
            cursor: "pointer",
            border: "2px solid transparent",
            padding: "2px",
            [theme.breakpoints.down("sm")]: {
                marginRight: "5px",
            },
            [theme.breakpoints.down("xs")]: {
                marginRight: "0px",
            },
            "&.active": {
                border: "2px solid "+blackColor,
            }
        }
    },
    colorPicker: {
        position: "relative",
        "& img": {
            width: "25px",
            height: "25px",
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            lineHeight: "25px",
            margin: "auto",
        },
        "& .color-picker-input": {
            position: "absolute",
            bottom: "5px",
            zIndex: "-1",
            visibility: "hidden",
        }
    },
    grayColor: {
        "& span": {
            background: grayColor[26]+" !important",
            boxShadow:
                "0 4px 20px 0px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.14), 0 7px 10px -5px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.4)"
        }
    },
    infoColor: {
        "& span": {
            background: infoColor[0]+" !important",
            ...infoBoxShadow
        }
    },
    roseColor: {
        "& span": {
            background: roseColor[0]+" !important",
            ...roseBoxShadow,
        }
    },
    successColor: {
        "& span": {
            background: successColor[0]+" !important",
            ...successBoxShadow
        }
    },
    customInfo: {
        "& span": {
            background: infoColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.2)"
        }
    },
    customRose: {
        "& span": {
            background: roseColor[5]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.2)"
        }
    },
    customSuccess: {
        "& span": {
            background: successColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.2)"
        }
    },
    customSwitchBox: {
        marginBottom: "10px"
    },
    themeChangeButton: {
        marginBottom: "10px",
        marginLeft: "-5px"
    },
    switchBaseCustom: {
        padding: "0px",
        position: "static !important"
    },
    switchCheckedCustom: {
        transform: "none !important"
    },
    switchIconCustom: {

    },
    switchBarCustom: {
        display: "none"
    },
    themeLabelRoot: {
        "& .MuiSwitch-root": {
            height: "65px",
            padding: "0px",
            justifyContent: "center",
            alignItems: "center"
        }
    },
    checkedIconCustom: {
        border: "0px !important"
    },
    uncheckedIconCustom: {
        border: "0px !important"
    }
});

export default styleBoxStyle;