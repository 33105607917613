import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import Api from '../../../../assets/js/utils/Api';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../../components/Grid/GridItem';

import {
   
} from "../../../../assets/jss/material-kit-pro-react.jsx";
import analyticsTabProfileBasicStyle from "../../../../assets/jss/dashboard/analytics/analyticsTabProfileBasicStyle.js";

const AnalyticsTabBasic = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);

        let analyticsArray = this.analyticsArray()
        this.state = {
            values: values,
            analyticsArray: analyticsArray
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription
        })
    }
    analyticsArray(){
        const { user } = this.store.getState();
        let analyticsArray = [
            {
                name: "Total Profile Views",
                value: user.statLifetimeViews
            },
            {
                name: "Total Searches",
                value: user.statLifetimeSearches
            },
            {
                name: "Total Content Impressions",
                value: user.statLifetimeContentViews
            },
            {
                name: "Total Content Clicks",
                value: user.statLifetimeContentClicks
            },
        ]

        return analyticsArray;
    }
    getAnalytic(analytic, key){
        return(
            <li key={key}>
                <p className={"name"}>{analytic.name}</p>
                <p className={"value"}>{analytic.value}</p>
            </li>
        )
    }
    render() {
        const { classes } = this.props;
        const { analyticsArray } = this.state;
        // const { user, authorized } = this.store.getState();

        return (
            <GridItem className={classes.main} xs={12} sm={12} md={10} lg={8}>
                <h4 className={classes.title}>Basic Analytics</h4>
                <GridItem xs={12} sm={10} md={8} lg={6} className={classes.gridItem}>
                    <ul className={classes.basicAnalyticsLists}>
                        {
                            analyticsArray.map((analytic, key) => {
                                return this.getAnalytic(analytic, key)
                            })
                        }
                    </ul>
                </GridItem>
                <Helmet 
                    title={process.env.REACT_APP_TITLE}
                />
            </GridItem>
        )
    }
}

AnalyticsTabBasic.defaultProps = {
};

AnalyticsTabBasic.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(analyticsTabProfileBasicStyle)(AnalyticsTabBasic);
