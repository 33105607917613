/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Popover from '@material-ui/core/Popover';
import { SketchPicker } from 'react-color';
import withStyles from "@material-ui/core/styles/withStyles";

import customColorPickerStyle from "../../../assets/jss/material-kit-pro-react/components/customColorPickerStyle.jsx";

class CustomColorPicker extends React.Component {
    onChange(e){
        if(this.props.onChange){
            this.props.onChange(e);
        }
    }
    onClose(){
        if(this.props.onClose){
            this.props.onClose();
        }
    }
    render(){
        const {
            id,
            open,
            name,
            anchorOrigin,
            transformOrigin,
            color,
            anchorEl
        } = this.props;

        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => this.onClose()}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <SketchPicker color={color} className="color-picker-input" name={name} id={id} onChangeComplete={(color, e) => this.props.onChange(color.hex)} />
            </Popover>
        )
    }
}

CustomColorPicker.defaultProps = {
    onChange: function(){ },
    name: "backgroundcolor",
    id: "backgroundcolor",
    className: "color-picker-input",
    onClose: function(){ },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    color: "#FFFFFF",
    anchorEl: null
}

CustomColorPicker.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    name: PropTypes.string,
    className: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
    color: PropTypes.string,
};

export default withStyles(customColorPickerStyle)(CustomColorPicker);
