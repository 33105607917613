/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import Api from '../../../assets/js/utils/Api';
import { Link } from 'react-router-dom';
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check"; 

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline } from '@mdi/js';

import customCountryStateStyle from "../../../assets/jss/material-kit-pro-react/components/customCountryStateStyle.jsx";

class CustomCountry extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            countries: [],
            itemLabel: "label",
            itemValue: "isoCode",
            showTransferConsent: false,
            cancelToken: null
        }
        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
    }
    componentDidMount(){
        this.getCountries();
    }
    componentWillUnmount(){
        if(this.state.cancelToken){
            this.state.cancelToken.cancel('Request Cancelled');
        }
    }
    getCountries() {
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        Api.getCountries().then(data => {
            let showTransferConsent = false;
            let showStates = false;
            let countryLabel = "";
            const { selectProps } = this.props;
            if(selectProps.hasOwnProperty("value")){
                data.map((item) => {
                    if(selectProps.value === item.isoCode){
                        showTransferConsent = item.showTransferConsent;
                        showStates = item.showStates;
                        countryLabel = item.label;
                    }
                    return null;
                });
            }
            
            that.setState({
                countries: data,
                showTransferConsent: showTransferConsent
            });

            if(showStates){
                this.props.onMount(countryLabel, showTransferConsent);
            }
        }).catch(err => {

        });
    }
    onChange(e, object){
        const showTransferConsent = (object.props.consent === "false");
        const showStates = (object.props.states === "true");
        this.setState({
            showTransferConsent: (object.props.consent === "true")
        });
        const label = object.props.label;
        if(this.props.selectProps.onChange){
            this.props.selectProps.onChange(e, showTransferConsent, showStates, label);
        }
    }
    onShowInfo(){
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    renderItems(){
        const { classes } = this.props;
        const { countries, itemLabel, itemValue } = this.state;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const selectedClasses = classNames({
            [" " + classes.selectMenuItemSelectedMultiple]: true,
            [classes[color]]: true
        });
        let itemsArray = [];
        countries.map((item, key) => {
            const label = item[itemLabel];
            const value = item[itemValue];
            itemsArray.push(<MenuItem
                key={key} 
                value={value}
                consent={""+item.showTransferConsent+""}
                states={""+item.showStates+""}
                label={label}
                classes={{
                    root: classes.selectMenuItem + " " + classes[color],
                    selected: selectedClasses
                }}
            >
                {label}
            </MenuItem>);
            return null;
        });
        return itemsArray;
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            showInfoTooltip,
            showPrivateIcon,
            selectProps,
            disabled,
            consentInputProps
        } = this.props;
        const { showTransferConsent } = this.state;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames({
                [formControlProps.className]: true,
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,

            });
        } else {
            formControlClasses = classNames({
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,
            });
        }

        return (
            <>
                <FormControl {...formControlProps} className={formControlClasses}>
                    {labelText !== undefined ? (
                        <InputLabel
                            className={classes.labelRoot + " " + labelClasses}
                            htmlFor={id}
                            {...labelProps}
                        >
                            {labelText}
                            {
                                showPrivateIcon ?
                                    <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                :
                                <></>
                            }
                            {
                                showInfoTooltip ?
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                                :
                                <></>
                            }
                        </InputLabel>
                    ) : null}
                    <Select {...selectProps}
                        inputProps={inputProps}
                        MenuProps={{
                            className: classes.selectMenu,
                            classes: { paper: classes.selectPaper }
                        }}
                        onChange={(e, object) => this.onChange(e, object)}
                        classes={{
                            select: classes.select
                        }}
                    >
                        {this.renderItems()}
                    </Select>
                    {error ? (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ? (
                        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                    ) : null }
                </FormControl>
                {
                    showTransferConsent ?
                        <FormControlLabel
                            classes={{label: classes.label, root: (consentInputProps.error ? classes.consentError : false)}}
                            control={
                                <Checkbox                                                
                                    tabIndex={-1}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    onChange={(e) => this.props.onConsentChange(e.target.checked)}
                                    checked={consentInputProps.value}
                                    disabled={disabled}
                                />
                            }
                            label={
                                <span>
                                    Search My Social is operated in the United States. Click here to acknowledge and accept the{" "}
                                    <Link to="/help/euconsent" target="_blank">Transfer of Personal Data to the United States.</Link>.
                                </span>
                            }
                        />
                    :
                    <></>
                }
                
            </>
        )
    }
}

CustomCountry.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    selectProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary",
    itemLabel: "",
    itemValue: "",
    onConsentChange: function(){ },
    disabled: false,
    consentInputProps: {
        value: false,
        error: false
    },
    onMount: function(){ }
}

CustomCountry.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
    selectProps: PropTypes.object,
    onConsentChange: PropTypes.func,
    disabled: PropTypes.bool,
    onMount: PropTypes.func
};

export default withStyles(customCountryStateStyle)(CustomCountry);
