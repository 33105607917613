import React from 'react'
import {Helmet} from 'react-helmet'
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid';
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import ContactConfirmationModal from "../../components/Auth/ContactConfirmationModal";

import contactStyle from "../../../assets/jss/help/contactStyle";

const Contact = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state ={
            open: false,
            fromEmail: user.userEmail,
            subject: "",
            textBody: "",
            validation: {
                fromEmail: "",
                subject: "",
                textBody: "",
                isValid: false,
            },
            cancelToken: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.onOkClick =this.onOkClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }

    handleChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    }
    onOkClick(){
        this.resetForm(true);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, open: true});
        const requestData = {
            fromEmail: this.state.fromEmail,
            subject: this.state.subject,
            textBody: this.state.textBody,
        };
        
        Api.sendContactRequest(requestData, source).then(data => {
            //Do nothing
            this.resetForm();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.resetForm();
            }  
        });
    }
    validateForm(){
        let validation = {
            fromEmail: 'success',
            subject: 'success',
            textBody: 'success',
            isValid: true
        };

        const values = this.state;
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(values.fromEmail.length <= 0 || emailRex.test(values.fromEmail) === false){
            validation.fromEmail = "error";
            validation.isValid = false;
        }
        if(values.subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        if(values.textBody.length <= 0){
            validation.textBody = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    resetForm(hideModal = false){
        let newState = {
            subject: "",
            textBody: "",
            validation: {
                fromEmail: "",
                subject: "",
                textBody: "",
                isValid: false,
            },
        };
        if(hideModal === true){
            newState['open'] = false;
        }
        this.setState(newState);
    }
    render() {
        const { classes } = this.props;
        const { subject, textBody, open, validation } = this.state;
        
        return (
            <Grid className={classes.container+" "+classes.main}>
                <h5 className={classes.title}>Contact Us</h5>
               
                <GridItem xs={12} sm={8} md={9} lg={7} className={classes.formGridItem}>
                    <form onSubmit={this.handleSubmit}>
                        <CustomInput
                            success={validation.fromEmail === "success"}
                            error={validation.fromEmail === "error"}
                            id="fromEmail"
                            labelText="Email"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'fromEmail'),
                                value: this.state.fromEmail,
                                name: "fromEmail",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                        />
                                
                        
                        <CustomInput
                            success={validation.subject === "success"}
                            error={validation.subject === "error"}
                            id="subject"
                            labelText="Subject"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'subject'),
                                value: subject,
                                name: "subject"
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                        />
                        
                        <CustomInput
                            success={validation.textBody === "success"}
                            error={validation.textBody === "error"}
                            id="textBody"
                            labelText="Question"
                            inputProps={{
                                onChange: (e) => this.handleChange(e, 'textBody'),
                                value: textBody,
                                name: "textBody",
                                multiline: true,
                                rows: 4
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                        />
                        <div className={classes.submitButton}>
                            <Button type="submit" color="success">Contact Us</Button>
                        </div>
                        <ContactConfirmationModal onClose={this.onOkClick} open={open} stor={this.store} history={this.history}/>
                    </form>
                </GridItem>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </Grid>
        )
    }
}

export default withStyles(contactStyle)(Contact);
