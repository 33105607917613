import React from 'react'
import {Helmet} from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid';

import videosStyle from "../../../assets/jss/help/videosStyle";

const Videos = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.container+" "+classes.main}>
                <h5 className={classes.title}>Help Videos</h5>
                <div className={classes.videoContainer}>
                    <p className={classes.videoTitle}>What is Search My Social?</p>
                    <iframe width="570" height="350" title="What is Search My Social?" src="https://www.youtube.com/embed/9nr9_8GCsDI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </Grid>
        )
    }
}

export default withStyles(videosStyle)(Videos);
