/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import Api from '../../../assets/js/utils/Api';
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

// core components
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check"; 

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline, mdiLoading } from '@mdi/js';

import customCountryStateStyle from "../../../assets/jss/material-kit-pro-react/components/customCountryStateStyle.jsx";

class CustomCountryState extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            country: this.props.country,
            states: [],
            itemLabel: "label",
            itemValue: "isoCode",
            showTransferConsent: false,
            value: this.props.selectProps.value,
            loading: false,
            cancelToken: null
        }
        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
    }
    componentDidMount(){
        const { country } = this.state;
        if(country.length <= 0){
            return;
        }
        this.getStates(country);
    }
    componentWillReceiveProps(props){
        if(props.country !== this.state.country){
            this.setState({
                country: props.country,
                value: props.selectProps.value
            });
            this.getStates(props.country);
        }
    }
    componentWillUnmount(){
        if(this.state.cancelToken){
            this.state.cancelToken.cancel('Request Cancelled');
        }
    }
    getStates(country) {
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source});
        Api.getStates(country).then(data => {
            let stateProvince = this.state.value;
            let stateProvinceLabel = "";
            let states = [];
            if(data[0].showStates){
                states = data[0].states;
            }
            states.map((item) => {
                if(stateProvince === item.label || stateProvince === item.isoCode){
                    stateProvince = item.isoCode;
                    stateProvinceLabel = item.label;
                }
                return null;
            });
            
            that.setState({
                states: states,
                value: stateProvince,
                loading: false
            });

            const showStates = (states.length > 0);
            this.props.onMount(showStates, stateProvinceLabel);
        }).catch(err => {
            
        });
    }
    onChange(e, object){
        this.setState({
            value: e.target.value
        });
        const label = object.props.label;
        if(this.props.selectProps.onChange){
            this.props.selectProps.onChange(e, label);
        }
    }
    onShowInfo(){
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    renderItems(){
        const { classes } = this.props;
        const { states, itemLabel, itemValue } = this.state;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const selectedClasses = classNames({
            [" " + classes.selectMenuItemSelectedMultiple]: true,
            [classes[color]]: true
        });
        let itemsArray = [];
        states.map((item, key) => {
            const label = item[itemLabel];
            const value = item[itemValue];
            itemsArray.push(<MenuItem
                key={key} 
                value={value}
                label={label}
                classes={{
                    root: classes.selectMenuItem + " " + classes[color],
                    selected: selectedClasses
                }}
            >
                {label}
            </MenuItem>);
            return null;
        });
        return itemsArray;
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            showInfoTooltip,
            showPrivateIcon,
        } = this.props;
        const { value, states, loading } = this.state;
        let selectProps = this.props.selectProps;
        selectProps.value = value;

        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames({
                [formControlProps.className]: true,
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,

            });
        } else {
            formControlClasses = classNames({
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,
            });
        }

        if(states.length <= 0 && !loading){
            return <></>
        }

        return (
            <>
                <FormControl {...formControlProps} className={formControlClasses}>
                    {labelText !== undefined ? (
                        <InputLabel
                            className={classes.labelRoot + " " + labelClasses}
                            htmlFor={id}
                            {...labelProps}
                        >
                            {labelText}
                            {
                                showPrivateIcon ?
                                    <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                :
                                <></>
                            }
                            {
                                showInfoTooltip ?
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                                :
                                <></>
                            }
                        </InputLabel>
                    ) : null}
                    <Select {...selectProps}
                        inputProps={inputProps}
                        MenuProps={{
                            className: classes.selectMenu,
                            classes: { paper: classes.selectPaper }
                        }}
                        onChange={(e, object) => this.onChange(e, object)}
                        classes={{
                            select: classes.select
                        }}
                    >
                        {this.renderItems()}
                    </Select>
                    {loading ? (
                        <MaterialIcon path={mdiLoading} className={classes.feedback+" "+classes.animate+" MuiSvgIcon-root "} />
                    ) : error ? (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ? (
                        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                    ) : null }
                </FormControl>
            </>
        )
    }
}

CustomCountryState.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    selectProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary",
    itemLabel: "",
    itemValue: "",
    onMount: function(){ }
}

CustomCountryState.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "info",
    //     "success",
    //     "gray",
    //     "rose",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     ""
    // ]),
    selectProps: PropTypes.object,
    onMount: PropTypes.func
};

export default withStyles(customCountryStateStyle)(CustomCountryState);
