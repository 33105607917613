/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import loadImage from "blueimp-load-image";
import PropTypes from "prop-types";
import Api from '../../../../assets/js/utils/Api';
import Config from "../../../../../Config";
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import ShowTooltipModal from "../../../components/ToolTips/Onboarding/ShowTooltipModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Danger from "../../../components/Typography/Danger.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from 'react-router-dom';
import Check from "@material-ui/icons/Check";
import MaterialIcon from "@mdi/react";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import { 
    mdiInformationOutline,
} from '@mdi/js';

const style = {
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    formControl: {
        marginBottom: "5px",
    },
    termsCheckbox: {
        "&.terms-error": {
            "& svg": {
                borderColor: "red",
            },
            "& .MuiFormControlLabel-label": {
                color: "red"
            }
        }
    },
    "@media (max-width: 450px)": {
        lineBreak: {
            display: "block"
        }
    }
};

class Step1 extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        const { user, authorized } = this.store.getState();
        let photo = null;
        if(user.hasOwnProperty("avatarMedia")){
            photo = user.avatarMedia.downloadUrlProfile;
        }

        let topSearchTerms;
        if(!user.hasOwnProperty("topSearchTerms")){
            topSearchTerms = [];
        }else{
            let topSearchTermsStr = user.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                topSearchTerms = [];
            }else{
                topSearchTerms = user.topSearchTerms.split(',');
            }
        }

        let username = user.username;
        if(username === user.userId || username.trim().length <= 0){
            username = "";
        }

        this.state = {
            firstname: user.firstName,
            firstnameState: user.firstName.trim().length > 0 ? "success":"",
            lastname: user.lastName,
            lastnameState: user.lastName.trim().length > 0 ? "success":"",
            email: user.userEmail,
            emailState: authorized ? "success":"",
            bio: user.bio,
            saving: false,
            photo: photo,
            photoState: photo !== null ? "success":"",
            coverMediaId: "",
            topSearchTerms: topSearchTerms,
            tagInputValue: "",
            tooltips: {
                showToolTips: false
            },
            showTips: false,
            defaultUsername: username,
            username: username,
            usernameState: username.trim().length > 0 ? "success":"",
            cancelTokenUsername: null,
            validatingUsername: false,
            terms: false,
            termsState: ''
        };

        this.validateUsernameTimeout = null;
        this.validateUsername = this.validateUsername.bind(this);
        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handlePrefChange = this.handlePrefChange.bind(this);
    }
    componentDidMount(){
        const isValidated = this.onNextStep(true);
        this.props.onMount(this.state, isValidated);
    }
    componentWillUnmount(){
        if (this.state.cancelTokenUsername) {
            this.state.cancelTokenUsername.cancel('Request Cancelled')
        }
    }
    componentDidUpdate(prevProps, prevState){
        const state = this.state;
        const that = this;
        if(state.username !== state.defaultUsername && state.username !== prevState.username){
            if(this.validateUsernameTimeout !== null){
                clearTimeout(this.validateUsernameTimeout);
            }
            this.validateUsernameTimeout = setTimeout(function(){
                that.validateUsername();
            },1000);
        }

        if(state.firstname !== prevState.firstname || state.usernameState !== prevState.usernameState || state.coverMediaId !== prevState.coverMediaId 
            || state.terms !== prevState.terms){
            this.onNextStep();
        }
    }
    onNextStep(returnStatus = false){
        const { firstnameState, photoState, terms, usernameState } = this.state;
        if (
            firstnameState === "success" &&
            photoState === "success" &&
            terms && 
            usernameState === "success"
        ) {
            if(returnStatus){
                return true;
            }
            this.props.onIsValidChange(false);
        }else {
            if(returnStatus){
                return false;
            }
            this.props.onIsValidChange(true);
        }
    }
    sendState() {
        return this.state;
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
        return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        if(stateName === "username"){
            let usernameValue = event.target.value.replace(/@|:| |;|\/|\\/g, "");
            this.setState({ [stateName]: usernameValue });
        }else{
            this.setState({ [stateName]: event.target.value });
        }
    }
    isValidated() {
        if(this.state.saving || this.state.validatingUsername){
            return false;
        }
        if (
            this.state.firstnameState === "success" &&
            // this.state.lastnameState === "success" &&
            this.state.emailState === "success" && 
            this.state.photoState === "success" && 
            this.state.usernameState === "success" &&
            this.state.terms === true
        ) {
            window.scrollTo(0,0);
            this.updateUserOnboarding();
            return true;
        } else {
            if (this.state.firstnameState !== "success") {
                this.setState({ firstnameState: "error" });
            }
            // if (this.state.lastnameState !== "success") {
            //     this.setState({ lastnameState: "error" });
            // }
            if (this.state.emailState !== "success") {
                this.setState({ emailState: "error" });
            }
            if (this.state.photoState !== "success") {
                this.setState({ photoState: "error" });
            }
            if (this.state.usernameState !== "success") {
                this.setState({ usernameState: "error" });
            }
            if(this.state.terms !== true){
                this.setState({ termsState: "error" })
            }
        }
        return false;
    }
    handleChangePhoto(params){
        if(params.file === null){
            this.deletePhoto();
            return;
        }

        this.setState({photo: params.file});
        this.uploadPhoto(params.file);

        // const that = this;
        // loadImage(params.file, img => {
        //     img.toBlob(blob => {
        //         const file = new File([blob], params.file.name, {type: params.file.type});
        //         that.setState({photo: file});
        //         that.uploadPhoto(file);
        //     }, params.file.type);
        // }, {
        //     orientation: true
        // });
    }
    uploadPhoto(file){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true, photoState: ""});
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
            isAvatar: true
        };
        Api.createMedia(file, requestData, source).then((data) => {
            that.setState({
                cancelToken: null, 
                coverMediaId: data.mediaId,
                saving: false,
                photoState: "success"
            })
        }).catch(err => {
            that.setState({saving: false, photoState: "error"});
        });
    }
    deletePhoto(){
        if(this.state.values.coverMediaId.length <= 0){
            this.setState({photo: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, photo: null, saving: true});
        Api.deleteMedia(this.state.values.coverMediaId, source).then((data) => {
            that.setState({
                cancelToken: null, 
                values: {
                    ...that.state.values,
                    coverMediaId: ""
                },
                saving: false
            })
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    handleTags = regularTags => {
        this.setState({
            topSearchTerms: regularTags,
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            topSearchTerms: [...topSearchTerms, tagValue],
            tagInputValue: ""
        })
    }
    handlePrefChange(event, name) {
        this.setState({
            [name]: event.target.checked
        });
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    validateUsername(){
        if(this.state.cancelTokenUsername){
            this.state.cancelTokenUsername.cancel('Cancelled');
        }
        if(this.state.username.length <= 0){
            this.setState({
                usernameState: "error",
                validatingUsername: false
            });
            return;
        }
        if(this.state.username === this.state.defaultUsername){
            this.setState({
                usernameState: "success",
                validatingUsername: false
            });
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            validatingUsername: true,
            cancelTokenUsername: source
        });        
        Api.checkUsername(this.state.username, source).then((data) => {
            this.setState({
                cancelTokenUsername: null,
                validatingUsername: false,
                usernameState: "error"
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelTokenUsername: null,
                    validatingUsername: false,
                    usernameState: "success"
                });
            }
        });
    }
    updateUserOnboarding(){
        const { user } = this.store.getState();
        if(user.userPreferences.hasOwnProperty("onboarding") && user.userPreferences.onboarding.hasOwnProperty("profile") && 
            user.userPreferences.onboarding.profile){
            return;
        }
        if(!user.userPreferences.hasOwnProperty("onboarding")){
            user.userPreferences['onboarding'] = {};
        }
        user.userPreferences.onboarding['profile'] = true;
        Api.updateUser(user.userId, user).then((data) => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    render() {
        const { classes } = this.props;
        const { tooltips, showTips, terms, termsState } = this.state;
        const { user, authorized } = this.store.getState();
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>
                        Let{"'"}s start with the basic information
                    </h4>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <PictureUpload
                        onChange={this.handleChangePhoto}
                        file={this.state.photo}
                        showLoading={this.state.saving}
                        success={this.state.photoState === "success"}
                        error={this.state.photoState === "error"}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput
                        success={this.state.firstnameState === "success"}
                        error={this.state.firstnameState === "error"}
                        labelText={
                        <span>
                            First Name <small>(required)</small>
                        </span>
                        }
                        id="firstname"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => this.change(event, "firstname", "length", 3),
                            value: this.state.firstname,
                        }}
                    />
                    <CustomInput
                        // success={this.state.lastnameState === "success"}
                        // error={this.state.lastnameState === "error"}
                        labelText={
                        <span>
                            Last Name
                        </span>
                        }
                        id="lastname"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => this.change(event, "lastname", "length", 3),
                            value: this.state.lastname,
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <CustomInput
                        success={this.state.emailState === "success"}
                        error={this.state.emailState === "error"}
                        labelText="Verified Email"
                        id="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => this.change(event, "email", "email"),
                            value: this.state.email,
                            disabled: true,
                        }}
                        showPrivateIcon={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={10}>
                    <CustomInput
                        id="outlined-myusername"
                        success={this.state.usernameState === "success"}
                        error={this.state.usernameState === "error"}
                        labelText="Username"
                        loading={this.state.validatingUsername}
                        inputProps={{
                            value: this.state.username,
                            required: true,
                            onChange: event => this.change(event, "username", "length", 3),
                            name: "myusername",
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl
                        }}
                    />
                    {
                        this.state.usernameState === "error" ?
                            <Danger>This url is not available</Danger>
                        :
                        <></>
                    }
                    <p className={classes.socialLinkPreview}>
                        {Config.getFrontEndUrl()+"/"+this.state.username}
                    </p>
                </GridItem>
                {
                    (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                        <GridItem xs={12} sm={12} md={12} lg={10}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={showTips}
                                    onChange={(e) => this.handlePrefChange(e, "showTips")}
                                    value="1"
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Show Tool Tips to help explain features"
                            />
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('showToolTips', true)} />
                        </GridItem>
                    :
                        <></>
                }
                <GridItem xs={12} sm={12} md={12} lg={10} className={classes.termsCheckbox+" "+( terms === false && termsState === "error" ? "terms-error" : "")}>
                    <FormControlLabel
                        classes={{label: classes.label}}
                        control={
                            <Checkbox                                                
                                tabIndex={-1}
                                onClick={(e) => this.handleCheckbox(e, "terms")}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                checked={this.state.terms}
                            />
                        }
                        label={
                            <span>
                                I agree to the{" "}
                                <Link to="/help/terms" target="_blank">terms and conditions</Link>.
                            </span>
                        }
                    />
                </GridItem>
                {
                    tooltips.showToolTips ?
                        <ShowTooltipModal open={tooltips.showToolTips} store={this.store} onClose={() => this.onTooltip('showToolTips', false)} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

Step1.defaultProps = {
    onMount: function(){ }
}
Step1.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step1);
