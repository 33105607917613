import React from 'react';
import { helper } from '../../../../assets/js/utils/Element';
import PropTypes from "prop-types";
import Cookie from "../../../../assets/js/utils/Cookie";
import Config from '../../../../../Config';
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from '../../../components/Loader';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import InstagramWizard from "../platforms/InstagramSteps/Wizard";
import InstagramLinkWizard from "../platforms/InstagramLinkSteps/Wizard";
import InstagramLinkEditWizard from "../platforms/InstagramLinkSteps/EditWizard";
import YoutubeLinkWizard from "../platforms/YoutubeLinkSteps/Wizard";
import YoutubeLinkEditWizard from "../platforms/YoutubeLinkSteps/EditWizard";
import LinktreeWizard from "../platforms/linktreeSteps/Wizard";
import LinkinprofileWizard from "../platforms/linkinprofileSteps/Wizard";
import SearchableContentTypes from "../platforms/Searchable/ContentTypes";
import PlatformContent from "../platforms/Content";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown.jsx";
import Link from '@material-ui/core/Link';
import Button from "../../../components/CustomButtons/Button.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import ContentTourModal from "../../../components/Dashboard/ContentTourModal.jsx";
import PlateformTour from "../../../components/Dashboard/PlateformTour.jsx";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent.jsx";

import { ReactComponent as LinkedIcon } from "../../../../assets/icons/linkinprofile.svg";
import { ReactComponent as MediumIcon } from "../../../../assets/icons/medium.svg";
import { ReactComponent as LinktreeIcon } from "../../../../assets/icons/linktr.ee.svg";
import { ReactComponent as PinterestIcon } from "../../../../assets/icons/pinterest_icon.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/icons/youtube.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/help.svg";
import instagramIcon from "../../../../assets/img/instagram-icon.png";
import youtubeIcon from "../../../../assets/img/youtube-icon.png";

import MaterialIcon from "@mdi/react";
import { mdiPlusCircleOutline, mdiInformationOutline, mdiGestureTapButton, mdiLink } from '@mdi/js';
//Tooltip modals
import WebLinkTooltipModal from "../../../components/ToolTips/Dashboard/ContentPlatforms/Platforms/WebLinkTooltipModal";
import InstagramTooltipModal from "../../../components/ToolTips/Dashboard/ContentPlatforms/Platforms/InstagramTooltipModal";
import ContentPlatformsTooltipModal from "../../../components/ToolTips/Dashboard/ContentPlatforms/Platforms/ContentPlatformsTooltipModal";
import ChangeUserLevelModal from "../../../components/Dashboard/ChangeUserLevelModal";

import dashboardTabPlatformsStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabPlatformsStyle.js";
const history = createBrowserHistory();

const DashboardTabPlatforms = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        let hasPlatformPath = false;
        const pathname = window.location.pathname;
        if(pathname === "/dashboard/platformwizard/instagramlink" || pathname === "/dashboard/platformwizard/youtube"){
            hasPlatformPath = true;
        }
        let openModal = (user.userPreferences.platformTourComplete === false && user.userPreferences.showTours === true && hasPlatformPath === false);
        const plateformsState = this.getPlatformsDefaultState(user.contentPlatforms);
        this.state = {
            type: "",
            ...plateformsState,
            cancelToken: null,
            response: [],
            addContent: 0,
            platforms: user.contentPlatforms,
            openModal: openModal,
            startTour: false,
            showTours: null,
            tooltips: {
                webLink: false,
                instagram: false,
                linktree: false,
                contentPlatforms: false,
                linkinprofile: false
            },
            changeUserLevel: false,
            paymentData: this.props.paymentData,
            subscription: this.props.subscription
        };

        this.handleAddPlatforms = this.handleAddPlatforms.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onWebLinkAdded = this.onWebLinkAdded.bind(this);
        this.handleTourPopup = this.handleTourPopup.bind(this);
        this.handleTour = this.handleTour.bind(this);
        this.onTourClose = this.onTourClose.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.onPlatformEdit = this.onPlatformEdit.bind(this);
        this.fixInstagramToken = this.fixInstagramToken.bind(this);
        this.fixYoutubeToken = this.fixYoutubeToken.bind(this);
        this.onLinktreePlatformAdded = this.onLinktreePlatformAdded.bind(this);
        this.upgradeUserLevel = this.upgradeUserLevel.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
        this.onPlatformDelete = this.onPlatformDelete.bind(this);
    }
    componentDidMount(){
        const { match } = this.props;
        // this.loadUserPlatforms();
        this.updateUserPlatform(match);
        let type = null;
        if(match.params.hasOwnProperty("type")){
            type = match.params.type;
        }
        if(type !== null && (type === "instagramDemo" || type === "instagramlink" || type === "youtube")){
            this.props.onPlatformWizard();
        }

        const { linktreePlatform, instagramApiPlatform, youtubeApiPlatform } = this.state;
        if(linktreePlatform !== null){
            this.handleWebLinkPlatform(linktreePlatform);
        }
        if(instagramApiPlatform !== null){
            this.validateInstagramToken(instagramApiPlatform);
        }
        if(youtubeApiPlatform !== null){
            this.validateYoutubeToken(youtubeApiPlatform);
        }
    }
    componentWillReceiveProps(props){
        const { match } = props;
        match.params['type'] = null;
        // this.updateUserPlatform(match);

        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription,
            platforms: props.platforms
        });
        if(props.platforms.length > 0){
            //this.handleLoadPlatforms(props.platforms);
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadUserPlatforms(){
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        const requestData = {};
        Api.getPlatforms(requestData, source, user.userId).then(data => {
            this.handleLoadPlatforms(data.response);
        });
    }
    getPlatformsDefaultState(platforms){
        const { match } = this.props;
        let contentPlatformId = null;
        if(match.params.hasOwnProperty("contentPlatformId")){
            contentPlatformId = match.params.contentPlatformId;
        }
        let defaultPlatform = {
            contentPlatformId: null
        };
        let hasInstagramProfile = false;
        let hasLinktreeProfile = false;
        let hasLinkinProfile = false;
        let hasInstagramLinkProfile = false;
        let hasYoutubeApiProfile = false;
        let webLinkPlatform = null;
        let instagramPlatform = null;
        let linktreePlatform = null;
        let instagramApiPlatform = null;
        let youtubeApiPlatform = null;
        platforms.map(userPlatform => {
            if(userPlatform.type === "webLink"){
                webLinkPlatform = userPlatform;
            }
            if(userPlatform.type === "instagramDemo"){
                hasInstagramProfile = true;
                instagramPlatform = userPlatform;
            }
            if(userPlatform.type === "linktree"){
                hasLinktreeProfile = true;
                linktreePlatform = userPlatform;
            }
            if(userPlatform.type === "youtubeAPI"){
                hasYoutubeApiProfile = true;
                youtubeApiPlatform = userPlatform;
            }
            if(userPlatform.type === "linkinprofile"){
                hasLinkinProfile = true;
            }
            if(userPlatform.type === "instagramAPI"){
                hasInstagramLinkProfile = true;
                instagramApiPlatform = userPlatform;
            }
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });

        const state = {
            platforms: platforms,
            hasInstagramProfile: hasInstagramProfile,
            hasLinktreeProfile: hasLinktreeProfile,
            hasLinkinProfile: hasLinkinProfile,
            hasYoutubeApiProfile: hasYoutubeApiProfile,
            platform: defaultPlatform,
            webLinkPlatform: webLinkPlatform,
            instagramPlatform: instagramPlatform,
            hasInstagramLinkProfile: hasInstagramLinkProfile,
            linktreePlatform: linktreePlatform,
            instagramApiPlatform: instagramApiPlatform,
            youtubeApiPlatform: youtubeApiPlatform
        };
        return state;
    }
    handleLoadPlatforms(platforms){
        const state = this.getPlatformsDefaultState(platforms);
        this.setState({
            cancelToken: null,
            ...state
        });
        // if(webLinkPlatform !== null){
        //     this.handleWebLinkPlatform(webLinkPlatform);
        // }
        if(state.linktreePlatform !== null){
            this.handleWebLinkPlatform(state.linktreePlatform);
        }
        if(state.instagramApiPlatform !== null){
            this.validateInstagramToken(state.instagramApiPlatform);
        }
        if(state.youtubeApiPlatform !== null){
            this.validateYoutubeToken(state.youtubeApiPlatform);
        }
    }
    updateUserPlatform(match){
        if(match.params.hasOwnProperty("type")){
            this.setState({type: match.params.type});
        }       
    }
    handleWebLinkPlatform(userPlatform = null){
        let { webLinkPlatform } = this.state;
        if(userPlatform !== null){
            webLinkPlatform = userPlatform;
        }
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source});
        const requestData = {
            contentPlatformId: webLinkPlatform.contentPlatformId
        }
        Api.getContent(requestData, source, user.userId).then(data => {
            let webLinkHasContent = true;
            if(data.response.length <= 0){
                webLinkHasContent = false;
            }
            that.setState({
                response: data.response,
                cancelToken: null,
                webLinkHasContent: webLinkHasContent
            });
        });
    }
    handleAddPlatforms(event, platform){
        event.preventDefault();        
        if(platform === "instagramDemo" || platform === "instagramlink" || platform === "youtube" || platform === "linktree" || platform === "linkinprofile" || platform === "searchable"){
            this.setState({type: platform, platform: {contentPlatformId: null}});
            this.props.onPlatformWizard();
            history.push("/dashboard/platformwizard/"+platform);
        }
        const { webLinkPlatform } = this.state;
        if(platform === "webLink"){
            this.props.onPlatformWizard(false);
            if(webLinkPlatform !== null){
                this.setState({type: platform, platform: webLinkPlatform, addContent: 1});
                history.push("/dashboard/platform/"+webLinkPlatform.contentPlatformId+"/?new=1");
            }else{
                this.setState({type: platform, platform: {contentPlatformId: null}});
                this.createPlatform({
                    type: platform,
                });
            }            
        }
    }
    getPlatormsMenu(){
        const { classes } = this.props;
        const { hasInstagramProfile, hasLinktreeProfile, hasInstagramLinkProfile, hasLinkinProfile, hasYoutubeApiProfile } = this.state;
        const { user, authorized } = this.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        let menuItems = [];
        if(hasInstagramLinkProfile === false){
            menuItems.push(<Link href="/"  onClick={ event => this.handleAddPlatforms(event,"instagramlink")} className={classes.dropdownLink}>
                <i className="fab fa-instagram" />Link Your Instagram Account
            </Link>);
        }        
        if(hasInstagramProfile === false && showBetaFeatures){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"instagramDemo")}>
                <i className="fab fa-instagram" />Add A Demo Instagram Account
            </Link>);
        }
        if(hasYoutubeApiProfile === false){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"youtube")}>
                <YoutubeIcon className={classes.customIcon+" MuiSvgIcon-root"} />Link your YouTube channel
            </Link>);
        }
        if(hasLinktreeProfile === false){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"linktree")}>
                <LinktreeIcon className={classes.customIcon+" MuiSvgIcon-root"} />Import Your Linktr.ee
            </Link>);
        }
        if(showBetaFeatures){
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"pinterest")}>
                <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />Add a Pinterest Account - COMING SOON!
            </Link>);
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"medium")}>
                <MediumIcon className={classes.customIcon+" MuiSvgIcon-root"} />Add a Medium Profile - COMING SOON!
            </Link>);
            if(hasLinkinProfile === false){
                menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"linkinprofile")}>
                    <LinkedIcon className={classes.customIcon+" MuiSvgIcon-root"} />Import Your Link In Profile Links
                </Link>);
            }
            menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={ event => this.handleAddPlatforms(event,"searchable")}>
                <SearchIcon className={classes.customIcon+" MuiSvgIcon-root"} />Add any searchable content
            </Link>);
        }
        return menuItems;
    }
    onPlatformSelect(event, platform){
        event.preventDefault();
        const currentPlatform = this.state.platform;
        if(currentPlatform.contentPlatformId !== null && platform.type === currentPlatform.type){
            this.setState({platform: {contentPlatformId: null}, type: ""});
            history.push("/dashboard/platforms");
        }else{
            this.setState({platform: platform, type: ""});
            history.push("/dashboard/platform/"+platform.contentPlatformId);
        }
        
    }
    createPlatform(requestData){
        const that = this;
        const source = axios.CancelToken.source(); 
        this.setState({saving: true, cancelToken: source});
        const { user } = this.store.getState();
        Api.createPlatform(requestData, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                const { user } = that.store.getState();
                let userPlatform = data.response[0];
                history.push("/dashboard/platform/"+userPlatform.contentPlatformId+"/?new=1");
                that.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onWizardClose(platform = null){
        const previewPageRedirect = Cookie.read("preview_redirect");
        if(platform === null){
            this.setState({platform: {contentPlatformId: null}, type: ""});
            if(previewPageRedirect === "1"){
                Cookie.dispose("preview_redirect");
                history.push("/dashboard/editmode/search");
            }else{
                history.push("/dashboard/platforms");
            }
        }else{
            Cookie.dispose("platform_id");
            Cookie.dispose("platform_credentials");
            let state = {platform: platform, type: ""};
            switch(platform.type){
                case "youtubeAPI":
                    state['youtubeApiPlatform'] = platform;
                    if(!platform.hasOwnProperty("error") || platform.error === false){
                        this.props.onYoutubeTokenNotice(null);
                    }
                    break;
                case "instagramAPI":
                    state['instagramApiPlatform'] = platform;
                    if(!platform.hasOwnProperty("error") || platform.error === false){
                        this.props.onInstagramTokenNotice(null);
                    }
                    break;
                case "instagramDemo":
                    state['instagramPlatform'] = platform;
                    break;
                default:
                    break;
            }
            this.setState(state);
            if(previewPageRedirect === "1"){
                Cookie.dispose("preview_redirect");
                history.push("/dashboard/editmode/search");
            }else{
                history.push("/dashboard/platform/"+platform.contentPlatformId);
            }
        }
        
        this.props.onPlatformWizard(false);
    }
    onWebLinkAdded(){
        if(this.state.webLinkHasContent === false){
            this.setState({webLinkHasContent: true, addContent: 0});
        }
    }
    // componentWillMount(){
    //     const { user } = this.store.getState();
    //     if(user.userPreferences.platformTourComplete === false && user.userPreferences.showTours === true){
    //         user.userPreferences.platformTourComplete = true;
    //         user.userPreferences.platformTour = true
    //         Api.updateUser(user.userId, user);
    //     }
    // }
    handleTourPopup(showTours = null) {
        this.setState({ openModal: false });

        const { user } = this.store.getState();
        user.userPreferences.platformTourComplete = true;
        Api.updateUser(user.userId, user);        
        if(showTours !== null){
            user.userPreferences.showTours = showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
        }
        const searchParams = window.location.search.replace("?startplatformTour=1","");
        this.history.push(window.location.pathname+searchParams);
    }
    handleTour(showTours = null) {
        this.setState({
            openModal: false,
            startTour: true,
            showTours: showTours
        });
        const { user } = this.store.getState();
        user.userPreferences.platformTourComplete = true;
        Api.updateUser(user.userId, user);
        const searchParams = window.location.search.replace("?startplatformTour=1","");
        history.push(window.location.pathname+searchParams);
    }
    onTourClose(){
        if(this.state.showTours !== null){
            const { user } = this.store.getState();
            user.userPreferences.profileTourComplete = true;
            user.userPreferences.showTours = this.state.showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
            const searchParams = window.location.search.replace("?startplatformTour=1","");
            this.history.push(window.location.pathname+searchParams);
        }
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    renderPlatformTooltipIcon(userPlatform){
        const { authorized, user } = this.store.getState();
        if(!authorized || user.userPreferences.showTips !== true){
            return;
        }
        const { classes } = this.props;
        let icon = null;
        switch(userPlatform.type){
            case "instagramDemo":
                icon = <MaterialIcon path={mdiInformationOutline} className={classes.customTooltipIcon + " MuiSvgIcon-root info_tooltip_icon"} onClick={() => this.onTooltip('instagram', true)} />;
                break;
            case "webLink":
                icon = <MaterialIcon path={mdiInformationOutline} className={classes.customTooltipIcon + " MuiSvgIcon-root info_tooltip_icon"} onClick={() => this.onTooltip('webLink', true)} />;
                break;
            default:
                icon = null;
                break;
        }
        return icon;
    }
    onPlatformEdit(platform){
        Cookie.dispose("platform_credentials");
        Cookie.write("platform_id", platform.contentPlatformId);
        let state = {
            type: "instagramlink",
            platform: {
                contentPlatformId: null
            }
        };
        if(platform.type === "youtubeAPI"){
            state.type = "youtube";
        }
        this.setState(state);
        history.push("/dashboard/platformwizard/"+state.type+"/"+platform.contentPlatformId);
        this.props.onPlatformWizard();
    }
    validateInstagramToken(userPlatform){
        // if(!userPlatform.hasOwnProperty("instagramAccessToken") && userPlatform.error !== true){
        //     userPlatform.error = true;
        //     this.updatePlatform(userPlatform);
        //     return;
        // }else if(userPlatform.error !== true){
        //     var requestData = {
        //         'endpoint': 'me',
        //         'fields': 'username',
        //         'instagram_access_token': userPlatform.instagramAccessToken
        //     };
        //     const source = axios.CancelToken.source();
        //     this.setState({cancelToken: source});
        //     Api.getInstagramDetails(requestData).then((data) => {
        //         if(data.hasOwnProperty("username")){
        //             userPlatform.error = false;
        //             userPlatform.instagramHandle = data.username;
        //             this.updatePlatform(userPlatform);
        //         }else{
        //             userPlatform.error = true;
        //             this.updatePlatform(userPlatform);
        //         }
        //     }).catch(err => {
        //         userPlatform.error = true;
        //         this.updatePlatform(userPlatform);
        //     });
        // }else{
            if(userPlatform.hasOwnProperty("error") && userPlatform.error){
                this.props.onInstagramTokenNotice(
                    this.renderInstagramTokenError(userPlatform)
                );
            }
        // }
    }
    validateYoutubeToken(userPlatform){
        if(userPlatform.hasOwnProperty("error") && userPlatform.error){
            this.props.onYoutubeTokenNotice(
                this.renderYoutubeTokenError(userPlatform)
            );
        }
    }
    updatePlatform(userPlatform){
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, instagramApiPlatform: userPlatform});
        Api.updatePlatform(userPlatform, source, user.userId).then((data) => { 
            this.setState({cancelToken: null}); 
        }).catch(err => {
            console.log(err);
        });
        if(userPlatform.hasOwnProperty("error") && userPlatform.error){
            this.props.onInstagramTokenNotice(
                this.renderInstagramTokenError(userPlatform)
            );
        }
    }
    renderInstagramTokenError(userPlatform){
        return (
            <SnackbarContent
                message={
                    <p>We are having trouble accessing your Instagram account. <Link href={"/dashboard/platformwizard/instagramlink/"+userPlatform.contentPlatformId} onClick={this.fixInstagramToken}>Click Here</Link> to fix it.</p>
                }
                color="danger"
            />
        );
    }
    renderYoutubeTokenError(userPlatform){
        return (
            <SnackbarContent
                message={
                    <p>We are having trouble accessing your YouTube account. <Link href={"/dashboard/platformwizard/instagramlink/"+userPlatform.contentPlatformId} onClick={this.fixYoutubeToken}>Click Here</Link> to fix it.</p>
                }
                color="danger"
            />
        );
    }
    fixInstagramToken(event){
        event.preventDefault();
        if(this.props.onTabChange && this.props.isActiveTab !== true){
            this.props.onTabChange(3, true);
        }
        const { instagramApiPlatform } = this.state;
        history.push("/dashboard/platformwizard/instagramlink/"+instagramApiPlatform.contentPlatformId);
        Cookie.write("platform_credentials", 1);
        Cookie.write("platform_id", instagramApiPlatform.contentPlatformId);
        const authUrl = Config.getInstagramAuthUrl();
        window.location.href = authUrl;
    }
    fixYoutubeToken(event){
        event.preventDefault();
        if(this.props.onTabChange && this.props.isActiveTab !== true){
            this.props.onTabChange(3, true);
        }
        const { youtubeApiPlatform } = this.state;
        history.push("/dashboard/platformwizard/instagramlink/"+youtubeApiPlatform.contentPlatformId);
        Cookie.write("platform_credentials", 1);
        Cookie.write("platform_id", youtubeApiPlatform.contentPlatformId);
        const authUrl = Config.getGoogleAuthUrl();
        window.location.href = authUrl;
    }
    onLinktreePlatformAdded(){
        this.setState({
            platform: {contentPlatformId: null}, 
            type: "",
            webLinkHasContent: true,
            hasLinktreeProfile: true
        });
        history.push("/dashboard/platforms");
        this.props.onPlatformWizard(false);
    }
    upgradeUserLevel(e){
        e.preventDefault();
        this.changeUserLevel();
    }
    changeUserLevel(changeUserLevel = true){
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    onPlatformDelete(platform){
        const { user } = this.store.getState();
        let userPlatforms = [];
        user.contentPlatforms.map(userPlatform => {
            if(userPlatform.contentPlatformId !== platform.contentPlatformId){
                userPlatforms.push(userPlatform);
            }
            return null;
        });
        user.contentPlatforms = userPlatforms;
        this.store.dispatch({type: 'UPDATE_STATE',state: {user: user}});
        this.history.push("/dashboard/platforms");
    }
    render() {
        let { openModal, startTour, tooltips, instagramPlatform, instagramApiPlatform, changeUserLevel, paymentData, subscription,
            hasYoutubeApiProfile, youtubeApiPlatform } = this.state;
        const startplatformTour = helper.getParam("startplatformTour");
        const platformsMenu = this.getPlatormsMenu();
        const { authorized, user } = this.store.getState();
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);
        if(startplatformTour && startplatformTour.length > 0 && startplatformTour !== "null"){
            openModal = true;
        }
        if(this.props.isActiveTab !== true){
            return <></>;
        }
        
        const { classes } = this.props;
        const { type, platform, webLinkHasContent, addContent, platforms } = this.state;
        if(platforms.length <= 0){
            return <LoaderComponent align="left" />
        }
        return (
            <div className="plateform" style={{height: (startTour || openModal)?"500px":"auto"}}>
                <h4 className={"platformWizardTitle "+classes.title}>
                    Your Content Platforms
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('contentPlatforms', true)} />
                        :
                        <></>
                    }
                </h4>
                <GridContainer className={classes.platformsGridContainer+" platformWizardDropdown"}>
                    {
                        platforms.length > 0 ?
                            <GridItem className={classes.platformsGridItem+" platformIcons "+(showLabels ? classes.showLabels : "")}>
                                {
                                    platforms.map((userPlatform,key) => {
                                        if(userPlatform.type === "linktree" || userPlatform.type === "linkinprofile"){
                                            return null;
                                        }
                                        // if(userPlatform.type === "instagramDemo" && userPlatform.hasOwnProperty("bioLink")){
                                        //     return null;
                                        // }
                                        return <div key={key} className={classes.platformLink}>
                                            <Link className={(userPlatform.contentPlatformId === platform.contentPlatformId?"active ":"")} href={"/dashboard/platform/"+userPlatform.contentPlatformId} onClick={(event) => this.onPlatformSelect(event, userPlatform)} underline="none">
                                                {
                                                    (userPlatform.type === "instagramDemo" || userPlatform.type === "instagramAPI") ?
                                                        <Button color="transparent" justIcon>
                                                            <img src={instagramIcon} className="instagram-icon" alt="instagram"/>
                                                            {
                                                                showLabels ?
                                                                    userPlatform.type === "instagramDemo" ?
                                                                        "Demo"
                                                                    :
                                                                    "Instagram"
                                                                :
                                                                ""
                                                            }
                                                        </Button>
                                                    : (userPlatform.type === "webLink") ?
                                                        <Button color="transparent" justIcon>
                                                            <MaterialIcon path={mdiLink} className={classes.materialIcon+" MuiSvgIcon-root tab-add-weblink"} />
                                                            {
                                                                showLabels ?
                                                                    "Web Links"
                                                                :
                                                                ""
                                                            }
                                                        </Button>
                                                    : (userPlatform.type === "youtubeAPI") ?
                                                        <Button color="transparent" justIcon>
                                                            <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                                            {
                                                                showLabels ?
                                                                    "YouTube"
                                                                :
                                                                ""
                                                            }
                                                        </Button>
                                                    : (userPlatform.type === "button") ?
                                                        <Button color="transparent" justIcon>
                                                            <MaterialIcon path={mdiGestureTapButton} className={classes.materialIcon+" MuiSvgIcon-root tab-add-button"} />
                                                            {
                                                                showLabels ?
                                                                    "Buttons"
                                                                :
                                                                ""
                                                            }
                                                        </Button>
                                                    :
                                                    <Button color="transparent" justIcon><MaterialIcon path={mdiPlusCircleOutline} className={classes.materialIcon+" MuiSvgIcon-root"} /></Button>
                                                }
                                            </Link>
                                            {this.renderPlatformTooltipIcon(userPlatform)}
                                        </div>
                                    })
                                }
                            </GridItem>
                        :
                        <></>
                    }
                    {
                        platformsMenu.length > 0 ?
                            <GridItem className={classes.platformsGridItem+" tab-add-content"} xs={12}>
                                <CustomDropdown
                                    noLiPadding
                                    navDropdown
                                    hoverColor="primary"
                                    buttonText="Click to Import Other Platforms"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent"
                                    }}
                                    buttonIconImage={<MaterialIcon path={mdiPlusCircleOutline} className={classes.materialIcon+" MuiSvgIcon-root"} />}
                                    dropdownList={platformsMenu}
                                />
                            </GridItem>
                        :
                        <></>
                    }
                </GridContainer>
                {
                    type === "instagramlink" ?
                        this.state.hasInstagramLinkProfile === false ?
                            <InstagramLinkWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} instagramPlatform={instagramPlatform} youtubePlatform={youtubeApiPlatform} />
                        :
                            <InstagramLinkEditWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} instagramApiPlatform={instagramApiPlatform} youtubePlatform={youtubeApiPlatform} />
                    :
                    <></>
                }
                {
                    type === "youtube" ?
                        hasYoutubeApiProfile === false ?
                            <YoutubeLinkWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} youtubePlatform={youtubeApiPlatform} />
                        :
                            <YoutubeLinkEditWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} youtubePlatform={youtubeApiPlatform} />
                    :
                    <></>
                }
                {
                    type === "instagramDemo" && this.state.hasInstagramProfile === false ?
                        <InstagramWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} />
                    :
                    <></>
                }
                {
                    platform.contentPlatformId !== null && type !== "searchable" && type !== "youtube" && type !== "instagramlink" ?
                        <PlatformContent subscription={subscription} paymentData={paymentData} store={this.store} match={this.props.match} platform={platform} addContent={addContent} onWebLinkAdded={this.onWebLinkAdded} onPlatformEdit={this.onPlatformEdit} history={this.history} onPlatformDelete={this.onPlatformDelete} />
                    :
                    <></>
                }
                {
                    type === "linktree" && this.state.hasLinktreeProfile === false ?
                        <LinktreeWizard store={this.store} onPlatformAdded={this.onLinktreePlatformAdded} onWizardClose={this.onWizardClose} history={this.history} />
                    :
                    <></>
                }
                {
                    type === "linkinprofile" && this.state.hasLinkinProfile === false ?
                        <LinkinprofileWizard store={this.store} onWizardClose={this.onWizardClose} history={this.history} />
                    :
                    <></>
                }
                
                {
                    type === "searchable" ?
                        <SearchableContentTypes subscription={subscription} paymentData={paymentData} store={this.store} history={this.history} match={this.props.match} platform={this.state.webLinkPlatform} onWizardClose={this.onWizardClose} />
                    :
                    <></>
                }
                <ContentTourModal
                    open={openModal}
                    onClose={this.handleTourPopup}
                    onClick={this.handleTour}
                    store={this.store}
                />
                {(startTour === true) ? <PlateformTour run={startTour} platforms={platforms} platformsMenu={platformsMenu} onClose={this.onTourClose} webLinkHasContent={webLinkHasContent}  /> : <></>}
                {
                    tooltips.webLink ?
                        <WebLinkTooltipModal open={tooltips.webLink} store={this.store} onClose={() => this.onTooltip('webLink', false)} />
                    :
                    <></>
                }
                {
                    tooltips.instagram ?
                        <InstagramTooltipModal open={tooltips.instagram} store={this.store} onClose={() => this.onTooltip('instagram', false)} />
                    :
                    <></>
                }
                {
                    tooltips.contentPlatforms ?
                        <ContentPlatformsTooltipModal open={tooltips.contentPlatforms} store={this.store} onClose={() => this.onTooltip('contentPlatforms', false)} />
                    :
                    <></>
                }
                {
                    changeUserLevel ?
                        <ChangeUserLevelModal upgrade={true} redirectUrl="/dashboard/platforms" subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                    :
                        <></>
                }
            </div>
        )
    }
}

DashboardTabPlatforms.defaultProps = {
    onPlatformWizard: function(){ },
    onInstagramTokenNotice: function(){ },
    onYoutubeTokenNotice: function(){ },
    onTabChange: function(){ },
    subscription: null,
    paymentData: null
}
DashboardTabPlatforms.propTypes = {
    onPlatformWizard: PropTypes.func,
    onInstagramTokenNotice: PropTypes.func,
    onTabChange: PropTypes.func,
    subscription: PropTypes.object,
    paymentData: PropTypes.object.isRequired,
    onYoutubeTokenNotice: PropTypes.func
}
export default withStyles(dashboardTabPlatformsStyle)(DashboardTabPlatforms);
