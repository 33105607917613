import React from 'react';
import Api from '../../../../../assets/js/utils/Api';
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../../../../components/Loader";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NoticeUpgrade = class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            shouldWait: true
        }
        this.store = this.props.store;
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount(){
        this.loadUserPlatforms();
    }
    loadUserPlatforms(){
        const { instagramPlatform } = this.props;
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        const that = this;
        const requestData = {};
        Api.getPlatforms(requestData, source, user.userId).then(data => {
            let shouldWait = true;
            data.response.map(userPlatform => {
                if(userPlatform.contentPlatformId === instagramPlatform.contentPlatformId && userPlatform.type === "instagramAPI"){
                    shouldWait = false;
                }
                return null;
            });
            if(shouldWait){
                setTimeout(function(){
                    that.loadUserPlatforms();
                },1000)
            }else{
                this.setState({shouldWait: false});
            }
        });
    }
    onClose(){
        this.props.onClose();
        window.location.reload();
    }
    render() {
        const { classes, saving } = this.props;
        const { shouldWait } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.onClose()}
                aria-labelledby="instagramLink-modal-slide-title"
                aria-describedby="instagramLink-modal-slide-description"
            >
                <DialogTitle
                    id="instagramLink-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            shouldWait || saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>One moment while we upgrade your Instagram Experience.</h4> 
                            :
                            <></>
                        }
                </DialogTitle>
                <DialogContent
                    id="instagramLink-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            shouldWait || saving ?
                                <LoaderComponent align="center" color="success" />
                            :
                            <p className={classes.textCenter}>The process is complete.</p>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false && shouldWait === false ?
                            <Button
                                onClick={() => this.onClose()}
                                color="info"
                                round
                            >
                                OK
                            </Button>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

NoticeUpgrade.defaultProps = {
    open: false
}
NoticeUpgrade.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(instagramNoticeStyle)(NoticeUpgrade);