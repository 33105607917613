import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import ButtonBase from '@material-ui/core/ButtonBase';
import Close from "@material-ui/icons/Close";
import Button from "../../CustomButtons/Button.jsx";
import ContentDeleteModal from "../../Dashboard/Content/ContentDeleteModal";
import WebLinkEditWizard from "../../../pages/dashboard/platforms/WebLinkEditWizard";
import CoverPhoto from "./CoverPhoto.jsx";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
import ProcessingModal from "./ProcessingModal";

import buttonsViewStyle from "../../../../assets/jss/dashboard/editmode/buttonsViewStyle";
import defaultImage from "../../../../assets/img/image_placeholder.jpg";

class ButtonsView extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.state = {
            user: this.props.user,
            response: this.props.response,
            platforms: this.props.user.contentPlatforms,
            editMode: false,
            deleteMode: false,
            content: null,
            key: null,
            saving: false,
            error: false
        };

        this.deleteCatalog = this.deleteCatalog.bind(this);
        this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.cleanBodyClasses = this.cleanBodyClasses.bind(this);
    }
    componentDidMount(){
        document.addEventListener('touchend', this.cleanBodyClasses, false);
        window.addEventListener('scroll', this.cleanBodyClasses, false);
    }
    componentWillUnmount(){
        document.removeEventListener('touchend', this.cleanBodyClasses, false);
        window.removeEventListener('scroll', this.cleanBodyClasses, false);
    }
    cleanBodyClasses(){
        document.body.className = "";
    }
    componentWillReceiveProps(props){
        let state = {
            response: props.response,
        };
        if(this.state.saving === true && props.refresh === false){
            state['saving'] = false;
        }
        this.setState(state);
    }
    getCoverPhoto(content){
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlProfile;
    }
    getTitle(content){
        if(!content.hasOwnProperty("title")){
            return;
        }
        if(content.title.length <= 64){
            return content.title;
        }
        return content.title.substr(0,64).trim()+"..."
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    onDeleteModalClose(){
        this.setState({deleteMode: false});
    }
    deleteModal(content, key){
        this.setState({deleteMode: true, content: content, editMode: false, key: key});
    }
    getContentPlatform(){
        const { platforms, content } = this.state;
        let defaultPlatform = {
            contentPlatformId: null
        };
        let contentPlatformId = null;
        if(content.hasOwnProperty("contentPlatformId")){
            contentPlatformId = content.contentPlatformId;
        }
        platforms.map(userPlatform => {
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });
        this.contentPlatform = defaultPlatform;
        return this.contentPlatform;
    }
    deleteCatalog(){
        const content = this.state.content;
        const key = this.state.key;
        if(this.props.onDelete){
            this.props.onDelete(key, content);
        }
        this.setState({
            content: null,
            deleteMode: false,
            key: null,
        });
        Api.deleteContent(content.contentId);
        //this.props.onSearchResultOpen(false);
    }
    onEdit(content, key){
        this.setState({editMode: true, content: content, key: key});
    }
    onWizardClose(platform = null, newContent = null, afterCreate = false, afterEdit = false, afterDelete = false){
        if(afterDelete){
            if(this.props.onDelete){
                this.props.onDelete(this.state.key, this.state.content);
            }
            this.setState({editMode: false, content: null});
        }
        if(newContent !== null){
            this.setState({editMode: false, content: newContent});
            if(this.props.onEdit){
                this.props.onEdit(this.state.key, newContent);
            }
        }else{
            this.setState({editMode: false});
        }        
    }
    onDrop({ removedIndex, addedIndex }){
        let { response } = this.state;
        const content = response[removedIndex];
        response = arrayMove(response, removedIndex, addedIndex);
        

        let date = new Date();
        if(addedIndex > 0){
            const draggedTopContent = response[(addedIndex - 1)];
            if(draggedTopContent.hasOwnProperty("sequenceDateTime") && draggedTopContent.sequenceDateTime.length > 0){
                date = new Date(draggedTopContent.sequenceDateTime);
                date.setTime(date.getTime() - 10);
            }
        }
        content['sequenceDateTime'] = date.toISOString();
        response[addedIndex] = content;
        this.setState({response: response, saving: true});
        document.body.className = "";

        Api.updateContent(content.contentId, content).then((data) => {
            if(this.props.onDropSuccess){
                this.props.onDropSuccess(response);
            }
        }).catch(err => {
            this.setState({saving: true, error: true});
        });
    }
    render(){
        const { response, deleteMode, editMode, content, saving, error } = this.state;
        const { classes } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        const match = {
            params: {
                contentId: content  ? content.contentId : null
            }
        }
        // const { authorized } = this.store.getState();
        // const authorizedUser = this.store.getState().user;
        // var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        return (
            <GridContainer justify="center" alignItems="center" className={classes.gridContainer+" "+classes.dragContainer}>
                <Container dragHandleSelector=".custom-drag-handle" dragBeginDelay={200} dragClass={"wobble_effect"} lockAxis="y" onDrop={this.onDrop}>
                    {response.map((content, key) => (
                        <Draggable key={key}>
                            <div data-type="Content" key={key} className={classes.button+" "+classes.gridSpacing+" sd_track_view custom-drag-handle "} data-id={content.contentId}>
                                {/* {
                                    showBetaFeatures ?
                                        <div className="drag-handle">
                                            <DragHandleIcon />
                                        </div>
                                    :
                                    <></>
                                }
                                 */}
                                <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.hidePicture} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                                    <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase+(buttonStyle === "rounded" ? " rounded" : "")}>
                                        {
                                            this.isShowPicture(content) ?
                                                <CoverPhoto type="button" content={content} classes={classes} onPhotoResize={(newContent) => this.props.onPhotoResize(newContent, "button")} />
                                            :
                                            <></>
                                        }
                                        <p onClick={(e) => {e.preventDefault(); this.onEdit(content, key)}} style={buttonStyles}>{content.title}</p>
                                    </ButtonBase>
                                </GridItem>
                                <div className={classes.options}>
                                    <div className={classes.optionsRow}>
                                        <Button size="sm" justIcon color="danger" className={classes.icon} onClick={() => this.deleteModal(content, key)}>
                                            <Close />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Draggable>
                    ))}
                </Container>
                {
                    deleteMode ?
                        <ContentDeleteModal open={deleteMode} onClose={() => this.onDeleteModalClose()} onSuccess={() => this.deleteCatalog()} />
                    :
                    <></>
                }                                
                {
                    editMode ?
                        <WebLinkEditWizard match={match} open={editMode} store={this.store} content={this.state.content} onWizardClose={this.onWizardClose} platform={this.getContentPlatform()} onDelete={() => this.deleteModal()} hardrefresh={false} />
                    :
                        <></>
                }
                {
                    saving ?
                        <ProcessingModal open={saving} error={error} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

ButtonsView.defaultProps = {
    onOpen: function(){ },
    onEdit: function(){ },
    onDelete: function(){ },
    onPhotoResize: function(){ },
    onDropSuccess: function(){ },
}
ButtonsView.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onPhotoResize: PropTypes.func
};
  
export default withTheme(withStyles(buttonsViewStyle)(ButtonsView));