import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import loginUserModalStyle from "../../../assets/jss/dashboard/loginUserModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LoginUserModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.cancel()}
                aria-labelledby="userLevel-slide-title"
                aria-describedby="userLevel-slide-description"
            >
                <DialogTitle
                    id="userLevel-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle}>You are about to login as:</h4>
                </DialogTitle>
                <DialogContent
                    id="userLevel-slide-description"
                    className={classes.modalBody}
                    >
                    <p style={{textAlign: "center"}}>{this.props.username}</p>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.justifyContentCenter}>
                    <Button color="info" onClick={() => this.props.onSuccess()} round>
                        OK
                    </Button>
                    <Button onClick={() => this.props.onClose()} color="info" round className={classes.changeLevelButton}>Cancel</Button>                    
                </DialogActions>
            </Dialog>
        );
    }
};

LoginUserModal.defaultProps = {
  open: false,
  username: ""
};
LoginUserModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  username: PropTypes.string
};
export default withStyles(loginUserModalStyle)(LoginUserModal);
