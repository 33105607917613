import React from "react";
import PropTypes from "prop-types";
import LoaderComponent from '../../Loader';
// import defaultImage from "../../../../assets/img/image_placeholder.jpg";
import axios from 'axios';
import Config from '../../../../../Config';

const CoverPhoto = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            content: this.props.content,
            cancelToken: null,
        };

        this.getContent = this.getContent.bind(this);
        this.apiCallsCount = 0;
    }
    componentDidMount(){
        const { content } = this.state;
        if(!content.hasOwnProperty("mediaProcessing") || !content.mediaProcessing){
            return;
        }
        if(content.hasOwnProperty("showPicture") && !content.showPicture){
            return;
        }
        setTimeout(() => {
            if(this.apiCallsCount >= 3){
                return;
            }
            this.apiCallsCount++;
            this.getContent(content)
        }, 5000);
    }
    componentWillReceiveProps(props){
        this.setState({content: props.content});

        const { content } = props;
        if(!content.hasOwnProperty("mediaProcessing") || !content.mediaProcessing){
            return;
        }
        if(content.hasOwnProperty("showPicture") && !content.showPicture){
            return;
        }
        setTimeout(() => {
            if(this.apiCallsCount >= 3){
                return;
            }
            this.apiCallsCount++;
            this.getContent(content)
        }, 5000);
    }
    getContent(content){
        const ApiUrl = Config.getApiUrl()+"data/content/"+content.contentId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        const that = this;
        that.setState({
            cancelToken: source
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0){
                let response = res.data.response[0];
                if(this.props.onPhotoResize){
                    this.props.onPhotoResize(response);
                }else{
                    that.setState({
                        cancelToken: null, 
                        content: response
                    });
                }
            }
        }).catch(err => {
            
        });
    }
    isShowPicture(content){
        if(content.hasOwnProperty("mediaProcessing") && content.mediaProcessing){
            return false;
        }
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    isButton(content){
        if(content.hasOwnProperty("isButton") && content.isButton){
            return true;
        }
        return false;
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("showPicture") && !content.showPicture){
            return null;
        }
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return null;
        }
        let isButton = this.isButton(content);
        if(isButton){
            return content.coverMedia.downloadUrlProfile;
        }
        return content.coverMedia.downloadUrlMain;
    }
    renderGrid(){
        const { content } = this.state;
        const { classes, style } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.loaderImage+" loaderImage"}><LoaderComponent align="center" /></div>
                    :
                    coverPhoto ?
                        <img onClick={this.props.onClick} src={coverPhoto} style={style} alt={content.title} />
                    :
                        <></>
                        
                } 
            </>  
        );
    }
    renderStream(){
        const { content } = this.state;
        const { classes, style } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    coverPhoto ?
                        <img onClick={this.props.onClick} src={coverPhoto} style={style} alt={content.title} />
                    :
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.loaderImage+" loaderImage"}><LoaderComponent align="center" /></div>
                    :
                        this.isShowPicture(content) ?
                            <img onClick={this.props.onClick} src={coverPhoto} style={style} alt={content.title} />
                        :
                        <></>
                        
                } 
            </>  
        );
    }
    renderList(){
        const { content } = this.state;
        const { classes, style } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    coverPhoto ?
                        <img onClick={this.props.onClick} src={coverPhoto} className={classes.coverPhoto} style={style} alt={content.title} />
                    :
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.loaderImage+" loaderImage"}><LoaderComponent align="center" /></div>
                    :
                        this.isShowPicture(content) ?
                            <img onClick={this.props.onClick} src={coverPhoto} className={classes.coverPhoto} style={style} alt={content.title} />
                        :
                        <></>
                        
                } 
            </>  
        );
    }
    renderCard(){
        const { content } = this.state;
        const { classes, style } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    coverPhoto ?
                        <img onClick={this.props.onClick} src={coverPhoto} className={classes.coverPhoto} style={style} alt={content.title} />
                    :
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.loaderImage+" loaderImage"}><LoaderComponent align="center" /></div>
                    :
                        this.isShowPicture(content) ?
                            <img onClick={this.props.onClick} src={coverPhoto} className={classes.coverPhoto} style={style} alt={content.title} />
                        :
                        <></>
                        
                } 
            </>  
        );
    }
    renderButton(){
        const { content } = this.state;
        const { classes, style } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    coverPhoto ?
                        <img onClick={this.props.onClick} src={coverPhoto} style={style} alt={content.title} />
                    :
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.loaderImage+" loaderImage"}><LoaderComponent align="center" /></div>
                    :
                        this.isShowPicture(content) ?
                            <img onClick={this.props.onClick} src={coverPhoto} style={style} alt={content.title} />
                        :
                        <></>
                        
                } 
            </>  
        );
    }
    render() {
        const { type } = this.props;
        let content = null;
        switch(type){
            case "stream":
                content = this.renderStream();
                break;
            case "list":
                content = this.renderList();
                break;
            case "grid":
                content = this.renderGrid();
                break;
            case "card":
                content = this.renderCard();
                break;
            case "button":
                content = this.renderButton();
                break;
            default:
                break;
        }
        return content;
    }
};

CoverPhoto.defaultProps = {
    onPhotoResize: null,
    onClick: function(){ },
    type: "grid",
    style: {}
};
CoverPhoto.propTypes = {
  classes: PropTypes.object,
  onPhotoResize: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object
};
export default CoverPhoto;
