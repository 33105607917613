import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Link from "@material-ui/core/Link";
import ButtonBase from '@material-ui/core/ButtonBase';
import WebLinkEditWizard from "../../pages/dashboard/platforms/WebLinkEditWizard";
import CoverPhoto from "../Dashboard/Editmode/CoverPhoto.jsx";

import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";

import Button from "../../components/CustomButtons/Button";
import ContentTypeIcon from "../ContentTypeIcon";

import listViewStyle from "../../../assets/jss/user/listViewStyle";
import defaultImage from "../../../assets/img/image_placeholder.jpg";
import instagramIcon from "../../../assets/img/instagram-platform-icon.png";
import instagramWhiteIcon from "../../../assets/img/instagram-platform-icon-white.png";
import youtubeIcon from "../../../assets/img/youtube-platform-icon.png";
import youtubeWhiteIcon from "../../../assets/img/youtube-platform-icon-white.png";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

class ListView extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.state = {
            user: this.props.user,
            response: this.props.response,
            platforms: this.props.user.contentPlatforms,
            editMode: false,
            content: null,
            key: null,
        };

        this.onEdit = this.onEdit.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            response: props.response,
            user: props.user,
        });
    }
    getCoverPhoto(content){
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        let isButton = this.isButton(content);
        if(isButton){
            return content.coverMedia.downloadUrlProfile;
        }
        return content.coverMedia.downloadUrlMain;
    }
    getTitle(content){
        if(!content.hasOwnProperty("title")){
            return;
        }
        if(content.title.length <= 64){
            return content.title;
        }
        return content.title.substr(0,64).trim()+"..."
    }
    getLocation(content){
        if(content.location.length <= 64){
            return content.location;
        }
        return content.location.substr(0,64).trim()+"..."
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    isButton(content){
        if(content.hasOwnProperty("isButton") && content.isButton){
            return true;
        }
        return false;
    }
    getButtonStyles(){
        const { primaryColor } = this.props;
        const { fontSize, buttonStyle } = this.props.user.guestPreferences;
        const { palette } = this.props.theme;
        let buttonStyles = {
            backgroundColor: primaryColor, 
            fontSize: (fontSize === "large" ? "14px" : "12px"),
            borderRadius: (buttonStyle === "rounded" ? "30px" : "0px"),
            color: palette.custom.buttonText
        };
        return buttonStyles;
    }
    isDarkMode(){
        const { user } = this.props;
        return (user.hasOwnProperty("guestPreferences") && user.guestPreferences.theme === "dark");
    }
    getPlatformButtonStyles(){
        const { fontSize } = this.props.user.guestPreferences;
        const buttonStyles = {
            fontSize: (fontSize === "large" ? "14px" : "12px"),
        };
        return buttonStyles;
    }
    renderWebLink(){
        const { platformsData, classes, user } = this.props;
        if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
            user.guestPreferences['contentPlatforms'] = [];
        }
        const { theme, contentPlatforms } = user.guestPreferences;
        if(contentPlatforms.indexOf(platformsData.webLinkPlatform.contentPlatformId) === -1){
            return null;
        }
        const isDark = (theme === "dark");
        const buttonStyles = this.getPlatformButtonStyles();
        return (
            <GridContainer className={classes.platformView}>
                <Button color="white" onClick={() => this.props.onLinkClick(platformsData.webLinkPlatform)} className={classes.platformButton+" "+(isDark ? classes.platformButtonDark : "")} style={buttonStyles}>
                    <AddIcon />
                    Web Link
                </Button>
            </GridContainer>
        );
    }
    renderInstagramLink(){
        const { platformsData, classes, user } = this.props;
        const { theme } = user.guestPreferences;
        const isDark = (theme === "dark");
        const buttonStyles = this.getPlatformButtonStyles();
        return (
                platformsData.hasInstagramLinkProfile === false && user.userPreferences.showInstagram ?
                    <GridContainer className={classes.platformView}>
                        <CloseIcon className={classes.platformClose} onClick={() => this.props.onPlatformClose('showInstagram')} />
                        <Button color="white" onClick={() => this.props.onAddPlatform('instagram')} className={classes.platformButton+" "+classes.instagramButton+" "+(isDark ? classes.platformButtonDark : "")} style={buttonStyles}>
                            <AddIcon />
                            Add Your 
                            {
                                isDark ?
                                    <img src={instagramWhiteIcon} className="instagram-icon" alt="instagram"/>
                                :
                                <img src={instagramIcon} className="instagram-icon" alt="instagram"/>
                            }
                        </Button>
                    </GridContainer>
                :
                null
        );
    }
    renderYoutubeLink(){
        const { platformsData, classes, user } = this.props;
        const { theme } = user.guestPreferences;
        const isDark = (theme === "dark");
        const buttonStyles = this.getPlatformButtonStyles();
        return (
                platformsData.hasYoutubeApiProfile === false && user.userPreferences.showYouTube ?
                    <GridContainer className={classes.platformView}>
                        <CloseIcon className={classes.platformClose} onClick={() => this.props.onPlatformClose('showYouTube')} />
                        <Button color="white" onClick={(e) => this.props.onAddPlatform('youtube')} className={classes.platformButton+" "+classes.instagramButton+" "+(isDark ? classes.platformButtonDark : "")} style={buttonStyles}>
                            <AddIcon />
                            Add Your 
                            {
                                isDark ?
                                    <img src={youtubeWhiteIcon} className="youtube-icon" alt="youtube"/>
                                :
                                    <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                            }
                        </Button>
                    </GridContainer>
                :
                null
        );
    }
    onEdit(content, key){
        this.setState({editMode: true, content: content, key: key});
    }
    onWizardClose(platform = null, newContent = null, afterCreate = false, afterEdit = false, afterDelete = false){
        if(afterDelete){
            if(this.props.onDelete){
                this.props.onDelete(this.state.key, this.state.content);
            }
            this.setState({editMode: false, content: null});
        }
        if(newContent !== null){
            this.setState({editMode: false, content: newContent});
            if(this.props.onEdit){
                this.props.onEdit(this.state.key, newContent);
            }
        }else{
            this.setState({editMode: false});
        }        
    }
    getContentPlatform(){
        const { platforms, content } = this.state;
        let defaultPlatform = {
            contentPlatformId: null
        };
        let contentPlatformId = null;
        if(content.hasOwnProperty("contentPlatformId")){
            contentPlatformId = content.contentPlatformId;
        }
        platforms.map(userPlatform => {
            if(contentPlatformId === userPlatform.contentPlatformId){
                defaultPlatform = userPlatform;
            }
            return null;
        });
        this.contentPlatform = defaultPlatform;
        return this.contentPlatform;
    }
    render(){
        const { response, editMode, content  } = this.state;
        const { classes, showStar, user, platformLinks } = this.props;
        const buttonStyles = this.getButtonStyles();
        const { buttonStyle } = this.props.user.guestPreferences;
        const match = {
            params: {
                contentId: content  ? content.contentId : null
            }
        }
        return (
            <GridContainer className={classes.gridContainer}>
                {
                    platformLinks ?
                        this.renderWebLink()
                    :
                    null
                }
                {
                    platformLinks ?
                        this.renderInstagramLink()
                    :
                    null
                }
                {
                    platformLinks ?
                        this.renderYoutubeLink()
                    :
                    null
                }
                {response.map((content, key) => (
                    <GridContainer data-type="Content" key={content.contentId} className={(this.isButton(content) ? classes.buttonView : "")+" "+classes.listView+" "+classes.gridSpacing+" sd_track_view"} style={(this.isButton(content) ? buttonStyles : {})} data-id={content.contentId}>
                        {
                            this.isButton(content) ?
                                <ButtonBase className={(this.isShowPicture(content) ? classes.buttonIcon : "")+" "+classes.buttonBase} style={buttonStyles}>
                                    {
                                        this.isShowPicture(content) ?
                                            <CoverPhoto type="list" onClick={() => this.onEdit(content, key)} content={content} classes={classes} onPhotoResize={this.props.onPhotoResize} style={buttonStyles} />
                                        :
                                        <></>
                                    }
                                    <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.onEdit(content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                </ButtonBase>
                            :
                            <>
                                {
                                    this.isShowPicture(content) ?
                                        <>
                                            <GridItem xs={5} sm={4} md={4} lg={4} className={classes.gridItem}>
                                                <CoverPhoto type="list" content={content} classes={classes} onPhotoResize={this.props.onPhotoResize} onClick={(e) => {this.onEdit(content, key)}} />
                                                {
                                                    content.hasOwnProperty("type") && content.type !== "" ?
                                                        <ContentTypeIcon contentType={content.type} user={user} />
                                                    :
                                                        <></>
                                                }
                                                {
                                                    (content.hasOwnProperty("hasPurchaseInfo") && content.isAffiliate && content.hasPurchaseInfo && showStar) ?
                                                        <ShoppingBagIcon className={classes.cartIcon+" MuiSvgIcon-root"} />
                                                    :
                                                        <></>
                                                }
                                            </GridItem>
                                            <GridItem xs={7} sm={8} md={8} lg={8} className={classes.gridItem+" "+(this.isDarkMode() ? classes.darkTitle : "")}>
                                                <h3 className={classes.title} onClick={() => this.onEdit(content, key)}>{this.getTitle(content)}</h3>
                                                {
                                                    content.hasOwnProperty("location") && content.location.length > 0 ?
                                                        <p className={classes.location}>{this.getLocation(content)}</p>
                                                    :
                                                    <></>
                                                }
                                            </GridItem>
                                        </>
                                    :
                                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.gridItem+" "+classes.hidePicture} style={{borderRadius: (buttonStyle === "rounded" ? "30px" : "0px")}}>
                                        <ButtonBase className={classes.buttonBase}>
                                            <Link href={user.username+"/"+content.contentId} onClick={(e) => {e.preventDefault(); this.onEdit(content, key)}} underline="none" style={buttonStyles}>{content.title}</Link>
                                        </ButtonBase>
                                    </GridItem>
                                }
                            </>
                        }
                        
                                                
                    </GridContainer>
                ))}
                {
                    editMode ?
                        <WebLinkEditWizard match={match} open={editMode} store={this.store} content={this.state.content} onWizardClose={this.onWizardClose} platform={this.getContentPlatform()} onDelete={() => this.deleteModal()} hardrefresh={false} />
                    :
                        <></>
                }
            </GridContainer>
        );
    }
}

ListView.defaultProps = {
    onOpen: function(){ },
    showStar: true,
    platformsData: {

    },
    onLinkClick: function(){ },
    onAddPlatform: function(){ },
    onPlatformClose: function(){ },
    platformLinks: false,
    onPhotoResize: function(){ },
    onEdit: function(){ },
    onDelete: function(){ },
}
ListView.propTypes = {
    classes: PropTypes.object,
    onOpen: PropTypes.func,
    showStar: PropTypes.bool,
    platformsData: PropTypes.object,
    onLinkClick: PropTypes.func,
    onAddPlatform: PropTypes.func,
    onPlatformClose: PropTypes.func,
    platformLinks: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onPhotoResize: PropTypes.func
};
  
export default withTheme(withStyles(listViewStyle)(ListView));