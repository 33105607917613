import React from 'react'
import {Helmet} from 'react-helmet'
import Api from '../../../assets/js/utils/Api';
import TourModalConfirm from "../../components/Dashboard/TourModalConfirm.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from '@material-ui/core/Grid';
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
//Tooltip Modals
import TourTooltipModal from "../../components/ToolTips/Dashboard/Help/TourTooltipModal";

import tourStyle from "../../../assets/jss/help/tourStyle";

const Tour = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();

        this.state = {
            dashboardTourComplete: user.userPreferences.dashboardTourComplete,
            profileTourComplete: user.userPreferences.profileTourComplete,
            platformTourComplete: user.userPreferences.platformTourComplete,
            showTours: user.userPreferences.showTours,
            tourType: null,
            tooltips: {
                tours: false
            }
        };

        this.handlePrefChange = this.handlePrefChange.bind(this);
        this.handleClickLabel = this.handleClickLabel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
    }
    
    handlePrefChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.checked,
        });
        const { user } = this.store.getState();
        user.userPreferences.showTours = event.target.checked;
        Api.updateUser(user.userId, user);
    }
    handleClickLabel(name) {
        this.setState({
            ...this.state,
            tourType: name
        });
    }

    onConfirm() {
        const { tourType } = this.state;
        if(tourType === null){
            return;
        }

        if(tourType === "dashboardTour"){
            this.props.history.push("/dashboard?startTour=1");
            return;
        }

        if(tourType === "profileTour"){
            this.props.history.push("/dashboard/profile?startProfileTour=1");
            return;
        }

        if(tourType === "platformTour"){
            this.props.history.push("/dashboard/platforms?startplatformTour=1");
            return;
        }
    }
    handleOnClose() {
        this.setState({ tourType: null });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { showTours, tourType, tooltips } = this.state;
        const { user } = this.store.getState();
        return (
            <Grid className={classes.container+" "+classes.main}>
                <h5 className={classes.title}>Tours
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('tours', true)} />
                        :
                        <></>
                    }
                </h5>
                
                <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showTours}
                            onChange={(e) => this.handlePrefChange(e, "showTours")}
                            value="1"
                            name="showTours"
                            icon={<Check className={classes.uncheckedIcon}/>}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                        }
                        classes={{ label: classes.label, root: classes.labelRoot }}
                        label="Show guided tour the first time I use a feature"
                    />
                </div>
                <h5 className={classes.title}>Available Tours</h5>

                <div>
                    <span className={classes.formControlLabel} onClick={(e) => this.handleClickLabel("dashboardTour")}>Dashboard Tour</span>
                </div>
                {/* <div>
                    <span className={classes.formControlLabel} onClick={(e) => this.handleClickLabel("profileTour")}>Profile Tour</span>
                </div> */}
                <div>
                    <span className={classes.formControlLabel} onClick={(e) => this.handleClickLabel("platformTour")}>Content Platform Tour</span>
                </div>
                <div className={classes.main}>
                    {
                        tourType !== null?
                        <TourModalConfirm onConfirm={this.onConfirm} onClose={this.handleOnClose} open={(tourType !== null)} stor={this.store} history={this.history}/>
                        : <></>
                    }
                </div>
                {
                    tooltips.tours ?
                        <TourTooltipModal open={tooltips.tours} store={this.store} onClose={() => this.onTooltip('tours', false)} />
                    :
                    <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </Grid>
        )
    }
}

export default withStyles(tourStyle)(Tour);