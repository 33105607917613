import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import AuthApi from "../../../assets/js/utils/Auth";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Auth from '@aws-amplify/auth';
import Config from "../../../../Config";
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import Cookie from "../../../assets/js/utils/Cookie";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Warning from "../../components/Typography/Warning";
import SignupCodeModal from "../../components/Auth/SignupCodeModal";
import LockIcon from "@material-ui/icons/Lock";
import MaterialLink from "@material-ui/core/Link";
import { ReactComponent as AmazonIcon } from "../../../assets/icons/amazon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook2.svg";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

import loginPageStyle from "../../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import image from "../../../assets/img/bg7.jpg";
const history = createBrowserHistory();

const Login = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.onSignupCodeDialogClose = this.onSignupCodeDialogClose.bind(this);

        let email = helper.getParam("email");
        if(email === null || email === "null"){
            email = "";
        }

        this.state = {
            email: decodeURIComponent(email),
            password: "",
            passwordConfirm: "",
            code: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            isSuspended: false,
            signupCode: false,
            verifyEmail: false
        }
    }
    componentDidMount(){
        const that = this;
        const code = helper.getParam("code");    
        if(code && code.length > 0 && code !== "null"){
            this.setState({loading: true});
            AuthApi.validateCode(code, Config.getRedirectUri("/login")).then(data => {
                AuthApi.updateTokens(data,true);
                that.getUserInfo(true);   
            }).catch(err => {
                console.log(err);
                this.setState({loading: false});
            });

            const searchQuery = localStorage.getItem("searchmysocial_query_params");
            const queryParams = Cookie.read("query_params");
            if(searchQuery !== null){
                history.push("/signup"+searchQuery);
            }else if(queryParams !== null && queryParams !== "null"){
                history.push("/signup"+queryParams);
            }
            localStorage.removeItem("searchmysocial_query_params");
            Cookie.dispose("query_params");
        }
    }
    getUserInfo(login = false){
        const that = this;
        AuthApi.getUserInfo(function(data){
            that.handleUserInfo(data,login);
        },function(err){
            if(err.response !== 'undefined'){
                AuthApi.logout();
                that.store.dispatch({type: 'LOGOUT'});
            }else{
                that.store.dispatch({type: 'LOGOUT'});
            }
        });
    }
    handleUserInfo(data,login = false){
        const that = this;
        if(data.numberOfResponses > 0){
            data.response.map(user => {
                let actionType = 'UPDATE_STATE';
                if(login){
                    if(user.username === user.userId){
                        that.history.push('/user/onboarding'+window.location.search);
                    }else{
                        that.history.push('/dashboard/editmode/search?login=1');
                    }
                    actionType = 'LOGIN';
                }
                user = Api.prepareMemberObject(user);
                var fullName = "";
                if(user.hasOwnProperty("firstName")){
                    fullName = user.firstName;
                }
                if(user.hasOwnProperty("lastName")){
                    fullName += " "+user.lastName;
                }

                try{
                    if('FS' in window){
                        window.FS.identify(user.userId, {
                            displayName: fullName.trim(),
                            email: user.userEmail,
                            userId: user.userId
                        });
                    }
                }catch(e){  }

                that.store.dispatch({
                    type: actionType,
                    state: {
                        authorized: true,
                        user: user
                    }
                });
                
                return null;
            });
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        state[name] = value;
        this.setState(state);
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const { email, password } = this.state;
        AuthApi.signIn(email, password, this);
        this.setState({loading: true, showError: false});
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const { email } = this.state;        
        this.setState({loading: true, showError: false});
        Auth.forgotPassword(email)
        .then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        })
        .catch(err => {
            let errorMessage = "";
            if(typeof(err) === "string"){
                errorMessage = err;
            }else{
                errorMessage = err.message;
            }
            this.setState({loading: false, showError: true, errorMessage: errorMessage});
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const { email, code, password } = this.state;
        this.setState({loading: true});
        const that = this;
        Auth.forgotPasswordSubmit(email, code, password)
        .then(data => {
            AuthApi.signIn(email, password, that);
        })
        .catch(err => {
            let errorMessage = "";
            if(typeof(err) === "string"){
                errorMessage = err;
            }else{
                errorMessage = err.message;
            }
            that.setState({showError: true, loading: false, errorMessage: errorMessage});
        });

    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    onValidateSuccess(data){
        if(data.numberOfResponses > 0){
            const user = Api.prepareMemberObject(data.response[0]);
            if(user.hasOwnProperty("isSuspended") && user.isSuspended){
                AuthApi.logout();
                this.setState({
                    isSuspended: true
                });
                return;
            }
            try{
                setTimeout(function(){
                    var fullName = "";
                    if(user.hasOwnProperty("firstName")){
                        fullName = user.firstName;
                    }
                    if(user.hasOwnProperty("lastName")){
                        fullName += " "+user.lastName;
                    }
                    
                    if('FS' in window){
                        window.FS.identify(user.userId, {
                            displayName: fullName.trim(),
                            email: user.userEmail,
                            userId: user.userId
                        });
                    }
                },1000);
            }catch(e){  }

            if(user.userId === user.username){
                this.history.push(this.getOnboardingUrl());
            }
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: user,
                    notifications: null
                }
            });
        }
    }
    onValidateFail(err){
        let errorMessage = "";
        if(typeof(err) === "string"){
            errorMessage = err;
        }else{
            errorMessage = err.message;
        }
        let verifyEmail = false;
        if(typeof(err) === "object" && err.code === "UserNotConfirmedException"){
            verifyEmail = true;
        }
        this.setState({
            loading: false,
            showError: true,
            errorMessage: errorMessage,
            verifyEmail: verifyEmail
        });
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
    }
    logout(){
        this.history.push("/");
    }
    handleSignup(show = true){
        this.setState({signupCode: show});
    }
    onSignupCodeDialogClose(){
        this.setState({signupCode: false});
        this.history.push("/signup"+window.location.search);
    }
    getOnboardingUrl(){
        let url = "/user/onboarding";
        let referralData = {};
        const refType = helper.getParam("refType");
        if(refType !== null && refType !== "null" && refType.length > 0){
            referralData['refType'] = refType;
        }
        const refUId = helper.getParam("refUId");
        if(refUId !== null && refUId !== "null" && refUId.length > 0){
            referralData['refUId'] = refUId;
        }
        url += "?"+helper.serialize(referralData);
        return url;
    }
    onSocialLogin(e, url){
        e.preventDefault();
        const queryString = window.location.search;
        if(queryString.length > 1){
            localStorage.setItem("searchmysocial_query_params", queryString);
        }
        window.location.href = url;
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, showError, errorMessage, forgotPassword, requestCode, verifyEmail } = this.state;
        const { /*requireLowerletter, requireUpperletter, requireNumber,*/ requireSymbol, requireLength, isSuspended, signupCode } = this.state;
        const { authorized, user } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        if(authorized){
            const returnUrl = decodeURIComponent(helper.getParam("return"));
            if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
                return <Redirect to={returnUrl} />
            }
            if(user.userId === user.username){
                return <Redirect to={this.getOnboardingUrl()} />
            }else{
                return <Redirect to='/dashboard/editmode/search?login=1' />
            }            
        }
        if(isSuspended){
            return (
                <div
                    className={classes.pageHeader}
                    style={{
                        backgroundImage: "url(" + image + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "top center"
                    }}
                >
                    <div className={classes.container}>
                        <div className={classes.suspended}>
                            <Warning>Your account is currently suspended.</Warning>
                            <Button color="primary" round onClick={() => this.logout()}>OK</Button>
                        </div>
                    </div>
                </div>
            )
        }
        const returnUrl = "/login";
        const amazonUrl = Config.getAmazonLoginUrl(returnUrl);
        const googleUrl = Config.getGoogleLoginUrl(returnUrl);
        const facebookUrl = Config.getFacebookLoginUrl(returnUrl);

        return (
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={9} md={6} lg={5}>
                            <Card>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    {/* <CardHeader
                                    color="primary"
                                    signup
                                    className={classes.cardHeader}
                                    > */}
                                    <h5 className={classes.cardTitle + " " + classes.modalTitle}>Log in</h5>
                                    {/* <div className={classes.socialLine}>
                                        <Button
                                        justIcon
                                        color="transparent"
                                        className={classes.iconButtons}
                                        onClick={e => e.preventDefault()}
                                        >
                                        <i className="fab fa-twitter" />
                                        </Button>
                                        <Button
                                        justIcon
                                        color="transparent"
                                        className={classes.iconButtons}
                                        onClick={e => e.preventDefault()}
                                        >
                                        <i className="fab fa-facebook" />
                                        </Button>
                                        <Button
                                        justIcon
                                        color="transparent"
                                        className={classes.iconButtons}
                                        onClick={e => e.preventDefault()}
                                        >
                                        <i className="fab fa-google-plus-g" />
                                        </Button>
                                    </div>
                                    </CardHeader>
                                    <p
                                    className={classes.description + " " + classes.textCenter}
                                    >
                                    Or Be Classical
                                    </p> */}
                                    <CardBody signup>
                                        {
                                            verifyEmail ?
                                                <GridItem className={"passwordCheck-notValid-customizable "+classes.errorsSpacing}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">Your email is not verified. Please click <Link to={"/signup/verification/"+this.state.email}>here</Link> to verify it.</span>
                                                </GridItem>
                                            :
                                            showError ?
                                                <GridItem className={"passwordCheck-notValid-customizable "+classes.errorsSpacing}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                </GridItem>
                                            :
                                            <></>
                                        }
                                        {
                                            requestCode === false ?
                                                <CustomInput
                                                    id="email"
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Email",
                                                        type: "email",
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        value: this.state.email,
                                                        name: "email",
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Email className={classes.inputIconsColor} />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            :
                                            <>
                                                <Warning>
                                                    <p>We just sent you a verification code by email. Enter it below to finish resetting your password.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <CustomInput
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id="input-password"
                                                    labelText="New Password"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'password'),
                                                        name: "password",
                                                        type: "password"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id="input-passwordConfirm"
                                                    labelText="New Password Again"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                        name: "passwordConfirm",
                                                        type: "password"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </>
                                        }
                                        
                                        {
                                            forgotPassword === false ?
                                                <>
                                                <CustomInput
                                                    id="password"
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.passwordControl
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Password",
                                                        type: "password",
                                                        onChange: (e) => this.handleChange(e, 'password'),
                                                        name: "password",
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon className={classes.inputIconsColor}></LockIcon>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                />
                                                <GridItem className={classes.forgotGridItem}>
                                                    <p className={classes.anchor} onClick={this.handleForgotPassword}>Forgot?</p>
                                                    <p className={classes.anchor} onClick={() => this.onSignupCodeDialogClose()}>Signup</p>
                                                </GridItem>
                                                </>
                                            :
                                            <></>
                                        }
                                    </CardBody>
                                    <div className={classes.textCenter}>
                                        {
                                            loading ?
                                                <LoaderComponent />
                                            :
                                            forgotPassword === false ?
                                                <Button round color="primary" type="submit">Login</Button>
                                            :
                                                requestCode === true ?
                                                    <Button round color="primary" type="submit">Reset Password</Button>
                                                :
                                                    <Button round color="primary" type="submit">Email me a reset link!</Button>
                                            
                                        }                                        
                                    </div>
                                    <div className={classes.textCenter+" "+classes.socialLinks}>
                                        <MaterialLink href={amazonUrl} underline="none" onClick={(e) => this.onSocialLogin(e, amazonUrl)}>
                                            <Button color="amazon">
                                                <AmazonIcon className={"MuiSvgIcon-root amazon-icon"} />
                                                Continue with Login with Amazon
                                            </Button>
                                        </MaterialLink>
                                        <MaterialLink href={googleUrl} underline="none" onClick={(e) => this.onSocialLogin(e, googleUrl)}>
                                            <Button color="google" className={classes.googleButton}>
                                                <GoogleIcon className={"MuiSvgIcon-root google-icon"} />
                                                Continue with Google
                                            </Button>
                                        </MaterialLink>
                                        <MaterialLink href={facebookUrl} underline="none" onClick={(e) => this.onSocialLogin(e, facebookUrl)}>
                                            <Button color="facebook">
                                                <FacebookIcon className={"MuiSvgIcon-root facebook-icon"} />
                                                Continue with Facebook
                                            </Button>
                                        </MaterialLink>
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <SignupCodeModal showSignup={this.onSignupCodeDialogClose} onClose={() => this.handleSignup(false)} open={signupCode} history={this.history}/>
                </div>
            </div>
        )
    }
}

export default withStyles(loginPageStyle)(Login);