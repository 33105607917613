import React from 'react'
import { Helmet } from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"
import circlesHomePageStyle from "../../../assets/jss/circles/circlesHomePageStyle.js";

const PrivacyCenter = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container + " " + classes.main}>
                Lorem Ipsum
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(circlesHomePageStyle)(PrivacyCenter);
