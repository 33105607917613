import React from 'react';
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardTabHelpFaqs from "../../help/Faqs";
import DashboardTabHelpVideos from "../../help/Videos";
import DashboardTabHelpContact from "../../help/Contact";
import DashboardTabHelpTour from "../../help/Tour";

import NavPills from "../../../components/NavPills/NavPills.jsx";

import MaterialIcon from "@mdi/react";
import { mdiFrequentlyAskedQuestions, mdiLibraryVideo, mdiAccountQuestion, mdiDirections} from '@mdi/js';

import dashboardTabHelpStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabHelpStyle.js";
const history = createBrowserHistory();

const DashboardTabHelp = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.navPills = React.createRef();
        let subTab = this.props.params.subTab;
        if(subTab > 3){
            subTab = 0;
        }

        this.state = {
            defaultTab: subTab,
            match: this.props.match,
        };

        this.onTabChange = this.onTabChange.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/help/faqs");
                break;
            case 1:
                history.push("/dashboard/help/videos");
                break;
            case 2:
                history.push("/dashboard/help/contact");
                break;
            case 3:
                history.push("/dashboard/help/tours");
                break;
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    render() {
        const { classes, history } = this.props;
        const { defaultTab } = this.state;
        const { user, authorized } = this.store.getState();
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);

        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    horizontal={{
                        tabsGrid: { className: (showLabels ? "showLabels" : "") },
                    }}
                    alignCenter
                    color="primary"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    tabs={[
                        {
                            tabButton: (showLabels ? "FAQ" : ""),
                            tabImageIcon: <MaterialIcon path={mdiFrequentlyAskedQuestions} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabHelpFaqs store={this.store} />
                            ),
                        },
                        {
                            tabButton: (showLabels ? "Videos" : ""),
                            tabImageIcon: <MaterialIcon path={mdiLibraryVideo} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabHelpVideos store={this.store} />
                            ),
                        },
                        {
                            tabButton: (showLabels ? "Contact" : ""),
                            tabImageIcon: <MaterialIcon path={mdiAccountQuestion} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabHelpContact store={this.store}/>
                            ),
                        },
                        {
                            tabButton: (showLabels ? "Tours" : ""),
                            tabImageIcon: <MaterialIcon path={mdiDirections} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabHelpTour history={history} store={this.store} />
                            ),
                        }
                    ]}
                />
            </div>
        )
    }
}

export default withStyles(dashboardTabHelpStyle)(DashboardTabHelp);
