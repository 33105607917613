import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NoticeZeroContent = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;        
    }
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="instagramLink-modal-slide-title"
                aria-describedby="instagramLink-modal-slide-description"
            >
                <DialogTitle
                    id="instagramLink-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <h4 className={classes.modalTitle+" "+classes.textCenter}>There is not currently any Instagram content loaded to your search engine.  If you recently linked your Instagram account, check back soon!</h4> 
                </DialogTitle>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button
                        onClick={() => this.props.onClose()}
                        color="info"
                        round
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

NoticeZeroContent.defaultProps = {
    open: false
}
NoticeZeroContent.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(instagramNoticeStyle)(NoticeZeroContent);