import React from 'react';
import loadImage from "blueimp-load-image";
import Api from '../../../../assets/js/utils/Api';
import LoaderComponent from '../../../components/Loader'
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../../components/Grid/GridItem';
import ImageUpload from "../../../components/CustomUpload/ImageUpload.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import DatePicker from "../../../components/CustomInput/DatePicker.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import CustomCountry from "../../../components/CustomInput/CustomCountry";
import CustomCountryState from "../../../components/CustomInput/CustomCountryState";
import MaterialIcon from "@mdi/react";
import { mdiLockOutline, mdiInformationOutline } from '@mdi/js';
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//Tooltip modals
import ProfilePhotoTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/ProfilePhotoTooltipModal";
import CoverPhotoTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/CoverPhotoTooltipModal";
import FirstNameTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/FirstNameTooltipModal";
import LastNameTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/LastNameTooltipModal";
import BlogUrlTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/BlogUrlTooltipModal";
import BioTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/BioTooltipModal";
import SearchTermTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/SearchTermTooltipModal";
import SocialLinksTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/SocialLinksTooltipModal";
import PersonalInformationTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/PersonalInformationTooltipModal";
import DemographicsTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/DemographicsTooltipModal";

import dashboardTabProfileEditStyle from "../../../../assets/jss/dashboard/profile/dashboardTabProfileEditStyle.js";

const DashboardTabProfileEdit = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        let avatarPhoto = null;
        let avatarMediaId = null;
        if(values.hasOwnProperty("avatarMedia")){
            avatarPhoto = values.avatarMedia.downloadUrlProfile;
            avatarMediaId = values.avatarMedia.mediaId;
        }

        let coverPhoto = null;
        let coverMediaId = null;
        if(values.hasOwnProperty("coverMedia")){
            coverPhoto = values.coverMedia.downloadUrlProfile;
            coverMediaId = values.coverMedia.mediaId;
        }

        if(!values.hasOwnProperty("topSearchTerms")){
            values.topSearchTerms = [];
        }else{
            let topSearchTermsStr = values.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                values.topSearchTerms = [];
            }else{
                values.topSearchTerms = values.topSearchTerms.split(',');
            }
        }

        let birthPickerDate = values.birthdate;
        if(typeof(birthPickerDate) === "string" && birthPickerDate.trim().length > 0){
            try{
                birthPickerDate = birthPickerDate.replace(/-/g, '/');
                birthPickerDate = new Date(birthPickerDate).toString();
            }catch(e){
                console.log(e);
                birthPickerDate = "";
            }
        }

        let country = user.country ? user.country : "";
        if(country === "United States"){
            country = "US";
        }else if(country === "Canada"){
            country = "CA";
        }
        values.country = country;

        this.state = {
            values: values,
            birthPickerDate: birthPickerDate,
            avatarPhoto: avatarPhoto,
            avatarMediaId: avatarMediaId,
            coverPhoto: coverPhoto,
            coverMediaId: coverMediaId,
            saving: false,
            savingAvatarPhoto: false,
            savingCoverPhoto: false,
            user: user,
            editInfo: false,
            validation: {
                firstName: values.firstName.trim().length > 0 ? "success":"error",
                // lastName: values.lastName.trim().length > 0 ? "success":""
            },
            savingInfo: false,
            tooltips: {
                avatarPhoto: false,
                coverPhoto: false,
                firstName: false,
                lastName: false,
                blogUrl: false,
                bio: false,
                searchTerm: false,
                socialLinks: false,
                personalInformation: false,
                demographics: false
            },
            googlePlacesResponse: {
                latitude: 0.0,
                longitude: 0.0,
                location: values.city ? values.city : "",
                googlePlacesId: "",
                formatted_address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            },
            countries: [],
            states: [],
            pronounsItems: [],
            birthdateState: user.birthdate ? "success":"",
            countryState: user.country ? "success":"",
            stateProvinceState: user.stateProvince ? "success":"",
            tagInputValue: "",
            euTransferConsentState: user.euTransferConsent ? "success":"",
            showStates: false,
            stateProvinceLabel: "",
            countryLabel: "",
        };

        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.editUserInfo = this.editUserInfo.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
    }
    componentDidMount(){
        // this.countries();
        // this.states();
        this.pronounsItems();
    }
    handleChangePhoto(type, params){
        if(params.file === null){
            this.deletePhoto(type);
            return;
        }
        // if(type === "avatarPhoto"){
        //     this.setState({avatarPhoto: params.file, savingAvatarPhoto: true});
        // }else{
        //     this.setState({coverPhoto: params.file, savingCoverPhoto: true});
        // }

        const that = this;
        loadImage(params.file, img => {
            img.toBlob(blob => {
                const file = new File([blob], params.file.name, {type: params.file.type});
                if(type === "avatarPhoto"){
                    that.setState({avatarPhoto: file, savingAvatarPhoto: true});
                }else{
                    that.setState({coverPhoto: file, savingCoverPhoto: true});
                }
                that.uploadPhoto(file, type);
            }, params.file.type);
        }, {
            orientation: true
        });
    }
    uploadPhoto(file, type){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
        };
        if(type === "avatarPhoto"){
            requestData['isAvatar'] = true;
        }else{
            requestData['isCover'] = true;
        }
        Api.createMedia(file, requestData, source).then((data) => {
            let newState = {
                cancelToken: null, 
                savingAvatarPhoto: false,
                savingCoverPhoto: false
            };
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = data.mediaId;
            }else{
                newState['coverMediaId'] = data.mediaId;
            }
            that.setState(newState);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    deletePhoto(type){
        let mediaIdKey = "avatarMediaId";
        if(type === "coverPhoto"){
            mediaIdKey = "coverMediaId";
        }
        const mediaId = this.state[mediaIdKey];
        if(!mediaId || mediaId.length <= 0){
            if(type === "avatarPhoto"){
                this.setState({avatarPhoto: null});
                return;
            }
            this.setState({coverPhoto: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        if(type === "coverPhoto"){
            this.setState({cancelToken: source, coverPhoto: null, saving: true});
        }else{
            this.setState({cancelToken: source, avatarPhoto: null, saving: true});
        }
        
        Api.deleteMedia(mediaId, source).then((data) => {
            let newState = {
                cancelToken: null, 
                saving: false
            };
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = null;
            }else{
                newState['coverMediaId'] = null;
            }
            that.setState(newState);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    editUserInfo(editInfo = true){
        this.setState({editInfo: editInfo});
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
        });
        this.updateUser();
    }
    validateForm(){
        const { validation, values, showStates } = this.state;
        if (
            this.state.birthdateState === "success" &&
            this.state.countryState === "success" &&
            (this.state.stateProvinceState === "success" || !showStates) &&
            validation.firstName === "success" && 
            values.euTransferConsent
        ) {
            return true;
        }else {
            let newState = {
                validation: {
                    ...validation,
                    firstName: "success"
                }
            };
            if (this.state.birthdateState !== "success") {
                newState['birthdateState'] = "error";
            }
            if (this.state.countryState !== "success") {
                newState['countryState'] = "error";
            }
            if (this.state.stateProvinceState !== "success" && showStates) {
                newState['stateProvinceState'] = "error";
            }
            if(values.firstName.length <= 0){
                newState['validation'] = {
                    ...validation,
                    firstName: "error"
                };
            }
            if (!this.state.values.euTransferConsent) {
                newState['euTransferConsentState'] = "error";
            }else{
                newState['euTransferConsentState'] = "success";
            }
            this.setState(newState);
            return false;
        }        
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        let regularTagsStr = this.state.values.topSearchTerms.toString();
        values.topSearchTerms = regularTagsStr;

        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser, redirect: true }});
                this.history.push("/dashboard/profile");
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(event, name) {
        let state = {
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        };
        if(name === "firstName"){
            state['validation'] = {
                ...this.state.validation,
                firstName: "success"
            }
            if(!event.target.value || event.target.value.length <= 0){
                state['validation']['firstName'] = "error";
            }
        }
        this.setState(state);
    }
    onClear(event, name){
        let state = {
            values: {
                ...this.state.values, 
                [name]: ""
            }
        };
        if(name === "firstName"){
            state['validation'] = {
                ...this.state.validation,
                firstName: "error"
            }
        }
        this.setState(state);
    }
    onSelectChange(event, stateName, showTransferConsent = null, showStates = null){
        let value = event.target.value;
        if (value) {
            this.setState({ [stateName + "State"]: "success" });
        } else {
            this.setState({ [stateName + "State"]: "error" });
        }

        let euTransferConsent = this.state.values.euTransferConsent;
        if(showTransferConsent !== null){
            euTransferConsent = showTransferConsent;
        }

        let stateProvinceState = "success";
        if(showStates === null){
            showStates = this.state.showStates;
            stateProvinceState = "";
        }
        if(showStates){
            stateProvinceState = "error";
        }

        if(stateName === "country"){
            this.setState({
                values: {
                    ...this.state.values, 
                    [stateName]: value,
                    stateProvince: "",
                    euTransferConsent: euTransferConsent
                },
                stateProvinceState: stateProvinceState,
                showStates: showStates
            });
        }else{
            this.setState({
                values: {
                    ...this.state.values, 
                    [stateName]: value,
                    euTransferConsent: euTransferConsent
                },
            })
        }
    }
    onConsentChange(status){
        this.setState({
            values: {
                ...this.state.values,
                euTransferConsent: status
            },
            euTransferConsentState: (status ? "success" : "error")
        });
    }
    onCountryMount(countryLabel, showTransferConsent){
        this.setState({
            showStates: true,
            countryLabel: countryLabel,
            euTransferConsentState: (showTransferConsent ? this.state.euTransferConsentState : "success")
        });
    }
    onStateMount(showStates, stateProvinceLabel){
        this.setState({
            showStates: showStates,
            stateProvinceLabel: stateProvinceLabel
        });
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        const { values } = this.state;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(values[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "https://"+value;
            }
        }
        if(value === "http://" || value === "https://"){
            value = "";
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                [name]: value
            }
        });
    }
    handleTags = regularTags => {
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: regularTags,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.values.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: [...topSearchTerms, tagValue],
            },
            tagInputValue: ""
        })
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    handleDateChange(date, stateName) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            let birthdate = this.formatDateToString(parsedDate);

            parsedDate.toISOString();
            this.setState({
                values: {
                    ...this.state.values,
                    [stateName]: date ? birthdate : ""
                },
                [stateName + "State"]: (date? "success" : "error"),
                birthPickerDate: date ? parsedDate.toISOString() : "",
           });
        }catch(e){
            this.setState({
                [stateName + "State"]: "error"
            });
            //console.log(e); 
        }
    }
    formatDateToString(date){
        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        var yyyy = date.getFullYear();
     
        return (MM + "-" + dd + "-" + yyyy);
    }
    handleLocationChange(location){
        this.setState({
            values: {
                ...this.state.values, 
                city: location.location,
            },
            googlePlacesResponse: location
        });
    };
    clearInput(e, stateName){
        if(stateName === "city"){
            this.setState({
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                }
            })
        }
        this.setState({
            values: {
                ...this.state.values, 
                [stateName]: "",
            },
            [stateName + "Input"]: "",
            [stateName + "State"]: "error"
        });
    }
    pronounsItems() {
        const that = this;
        Api.pronouns().then(data => {
            that.setState({
                pronounsItems: data,
            });
        });
    }
    isCustomColor(primaryColor){
        let hasHash = primaryColor.startsWith("#");
        return hasHash;
    }
    render() {
        const { classes } = this.props;
        const { avatarPhoto, editInfo, values, validation, savingInfo, tooltips, birthPickerDate, pronounsItems, showStates } = this.state;
        let youtubeChannelUrl = values.youtubeChannelUrl;
        const { user } = this.store.getState();
        const {birthdateState, countryState, stateProvinceState, euTransferConsentState } = this.state;
        if(youtubeChannelUrl.length <= 0){
            youtubeChannelUrl = "https://";
        }
        let blogUrl = values.blogUrl;
        if(blogUrl.length <= 0){
            blogUrl = "https://";
        }
        const { primaryColor } = this.state.values.guestPreferences;
        let isCustomColor = this.isCustomColor(primaryColor);
        let imageBtnCss = {}
        if(isCustomColor === true && user.userLevel >= 1){
            imageBtnCss.background = primaryColor;
        }
        return (
            <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                <h4 className={classes.title}>Update Your Profile</h4>
                <div className={classes.imageUpload}>
                    <ImageUpload
                        onChange={(params) => this.handleChangePhoto('avatarPhoto', params)}
                        file={avatarPhoto}
                        showLoading={this.state.savingAvatarPhoto}
                        addButtonProps={{ 
                            round: true, className:"addImage", label: "Add Profile Photo",
                            color: (user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor)),
                            style: imageBtnCss
                        }}
                        changeButtonProps={{ 
                            round: true, className:"changeImage", label: "Change Profile Photo",
                            color: (user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor)),
                            style: imageBtnCss
                        }}
                        removeButtonProps={{ 
                            round: true, className:"removeImage",
                            color: (user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor)),
                            style: imageBtnCss
                        }}
                        showInfoTooltip={user.userPreferences.showTips}
                        onShowInfo={() => this.onTooltip('avatarPhoto', true)}
                        avatar={true}
                    />
                </div>
                <div>
                    {
                        editInfo === false && savingInfo === false ?
                            <div className={classes.editButton}>
                                <Button color="blue" onClick={() => this.editUserInfo()}>Edit</Button>
                            </div>
                        :
                        savingInfo ? <LoaderComponent align="left" /> : <></>
                    }                    
                </div>
                <div className={"userInfo "+(editInfo === false?classes.viewInfo:'')}>
                    {
                        editInfo === true ?
                            <div className={classes.saveButtons}>
                                <Button color="white" onClick={() => this.editUserInfo(false)}>
                                    <ArrowBackIcon className={classes.arrow} /> Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveUserInfo()}>
                                    <SaveIcon className={classes.icons} /> Save
                                </Button>
                            </div>
                        :
                            <></>
                    }
                    <p>
                        Update Your Personal Information
                        {
                            user.userPreferences.showTips ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('personalInformation', true)} />
                            :
                            <></>
                        }
                    </p>
                    <CustomInput
                        success={validation.firstName === "success"}
                        error={validation.firstName === "error"}
                        id="outlined-firstName"
                        labelText="First Name"                                    
                        inputProps={{
                            value: values.firstName,
                            onChange: (e) => this.handleChange(e, 'firstName'),
                            name: "firstName",
                            disabled: !editInfo
                        }}
                        onClear={(e) => this.onClear(e, "firstName")}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    <CustomInput
                        success={validation.lastName === "success"}
                        error={validation.lastName === "error"}
                        id="outlined-lastName"
                        labelText="Last Name"                                    
                        inputProps={{
                            value: values.lastName,
                            onChange: (e) => this.handleChange(e, 'lastName'),
                            name: "lastName",
                            disabled: !editInfo
                        }}                                    
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </div>
                <div className={"fs-block "+classes.demographicsContent+" "+(editInfo === false?classes.viewInfo:'')}> 
                    <DatePicker
                        error={birthdateState  === "error"}
                        labelText= {
                            <span>
                                Birthdate (MM-DD-YYYY)<MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            </span>
                        }
                        id="info-birthdate"
                        value= {birthPickerDate}
                        disableFuture= {true}
                        onChange={(date) => this.handleDateChange(date, 'birthdate')}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps = {{
                            disabled: !editInfo
                        }}
                        showPrivateIcon={true}
                    />
                    <CustomSelect 
                        formControlProps={{
                            fullWidth: true
                        }}
                        selectProps={{
                            onChange: (e) => this.handleChange(e,"pronouns"),
                            value: values.pronouns ? values.pronouns : ""
                        }}
                        labelText={
                            <>
                                Pronouns
                                <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            </>
                        }
                        inputProps={{
                            name: "pronouns",
                            id: "info-pronouns",
                            disabled: !editInfo
                        }}
                        items={pronounsItems}
                    />
                    <CustomCountry 
                        success={countryState === "success"}
                        error={countryState === "error"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        selectProps={{
                            onChange: (e, showTransferConsent, showStates) => this.onSelectChange(e, 'country', showTransferConsent, showStates),
                            value: values.country
                        }}
                        labelText={
                            <span>
                                Country <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            </span>
                        }
                        onConsentChange={(status) => this.onConsentChange(status)}
                        onMount={(countryLabel) => this.onCountryMount(countryLabel)}
                        consentInputProps={{
                            error: (euTransferConsentState === "error"),
                            value: values.euTransferConsent
                        }}
                        inputProps={{
                            name: "country",
                            id: "outlined-country",
                            className: classes.alignLeft,
                            disabled: !editInfo
                        }}
                        disabled= {!editInfo}
                    />
                    {
                        showStates ?
                            <CustomCountryState 
                                success={stateProvinceState === "success"}
                                error={stateProvinceState === "error"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                selectProps={{
                                    onChange: (e) => this.onSelectChange(e, 'stateProvince'),
                                    value: values.stateProvince
                                }}
                                country={values.country}
                                onMount={(showStates, stateProvinceLabel) => this.onStateMount(showStates, stateProvinceLabel)}
                                labelText={
                                    <span>
                                        State/Province <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                    </span>
                                }
                                inputProps={{
                                    name: "stateProvince",
                                    id: "outlined-stateProvince",
                                    className: classes.alignLeft,
                                    disabled: !editInfo
                                }}
                                disabled= {!editInfo}
                            />
                        :
                        <></>
                    }
                    
                    <CustomInput
                        id="info-city"
                        labelText="City"
                        inputProps={{
                            value: values.city ? values.city : "",
                            onChange: (e) => this.handleChange(e, "city"),
                            name: "city",
                            disabled: !editInfo
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl
                        }}
                        showPrivateIcon={true}
                    />
                    <CustomInput
                        id="info-postalcode"
                        labelText= "Postal Code"
                        inputProps={{
                            value: values.postalCode ? values.postalCode : "",
                            onChange: (e) => this.handleChange(e, "postalCode"),
                            name: "postalCode",
                            disabled: !editInfo
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.formControl
                        }}
                        showPrivateIcon={true}
                    />
                </div>
                <div>
                    {
                        editInfo === true ?
                            <div className={classes.saveButtons}>
                                <Button color="white" onClick={() => this.editUserInfo(false)}>
                                    <ArrowBackIcon className={classes.arrow} /> Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveUserInfo()}>
                                    <SaveIcon className={classes.icons} /> Save
                                </Button>
                            </div>
                        :
                            editInfo === false && savingInfo === false ?
                                <div className={classes.editButton+" "+classes.editButtonBottom}>
                                    <Button color="blue" onClick={() => this.editUserInfo()}>Edit</Button>
                                </div>
                            :
                            savingInfo ? <LoaderComponent align="left" /> : <></>
                    }
                </div>
                {
                    tooltips.avatarPhoto ?
                        <ProfilePhotoTooltipModal open={tooltips.avatarPhoto} store={this.store} onClose={() => this.onTooltip('avatarPhoto', false)} />
                    :
                    <></>
                }
                {
                    tooltips.coverPhoto ?
                        <CoverPhotoTooltipModal open={tooltips.coverPhoto} store={this.store} onClose={() => this.onTooltip('coverPhoto', false)} />
                    :
                    <></>
                }
                {
                    tooltips.firstName ?
                        <FirstNameTooltipModal open={tooltips.firstName} store={this.store} onClose={() => this.onTooltip('firstName', false)} />
                    :
                    <></>
                }
                {
                    tooltips.lastName ?
                        <LastNameTooltipModal open={tooltips.lastName} store={this.store} onClose={() => this.onTooltip('lastName', false)} />
                    :
                    <></>
                }
                {
                    tooltips.blogUrl ?
                        <BlogUrlTooltipModal open={tooltips.blogUrl} store={this.store} onClose={() => this.onTooltip('blogUrl', false)} />
                    :
                    <></>
                }
                {
                    tooltips.bio ?
                        <BioTooltipModal open={tooltips.bio} store={this.store} onClose={() => this.onTooltip('bio', false)} />
                    :
                    <></>
                }
                {
                    tooltips.searchTerm ?
                        <SearchTermTooltipModal open={tooltips.searchTerm} store={this.store} onClose={() => this.onTooltip('searchTerm', false)} />
                    :
                    <></>
                }
                {
                    tooltips.socialLinks ?
                        <SocialLinksTooltipModal open={tooltips.socialLinks} store={this.store} onClose={() => this.onTooltip('socialLinks', false)} />
                    :
                    <></>
                }
                {
                    tooltips.personalInformation ?
                        <PersonalInformationTooltipModal open={tooltips.personalInformation} store={this.store} onClose={() => this.onTooltip('personalInformation', false)} />
                    :
                    <></>
                }
                {
                    tooltips.demographics ?
                        <DemographicsTooltipModal open={tooltips.demographics} store={this.store} onClose={() => this.onTooltip('demographics', false)} />
                    :
                    <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabProfileEditStyle)(DashboardTabProfileEdit);
