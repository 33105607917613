import {
    blackColor,
    mainRaised,
    whiteColor,
    hexToRgb,
    primaryColor,
    grayColor,
    roseColor,
    successColor,
    infoColor,
    primaryBoxShadow,
    successBoxShadow,
    infoBoxShadow,
    roseBoxShadow
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "./dashboardSubTabs";
import searchPageStyle from "../../user/searchPageStyle.js";
import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const dashboardTabPreviewStyle = theme => ({
    ...dashboardSubTabs(theme),
    ...searchPageStyle(theme),
    ...customCheckboxRadioSwitch,
    gridItem: {
        paddingLeft: "1px",
        paddingRight: "1px"
    },
    mainRaised: {
        ...mainRaised,
        marginTop: "150px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "120px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "80px"
        },
        marginBottom: "50px"
    },
    previewMain: {
        "& .user_cover_photo": {
            position: "absolute",
            top: "0px",
            width: "100%",
            height: "100%",
            objectFit: "cover"
        }
    },
    previewLabel: {
        color: blackColor,
        top: "23px",
        marginLeft: "-60px",
        position: "absolute",
        zIndex: 1106,
        background: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
        transform: "rotate(-45deg)",
        height: "30px",
        width: "200px",
        textAlign: "center",
        border: "1px solid "+blackColor,
        lineHeight: "30px",
        [theme.breakpoints.down("sm")]: {
            height: "28px",
            lineHeight: "28px",
            top: "13px",
            marginLeft: "-72px",
            fontSize: "14px"
        }
    },
    styleBox: {
        position: "absolute",
        bottom: "-275px",
        zIndex: "1110",
        width: "500px",
        transition: "all 0.3s ease",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    openStyleBox: {
        bottom: "0px"
    },
    styleBoxButton: {
        margin: "0px !important",
        border: "0px !important"
    },
    styleBoxContent: {
        background: whiteColor,
        padding: theme.spacing(2)
    },
    rangerSlider: {
        textAlign: "center",
        "& .slidecontainer": {
            maxWidth: "400px",
            width: "100%",
            margin: theme.spacing(0, "auto", 0)
        },
        "& .slider": {
            "-webkit-appearance": "none",
            width: "100%",
            height: "10px",
            borderRadius: "5px",
            background: "#d3d3d3",
            outline: "none",
            opacity: "1",
            "-webkit-transition": ".2s",
            transition: "opacity .2s",
            "&::-webkit-slider-thumb": {
                "-webkit-appearance": "none",
                appearance: "none",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            },
            "&::-moz-range-thumb": {
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            }
        },
    },
    customLabel: {
        margin: theme.spacing(1, 1, 2, 0),
    },
    fontSize: {
        listStyle: "none",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "center",
        marginBottom: "0px",
        marginTop: theme.spacing(2),
        "& li": {
            display: "inline-block",
            verticalAlign: "middle",
            margin: theme.spacing(0, 1),
            cursor: "pointer",
            padding: "6px",
            "& span": {
                display: "block",
            },
            "&.large":{
                "& span": {
                    width: "30px",
                    height: "30px",
                    lineHeight: "30px",
                    fontSize: "23px",
                }
            },
            "&.small":{
                "& span": {
                    width: "18px",
                    height: "18px",
                    lineHeight: "18px",
                    fontSize: "16px",
                }
            },
            "&.active": {
                border: "2px solid "+blackColor,
                padding: "4px"
            }
        }
    },
    guestViewOptions: {
        width: "auto",
        textAlign: "center",
        maxWidth: "220px",
        boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& button:hover": {
            backgroundColor: "transparent",
            color: grayColor[8],
        },
        // "& button:focus": {
        //     backgroundColor: "rgba(0, 0, 0, 0.14)",
        //     color: grayColor[8],
        // },
        float: "none",
        display: "inline-block"
    },
    activeViewOption: {
        backgroundColor: grayColor[11]+" !important",
        color: grayColor[8]+" !important",
    },
    grid: {
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    viewTypeLabel: {
        fontWeight: "bold",
        fontSize: "14px"
    },
    showButtonOption: {
        width: "75px",
        border: "1px dashed "+blackColor,
        padding: "3px",
        textAlign: "center",
        marginBottom: theme.spacing(2),
        display: "inline-block"
    },
    buttons: {
        margin: "0px"
    },
    layout: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    gridContainer: {
        display: "flex",
        "& .MuiGrid-item + .MuiGrid-item": {
            paddingLeft: "0px"
        },
        "& .MuiFormControlLabel-root": {
            marginRight: "0px"
        }
    },
    primaryColor: {
        paddingLeft: "0px",
        paddingRight: "0px",
        marginTop: theme.spacing(1),
        "& li": {
            "& span": {
                width: "25px",
                height: "25px",
                display: "block",
                background: primaryColor[0],
                ...primaryBoxShadow,
                [theme.breakpoints.down("sm")]: {
                    width: "20px",
                    height: "20px",
                },
            },            
            display: "inline-block",
            marginRight: "5px",
            cursor: "pointer",
            border: "2px solid transparent",
            padding: "2px",
            [theme.breakpoints.down("sm")]: {
                marginRight: "5px",
            },
            "&.active": {
                border: "2px solid "+blackColor,
            }
        }
    },
    colorPicker: {
        position: "relative",
        "& img": {
            width: "25px",
            height: "25px",
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            lineHeight: "25px",
            margin: "auto",
        },
        "& .color-picker-input": {
            position: "absolute",
            bottom: "5px",
            zIndex: "-1",
            visibility: "hidden",
        }
    },
    grayColor: {
        "& span": {
            background: grayColor[26]+" !important",
            boxShadow:
                "0 4px 20px 0px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.14), 0 7px 10px -5px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.4)"
        }
    },
    infoColor: {
        "& span": {
            background: infoColor[0]+" !important",
            ...infoBoxShadow
        }
    },
    roseColor: {
        "& span": {
            background: roseColor[0]+" !important",
            ...roseBoxShadow,
        }
    },
    successColor: {
        "& span": {
            background: successColor[0]+" !important",
            ...successBoxShadow
        }
    },
    customInfo: {
        "& span": {
            background: infoColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.2)"
        }
    },
    customRose: {
        "& span": {
            background: roseColor[5]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.2)"
        }
    },
    customSuccess: {
        "& span": {
            background: successColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.2)"
        }
    },
    changeBackground: {
        position: "absolute",
        right: "-20px",
        top: "-145px",
        [theme.breakpoints.down("sm")]: {
            top: "-115px",
        },
        "@media screen and (max-width: 830px)": {
            right: '0px'
        },
        [theme.breakpoints.down("xs")]: {
            top: "-75px",
            right: '0px'
        }
    },
    changeProfilePhoto: {
        position: "absolute",
        top: "-85px",
        marginLeft: "0px",
        zIndex: "1",
        [theme.breakpoints.down("sm")]: {
            top: "-90px",
        },
        [theme.breakpoints.down("xs")]: {
            top: "-70px",
        }
    },
    fileInput: {
        display: "none !important"
    },
    coverPhotoBackground: {
        overflow: "hidden",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center center"
    },
    classic: {
        //No Change
    },
    simple: {
        "&$coverPhotoBackground": {
            backgroundColor: whiteColor,
        },
        "& $mainRaised": {
            boxShadow: "none",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "75px",
            [theme.breakpoints.down("sm")]: {
                marginTop: "60px",
            }
        },
        "& $changeProfilePhoto": {
            [theme.breakpoints.down("sm")]: {
                minWidth: "30px",
                width: "30px",
                height: "30px",
                top: "-70px"
            }
        },
        "& $changeBackground": {
            right: "10px",
            top: "-70px",
            [theme.breakpoints.down("sm")]: {
                top: "-55px",
            }
        },
        "& .user_avatar_photo": {
            maxWidth: "80px",
            height: "80px",
            transform: "translate3d(0, -85%, 0)",
            [theme.breakpoints.down("sm")]: {
                maxWidth: "60px",
                height: "60px",
                transform: "translate3d(0, -85%, 0)",
            }
        }
    },
});

export default dashboardTabPreviewStyle;