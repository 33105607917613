import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import Config from "../../../../../Config";
import { createBrowserHistory } from "history";
import { Helmet } from 'react-helmet';
import { helper } from "../../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import classNames from "classnames";
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import SearchForm from "../../../components/Dashboard/Editmode/Form";
import UserAbout from "../../../components/Dashboard/Editmode/About";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Link from "@material-ui/core/Link";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AddPhotoAlternate from "@material-ui/icons/AddPhotoAlternate";
import Portrait from "@material-ui/icons/Portrait";
import AboutModal from "../../../components/Dashboard/Editmode/AboutModal";
import FillAboutInfoModal from "../../../components/Dashboard/Editmode/FillAboutInfoModal";

import { primaryColor as defaultPrimaryColor, grayColor, infoColor, roseColor, successColor, whiteColor } from "../../../../assets/jss/material-kit-pro-react.jsx";
import dashboardTabPreviewStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabPreviewStyle.js";
import christian from "../../../../assets/img/default-avatar.png";
const history = createBrowserHistory();

const DashboardTabPreview = class extends React.Component {
    constructor(props){
        super(props);
       
        this.store = this.props.store;
        this.history = this.props.history;
        this.primaryColor = null;
        this.backgroundInput = React.createRef();
        this.avatarInput = React.createRef();

        const { user } = this.props;
        let values = Api.prepareMemberObject(user);

        let coverPhoto = null;
        let coverMediaId = null;
        if(values.hasOwnProperty("coverMedia")){
            coverPhoto = values.coverMedia.downloadUrlProfile;
            coverMediaId = values.coverMedia.mediaId;
        }
        let isCustomColor = helper.isCustomColor(values.guestPreferences.primaryColor);
        let customColor = defaultPrimaryColor[0];
        if(isCustomColor){
            customColor = values.guestPreferences.primaryColor;
        }

        let viewType = "list";
        let showButtons = false;
        let contentPlatforms = [];
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("defaultLayout")){
            viewType = user.guestPreferences.defaultLayout;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("showButtons")){
            showButtons = user.guestPreferences.showButtons;
        }
        if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("contentPlatforms")){
            contentPlatforms = user.guestPreferences.contentPlatforms;
        }

        let isSearchPage = true;
        if(this.props.hasOwnProperty("search") && (this.props.search === 0 || this.props.search === "0")){
            isSearchPage = false;
        }
        if(window.location.pathname === "/dashboard/editmode/search"){
            isSearchPage = true;
        }
        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        this.state = {
            values: values,
            isSearchPage: isSearchPage,
            searchTerm: null,
            customColor: customColor,
            coverPhoto: coverPhoto,
            coverPhotoDataUrl: null,
            coverMediaId: coverMediaId,
            fonts: Api.getFonts(),
            viewType: viewType,
            showButtons: showButtons,
            contentPlatforms: contentPlatforms,
            openColorPicker: false,
            anchorEl: null,
            openStyleBox: this.props.openStyleBox,
            backgroundImage: null,
            backgroundImageUrl: null,
            avatarImage: null,
            avatarImageUrl: null,
            user: values,
            aboutModal: (isSearchPage === false && template === "simple"),
            fillInfoAboutModal: false
        };

        this.onSearchPopupClosed = this.onSearchPopupClosed.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            openStyleBox: props.openStyleBox,
            user: props.user
        };
    }
    componentDidUpdate(){
        this.primaryColor = null;
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        const { user } = this.state;
        this.primaryColor = palette.custom.main;

        let color = defaultPrimaryColor[0];
        let isCustomColor = helper.isCustomColor(user.guestPreferences.primaryColor);
        if(isCustomColor){
            color = user.guestPreferences.primaryColor;
        }else{
            switch(user.guestPreferences.primaryColor){
                case 'gray':
                    color = grayColor[26];
                    break;
                case 'customInfo':
                    color = infoColor[8];
                    break;
                case 'customRose':
                    color = roseColor[5];
                    break;
                case 'customSuccess':
                    color = successColor[8];
                    break;
                default:
                    color = defaultPrimaryColor[0];
                    break;
            }
        }
        this.primaryColor = color;
        return color;
    }
    onSearchPopupClosed(slide = 0){
        const { user } = this.state;
        let template = null;
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template")){
            template = user.guestPreferences.template;            
        }
        if(template === "simple"){
            this.setState({aboutModal: true});
            return;
        }
        
        this.setState({
            isSearchPage: (slide === 0)
        });

        if(slide === 0){
            history.push("/dashboard/editmode/search");
        }else{
            history.push("/dashboard/editmode/search/about");
        }
    }
    handleGuestPrefChange(event, name, value = null, customColor = false) {
        if(value === null){
            value = event.target.value;
        }
        let state = {
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    [name]: value
                }                
            }
        };
        if(customColor){
            state['customColor'] = value;
        }
        this.setState(state);
    }
    getViewTypeLabel(){
        const { viewType } = this.state;
        let label = "";
        switch(viewType){
            case "stream":
                label = "Stream";
                break;
            case "list":
                label = "List View";
                break;
            case "grid":
                label = "Large Grid";
                break;
            case "threecolumngrid":
                label = "Small Grid";
                break;
            case "card":
                label = "Cards";
                break;
            default:
                break;
        }
        return label;
    }
    toggleStyleBox(){
        this.setState({
            openStyleBox: !this.state.openStyleBox
        });
    }
    getUserTheme(){
        let { user } = this.state;
        let buttonText = whiteColor;
        let iconColor = whiteColor;
        if(user.guestPreferences.theme === "dark"){
            buttonText = grayColor[27];
            iconColor = grayColor[28];
        }
        let themeOptions = {
            palette: {
                primary: {
                    main: defaultPrimaryColor[0],
                    light: grayColor[5],
                    dark: grayColor[20],
                    contrastText: whiteColor,
                },
                secondary: {
                    main: infoColor[0],
                    light: infoColor[6],
                    dark: infoColor[0],
                    contrastText: whiteColor,
                },
                custom: {
                    main: defaultPrimaryColor[0],
                    buttonText: buttonText,
                    iconColor: iconColor
                }
            },
            typography: {
                fontFamily: [
                    '"Gotham-Book"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif'
                ].join(',')
            },
            customize: {
                fontSize: "14px"
            }
        };
        themeOptions.palette.custom.main = this.getPrimaryColor();
        themeOptions.customize.fontSize = (user.guestPreferences.fontSize === "large" ? "14px" : "12px");
        const muiTheme = createMuiTheme(themeOptions);
        return muiTheme;
    }
    onBackgroundChange(){
        this.backgroundInput.current.click();
    }
    onAvatarChange(){
        this.avatarInput.current.click();
    }
    handleImageChange(e, type){
        if(e.target.files.length <= 0){
            return;
        }
        let file = e.target.files[0];

        let reader = new FileReader();
        reader.onloadend = () => {
            let state = {};
            if(type === "avatarPhoto"){
                state['avatarImageUrl'] = reader.result;
            }else{
                state['backgroundImageUrl'] = reader.result;
            }
            this.setState(state);
        };
        reader.readAsDataURL(file);
        this.uploadPhoto(file, type);
    }
    uploadPhoto(file, type){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        let state = {cancelToken: source};
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
        };
        if(type === "avatarPhoto"){
            requestData['isAvatar'] = true;
            state['avatarImage'] = file;
        }else{
            requestData['isCover'] = true;
            state['backgroundImage'] = file;
        }
        this.setState(state);
        Api.createMedia(file, requestData, source).then((data) => {
            let newState = {
                cancelToken: null, 
                savingAvatarPhoto: false,
                savingCoverPhoto: false
            };
            let mediaKey = "coverMediaId";
            if(type === "avatarPhoto"){
                mediaKey = "avatarMediaId";
            }
            newState[mediaKey] = data.mediaId;
            that.setState(newState);

            const state = {
                values: {
                    ...this.state.user,
                    [mediaKey]: data.mediaId
                }
            };
            setTimeout(() => {
                this.props.onUserUpdate(state);
            }, 5000);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    onAboutModalClose(){
        this.setState({aboutModal: false});
        history.push("/dashboard/editmode/search");
    }
    onPreview(e){
        const { user } = this.state;
        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        if(template === "simple"){
            if((user.hasOwnProperty("bio") && user.bio.trim().length > 0)|| (user.hasOwnProperty("topSearchTerms") && user.topSearchTerms.trim().length > 0) 
            || (user.hasOwnProperty("socialIcons") && user.socialIcons.hasOwnProperty("blogUrl") && user.socialIcons.blogUrl.trim().length > 0)){
                return;
            }
        }else{
            if((user.hasOwnProperty("bio") && user.bio.trim().length > 0)|| (user.hasOwnProperty("socialIcons") && user.socialIcons.hasOwnProperty("blogUrl") && user.socialIcons.blogUrl.trim().length > 0)
            || (user.hasOwnProperty("socialIcons") && Object.keys(user.socialIcons).length > 0) || (user.hasOwnProperty("topSearchTerms") && user.topSearchTerms.trim().length > 0)){
                return;
            }
        }
        e.preventDefault();
        this.setState({fillInfoAboutModal: true});
    }
    onFillAboutModalClose(){
        this.setState({fillInfoAboutModal: false});
        const { user } = this.state;
        window.location.href = Config.getFrontEndUrl()+"/"+user.username;
    }
    onFillAboutModalSuccess(){
        const { user } = this.state;
        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        if(template === "simple"){
            this.setState({
                aboutModal: true,
                fillInfoAboutModal: false
            });
        }else{
            this.setState({
                isSearchPage: false,
                fillInfoAboutModal: false
            });
            history.push("/dashboard/editmode/search/about");
        }
    }
    render() {
        const { classes } = this.props;
        const { isSearchPage, searchTerm, user, backgroundImageUrl, avatarImageUrl, aboutModal, fillInfoAboutModal } = this.state;
        
        if(this.props.isActiveTab !== true){
            return <></>;
        }

        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );

        let defaultAvatar = christian;
        if(avatarImageUrl !== null){
            defaultAvatar = avatarImageUrl;
        }else if(user !== null && user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlProfile;
        }

        let defaultCover = null;
        if(backgroundImageUrl !== null){
            defaultCover = backgroundImageUrl;
        }else if(user.hasOwnProperty("coverMedia")){
            defaultCover = user.coverMedia.downloadUrlMain;
        }

        let theme = "light"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("theme") && user.guestPreferences.theme === "dark"){
            theme = "dark";            
        }

        let backgroundCss = {}
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("layoutTransparency")){
            const { layoutTransparency } = user.guestPreferences;
            let backgroundOpacity = 1-(layoutTransparency/100);
            backgroundCss.backgroundColor= "rgb(255, 255, 255, "+backgroundOpacity+")";
            if(theme === "dark"){
                backgroundCss.backgroundColor= "rgb(1, 1, 1, "+backgroundOpacity+")";
            }
            
            if(backgroundOpacity === 0){
                backgroundCss.boxShadow= "none"
            }
        }

        let coverPhotoStyles = {};
        if(defaultCover !== null){
            coverPhotoStyles['backgroundImage'] = "url("+defaultCover+")";
        }

        let avatarPhotoStyles = {};
        if(defaultAvatar !== null){
            avatarPhotoStyles['backgroundImage'] = "url("+defaultAvatar+")";
        }

        let headStyles = [];
        if(user !== null && user.guestPreferences.hasOwnProperty("fontFamily")){
            headStyles.push({
                "cssText": `
                    body, h1, h2, h3, h4, h5, h6, p, div, a, span, label {
                        font-family: '`+user.guestPreferences.fontFamily+`', "Helvetica", "Arial", sans-serif !important;
                    }
                    a {
                        color: `+this.getPrimaryColor()+`
                    }
                `
            });
        }
        let previewLabelUrl = Config.getFrontEndUrl()+"/"+user.username;
        if(!isSearchPage){
            previewLabelUrl += "/about";
        }

        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        if(template === "simple" && theme === "dark"){
            coverPhotoStyles['backgroundColor'] = "rgb(1, 1, 1)";
        }

        if(template === "simple" && defaultAvatar !== null){
            backgroundCss['backgroundColor'] = "transparent";
        }

        return (
            <div className={classes.coverPhotoBackground+" "+(classes[template])} style={coverPhotoStyles}>
            <MuiThemeProvider theme={this.getUserTheme()}>
                <div className={classes.tabs+" "}>
                    <div className={classes.previewLabel}>
                        <Link href={previewLabelUrl} underline="always" onClick={(e) => this.onPreview(e)}>Preview</Link>
                    </div>
                    <div className={classNames(classes.main, classes.mainRaised)+" "+(classes.hiddenSplash)+" "+(theme === "dark" ? classes.dark : classes.light)} style={backgroundCss}>
                        <Button round justIcon color="primary" onClick={(e) => this.onBackgroundChange(e)} className={classes.changeBackground}><AddPhotoAlternate /></Button>
                        <input type="file" className={classes.fileInput} ref={this.backgroundInput}  onChange={e => this.handleImageChange(e, 'coverPhoto')} />
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div className={classes.profileAvatar}>
                                            <Button round justIcon color="primary" onClick={(e) => this.onAvatarChange(e)} className={classes.changeProfilePhoto}><Portrait /></Button>
                                            <input type="file" className={classes.fileInput} ref={this.avatarInput}  onChange={e => this.handleImageChange(e, 'avatarPhoto')} />
                                            {/* <img src={defaultAvatar} alt="..." className={imageClasses} onClick={(event) => { this.onSearchPopupClosed(isSearchPage ? 1: 0); }} /> */}
                                            <div style={avatarPhotoStyles} className={imageClasses+" user_avatar_photo"} onClick={(event) => { this.onSearchPopupClosed(isSearchPage ? 1: 0); }}></div>
                                        </div>
                                        <div className={classes.name}>
                                        </div>
                                    </div>
                                </GridItem>
                                {
                                    template !== "simple" ?
                                        isSearchPage ?
                                            <Link underline="always" href={"/"+user.username+"/about"} style={{color: this.getPrimaryColor()}} onClick={(event) => { event.preventDefault(); this.onSearchPopupClosed(1); }} className={classes.aboutLink}>About Me</Link>
                                        :
                                        <Link underline="always" href={"/"+user.username} style={{color: this.getPrimaryColor()}} onClick={(event) => { event.preventDefault(); this.onSearchPopupClosed(0); }} className={classes.aboutLink}>Search Me</Link>
                                    :
                                    <></>
                                }
                            </GridContainer>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {
                                        isSearchPage || template === "simple" ?
                                            <GridItem className={classes.mobileSpacing}>                                        
                                                <SearchForm match={this.props.match} store={this.store} history={this.history} user={user} onUserUpdate={this.props.onUserUpdate} onPopupClose={this.onSearchPopupClosed} onCopy={this.onCopy} primaryColor={this.getPrimaryColor()} searchTerm={searchTerm} />
                                            </GridItem>
                                        :
                                        <GridItem>
                                            <UserAbout store={this.store} user={user} primaryColor={this.getPrimaryColor()} onSearchTerm={this.onSearchTerm} onUserUpdate={this.props.onUserUpdate} />
                                        </GridItem>
                                    }
                                </GridItem>
                            </GridContainer> 
                        </div>
                    </div>
                    {/* {
                        defaultCover !== null ?
                            <img src={defaultCover} alt="User Cover" className="user_cover_photo" />
                        :
                        <></>
                    } */}
                    {
                        aboutModal && template === "simple" ?
                            <AboutModal open={aboutModal} onClose={() => this.onAboutModalClose()} store={this.store} user={user} primaryColor={this.getPrimaryColor()} onSearchTerm={this.onSearchTerm} onUserUpdate={this.props.onUserUpdate} />
                        :
                        <></>
                    }
                    {
                        fillInfoAboutModal ?
                            <FillAboutInfoModal open={fillInfoAboutModal} template={template} onClose={() => this.onFillAboutModalClose()} onSuccess={() => this.onFillAboutModalSuccess()} store={this.store} user={user} />
                        :
                        <></>
                    }
                </div>
            </MuiThemeProvider>
            <Helmet 
                style={headStyles}
            />
            </div>
        )
    }
}

DashboardTabPreview.defaultProps = {
    subscription: null,
    paymentData: null,
    openStyleBox: false,
    user: {}
}
DashboardTabPreview.propTypes = {
    subscription: PropTypes.object,
    paymentData: PropTypes.object,
    openStyleBox: PropTypes.bool,
    user: PropTypes.object
}

export default withTheme(withStyles(dashboardTabPreviewStyle)(DashboardTabPreview));
