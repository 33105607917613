import {
    blackColor,
    hexToRgb
} from "../../material-dashboard-pro-react.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";

const webLinkEditWizardStyle = theme => ({
    ...modalStyle(theme),
    wizard: {
        display: "inline-block",
        width: "100%",
        marginTop: "10px",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        transition: "all 300ms linear",
        minHeight: "410px",
        maxWidth: "100%",
        marginLeft: "0px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            paddingLeft: "8px",
            paddingRight: "8px"
        },
        "& .catalog-edit-page-holder": {
            padding: "0px",
            "& > div": {
                width: "auto",
                maxWidth: "none",
                flexBasis: "100%",
                padding: "0px",
                "& > div": {
                    padding: "0px",
                }
            },
            "& form": {
                "& > div": {
                    width: "auto",
                    maxWidth: "none",
                    flexBasis: "100%",
                    padding: "0px"
                }
            }
        }
    },
    closeButton: {
        position: "absolute",
        right: "-1px",
        zIndex: 1,
        top: "-5px",
        [theme.breakpoints.down("xs")]: {
            top: "0px",
            right: "-1px",
            width: "30px",
            height: "20px",
            padding: "0px",
            minWidth: "0px"
        }
    },
    modalRootCustom: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            top: "16px !important",
        }
    },
    modalWebLink: {
        width: "100%",
        maxWidth: "600px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important"
        }        
    },
    paperScrollPaperCustom: {
        height: "auto",
    },
    customModalWebLink: {
        marginTop: "10px !important",
        [theme.breakpoints.down("xs")]: {
            margin: "8px",
            marginTop: "0px !important",
            marginBottom: "0px !important",
        }
    },
    modalCustomBody: {
        textAlign: "center",
        "& form": {
            textAlign: "left",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0px 8px 8px !important"
        }
    }
});

export default webLinkEditWizardStyle;