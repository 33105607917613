import { grayColor, whiteColor, dangerColor } from "../material-kit-pro-react.jsx";

import paymentMethodPageStyle from "../payment/paymentMethodPageStyle";
import customSelectStyle from "../material-kit-pro-react/customSelectStyle";
const changeUserLevelModalStyle = theme => ({
    ...paymentMethodPageStyle(theme),
    ...customSelectStyle,
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "130px !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "0",
        paddingLeft: "24px",
        minHeight: "16.43px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center"
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "left"
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto",
        justifyContent: "flex-start",
        width: "100%",
        display: "block",
        overflow: "hidden",
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
            width: "300px",
            margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
            color: whiteColor,
            top: "-10px",
            right: "10px",
            textShadow: "none",
            position: "relative"
        },
        "& $modalHeader": {
            borderBottom: "none",
            paddingTop: "24px",
            paddingRight: "24px",
            paddingBottom: "0",
            paddingLeft: "24px"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        },
        "& $modalFooter": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
            paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
            paddingTop: "0"
        },
        "& $modalTitle": {
            textAlign: "center",
            width: "100%",
            marginTop: "0.625rem"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    featuresTitle: {
        marginTop: theme.spacing(2),
        lineHeight: "1.5",
        textAlign: "center"
    },
    features: {
        listStyleType: "none",
        paddingLeft: "0px",
        textAlign: "center",
        marginTop: theme.spacing(2),
        "& li + li": {
            marginTop: theme.spacing(1)
        }
    },
    changeLevelButton: {
        float: "right"
    },
    packagePrice: {
        textAlign: "center",
        overflow: "hidden",
        "& span": {
            fontWeight: "bold"
        },
        "& .monthly_price": {
            marginRight: "10px"
        },
        "& .yearly_price": {
            marginLeft: "10px"
        }
    },
    card: {
        marginTop: "0px",
        marginBottom: "0px",
        "& h1": {
            marginTop: "0px"
        },
        "& ul": {
            marginBottom: "0px",
            maxWidth: "none",
            "& li": {
                padding: "6px 0px"
            }
        },
        "& > div": {
            paddingTop: "0px !important",
            [theme.breakpoints.down("xs")]: {
                paddingLeft: "0px !important",
                paddingRight: "0px !important"
            }
        }
    },
    formControl: {
        marginTop: "-10px"
    },
    modalCloseButtonLogin: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        "& svg": {
            marginRight: "0px"
        }
    },
    center: {
        textAlign: "center",
        "& .card_details > label": {
            display: "none !important"
        }
    },
    modalRootCustom: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            top: "16px !important",
        }
    },
    modalCustom: {
        width: "100%",
        maxWidth: "500px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important"
        }        
    },
    paperScrollPaperCustom: {
        height: "auto",
    },
    alignRight: {
        textAlign: "right",
        "& p": {
            textAlign: "center"
        },
        "& p.informationSafe": {
            textAlign: "right",
        },
        "& .card_details > label": {
            display: "none !important"
        }
    },
    requiredCard: {
        color: dangerColor[0],
        textAlign: "center !important"
    },
    applyButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

export default changeUserLevelModalStyle;
