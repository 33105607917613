import { whiteColor, grayColor } from "../material-kit-pro-react.jsx";
const tourTooltipStyle = theme => ({
  body: {
    backgroundColor: whiteColor,
    padding: theme.spacing(3),
    width: "350px",
    maxWidth: "100%",
    overflow: "hidden",
    position: "relative",
    borderRadius: "6px"
  },
  content: {
    marginBottom: theme.spacing(2),
    textAlign: "center"
  },
  next: {
    float: "right"
  },
  back: {},
  finish: {
    float: "right"
  },
  footer: {},
  modalCloseButton: {
    "&, &:hover": {
      color: grayColor[0]
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "0px",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5",
    position: "absolute",
    right: "5px",
    top: "5px"
  },
});

export default tourTooltipStyle;
