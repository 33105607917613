import React from 'react'
import {Helmet} from 'react-helmet'
import SetUserLocation from "../../components/Location/UserLocation";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import useStyles from "../../../assets/jss/location/locationPageStyle";

export default function UserLocation(props) {
    const store = props.store;
    const classes = useStyles();

    return (
        <Container className="container profile-edit-page-holder">
            <Grid item className={classes.container} xs={12} md={8} lg={8}>               
                <SetUserLocation store={store} />                
            </Grid>
            <Helmet>
                <title>{process.env.REACT_APP_TITLE}</title>
            </Helmet>
        </Container>
    )
}
