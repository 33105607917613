import { whiteColor } from "../../material-kit-pro-react.jsx";

const platformContentStyle = theme => ({
    mainContainer: {
        marginTop: theme.spacing(2)
    },
    mainGridItem: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    searchButtonGridItem: {
        alignSelf: "center"
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2),
    },
    gridContainer: {
        marginLeft: "0px",
        marginRight: "0px",
        "& > .MuiGrid-item": {
            paddingLeft: "0px",
            paddingRight: "0px"
        },
        "& .info_tooltip_icon": {
            position: "absolute",
            top: "119px",
            right: "10px"
        }
    },
    searchGridItem: {
        paddingRight: "15px !important"
    },
    clearSearch: {
        padding: "10px 12px",
        marginTop: "0px",
        marginBottom: "0px",
        marginRight: "0px",
        [theme.breakpoints.down("xs")]: {
            padding: "8px 8px",
        },
        "& svg": {
            color: whiteColor,
            margin: "0px"
        }
    },
    loadMore: {
        margin: "0px auto",
        marginTop: theme.spacing(2)
    },
    editSettings: {
        position: "absolute",
        right: "0px",
        zIndex: "1"
    },
    snackbarContent: {
        textAlign: "center"
    },
    platformDetail: {
        position: "relative"
    },
    platformDeleteButton: {
        position: "absolute",
        right: "0px",
        bottom: "13px"
    }
});

export default platformContentStyle;