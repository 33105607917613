import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = theme => ({
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allStates: this.props.allStates,
            ownedByMe: false,
            futureChanges: false,
            importedLinks: false,
            ownedByMeState: "",
            futureChangesState: "",
            importedLinksState: ""
        };
    }
    componentWillReceiveProps(props){
        this.setState({allStates: props.allStates});
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        const { ownedByMe, futureChanges, importedLinks } = this.state;
        if(ownedByMe === false || futureChanges === false || importedLinks ===  false){
            this.setState({
                ownedByMeState: ownedByMe?"success":"error",
                futureChangesState: futureChanges?"success":"error",
                importedLinksState: importedLinks?"success":"error"
            });
        }
        return (ownedByMe && futureChanges && importedLinks);
    }
    handleToggle(name) {
        let newState = {
            [name+"State"]: this.state[name] ?"error":"success",
            [name]: !this.state[name]
        };
        this.setState(newState);
    };
    render() {
        const { classes } = this.props;
        const { allStates, ownedByMe, ownedByMeState, futureChanges, futureChangesState, importedLinks, importedLinksState } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>Did we get it right?</h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        {
                            allStates.hasOwnProperty("account") ?
                                <CustomInput
                                    labelText="Link in Profile Username"
                                    inputProps={{
                                        value: "https://linkinprofile.com/"+allStates.account.username.replace(/\s/g, ""),
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                />
                            :
                            <></>
                        }
                        {
                            allStates.hasOwnProperty("robot") ?
                                <>
                                <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={allStates.robot.featureLinktreeLinks}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            disabled={true}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot,
                                                disabled: classes.disabledCheckboxAndRadio
                                            }}
                                        />
                                        }
                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                        label="Feature all imported links"
                                    />
                                </div>
                                </>
                            :
                            <></>
                        }
                        
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("ownedByMe")}
                                    checked={ownedByMe}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    success={(ownedByMeState === "success" ? ownedByMeState: "")}
                                    error={(ownedByMeState === "error" ? ownedByMeState: "")}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label+" "+(ownedByMeState === "error"?classes.labelError:""), root: classes.labelRoot }}
                                label="This Link in Profile Account is owned by me"
                            />
                        </div>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("importedLinks")}
                                    checked={importedLinks}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    success={(importedLinksState === "success" ? importedLinksState: "")}
                                    error={(importedLinksState === "error" ? importedLinksState: "")}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label+" "+(importedLinksState === "error"?classes.labelError:""), root: classes.labelRoot }}
                                label="I understand the imported links will be found under my web links platform."
                            />
                        </div>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={() => this.handleToggle("futureChanges")}
                                    checked={futureChanges}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    success={(futureChangesState === "success" ? futureChangesState: "")}
                                    error={(futureChangesState === "error" ? futureChangesState: "")}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                        disabled: classes.disabledCheckboxAndRadio,
                                    }}
                                />
                                }
                                classes={{ label: classes.label+" "+(futureChangesState === "error"?classes.labelError:""), root: classes.labelRoot }}
                                label="I understand future changes to my Link In Profile account will not be imported automatically"
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step3.defaultProps = {
    onMount: function(){ }
}
Step3.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step3);
