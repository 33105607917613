import Joyride from "react-joyride";
import React from "react";
import PropTypes from "prop-types";
import TourTooltip from "./TourTooltip";
import Config from '../../../../Config';
import Link from '@material-ui/core/Link';

class PlateformTour extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            platforms: this.props.platforms,
            platformsMenu: this.props.platformsMenu,
            webLinkHasContent: this.props.webLinkHasContent
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            platforms: props.platforms,
            webLinkHasContent: props.webLinkHasContent,
            platformsMenu: props.platformsMenu,
        });
    }
    getSteps(){
        const chromeExtensionUrl = Config.getChromeExtensionUrl();
        let steps = [];
        const { webLinkHasContent, platforms, platformsMenu } = this.state;
        let target = (webLinkHasContent || platforms.length > 1) ? ".platformIcons" : ".platformWizardTitle"
        
        steps.push({
            target: target,
            disableBeacon: true,
            content: "A Content Platform is where you identify what content you want added to your search engine."
        });
        steps.push({
            target: ".tab-add-button",
            disableBeacon: true,
            content: "You can add buttons for important call to actions on your search page. They are similar to web links, except they will appear first on your search page under the search bar."
        });
        steps.push({
            target: ".tab-add-weblink",
            disableBeacon: true,
            content: <div>You can add any web link manually. These can also be added from our <Link href={chromeExtensionUrl} target='_blank'>chrome extension</Link>.</div>
        });
        if(platformsMenu.length > 0){
            steps.push({
                target: ".tab-add-content",
                disableBeacon: true,
                content: "Click here to add a content platform. Most users start by linking their Instagram account or YouTube channel. You can also import your LinkTree buttons."
            });
        }        
        return steps;
    }

    render() {
        const steps = this.getSteps();
        const that = this;
        return (
            <Joyride
                steps={steps}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                run={this.props.run}
                tooltipComponent={TourTooltip}
                callback={function(data){
                    if(data.type === "tour:end"){
                        that.props.onClose();
                    }
                }}
            />
        );
    }
}

PlateformTour.defaultProps = {
    onClose: function(){ }
};
PlateformTour.propTypes = {
    onClose: PropTypes.func,
};

export default PlateformTour;
