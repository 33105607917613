import {
    blackColor,
    hexToRgb
} from "../../material-dashboard-pro-react.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";
const SearchableContentStyle = theme => ({
    ...modalStyle(theme),
    gridContainer: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    gridItemSpacing: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            marginRight: "0px",
            paddingRight: "1px",
            paddingLeft: "1px"
        }
    },
    closeButton: {
        position: "absolute",
        right: "15px",
        zIndex: 1,
        top: "20px",
        [theme.breakpoints.down("xs")]: {
            right: "10px"
        }
    },
    mainContainer: {
        color: "rgba(0, 0, 0, 0.87)",
        width: "100%",
        margin: "25px 0",
        display: "block",
        position: "relative",
        background: "#FFF",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        minHeight: "410px",
        transition: "all 300ms linear",
        borderRadius: "6px",
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            marginRight: "10px",
            marginLeft: "10px"
        }
    },
    title: {
        padding: "25px 0 35px",
        textAlign: "center",
        margin: "0px"
    },
    gridItem: {
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
        "& a": {
            color: "rgba(0, 0, 0, 0.87)",
            "&:hover": {
                textDecoration: "none"
            }
        },
        "& span": {
            display: "block",
            marginTop: "5px",
            marginBottom: "25px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "14px"
            }
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
            paddingLeft: "0px"
        }
    },
    icons: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100px",
        maxHeight: "100px",
        height: "100%",
        width: "100%",
        padding: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 2px 2px 0 rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 1px 5px 0 rgba(" +
            hexToRgb(blackColor) +
            ", 0.2)"
    },
    icon: {
        fontSize: "30px",
        width: "30px",
        height: "auto",
        color: "rgba(0, 0, 0, 0.87)"
    },
    wizard: {
        display: "inline-block",
        width: "100%",
        marginTop: "10px",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        transition: "all 300ms linear",
        minHeight: "410px",
        maxWidth: "100%",
        marginLeft: "0px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "0px",
            paddingLeft: "8px",
            paddingRight: "8px"
        },
        "& .catalog-create-page-holder": {
            padding: "0px",
            "& > div": {
                width: "auto",
                maxWidth: "none",
                flexBasis: "100%",
                padding: "0px",
                "& > div": {
                    padding: "0px",
                }
            },
            "& form": {
                "& > div": {
                    width: "auto",
                    maxWidth: "none",
                    flexBasis: "100%",
                    padding: "0px"
                }
            }
        }
    },
    formCloseButton: {
        position: "absolute",
        right: "-1px",
        zIndex: 1,
        top: "-5px",
        [theme.breakpoints.down("xs")]: {
            top: "0px",
            right: "-1px",
            width: "30px",
            height: "20px",
            padding: "0px",
            minWidth: "0px"
        }
    },
    modalRootCustom: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            top: "16px !important",
        }
    },
    modalWebLink: {
        width: "100%",
        maxWidth: "600px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important"
        }        
    },
    paperScrollPaperCustom: {
        height: "auto",
    },
    customModalWebLink: {
        marginTop: "10px !important",
        [theme.breakpoints.down("xs")]: {
            margin: "8px",
            marginTop: "0px !important",
            marginBottom: "0px !important",
        }
    },
    modalCustomBody: {
        textAlign: "center",
        "& form": {
            textAlign: "left",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0px 8px 8px !important"
        }
    }
});

export default SearchableContentStyle;