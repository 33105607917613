import {
    container,
    main,
    mainRaised,
} from "../material-kit-pro-react.jsx";

const videosStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    container: {
        ...container,
        padding: "0px !important",
        [theme.breakpoints.down("sm")]: {
            "& iframe": {
                maxWidth: "100%"
            }
        },
        [theme.breakpoints.down("xs")]: {
            "& iframe": {
                maxHeight: "250px"
            }
        },
        "@media (max-width: 420px)": {
            "& iframe": {
                maxHeight: "200px"
            }
        }
    },
    videoTitle:{
        marginTop:"20px"
    },
    videoContainer: {
        "& + div": {
            marginTop: theme.spacing(2)
        }
    }
});

export default videosStyle;