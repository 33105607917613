import {
    container,
    main,
    mainRaised,
    grayColor,
    roseColor,
} from "../material-kit-pro-react.jsx";

const faqsStyle = theme => ({
    main: {
        ...main,
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    container: {
        ...container,
        padding: "0px !important"
    },
    videoTitle:{
        marginTop:"20px",
    },
    customAccordionIcon:{
        paddingLeft: "28px",
        '& .MuiExpansionPanel-root':{
            '& .MuiExpansionPanelSummary-root:hover':{
                color: grayColor[1]
            },
        },
        '& .Mui-expanded':{
            color: roseColor[0],
            '& .MuiIconButton-label:before':{
                content:"'\\f057'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
            },
        },
        '& .MuiExpansionPanelSummary-expandIcon':{
            position:"absolute !important",
            left:"-40px !important",
            right: "auto !important",
            top: "15px !important",
            '& .MuiIconButton-label:before':{
                content:"'\\f055'",
                display:"inline-block",
                fontFamily: "'Font Awesome 5 Free'",
                fontWeight: 900,
                MozOsxFontSmoothing: "grayscale",
                WebkitFontSmoothing: "antialiased",
                fontStyle: "normal",
                fontVariant: "normal",
                textRendering: "auto",
                lineHeight: 1
            },
            '& svg':{
                display:"none",
            },
            
        } 
    }
});

export default faqsStyle;