import React from "react";
import axios from 'axios';
import Config from "../../../../../../Config";
import PropTypes from "prop-types";
import { helper } from '../../../../../assets/js/utils/Element'
import Api from '../../../../../assets/js/utils/Api';
import Cookie from '../../../../../assets/js/utils/Cookie';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import Button from "../../../../components/CustomButtons/Button.jsx";
import LoaderComponent from "../../../../components/Loader";
import instagramIcon from "../../../../../assets/img/instagram-platform-icon.png";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import TokenErrorNotice from "./TokenErrorNotice";
import HandleChangeModal from "./HandleChangeModal";
import InformationSafeTooltipModal from "../../../../components/ToolTips/Dashboard/Platforms/Instagram/InformationSafeTooltipModal"

const style = theme => ({
    center: {
        textAlign: "center"
    },
    preview: {
        marginTop: theme.spacing(2)
    },
    previewImg: {
        display: "inline-block",
        marginRight: "10px",
        "& img": {
            width: "100px",
            height: "100px",
            borderRadius: "100%",
            objectFit: "cover",
            [theme.breakpoints.down("xs")]: {
                height: "70px",
                width: "70px",
            }
        }
    },
    previewUsername: {
        display: "inline-block",
    },
    message: {
        marginTop: theme.spacing(2)
    },
    instagramPlatform: {
        display: "inline-block",
        padding: "2px",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1)
        },
        "& a": {
            display: "inline-block"
        },
        "& h4": {
            margin: "0px",
            color: "#fff",
            fontWeight: "bold",
            [theme.breakpoints.down("xs")]: {
                fontSize: "15px"
            }
        },
        "& button": {
            margin: "0px",
            minWidth: "250px",
            [theme.breakpoints.down("xs")]: {
                minWidth: "200px",
            }
        },
        "& div": {
            marginTop: "10px",
            marginBottom: "10px"
        },
        "& img": {
            width: "150px",
            display: "block",
            margin: "0px auto",
            [theme.breakpoints.down("xs")]: {
                width: "120px"
            }
        }
    }
});

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.checkingDetails = false;
        
        this.state = {
            username: "",
            usernameState: "",
            authCode: "",
            instagramAccessToken: null,
            loading: false,
            imageUrl: null,
            platform: this.props.platform,
            instagramPlatform: this.props.instagramPlatform,
            cancelToken: null,
            error: false,
            changePopupShown: false,
            handleChange: false,
            newUsername: "",
            tooltips: {
                informationSafe: false
            },
            instagramRequest: false
        };

        this.onClose = this.onClose.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onYes = this.onYes.bind(this);
        this.onTryAgain = this.onTryAgain.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    componentDidMount(){
        const that = this;
        const authCode = helper.getParam("code");
        const { platform, instagramPlatform } = this.state;
        setTimeout(function(){
            that.props.onMount(that.state);
            if(platform === null && instagramPlatform === null){
                that.props.onIsValidChange(true);
            }
        },100);
        if(authCode && authCode !== "null"){
            this.setState({ 
                authCode: authCode
            });
        }else{
            const { instagramPlatform } = this.state;
            this.checkPlatformToken(instagramPlatform);
        }

        const username = this.getUsername();
        if(username !== null){
            this.getInstagramUserImage(username);
        }
    }
    componentDidUpdate(prevProps, prevState){
        const { platform, instagramPlatform, imageUrl } = this.state;
        if(platform !== prevState.platform){
            if(platform === null && instagramPlatform === null){
                this.props.onIsValidChange(true);
            }else{
                this.props.onIsValidChange(false);
            }
        }
        const username = this.getUsername();
        if(username !== null && imageUrl === null){
            this.getInstagramUserImage(username);
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    isTokenExpired(platform){
        if(!platform.hasOwnProperty("error")){
            return false;
        }
        if(platform.error === true){
            return true;
        }
        return false;
    }
    componentWillReceiveProps(props){
        this.setState({
            platform: props.platform,
            loading: props.loading,
        });

        const { instagramPlatform, changePopupShown } = this.state;
        if(changePopupShown){
            return;
        }
        const { platform } = props;
        if(instagramPlatform === null || platform === null || typeof(instagramPlatform) !== "object" || typeof(platform) !== "object"){
            return;
        }
        if(!platform.hasOwnProperty("pendingData") || !platform.pendingData.hasOwnProperty("instagramHandle")){
            return;
        }
        if(instagramPlatform.instagramHandle !== platform.pendingData.instagramHandle){
            this.setState({
                changePopupShown: true,
                handleChange: true,
            })
        }
    }
    checkPlatformToken(platform){
        if(platform === null || typeof(platform) !== "object"){
            return;
        }
        if(this.isTokenExpired(platform)){
            this.setState({
                error: true,
            });
        }
    }
    // getInstagramAccessToken(authCode){
    //     const { cancelToken, platform, instagramAccessToken } = this.state;
    //     if(cancelToken){
    //         cancelToken.cancel('Request Cancelled');
    //     }
    //     var requestData = {};
    //     requestData.code = authCode;
    //     const source = axios.CancelToken.source();
    //     this.setState({loading: true, cancelToken: source});
    //     Api.getInstagramAccessToken(requestData, source).then((data) => {
    //         this.setState({instagramAccessToken: data.instagramAccessToken, loading: false});
    //         this.getInstagramDetails(data.instagramAccessToken);
    //     }).catch(err => {
    //         if(err && err.message !== "Request Cancelled"){
    //             this.setState({loading: false});
    //             if(instagramAccessToken === null && platform !== null){
    //                 this.checkPlatformToken(platform);
    //             }
    //         }
    //     });
    // }
    // getInstagramDetails(instagramAccessToken){
    //     var requestData = {
    //         'endpoint': 'me',
    //         'fields': 'username',
    //         'instagram_access_token': instagramAccessToken
    //     };
    //     this.setState({loading: true});
    //     Api.getInstagramDetails(requestData).then((data) => {
    //         if(this.state.platform && this.state.platform.instagramHandle !== "" && data.username !== "" && data.username !== this.state.platform.instagramHandle){
    //             this.setState({
    //                 handleChange: true,
    //                 newUsername: data.username,
    //             })
    //             return;
    //         }
    //         this.setState({username: data.username, usernameState: "success"});
    //         this.getInstagramUserImage(data.username);
    //     }).catch(err => {
    //         this.setState({loading: false, error: true});
    //     });
    // }
    getInstagramUserImage(username){
        if(this.state.instagramRequest){
            return;
        }
        var url = "https://www.instagram.com/"+username+"/";
        helper.scrapeUrl(url).then(data => {
            let state = {
                loading: false,
                instagramRequest: true
            }
            if(data.hasOwnProperty("imageUrl")){
                state['imageUrl'] = data.imageUrl;
            }
            this.setState(state);
        }).catch(err => {
            this.setState({loading: false, instagramRequest: true});
        });
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        if(this.state.loading || this.state.platform === null){
            return false;
        }
        return true;
    }
    onSuccess(){
        this.setState({error: false});
        const authUrl = Config.getInstagramAuthUrl();
        window.location.href = authUrl;
    }
    onClose(){
        this.setState({
            error: false,
            instagramAccessToken: null,
            loading: false
        });
    }
    onYes(){
        this.setState({
            handleChange: false,
            username: this.state.newUsername,
            usernameState: "success",
            loading: true
        });
    }
    onTryAgain(){
        this.setState({handleChange: false});
        const { platform } = this.state;
        if(platform !== null){
            Cookie.write("platform_id", platform.contentPlatformId);
        }
        const authUrl = Config.getInstagramAuthUrl();
        window.location.href = authUrl;
    }
    onCancel(){
        this.setState({
            handleChange: false,
            instagramAccessToken: null
        });

        const { instagramPlatform } = this.state;
        if(instagramPlatform !== null){
            const { user } = this.store.getState();
            const source = axios.CancelToken.source();
            instagramPlatform.status = "Reset Platform";
            this.setState({platform: instagramPlatform});
            Api.updatePlatform(instagramPlatform, source, user.userId).then((data) => { 
                if(data.numberOfResponses > 0){
                    this.setState({platform: data.response[0]});
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    getUsername(){
        const { instagramPlatform, platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("instagramHandle")){
            return platform.pendingData.instagramHandle;
        }
        if(instagramPlatform !== null && instagramPlatform.hasOwnProperty("pendingData") && instagramPlatform.pendingData.hasOwnProperty("instagramHandle")){
            return instagramPlatform.pendingData.instagramHandle;
        }
        if(instagramPlatform !== null && instagramPlatform.hasOwnProperty("instagramHandle")){
            return instagramPlatform.instagramHandle;
        }
        return null;
    }
    render() {
        const { loading, imageUrl, error, handleChange, tooltips, platform, instagramPlatform, instagramRequest } = this.state;
        const authUrl = Config.getInstagramAuthUrl();
        const { classes } = this.props;
        const username = this.getUsername();
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10} className={classes.center}>
                        {
                            loading === true ?
                                <LoaderComponent align="center" />
                            :
                                platform === null && instagramPlatform === null ?
                                    <div className={classes.instagramPlatform}>
                                        <img src={instagramIcon} className="instagram-icon" alt="instagram"/>
                                        <div>
                                            <Link href={authUrl} underline="none">
                                                <Button color="blue">
                                                    <h4>Click to Link</h4>
                                                </Button>
                                            </Link>
                                        </div>
                                        <Link underline="always" href="/" onClick={(e) => {e.preventDefault(); this.onTooltip("informationSafe", true) }}>
                                            Your Information is Safe
                                        </Link>
                                    </div>
                                :
                                    username && username.length > 0 ?
                                        <div className={classes.preview}>
                                            <div>
                                                <span className={classes.previewImg}>
                                                    {
                                                        imageUrl !== null ?
                                                            <img src={imageUrl} alt={username} />
                                                        :
                                                            instagramRequest === false ?
                                                                <LoaderComponent align="center" />
                                                            :
                                                            <></>
                                                    }
                                                </span>
                                                <span className={classes.previewUsername}>{username}</span>
                                            </div>
                                            <p className={classes.message}>We have identified your Instagram account! Click the Next button to finish the process.</p>
                                            <Link href={authUrl}>
                                                Wrong Account?
                                            </Link>
                                        </div>
                                    :
                                    <></>                                
                        }
                    </GridItem>
                </GridContainer>
                <TokenErrorNotice open={error} onSuccess={this.onSuccess} onClose={this.onClose} />
                {
                    handleChange && platform !== null && instagramPlatform.instagramHandle ?
                        <HandleChangeModal open={handleChange} previousUsername={instagramPlatform.instagramHandle} newUsername={platform.pendingData.instagramHandle} onYes={this.onYes} onTryAgain={this.onTryAgain} onCancel={this.onCancel} />
                    :
                        <></>
                }
                {
                    tooltips.informationSafe ?
                        <InformationSafeTooltipModal open={tooltips.informationSafe} store={this.store} onClose={() => this.onTooltip('informationSafe', false)} />
                    :
                    <></>
                }
            </div>
        );
    }
}

Step1.defaultProps = {
    onMount: function(){ },
    platform: null,
    instagramPlatform: null
}
Step1.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func,
  platform: PropTypes.object,
  instagramPlatform: PropTypes.object
};

export default withStyles(style)(Step1);
