import {
    container,
    main,
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "../tabs/dashboardSubTabs";

const dashboardTabHelpStyle = theme => ({
    main,
    container: {
        ...container,
        padding: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginRight: "15px",
            marginLeft: "15px",
            maxWidth: "100%",
            width: "auto"
        },
        "& .navPillsContentWrapper": {
            marginTop: "0px",
        },
        "& .navPillsTabContent": {
            paddingLeft: "2px !important",
            paddingRight: "0px !important"
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "calc(100% - 20px)"
        }
    },
    ...dashboardSubTabs(theme),
});

export default dashboardTabHelpStyle;