import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = theme => ({
    ...customCheckboxRadioSwitch,
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    },
});

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.state = {
            linktreeButtons: true
        };
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.props.onMount(that.state);
        },100);
    }
    sendState() {
        return this.state;
    }
    handleToggle(name) {
        this.setState({[name]: !this.state[name]});
    };

    render() {
        const { classes } = this.props;
        const { linktreeButtons } = this.state;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={linktreeButtons}
                                    onChange={ (e) => this.handleToggle("linktreeButtons")}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                                }
                                classes={{ label: classes.label, root: classes.labelRoot }}
                                label={(linktreeButtons ? "Create linktr.ee links as buttons" : "Create linktr.ee links as web links")}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Step2.defaultProps = {
    onMount: function(){ }
}
Step2.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step2);
