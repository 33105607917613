import React from 'react'
import { Route, Switch } from "react-router-dom";
import Home from '../pages/Home';
// import Searchmysocial from '../pages/Searchmysocial';
// import FeatureComparison from '../pages/FeatureComparison'
// import Profile from '../pages/Profile';
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import SignupVerification from "../pages/auth/SignupVerification";
import Logout from './Logout';
import UserEdit from '../pages/user/Edit';
import CatalogHome from '../pages/catalog/Home';
import CatalogCreate from '../pages/catalog/Create';
import CatalogEdit from '../pages/catalog/Edit';
import CatalogView from '../pages/catalog/View';
// import UserSearch from '../pages/user/Search';
import UserLocation from "../pages/user/Location";
// import FullScreenMap from "../pages/maps/FullScreenMap";
// import GoogleMaps from "../pages/maps/GoogleMaps";
// import Charts from "../pages/charts/Charts";
// import Dashboard from "../pages/dashboard/Dashboard.jsx";
// import PaymentMethod from "../pages/payment/Method";
import UserOnboarding from "../pages/user/Onboarding";
// import Terms from "../pages/help/Terms";
import CirclesHome from "../pages/circles/Home";
import DashboardHome from "../pages/dashboard/Home";
// import Faqs from "../pages/help/Faqs";
// import Videos from "../pages/help/Videos";
// import Tour from "../pages/help/Tour";
// import Privacy from "../pages/help/Privacy";
// import Contact from "../pages/help/Contact";
import PrivacyCenter from "../pages/help/PrivacyCenter";
import HelpHome from "../pages/help/Home";
import Euconsent from "../pages/help/Euconsent"
import NotFound from './NotFound';
// import LinkCreate from "../pages/dashboard/platforms/contents/types/LinkCreate";

const RouterComponent = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.state = {
            mobileOpen: false,
            miniActive: false,
            image: require("../../assets/img/sidebar-2.jpg"),
            color: "blue",
            bgColor: "black",
            hasImage: true,
            fixedClasses: "dropdown",
            logo: require("../../assets/img/logo-white.svg")
        };
    }
    handleImageClick = image => {
        this.setState({ image: image });
      };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleBgColorClick = bgColor => {
        switch (bgColor) {
          case "white":
            this.setState({ logo: require("../../assets/img/logo.svg") });
            break;
          default:
            this.setState({ logo: require("../../assets/img/logo-white.svg") });
            break;
        }
        this.setState({ bgColor: bgColor });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
          this.setState({ fixedClasses: "dropdown show" });
        } else {
          this.setState({ fixedClasses: "dropdown" });
        }
    };
    sidebarMinimize = () => {
        this.setState({ miniActive: !this.state.miniActive });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
          this.setState({ mobileOpen: false });
        }
    };
    render() {
        const { history } = this.props;
        return (
            <div id="global_content">
                <Switch>
                    <Route exact path="/" component={() => <Login store={this.store} history={this.history} />} />
                    <Route exact path="/home" component={() => <Login store={this.store} history={this.history} />} />
                    {/* <Route exact path="/feature-comparison" component={() => <FeatureComparison store={this.store} history={this.history} />} /> */}
                    {/* <Route exact path="/user/home" component={() => <Home store={this.store} history={this.history} />} /> */}
                    {/* <Route exact path="/profile" component={() => <Profile store={this.store} />} /> */}
                    <Route exact path="/login" component={() => <Login store={this.store} history={this.history} />} />
                    <Route exact path="/signup" component={() => <Signup store={this.store} history={this.history} />} />
                    <Route exact path="/signup/verification" component={({match}) => <SignupVerification match={match} store={this.store} history={this.history} />} />
                    {/* <Route exact path="/signup/verification/:email" component={({match}) => <SignupVerification match={match} store={this.store} history={this.history} />} />
                    <Route exact path="/signup/verification/:email/:code" component={({match}) => <SignupVerification match={match} store={this.store} history={this.history} />} /> */}
                    <Route exact path="/user/onboarding" component={() => <UserOnboarding store={this.store} history={this.props.history} />} />
                    <Route exact path="/help" component={ ({match}) => <HelpHome store={this.store} subTab={0} match={match} history={history} />} />
                    <Route exact path="/help/terms" component={({match}) => <HelpHome store={this.store} subTab={0} match={match} history={history} />} />
                    <Route exact path="/help/faqs" component={({match}) => <HelpHome store={this.store} subTab={3} match={match} history={history} />} />
                    <Route exact path="/help/cookies" component={({match}) => <HelpHome store={this.store} subTab={2} match={match} history={history} />} />
                    <Route exact path="/help/privacy/privacy-center" component={({match}) => <PrivacyCenter store={this.store} subTab={0} match={match} history={history} />} />
                    <Route exact path="/help/privacy" component={({match}) => <HelpHome store={this.store} subTab={1} match={match} history={history} />} />
                    <Route exact path="/help/contact" component={({match}) => <HelpHome store={this.store} subTab={4} match={match} history={history} />} />
                    <Route exact path="/help/videos" component={({match}) => <HelpHome store={this.store} subTab={5} match={match} history={history} />} />
                    <Route exact path="/help/euconsent" component={({match}) => <Euconsent store={this.store} match={match} history={history} />} />

                    <Route exact path="/logout" component={ () => <Logout store={this.store} />} />
                    <Route exact path="/circles" component={ () => <CirclesHome store={this.store} />} />
                    
                    <Route exact path="/dashboard/editmode/search" component={ ({match}) => <DashboardHome store={this.store} tab={1} subTab={0} match={match} history={history} search={1} />} />
                    <Route exact path="/dashboard/editmode/search/about" component={ ({match}) => <DashboardHome store={this.store} tab={1} subTab={0} match={match} history={history} search={0} />} />
                    <Route exact path="/dashboard/editmode/search/:contentPlatformId" component={ ({match}) => <DashboardHome store={this.store} tab={1} subTab={0} match={match} history={history} search={1} />} />
                    <Route exact path="/dashboard/profile/info" component={ ({match}) => <DashboardHome store={this.store} tab={2} subTab={0} match={match} history={history} />} />
                    <Route exact path="/dashboard/profile/styling" component={ ({match}) => <DashboardHome store={this.store} tab={2} subTab={3} match={match} history={history} />} />
                    <Route exact path="/dashboard/profile/about" component={ ({match}) => <DashboardHome store={this.store} tab={2} subTab={2} match={match} history={history} />} />
                    <Route exact path="/dashboard/profile/home" component={ ({match}) => <DashboardHome store={this.store} tab={2} subTab={1} match={match} history={history} />} />
                    <Route exact path="/dashboard/profile" component={ ({match}) => <DashboardHome store={this.store} tab={2} subTab={0} match={match} history={history} />} />

                    <Route exact path="/dashboard/admin" component={ ({match}) => <DashboardHome store={this.store} tab={3} subTab={0} match={match} history={history} />} />
                    <Route exact path="/dashboard/admin/login" component={ ({match}) => <DashboardHome store={this.store} tab={3} subTab={0} match={match} history={history} />} />
                    <Route exact path="/dashboard/admin/email" component={ ({match}) => <DashboardHome store={this.store} tab={3} subTab={1} match={match} history={history} />} />

                    <Route exact path="/dashboard/platforms" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/platform/:contentPlatformId/:contentId" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/platform/:contentPlatformId" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/platform/:contentPlatformId" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/platformwizard/:type" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/platformwizard/:type/:contentPlatformId" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />
                    <Route exact path="/dashboard/analytics" component={ ({match}) => <DashboardHome store={this.store} tab={5} match={match} history={history} />} />
                    <Route exact path="/dashboard/circles" component={ ({match}) => <DashboardHome store={this.store} tab={6} match={match} history={history} />} />
                    {/* <Route exact path="/dashboard/robots" component={ ({match}) => <DashboardHome store={this.store} tab={7} match={match} history={history} />} /> */}
                    
                    <Route exact path="/dashboard/platform/:contentPlatformId/:type/:contentType" component={ ({match}) => <DashboardHome store={this.store} tab={4} match={match} history={history} />} />

                    <Route exact path="/dashboard/analytics/basic" component={ ({match}) => <DashboardHome store={this.store} tab={5} subTab={0} match={match} history={history} />} />

                    <Route exact path="/dashboard/help/faqs" component={ ({match}) => <DashboardHome store={this.store} tab={10} subTab={0} match={match} history={history} />} />                    
                    <Route exact path="/dashboard/help/videos" component={ ({match}) => <DashboardHome store={this.store} tab={10} subTab={1} match={match}  history={history} />} />                    
                    <Route exact path="/dashboard/help/contact" component={ ({match}) => <DashboardHome store={this.store} tab={10} subTab={2} match={match} history={history} />} />                    
                    <Route exact path="/dashboard/help/tours" component={ ({match}) => <DashboardHome store={this.store} tab={10} subTab={3} match={match} history={history} />} />                    
                    
                    <Route exact path="/dashboard/account/settings" component={ ({match}) => <DashboardHome store={this.store} tab={8} subTab={0} match={match} history={history} />} />
                    <Route exact path="/dashboard/account/payments" component={ ({match}) => <DashboardHome store={this.store} tab={8} subTab={1} match={match} history={history} />} />
                    <Route exact path="/dashboard/account/upgrade" component={ ({match}) => <DashboardHome store={this.store} tab={8} subTab={0} upgrade={1} match={match} history={history} />} />
                    <Route exact path="/dashboard/account" component={ ({match}) => <DashboardHome store={this.store} tab={8} subTab={0} match={match} history={history} />} />
                    
                    <Route exact path="/dashboard/settings/smearch" component={ ({match}) => <DashboardHome store={this.store} tab={9} subTab={0} match={match} history={history} />} />
                    <Route exact path="/dashboard/settings" component={ ({match}) => <DashboardHome store={this.store} tab={9} match={match} history={history} />} />                    
                    <Route exact path="/dashboard/help" component={ ({match}) => <DashboardHome store={this.store} tab={10} match={match} history={history} />} />
                    <Route exact path="/dashboard" component={ ({match}) => <DashboardHome store={this.store} tab={0} match={match} history={history} />} />
                    <Route exact path="/profile/edit" component={() => <UserEdit store={this.store} />} />
                    <Route exact path="/profile/location" component={() => <UserLocation store={this.store} />} />
                    <Route exact path="/profile/:userId/catalog" component={({match}) => <CatalogHome store={this.store} match={match} />} />
                    <Route exact path="/profile/:userId/catalog/new" component={({match}) => <CatalogCreate store={this.store} match={match} />} />
                    {/* <Route exact path="/charts" component={({match}) => <Charts store={this.store} match={match} />} /> */}
                    {/* <Route exact path="/google-maps" component={({match}) => <GoogleMaps store={this.store} match={match} />} />
                    <Route exact path="/full-screen-maps" component={({match}) => <FullScreenMap store={this.store} match={match} />} />
                    <Route exact path="/default-dashboard" component={({match}) => <Dashboard store={this.store} match={match} />} /> */}
                    {/* <Route exact path="/profile/paymentmethod" component={({match}) => <PaymentMethod store={this.store} match={match} />} /> */}
                    
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/:utm_campaign/utm_term/:utm_term/utm_content/:utm_content" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/:utm_campaign/utm_term/:utm_term/utm_content/" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/:utm_campaign/utm_term/:utm_term" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/:utm_campaign/utm_term/" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/:utm_campaign" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium/utm_campaign/" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/:utm_medium" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source/utm_medium/" component={({match}) => <Home store={this.store} match={match} />} />
                    <Route exact path="/utm_source/:utm_source" component={({match}) => <Home store={this.store} match={match} />} />

                    <Route exact path="/:contentId/edit" component={({match}) => <CatalogEdit store={this.store} match={match} />} />
                    <Route exact path="/c/:contentId" component={({match}) => <CatalogView store={this.store} match={match} />} />
                    {/* <Route exact path="/:username" component={({match}) => <UserSearch store={this.store} match={match} slide="0" history={history} />} />
                    <Route exact path="/:username/about" component={({match}) => <UserSearch store={this.store} match={match} slide="1" history={history}  />} />
                    <Route exact path="/:username/:contentId" component={({match}) => <UserSearch store={this.store} match={match} slide="0" history={history} />} /> */}
                    <Route path="*" exact={true} component={NotFound} />
                </Switch>                
            </div>
        )
    }
}

export default RouterComponent
