
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const contactConfirmationModalStyle = theme => ({
    ...modalStyle(theme),
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
    modalTitle:{
        textAlign:"center"
    }
});

export default contactConfirmationModalStyle;
