import React from 'react'

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';

import MaterialIcon from "@mdi/react";
import { mdiFoodVariant, mdiPodcast } from '@mdi/js';

import StarHalfIcon from '@material-ui/icons/StarHalf';
import PlacesIcon from '@material-ui/icons/LocationOn';
import { ReactComponent as ShoppingBagIcon } from "../../assets/icons/shopping_bag.svg";
import { ReactComponent as GuidesIcon } from "../../assets/icons/guides.svg";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkIcon from "@material-ui/icons/Link";
import InstagramIcon from '@material-ui/icons/Instagram';
import tiktokIcon from "../../assets/img/tiktok-icon.png";
import tiktokIconWhite from "../../assets/img/tiktok-icon-white.png";
import instagramTvIcon from "../../assets/img/instagram-tv-icon.png";
import instagramTvIconWhite from "../../assets/img/instagram-tv-icon-white.png";
import instagramVideoIcon from "../../assets/img/instagram-video-icon.png";
import instagramVideoIconWhite from "../../assets/img/instagram-video-icon-white.png";
import instagramReelsIcon from "../../assets/img/instagram-reels-icon.png";
import instagramReelsIconWhite from "../../assets/img/instagram-reels-icon-white.png";

import contentTypeIconStyle from "../../assets/jss/contentTypeIconStyle.jsx";

class ContentTypeIcon extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            user: this.props.user,
            contentType: this.props.contentType
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            user: props.user,
            contentType: props.contentType
        });
    }
    renderTypeIcon(){
        const { classes } = this.props;
        const { theme } = this.state.user.guestPreferences;
        const { palette } = this.props.theme;

        let colorStyle = {
            color: palette.custom.iconColor,
            fill: palette.custom.iconColor
        }

        let icon = null;
        switch(this.state.contentType){
            case "instagram_post":
                icon = <div className={classes.icons}>
                        <InstagramIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "link":
                icon = <div className={classes.icons}>
                        <LinkIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "recommendation":
                icon = <div className={classes.icons}>
                        <StarHalfIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "recipe":
                icon = <div className={classes.icons}>
                        <MaterialIcon path={mdiFoodVariant} className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "place":
                icon = <div className={classes.icons}>
                        <PlacesIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "photo":
                icon = <div className={classes.icons}>
                        <CameraAltOutlinedIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "shopping_items":
                icon = <div className={classes.icons}>
                        <ShoppingBagIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "workout":
                icon = <div className={classes.icons}>
                        <PoolOutlinedIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "youtube":
                icon = <div className={classes.icons}>
                        <YouTubeIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "tiktok":
                icon = <div className={classes.icons}>
                        <img src={(theme === "dark" ? tiktokIcon : tiktokIconWhite)} alt="tiktok" className={classes.icon+" icon"} style={{width: "100%"}} />
                    </div>
                break;
            case "podcast":
                icon = <div className={classes.icons}>
                        <MaterialIcon path={mdiPodcast} className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            case "instagram_reel":
                icon = <div className={classes.icons}>
                        <img src={(theme === "dark" ? instagramReelsIcon : instagramReelsIconWhite)} alt="instagram reels" className={classes.icon+" icon"} style={{width: "100%"}} />
                    </div>
                break;
            case "instagram_tv":
                icon = <div className={classes.icons}>
                        <img src={(theme === "dark" ? instagramTvIcon : instagramTvIconWhite)} alt="instagram tv" className={classes.icon+" icon"} style={{width: "100%"}} />
                    </div>
                break;
            case "instagram_video":
                icon = <div className={classes.icons}>
                        <img src={(theme === "dark" ? instagramVideoIcon : instagramVideoIconWhite)} alt="instagram Video" className={classes.icon+" icon"} style={{width: "100%"}} />
                    </div>
                break;
            case "instagram_guide":
                icon = <div className={classes.icons}>
                        <GuidesIcon className={classes.icon+" icon"} style={colorStyle} />
                    </div>
                break;
            default:
                break;
        }
        return icon;
    }
    render(){
        return (
            this.renderTypeIcon()
        );
    }
}

ContentTypeIcon.defaultProps = {
}
ContentTypeIcon.propTypes = {
    classes: PropTypes.object,
};
  
export default withTheme(withStyles(contentTypeIconStyle)(ContentTypeIcon));