import {
    
} from "../../material-kit-pro-react.jsx";

const dashboardSubTabs = theme => ({
    tabs: {
        "& .MuiTabs-root": {
            marginTop: "0px"
        },
        "& .MuiTab-root": {
            minWidth: "0px",
            float: "left !important",
            width: "50px",
            height: "50px",
            margin: "0px 5px",
            [theme.breakpoints.down("xs")]: {
                width: "40px",
                height: "40px"
            },
            "& .MuiTab-wrapper": {
                height: "100%",
            },
            "& svg": {
                margin: "0px !important",
                width: "24px",
                height: "24px",
            },
        },
        "& .showLabels": {
            "& .MuiTab-root": {
                fontSize: "8px",
                width: "55px",
                height: "55px",
                paddingBottom: "5px",
                paddingTop: "5px",
            }
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "flex-start",
            display: "flex",
        },
        "& > .MuiGrid-container": {
            marginLeft: "0px",
            marginRight: "0px",
            "& > .MuiGrid-item": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        }
    }
});

export default dashboardSubTabs;