import React from 'react'
import { Redirect } from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Api from '../../../assets/js/utils/Api'
import LoaderComponent from '../../components/Loader'
import { helper } from '../../../assets/js/utils/Element'
import axios from 'axios';

import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ImageUpload from "../../components/CustomUpload/ImageUpload.jsx";

import editPageStyle from "../../../assets/jss/user/editPageStyle";

class UserEdit extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        let photo = null;
        let avatarMediaId = null;
        if(values.hasOwnProperty("avatarMedia")){
            photo = values.avatarMedia.downloadUrlProfile;
            avatarMediaId = values.avatarMedia.mediaId;
        }

        this.state = {
            values: values,
            validation: {
                firstName: '',
                lastName: '',
            },
            photo: photo,
            avatarMediaId: avatarMediaId,
            redirect: false,
            saving: false,
            user: user
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handlePrefChange = this.handlePrefChange.bind(this);
        this.handleChangePhoto = this.handleChangePhoto.bind(this);
    }
    
    handleChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        const { values } = this.state;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(values[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "https://"+value;
            }
        }
        if(value === "http://" || value === "https://"){
            value = "";
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                [name]: value
            }
        });
    }
    handlePrefChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                userPreferences: {
                    ...this.state.values.userPreferences,
                    [name]: event.target.checked
                }                
            }
        });
    }
    handlePrefViewOptionChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                userPreferences: {
                    ...this.state.values.userPreferences,
                    [name]: event.target.value
                }                
            }
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.saving){
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            return;
        }

        const { user } = this.store.getState();
        this.setState({ ...this.state, redirect: false, saving: true});
        Api.updateUser(user.userId, this.state.values).then((data) => {
            if(data.numberOfResponses > 0){
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0], redirect: true }});
            }
            this.goBack();
        }).catch(err => {
            console.log(err);
        });
    }
    validateForm(){
        let validation = {
            firstName: 'success',
            title: 'success',
            isValid: true
        };

        const { values } = this.state;
        if(values.firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(values.lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        this.setState({ ...this.state, validation: validation});
        return validation.isValid;
    }
    handleChangePhoto(params){
        if(params.file === null){
            this.deletePhoto();
            return;
        }
        this.setState({photo: params.file});
        this.uploadPhoto(params.file);
    }
    uploadPhoto(file){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
            isAvatar: true
        };
        Api.createMedia(file, requestData, source).then((data) => {
            that.setState({
                cancelToken: null, 
                avatarMediaId: data.mediaId,
                saving: false
            })
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    deletePhoto(){
        const avatarMediaId = this.state.avatarMediaId;
        if(!avatarMediaId || avatarMediaId.length <= 0){
            this.setState({photo: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, photo: null, saving: true});
        Api.deleteMedia(avatarMediaId, source).then((data) => {
            that.setState({
                cancelToken: null,
                avatarMediaId: null,
                saving: false
            })
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    goBack(){
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/profile"} />
        }
    }
    
    render(){
        const { classes } = this.props;
        const { user, authorized } = this.store.getState();
        const { values, validation, photo } = this.state;
        let youtubeChannelUrl = values.youtubeChannelUrl;
        if(youtubeChannelUrl.length <= 0){
            youtubeChannelUrl = "https://";
        }
        let blogUrl = values.blogUrl;
        if(blogUrl.length <= 0){
            blogUrl = "https://";
        }
        return (
            <Container className="container profile-edit-page-holder">
                <Grid item className={classes.container} xs={12} md={8} lg={8}>
                    <GridItem>
                        <h3 className={classes.title}>
                            Profile Page
                        </h3>
                    </GridItem>
                    {
                        authorized ?
                            <form onSubmit={this.handleSubmit} noValidate>
                                {
                                    (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") ?
                                        <GridItem xs={12} sm={8} md={8} lg={6}>
                                            <CustomInput
                                                id="outlined-userId"
                                                labelText="User Id"
                                                inputProps={{
                                                    value: user.userId,
                                                    disabled: true
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>
                                    :
                                        <></>
                                }
                                
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.firstName === "success"}
                                        error={validation.firstName === "error"}
                                        id="outlined-firstName"
                                        labelText="First Name"                                    
                                        inputProps={{
                                            value: values.firstName,
                                            required: true,
                                            onChange: (e) => this.handleChange(e, 'firstName'),
                                            name: "firstName"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.lastName === "success"}
                                        error={validation.lastName === "error"}
                                        id="outlined-lastName"
                                        labelText="Last Name"                                    
                                        inputProps={{
                                            value: values.lastName,
                                            required: true,
                                            onChange: (e) => this.handleChange(e, 'lastName'),
                                            name: "lastName"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>                                
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        id="outlined-username"
                                        labelText="Username"                                    
                                        inputProps={{
                                            value: values.username,
                                            required: true,
                                            onChange: (e) => this.handleChange(e, 'username'),
                                            name: "username"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl
                                        }}
                                    />                                
                                </GridItem>
                                <p className={classes.socialLinkPreview}>
                                    {document.location.origin+"/"+values.username}
                                </p>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <ImageUpload
                                        onChange={this.handleChangePhoto}
                                        file={photo}
                                        addButtonProps={{ round: true }}
                                        changeButtonProps={{ round: true }}
                                        removeButtonProps={{ round: true, color: "danger" }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                            id="outlined-bio"
                                            labelText="Bio"                                    
                                            inputProps={{
                                                value: values.bio,
                                                onChange: (e) => this.handleChange(e, 'bio'),
                                                name: "bio",
                                                multiline: true,
                                                rows: 4
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="instagram" justIcon><i className="fab fa-instagram" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-instagramUsername"
                                                labelText="Instagram Handle"                                    
                                                inputProps={{
                                                    value: values.instagramUsername,
                                                    onChange: (e) => this.handleChange(e, 'instagramUsername'),
                                                    name: "instagramUsername"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />                                        
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {helper.prepareSocialUrl("instagram",values.instagramUsername)}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-twitterUsername"
                                                labelText="Twitter Username"                                    
                                                inputProps={{
                                                    value: values.twitterUsername,
                                                    onChange: (e) => this.handleChange(e, 'twitterUsername'),
                                                    name: "twitterUsername"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {helper.prepareSocialUrl("twitter",values.twitterUsername)}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-youtubeChannelUrl"
                                                labelText="Youtube Channel Url"                                    
                                                inputProps={{
                                                    value: values.youtubeChannelUrl,
                                                    onChange: (e) => this.handleLinkChange(e, 'youtubeChannelUrl'),
                                                    name: "youtubeChannelUrl"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {youtubeChannelUrl}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="facebook" justIcon><i className="fab fa-facebook-square" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-facebookUsername"
                                                labelText="Facebook Username"                                    
                                                inputProps={{
                                                    value: values.facebookUsername,
                                                    onChange: (e) => this.handleChange(e, 'facebookUsername'),
                                                    name: "facebookUsername"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {helper.prepareSocialUrl("facebook",values.facebookUsername)}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="pinterest" justIcon><i className="fab fa-pinterest" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-pinterestUsername"
                                                labelText="Pinterest Username"                                    
                                                inputProps={{
                                                    value: values.pinterestUsername,
                                                    onChange: (e) => this.handleChange(e, 'pinterestUsername'),
                                                    name: "pinterestUsername"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {helper.prepareSocialUrl("pinterest",values.pinterestUsername)}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="linkedin" justIcon><i className="fab fa-linkedin" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-linkedinUsername"
                                                labelText="LInkedIn Username"                                    
                                                inputProps={{
                                                    value: values.linkedinUsername,
                                                    onChange: (e) => this.handleChange(e, 'linkedinUsername'),
                                                    name: "linkedinUsername"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {helper.prepareSocialUrl("linkedin",values.linkedinUsername)}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="pinterest" justIcon><i className="fas fa-envelope" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-contactEmail"
                                                labelText="Contact Email"                                    
                                                inputProps={{
                                                    value: values.contactEmail,
                                                    onChange: (e) => this.handleChange(e, 'contactEmail'),
                                                    name: "contactEmail",
                                                    type:"email",
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {"mailto:"+values.contactEmail}
                                        </p>
                                    </GridContainer>
                                    <GridContainer justify="flex-start" alignItems="flex-end">
                                        <GridItem className={classes.socialGridItem}>
                                            <Button color="transparent" justIcon className={classes.iconButton}><i className="fas fa-file-signature" /></Button>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                                            <CustomInput
                                                id="outlined-blogUrl"
                                                labelText="Blog Url"                                    
                                                inputProps={{
                                                    value: values.blogUrl,
                                                    onChange: (e) => this.handleLinkChange(e, 'blogUrl'),
                                                    name: "blogUrl"
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.formControl
                                                }}
                                            />
                                        </GridItem>
                                        <p className={classes.socialLinkPreview}>
                                            {blogUrl}
                                        </p>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showSidebarControl}
                                            onChange={(e) => this.handlePrefChange(e, "showSidebarControl")}
                                            value="1"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Sidebar Control"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.userPreferences.showBetaFeatures}
                                            onChange={(e) => this.handlePrefChange(e, "showBetaFeatures")}
                                            value="1"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Show Beta Features"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <div className={classes.inputLabel}>
                                        <label>Filter Options</label>
                                    </div>
                                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={values.userPreferences.searchFilterLinks}
                                                onChange={(e) => this.handlePrefChange(e, "searchFilterLinks")}
                                                value="1"
                                                name="searchFilterResults"
                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Links"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={values.userPreferences.searchFilterLists}
                                                onChange={(e) => this.handlePrefChange(e, "searchFilterLists")}
                                                value="1"
                                                name="searchFilterResults"
                                                icon={<Check className={classes.uncheckedIcon}/>}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Lists"
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <div className={classes.inputLabel}>
                                        <label>View Options</label>
                                    </div>
                                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                            <Radio
                                                checked={values.userPreferences.searchViewResults === "stream"}
                                                onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                                value="stream"
                                                name="searchViewResults"
                                                aria-label="Stream View"
                                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                            }
                                            classes={{label: classes.label, root: classes.labelRoot}}
                                            label="Stream View"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                            <Radio
                                                checked={values.userPreferences.searchViewResults === "list"}
                                                onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                                value="list"
                                                name="searchViewResults"
                                                aria-label="List View"
                                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                            }
                                            classes={{label: classes.label, root: classes.labelRoot}}
                                            label="List View"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                            <Radio
                                                checked={values.userPreferences.searchViewResults === "grid"}
                                                onChange={(e) => this.handlePrefViewOptionChange(e, "searchViewResults")}
                                                value="grid"
                                                name="searchViewResults"
                                                aria-label="Grid View"
                                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                            }
                                            classes={{label: classes.label, root: classes.labelRoot}}
                                            label="Grid View"
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={6} lg={6} className={classes.buttonsGridContainer}>
                                    <Button type="submit" color="primary" round>
                                        <SaveIcon className={classes.icons} /> Save
                                    </Button>
                                    <Button type="button" color="primary" round onClick={this.goBack} className={classes.buttonSpacing}>
                                        <ArrowBackIcon className={classes.icons} /> Cancel
                                    </Button>
                                </GridItem>
                            </form>
                        :
                            <LoaderComponent align="left" />
                    }
                    
                </Grid>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </Container>
        )
    }
}

export default withStyles(editPageStyle)(UserEdit);