import React from 'react';
import loadImage from "blueimp-load-image";
import Api from '../../../../assets/js/utils/Api';
import Cookie from "../../../../assets/js/utils/Cookie";
import { helper } from '../../../../assets/js/utils/Element'
import LoaderComponent from '../../../components/Loader'
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import CustomTagsInput from "../../../components/CustomInput/CustomTagsInput.jsx";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
import InstagramApiModal from "../../../components/Dashboard/InstagramApiModal.jsx"
import YoutubeApiModal from "../../../components/Dashboard/YoutubeApiModal.jsx"
import Link from "@material-ui/core/Link";
import ChangeUserLevelModal from "../../../components/Dashboard/ChangeUserLevelModal";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomSelectSocialIcons from "../../../components/CustomInput/CustomSelectSocialIcons";
import { ReactSortable } from "react-sortablejs";
import Menu from "@material-ui/icons/Menu";

//Tooltip modals
import AboutProfileTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/AboutProfileTooltipModal";
import ProfilePhotoTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/ProfilePhotoTooltipModal";
import CoverPhotoTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/CoverPhotoTooltipModal";
import FirstNameTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/FirstNameTooltipModal";
import LastNameTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/LastNameTooltipModal";
import BlogUrlTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/BlogUrlTooltipModal";
import BioTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/BioTooltipModal";
import SearchTermTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/SearchTermTooltipModal";
import SocialLinksTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/SocialLinksTooltipModal";
import PersonalInformationTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/PersonalInformationTooltipModal";
import DemographicsTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/DemographicsTooltipModal";

import { ReactComponent as PinterestIcon } from "../../../../assets/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/facebook.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import instagramIcon from "../../../../assets/img/instagram-icon.png";
import tiktokIcon from "../../../../assets/img/tiktok-icon.png";
import snapchatIcon  from "../../../../assets/img/snapchat-icon.png";
import soundcloudIcon from "../../../../assets/img/soundcloud-icon.png";
import whatsappIcon from "../../../../assets/img/whatsapp-icon.png";
import twitchIcon from "../../../../assets/img/twitch-icon.png";
import patreonIcon from "../../../../assets/img/patreon-icon.png";
import spotifyIcon from "../../../../assets/img/spotify-icon.png";
import amazonLiveIcon from "../../../../assets/img/amazon-live-icon.png";
import amazonShopIcon from "../../../../assets/img/amazon-shop-icon.png";
import appleMusicIcon from "../../../../assets/img/apple-music-icon.png";
import itunesIcon from "../../../../assets/img/itunes-icon.png";
import googlePlayIcon from "../../../../assets/img/gogole-play-icon.png";
import mediumIcon from "../../../../assets/img/medium-icon.png";
import likeToKnowItIcon from "../../../../assets/img/like-to-know-icon.png";

import dashboardTabProfileAboutStyle from "../../../../assets/jss/dashboard/profile/dashboardTabProfileAboutStyle.js";

const DashboardTabProfileAbout = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        let values = this.prepareValues();
        let avatarPhoto = null;
        let avatarMediaId = null;
        if(values.hasOwnProperty("avatarMedia")){
            avatarPhoto = values.avatarMedia.downloadUrlProfile;
            avatarMediaId = values.avatarMedia.mediaId;
        }

        let coverPhoto = null;
        let coverMediaId = null;
        if(values.hasOwnProperty("coverMedia")){
            coverPhoto = values.coverMedia.downloadUrlProfile;
            coverMediaId = values.coverMedia.mediaId;
        }

        this.isInstaModal = helper.getParam("instaModal");
        this.isYoutubeModal = helper.getParam("youtubeModal");
        let socialIcons = Api.getSocialIcons();
        this.state = {
            values: values,
            avatarPhoto: avatarPhoto,
            avatarMediaId: avatarMediaId,
            coverPhoto: coverPhoto,
            coverMediaId: coverMediaId,
            saving: false,
            savingCoverPhoto: false,
            user: user,
            editSocialLinks: false,
            validation: {
                firstName: '',
                lastName: '',
            },
            socialIconsValidation: {
                instagramUrl: "",
                facebookUrl: "",
                pinterestUrl: "",
                tiktokUrl: "",
                youtubeUrl: "",
                contactEmail: "",
                twitterUrl: "",
                linkedinUrl: "",
                snapchatUrl: "",
                soundcloudUrl: "",
                whatsappNumber: "",
                twitchUrl: "",
                patreonUrl: "",
                spotifyUrl: "",
                amazonLiveUrl: "",
                amazonShopUrl: "",
                appleMusicUrl: "",
                itunesUrl: "",
                googlePlayUrl: "",
                mediumUrl: "",
                likeToKnowItUrl: "",
            },
            savingSocialLinks: false,
            tooltips: {
                avatarPhoto: false,
                coverPhoto: false,
                firstName: false,
                lastName: false,
                blogUrl: false,
                bio: false,
                searchTerm: false,
                socialLinks: false,
                personalInformation: false,
                demographics: false,
                aboutMe: false
            },
            googlePlacesResponse: {
                latitude: 0.0,
                longitude: 0.0,
                location: values.city ? values.city : "",
                googlePlacesId: "",
                formatted_address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            },
            tagInputValue: "",
            instagramApiModal: false,
            youtubeApiModal: false,
            changeUserLevel: false,
            paymentData: this.props.paymentData,
            subscription: this.props.subscription,
            socialIcons: socialIcons,
            remainingSocialIcons: socialIcons,
            selectedSocialIcons: socialIcons,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        };

        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.handleBioChange = this.handleBioChange.bind(this);
        this.handleSocialIcon = this.handleSocialIcon.bind(this);
        this.editSocialLinks = this.editSocialLinks.bind(this);
        this.saveSocialLinks = this.saveSocialLinks.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.handleBlogLinkChange = this.handleBlogLinkChange.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.upgradeUserLevel = this.upgradeUserLevel.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
        this.onSocialIconsSort = this.onSocialIconsSort.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription
        })
    }
    componentDidMount(){
        this.updateSocialIconsStatus();
        // this.selectedSocialIcons();
        // this.remainingSocialIcons();

        let instaCookie = Cookie.read("instagramModalCookie");
        let youtubeCookie = Cookie.read("youtubeModalCookie");
        if(instaCookie === "1" || youtubeCookie === "1"){
            this.loadUserPlatforms();
        }
    }
    prepareValues(){
        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        if(!values.hasOwnProperty("topSearchTerms")){
            values.topSearchTerms = [];
        }else{
            let topSearchTermsStr = values.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                values.topSearchTerms = [];
            }else{
                values.topSearchTerms = values.topSearchTerms.split(',');
            }
        }
        if(!values.hasOwnProperty("bio") || values.bio.length <= 0){
            values['bio'] = "";
        }
        return values;
    }
    handleChangePhoto(type, params){
        if(params.file === null){
            this.deletePhoto(type);
            return;
        }
        // if(type === "avatarPhoto"){
        //     this.setState({avatarPhoto: params.file, savingAvatarPhoto: true});
        // }else{
        //     this.setState({coverPhoto: params.file, savingCoverPhoto: true});
        // }

        const that = this;
        loadImage(params.file, img => {
            img.toBlob(blob => {
                const file = new File([blob], params.file.name, {type: params.file.type});
                if(type === "avatarPhoto"){
                    that.setState({avatarPhoto: file, savingAvatarPhoto: true});
                }else{
                    that.setState({coverPhoto: file, savingCoverPhoto: true});
                }
                that.uploadPhoto(file, type);
            }, params.file.type);
        }, {
            orientation: true
        });
    }
    uploadPhoto(file, type){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
        };
        if(type === "avatarPhoto"){
            requestData['isAvatar'] = true;
        }else{
            requestData['isCover'] = true;
        }
        Api.createMedia(file, requestData, source).then((data) => {
            let newState = {
                cancelToken: null, 
                savingAvatarPhoto: false,
                savingCoverPhoto: false
            };
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = data.mediaId;
            }else{
                newState['coverMediaId'] = data.mediaId;
            }
            that.setState(newState);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    deletePhoto(type){
        let mediaIdKey = "avatarMediaId";
        if(type === "coverPhoto"){
            mediaIdKey = "coverMediaId";
        }
        const mediaId = this.state[mediaIdKey];
        if(!mediaId || mediaId.length <= 0){
            if(type === "avatarPhoto"){
                this.setState({avatarPhoto: null});
                return;
            }
            this.setState({coverPhoto: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        if(type === "coverPhoto"){
            this.setState({cancelToken: source, coverPhoto: null, saving: true});
        }else{
            this.setState({cancelToken: source, avatarPhoto: null, saving: true});
        }
        
        Api.deleteMedia(mediaId, source).then((data) => {
            let newState = {
                cancelToken: null, 
                saving: false
            };
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = null;
            }else{
                newState['coverMediaId'] = null;
            }
            that.setState(newState);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    editSocialLinks(editSocialLinks = true){
        let state = {editSocialLinks: editSocialLinks};
        if(!editSocialLinks){
            const socialIcons = this.updateSocialIconsStatus(true);
            const values = this.prepareValues();
            state['values'] = values;
            state['socialIcons'] = socialIcons;
            state['remainingSocialIcons'] = socialIcons;
            state['selectedSocialIcons'] = socialIcons;
            state['sortableSocialIcons'] = this.prepareSortableSocialIcons(values.socialIcons);
        }
        this.setState(state);
    }
    saveSocialLinks(){
        const isValid = this.validateForm();
        if(!isValid){
            window.scrollTo(0, 0);
            return;
        }

        this.setState({editSocialLinks: false, savingSocialLinks: true});
        this.updateUser();
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        let regularTagsStr = this.state.values.topSearchTerms.toString();
        values.topSearchTerms = regularTagsStr;
        if(user.userPreferences.hasOwnProperty("onboarding")){
            values.userPreferences.onboarding.step7Complete = true;
        }
        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                if((!user.socialIcons.hasOwnProperty("instagramUrl") || user.socialIcons.instagramUrl === null) && (newUser.socialIcons.hasOwnProperty("instagramUrl") && newUser.socialIcons.instagramUrl !== null)){
                    Cookie.write("instagramModalCookie", "1");
                }
                if((!user.socialIcons.hasOwnProperty("youtubeUrl") || user.socialIcons.youtubeUrl === null) && (newUser.socialIcons.hasOwnProperty("youtubeUrl") && newUser.socialIcons.youtubeUrl !== null)){
                    Cookie.write("youtubeModalCookie", "1");
                }
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser, redirect: true }});
                
                this.history.push("/dashboard/profile/about");
            }
        }).catch(err => {
            console.log(err);
        });
    }
    loadUserPlatforms(){
        const { user } = this.store.getState();
        if(!user.hasOwnProperty("platforms")){
            return;
        }
        let hasInstagramPlatform = false;
        let hasYoutubePlatform = false;
        user.contentPlatforms.map((userPlatform) => {
            if(userPlatform.type === "instagramAPI"){
                hasInstagramPlatform = true;
            }
            if(userPlatform.type === "youtubeAPI"){
                hasYoutubePlatform = true;
            }
            return null;
        });

        let instaCookie = Cookie.read("instagramModalCookie");
        let youtubeCookie = Cookie.read("youtubeModalCookie");
        if(instaCookie === "1" && hasInstagramPlatform === false){
            this.setState({
                instagramApiModal: true,
            });
        }else if(youtubeCookie === "1" && hasYoutubePlatform === false){
            this.setState({
                youtubeApiModal: true,
            });
        }
    }
    handleInstagramModal(open){
        if(open === false){
            let youtubeCookie = Cookie.read("youtubeModalCookie");
            if(youtubeCookie === "1"){
                this.setState({ 
                    instagramApiModal: false,
                    youtubeApiModal: true
                });
            }else{
                this.setState({ instagramApiModal: false });
            }
            Cookie.dispose("instagramModalCookie");
            this.history.push("/dashboard/profile/about");
            return;
        }
        this.setState({ instagramApiModal: false });
        Cookie.dispose("instagramModalCookie");
        Cookie.dispose("youtubeModalCookie");
        this.history.push("/dashboard/platformwizard/instagramlink");
    }
    handleYoutubeModal(open){
        if(open === false){
            this.setState({ youtubeApiModal: false });
            Cookie.dispose("youtubeModalCookie");
            this.history.push("/dashboard/profile/about");
            return;
        }
        this.setState({ youtubeApiModal: false });
        Cookie.dispose("instagramModalCookie");
        Cookie.dispose("youtubeModalCookie");
        this.history.push("/dashboard/platformwizard/youtube");
    }
    handleBioChange(event, name){
        let value = event.target.value;
        this.setState({
            values: {
                ...this.state.values, 
                [name]: value,
            }
        });
    }
    onClearInput(name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]: "",
            }
        });
    }
    getDomainName(name){
        let domain = "";
        switch(name){
            case "instagramUrl":
                domain = "instagram"
                break;
            case "facebookUrl":
                domain = "facebook"
                break;
            case "pinterestUrl":
                domain = "pinterest"
                break;
            case "tiktokUrl":
                domain = "tiktok"
                break;
            case "youtubeUrl":
                domain = "youtube"
                break;
            case "twitterUrl":
                domain = "twitter"
                break;
            case "linkedinUrl":
                domain = "linkedin"
                break;
            case "snapchatUrl":
                domain = "snapchat"
                break;
            case "twitchUrl":
                domain = "twitch"
                break;
            case "soundcloudUrl":
                domain = "soundcloud"
                break;
            case "patreonUrl":
                domain = "patreon"
                break;
            case "spotifyUrl":
                domain = "spotify"
                break;
            case "amazonLiveUrl":
                domain = "amazonlive"
                break;
            case "amazonShopUrl":
                domain = "amazonshop"
                break;
            case "appleMusicUrl":
                domain = "applemusic"
                break;
            case "itunesUrl":
                domain = "itunes"
                break;
            case "googlePlayUrl":
                domain = "googleplay"
                break;
            case "mediumUrl":
                domain = "medium"
                break;
            case "likeToKnowItUrl":
                domain = "liketoknow"
                break;
            default:
                domain  = "";
                break;
        }

        return domain;
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        
        let domain = this.getDomainName(name);
        if((name === "tiktokUsername" || name === "mediumUrl") && value.substr(0,1) === "@"){
            value = value.substr(1);
        }
        try{
            new URL(value); 
        }catch(e){
            if((name !== "contactEmail" && name !== "whatsappNumber")  && 
                value.indexOf("http://") === -1 && value.indexOf("https://") === -1
            ){
                value = helper.prepareSocialUrl(domain,value)
            }
        }

        let domainUrl = helper.prepareSocialUrl(domain,'');
        if(value === domainUrl){
            value = null;
        }
        
        const values = {
            ...this.state.values, 
            socialIcons: {
                ...this.state.values.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    handleBlogLinkChange(event, name) {
        let value = event.target.value;
        let { values } = this.state;
        const socialIcons = values.socialIcons;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(socialIcons.hasOwnProperty(name) && socialIcons[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "https://"+value;
            }
        }
        if(value === "https:/"){
            value = "https://";
        }
        
        values = {
            ...values, 
            socialIcons: {
                ...values.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    handleTags = regularTags => {
        let { topSearchTerms } = this.state.values;
        if(typeof(topSearchTerms) === "object" && regularTags.length > topSearchTerms.length){
            const lastIndex = regularTags[regularTags.length - 1];
            topSearchTerms = [lastIndex].concat(topSearchTerms);
        }else{
            topSearchTerms = regularTags;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: topSearchTerms,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.values.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: [tagValue, ...topSearchTerms],
            },
            tagInputValue: ""
        })
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    upgradeUserLevel(e){
        e.preventDefault();
        this.changeUserLevel();
    }
    changeUserLevel(changeUserLevel = true){
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    // selectedSocialIcons(){
    //     const { socialIcons } = this.state;
    //     const { user } = this.store.getState();

    //     socialIcons.map((socialIcon) => {
    //         if(user.socialIcons.hasOwnProperty(socialIcon.itemValue) === false || user.socialIcons.hasOwnProperty(socialIcon.itemValue) === ""){
    //             this.setState(prevState => ({
    //                 selectedSocialIcons: prevState.selectedSocialIcons.filter(selectedSocialIcons => selectedSocialIcons.itemValue !== socialIcon.itemValue),
    //             }));
    //         }
    //         return null;
    //     })
    // }
    // remainingSocialIcons(){
    //     const { socialIcons } = this.state;
    //     const { user } = this.store.getState();

    //     socialIcons.map((socialIcon) => {
    //         if(user.socialIcons.hasOwnProperty(socialIcon.itemValue) === true && user.socialIcons.hasOwnProperty(socialIcon.itemValue) !== ""){
    //             this.setState(prevState => ({
    //                 remainingSocialIcons: prevState.remainingSocialIcons.filter(remainingSocialIcons => remainingSocialIcons.itemValue !== socialIcon.itemValue),
    //             }));
    //         }
    //         return null;
    //     })
    // }
    updateSocialIconsStatus(returnIcons = false){
        const { socialIcons } = this.state;
        const { user } = this.store.getState();

        let socialIconsWithStatus = [];
        socialIcons.map((socialIcon) => {
            let status = false;
            let itemName = socialIcon.itemValue;
            if(user.socialIcons.hasOwnProperty(socialIcon.itemValue) === true && user.socialIcons[itemName] !== "" && user.socialIcons[itemName] !== null){
                status = true;
            }
            socialIconsWithStatus.push({
                itemValue: socialIcon.itemValue,
                itemLabel: socialIcon.itemLabel,
                status: status,
            });

            return null;
        });

        if(returnIcons){
            return socialIconsWithStatus;
        }

        this.setState({
            socialIcons: socialIconsWithStatus
        })

    }
    handleSocialIcon(event){
        let value = event.target.value;
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === value)[0];
        socialIcon.status = true;
        const values = {
            ...this.state.values, 
            socialIcons: {
                ...this.state.values.socialIcons,
                [socialIcon.itemValue]: null,
            }
        };

        this.setState({
            ...this.state,
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });

        // this.setState(prevState => ({
        //     remainingSocialIcons: prevState.remainingSocialIcons.filter(remainingSocialIcons => remainingSocialIcons.itemValue !== value),
        //     selectedSocialIcons: [
        //         ...this.state.selectedSocialIcons,
        //         ...prevState.socialIcons.filter(socialIcons => socialIcons.itemValue === value),
        //     ]
        // }));
    }
    onClear(name){
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === name)[0];
        socialIcon.status = false;

        let socialIcons = this.state.values.socialIcons;
        if(socialIcons.hasOwnProperty(name)){
            delete socialIcons[name];
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                socialIcons: socialIcons
            },
            sortableSocialIcons: this.prepareSortableSocialIcons(socialIcons)
        });
    }
    renderSocialIcons(){
        const { classes } = this.props;
        const { socialIcons, editSocialLinks } = this.state;
        return(
            <div className={classes.addSocialIcon}>
                <CustomSelectSocialIcons 
                    formControlProps={{
                        fullWidth: true
                    }}
                    selectProps={{
                        onChange: (e) => this.handleSocialIcon(e),
                        value: ""
                    }}
                    labelText={"Add Social Icon"}
                    inputProps={{
                        name: "type",
                        id: "outlined-type",
                        className: classes.alignLeft,
                        disabled: !editSocialLinks
                    }}
                    items={socialIcons}
                    itemLabel="itemLabel"
                    itemValue="itemValue"
                />
            </div>
        )
    }
    renderSocialFields(){
        const { classes } = this.props;
        const { sortableSocialIcons } = this.state;
        let socialIconsRendered = [];
        sortableSocialIcons.map((socialIcon, key) => {
            if(socialIcon.id === "blogUrl"){
                return null;
            }
            socialIconsRendered.push(
                <li key={key}>
                    <span className={classes.sortIcon+" social_icons_sort"}><Menu /></span>
                    {this.renderSocialField(socialIcon.id)}
                </li>
            );
            return null;
        });
        return socialIconsRendered;
    }
    renderSocialField(type){
        const { classes } = this.props;
        const { editSocialLinks, values, socialIconsValidation } = this.state;
        let socialField = "";
        switch(type){
            case "instagramUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                        <GridItem className={classes.socialGridItem}>
                            <Button justIcon className="instagram-icon">
                                <img src={instagramIcon} alt="instagram"/>
                            </Button>
                        </GridItem>
                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                            <CustomInput
                                id="outlined-instagramUsername"
                                labelText="Instagram Handle"
                                success={socialIconsValidation.instagramUrl === "success"}
                                error={socialIconsValidation.instagramUrl === "error"}
                                inputProps={{
                                    value: values.socialIcons.instagramUrl ? values.socialIcons.instagramUrl : "",
                                    onChange: (e) => this.handleLinkChange(e, 'instagramUrl'),
                                    name: "instagramUrl",
                                    disabled: !editSocialLinks
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                                showClear={true}
                                onClear= {() => this.onClear("instagramUrl")}
                            />                                        
                        </GridItem>
                        <p className={classes.socialLinkPreview}>
                            {
                                values.socialIcons.instagramUrl ?
                                    values.socialIcons.instagramUrl
                                :
                                    helper.prepareSocialUrl("instagram","")
                            }
                        </p>
                    </GridContainer>
                break;
            case "twitterUrl": 
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitterUsername"
                            labelText="Twitter Username"
                            success={socialIconsValidation.twitterUrl === "success"}
                            error={socialIconsValidation.twitterUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitterUrl ? values.socialIcons.twitterUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'twitterUrl'),
                                name: "twitterUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("twitterUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitterUrl ?
                                values.socialIcons.twitterUrl
                            :
                                helper.prepareSocialUrl("twitter","")
                        }
                    </p>
                </GridContainer>
                break;
            case "youtubeUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-youtubeChannelUrl"
                            labelText="Youtube Channel Url"
                            success={socialIconsValidation.youtubeUrl === "success"}
                            error={socialIconsValidation.youtubeUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.youtubeUrl ? values.socialIcons.youtubeUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'youtubeUrl'),
                                name: "youtubeUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("youtubeUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.youtubeUrl ?
                                values.socialIcons.youtubeUrl
                            :
                                helper.prepareSocialUrl("youtube","")
                        }
                    </p>
                </GridContainer>
                break;
            case "tiktokUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={tiktokIcon} alt="tiktok"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-tiktokUsername"
                            labelText="Tiktok Username"
                            success={socialIconsValidation.tiktokUrl === "success"}
                            error={socialIconsValidation.tiktokUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.tiktokUrl ? values.socialIcons.tiktokUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'tiktokUrl'),
                                name: "tiktokUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("tiktokUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.tiktokUrl ?
                                values.socialIcons.tiktokUrl
                            :
                                helper.prepareSocialUrl("tiktok","")
                        }
                    </p>
                </GridContainer>
                break;
            case "facebookUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <FacebookIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-facebookUsername"
                            labelText="Facebook Username"
                            success={socialIconsValidation.facebookUrl === "success"}
                            error={socialIconsValidation.facebookUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.facebookUrl ? values.socialIcons.facebookUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'facebookUrl'),
                                name: "facebookUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("facebookUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.facebookUrl ?
                                values.socialIcons.facebookUrl
                            :
                                helper.prepareSocialUrl("facebook","")
                        }
                    </p>
                </GridContainer>
                break;
            case "pinterestUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-pinterestUsername"
                            labelText="Pinterest Username"
                            success={socialIconsValidation.pinterestUrl === "success"}
                            error={socialIconsValidation.pinterestUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.pinterestUrl ? values.socialIcons.pinterestUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'pinterestUrl'),
                                name: "pinterestUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("pinterestUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.pinterestUrl ?
                                values.socialIcons.pinterestUrl
                            :
                                helper.prepareSocialUrl("pinterest","")
                        }
                    </p>
                </GridContainer>
                break;
            case "linkedinUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <LinkedinIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-linkedinUsername"
                            labelText="LinkedIn Username"
                            success={socialIconsValidation.linkedinUrl === "success"}
                            error={socialIconsValidation.linkedinUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.linkedinUrl ? values.socialIcons.linkedinUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'linkedinUrl'),
                                name: "linkedinUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("linkedinUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.linkedinUrl ?
                                values.socialIcons.linkedinUrl
                            :
                                helper.prepareSocialUrl("linkedin","")
                        }
                    </p>
                </GridContainer>
                break;
            case "contactEmail":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <MailIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-contactEmail"
                            labelText="Contact Email"
                            success={socialIconsValidation.contactEmail === "success"}
                            error={socialIconsValidation.contactEmail === "error"}
                            inputProps={{
                                value: values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "",
                                onChange: (e) => this.handleLinkChange(e, 'contactEmail'),
                                name: "contactEmail",
                                type:"email",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("contactEmail")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {"mailto:"+(values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "")}
                    </p>
                </GridContainer>
                break;
            case "snapchatUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={snapchatIcon} alt="snapchat"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-snapchatUsername"
                            labelText="Snapchat Username"
                            success={socialIconsValidation.snapchatUrl === "success"}
                            error={socialIconsValidation.snapchatUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.snapchatUrl ? values.socialIcons.snapchatUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'snapchatUrl'),
                                name: "snapchatUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("snapchatUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.snapchatUrl ?
                                values.socialIcons.snapchatUrl
                            :
                                helper.prepareSocialUrl("snapchat","")
                        }
                    </p>
                </GridContainer>
                break;
            case "soundcloudUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={soundcloudIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-soundcloud"
                            labelText="SoundCloud"
                            success={socialIconsValidation.soundcloudUrl === "success"}
                            error={socialIconsValidation.soundcloudUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.soundcloudUrl ? values.socialIcons.soundcloudUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'soundcloudUrl'),
                                name: "soundcloudUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("soundcloudUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.soundcloudUrl ?
                                values.socialIcons.soundcloudUrl
                            :
                                helper.prepareSocialUrl("soundcloud","")
                        }
                    </p>
                </GridContainer>
                break;
            case "whatsappNumber":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={whatsappIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-whatsappNumber"
                            labelText="WhatsApp"
                            success={socialIconsValidation.whatsappNumber === "success"}
                            error={socialIconsValidation.whatsappNumber === "error"}
                            inputProps={{
                                value: values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "",
                                onChange: (e) => this.handleLinkChange(e, 'whatsappNumber'),
                                name: "whatsappNumber",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("whatsappNumber")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {(values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "+00000000000")}
                    </p>
                </GridContainer>
                break;
            case "twitchUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={twitchIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitch"
                            labelText="Twitch"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitchUrl ? values.socialIcons.twitchUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'twitchUrl'),
                                name: "twitchUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("twitchUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitchUrl ?
                                values.socialIcons.twitchUrl
                            :
                                helper.prepareSocialUrl("twitch","")
                        }
                    </p>
                </GridContainer>
                break;
            case "patreonUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={patreonIcon} alt="patreon"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-patreon"
                            labelText="Patreon"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.patreonUrl ? values.socialIcons.patreonUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'patreonUrl'),
                                name: "patreonUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("patreonUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.patreonUrl ?
                                values.socialIcons.patreonUrl
                            :
                                helper.prepareSocialUrl("patreon","")
                        }
                    </p>
                </GridContainer>
                break;
            case "spotifyUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={spotifyIcon} alt="spotify"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-spotify"
                            labelText="Spotify"
                            success={socialIconsValidation.spotifyUrl === "success"}
                            error={socialIconsValidation.spotifyUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.spotifyUrl ? values.socialIcons.spotifyUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'spotifyUrl'),
                                name: "spotifyUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("spotifyUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.spotifyUrl ?
                                values.socialIcons.spotifyUrl
                            :
                                helper.prepareSocialUrl("spotify","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonLiveUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonLiveIcon} alt="amazon live"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonLive"
                            labelText="Amazon Live"
                            success={socialIconsValidation.amazonLiveUrl === "success"}
                            error={socialIconsValidation.amazonLiveUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonLiveUrl ? values.socialIcons.amazonLiveUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'amazonLiveUrl'),
                                name: "amazonLiveUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("amazonLiveUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonLiveUrl ?
                                values.socialIcons.amazonLiveUrl
                            :
                                helper.prepareSocialUrl("amazonlive","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonShopUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonShopIcon} alt="amazon shop"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonShop"
                            labelText="Amazon Shop"
                            success={socialIconsValidation.amazonShopUrl === "success"}
                            error={socialIconsValidation.amazonShopUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonShopUrl ? values.socialIcons.amazonShopUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'amazonShopUrl'),
                                name: "amazonShopUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("amazonShopUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonShopUrl ?
                                values.socialIcons.amazonShopUrl
                            :
                                helper.prepareSocialUrl("amazonshop","")
                        }
                    </p>
                </GridContainer>
                break;
            case "appleMusicUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={appleMusicIcon} alt="apple music"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-appleMusic"
                            labelText="Apple Music"
                            success={socialIconsValidation.appleMusicUrl === "success"}
                            error={socialIconsValidation.appleMusicUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.appleMusicUrl ? values.socialIcons.appleMusicUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'appleMusicUrl'),
                                name: "appleMusicUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("appleMusicUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.appleMusicUrl ?
                                values.socialIcons.appleMusicUrl
                            :
                                helper.prepareSocialUrl("applemusic","")
                        }
                    </p>
                </GridContainer>
                break;
            case "itunesUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={itunesIcon} alt="itunes"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-itunesUrl"
                            labelText="itunes"
                            success={socialIconsValidation.itunesUrl === "success"}
                            error={socialIconsValidation.itunesUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.itunesUrl ? values.socialIcons.itunesUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'itunesUrl'),
                                name: "itunesUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("itunesUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.itunesUrl ?
                                values.socialIcons.itunesUrl
                            :
                                helper.prepareSocialUrl("itunes","")
                        }
                    </p>
                </GridContainer>
                break;
            case "googlePlayUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={googlePlayIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-googlePlayUrl"
                            labelText="Google Play"
                            success={socialIconsValidation.googlePlayUrl === "success"}
                            error={socialIconsValidation.googlePlayUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.googlePlayUrl ? values.socialIcons.googlePlayUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'googlePlayUrl'),
                                name: "googlePlayUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("googlePlayUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.googlePlayUrl ?
                                values.socialIcons.googlePlayUrl
                            :
                                helper.prepareSocialUrl("googleplay","")
                        }
                    </p>
                </GridContainer>
                break;
            case "mediumUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={mediumIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-mediumUrl"
                            labelText="Medium"
                            success={socialIconsValidation.mediumUrl === "success"}
                            error={socialIconsValidation.mediumUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.mediumUrl ? values.socialIcons.mediumUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'mediumUrl'),
                                name: "mediumUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("mediumUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.mediumUrl ?
                                values.socialIcons.mediumUrl
                            :
                                helper.prepareSocialUrl("medium","")
                        }
                    </p>
                </GridContainer>
                break;
            case "likeToKnowItUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={likeToKnowItIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-likeToKnowItUrl"
                            labelText="Like to Know It"
                            success={socialIconsValidation.likeToKnowItUrl === "success"}
                            error={socialIconsValidation.likeToKnowItUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.likeToKnowItUrl ? values.socialIcons.likeToKnowItUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'likeToKnowItUrl'),
                                name: "likeToKnowItUrl",
                                disabled: !editSocialLinks
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("likeToKnowItUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.likeToKnowItUrl ?
                                values.socialIcons.likeToKnowItUrl
                            :
                                helper.prepareSocialUrl("liketoknow","")
                        }
                    </p>
                </GridContainer>
                break;
            default :
                socialField = <></>;
                break;
        }
        return socialField;
    }
    onSocialIconsSort(socialIcons){
        const socialIconsOld = this.state.values.socialIcons;
        let socialIconsNewOrder = {};
        socialIcons.map((socialIcon) => {
            socialIconsNewOrder[socialIcon.id] = socialIconsOld[socialIcon.id];
            return null;
        });
        if(socialIconsOld.hasOwnProperty("blogUrl")){
            socialIconsNewOrder['blogUrl'] = socialIconsOld.blogUrl;
        }
        this.setState({
            sortableSocialIcons: socialIcons,
            values: {
                ...this.state.values,
                socialIcons: socialIconsNewOrder
            }
        });
    }
    prepareSortableSocialIcons(socialIcons){
        let sortableSocialIcons = [];
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            sortableSocialIcons.push({
                id: socialIcon,
                value: socialIcons[socialIcon],
                selected: false,
                chosen: false,
                filtered: false
            })
        }
        return sortableSocialIcons;
    }
    validateForm(){
        const { socialIcons } = this.state.values;
        if(typeof(socialIcons) !== "object"){
            return;
        }
        let isValid = true;
        let validation = this.state.socialIconsValidation;
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            validation[socialIcon] = "success";
            const value = socialIcons[socialIcon];
            if(!value || value === "" || value === "https://"){
                validation[socialIcon] = "error";
                isValid = false;
            }
        }
        this.setState({socialIconsValidation: validation});
        return isValid;
    }
    render() {
        const { classes } = this.props;
        const { editSocialLinks, values, savingSocialLinks, tooltips, instagramApiModal, youtubeApiModal, changeUserLevel, 
            paymentData, subscription, sortableSocialIcons } = this.state;
        const { user } = this.store.getState();
        const { socialIcons } = this.state.values;

        let blogUrl = "https://";
        if(socialIcons.hasOwnProperty("blogUrl") && socialIcons.blogUrl.length > 0){
            blogUrl = socialIcons.blogUrl;
        }
        return (
            <GridItem className={classes.main} xs={12} sm={12} md={9} lg={6}>
                <h4 className={classes.title}>
                    Setup Your About Me Page
                    {
                        user.userPreferences.showTips ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('personalInformation', true)} />
                        :
                        <></>
                    }
                </h4>
                <div className={"userInfo "+(editSocialLinks === false?classes.viewInfo:'')}>
                    {
                        (values.userLevel < 1) ?
                            <p>
                                Free accounts do not have access to these features -&nbsp;
                                <Link href="/dashboard/account/upgrade" underline="always" onClick={this.upgradeUserLevel}>
                                    Upgrade Now!
                                </Link>
                            </p>
                        :
                            editSocialLinks === false && savingSocialLinks === false ?
                                <div className={classes.editButton}>
                                    <Button color="blue" onClick={() => this.editSocialLinks()}>Edit</Button>
                                </div>
                            :
                            savingSocialLinks ? <LoaderComponent align="left" /> : <></>
                    }
                    {
                        editSocialLinks === true ?
                            <div className={classes.saveButtons}>
                                <Button color="white" onClick={() => this.editSocialLinks(false)}>
                                    <ArrowBackIcon className={classes.arrow} /> Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveSocialLinks()}>
                                    <SaveIcon className={classes.icons} /> Save
                                </Button>
                            </div>
                        :
                            <></>
                    }
                </div>
                <div className={"userInfo "+(editSocialLinks === false?classes.viewInfo:'')}>
                    <label className={classes.socialLinksTitle}>
                        Enter the website link you would like featured on your About Me page
                    </label>
                    <CustomInput
                        id="outlined-blogUrl"
                        labelText="Website Url"                                    
                        inputProps={{
                            value: blogUrl,
                            onChange: (e) => this.handleBlogLinkChange(e, 'blogUrl'),
                            name: "blogUrl",
                            disabled: !editSocialLinks
                        }}                                    
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                    <p className={classes.socialLinkPreview}>
                        {blogUrl}
                    </p>
                    <div className={classes.socialLinks}>
                        <label className={classes.socialLinksTitle}>
                            Enter your social links that will appear on your About Me page
                            {
                                user.userPreferences.showTips ?
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('socialLinks', true)} />
                                :
                                <></>
                            }
                        </label>
                    </div>
                </div>
                <div className={classes.socialLinksContent+" "+(editSocialLinks === false?classes.viewInfo:'')}>
                    {
                        editSocialLinks ?
                            <ReactSortable
                                tag="ul"
                                handle=".social_icons_sort"
                                direction="horizontal"
                                dragoverBubble={true}
                                animation={150}
                                className={classes.sortableList+" "+classes.sortableListEnabled} 
                                list={sortableSocialIcons}
                                setList={this.onSocialIconsSort}
                            >
                                {this.renderSocialFields()}
                            </ReactSortable>
                        :
                        <ul className={classes.sortableList}>
                            {this.renderSocialFields()}
                        </ul>
                    }
                    
                    {this.renderSocialIcons()}

                    <label className={classes.socialLinksTitle}>
                        The following text will appear on your About Me page
                    </label>
                    <CustomInput
                        id="outlined-bio"
                        labelText="Bio"                                    
                        inputProps={{
                            value: values.bio,
                            onChange: (e) => this.handleBioChange(e, 'bio'),
                            name: "bio",
                            multiline: true,
                            rows: 4,
                            disabled: !editSocialLinks
                        }}
                        onClear={() => this.onClearInput('bio')}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    <CustomTagsInput
                        labelText= {
                            <span>
                                What should your audience search you for? <span className={classes.lineBreak}></span> (For example, "pizza")
                            </span>
                        }
                        tagProps={{
                            value: values.topSearchTerms,
                            onChange: this.handleTags,
                            disabled: !editSocialLinks
                        }}
                        inputProps={{
                            placeholder: "Add a search term",
                            onBlur: this.handleTagsChange,
                            onChange: this.handleTagInput,
                            value: this.state.tagInputValue,
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                        showInfoTooltip={user.userPreferences.showTips}
                        onShowInfo={() => this.onTooltip('searchTerm', true)}
                        color={(user.userLevel >= 1 ? user.guestPreferences.primaryColor: "primary")}
                     />
                </div>
                <div>
                    {
                        editSocialLinks === true ?
                            <div className={classes.saveButtons}>
                                <Button color="white" onClick={() => this.editSocialLinks(false)}>
                                    <ArrowBackIcon className={classes.arrow} /> Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveSocialLinks()}>
                                    <SaveIcon className={classes.icons} /> Save
                                </Button>
                            </div>
                        :   
                            (values.userLevel < 1) ?
                                <p className={classes.upgradePlan}>
                                    Free accounts do not have access to these features -&nbsp;
                                    <Link href="/dashboard/account/upgrade" underline="always" onClick={this.upgradeUserLevel}>
                                        Upgrade Now!
                                    </Link>
                                </p>
                            :
                                editSocialLinks === false && savingSocialLinks === false ?
                                    <div className={classes.editButton+" "+classes.editButtonBottom}>
                                        <Button color="blue" onClick={() => this.editSocialLinks()}>Edit</Button>
                                    </div>
                                :
                                savingSocialLinks ? <LoaderComponent align="left" /> : <></>
                    }
                </div>
                {
                    tooltips.avatarPhoto ?
                        <ProfilePhotoTooltipModal open={tooltips.avatarPhoto} store={this.store} onClose={() => this.onTooltip('avatarPhoto', false)} />
                    :
                    <></>
                }
                {
                    tooltips.coverPhoto ?
                        <CoverPhotoTooltipModal open={tooltips.coverPhoto} store={this.store} onClose={() => this.onTooltip('coverPhoto', false)} />
                    :
                    <></>
                }
                {
                    tooltips.firstName ?
                        <FirstNameTooltipModal open={tooltips.firstName} store={this.store} onClose={() => this.onTooltip('firstName', false)} />
                    :
                    <></>
                }
                {
                    tooltips.lastName ?
                        <LastNameTooltipModal open={tooltips.lastName} store={this.store} onClose={() => this.onTooltip('lastName', false)} />
                    :
                    <></>
                }
                {
                    tooltips.blogUrl ?
                        <BlogUrlTooltipModal open={tooltips.blogUrl} store={this.store} onClose={() => this.onTooltip('blogUrl', false)} />
                    :
                    <></>
                }
                {
                    tooltips.bio ?
                        <BioTooltipModal open={tooltips.bio} store={this.store} onClose={() => this.onTooltip('bio', false)} />
                    :
                    <></>
                }
                {
                    tooltips.searchTerm ?
                        <SearchTermTooltipModal open={tooltips.searchTerm} store={this.store} onClose={() => this.onTooltip('searchTerm', false)} />
                    :
                    <></>
                }
                {
                    tooltips.socialLinks ?
                        <SocialLinksTooltipModal open={tooltips.socialLinks} store={this.store} onClose={() => this.onTooltip('socialLinks', false)} />
                    :
                    <></>
                }
                {
                    tooltips.personalInformation ?
                        <PersonalInformationTooltipModal open={tooltips.personalInformation} store={this.store} onClose={() => this.onTooltip('personalInformation', false)} />
                    :
                    <></>
                }
                {
                    tooltips.demographics ?
                        <DemographicsTooltipModal open={tooltips.demographics} store={this.store} onClose={() => this.onTooltip('demographics', false)} />
                    :
                    <></>
                }
                {
                    tooltips.aboutMe ?
                        <AboutProfileTooltipModal open={tooltips.aboutMe} store={this.store} onClose={() => this.onTooltip('aboutMe', false)} />
                    :
                    <></>
                }
                {
                    instagramApiModal ?
                        <InstagramApiModal instagramIcon={true} open={instagramApiModal} onClick={event => this.handleInstagramModal(true)} onClose={event => this.handleInstagramModal(false)} onNever={event => this.handleInstagramModal(false)} />
                    :
                        <></>
                }
                {
                    youtubeApiModal ?
                        <YoutubeApiModal youtubeIcon={true} open={youtubeApiModal} onClick={event => this.handleYoutubeModal(true)} onClose={event => this.handleYoutubeModal(false)} onNever={event => this.handleYoutubeModal(false)} />
                    :
                        <></>
                }
                {
                    changeUserLevel ?
                        <ChangeUserLevelModal upgrade={true} redirectUrl="/dashboard/profile/about" subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                    :
                        <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabProfileAboutStyle)(DashboardTabProfileAbout);
