import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";
import { matchPath } from 'react-router';
import PropTypes from "prop-types";
import {Helmet} from 'react-helmet';
import Navbar from './components/Navbar';
import LoaderComponent from './components/Loader';
import RouterComponent from './components/Router';
import AuthApi from '../assets/js/utils/Auth';
import Cookie from "../assets/js/utils/Cookie";
import { helper } from '../assets/js/utils/Element';
import Footer from './components/Footer';
import NewUpdate from './components/NewUpdate';
import GpdrBanner from "./components/GpdrBanner";
import CallToAction from "./components/CallToAction"
import Api from '../assets/js/utils/Api';
import Debug from '../views/components/Debug';
import FixedSidebar from "./components/FixedSidebar";
import ScrollTop from "./components/ScrollTop";
import withStyles from "@material-ui/core/styles/withStyles";
import SplashScreen from './components/SplashScreen';
import SystemNotifications from "./components/SystemNotifications";
import ManageSession from "./components/ManageSession";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Config from "../../Config";

import "../assets/fonts/gotham/all.css";
import '../assets/scss/App.scss';
import {
    primaryColor,
    grayColor,
    whiteColor,
    infoColor,
    roseColor,
    successColor
} from "../assets/jss/material-kit-pro-react.jsx";
import appStyle from "../assets/jss/appStyle.jsx";

const history = createBrowserHistory();
history.listen(function (location) {
    setTimeout(function(){
        window.gtag('pageTitle', document.title);
        window.gtag('event', 'page_view',{
          'page_path': window.location.href
        });

        window.fbq('track', 'PageView');
    },1000);

    var requestData = [];
    requestData.push({
        'activityType': "viewed_page",
        'url': window.location.href,
        'data': Cookie.getAdsData()
    });
    
    Api.sendActivity(requestData);
    Api.sendAdClickedActivity();
    if(process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true"){
        console.log("React-Router: "+location.pathname);
    }
    window.scrollTo(0, 0);
});

class App extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.loading = this.props.loading;
        this.state = this.store.getState()
    }
    componentDidMount(){
        const that = this;
        this.loadTopics();
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken === false){
            this.loading = false
            that.store.dispatch({type: 'UPDATE_STATE', state: {}}); 
        }
        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){
                that.handleUserInfo(isValidToken,false);
                AuthApi.idleRefreshToken();
            }
        }).catch(err => {
            this.loading = false;
            that.store.dispatch({type: 'UPDATE_STATE',state: {}});
            console.log(err);
        });

        if(process.env.REACT_APP_ENV !== "production"){
            console.log(process.env);
        }

        this.trackAds();        
        let returnUrl = helper.getParam("rte");
        if(returnUrl && returnUrl.length > 0 && returnUrl !== "null"){
            returnUrl = window.location.search.replace("?rte=","");
            const previewPageRedirect = localStorage.getItem("preview_redirect");
            if(hasAccessToken && previewPageRedirect === "1"){
                returnUrl = "/dashboard/editmode/search"+window.location.search;
            }
            this.store.dispatch({type: 'UPDATE_STATE',state: {url: "/"+returnUrl }});
            history.push(returnUrl);
            this.listenHistory();
        }else{
            const previewPageRedirect = localStorage.getItem("preview_redirect");
            if(hasAccessToken && previewPageRedirect === "1"){
                history.push("/dashboard/editmode/search"+window.location.search);
            }
            //View page activity request
            if(window.location.pathname !== "/" || true){
                var requestData = [];
                requestData.push({
                    'activityType': "viewed_page",
                    'url': window.location.href,
                    'data': Cookie.getAdsData()
                });
                Api.sendActivity(requestData);
                Api.sendAdClickedActivity();
            }
            this.listenHistory();
            this.sendGooglePageView();
        }        

        const code = helper.getParam("code");    
        if(code && code.length > 0 && code !== "null"){
            // that.store.dispatch({type: 'UPDATE_STATE',state: {mainLoading: true }});
            // AuthApi.validateCode(code,function(data){
            //     AuthApi.updateTokens(data,true);
            //     that.getUserInfo(true);                      
            // },function(err){
            //     if(err.response !== 'undefined'){
            //         AuthApi.logout();
            //         that.store.dispatch({type: 'LOGOUT'});
            //     }else{
            //         that.store.dispatch({type: 'LOGOUT'});
            //     }                
            // });
        }else if(hasAccessToken === false && that.state.loggedIn){
            AuthApi.logout();
            this.store.dispatch({type: 'LOGOUT'});
        }
    }
    getUserInfo(login = false){
        const that = this;
        AuthApi.getUserInfo(function(data){
            that.handleUserInfo(data,login);
        },function(err){
            if(err.response !== 'undefined'){
                AuthApi.logout();
                that.store.dispatch({type: 'LOGOUT'});
            }else{
                that.store.dispatch({type: 'LOGOUT'});
            }
        });
    }
    handleUserInfo(data,login = false){
        const that = this;
        if(data.numberOfResponses > 0){
            data.response.map(user => {
                let actionType = 'UPDATE_STATE';
                if(login && user.hasOwnProperty("cognitoForcePasswordReset") && user.cognitoForcePasswordReset && user.username === user.userId){
                    history.push('/user/onboarding');
                    // if(login){
                        actionType = 'LOGIN';
                    // }
                }else{
                    if(login){
                        if(user.username === user.userId){
                            history.push('/user/onboarding');
                        }else{
                            history.push('/dashboard');
                        }
                        actionType = 'LOGIN';
                    }
                }
                user = Api.prepareMemberObject(user);
                // user.contentPlatforms.map((platform) => {
                //     if(platform.type === "youtubeAPI" || platform.type === "instagramAPI"){
                //         platform['error'] = true;
                //     }
                //     return null;
                // });
                var fullName = "";
                if(user.hasOwnProperty("firstName")){
                    fullName = user.firstName;
                }
                if(user.hasOwnProperty("lastName")){
                    fullName += " "+user.lastName;
                }

                try{
                    if('FS' in window){
                        window.FS.identify(user.userId, {
                            displayName: fullName.trim(),
                            email: user.userEmail,
                            userId: user.userId
                        });
                    }
                }catch(e){  }

                that.store.dispatch({
                    type: actionType,
                    state: {
                        authorized: true,
                        user: user
                    }
                });
                
                return null;
            });
        }
    }
    updateState(state){
        if(!state.loggedIn){
            this.store.dispatch({type: 'LOGOUT'});
        }    
    }
    logout(){
        history.push("/logout");
        AuthApi.logout();
        this.store.dispatch({type: 'LOGOUT'});
    }
    listenHistory(){
        const that = this;
        history.listen(function (location) {
            if(location.hash.length <= 0){
                that.store.dispatch({type: 'UPDATE_STATE',state: {url: location.pathname }});
                that.trackAds();
                that.handleFullStory(location);
            }
        });
    }
    handleFullStory(location){
        const { authorized, fullstory } = this.store.getState();
        const url = location.pathname;
        const hasAccessToken = AuthApi.hasAccessToken();
        if('FS' in window){
            if(fullstory.active !== true){
                window.FS.shutdown();
                return;
            }
            if(!hasAccessToken && !authorized && fullstory.anonymous === false ){
                window.FS.shutdown();
                return;
            }
            if(fullstory.routes.length > 0){
                let matchRoute = false;
                fullstory.routes.map((route) => {
                    let hasRoute = matchPath(url, {
                        path: route,
                    });
                    if(hasRoute !== null){
                        matchRoute = true;
                    }
                    return null;
                });
                if(matchRoute === false){
                    window.FS.shutdown();
                    return;
                }
            }
            window.FS.restart();
        }
    }
    loadTopics(){
        const that = this;
        Api.getSettings(true).then(data => {
            const topics = data.en.topics;
            const packages = data.en.packages;
            const onboarding = data.en.onboarding;
            const notifications = data.en.notifications;
            const fullstory = data.fs;
            localStorage.setItem("searchmysocial_topics",JSON.stringify(topics));
            localStorage.setItem("searchmysocial_packages",JSON.stringify(packages));
            localStorage.setItem("searchmysocial_onboarding",JSON.stringify(onboarding));
            localStorage.setItem("searchmysocial_sysnotifications",JSON.stringify(notifications));
            localStorage.setItem("searchmysocial_fullstory",JSON.stringify(fullstory));
            localStorage.setItem("searchmysocial_settings",JSON.stringify(data));
            const state = that.store.getState();
            let newState = {topics: topics, packages: packages, onboarding: onboarding, notifications: notifications, fullstory: fullstory, settings: data };
            if(state.topics.length <= 0 || state.packages.length <= 0 || state.onboarding.length <= 0 || 
                (state.notifications.length <= 0 && notifications.length > 0) || (state.settings === null)){
                that.store.dispatch({type: 'UPDATE_STATE',state: newState});
            }
        });
    }
    trackAds(){
        const gclid = helper.getParam("gclid");
        if(gclid && gclid.length > 0 && gclid !== "null"){
            Cookie.write("ad_gclid", gclid);
        }

        const fbclid = helper.getParam("fbclid");
        if(fbclid && fbclid.length > 0 && fbclid !== "null"){
            Cookie.write("ad_fbclid", fbclid);
        }
    }
    sendGooglePageView(){
        try{
            setTimeout(function(){
                window.gtag('pageTitle', document.title);
                window.gtag('event', 'page_view',{
                  'page_path': window.location.href
                });

                window.fbq('track', 'PageView');
            },1000);
        }catch(e){ console.log(e); }
    }
    getUserTheme(){
        const storeState = this.store.getState();
        let user = storeState.user;
        const { searchedUser, url } = storeState;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(searchMatch !== null && !searchMatch.params.hasOwnProperty("username")){
            searchMatch.params["username"] = "";
        }
        if(searchMatch !== null && typeof(searchedUser) == "object" && url.indexOf(searchedUser.username) !== -1 && searchMatch.params.username !== user.username){
            user = searchedUser;
        }
        let buttonText = whiteColor;
        let iconColor = whiteColor;
        if(user.guestPreferences.theme === "dark"){
            buttonText = grayColor[27];
            iconColor = grayColor[28];
        }
        let themeOptions = {
            palette: {
                primary: {
                    main: primaryColor[0],
                    light: grayColor[5],
                    dark: grayColor[20],
                    contrastText: whiteColor,
                },
                secondary: {
                    main: infoColor[0],
                    light: infoColor[6],
                    dark: infoColor[0],
                    contrastText: whiteColor,
                },
                custom: {
                    main: primaryColor[0],
                    buttonText: buttonText,
                    iconColor: iconColor
                }
            },
            typography: {
                fontFamily: [
                    '"Gotham-Book"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif'
                ].join(',')
            },
            customize: {
                fontSize: "14px"
            }
        };
        let color = primaryColor[0];
        let isCustomColor = helper.isCustomColor(user.guestPreferences.primaryColor);
        if(isCustomColor){
            color = user.guestPreferences.primaryColor;
        }else{
            switch(user.guestPreferences.primaryColor){
                case 'gray':
                    color = grayColor[26];
                    break;
                case 'customInfo':
                    color = infoColor[8];
                    break;
                case 'customRose':
                    color = roseColor[5];
                    break;
                case 'customSuccess':
                    color = successColor[8];
                    break;
                default:
                    color = primaryColor[0];
                    break;
            }
        }
        themeOptions.palette.custom.main = color;
        themeOptions.customize.fontSize = (user.guestPreferences.fontSize === "large" ? "14px" : "12px");
        const muiTheme = createMuiTheme(themeOptions);
        return muiTheme;
    }
    getHeadScripts(){
        const { url, authorized, user, fullstory, settings } = this.store.getState();
        const organizationId = fullstory.org
        const hasAccessToken = AuthApi.hasAccessToken();
        let headScripts = [];
        let statusPageScript = false;
        if(settings !== null && settings.hasOwnProperty("trust") && settings.trust.visibility === "public"){
            statusPageScript = true;
        }else{
            const dashboardRoute = matchPath(url, {
                path: "/dashboard",
            });
            if(dashboardRoute !== null){
                statusPageScript = true;
            }
        }
        if(statusPageScript){
            headScripts.push({
                type: 'text/javascript',
                src: "https://z5bwg97j9kny.statuspage.io/embed/script.js"
            });
        }
        if(hasAccessToken && user.userLevel >= 1){
            headScripts.push({
                type: 'text/javascript',
                id: "ze-snippet",
                src: "https://static.zdassets.com/ekr/snippet.js?key=ba42736d-08b8-4cd0-b8ff-c3512830e2cf"
            });
        }
        if(!authorized && Config.isRecaptchaEnabled()){
            headScripts.push({
                type: 'text/javascript',
                async: "async",
                defer: true,
                src: "https://www.google.com/recaptcha/api.js?onload=&render=explicit"
            });
        }
        if(fullstory.active !== true){
            return headScripts;
        }
        if(!hasAccessToken && !authorized && fullstory.anonymous === false ){
            return headScripts;
        }
        if(fullstory.routes.length > 0){
            let matchRoute = false;
            fullstory.routes.map((route) => {
                let hasRoute = matchPath(url, {
                    path: route,
                });
                if(hasRoute !== null){
                    matchRoute = true;
                }
                return null;
            });
            if(matchRoute === false){
                return headScripts;
            }
        }
        if(!user.userPreferences.fullstoryOptOut){
            headScripts.push({
                type: 'text/javascript', 
                innerHTML: "window['_fs_debug'] = false;"+
                    "window['_fs_host'] = 'fullstory.com';"+
                    "window['_fs_script'] = 'edge.fullstory.com/s/fs.js';"+
                    "window['_fs_org'] = '"+organizationId+"';"+
                    "window['_fs_namespace'] = 'FS';"+
                    "(function(m,n,e,t,l,o,g,y){"+
                        "if (e in m) {if(m.console && m.console.log) { /*m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');*/} return;}"+
                        "g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];"+
                        "g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};"+
                        "g.shutdown=function(){g('rec',!1)};g.restart=function(){g('rec',!0)};"+
                        "g.log = function(a,b) { g('log', [a,b]) };"+
                        "g.consent=function(a){g('consent',!arguments.length||a)};"+
                        "g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};"+
                        "g.clearUserCookie=function(){};"+
                    "})(window,document,window['_fs_namespace'],'script','user');"
            });
            headScripts.push({
                type: 'text/javascript',
                src: 'https://edge.fullstory.com/s/fs.js',
                async: 1,
                crossOrigin:'anonymous'
            });
        }

        return headScripts;
    }
    getDefaultCover(){
        const { url, authorized, user, searchedUser } = this.store.getState();
        let defaultCover = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(searchMatch !== null && !searchMatch.params.hasOwnProperty("username")){
            searchMatch.params["username"] = "";
        }
        if(searchMatch !== null && !helper.isUsernameMatchingRoute(searchMatch.params.username)){
            //If non logged in member
            if(searchMatch.params.username.toLowerCase() !== user.username){
                if(searchedUser && searchedUser.hasOwnProperty("coverMedia")){
                    defaultCover = searchedUser.coverMedia.downloadUrlMain;
                }
            }else if(authorized){
                if(user.hasOwnProperty("coverMedia")){
                    defaultCover = user.coverMedia.downloadUrlMain;
                }                
            }
        }

        if(searchMatch !== null && searchMatch.params.username.toLowerCase() !== user.username && !helper.isUsernameMatchingRoute(searchMatch.params.username)){
            defaultCover = null;
            if(searchedUser && searchedUser.hasOwnProperty("coverMedia")){
                defaultCover = searchedUser.coverMedia.downloadUrlMain;
            }
        }
        return defaultCover;
    }
    render() {
        const { mainLoading, url, authorized } = this.store.getState();
        const { classes } = this.props;
        const hasAccessToken = AuthApi.hasAccessToken();
        if(process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true"){
            console.log(this.store.getState());
        }
        
        if(mainLoading){
            return <LoaderComponent />
        }

        if(this.loading === true){
            return <SplashScreen />
        }
        

        const isTransparentHeader = helper.isTransparentHeader(url);
        const bodyClass = helper.getBodyClass(url);
        const defaultCover = this.getDefaultCover();
        const innerTheme = this.getUserTheme();
        const headScripts = this.getHeadScripts();

        return (
            <Router history={history}>
                <Helmet 
                    htmlAttributes={{
                        class: bodyClass
                    }}
                    script={headScripts}
                />
                    <div className={classes.mainApp+" "+bodyClass+" App"+(authorized?' user_authorized':'')+(isTransparentHeader?" app_transparent_header":"")}>
                        <MuiThemeProvider theme={innerTheme}>
                            <SystemNotifications store={this.store} history={history} />
                            <Navbar store={this.store} isTransparentHeader={isTransparentHeader} history={history}/>
                            <div className="main-container" id="global_wrapper">
                                <RouterComponent store={this.store} history={history}/>
                            </div>
                            {
                                (url !== "/" || hasAccessToken) ?
                                    <Footer />
                                :
                                <></>
                            }
                            <GpdrBanner />
                            {
                                !authorized ? <CallToAction store={this.store} history={history}/> : <></>
                            }
                            <NewUpdate domain="searchmysocial" />
                            <Debug store={this.store} domain="searchmysocial" />
                            <FixedSidebar store={this.store} />
                            <ScrollTop store={this.store} />
                            {
                                authorized ?
                                    <ManageSession store={this.store} history={history} />
                                :
                                    <></>
                            }
                        </MuiThemeProvider>
                    </div>
                    
                    {
                        defaultCover !== null ?
                            <img src={defaultCover} alt="User Cover" className="user_cover_photo" />
                        :
                        <></>
                    }
            </Router>
        );
    }
}

App.defaultProps = {
    loading: true
}
App.propTypes = {
    loading: PropTypes.bool,
}

export default withStyles(appStyle)(App);
