import {
    whiteColor,
    hexToRgb,
    blackColor,
    grayColor
} from "../../material-kit-pro-react.jsx";

const dashboardTabProfileSearchStyle = theme => ({
    mainContainer: {
        marginTop: theme.spacing(2)
    },
    mainGridItem: {
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
        "& .refresh_icon": {
            width: "30px",
            verticalAlign: "middle",
            marginLeft: "20px",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
                marginLeft: "10px",
            },
        }
    },
    searchButtonGridItem: {
        alignSelf: "center"
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2),
    },
    gridContainer: {
        marginLeft: "0px",
        marginRight: "0px",
        "& > .MuiGrid-item": {
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    },
    searchGridItem: {
        paddingRight: "15px !important"
    },
    clearSearch: {
        padding: "10px 12px",
        marginTop: "0px",
        marginBottom: "0px",
        marginRight: "0px",
        [theme.breakpoints.down("xs")]: {
            padding: "8px 8px",
        },
        "& svg": {
            color: whiteColor,
            margin: "0px"
        }
    },
    center: {
        textAlign: "center"
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
        textAlign: "center"
    },
    guestViewOptions: {
        width: "auto",
        textAlign: "center",
        maxWidth: "220px",
        marginBottom: theme.spacing(2),
        boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& button:hover": {
            backgroundColor: "transparent",
            color: grayColor[8],
        },
        // "& button:focus": {
        //     backgroundColor: "rgba(0, 0, 0, 0.14)",
        //     color: grayColor[8],
        // },
        marginRight: "auto",
        marginLeft: "auto",
        float: "none",
        display: "inline-block"
    },
    activeViewOption: {
        backgroundColor: grayColor[11]+" !important",
        color: grayColor[8]+" !important",
    },
    mobileCenter: {
        textAlign: "center"
    },
    loadMore: {
        margin: "0px auto",
        marginTop: theme.spacing(2)
    },
    featuredContentTooltip: {
        marginLeft: "10px",
        marginTop: "0px",
        float: "none",
    },
    tooltipCustomICon: {
        width: "100%",
        overflow: "hidden",
        paddingLeft: "5px",
        paddingRight: "5px",
        "& .tooltipCustomICon": {
            float: "right",
        }  
    },
    grid: {
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    viewTypeLabel: {
        fontWeight: "bold",
        fontSize: "14px"
    },
    showButtonOption: {
        width: "75px",
        border: "1px dashed "+blackColor,
        padding: "3px",
        textAlign: "center",
        marginBottom: theme.spacing(2),
        display: "inline-block"
    },
    buttons: {
        margin: "0px"
    },
    platformOptions: {
        width: "auto",
        border: "1px dashed "+blackColor,
        padding: "8px",
        textAlign: "center",
        marginBottom: theme.spacing(2),
        display: "inline-block",
        "& button": {
            padding: "6px",
            margin: "0px 6px"
        },
        "& svg": {
            height: "30px",
            width: "30px",
            display: "block"
        },
        "& img": {
            width: "25px",
            display: "block",
            "&.youtube-icon": {
                 width: "30px",
            }
        },
    },
    buttonsMargin: {
        marginBottom: "10px"
    }
});

export default dashboardTabProfileSearchStyle;