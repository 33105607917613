import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import upgradeStep1Style from "../../../../assets/jss/dashboard/userLevelWizard/upgradeStep1Style";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UpgradeStep3 = class extends React.Component {
    render(){
        const { classes } = this.props;
        return(
            <div>
                <div className={classes.center}>
                    <p>Thank You!  Your Payment was accepted and your account is now even more awesome.</p>
                </div>
                <div className={classes.center+" "+classes.marginTopStep2}>
                    <Button onClick={() => this.props.onClose()} color="info" round>Close</Button>
                </div>
            </div>
        )
    }
}

UpgradeStep3.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};
export default withStyles(upgradeStep1Style)(UpgradeStep3);