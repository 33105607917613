/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBack from "@material-ui/icons/ArrowBack";

import wizardStyle from "../../../assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

class Wizard extends React.Component {
    constructor(props) {
        super(props);
        var width;
        if (this.props.steps.length === 1) {
            width = "100%";
        } else {
            if (window.innerWidth < 600) {
                if (this.props.steps.length !== 3) {
                    width = "50%";
                } else {
                    width = 100 / 3 + "%";
                }
            } else {
                if (this.props.steps.length === 2) {
                    width = "50%";
                } else {
                    width = 100 / 3 + "%";
                }
            }
        }
        const currentStep = this.props.currentStep;
        this.state = {
            currentStep: currentStep !== null ? currentStep : 0,
            color: this.props.color,
            nextButton: this.props.steps.length > 1 ? true : false,
            previousButton: false,
            finishButton: this.props.steps.length === 1 ? true : false,
            width: width,
            movingTabStyle: {
                transition: "transform 0s"
            },
            allStates: {},
            finishButtonText: this.props.finishButtonText,
            nextButtonDisabled: this.props.nextButtonDisabled
        };
        this.navigationStepChange = this.navigationStepChange.bind(this);
        this.refreshAnimation = this.refreshAnimation.bind(this);
        this.previousButtonClick = this.previousButtonClick.bind(this);
        this.previousButtonClick = this.previousButtonClick.bind(this);
        this.finishButtonClick = this.finishButtonClick.bind(this);
        this.updateWidth = this.updateWidth.bind(this);
        this.onStepMount = this.onStepMount.bind(this);
    }
    wizard = React.createRef();
    componentDidMount() {
        this.refreshAnimation(0);
        window.addEventListener("resize", this.updateWidth);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }
    componentWillReceiveProps(props){
        if(props.currentStep !== null && props.currentStep !== this.state.currentStep){
            this.setState({
                currentStep: props.currentStep, 
                finishButtonText: props.finishButtonText,
                nextButton: (this.props.steps.length > props.currentStep),
                previousButton: (props.currentStep > 0),
                finishButton: (this.props.steps.length === props.currentStep)
            });
            this.refreshAnimation(props.currentStep);
        }else if(props.finishButtonText !== this.state.finishButtonText){
            this.setState({finishButtonText: props.finishButtonText});
        }
    }
    updateWidth() {
        this.refreshAnimation(this.state.currentStep);
    }
    onStepMount(stepId, state, isValidated = null){
        const currentStepId = this.props.steps[this.state.currentStep].stepId;
        let newState = {
            allStates: {
                ...this.state.allStates,
                [stepId]: state
            }
        };
        if(isValidated !== null && currentStepId === stepId){
            newState['nextButtonDisabled'] = (isValidated === false);
        }
        this.setState(newState);

        if(this.props.onStepMount){
            this.props.onStepMount(stepId, state);
        }
    }
    onIsValidChange(invalid){
        this.setState({
            nextButtonDisabled: invalid
        });
    }
    navigationStepChange(key) {
        let allStates = this.state.allStates;
        if (this.props.steps) {
        var validationState = true;
        if (key > this.state.currentStep) {
            for (var i = this.state.currentStep; i < key; i++) {
            if (this[this.props.steps[i].stepId].sendState !== undefined) {
                allStates = {
                    ...this.state.allStates,
                    [this.props.steps[i].stepId]: this[
                    this.props.steps[i].stepId
                    ].sendState()
                }
                this.setState({
                allStates: allStates
                });
            }
            if (
                this[this.props.steps[i].stepId].isValidated !== undefined &&
                this[this.props.steps[i].stepId].isValidated() === false
            ) {
                validationState = false;
                break;
            }
            }
        }

        if(validationState){    
            if(this.props.onStepMount){
                this.props.onStepMount(key, allStates, true);
            }
        }
        
        if (validationState) {
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1 ? true : false,
                previousButton: key > 0 ? true : false,
                finishButton: this.props.steps.length === key + 1 ? true : false
            });
            this.refreshAnimation(key);
        }
        }
    }
    nextButtonClick() {
        if (
        (this.props.validate &&
            ((this[this.props.steps[this.state.currentStep].stepId].isValidated !== undefined &&
            this[
                this.props.steps[this.state.currentStep].stepId
            ].isValidated()) ||
            this[this.props.steps[this.state.currentStep].stepId].isValidated === undefined)) ||
        this.props.validate === undefined
        ) {
            if (this[this.props.steps[this.state.currentStep].stepId].sendState !== undefined) {
                var currentStepState = this[
                    this.props.steps[this.state.currentStep].stepId
                ].sendState();
                var currentStepId = this.props.steps[this.state.currentStep].stepId;

                const allStates = {
                    ...this.state.allStates,
                    [currentStepId]: currentStepState
                };
                this.setState({
                    allStates: allStates
                });

                
                if(this.props.onStepMount){
                    this.props.onStepMount(this.state.currentStep, allStates, true);
                }
            }
            var key = this.state.currentStep + 1;
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1 ? true : false,
                previousButton: key > 0 ? true : false,
                finishButton: this.props.steps.length === key + 1 ? true : false
            }, () => {
                const nextComponent = this[
                    this.props.steps[key].stepId
                ];
                if(typeof(nextComponent) === "object" && nextComponent.hasOwnProperty("onNextStep")){
                    nextComponent.onNextStep();
                }
            });
            this.refreshAnimation(key);
        }
    }
    previousButtonClick() {
        var key = this.state.currentStep - 1;
        if (this[this.props.steps[this.state.currentStep].stepId].sendState !== undefined) {
            const allStates =  {
                ...this.state.allStates,
                [this.props.steps[this.state.currentStep].stepId]: this[
                this.props.steps[this.state.currentStep].stepId
                ].sendState()
            };
            this.setState({
                allStates: allStates
            });
            
            if(this.props.onStepMount){
                this.props.onStepMount(key, allStates, true);
            }
        }
        if (key >= 0) {
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1 ? true : false,
                previousButton: key > 0 ? true : false,
                finishButton: this.props.steps.length === key + 1 ? true : false
            });
            this.refreshAnimation(key);
        }
    }
    finishButtonClick() {
        if ((this.props.validate === false && this.props.finishButtonClick !== undefined) || (this.props.validate &&
            ((this[this.props.steps[this.state.currentStep].stepId].isValidated !== undefined &&
            this[
                this.props.steps[this.state.currentStep].stepId
            ].isValidated()) ||
            this[this.props.steps[this.state.currentStep].stepId].isValidated === undefined) && this.props.finishButtonClick !== undefined)
        ) {
            const currentStep = this.props.steps.length - 1;
            this.setState({
                currentStep: currentStep,
                allStates: {
                    ...this.state.allStates,
                    [this.props.steps[this.state.currentStep].stepId]: this[
                    this.props.steps[this.state.currentStep].stepId
                    ].sendState()
                }},
                () => {
                    this.props.finishButtonClick(this.state.allStates);
                }
            );
        }
    }
    refreshAnimation(index) {
        var total = this.props.steps.length;
        var li_width = 100 / total;
        var total_steps = this.props.steps.length;
        var move_distance =
        this.wizard.current.children[0].offsetWidth / total_steps;
        var index_temp = index;
        var vertical_level = 0;

        //var mobile_device = window.innerWidth < 600 && total > 3;
        var mobile_device = false;

        if (mobile_device) {
            move_distance = this.wizard.current.children[0].offsetWidth / 2;
            index_temp = index % 2;
            li_width = 50;
        }

        this.setState({ width: li_width + "%" });

        var step_width = move_distance;
        move_distance = move_distance * index_temp;

        var current = index + 1;

        if (current === 1 || (mobile_device === true && index % 2 === 0)) {
            move_distance -= 8;
        } else if (
            current === total_steps ||
            (mobile_device === true && index % 2 === 1)
        ) {
            move_distance += 8;
        }

        if (mobile_device) {
            vertical_level = parseInt(index / 2, 10);
            vertical_level = vertical_level * 38;
        }
        var movingTabStyle = {
            width: step_width,
            transform:
                "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
            transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
        };
        this.setState({ movingTabStyle: movingTabStyle });
    }
    render() {
        const { classes, title, subtitle, color, steps } = this.props;
        return (
        <div className={"wizardContainer "+classes.wizardContainer} ref={this.wizard}>
            <Card className={classes.card}>
            <div className={"wizardHeader "+ classes.wizardHeader}>
                <h3 className={classes.title}>{title}</h3>
                <h5 className={classes.subtitle}>{subtitle}</h5>
            </div>
            <div className={classes.wizardNavigation}>
                <ul className={classes.nav}>
                {steps.map((prop, key) => {
                    return (
                    <li
                        className={classes.steps}
                        key={key}
                        style={{ width: this.state.width }}
                    >
                        <a
                        href="#pablo"
                        className={classes.stepsAnchor}
                        onClick={e => {
                            e.preventDefault();
                            this.navigationStepChange(key);
                        }}
                        >
                        {prop.stepName}
                        </a>
                    </li>
                    );
                })}
                </ul>
                <div
                className={classes.movingTab + " " + classes[color]}
                style={this.state.movingTabStyle}
                >
                {steps[this.state.currentStep].stepName}
                </div>
            </div>
            <div className={"wizardContent "+classes.content}>
                {steps.map((prop, key) => {
                const stepContentClasses = cx({
                    [classes.stepContentActive]: this.state.currentStep === key,
                    [classes.stepContent]: this.state.currentStep !== key
                });
                return (
                    <div className={stepContentClasses} key={key}>
                    <prop.stepComponent
                        innerRef={node => (this[prop.stepId] = node)}
                        allStates={this.state.allStates}
                        store={this.props.store}
                        onMount={(state, isValidated) => this.onStepMount(prop.stepId, state, isValidated)}
                        onIsValidChange={(invalid) => this.onIsValidChange(invalid)}
                        {...prop.props}
                    />
                    </div>
                );
                })}
            </div>
            <div className={classes.footer}>
                <div className={classes.left}>
                {this.state.previousButton ? (
                    <Button
                    color="white"
                    className={this.props.previousButtonClasses}
                    onClick={() => this.previousButtonClick()}
                    >
                    <ArrowBack />
                    {this.props.previousButtonText}
                    </Button>
                ) : null}
                </div>
                <div className={classes.right}>
                {this.state.nextButton ? (
                    <Button
                    color={(this.state.nextButtonDisabled ? null : "blue")}
                    className={this.props.nextButtonClasses}
                    onClick={() => this.nextButtonClick()}
                    >
                    {this.props.nextButtonText}
                    </Button>
                ) : null}
                {this.state.finishButton ? (
                    <Button
                    color="success"
                    className={this.finishButtonClasses}
                    onClick={() => this.finishButtonClick()}
                    >
                    <SaveIcon />
                    {this.state.finishButtonText}
                    </Button>
                ) : null}
                </div>
                <div className={classes.clearfix} />
            </div>
            </Card>
        </div>
        );
    }
}

Wizard.defaultProps = {
    color: "rose",
    title: "Here should go your title",
    subtitle: "And this would be your subtitle",
    previousButtonText: "Previous",
    previousButtonClasses: "",
    nextButtonClasses: "",
    nextButtonText: "Next",
    finishButtonClasses: "",
    finishButtonText: "Finish",
    onStepMount: null,
    currentStep: null,
    nextButtonDisabled: false
};

Wizard.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepName: PropTypes.string.isRequired,
            stepComponent: PropTypes.object.isRequired,
            stepId: PropTypes.string.isRequired,
            props: PropTypes.object
        })
    ).isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    previousButtonClasses: PropTypes.string,
    previousButtonText: PropTypes.string,
    nextButtonClasses: PropTypes.string,
    nextButtonText: PropTypes.string,
    finishButtonClasses: PropTypes.string,
    finishButtonText: PropTypes.string,
    finishButtonClick: PropTypes.func,
    validate: PropTypes.bool,
    onStepMount: PropTypes.func,
    currentStep: PropTypes.number,
    nextButtonDisabled: PropTypes.bool
};

export default withStyles(wizardStyle)(Wizard);
