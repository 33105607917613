import React from 'react';
import PropTypes from "prop-types";
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardTabAccountSettings from "../account/Settings";
import DashboardTabAccountPayments from "../account/Payments";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent.jsx";
import Link from "@material-ui/core/Link";

import MaterialIcon from "@mdi/react";
import { mdiAccountSettings, mdiCurrencyUsd } from '@mdi/js';

import dashboardTabAccountStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabAccountStyle.js";
const history = createBrowserHistory();

const DashboardTabAccount = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;
        this.navPills = React.createRef();
        let defaultTab = this.props.params.subTab;
        if(defaultTab >= 2){
            defaultTab = 0;
        }

        this.state = {
            defaultTab: defaultTab,
            match: this.props.match,
            values: {
                paymentToken: "",
                paymentLast4Digits: "",
                paymentExpirationDate: "",
                existingCardLoaded: false,
                stripeData: null,
                hasError: false
            },
            cancelToken: null,
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.onPaymentDataChanged = this.onPaymentDataChanged.bind(this);
        this.fixCardInfo = this.fixCardInfo.bind(this);
    }
    onPaymentDataChanged(data){
        this.props.onPaymentDataChanged(data);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/account/settings");
                break;
            case 1:
                history.push("/dashboard/account/payments");
                break;
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    fixCardInfo(event){
        const tab = 1;
        event.preventDefault();
        if(this.props.onTabChange && this.props.isActiveTab !== true){
            this.props.onTabChange(7, true);
        }
        history.push("/dashboard/account/payments");
        this.setState({
            defaultTab: tab,
        });
        this.navPills.current.handleChange(null, tab, false);
    }
    renderCardExpiring(){
        return (
            <SnackbarContent
                message={
                    <p>Your credit card will expire soon. <Link href="/" onClick={this.fixCardInfo}>Click Here</Link> to update your payment information.</p>
                }
                color="danger"
            />
        );
    }
    renderCardExpired(){
        return (
            <SnackbarContent
                message={
                    <p>Your credit card information needs to be updated. <Link href="/" onClick={this.fixCardInfo}>Click Here</Link> to Fix It</p>
                }
                color="danger"
            />
        );
    }
    render() {
        const { classes, paymentData, subscription } = this.props;
        const { defaultTab } = this.state;
        const { user, authorized } = this.store.getState();
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);

        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    horizontal={{
                        tabsGrid: { className: (showLabels ? "showLabels" : "") },
                    }}
                    alignCenter
                    color="primary"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    tabs={[
                        {
                            tabButton: (showLabels ? "Account" : ""),
                            tabImageIcon: <MaterialIcon path={mdiAccountSettings} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabAccountSettings subscription={subscription} paymentData={paymentData} match={this.props.match} store={this.store} history={this.history} onSubscriptionNotice={this.props.onSubscriptionNotice} onCardExpiredNotice={this.props.onCardExpiredNotice} />
                            ),
                        },
                        {
                            tabButton: (showLabels ? "Payment" : ""),
                            tabImageIcon: <MaterialIcon path={mdiCurrencyUsd} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabAccountPayments paymentData={paymentData} match={this.props.match} onPaymentDataChanged={this.onPaymentDataChanged} store={this.store} history={this.history} />
                            ),
                        }
                    ]}
                />
            </div>
        )
    }
}

DashboardTabAccount.defaultProps = {
    onSubscriptionNotice: function(){ },
    onCardExpiredNotice: function(){ },
    onTabChange: function(){ },
    subscription: null,
    paymentData: null,
    onPaymentDataChanged: function(){ }
}
DashboardTabAccount.propTypes = {
    onSubscriptionNotice: PropTypes.func,
    onCardExpiredNotice: PropTypes.func,
    onTabChange: PropTypes.func,
    subscription: PropTypes.object,
    paymentData: PropTypes.object.isRequired,
    onPaymentDataChanged: PropTypes.func
}

export default withStyles(dashboardTabAccountStyle)(DashboardTabAccount);
