import { makeStyles } from '@material-ui/core/styles';

import {
    whiteColor,
} from "../material-kit-pro-react.jsx";

const useStyles = makeStyles(theme => ({
    title: {
        margin: theme.spacing(1, 0, 1),
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        }
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconButton: {
        marginTop: '4px',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },
    close: {
        padding: theme.spacing(0.5),
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
}));

export default useStyles;