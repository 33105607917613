import { grayColor, primaryColor, whiteColor } from "../../material-kit-pro-react.jsx";

const coverPhotoStyle = theme => ({
    coverPhoto: {
        maxWidth: "100%",
        cursor: "pointer",
    },
    coverPhotoWithArrows: {
        width: "calc( 100% - 43px )"
    },
    loaderImage: {
        position: "relative",
        backgroundColor: grayColor[23],
        "& .sd_loader": {
            width: "80px !important",
            height: "80px !important",
            [theme.breakpoints.down('xs')]: {
                width: "50px !important",
                height: "50px !important",
            }
        },
        "& .MuiCircularProgress-root": {
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "20px !important",
                height: "20px !important",
            }
        }
    },
    loaderImageWithArrows: {
        display: "inline-flex",
        verticalAlign: "middle",
    },
    noPicture: {
        background: primaryColor[0],
        color: whiteColor,
        padding: theme.spacing(2,1),
        cursor: "pointer",
        height: "150px",
        [theme.breakpoints.down('sm')]: {
            height: "100px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "50px",
        }
    },
    noPictureWithArrows: {
        width: "calc( 100% - 43px )",
        verticalAlign: "middle",
        display: "inline-flex",
        [theme.breakpoints.down('xs')]: {
            width: "calc( 100% - 24px )",
        }
    }
});

export default coverPhotoStyle;
