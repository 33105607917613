import {
    grayColor,
    defaultFont,
    infoColor,
    roseColor,
    successColor
} from "../../material-kit-pro-react.jsx";

const tagsInputStyle = {
    disabled: {
        "&:before": {
        backgroundColor: "transparent !important"
        }
    },
    labelRoot: {
        ...defaultFont,
        color: grayColor[12] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "10px",
        letterSpacing: "unset",
        "& + $underline": {
            marginTop: "0px"
        },
        transform: "scale(1)",
        position: "static"
    },
    formControl: {
        margin: "0",
        paddingTop: "27px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        },
    },
    input: {
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1",
        },
        "&::placeholder": {
            color: grayColor[12]
        }
    },
    animate: {
        animation: "customSpin 1.3s infinite linear"
    },
    tagsInput: {
        borderBottomColor: grayColor[11]+" !important",
        borderBottomWidth: "1px !important",
        borderBottomStyle: "solid",
        paddingLeft: "0px",
        "& input": {
            marginBottom: "0px",
            width: "120px"
        },
        "& .react-tagsinput-tag": {
            margin: "3px 3px 3px 0px",
            paddingTop: "5px",
            paddingBottom: "5px",
            "& a": {
                top: "5px"
            },
        },
        "&.react-tagsinput-disabled": {
            "& .react-tagsinput-tag:hover": {
                paddingRight: "8px",
                cursor: "default"
            },
            "& input": {
                display: "none"
            }
        },
        "& .gray": {
            backgroundColor: grayColor[26],
        },
        "& .customInfo": {
            backgroundColor: infoColor[8],
        },
        "& .customRose": {
            backgroundColor: roseColor[5],
        },
        "& .customSuccess": {
            backgroundColor: successColor[8],
        }
    },
    tagsNote: {
        fontSize: "12px",
    }
};

export default tagsInputStyle;
