import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from "../Loader";
import Button from "../CustomButtons/Button.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import loadingModalStyle from "../../../assets/jss/dashboard/loadingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LoadingModal = class extends React.Component {
    render() {
        const { classes, message } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="loading-modal-slide-title"
                aria-describedby="loading-modal-slide-description"
            >
                <DialogTitle
                    id="loading-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <h4 className={classes.modalTitle+" "+classes.textCenter}>{message}</h4> 
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                </DialogTitle>
                <DialogContent
                    id="loading-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <LoaderComponent align="center" color="success" />
                </DialogContent>
            </Dialog>
        )
    }
}

LoadingModal.defaultProps = {
    open: false,
}
LoadingModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    message: PropTypes.string
}
export default withStyles(loadingModalStyle)(LoadingModal);