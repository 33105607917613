import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from "../../Loader";
import Link from "@material-ui/core/Link";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import processingModalStyle from "../../../../assets/jss/dashboard/editmode/processingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ProcessingModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }
    render() {
        const { classes, error } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal+" "+classes.modalCustom
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="processing-modal-slide-title"
                aria-describedby="processing-modal-slide-description"
            >
                <DialogContent
                    id="processing-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <div className={classes.loading}>
                        {
                            error ?
                                <div className={classes.error}><Close /></div>
                            :
                            <div><LoaderComponent align="center" color="success" /></div>
                        }
                        {
                            error ?
                                <p>
                                    Whoops! There seems to be a problem...
                                    <br />
                                    Please <Link href={"/dashboard/editmode/search"+window.location.search}>refresh</Link> and try again!
                                </p>
                            :
                                <p>Please Wait...</p>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

ProcessingModal.defaultProps = {
    open: false,
}
ProcessingModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default withStyles(processingModalStyle)(ProcessingModal);