import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LoaderComponent from "../Loader";
import CheckIcon from "@material-ui/icons/Check";
import Button from "../CustomButtons/Button.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import paymentProcessingModalStyle from "../../../assets/jss/payment/paymentProcessingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PaymentProcessingModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }
    renderSubscriptionStatus(){
        let { subscription, classes, errorMessage, onBoarding } = this.props;
        if(subscription === null || !subscription.hasOwnProperty("subscriptionStatus")){
            subscription = {
                subscriptionStatus: "error"
            };
        }
        if(errorMessage === null){
            errorMessage = "There was an error changing your subscription.";
        }
        let statusIcon = null;
        switch(subscription.subscriptionStatus){
            case "active":
                statusIcon = <div className={classes.statusIcon}><CheckIcon /></div>
                break;
            case "trialing":
                break;
            case "incomplete":
                statusIcon = <div className={classes.errorIcon}>
                    <Close />
                    <div>
                        <Button onClick={() => this.props.onMethodChange()} color="info" round>
                            Try a different pament method
                        </Button>
                        <Button onClick={() => this.props.onFree()} color="info" round>
                            Just create me as a free account
                        </Button>
                        <a href="mailto:billing@smearch.com" className={classes.contactBilling}>Contact Billing Support</a>
                    </div>
                </div>
                break;
            default:
                statusIcon = <div className={classes.errorIcon}>
                    <Close />
                    <div>
                        <p>{errorMessage}</p>
                        <Button onClick={() => this.props.onMethodChange()} color="info" round>
                            Try a different pament method
                        </Button>
                        {
                            onBoarding ?
                                <Button onClick={() => this.props.onFree()} color="info" round>
                                    Just create me as a free account
                                </Button>
                            :
                            <></>
                        }
                        <a href="mailto:billing@smearch.com" className={classes.contactBilling}>Contact Billing Support</a>
                    </div>
                </div>
                break;
        }
        return statusIcon;
    }
    subscriptionStatusTitle(){
        let { subscription } = this.props;
        let title = null;
        if(subscription === null || !subscription.hasOwnProperty("subscriptionStatus")){
            subscription = {
                subscriptionStatus: "error"
            };
        }
        switch(subscription.subscriptionStatus){
            case "active":
                title = "Success!"
                break;
            case "incomplete":
                title = "There was an issue while processing your payment"
                break;
            case "trialing":
                title = "Trial"
                break;
            default:
                title = "Error!"
                break; 
        }
        return title;
    }
    getPaymentMessage(){
        const { coupon } = this.props;
        if(coupon !== null && coupon.percent_off === 100){
            return "Please wait while we setup your account";
        }
        return "Please wait while we process your payment";
    }
    render() {
        const { classes, saving, subscription, updateUserInfo } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="paymentProcessing-modal-slide-title"
                aria-describedby="paymentProcessing-modal-slide-description"
            >
                <DialogTitle
                    id="paymentProcessing-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            updateUserInfo ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>Please wait while we update info</h4> 
                            :
                            saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>{this.getPaymentMessage()}</h4> 
                            :
                                subscription !== null && subscription.hasOwnProperty("subscriptionStatus") ?
                                    <h4 className={classes.modalTitle+" "+classes.textCenter}>{this.subscriptionStatusTitle()}</h4> 
                                :
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>Error</h4> 
                        }
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                </DialogTitle>
                <DialogContent
                    id="paymentProcessing-modal-slide-description"
                    className={classes.modalBody}
                    >
                    {
                        (saving || updateUserInfo) ?
                            <LoaderComponent align="center" color="success" />
                        :
                            this.renderSubscriptionStatus()
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

PaymentProcessingModal.defaultProps = {
    open: false,
    saving: false,
    onBoarding: false,
    coupon: null
}
PaymentProcessingModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool,
    onFree: PropTypes.func,
    onMethodChange: PropTypes.func,
    updateUserInfo: PropTypes.bool,
    onBoarding: PropTypes.bool,
    coupon: PropTypes.object
}
export default withStyles(paymentProcessingModalStyle)(PaymentProcessingModal);