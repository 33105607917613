import React from "react";
import Button from "../CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import tourTooltipStyle from "../../../assets/jss/dashboard/tourTooltipStyle.jsx";

class TourTooltip extends React.Component {
    render() {
        const {
            index,
            size,
            step,
            backProps,
            closeProps,
            primaryProps,
            tooltipProps,
            skipProps
        } = this.props;
        const { classes } = this.props;
        return (
            <div {...tooltipProps} className={classes.body}>
                <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={skipProps.onClick}
                >
                    <Close className={classes.modalClose} />
                </Button>
                {step.title && <div>{step.title}</div>}
                <div className={classes.content}>{step.content}</div>
                <div className={classes.footer}>
                    {index > 0 && ( 
                        <Button
                            color="white"
                            disabled={index <= 0}
                            className={classes.back}
                            {...backProps}
                        >
                            <ArrowBackIcon className={classes.arrow} /> Back
                        </Button>
                    )}
                    {index < size - 1 && (
                        <Button
                        color="blue"
                        className={classes.next}
                        {...primaryProps}
                        >
                        Next
                        </Button>
                    )}
                    {index === size - 1 && (
                        <Button
                            color="success"
                            className={classes.finish}
                            {...closeProps}
                        >
                        Done
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(tourTooltipStyle)(TourTooltip);
