import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteAccountModal from "../../../assets/jss/dashboard/deleteAccountModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeletePaymentMethodModal = class extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.props.onClose()}
        aria-labelledby="paymentMethod-slide-title"
        aria-describedby="paymentMethod-slide-description"
      >
        <DialogTitle
          id="paymentMethod-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Delete Payment Method</h4>
        </DialogTitle>
        <DialogContent
          id="paymentMethod-slide-description"
          className={classes.modalBody}
        >
          <p>If you remove your payment method, you will be downgraded to a free account at your next billing cycle.</p>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button onClick={() => this.props.onSuccess()} color="danger" round>Downgrade Me</Button>
          <Button onClick={() => this.props.onClose()} color="info" round>Nevermind</Button>
        </DialogActions>
      </Dialog>
    );
  }
};

DeletePaymentMethodModal.defaultProps = {
  open: false
};
DeletePaymentMethodModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(deleteAccountModal)(DeletePaymentMethodModal);
