import {
  primaryColor,
  whiteColor,
  grayColor,
  defaultFont
} from "../../material-kit-pro-react.jsx";

const dateTimePickerStyle = {
    formControl: {
        margin: "0px",
        paddingTop: "0px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: primaryColor[0]
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: primaryColor[0]
        },
        "& .MuiInput-underline, & .MuiInput-underline:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: primaryColor[0]
        },
        "& .MuiFormLabel-root": {
            ...defaultFont,
            color: grayColor[12] + " !important",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "1.42857",
            letterSpacing: "unset",
        },
        "& input": {
            color: grayColor[13],
            height: "unset",
            "&,&::placeholder": {
                fontSize: "14px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400",
                lineHeight: "1.42857",
                opacity: "1"
            },
            "&::placeholder": {
                color: grayColor[12]
            }
        },
    },
    modalRoot: {
        "& .MuiPickersToolbar-toolbar": {
            backgroundColor: primaryColor[0]
        },
        "& .MuiPickerDTTabs-tabs": {
            backgroundColor: primaryColor[0],
            "& .MuiTabs-indicator": {
                backgroundColor: primaryColor[4],
            }
        },
        "& .MuiPickersDay-current": {
            color: whiteColor,
        },
        "& .MuiPickersDay-daySelected": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClock-pin": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-noPoint": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-thumb": {
            borderColor: primaryColor[0],
        },
        "& .MuiPickersClockPointer-pointer": {
            backgroundColor: primaryColor[0],
        },
        "& .MuiButton-text": {
            color: primaryColor[0],
        }
    },
    buttonDisabled: {
        display: "none"
    }
};

export default dateTimePickerStyle;
