import {
    
} from "../../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const dashboardTabAdminEmailStyle = theme => ({
    ...customCheckboxRadioSwitch,
    main: {
        padding: "0px"
    },
    title: {
        margin: theme.spacing(0),
        fontWeight:"bold",
    },
    formControl: {
        // marginBottom: "5px"
    },
    changeButton: {
        alignSelf: "flex-end",
        paddingLeft: "0px",
        "& .MuiButton-root": {
            marginBottom: "0px"
        }
    },
    gridContainer: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px",
            marginRight: "0px",
            "& > .MuiGrid-item": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        }
    },
    formButtons: {
        marginTop: "20px"
    }
});

export default dashboardTabAdminEmailStyle;