import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customSelectStyle from "../../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = theme => ({
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
        "& span": {
            display: "block",
            fontSize: "14px"
        }
    },
    ...customSelectStyle,
    checkboxLabelCustom: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        },
        "& label": {
            color: "inherit"
        },
        "& span": {
            marginLeft: "20px",
        }
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    },
    formControl: {
        "& label": {
            transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            transform: "translate(0, 1.5px) scale(0.75)",
            transformOrigin: "top left"
        }
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.state = {
            allStates: this.props.allStates,
            ownedByMe: false,
            isPublic: false,
            notifyMe: false,
            ownedByMeState: "",
            isPublicState: ""
        };
    }
    componentWillReceiveProps(props){
        this.setState({allStates: props.allStates});
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        return true;
    }
    getChannelTitle(){
        const { youtubePlatform, platform } = this.state.allStates.account;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelTitle")){
            return platform.pendingData.channelTitle;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("pendingData") && youtubePlatform.pendingData.hasOwnProperty("channelTitle")){
            return youtubePlatform.pendingData.channelTitle;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("channelTitle")){
            return youtubePlatform.channelTitle;
        }
        return null;
    }
    render() {
        const { classes } = this.props;
        const { allStates } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>Did we get it right?  <span>(You can always change these settings later)</span></h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        {
                            allStates.hasOwnProperty("account") ?
                                <CustomInput
                                    labelText="YouTube Channel"
                                    inputProps={{
                                        value: this.getChannelTitle(),
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                />
                            :
                            <></>
                        }
                        
                        {
                            allStates.hasOwnProperty("robot") ?
                                <>
                                    {/* <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.showOnHomeFeed}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label={(
                                                allStates.robot.showOnHomeFeed ?
                                                    "Show Instagram Content on Home Feed"
                                                :
                                                "Do NOT show Instagram Content on Home Feed"
                                            )}
                                        />
                                    </div> */}
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.allContentSearchable}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Make All Content Immediately Searchable"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.attemptGeoTag}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Add Geo-Tag information to your search result keywords"
                                        />
                                    </div>
                                </>
                            :
                            <></>
                        }                        
                    </GridItem>
                </GridContainer>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step3.defaultProps = {
    onMount: function(){ }
}
Step3.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step3);
