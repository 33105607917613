import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import Config from "../../../../../Config";
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import Danger from "../../../components/Typography/Danger.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ClearFormModal from "../../../components/Dashboard/ClearFormModal";
import DateTimePicker from "../../../components/CustomInput/DateTimePicker.jsx";

import dashboardTabAdminEmailStyle from "../../../../assets/jss/dashboard/admin/dashboardTabAdminEmailStyle.js";

const DashboardTabAdminEmail = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        
        this.state = {
            open: false,
            emailType: "all_users",
            defaultUsername: "",
            username: "",
            subject: "",
            message: "",
            ttl: "",
            ttlValue: "",
            from: "",
            validation: {
                username: "",
                subject: "",
                textBody: "",
                isValid: false,
            },
            validatingUsername: false,
            cancelToken: null,
        };

        this.validateUsernameTimeout = null;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm =this.resetForm.bind(this);
        this.openForm = this.openForm.bind(this);
        this.onClear = this.onClear.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidUpdate(prevProps, prevState){
        const state = this.state;
        const that = this;
        if(state.username !== state.defaultUsername && state.username !== prevState.username){
            if(this.validateUsernameTimeout !== null){
                clearTimeout(this.validateUsernameTimeout);
            }
            this.validateUsernameTimeout = setTimeout(function(){
                that.validateUsername();
            },500);
        }
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    validateUsername(){
        if(this.state.cancelTokenUsername){
            this.state.cancelTokenUsername.cancel('Cancelled');
        }
        if(this.state.username.length <= 0){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: "error"
                },
                validatingUsername: false,
            });
            return;
        }
        if(this.state.username === this.state.defaultUsername){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: ""
                },
                validatingUsername: false,
            });
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            validatingUsername: true,
            cancelTokenUsername: source,
        });        
        Api.checkUsername(this.state.username, source).then((users) => {
            this.setState({
                cancelTokenUsername: null,
                validatingUsername: false,
                validation: {
                    ...this.state.validation,
                    username: "success"
                },
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelTokenUsername: null,
                    validatingUsername: false,
                    validation: {
                        ...this.state.validation,
                        username: "error"
                    },
                });
            }
        });
    }
    onClear(){
        this.setState({
            username: "",
            validation: {
                ...this.state.validation,
                username: ""
            },
        })
    }
    handleSubmit(e){
        const { user } = this.store.getState();
        const values = this.state;
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        let to = "all_users";
        if(values.emailType === "specific-user"){
            to = values.username;
        }
        const requestData = {
            'jobType': 'send_admin_email',
            'jobData': {
                'to': to,
                'subject': this.state.subject,
                'htmlBody': this.state.message,
                'from': values.from
            }, 
            'userId': user.userId
        };
        if(values.ttl.length <= 0){
            requestData.immediate = true;
        }else{
            requestData.ttl = values.ttl/1000; //epoch format
        }
        
        Api.sendEmails(requestData, source).then(data => {
            this.resetForm();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.resetForm();
            }  
        });
    }
    validateForm(){
        const values = this.state;    
        let validation = {
            ...values.validation,
            subject: 'success',
            message: 'success',
            isValid: true
        };
    
        if(values.emailType === "specific-user" && values.validation.username !== "success"){
            validation.username = "error";
            validation.isValid = false;
        }
        if(values.subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        if(values.message.length <= 0){
            validation.message = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    resetForm(hideModal = false){
        let newState = {
            username: "",
            subject: "",
            message: "",
            ttl: "",
            ttlValue: "",
            validation: {
                username: "",
                subject: "",
                message: "",
                isValid: false,
            },
        };
        if(hideModal === true){
            newState['open'] = false;
        }
        this.setState(newState);
    }
    openForm(state){
        this.setState({
            open: state
        })
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            let timestamp = parsedDate.getTime();
            this.setState({
                ttlValue: parsedDate.toISOString(),
                ttl: timestamp,
           });
        }catch(e){ console.log(e); }        
    }
    render() {
        const { classes } = this.props;
        const{ validation, emailType, subject, message, ttlValue, from } = this.state;
        return (
            <GridItem className={classes.main} xs={12} md={9} lg={7}>
                <GridContainer className={classes.gridContainer}>
                    <GridItem>
                        <form onSubmit={this.handleSubmit}>
                            <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioVertical}>
                                <FormControlLabel
                                    control={
                                    <Radio
                                        checked={emailType === "all_users" ? true : false}
                                        onChange={(e) => this.handleChange(e, "emailType")}
                                        value={"all_users"}
                                        name="all_users"
                                        aria-label="All Users"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                    }
                                    classes={{label: classes.label, root: classes.labelRoot}}
                                    label="All Users"
                                />
                            </div>
                            <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioVertical}>
                                <FormControlLabel
                                    control={
                                    <Radio
                                        checked={emailType === "specific-user" ? true : false}
                                        onChange={(e) => this.handleChange(e, "emailType")}
                                        value={"specific-user"}
                                        name="specific-user"
                                        aria-label="Specific User"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                    }
                                    classes={{label: classes.label, root: classes.labelRoot}}
                                    label="Specific User"
                                />
                            </div>
                            {
                                emailType === "specific-user" ?
                                    <>
                                        <CustomInput
                                            id="outlined-username"
                                            success={validation.username === "success"}
                                            error={validation.username === "error"}
                                            labelText="Username"
                                            loading={this.state.validatingUsername}
                                            inputProps={{
                                                value: this.state.username,
                                                onChange: event => this.handleChange(event, "username"),
                                                name: "username",
                                            }}
                                            onClear={this.onClear}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.formControl
                                            }}
                                        />
                                        {
                                            validation.username === "error" ?
                                                <Danger>This username is not found</Danger>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                    <></>
                            }
                            <CustomSelect 
                                formControlProps={{
                                    fullWidth: true
                                }}
                                selectProps={{
                                    onChange: (e) => this.handleChange(e, 'from'),
                                    value: from
                                }}
                                labelText="From"
                                inputProps={{
                                    name: "from",
                                    id: "outlined-from",
                                    className: classes.alignLeft
                                }}
                                items={Config.getFromEmails()}
                            />
                            <CustomInput
                                success={validation.subject === "success"}
                                error={validation.subject === "error"}
                                id="outlined-subject"
                                labelText="Subject"
                                inputProps={{
                                    value: subject,
                                    onChange: event => this.handleChange(event, "subject"),
                                    name: "subject",
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                            />
                            <CustomInput
                                success={validation.message === "success"}
                                error={validation.message === "error"}
                                id="message"
                                labelText="Message"
                                inputProps={{
                                    onChange: (e) => this.handleChange(e, 'message'),
                                    value: message,
                                    name: "message",
                                    multiline: true,
                                    rows: 4
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                            />
                            <DateTimePicker
                                labelText="Do Not Send Before"
                                id="ttl"
                                value={ttlValue}
                                onChange={(date) => this.handleDateChange(date, 'ttl')}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <div className={classes.formButtons}>
                                <Button onClick={() => this.openForm(true)} color="info" round>Cancel</Button>
                                <Button type="submit" color="success" round>Queue & Send</Button>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
                {
                    this.state.open ?
                        <ClearFormModal open={this.state.open} onClose={() => this.openForm(false)} onSuccess={() => this.resetForm(true)} />
                    :
                        <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabAdminEmailStyle)(DashboardTabAdminEmail);