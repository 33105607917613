import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteSubscriptionModalStyle from "../../../assets/jss/dashboard/deleteSubscriptionModalStyle";
import LoaderComponent from '../Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteSubscriptionModal = class extends React.Component {
  render() {
    const { classes, deletingSubscription, subscription } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.props.onClose()}
        aria-labelledby="deleteSubscription-slide-title"
        aria-describedby="deleteSubscription-slide-description"
      >
        <DialogTitle
          id="deleteSubscription-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Cancel Subscription</h4>
        </DialogTitle>
        <DialogContent
          id="deleteSubscription-slide-description"
          className={classes.modalBody}
        >
            {
                subscription !== null ?
                    <p>Are you sure you want to cancel your subscription?  Your subscription will continue until the next billing cycle after which you will be downgraded to a free account</p>
                :
                    <p>We got it! Your account level will be downgraded at your next billing cycle.</p>
            }
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button onClick={() => this.props.onSuccess()} color="danger" round>Cancel Subscription</Button>
          <Button onClick={() => this.props.onClose()} color="info" round>Nevermind</Button>
        </DialogActions>
        {
            deletingSubscription ?
                <LoaderComponent align="center" />
            :
                <></>
        }
        
      </Dialog>
    );
  }
};

DeleteSubscriptionModal.defaultProps = {
  open: false,
  deletingSubscription: false,
  subscription: null
};
DeleteSubscriptionModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deletingSubscription: PropTypes.bool,
  subscription: PropTypes.object
};
export default withStyles(deleteSubscriptionModalStyle)(DeleteSubscriptionModal);
