import {
    container,
    main,
    blackColor,
} from "../material-kit-pro-react.jsx";

const cookiesHomePageStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    container: {
        ...container,
        padding: "0px !Important",
        "& h5": {
            fontWeight: "bold"
        },
        "& .sd-table": {
            margin: "10px 0px 20px",
            "& table, table th, table td": {
                border: "1px solid "+blackColor,
                borderCollapse: "collapse",
                padding: "1px 10px",
                fontSize: "14px",
                verticalAlign: "top",
            }
    }
    },
    textCenter: {
        textAlign: "Center"
    },
    italic: {
        fontStyle: "italic"
    },
});

export default cookiesHomePageStyle;