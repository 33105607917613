import {
    container,
    main,
    blackColor,
    grayColor
} from "../material-kit-pro-react.jsx";

const privacyStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    container: {
        ...container,
        padding: "0px !Important",
        "& h5": {
            fontWeight: "bold"
        },
    },
    textCenter: {
        textAlign: "Center"
    },
    italic: {
        fontStyle: "italic"
    },
    privacyContentLists: {
        "& p": {
            display: "inline-block",
            verticalAlign: "top",
            width: "calc(100% - 2.5em)",
            lineHeight: "1.8em",
            wordBreak: "break-word"
        },
        "& ul, & ol": {
            margin: "0px",
            padding: "0 0 0 2em",
        },
        "& ol": {
            counterReset: "counter",
            fontSize: "1.375em",
            margin: "0",
            padding: "0",
            "&:nth-of-type(1)": {
                counterReset: "counter 0",
            },
            "& ol": {
                paddingLeft: "1.1em",
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: "0.65em",
                },
            },
            "& ul": {
                listStyleType: "disc",
                paddingLeft: "3.5em",
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: "3em",
                },
            },
            "& h5": {
                display: "inline-block",
                paddingLeft: "1em",
                width: "calc(100% - 2em)",
                [theme.breakpoints.down("xs")]: {
                    paddingLeft: "0.4em",
                },
            },
            "& >li": {
                margin: "0px",
                padding: "0px",
                position: "relative",
                listStyle: "none",
                "&::before": {
                    content: 'counter(counter) ". "',
                    counterIncrement: "counter",
                    fontSize: "1.0625rem",
                    fontWeight: "bold",
                },
                "& ol>li": {
                    fontSize: "0.5333333333em",
                    "& p": {
                        paddingLeft: "1.25em",
                        [theme.breakpoints.down("xs")]: {
                            paddingLeft: "0.5em",
                        },
                    },
                    "&::before": {
                        content: 'counters(counter, ".") " "',
                        counterIncrement: "counter",
                        fontSize: "0.875em",
                        position: "relative",
                        maxWidth: "20px",
                        width: "100%",
                        display: "inline-block",
                        [theme.breakpoints.down("xs")]: {
                            maxWidth: "25px",
                        },
                    }
                },  
                "& ul": {
                    "& p": {
                        paddingLeft: "1.25em",
                        [theme.breakpoints.down("xs")]: {
                            paddingLeft: "0.5em",
                        },
                    },   
                },
                "& ul.direct-ul": {
                    paddingLeft: "2.3em",
                    "& li": {
                        fontSize: "0.7em"
                    },
                    [theme.breakpoints.down("xs")]: {
                        paddingLeft: "1.8em",
                    },
                }
            }
        },
        "& >ol>li": {
            "&::before": {
                display: "inline-block",
                verticalAlign: "top",
                top: "10px",
                position: "relative",
            }
        },
        "& .contact-us-detail": {
            paddingLeft: "40px",
            paddingTop: "30px",
            paddingBottom: "40px",
            "& p": {
                margin: "0px"
            },
            "& a": {
                fontSize: "14px",
                marginTop: "0px",
                display: "inline-block"
            }
        },
        "& .privacy-table": {
            position: "relative",
            left: "-2em",
            width: "calc(100% + 1.9em)",
            margin: "10px 0px 20px",
            [theme.breakpoints.down("xs")]: {
                left: "-1.2em",
                width: "calc(100% + 1.2em)",
            },
            "& table, td, th": {
                border: "1px solid "+blackColor,
                borderCollapse: "collapse",
            },
            "& table": {
                "& th": {
                    backgroundColor: grayColor[19],
                    "&.categories-column": {
                        width: "30%",
                        "&.processing-column": {
                            width: "50%"
                        }
                    }
                },
                "& td, th": {
                    padding: "1px 10px",
                    verticalAlign: "top",
                    fontSize: "14px",
                }
            },
            "& ul": {
                paddingLeft: "15px"
            }
        }
    }
});

export default privacyStyle;