import React from 'react'
import { helper } from '../../../../assets/js/utils/Element'
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Link from "@material-ui/core/Link";
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";
import Button from "../../CustomButtons/Button.jsx";
import CustomInput from "../../CustomInput/CustomInput.jsx";

import { ReactComponent as PinterestIcon } from "../../../../assets/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/facebook.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import instagramIcon from "../../../../assets/img/instagram-icon.png";
import tiktokIcon from "../../../../assets/img/tiktok-icon.png";
import snapchatIcon  from "../../../../assets/img/snapchat-icon.png";
import soundcloudIcon from "../../../../assets/img/soundcloud-icon.png";
import whatsappIcon from "../../../../assets/img/whatsapp-icon.png"
import twitchIcon from "../../../../assets/img/twitch-icon.png";
import patreonIcon from "../../../../assets/img/patreon-icon.png";
import spotifyIcon from "../../../../assets/img/spotify-icon.png";
import amazonLiveIcon from "../../../../assets/img/amazon-live-icon.png";
import amazonShopIcon from "../../../../assets/img/amazon-shop-icon.png";
import appleMusicIcon from "../../../../assets/img/apple-music-icon.png";
import itunesIcon from "../../../../assets/img/itunes-icon.png";
import googlePlayIcon from "../../../../assets/img/gogole-play-icon.png";
import mediumIcon from "../../../../assets/img/medium-icon.png";
import likeToKnowItIcon from "../../../../assets/img/like-to-know-icon.png";

import socialIconStyle from "../../../../assets/jss/dashboard/editmode/socialIconStyle";

class SocialIcon extends React.Component {
    renderSocialIcon(){
        const { classes, type } = this.props;
        const socialIconsValues = this.props.socialIcons;
        let socialIconItem = null;
        switch(type){
            case "instagramUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.instagramUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={instagramIcon} alt="instagram"/>
                    </Button>
                </Link> 
                break;
            case "twitterUrl": 
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.twitterUrl}>
                    <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                </Link>  
                break;
            case "youtubeUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.youtubeUrl}>
                    <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                </Link> 
                break;
            case "tiktokUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.tiktokUrl}>
                    <Button justIcon className="instagram-icon tiktok-icon">
                        <img src={tiktokIcon} alt="tiktok"/>
                    </Button>
                </Link>    
                break;
            case "facebookUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.facebookUrl}>
                    <FacebookIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link> 
                break;
            case "pinterestUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.pinterestUrl}>
                    <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link>
                break;
            case "linkedinUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.pinterestUrl}>
                    <LinkedinIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link> 
                break;
            case "contactEmail":
                socialIconItem = <Link href={"mailto:"+socialIconsValues.contactEmail} className={classes.buttonSpacing}>
                    <MailIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                </Link>   
                break;
            case "snapchatUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.snapchatUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={snapchatIcon} alt="tiktok"/>
                    </Button>
                </Link> 
                break;
            case "soundcloudUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.soundcloudUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={soundcloudIcon} alt="soundcloud"/>
                    </Button>
                </Link> 
                break;
            case "whatsappNumber":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={"https://api.whatsapp.com/send?phone="+socialIconsValues.whatsappNumber}>
                    <Button justIcon className="instagram-icon">
                        <img src={whatsappIcon} alt="whatsapp"/>
                    </Button>
                </Link> 
                break;
            case "twitchUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.twitchUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={twitchIcon} alt="twitch"/>
                    </Button>
                </Link>
                break;
            case "patreonUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.patreonUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={patreonIcon} alt="patreon"/>
                    </Button>
                </Link>
                break;
            case "spotifyUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.spotifyUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={spotifyIcon} alt="spotify"/>
                    </Button>
                </Link>
                break;
            case "amazonLiveUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.amazonLiveUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={amazonLiveIcon} alt="amazonLive"/>
                    </Button>
                </Link>
                break;
            case "amazonShopUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.amazonShopUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={amazonShopIcon} alt="amazonShop"/>
                    </Button>
                </Link>
                break;
            case "appleMusicUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.appleMusicUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={appleMusicIcon} alt="appleMusic"/>
                    </Button>
                </Link>
                break;
            case "itunesUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.itunesUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={itunesIcon} alt="itunes"/>
                    </Button>
                </Link>
                break;
            case "googlePlayUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.googlePlayUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={googlePlayIcon} alt="googlePlay"/>
                    </Button>
                </Link>
                break;
            case "mediumUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.mediumUrl}>
                    <Button justIcon className="instagram-icon medium-icon">
                        <img src={mediumIcon} alt="medium"/>
                    </Button>
                </Link>
                break;
            case "likeToKnowItUrl":
                socialIconItem = <Link target="_blank" className={classes.buttonSpacing} href={socialIconsValues.likeToKnowItUrl}>
                    <Button justIcon className="instagram-icon">
                        <img src={likeToKnowItIcon} alt="likeToKnowIt"/>
                    </Button>
                </Link>
                break;
            default :
                socialIconItem = null;
                break;
        }
        return socialIconItem;
    }
    renderSocialField(){
        const { classes, type, values, socialIconsValidation } = this.props;
        let socialField = "";
        switch(type){
            case "instagramUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                        <GridItem className={classes.socialGridItem}>
                            <Button justIcon className="instagram-icon">
                                <img src={instagramIcon} alt="instagram"/>
                            </Button>
                        </GridItem>
                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                            <CustomInput
                                id="outlined-instagramUsername"
                                labelText="Instagram Handle"
                                success={socialIconsValidation.instagramUrl === "success"}
                                error={socialIconsValidation.instagramUrl === "error"}
                                inputProps={{
                                    value: values.socialIcons.instagramUrl ? values.socialIcons.instagramUrl : "",
                                    onChange: (e) => this.props.handleLinkChange(e, 'instagramUrl'),
                                    name: "instagramUrl",
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                                showClear={true}
                                onClear= {() => this.props.onClear("instagramUrl")}
                                color={this.props.primaryColor}
                            />                                        
                        </GridItem>
                        <p className={classes.socialLinkPreview}>
                            {
                                values.socialIcons.instagramUrl ?
                                    values.socialIcons.instagramUrl
                                :
                                    helper.prepareSocialUrl("instagram","")
                            }
                        </p>
                    </GridContainer>
                break;
            case "twitterUrl": 
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitterUsername"
                            labelText="Twitter Username"
                            success={socialIconsValidation.twitterUrl === "success"}
                            error={socialIconsValidation.twitterUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitterUrl ? values.socialIcons.twitterUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'twitterUrl'),
                                name: "twitterUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("twitterUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitterUrl ?
                                values.socialIcons.twitterUrl
                            :
                                helper.prepareSocialUrl("twitter","")
                        }
                    </p>
                </GridContainer>
                break;
            case "youtubeUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-youtubeChannelUrl"
                            labelText="Youtube Channel Url"
                            success={socialIconsValidation.youtubeUrl === "success"}
                            error={socialIconsValidation.youtubeUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.youtubeUrl ? values.socialIcons.youtubeUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'youtubeUrl'),
                                name: "youtubeUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("youtubeUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.youtubeUrl ?
                                values.socialIcons.youtubeUrl
                            :
                                helper.prepareSocialUrl("youtube","")
                        }
                    </p>
                </GridContainer>
                break;
            case "tiktokUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon tiktok-icon">
                            <img src={tiktokIcon} alt="tiktok"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-tiktokUsername"
                            labelText="Tiktok Username"
                            success={socialIconsValidation.tiktokUrl === "success"}
                            error={socialIconsValidation.tiktokUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.tiktokUrl ? values.socialIcons.tiktokUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'tiktokUrl'),
                                name: "tiktokUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("tiktokUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.tiktokUrl ?
                                values.socialIcons.tiktokUrl
                            :
                                helper.prepareSocialUrl("tiktok","")
                        }
                    </p>
                </GridContainer>
                break;
            case "facebookUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <FacebookIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-facebookUsername"
                            labelText="Facebook Username"
                            success={socialIconsValidation.facebookUrl === "success"}
                            error={socialIconsValidation.facebookUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.facebookUrl ? values.socialIcons.facebookUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'facebookUrl'),
                                name: "facebookUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("facebookUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.facebookUrl ?
                                values.socialIcons.facebookUrl
                            :
                                helper.prepareSocialUrl("facebook","")
                        }
                    </p>
                </GridContainer>
                break;
            case "pinterestUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-pinterestUsername"
                            labelText="Pinterest Username"
                            success={socialIconsValidation.pinterestUrl === "success"}
                            error={socialIconsValidation.pinterestUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.pinterestUrl ? values.socialIcons.pinterestUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'pinterestUrl'),
                                name: "pinterestUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("pinterestUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.pinterestUrl ?
                                values.socialIcons.pinterestUrl
                            :
                                helper.prepareSocialUrl("pinterest","")
                        }
                    </p>
                </GridContainer>
                break;
            case "linkedinUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <LinkedinIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-linkedinUsername"
                            labelText="LinkedIn Username"
                            success={socialIconsValidation.linkedinUrl === "success"}
                            error={socialIconsValidation.linkedinUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.linkedinUrl ? values.socialIcons.linkedinUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'linkedinUrl'),
                                name: "linkedinUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("linkedinUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.linkedinUrl ?
                                values.socialIcons.linkedinUrl
                            :
                                helper.prepareSocialUrl("linkedin","")
                        }
                    </p>
                </GridContainer>
                break;
            case "contactEmail":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <MailIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-contactEmail"
                            labelText="Contact Email"
                            success={socialIconsValidation.contactEmail === "success"}
                            error={socialIconsValidation.contactEmail === "error"}
                            inputProps={{
                                value: values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'contactEmail'),
                                name: "contactEmail",
                                type:"email",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("contactEmail")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {"mailto:"+(values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "")}
                    </p>
                </GridContainer>
                break;
            case "snapchatUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={snapchatIcon} alt="snapchat"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-snapchatUsername"
                            labelText="Snapchat Username"
                            success={socialIconsValidation.snapchatUrl === "success"}
                            error={socialIconsValidation.snapchatUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.snapchatUrl ? values.socialIcons.snapchatUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'snapchatUrl'),
                                name: "snapchatUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("snapchatUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.snapchatUrl ?
                                values.socialIcons.snapchatUrl
                            :
                                helper.prepareSocialUrl("snapchat","")
                        }
                    </p>
                </GridContainer>
                break;
            case "soundcloudUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={soundcloudIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-soundcloud"
                            labelText="SoundCloud"
                            success={socialIconsValidation.soundcloudUrl === "success"}
                            error={socialIconsValidation.soundcloudUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.soundcloudUrl ? values.socialIcons.soundcloudUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'soundcloudUrl'),
                                name: "soundcloudUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("soundcloudUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.soundcloudUrl ?
                                values.socialIcons.soundcloudUrl
                            :
                                helper.prepareSocialUrl("soundcloud","")
                        }
                    </p>
                </GridContainer>
                break;
            case "whatsappNumber":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={whatsappIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-whatsappNumber"
                            labelText="WhatsApp"
                            success={socialIconsValidation.whatsappNumber === "success"}
                            error={socialIconsValidation.whatsappNumber === "error"}
                            inputProps={{
                                value: values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'whatsappNumber'),
                                name: "whatsappNumber",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("whatsappNumber")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {(values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "+00000000000")}
                    </p>
                </GridContainer>
                break;
            case "twitchUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={twitchIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitch"
                            labelText="Twitch"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitchUrl ? values.socialIcons.twitchUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'twitchUrl'),
                                name: "twitchUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("twitchUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitchUrl ?
                                values.socialIcons.twitchUrl
                            :
                                helper.prepareSocialUrl("twitch","")
                        }
                    </p>
                </GridContainer>
                break;
            case "patreonUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={patreonIcon} alt="patreon"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-patreon"
                            labelText="Patreon"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.patreonUrl ? values.socialIcons.patreonUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'patreonUrl'),
                                name: "patreonUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("patreonUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.patreonUrl ?
                                values.socialIcons.patreonUrl
                            :
                                helper.prepareSocialUrl("patreon","")
                        }
                    </p>
                </GridContainer>
                break;
            case "spotifyUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={spotifyIcon} alt="spotify"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-spotify"
                            labelText="Spotify"
                            success={socialIconsValidation.spotifyUrl === "success"}
                            error={socialIconsValidation.spotifyUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.spotifyUrl ? values.socialIcons.spotifyUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'spotifyUrl'),
                                name: "spotifyUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("spotifyUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.spotifyUrl ?
                                values.socialIcons.spotifyUrl
                            :
                                helper.prepareSocialUrl("spotify","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonLiveUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonLiveIcon} alt="amazon live"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonLive"
                            labelText="Amazon Live"
                            success={socialIconsValidation.amazonLiveUrl === "success"}
                            error={socialIconsValidation.amazonLiveUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonLiveUrl ? values.socialIcons.amazonLiveUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'amazonLiveUrl'),
                                name: "amazonLiveUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("amazonLiveUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonLiveUrl ?
                                values.socialIcons.amazonLiveUrl
                            :
                                helper.prepareSocialUrl("amazonlive","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonShopUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonShopIcon} alt="amazon shop"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonShop"
                            labelText="Amazon Shop"
                            success={socialIconsValidation.amazonShopUrl === "success"}
                            error={socialIconsValidation.amazonShopUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonShopUrl ? values.socialIcons.amazonShopUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'amazonShopUrl'),
                                name: "amazonShopUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("amazonShopUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonShopUrl ?
                                values.socialIcons.amazonShopUrl
                            :
                                helper.prepareSocialUrl("amazonshop","")
                        }
                    </p>
                </GridContainer>
                break;
            case "appleMusicUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={appleMusicIcon} alt="apple music"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-appleMusic"
                            labelText="Apple Music"
                            success={socialIconsValidation.appleMusicUrl === "success"}
                            error={socialIconsValidation.appleMusicUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.appleMusicUrl ? values.socialIcons.appleMusicUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'appleMusicUrl'),
                                name: "appleMusicUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("appleMusicUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.appleMusicUrl ?
                                values.socialIcons.appleMusicUrl
                            :
                                helper.prepareSocialUrl("applemusic","")
                        }
                    </p>
                </GridContainer>
                break;
            case "itunesUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={itunesIcon} alt="itunes"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-itunesUrl"
                            labelText="itunes"
                            success={socialIconsValidation.itunesUrl === "success"}
                            error={socialIconsValidation.itunesUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.itunesUrl ? values.socialIcons.itunesUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'itunesUrl'),
                                name: "itunesUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("itunesUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.itunesUrl ?
                                values.socialIcons.itunesUrl
                            :
                                helper.prepareSocialUrl("itunes","")
                        }
                    </p>
                </GridContainer>
                break;
            case "googlePlayUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={googlePlayIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-googlePlayUrl"
                            labelText="Google Play"
                            success={socialIconsValidation.googlePlayUrl === "success"}
                            error={socialIconsValidation.googlePlayUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.googlePlayUrl ? values.socialIcons.googlePlayUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'googlePlayUrl'),
                                name: "googlePlayUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("googlePlayUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.googlePlayUrl ?
                                values.socialIcons.googlePlayUrl
                            :
                                helper.prepareSocialUrl("googleplay","")
                        }
                    </p>
                </GridContainer>
                break;
            case "mediumUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon medium-icon">
                            <img src={mediumIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-mediumUrl"
                            labelText="Medium"
                            success={socialIconsValidation.mediumUrl === "success"}
                            error={socialIconsValidation.mediumUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.mediumUrl ? values.socialIcons.mediumUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'mediumUrl'),
                                name: "mediumUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("mediumUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.mediumUrl ?
                                values.socialIcons.mediumUrl
                            :
                                helper.prepareSocialUrl("medium","")
                        }
                    </p>
                </GridContainer>
                break;
            case "likeToKnowItUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={likeToKnowItIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-likeToKnowItUrl"
                            labelText="Like to Know It"
                            success={socialIconsValidation.likeToKnowItUrl === "success"}
                            error={socialIconsValidation.likeToKnowItUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.likeToKnowItUrl ? values.socialIcons.likeToKnowItUrl : "",
                                onChange: (e) => this.props.handleLinkChange(e, 'likeToKnowItUrl'),
                                name: "likeToKnowItUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.props.onClear("likeToKnowItUrl")}
                            color={this.props.primaryColor}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.likeToKnowItUrl ?
                                values.socialIcons.likeToKnowItUrl
                            :
                                helper.prepareSocialUrl("liketoknow","")
                        }
                    </p>
                </GridContainer>
                break;
            default :
                socialField = <></>;
                break;
        }
        return socialField;
    }
    render(){
        const { renderType } = this.props;
        if(renderType === "icon"){
            return this.renderSocialIcon();
        }
        return this.renderSocialField();
    }
}

SocialIcon.defaultProps = {
    renderType: "icon"
}
SocialIcon.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string
};
  
export default withTheme(withStyles(socialIconStyle)(SocialIcon));