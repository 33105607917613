import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import HideIcon from '@material-ui/icons/Close';
import HideSetupProgressModal from './HideSetupProgressModal';
import setupProgressStyle from "../../../assets/jss/material-kit-pro-react/components/setupProgressStyle.jsx";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

class SetupProgress extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            steps: this.props.steps,
            color: this.props.color,
            open: false
        };

        this.onChange = this.onChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            steps: props.steps
        });
    }
    getCompletedPercentage(){
        const { steps } = this.props;
        const totalSteps = steps.length;
        if(totalSteps <= 0){
            return 100;
        }
        let completedSteps = 0;
        steps.map((step) => {
            if(step.status === true){
                completedSteps++;
            }
            return null;
        });
        return Math.round((completedSteps/totalSteps)*100);
    }
    onChange(step, key){
        const { steps } = this.state;
        steps[key]['status'] = !step.status;
        this.setState({steps: steps});

        if(this.props.onChange){
            this.props.onChange(steps);
        }
    }
    showModal(show = true){
        this.setState({open: show});
    }
    onHide(){
        this.setState({open: false});
        this.props.onHide()
    }
    render(){
        const { classes, grid, title, color } = this.props;
        const { steps, open } = this.state;
        const percentage = this.getCompletedPercentage();
        const progressBarComplete = classNames({
            [classes.progressBarComplete]: true,
            [classes[color]]: true,
        });
        if(steps.length <= 0){
            return <></>
        }
        return (
            <GridContainer className={classes.main}>
                <GridItem {...grid}>
                    <h4 className={classes.title}>{title}</h4>
                    <HideIcon className={classes.icons+" "+classes.hideIcon} onClick={() => this.showModal(true)} />
                    <div className={classes.progressBar}>
                        <span className={progressBarComplete} style={{width: percentage+"%"}}>{percentage?percentage+"%":null}</span>
                    </div>
                    <ul className={classes.steps}>
                        {
                            steps.map((step, key) => {
                                return <li key={key} className={classes.step}>
                                    <span className={classes.statusIcon}>
                                        {
                                            step.status ?
                                                <Check className={classes.checkIcon} />
                                            :
                                            <Clear className={classes.clearIcon} />
                                        }
                                    </span>
                                    <span className={classes.label}>
                                        <div dangerouslySetInnerHTML={{__html: step.stepLabel}}></div>
                                    </span>
                                </li>
                            })
                        }
                    </ul>
                </GridItem>
                {
                    open ?
                        <HideSetupProgressModal open={open} onSuccess={this.onHide} onClose={() => this.showModal(false)} />
                    :
                        <></>
                }
            </GridContainer>
        )
    }
}

SetupProgress.defaultProps = {
    title: "Set-Up Your Account",
    color: "success",
    onChange: function(){ },
    onHide: function(){ },
}

SetupProgress.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepLabel: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            status: PropTypes.bool.isRequired
        })
    ).isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ]),
    grid: PropTypes.object,
    onChange: PropTypes.func,
    onHide: PropTypes.func
};

export default withStyles(setupProgressStyle)(SetupProgress);
