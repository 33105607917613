import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import LoaderComponent from '../../Loader';
import coverPhotoStyle from "../../../../assets/jss/dashboard/content/coverPhotoStyle";
import defaultImage from "../../../../assets/img/image_placeholder.jpg";
import axios from 'axios';
import Config from '../../../../../Config';

const CoverPhoto = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            content: this.props.content,
            cancelToken: null,
        };

        this.getContent = this.getContent.bind(this);
        this.apiCallsCount = 0;
    }
    componentDidMount(){
        const { content } = this.state;
        if(!content.hasOwnProperty("mediaProcessing") || !content.mediaProcessing){
            return;
        }
        if(content.hasOwnProperty("showPicture") && !content.showPicture){
            return;
        }
        setTimeout(() => {
            if(this.apiCallsCount >= 3){
                return;
            }
            this.apiCallsCount++;
            this.getContent(content)
        }, 5000);
    }
    componentWillReceiveProps(props){
        this.setState({content: props.content});
    }
    getContent(content){
        const ApiUrl = Config.getApiUrl()+"data/content/"+content.contentId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        const that = this;
        that.setState({
            cancelToken: source
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0){
                let response = res.data.response[0];
                if(this.props.onPhotoResize){
                    this.props.onPhotoResize(response);
                }else{
                    that.setState({
                        cancelToken: null, 
                        content: response
                    });
                }
            }
        }).catch(err => {
            
        });
    }
    getCoverPhoto(content){
        if(content.hasOwnProperty("showPicture") && !content.showPicture){
            return null;
        }
        if(content.hasOwnProperty("coverMediaSourceUrl") && !content.hasOwnProperty("coverMedia") && 
        content.coverMediaSourceUrl && content.coverMediaSourceUrl.length > 0){
            return content.coverMediaSourceUrl;
        }
        if(!content.hasOwnProperty("coverMedia")){
            return defaultImage;
        }
        return content.coverMedia.downloadUrlProfile;
    }
    isShowPicture(content){
        if(!content.hasOwnProperty("coverMedia") && !content.hasOwnProperty("coverMediaSourceUrl")){
            return false;
        }
        if(!content.hasOwnProperty("showPicture")){
            return true;
        }
        return content.showPicture;
    }
    render() {
        const { content } = this.state;
        const { classes } = this.props;
        const coverPhoto = this.getCoverPhoto(content);
        return (
            <>
                {
                    (content.hasOwnProperty("mediaProcessing") && content.mediaProcessing) ?
                        <div className={classes.coverPhoto+(this.props.showArrows?" "+classes.coverPhotoWithArrows+" "+classes.loaderImageWithArrows:"")+" "+classes.loaderImage}><LoaderComponent align="center" /></div>
                    :
                    coverPhoto && coverPhoto !== defaultImage ?
                        <img onClick={this.props.onClick} src={coverPhoto} alt={content.title} className={classes.coverPhoto+(this.props.showArrows?" "+classes.coverPhotoWithArrows:"")} />
                    :
                        <div onClick={this.props.onClick} className={classes.noPicture+(this.props.showArrows?" "+classes.noPictureWithArrows:"")} style={{backgroundColor: this.props.primaryColor}}></div>
                        
                } 
            </>  
        );
    }
};

CoverPhoto.defaultProps = {
    onPhotoResize: null
};
CoverPhoto.propTypes = {
  classes: PropTypes.object,
  onPhotoResize: PropTypes.func
};
export default withStyles(coverPhotoStyle)(CoverPhoto);
