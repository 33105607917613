
import React from "react";
import LoaderComponent from '../../../../components/Loader'
import Api from '../../../../../assets/js/utils/Api'
import axios from 'axios';
import PropTypes from "prop-types";

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import Notice from "./Notice";
import instagramStepsStyle from "../../../../../assets/jss/dashboard/platforms/instagramStepsStyle.js";

class InstagramWizard extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);

        this.state = {
            saving: false,
            cancelToken: null,
            successModal: false,
            user: null
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleFinish(allStates){
        const values = {
            type: "instagramDemo",
            // robotFrequency: parseInt(allStates.robot.robotFrequency),
            allContentSearchable: allStates.robot.searchable,
            instagramHandle: allStates.account.username.replace(/\s/g, ""),
            addHashtagsToKeywords: allStates.robot.hashtags,
            attemptGeoTag: allStates.robot.attemptGeoTag,
            onlySmearchTags: allStates.robot.onlySmearchTags,
            includeFirstCommentHashtags: allStates.robot.includeFirstCommentHashtags,
            featureNewInstagramPosts: allStates.robot.featureNewInstagramPosts,
            // instagramBeta: allStates.confirmation.notifyMe,
            retrieveAllMedia: allStates.robot.retrieveAllMedia,
            addRecognition: allStates.robot.addRecognition,
            importIGTV: allStates.robot.importIGTV,
            importReels: allStates.robot.importReels,
            useFirstLink: allStates.robot.useFirstLink,
            isActive: true
        };
        
        const { user } = this.store.getState();
        const that = this;
        const source = axios.CancelToken.source(); 
        this.setState({saving: true, cancelToken: source});
        Api.createPlatform(values, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                const { user } = this.store.getState();
                that.setState({successModal: true, user: user});
                that.updateUserOnboarding(data.response[0]);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    updateUserOnboarding(platform){
        const { user } = this.store.getState();
        if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
            user.guestPreferences.contentPlatforms = [];
        }
        user.guestPreferences.contentPlatforms.push(platform.contentPlatformId);
        if(!user.hasOwnProperty("contentPlatforms")){
            user.contentPlatforms = [];
        }
        user.contentPlatforms.push(platform);
        this.setState({user: user});
        Api.updateUser(user.userId, user).then((data) => {
            if(data.numberOfResponses > 0){
                // console.log(data);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onNoticeClose(){
        this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
        this.history.push("/dashboard/platforms");
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { successModal } = this.state;
        if(!authorized){
            return <></>
        }

        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    {
                        this.state.saving ?
                            <LoaderComponent align="center" />
                        :
                            <>
                                <Button color="transparent" justIcon className={classes.closeButton} onClick={this.props.onWizardClose}>
                                    <Close />
                                </Button>
                                <Wizard
                                    validate
                                    store={this.store}
                                    steps={[
                                        { stepName: "Account", stepComponent: Step1, stepId: "account" },
                                        { stepName: "Instructions", stepComponent: Step2, stepId: "robot" },
                                        { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                                    ]}
                                    title="Add A Demo Instagram Account"
                                    subtitle="We will automatically load your search engine!"
                                    finishButtonText="Done!"
                                    finishButtonClick={this.handleFinish}
                                />
                            </>
                    }                
                </GridItem>
                <Notice store={this.store} onClose={this.onNoticeClose} open={successModal} />
            </GridContainer>
        );
    }
}

InstagramWizard.defaultProps = {
    onWizardClose: function(){ }
}

InstagramWizard.propTypes = {
    onWizardClose: PropTypes.func
}
export default withStyles(instagramStepsStyle)(InstagramWizard);
