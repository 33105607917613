import {
    whiteColor,
    primaryColor,
    grayColor,
} from "../material-kit-pro-react.jsx";
import customSelectStyle from "../material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const editPageStyle = theme => ({
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    container: {
        backgroundColor: theme.palette.background.paper, 
        padding: theme.spacing(2),
        "& .MuiInput-multiline+svg": {
            right: "15px",
        }
    },
    title: {
        margin: theme.spacing(1, 0, 1),
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    buttonSpacing: {
        marginLeft: "10px",
        "& .sd_loader": {
            "& [class*='MuiCircularProgress-root']": {
                width: "18px !important",
                height: "18px !important"
            }
        }
    },
    imgGrid:{
        "& .fileinput": {
            width: "250px",
            maxWidth: "100%",
            margin: "0 auto",
            textAlign:"center",
            display:"block",
            position: "relative",
            "& svg" : {
                position: "absolute",
                top: "82px",
                right: "-25px"
            },
        }
    },
    marginTop:{
        marginTop: "20px"
    },
    hideLocation:{
        display:"none"
    },
    customRoot:{
        marginLeft: "0px !important",
    },
    centerParagraph:{
        textAlign:"center"
    },
    customGridItem:{
        maxWidth:"100%",
        paddingLeft:"0px !important",
        paddingRight:"0px !important"
    },
    cartIcon: {
        color: grayColor[0]
    },
    cartIconActive: {
        color: primaryColor[0]
    },
    customInputLabel: {
        "& .MuiFormLabel-root": {
            zIndex: "1"
        },
        zIndex: "1"
    },
    upgradePlan: {
        marginTop: theme.spacing(3)
    },
    buttonsSection: {
        display: "flex",
        justifyContent: "space-between",
        "& button": {
            maxWidth: "135px",
            width: "100%",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100px",
                padding: "12px 10px",
            }
        },
        "& .sd_loader": {
            width: "18px",
            height: "18px"
        }
    }
});

export default editPageStyle;