import React from 'react'
import {Helmet} from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"

import circlesHomePageStyle from "../../../assets/jss/circles/circlesHomePageStyle.js";

const CirclesHome = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container+" "+classes.main}>
                Circles Home
                <Helmet>
                    <title>Search My Social App Circles Home</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(circlesHomePageStyle)(CirclesHome);
