import React from 'react';
import { createBrowserHistory } from 'history';
import Config from "../../../../../Config";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardTabApplicationSetting from "../settings/Application";
import NavPills from "../../../components/NavPills/NavPills.jsx";

import dashboardTabSettingsStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabSettingsStyle.js";
const history = createBrowserHistory();

const DashboardTabSettings = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.history = this.props.history;
        this.navPills = React.createRef();
        let subTab = this.props.params.subTab;
        if(subTab !== 0){
            subTab = 0;
        }

        this.state = {
            defaultTab: subTab,
            match: this.props.match,
        };

        this.onTabChange = this.onTabChange.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/settings/smearch");
                break;
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    render() {
        const { classes } = this.props;
        const { defaultTab } = this.state;
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/app/icon_trans393x551.png";
        const { user, authorized } = this.store.getState();
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);

        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    horizontal={{
                        tabsGrid: { className: (showLabels ? "showLabels" : "") },
                    }}
                    alignCenter
                    color="info"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    tabs={[
                        {
                            tabButton: (showLabels ? "App" : ""),
                            tabImageIcon: <img src={logoUrl} alt="logo" className={classes.tabImageIcon} />,
                            tabContent: (
                                <DashboardTabApplicationSetting store={this.store} history={this.history} />
                            ),
                        }
                    ]}
                />
            </div>
        )
    }
}

export default withStyles(dashboardTabSettingsStyle)(DashboardTabSettings);
