import {
    whiteColor,
    blackColor,
} from "../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const useStyles = theme => ({
    ...customCheckboxRadioSwitch,
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },
    title: {
        margin: theme.spacing(1, 0, 1),
    },
    buttonSpacing: {
        marginLeft: "10px",
    },
    socialGridItem: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
    },
    iconButton: {
        color: blackColor,
        marginLeft: "5px",
        "& i": {
            fontSize: "30px !important",
        }
    },
    formControl: {
        marginBottom: "5px"
    },
    socialLinkPreview: {
        paddingLeft: theme.spacing(2),
        fontSize: "12px"
    },
    buttonsGridContainer: {
        marginTop: theme.spacing(2)
    },
    responsiveIconsGrid: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 0,
            maxWidth: "77%",
            flexBasis: "77%"
        }
    },
    inputLabel: {
        margin: theme.spacing(1, 0, 1),
    },
});

export default useStyles;