import React from "react";
import PropTypes from "prop-types";
import Auth from '@aws-amplify/auth';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import Cookie from "../../../assets/js/utils/Cookie";
// import AuthApi from "../../../assets/js/utils/Auth";
import LoaderComponent from '../../components/Loader';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "../../components/Card/Card.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import SaveIcon from "@material-ui/icons/Save";
import forcePasswordResetStyle from "../../../assets/jss/user/forcePasswordResetStyle.jsx";

class ForceResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        const { user } = this.store.getState();

        this.state = {
            email: user.userEmail,
            password: "",
            passwordConfirm: "",
            code: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            isSuspended: false,
            signupCode: false,
            verifyEmail: false
        };

        this.validateForm = this.validateForm.bind(this);
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(validatePassword || validatePasswordConfirm){
            this.validatePassword(validatePassword, validatePasswordConfirm);
        }
    }
    handleChange(e, name){
        this.setState({
            [name]: e.target.value
        });
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const { email } = this.state;
        const newPassword = this.state.password;
        // const idToken = Cookie.read("idToken");
        // const accessToken = Cookie.read("accessToken");
        // const refreshToken = Cookie.read("refreshToken");
        // const expiresIn = Cookie.read("expires_in");
        // let federated = Cookie.read("federated");
        // if(federated !== "1"){
        //     federated = null;
        // }
        // const authData = {
        //     accessToken: accessToken,
        //     expires_in: parseInt(expiresIn),
        //     idToken: idToken,
        //     refreshToken: refreshToken
        // };
        const { user } = this.store.getState();
        Auth.signIn(email, user.cognitoRandomPassword).then(user => {
            if(user.challengeName === 'NEW_PASSWORD_REQUIRED'){
                Auth.completeNewPassword(
                    user,
                    newPassword,
                    {
                      email: email,
                    }
                ).then(user => {
                    //Auth.signOut();
                    Cookie.dispose("federated");
                    setTimeout(() => {
                        //AuthApi.updateTokens(authData, federated);
                        this.props.onPasswordReset();
                    }, 100);
                }).catch(e => {
                    console.log(e);
                });
            }
        }).catch(err => {
            console.log(err);
        });
        this.setState({loading: true, showError: false});
    }
    validateForm(){
        let validation = {
            password: 'success',
            isValid: true
        };
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validatePassword(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    render() {
        const { classes } = this.props;
        const { email, validation, showPasswordErrors, requireSymbol, requireLength, loading } = this.state;
        return (
            <div className={"wizardContainer "+classes.wizardContainer}>
                <Card className={classes.card}>
                    <div className={"wizardHeader "+ classes.wizardHeader}>
                        <h3 className={classes.title}>Welcome to Search My Social!</h3>
                        <h5 className={classes.subtitle}>
                            <p>First things first - we see that you have used Social Sign Up (good choice!)</p>
                            <p>In case you ever want to sign in with your email instead, we still need you to create a password in our system.</p>
                        </h5>
                    </div>
                    <div className={classes.resetContainer}>
                        <h3 className={classes.emailTitle}>Your Verified Email</h3>
                        <p>{email}</p>
                        <form className={classes.form} onSubmit={(e) => this.handleSubmit(e)} noValidate>
                            <CustomInput
                                success={validation.password === "success"}
                                error={validation.password === "error"}
                                id="input-password"
                                labelText="New Password"
                                inputProps={{
                                    required: true,
                                    onChange: (e) => this.handleChange(e,'password'),
                                    name: "password",
                                    type: "password"
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            {
                                showPasswordErrors ?
                                    <GridItem className={classes.passwordErrors}>
                                        <div>
                                            <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                            </div>
                                            <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                            </div>
                                        </div>
                                    </GridItem>
                                :
                                <></>
                            }
                            <CustomInput
                                success={validation.passwordConfirm === "success"}
                                error={validation.passwordConfirm === "error"}
                                id="input-passwordConfirm"
                                labelText="New Password Again"
                                inputProps={{
                                    required: true,
                                    onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                    name: "passwordConfirm",
                                    type: "password"
                                }}                                    
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <Button color="success" type="submit" className={classes.confirmButton}>
                                {
                                    loading ?
                                        <LoaderComponent color="white" />
                                    :
                                    <><SaveIcon /> Confirm</>
                                }
                            </Button>
                        </form>
                    </div>
                </Card>
            </div>
        );
    }
}

ForceResetPassword.defaultProps = {
    onPasswordReset: function(){ }
}
ForceResetPassword.propTypes = {
  classes: PropTypes.object,
  onPasswordReset: PropTypes.func
};

export default withStyles(forcePasswordResetStyle)(ForceResetPassword);
