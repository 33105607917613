import React from 'react';
import { createBrowserHistory } from 'history';
import Api from "../../../../assets/js/utils/Api";
import Cookie from "../../../../assets/js/utils/Cookie";
import axios from 'axios';
import Link from "@material-ui/core/Link";
import { helper } from '../../../../assets/js/utils/Element';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// import Tour from "../../../components/Dashboard/Tour.jsx";
// import TourModal from "../../../components/Dashboard/TourModal.jsx";
// import InstagramApiModal from "../../../components/Dashboard/InstagramApiModal.jsx"
// import YoutubeApiModal from "../../../components/Dashboard/YoutubeApiModal.jsx"
import dashboardHomePageStyle from "../../../../assets/jss/dashboard/dashboardHomePageStyle.js";
const history = createBrowserHistory();

const DashboardTabHome = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        const openModal = (user.userPreferences.dashboardTourComplete === false && user.userPreferences.showTours === true);

        this.onboardingParam = helper.getParam("onboarding");
        this.isLoginParam = helper.getParam("islogin");

        let instagramApiModal = false;
        if(this.onboardingParam !== "null" && openModal === false){
            instagramApiModal = true;
        }
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(allowInstagram === user.userId){
            instagramApiModal = false;
        }
        let youtubeApiModal = false;
        const allowYoutube = Cookie.read("platform_wizard_youtube");
        if(this.onboardingParam !== "null" && instagramApiModal === false && openModal === false && allowYoutube !== user.userId){
            youtubeApiModal = true;
        }

        this.state = {
            openModal: openModal,
            startTour: false,
            showTours: null,
            instagramApiModal: instagramApiModal,
            youtubeApiModal: youtubeApiModal
        }
        this.handleTourPopup = this.handleTourPopup.bind(this);
        this.handleTour = this.handleTour.bind(this);
        this.onTourClose = this.onTourClose.bind(this);
    }
    componentDidMount(){
        if(this.isLoginParam !== "null"){
            this.loadUserPlatforms();
        }
    }
    loadUserPlatforms(){
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        const that = this;
        this.setState({cancelToken: source});
        Api.getPlatforms({}, source, user.userId).then(data => {        
            if(data.response.length === 1){
                that.setState({
                    instagramApiModal: true,
                });
            }
        });
    }
    onLinkClick(event, tab){
        event.preventDefault();
        this.props.onLinkClick(event, tab);
    }
    handleTourPopup(showTours = null) {
        this.setState({ openModal: false });
        const { user } = this.store.getState();
        user.userPreferences.dashboardTourComplete = true;
        Api.updateUser(user.userId, user);        
        if(showTours !== null){
            user.userPreferences.showTours = showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
        }
        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
            this.history.push("/dashboard?onboarding=1");
        }else{
            this.history.push("/dashboard");
        }
    }
    handleTour(showTours = null) {
        this.setState({
            openModal: false,
            startTour: true,
            showTours: showTours
        });
        const { user } = this.store.getState();
        user.userPreferences.dashboardTourComplete = true;
        Api.updateUser(user.userId, user);

        const allowInstagram = Cookie.read("platform_wizard_instagram");
        if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
            history.push("/dashboard?onboarding=1");
        }else{
            history.push("/dashboard");
        }
    }
    onTourClose(){
        if(this.state.showTours !== null){
            const { user } = this.store.getState();
            user.userPreferences.dashboardTourComplete = true;
            user.userPreferences.showTours = this.state.showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});

            const allowInstagram = Cookie.read("platform_wizard_instagram");
            if(this.onboardingParam !== "null" && allowInstagram !== user.userId){
                this.history.push("/dashboard?onboarding=1");
            }else{
                this.history.push("/dashboard");
            }
        }
    }
    handleInstagramModal(open){
        const { user } = this.store.getState();
        if(open === null){
            Cookie.write("platform_wizard_instagram", user.userId);
        }
        if(open === false || open === null){
            const allowYoutube = Cookie.read("platform_wizard_youtube");
            if(allowYoutube !== user.userId){
                this.setState({ instagramApiModal: false, youtubeApiModal: true });
                return;
            }
            this.setState({ instagramApiModal: false });
            this.history.push("/dashboard");
            return;
        }
        this.setState({ instagramApiModal: false });
        this.history.push("/dashboard/platformwizard/instagramlink");
    }
    handleYoutubeModal(open){
        const { user } = this.store.getState();
        if(open === null){
            Cookie.write("platform_wizard_youtube", user.userId);
        }
        if(open === false || open === null){
            this.setState({ instagramApiModal: false, youtubeApiModal: false });
            this.history.push("/dashboard");
            console.log("Redirect Correct Url2");
            return;
        }
        console.log("Redirect to Youtube");
        this.setState({ youtubeApiModal: false });
        this.history.push("/dashboard/platformwizard/youtube");
    }
    render() {
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        // let { openModal, startTour, instagramApiModal, youtubeApiModal } = this.state;
        // const startPlatformTour = helper.getParam("startTour");
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        // if(startPlatformTour && startPlatformTour.length > 0 && startPlatformTour !== "null"){
        //     openModal = true;
        // }
        // if(this.props.isActiveTab !== true){
        //     openModal = false;
        // }
        return (
            <div className={classes.homeTab}>
                <ul className="tab_home">
                    <li className="preview_profile">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,1)}>Edit Your Pages</Link>                        
                    </li>
                    <li className="edit_profile">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,2)}>Update Your Personal Info</Link>                        
                    </li>
                    {
                        authorized === true && user.isAdmin ?
                            <li className="admin_home">
                                <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,3)}>Admin</Link>                        
                            </li>
                        :
                        <></>
                    }
                    <li className="manage_platforms">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,4)}>Add/Manage Your Content</Link>                    
                    </li>
                    {
                        showBetaFeatures ?
                            <>
                                <li className="search_data">
                                    <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,5)}>View Analytics</Link>                        
                                </li>
                                <li className="manage_circles">
                                    <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,6)}>Manage Your Circles</Link>
                                </li>
                            </>
                        :
                        <></>
                    }                                        
                    <li className="manage_billing">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,8)}>Manage Account and Billing</Link>
                    </li>
                    <li className="manage_settings">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,9)}>Adjust Your Settings</Link>
                    </li>
                    <li className="ask_help">
                        <Link href="/" underline="none" onClick={ event => this.onLinkClick(event,10)}>Help!</Link>
                    </li>
                </ul>

                {/* <TourModal
                    open={openModal}
                    onClose={this.handleTourPopup}
                    onClick={this.handleTour}
                    store={this.store}
                />
                {(startTour === true) ? <Tour run={startTour} onClose={this.onTourClose} /> : <></>}
                <InstagramApiModal open={instagramApiModal} onClick={event => this.handleInstagramModal(true)} onClose={event => this.handleInstagramModal(false)} onNever={event => this.handleInstagramModal(null)} />
                {
                    youtubeApiModal ?
                        <YoutubeApiModal open={youtubeApiModal} onClick={event => this.handleYoutubeModal(true)} onClose={event => this.handleYoutubeModal(false)} onNever={event => this.handleYoutubeModal(null)} />
                    :
                    <></>
                } */}
            </div>
        )
    }
}

DashboardTabHome.defaultProps = {
    onLinkClick: function(){ }
}
DashboardTabHome.propTypes = {
    onLinkClick: PropTypes.func
}
export default withStyles(dashboardHomePageStyle)(DashboardTabHome);
