import {
  primaryColor,
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  hexToRgb,
  dangerColor
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const signupVerificationPageStyle = theme => ({
    container: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "10vh",
        color: whiteColor,
        [theme.breakpoints.down("sm")]: {
            paddingTop: "8vh",
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "5vh",
        }
    },
    pageHeader: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "120px 0px",
        border: "0",
        alignItems: "center",
        "&:before": {
            // background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:after": {
            // background:
            //     "linear-gradient(60deg,rgba(" +
            //     hexToRgb(primaryColor[4]) +
            //     ",.56),rgba(" +
            //     hexToRgb(primaryColor[5]) +
            //     ",.95))"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        background: "#E2E2E1 !important"
    },
    cardSignup: {
        borderRadius: "6px",
        boxShadow:
        "0 16px 24px 2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 6px 30px 5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2);",
        marginBottom: "0px",
        padding: "0px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "0px",
        }
    },
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0px !important",
        marginTop: "20px !important"
    },
    ...customCheckboxRadioSwitchStyle,
    socials: {
        marginTop: "0",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
    },
    textCenter: {
        textAlign: "center"
    },
    inputAdornment: {
        marginRight: "18px",
        position: "relative"
    },
    inputAdornmentIcon: {
        color: grayColor[13]
    },
    form: {
        margin: "0"
    },
    infoArea: {
        padding: "0px 0px 20px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    terms: {
        marginTop: "0px",
        marginBottom: theme.spacing(2),
        color: dangerColor[0]
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    verifyError: {
        marginTop: theme.spacing(1)
    },
    modalRootCustom: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            top: "16px !important",
        }
    },
    modalSignup: {
        width: "100%",
        maxWidth: "900px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important"
        }        
    },
    paperScrollPaperCustom: {
        height: "auto",
    },
    modalSignupCard: {
        margin: "0px",
        padding: "40px 0px",
        [theme.breakpoints.down('xs')]: {
            paddingTop: "0px !important"
        } 
    }
});

export default signupVerificationPageStyle;
