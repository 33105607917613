/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
// core components
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check"; 
import StarHalfIcon from '@material-ui/icons/StarHalf';
import InstagramIcon from '@material-ui/icons/Instagram';
import PlacesIcon from '@material-ui/icons/LocationOn';
import { ReactComponent as ShoppingBagIcon } from "../../../assets/icons/shopping_bag.svg";
import { ReactComponent as GuidesIcon } from "../../../assets/icons/guides.svg";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';
import YouTubeIcon from '@material-ui/icons/YouTube';
import tiktokIcon from "../../../assets/img/tiktok-icon.png";
import instagramTvIcon from "../../../assets/img/instagram-tv-icon.png";
import instagramVideoIcon from "../../../assets/img/instagram-video-icon.png"; 
import instagramReelsIcon from "../../../assets/img/instagram-reels-icon.png";

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline, mdiLockOutline, mdiFoodVariant, mdiGestureTapButton, mdiPodcast } from '@mdi/js';

import customSelectWithIconStyle from "../../../assets/jss/material-kit-pro-react/components/customSelectWithIconStyle.jsx";

class CustomSelectWithIcon extends React.Component {
    constructor(props){
        super(props);

        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
        this.onShowInfo = this.onShowInfo.bind(this);
    }
    onChange(e){
        if(this.props.inputProps.onChange){
            this.props.inputProps.onChange(e);
        }
    }
    onShowInfo(){
        if(this.props.onShowInfo){
            this.props.onShowInfo();
        }
    }
    getItemIcon(type){
        const { classes } = this.props;
        let icon = "";
        switch(type){
            case "link":
                icon = <div className={classes.icons}>
                        <i className={classes.icon+" fa fa-link icon"} />
                    </div>
                break;
            case "instagram_post":
                icon = <div className={classes.icons}>
                        <InstagramIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "recipe":
                icon = <div className={classes.icons}>
                        <MaterialIcon path={mdiFoodVariant} className={classes.icon+" icon"}/>
                    </div>
                break;
            case "recommendation":
                icon = <div className={classes.icons}>
                        <StarHalfIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "place":
                icon = <div className={classes.icons}>
                        <PlacesIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "photo":
                icon = <div className={classes.icons}>
                        <CameraAltOutlinedIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "shopping_items":
                icon = <div className={classes.icons}>
                        <ShoppingBagIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "workout":
                icon = <div className={classes.icons}>
                        <PoolOutlinedIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "youtube":
                icon = <div className={classes.icons}>
                        <YouTubeIcon className={classes.icon+" icon"}/>
                    </div>
                break;
            case "tiktok":
                icon = <div className={classes.icons}>
                        <img src={tiktokIcon} alt="tiktok" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "button":
                icon = <div className={classes.icons}>
                        <MaterialIcon path={mdiGestureTapButton} className={classes.icon+" icon"}/>
                    </div>
                break;
            case "instagram_reel":
                icon = <div className={classes.icons}>
                        <img src={instagramReelsIcon} alt="instagram reels" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "instagram_tv":
                icon = <div className={classes.icons}>
                        <img src={instagramTvIcon} alt="instagram tv" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "podcast":
                icon = <div className={classes.icons}>
                        <MaterialIcon path={mdiPodcast} className={classes.icon+" icon"}/>
                    </div>
                break;
            case "instagram_video":
                icon = <div className={classes.icons}>
                        <img src={instagramVideoIcon} alt="instagram video" className={classes.icon+" icon"}/>
                    </div>
                break;
            case "instagram_guide":
                icon = <div className={classes.icons}>
                        <GuidesIcon className={classes.icon+" icon"} />
                    </div>
                break;
            default:
                icon = <></>;
                break;
        }
        return icon;
    }
    renderItems(){
        const { classes, color, items, itemLabel, itemValue } = this.props;
        const selectedClasses = classNames({
            [" " + classes.selectMenuItemSelectedMultiple]: true,
            [classes[color]]: true
        });
        let itemsArray = [];
        items.map((item, key) => {
            let label = "";
            let value = "";
            if(typeof(item) === "object"){
                label = item[itemLabel];
                value = item[itemValue];
            }else{
                label = item;
                value = item;
            }
            itemsArray.push(<MenuItem
                key={key} 
                value={value}
                classes={{
                    root: classes.selectMenuItem+ " " + classes.selectMenuItemWithIcon + " " + classes[color],
                    selected: selectedClasses
                }}
            >   
                { this.getItemIcon(value) }
                {label}
            </MenuItem>);
            return null;
        });
        return itemsArray;
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            success,
            showInfoTooltip,
            showPrivateIcon,
            color,
            selectProps
        } = this.props;

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error,
            [classes[color]]: true
        });
        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames({
                [formControlProps.className]: true,
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,

            });
        } else {
            formControlClasses = classNames({
                [classes.selectFormControl]: true,
                [classes[color]]: true,
                [classes.formControlDisabled]: inputProps.disabled ? true : false,
            });
        }

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                        className={classes.labelRoot + " " + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                        {
                            showPrivateIcon ?
                                <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                            :
                            <></>
                        }
                        {
                            showInfoTooltip ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.onShowInfo} />
                            :
                            <></>
                        }
                    </InputLabel>
                ) : null}
                <Select {...selectProps}
                    inputProps={inputProps}
                    MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper }
                    }}
                    classes={{
                        select: classes.select
                    }}
                >
                    {this.renderItems()}
                </Select>
                {error ? (
                    <Clear className={classes.feedback + " " + classes.labelRootError} />
                ) : success ? (
                    <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                ) : null }
            </FormControl>
        )
    }
}

CustomSelectWithIcon.defaultProps = {
    loading: false,
    inputProps: {
        value: "",
        onChange: function(){ }
    },
    selectProps: {
        value: "",
        onChange: function(){ }
    },
    onClear: null,
    showClear: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    showPrivateIcon: false,
    color: "primary",
    itemLabel: "",
    itemValue: ""
}

CustomSelectWithIcon.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    loading: PropTypes.bool,
    onClear: PropTypes.func,
    showClear: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "gray",
        "rose",
        "customInfo",
        "customSuccess",
        "customRose",
    ]),
    selectProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    itemLabel: PropTypes.string,
    itemValue: PropTypes.string,
};

export default withStyles(customSelectWithIconStyle)(CustomSelectWithIcon);
