import {
  
} from "../../material-kit-pro-react.jsx";

const ratingStyle = {
    rating: {
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
        "& label": {
            fontSize: "36px",
            color: "#ffb400",
            // color: "rgba(0, 0, 0, 0.85)",
            "& .MuiRating-iconEmpty": {
                // color: "rgba(0, 0, 0, 0.85)"
            }
        },
    }
};

export default ratingStyle;
