import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customSelectStyle from "../../../../../assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import paymentMethodPageStyle from "../../../../../assets/jss/payment/paymentMethodPageStyle";
const style = theme => ({
    ...customSelectStyle,
    ...paymentMethodPageStyle(theme),
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    formControl: {
        marginBottom: "5px"
    }
});

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.state = {
            username: "",
            usernameState: "",
        };
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.props.onMount(that.state);
        },100);
    }
    sendState() {
        return this.state;
    }
    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        var regex = /[@/]/g;
        // var regex = /[!$%^&*()+|~=`;'"{}[:;<>?,@#\]]/g;
        if(regex.test(event.target.value)){
            this.setState({ [stateName]: this.state[stateName] });
            return;
        }
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                this.setState({ [stateName + "State"]: "success" });
                } else {
                this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated() {
        if (
          this.state.usernameState === "success"
        ) {
            return true;
        } else {
            if (this.state.usernameState !== "success") {
                this.setState({ usernameState: "error" });
            }
        }
        return false;
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10}>
                        <CustomInput
                            id="outlined-instagramusername"
                            success={this.state.usernameState === "success"}
                            error={this.state.usernameState === "error"}
                            labelText="Instagram Handle (Do not enter @)"
                            inputProps={{
                                value: this.state.username,
                                onChange: event => this.change(event, "username", "length", 3),
                                name: "instagramusername",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                        />
                        <p className={classes.socialLinkPreview}>
                            {"https://instagram.com/"+this.state.username}
                        </p>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

Step1.defaultProps = {
    onMount: function(){ }
}
Step1.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step1);
