
const linktreeStepsStyle = theme => ({
    gridContainer: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    gridItemSpacing: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: "10px",
            marginRight: "10px",
        }
    },
    closeButton: {
        position: "absolute",
        right: "15px",
        zIndex: 1,
        top: "20px",
        [theme.breakpoints.down("xs")]: {
            top: "-5px",
            right: "9px"
        }
    }
});

export default linktreeStepsStyle;