import { grayColor, infoColor, roseColor, successColor, primaryColor, hexToRgb, whiteColor, blackColor } from "../../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const searchTermsStyle = theme => ({
    ...customCheckboxRadioSwitch,
    tabs: {
        textAlign: "left",
        borderBottom: "1px solid "+theme.palette.custom.main,
        "& button": {
            border: "0px !important",
            marginTop: "10px",
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            borderRadius: "0px",
            [theme.breakpoints.down("sm")]: {
                borderBottom: "1px solid transparent !important",
                "&.activeTab": {
                    borderBottom: "1px solid "+ theme.palette.custom.main+" !important"
                }
            },
            [theme.breakpoints.down("xs")]: {
                paddingLeft: "15px",
                paddingRight: "15px"
            }
        }
    },
    roundTabs: {
        // "& $tabs": {
        //     "& button:first-child": {
        //         borderRadius: "30px 0px 0px 0px"
        //     },
        //     "& button:last-child": {
        //         borderRadius: "0px 30px 0px 0px"
        //     }
        // }
    },
    searchTermsContainer: {
        width: "100%",
        textAlign: "left",
        "& div.react-tagsinput": {
            "& .react-tagsinput-tag": {
                display: "none !important"
            },
            "& input": {
                width: "100%"
            }
        }
    },
    iconsContainer: {
        textAlign: "right",
        padding: "0px",
        paddingTop: "5px",
        width: "100%",
    },
    searchTerms: {
        listStyleType: "none",
        "& li": {
            display: "inline-block"
        },
        "& .gray": {
            backgroundColor: grayColor[26],
        },
        "& .customInfo": {
            backgroundColor: infoColor[8],
        },
        "& .customRose": {
            backgroundColor: roseColor[5],
        },
        "& .customSuccess": {
            backgroundColor: successColor[8],
        }
    },
    hidden: {
        display: "none"
    },
    filtersGrid: {
        marginTop: "30px",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& p": {
            textAlign: "left"
        },
        "& ul":{
            display: "flex",
            listStyleType: "none",
            paddingLeft: "0px",
            marginBottom: theme.spacing(0),
            paddingBottom: theme.spacing(1),
            borderBottom: "1px solid "+grayColor[11],
            "& li": {
                margin: "0px 3px",
                "&:first-child": {
                    marginLeft: "0px",
                },
                "&:last-child": {
                    marginRight: "0px",
                }
            },
            "& button": {
                margin: "0px",
                padding: "0px",
                minWidth: "0px",
                lineHeight: "0px",
                maxWidth: "100%",
                height: "41px",
                width: "41px",
                color: grayColor[15],
                "&:hover": {
                    color: grayColor[15],
                },
                "&.active": {
                    border: "1px solid "+grayColor[12]
                },
                "& i": {
                    lineHeight: "41px !important",
                    fontSize: "20px !important"
                },
                "& svg": {
                    height: "24px",
                    width: "24px",
                    display: "block"
                },
                "& img": {
                    width: "20px",
                    display: "block",
                    "&.youtube-icon": {
                         width: "25px",
                    }
                },
            },
            "& .active button": {
                backgroundColor: primaryColor[0],
                boxShadow:
                    "0 2px 2px 0 rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.14), 0 3px 1px -2px rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.2), 0 1px 5px 0 rgba(" +
                    hexToRgb(primaryColor[0]) +
                    ", 0.12)",
                color: whiteColor,
                "&:hover": {
                    color: whiteColor,
                },
            },
        },
    },
    dark: {
        "& $filtersGrid": {
            "& ul": {
                "& button": {
                    color: whiteColor,
                    "& svg": {
                        fill: whiteColor
                    }
                }
            },
            
        },
        "& p": {
            color: whiteColor
        },
        "& input": {
            color: whiteColor
        },
        "& input::placeholder": {
            color: whiteColor
        },
        "& input::-webkit-input-placeholder": {
            color: whiteColor
        },
        "& input:-ms-input-placeholder": {
            color: whiteColor
        },
        "& $platformOptions, & $guestViewOptions": {
            border: "1px dashed "+grayColor[0],
        },
        "& $guestViewOptions": {
            boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(grayColor[0]) + ", 0.26)"
        },
        "& $layout, & $label": {
            color: whiteColor,
        }
    },
    feedContainer: {
        maxWidth: "450px",
        textAlign: "left",
        marginTop: "15px"
    },
    guestViewOptionsContainer: {
        float: "none",
        display: "inline-block",
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: "220px",
        textAlign: "center",
        position: "relative",
        marginLeft: "30px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "15px",
            textAlign: "left",
        }
    },
    guestViewOptions: {
        width: "auto",
        textAlign: "center",
        maxWidth: "220px",
        boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
        paddingLeft: "0px",
        paddingRight: "0px",
        "& button:hover": {
            backgroundColor: "transparent",
            color: grayColor[8],
        },
        // "& button:focus": {
        //     backgroundColor: "rgba(0, 0, 0, 0.14)",
        //     color: grayColor[8],
        // },
        float: "none",
        display: "inline-block"
    },
    activeViewOption: {
        backgroundColor: grayColor[11]+" !important",
        color: grayColor[8]+" !important",
    },
    viewTypeLabel: {
        fontWeight: "bold",
        fontSize: "12px",
        position: "absolute",
        width: "100%",
        textAlign: "center"
    },
    grid: {
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    layout: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        marginBottom: "40px"
    },
    platforms: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        marginBottom: "40px",
        marginTop: "15px"
    },
    platformOptionsContainer: {
        float: "none",
        display: "inline-block",
        paddingLeft: "0px",
        paddingRight: "0px",
        maxWidth: "192px",
        textAlign: "center",
        position: "relative",
        marginLeft: "30px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "15px",
            textAlign: "left",
        }
    },
    platformOptions: {
        width: "auto",
        border: "1px dashed "+blackColor,
        padding: "8px",
        textAlign: "center",
        marginBottom: theme.spacing(2),
        display: "inline-block",
        "& button": {
            padding: "6px",
            margin: "0px 6px"
        },
        "& svg": {
            height: "30px",
            width: "30px",
            display: "block"
        },
        "& img": {
            width: "25px",
            display: "block",
            "&.youtube-icon": {
                 width: "30px",
            }
        },
    },
    label: {
        ...customCheckboxRadioSwitch.label,
        marginLeft: "45px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "25px"
        }
    }
});

export default searchTermsStyle;
