import {
    
} from "../../material-kit-pro-react.jsx";

const analyticsTabProfileBasicStyle = theme => ({
    main: {
        padding: "0px",
        [theme.breakpoints.up("xs")]: {
            paddingLeft: "2px"
        }
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
    },
    gridItem: {
        padding: "0px"
    },
    basicAnalyticsLists: {
        marginTop: theme.spacing(2),
        paddingLeft: "0px",
        listStyleType: "none",
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .name": {
                fontWeight: "bold",
            }
        }
    }
});

export default analyticsTabProfileBasicStyle;