import {
    container,
    main,
} from "../material-kit-pro-react.jsx";

const termsHomePageStyle = theme => ({
    main: {
        ...main
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    container: {
        ...container,
        paddingLeft: "0px",
        paddingRight: "0px",
        "& h5": {
            fontWeight: "bold"
        },
    },
    textCenter: {
        textAlign: "Center"
    },
    italic: {
        fontStyle: "italic"
    },
    marginLeft: {
        paddingLeft: "0.7em !important",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.1em !important"
        },
        "& >li ": {
            "& p": {
                paddingLeft: "1.1em !important"
            }
        }
    },
    termsContentLists: {
        "& p": {
            display: "inline-block",
            verticalAlign: "top",
            width: "calc(100% - 2.5em)",
            lineHeight: "1.8em",
            paddingLeft: "0.5em",
            wordBreak: "break-word"
        },
        "& ol": {
            counterReset: "counter",
            fontSize: "1.375em",
            marginLeft: "0.5em",
            padding: "0",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "0em",
            },
            "&:nth-of-type(1)": {
                counterReset: "counter 0",
            },
            "& ol": {
                paddingLeft: "0.3em",
                [theme.breakpoints.down("sm")]: {
                    paddingLeft: "0.9em",
                },
            },
            "& >li": {
                margin: "0px",
                padding: "0px",
                position: "relative",
                listStyle: "none",
                "&::before": {
                    content: 'counter(counter) ". "',
                    counterIncrement: "counter",
                    fontSize: "1.0625rem",
                    fontWeight: "bold",
                },
                "& ol>li": {
                    fontSize: "0.5333333333em",
                    "& p": {
                        paddingLeft: "1.0em",
                        [theme.breakpoints.down("sm")]: {
                            paddingLeft: "0.5em",
                        },
                    },
                    "&::before": {
                        content: 'counters(counter, ".") ". "',
                        counterIncrement: "counter",
                        fontSize: "0.875em",
                        position: "relative",
                        maxWidth: "20px",
                        width: "100%",
                        display: "inline-block",
                    }
                },
            }
        },
    }
});

export default termsHomePageStyle;