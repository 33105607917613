import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import Auth from '@aws-amplify/auth';
import Config from '../../../../../Config';
import { helper } from "../../../../assets/js/utils/Element";
import axios from 'axios';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Danger from "../../../components/Typography/Danger.jsx";
import Link from "@material-ui/core/Link";
import DeleteAccountModal from "../../../components/Dashboard/DeleteAccountModal";
import ChangeUserLevelModal from "../../../components/Dashboard/ChangeUserLevelModal";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
import { Redirect } from 'react-router-dom';
import DeleteSubscriptionModal from "../../../components/Dashboard/DeleteSubscriptionModal";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent.jsx";
import SaveIcon from "@material-ui/icons/Save";

//Tooltip Modals
import UniqueUrlTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/UniqueUrlTooltipModal";
import VerifiedEmailTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/VerifiedEmailTooltipModal";
import AccountLevelTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/AccountLevelTooltipModal";
import ChangeUniqueUrlTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/ChangeUniqueUrlTooltipModal";
import VerifiedPhoneNumberTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/VerifiedPhoneNumberTooltipModal";
import ExportDataTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/ExportDataTooltipModal";
import CloseAccountTooltipModal from "../../../components/ToolTips/Dashboard/Account/AccountSettings/CloseAccountTooltipModal";

import dashboardTabAccountSettingsStyle from "../../../../assets/jss/dashboard/account/dashboardTabAccountSettingsStyle.js";

const DashboardTabAccountSettings = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { match } = this.props;
        const { user, packages } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        let username = user.username;
        let userLevelLabel = "";
        if(packages.length > 0){
            userLevelLabel = packages[0].name;
        }
        packages.map(packageObj => {
            if(user.userLevel === packageObj.level){
                userLevelLabel = packageObj.name;
            }
            return null;
        });
        let changeUserLevel = false;
        if(typeof(match) === "object" && match.path === "/dashboard/account/upgrade"){
            changeUserLevel = true;
        }
        this.state = {
            values: values,
            defaultUsername: username,
            username: username,
            email: user.userEmail,
            newEmail: user.userEmail,
            emailCode: "",
            phone: ' ',
            userLevel: user.userLevel,
            userLevelLabel: userLevelLabel,
            saving: false,
            user: user,
            validation: {
                username: '',
                email: '',
                emailCode: '',
                phone: ''
            },
            validatingUsername: false,
            editUsername: false,
            cancelTokenUsername: null,
            editEmail: false,
            verificationEmailCode: false,
            deleteAccountModal: false,
            changeUserLevel: changeUserLevel,
            paymentData: this.props.paymentData,
            tooltips: {
                username: false,
                email: false,
                userLevel: false,
                editUsername: false,
                phone: false,
                exportData: false,
                closeAccount: false
            },
            logout: false,
            deletingUser: false,
            deleteSubscriptionModal: false,
            subscription: this.props.subscription,
            deletingSubscription: false
        };

        this.validateUsernameTimeout = null;
        this.saveUserInfo = this.saveUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editUsername = this.editUsername.bind(this);
        this.saveUsername = this.saveUsername.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.resendEmailCode = this.resendEmailCode.bind(this);
        this.submitEmailCode = this.submitEmailCode.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleDeleteSubscription = this.handleDeleteSubscription.bind(this);
        this.deleteSubscription = this.deleteSubscription.bind(this);
        this.fixUserSubscription = this.fixUserSubscription.bind(this);
    }
    componentWillReceiveProps(props){
        const { subscription } = this.state;
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription
        });
        if(subscription === null){
            this.handleSubscriptionNotice(props.subscription);
        }
    }
    componentDidUpdate(prevProps, prevState){
        const state = this.state;
        const that = this;
        if(state.username !== state.defaultUsername && state.username !== prevState.username){
            if(this.validateUsernameTimeout !== null){
                clearTimeout(this.validateUsernameTimeout);
            }
            this.validateUsernameTimeout = setTimeout(function(){
                that.validateUsername();
            },1000);
        }
    }
    componentDidMount(){
        const { subscription } = this.state;
        this.handleSubscriptionNotice(subscription);
    }
    handleSubscriptionNotice(subscription){
        if(subscription === null){
            return;
        }
        if(this.isSubscriptionCancelled(subscription)){
            this.props.onSubscriptionNotice(
                this.renderSubscriptionCancelled(subscription)
            );
        }
        const { user } = this.store.getState();
        if(user.userLevel !== subscription.userLevel){
            window.location.reload();
        }
    }
    getSubscription(){
        const { user } = this.store.getState();
        const source = axios.CancelToken.source(); 
        this.setState({cancelToken: source});
        Api.getSubscription(source).then((data) => {
            this.setState({subscription: data});
            if(this.isSubscriptionCancelled(data)){
                this.props.onSubscriptionNotice(
                    this.renderSubscriptionCancelled(data)
                );
            }
            if(user.userLevel !== data.userLevel){
                window.location.reload();
            }
        }).catch(err => {
            
        });
    }
    saveUserInfo(){
        this.setState({editInfo: false, savingInfo: true});
        this.updateUser();
    }
    updateUser(values){
        const { user } = this.store.getState();
        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0], redirect: true }});
                this.history.push("/dashboard/account");
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(event, name) {
        let value = event.target.value;
        if(name === "username"){
            value = value.replace(/@|:| |;|\/|\\/g,"");
        }
        this.setState({
            [name]: value
        });
    }
    editUsername(status = true){
        let state = {editUsername: status};
        if(!status){
            state['username'] = this.state.defaultUsername;
            state['validatingUsername'] = false;
            state['validation'] = {
                ...this.state.validation,
                username: ''
            }
            if(this.state.cancelTokenUsername){
                this.state.cancelTokenUsername.cancel('Cancelled');
            }
        }
        this.setState(state);
    }
    saveUsername(){
        if(this.state.validation.username !== "success"){
            return;
        }

        let values = this.state.values;
        values.username = this.state.username;
        this.updateUser(values);
        this.setState({ values: values });
    }
    validateUsername(){
        if(this.state.cancelTokenUsername){
            this.state.cancelTokenUsername.cancel('Cancelled');
        }
        if(this.state.username.length <= 0){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: "error"
                },
                validatingUsername: false
            });
            return;
        }
        if(this.state.username === this.state.defaultUsername){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: "success"
                },
                validatingUsername: false
            });
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            validatingUsername: true,
            cancelTokenUsername: source
        });        
        Api.checkUsername(this.state.username, source).then((data) => {
            this.setState({
                cancelTokenUsername: null,
                validatingUsername: false,
                validation: {
                    ...this.state.validation,
                    username: "error"
                },
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelTokenUsername: null,
                    validatingUsername: false,
                    validation: {
                        ...this.state.validation,
                        username: "success"
                    },
                });
            }
        });
    }
    changeEmail(status = true) {
        let state = { editEmail: status };
        if(!status){
            state['newEmail'] = this.state.email;
        }
        this.setState(state);
    }
    verifyEmail() {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(this.state.newEmail) === true) {
            this.setState({
                validation: {
                    ...this.state.validation,
                    email: "success"
                },
            });
            Auth.currentAuthenticatedUser().then(user => {
                Auth.updateUserAttributes(user, { email: this.state.newEmail }).then(newUser => {
                    //Success
                }).catch(e => {
                    //Handle error here
                });
            }).catch(e => {
                //Handle error here
            });
            this.setState({ verificationEmailCode: true });            
        }else{
            this.setState({
                validation: {
                    ...this.state.validation,
                    email: "error"
                },
            });
        }
        return false;
    }
    submitEmailCode() {
        Auth.verifyCurrentUserAttributeSubmit("email", this.state.emailCode).then(data => {
            let values = this.state.values;
            values.userEmail = this.state.newEmail;
            // this.updateUser(values);

            this.setState({
                values: values,
                editEmail: false,
                verificationEmailCode: false,
                validation: {
                    ...this.state.validation,
                    emailCode: "success",
                    email: ''
                }
            });            
        }).catch(err => {
            this.setState({
                validation: {
                    ...this.state.validation,
                    emailCode: "error"
                }
            });
        });
        
    }
    resendEmailCode(event) {
        event.preventDefault();
        Auth.verifyCurrentUserAttribute("email");
    }
    handleDeleteClick(deleteAccount = true){
        this.setState({deleteAccountModal: deleteAccount});
    }
    deleteAccount(){
        this.setState({
            deletingUser: true,
        });
        const { user } = this.store.getState();
        Api.deleteUser(user.userId).then(data => {
            this.setState({
                deleteAccountModal: false,
                logout: true,
                deletingUser: false
            });
        }).catch(err => {
            console.log(err);
            this.setState({
                deleteAccountModal: false,
                deletingUser: false
            });
        });
    }
    changeUserLevel(changeUserLevel = true){
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(!changeUserLevel && returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            const { user } = this.store.getState();
            if(returnUrl === "search"){
                window.location.href = Config.getFrontEndUrl()+"/"+user.username;
            }else{
                this.setState({changeUserLevel: changeUserLevel});
                this.history.push(decodeURIComponent(returnUrl));
            }
            return;
        }
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    handleDeleteSubscription(deleteSubscription = true){
        this.setState({deleteSubscriptionModal: deleteSubscription});
    }
    deleteSubscription(){
        const source = axios.CancelToken.source();
        this.setState({
            deletingSubscription: true,
            cancelToken: source
        });
        const that = this;
        Api.deleteSubscription({}, source).then(data => {
            this.setState({deletingSubscription: false});
            Api.getSubscription(source).then((data) => {
                this.setState({
                    subscription: data,
                });
                if(this.isSubscriptionCancelled(data)){
                    this.props.onSubscriptionNotice(
                        this.renderSubscriptionCancelled(data)
                    );
                }else{
                    this.props.onSubscriptionNotice(null);
                }
            }).catch(err => {
                this.setState({
                    subscription: null,
                });
            });
            setTimeout(function(){
                that.setState({deleteSubscriptionModal: false});
            }, 2000);
        }).catch(err => {

        });
    }
    isSubscriptionCancelled(subscription){
        if(subscription === null || !subscription.subscriptionIsCanceled){
            return false;
        }
        if(!subscription.hasOwnProperty("subscriptionCanceledAsOfDateTime") || subscription.subscriptionCanceledAsOfDateTime.trim().length <= 0){
            return false;
        }
        return true;
    }
    getSubscriptionCancelDate(subscription = null){
        if(subscription === null){
            subscription = this.state.subscription;
        }
        if(!this.isSubscriptionCancelled(subscription)){
            return null;
        }
        try{
            const date = new Date(subscription.subscriptionCanceledAsOfDateTime);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
            const month = monthNames[date.getMonth()];
            return (month+" "+date.getDate()+", "+date.getFullYear());
        }catch(e){ console.log(e); }
    }
    fixUserSubscription(event){
        event.preventDefault();
        this.setState({changeUserLevel: true});
    }
    renderSubscriptionCancelled(subscription){
        const cancelDate = this.getSubscriptionCancelDate(subscription);
        return (
            <SnackbarContent
                message={
                    <p>Your subscription is pending cancelation as of {cancelDate}.  <Link href="/" onClick={this.fixUserSubscription}>Click Here</Link> to Fix It</p>
                }
                color="danger"
            />
        );
    }
    render() {
        if(this.state.logout){
            return <Redirect to='/logout' />
        }
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const{ validation, editUsername, editEmail, verificationEmailCode, deleteAccountModal, deletingSubscription, 
            changeUserLevel, paymentData, tooltips, deletingUser, subscription, deleteSubscriptionModal } = this.state;
        const cancelDate = this.getSubscriptionCancelDate();
        return (
            <GridItem className={classes.main} xs={12} sm={12} md={10} lg={8}>
                <h4 className={classes.title}>Account Settings</h4>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={8} sm={9} md={9} lg={9}>
                        <CustomInput
                            id="outlined-myusername"
                            success={validation.username === "success"}
                            error={validation.username === "error"}
                            labelText="Unique Url"
                            loading={this.state.validatingUsername}
                            inputProps={{
                                value: this.state.username,
                                onChange: event => this.handleChange(event, "username"),
                                name: "myusername",
                                disabled: !editUsername,
                                onBlur: event => this.validateUsername()
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear={() => this.editUsername(false) }
                            showInfoTooltip={user.userPreferences.showTips}
                            onShowInfo={() => this.onTooltip('username', true)}
                        />
                        {
                            validation.username === "error" ?
                                <Danger>This url is not available</Danger>
                            :
                            <></>
                        }                        
                    </GridItem>
                    <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                        {
                            editUsername === false ?
                                <div>
                                    <Button color="blue" onClick={() => this.editUsername()}>Change</Button>
                                </div>
                            :
                                <Button color="success" onClick={() => this.saveUsername()}>
                                    <SaveIcon className={classes.icons} /> Save
                                </Button>
                        }
                    </GridItem>
                </GridContainer>
                <p className={classes.socialLinkPreview}>
                    {Config.getFrontEndUrl()+"/"+this.state.username}
                </p>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={8} sm={9} md={9} lg={9}>
                        <CustomInput
                            id="outlined-email"
                            success={validation.email === "success"}
                            error={validation.email === "error"}
                            labelText="Verified Email"
                            inputProps={{
                                value: this.state.newEmail,
                                onChange: event => this.handleChange(event, "newEmail"),
                                name: "email",
                                disabled: !editEmail,
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear={() => this.changeEmail(false) }
                            showInfoTooltip={user.userPreferences.showTips}
                            showPrivateIcon={true}
                            onShowInfo={() => this.onTooltip('email', true)}
                        />
                    </GridItem>
                    {
                        (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                            <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                                {
                                    editEmail === false ?
                                        <Button color="blue" onClick={() => this.changeEmail()}>Change</Button>
                                    :
                                        <Button color="blue" onClick={() => this.verifyEmail()}>Verify</Button>
                                }
                            </GridItem>
                        :
                        <></>
                    }
                    
                </GridContainer>
                {
                    verificationEmailCode ?
                        <>
                            <GridContainer>
                                <GridItem xs={8} sm={9} md={9} lg={9}>
                                    <CustomInput
                                        id="outlined-emailCode"
                                        success={validation.emailCode === "success"}
                                        error={validation.emailCode === "error"}
                                        labelText="Verification Code"
                                        inputProps={{
                                            value: this.state.emailCode,
                                            onChange: (e) => this.handleChange(e, 'emailCode'),
                                            name: "emailCode",
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                                    <Button color="success" round onClick={() => this.submitEmailCode(false)}>Submit Code</Button>
                                </GridItem>
                            </GridContainer>
                            <p className="redirect-customizable text-center mt-2"><span>Didn't receive a code?</span>&nbsp;<Link href="/" onClick={(e) => this.resendEmailCode(e)}>Resend it</Link></p>
                        </>
                    :
                    <></>
                }
                {
                    (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                        <GridContainer className={classes.gridContainer}>
                            <GridItem xs={8} sm={9} md={9} lg={9}>
                                <CustomInput
                                    id="outlined-phone"
                                    success={validation.phone === "success"}
                                    error={validation.phone === "error"}
                                    labelText="Your Verified Phone Number"
                                    inputProps={{
                                        value: this.state.phone,
                                        onChange: event => this.handleChange(event, "phone"),
                                        name: "phone",
                                        disabled: true,
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    showInfoTooltip={user.userPreferences.showTips}
                                    showPrivateIcon={true}
                                    onShowInfo={() => this.onTooltip('phone', true)}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                                <Button color="blue" >Change</Button>
                            </GridItem>
                        </GridContainer>
                    :
                    <></>
                }
                
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={8} sm={9} md={9} lg={9}>
                        <CustomInput
                            id="input-userLevel"
                            success={validation.userLevel === "success"}
                            error={validation.userLevel === "error"}
                            labelText="Your Account Level"
                            inputProps={{
                                value: this.state.userLevelLabel,
                                name: "userLevel",
                                disabled: true,
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showInfoTooltip={user.userPreferences.showTips}
                            showPrivateIcon={true}
                            onShowInfo={() => this.onTooltip('userLevel', true)}
                        />
                        
                    </GridItem>
                    <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                        <Button color="blue" onClick={() => this.changeUserLevel()} >Change</Button>
                    </GridItem>
                    {
                        cancelDate !== null ?
                            <GridItem lg={12}>
                                <p className={classes.cancelSubscription}>Subscription will be canceled on {cancelDate}.  <Link href="/" onClick={this.fixUserSubscription}>Click Here</Link> to Fix It</p>
                            </GridItem>
                        :
                        <></>
                    }
                </GridContainer>
                {
                    (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                    <>
                        <br />
                        <Button round>Export Data</Button>
                        {
                            user.userPreferences.showTips ?
                                <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('exportData', true)} />
                            :
                            <></>
                        }
                        <br />
                    </>
                    :
                    <></>
                }
                <br />
                Danger Zone
                {
                    user.userLevel !== 0 && subscription !== null && subscription.subscriptionIsCanceled === false ?
                        <>
                            <br />
                            <Button color="danger" onClick={() => this.handleDeleteSubscription()} >Cancel Subscription</Button>
                        </>
                    :
                    <></>
                }
                <br />
                <Button color="danger" onClick={() => this.handleDeleteClick()} >Close Account</Button>
                {
                    user.userPreferences.showTips ?
                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('closeAccount', true)} />
                    :
                    <></>
                }
                <DeleteAccountModal deletingUser={deletingUser} open={deleteAccountModal} onSuccess={this.deleteAccount} onClose={() => this.handleDeleteClick(false)} />
                <DeleteSubscriptionModal subscription={subscription} deletingSubscription={deletingSubscription} open={deleteSubscriptionModal} onSuccess={this.deleteSubscription} onClose={() => this.handleDeleteSubscription(false)} />
                {
                    changeUserLevel ?
                        <ChangeUserLevelModal upgrade={true} subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                    :
                    <></>
                }
                {
                    tooltips.username ?
                        <UniqueUrlTooltipModal open={tooltips.username} store={this.store} onClose={() => this.onTooltip('username', false)} />
                    :
                    <></>
                }
                {
                    tooltips.email ?
                        <VerifiedEmailTooltipModal open={tooltips.email} store={this.store} onClose={() => this.onTooltip('email', false)} />
                    :
                    <></>
                }
                {
                    tooltips.userLevel ?
                        <AccountLevelTooltipModal open={tooltips.userLevel} store={this.store} onClose={() => this.onTooltip('userLevel', false)} />
                    :
                    <></>
                }
                {
                    tooltips.editUsername ?
                        <ChangeUniqueUrlTooltipModal open={tooltips.editUsername} store={this.store} onClose={() => this.onTooltip('editUsername', false)} />
                    :
                    <></>
                }
                {
                    tooltips.phone ?
                        <VerifiedPhoneNumberTooltipModal open={tooltips.phone} store={this.store} onClose={() => this.onTooltip('phone', false)} />
                    :
                    <></>
                }
                {
                    tooltips.exportData ?
                        <ExportDataTooltipModal open={tooltips.exportData} store={this.store} onClose={() => this.onTooltip('exportData', false)} />
                    :
                    <></>
                }
                {
                    tooltips.closeAccount ?
                        <CloseAccountTooltipModal open={tooltips.closeAccount} store={this.store} onClose={() => this.onTooltip('closeAccount', false)} />
                    :
                    <></>
                }
            </GridItem>
        )
    }
}

DashboardTabAccountSettings.defaultProps = {
    onSubscriptionNotice: function(){ },
    onCardExpiredNotice: function(){ }
}
DashboardTabAccountSettings.propTypes = {
    onSubscriptionNotice: PropTypes.func,
    onCardExpiredNotice: PropTypes.func
}

export default withStyles(dashboardTabAccountSettingsStyle)(DashboardTabAccountSettings);
