import {
    
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "../tabs/dashboardSubTabs";

const dashboardTabAccountStyle = theme => ({
    ...dashboardSubTabs(theme),
});

export default dashboardTabAccountStyle;