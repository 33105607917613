import React from 'react';
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardTabAdminLogin from "../admin/Login";
import DashboardTabAdminEmail from "../admin/Email";
import NavPills from "../../../components/NavPills/NavPills.jsx";

import MaterialIcon from "@mdi/react";
import { mdiAccountSwitch, mdiEmail } from '@mdi/js';

import dashboardTabAdminStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabAdminStyle.js";
const history = createBrowserHistory();

const DashboardTabAdmin = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.navPills = React.createRef();

        this.state = {
            defaultTab: this.props.params.subTab,
            match: this.props.match,
        };

        this.onTabChange = this.onTabChange.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/admin/login");
                break;
            case 1:
                history.push("/dashboard/admin/email");
                break;
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    render() {
        const { classes } = this.props;
        const { defaultTab } = this.state;
        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    alignCenter
                    color="primary"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    tabs={[
                        {
                            tabButton: "",
                            tabImageIcon: <MaterialIcon path={mdiAccountSwitch} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabAdminLogin store={this.store} />
                            ),
                        },
                        {
                            tabButton: "",
                            tabImageIcon: <MaterialIcon path={mdiEmail} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabAdminEmail store={this.store} />
                            ),
                        },
                    ]}
                />
            </div>
        )
    }
}

export default withStyles(dashboardTabAdminStyle)(DashboardTabAdmin);
