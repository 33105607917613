import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import changeBillingTypeModalStyle from "../../../assets/jss/dashboard/changeBillingTypeModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeBillingTypeModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            billingType: "monthly"
        };

        this.handleBillingType = this.handleBillingType.bind(this);
    }
    handleBillingType(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="billingType-slide-title"
                aria-describedby="billingType-slide-description"
            >
                <DialogTitle
                    id="billingType-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <h4 className={classes.modalTitle}>Billing Type</h4>
                </DialogTitle>
                <DialogContent
                    id="billingType-slide-description"
                    className={classes.modalBody}
                    >
                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                            control={
                            <Radio
                                checked={this.state.billingType === "monthly"}
                                onChange={(e) => this.handleBillingType(e, "billingType")}
                                value="monthly"
                                name="billingType"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                            }
                            classes={{label: classes.label, root: classes.labelRoot}}
                            label="Monthly Billing - $5.99/month"
                        />
                    </div>
                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                            control={
                            <Radio
                                checked={this.state.billingType === "yearly"}
                                onChange={(e) => this.handleBillingType(e, "billingType")}
                                value="yearly"
                                name="billingType"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                            }
                            classes={{label: classes.label, root: classes.labelRoot}}
                            label="Annual Billing - $4.99/month"
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                    >
                    <Button onClick={() => this.props.onSuccess()} color="info" round>Change</Button>
                    <Button onClick={() => this.props.onClose()} color="info" round>Nevermind</Button>
                </DialogActions>
            </Dialog>
        );
    }
};

ChangeBillingTypeModal.defaultProps = {
  open: false
};
ChangeBillingTypeModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(changeBillingTypeModalStyle)(ChangeBillingTypeModal);
