import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
// import Radio from "@material-ui/core/Radio";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import downgradeStep1Style from "../../../../assets/jss/dashboard/userLevelWizard/downgradeStep1Style";

const DowngradeStep1 = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.state = {
            billingType: this.props.billingFrequency,
        };

        this.handleBillingType = this.handleBillingType.bind(this);
        this.success = this.success.bind(this);
    }
    handleBillingType(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    success(){
        this.props.onSuccess(this.state);
    }
    render(){
        const { classes, userPackage } = this.props;
        const { billingType } = this.state;
        return(
            <div>
                <div className={classes.centerStep2}>
                    <p>You have chosen to downgrade your account to:</p>
                    <div className={classes.bold}>{userPackage.name}</div>
                </div>
               
                <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                    <p>Total Package Cost</p>
                    <div className={classes.bold}>
                        {
                            billingType.toLowerCase()  === "monthly" ?
                                userPackage.pricing.monthlyDescription
                            :
                                userPackage.pricing.annualDescription
                        }
                    </div>
                </div>
                <div className={classes.overflowHidden+" "+classes.marginTopStep2}>
                    <Button onClick={() => this.props.onBack()}  color="info" round>Back</Button>
                    <Button color="info" onClick={() => this.success()} round className={classes.nextButton}>
                        Confirm
                    </Button>
                </div>
            </div>
        )
    }
}

DowngradeStep1.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};
export default withStyles(downgradeStep1Style)(DowngradeStep1);