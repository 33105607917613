import React from 'react';
import PropTypes from "prop-types";
import Api from "../../../../assets/js/utils/Api";
// import { helper } from '../../../../assets/js/utils/Element';
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardTabProfileEdit from "../profile/Edit";
import DashboardTabProfileTheme from "../profile/Theme";
import DashboardTabProfileAbout from "../profile/About";
import DashboardTabProfileSearch from "../profile/Search";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import Palette from "@material-ui/icons/Palette";
// import ProfileTourModal from "../../../components/Dashboard/ProfileTourModal.jsx";
// import ProfileTour from "../../../components/Dashboard/ProfileTour.jsx";

import MaterialIcon from "@mdi/react";
import { mdiAccountEditOutline, mdiAccountDetails, mdiHome } from '@mdi/js';

import dashboardTabProfileStyle from "../../../../assets/jss/dashboard/tabs/dashboardTabProfileStyle.js";
const history = createBrowserHistory();

const DashboardTabProfile = class extends React.Component {
    constructor(props){
        super(props);
       
        this.store = this.props.store;
        this.history = this.props.history;
        this.navPills = React.createRef();
        const { user } = this.store.getState();
        const openModal = (user.userPreferences.profileTourComplete === false && user.userPreferences.showTours === true);
        let subTab = this.props.params.subTab;
        if(subTab > 3){
            subTab = 0;
        }
        this.state = {
            defaultTab: subTab,
            match: this.props.match,
            openModal: openModal,
            startTour: false,
            showTours: null
        };

        this.onTabChange = this.onTabChange.bind(this);
        this.handleTourPopup = this.handleTourPopup.bind(this);
        this.handleTour = this.handleTour.bind(this);
        this.onTourClose = this.onTourClose.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/dashboard/profile/info");
                break;
            case 1:
                history.push("/dashboard/profile/home");
                break;
            case 2:
                history.push("/dashboard/profile/about");
                break;
            case 3:
                history.push("/dashboard/profile/styling");
                break;
            
            default:
                break;
        }
        setTimeout(function(){
            window.gtag('pageTitle', document.title);
            window.gtag('event', 'page_view',{
              'page_path': window.location.href
            });
        },1000);
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    handleTourPopup(showTours = null) {
        this.setState({ openModal: false });

        const { user } = this.store.getState();
        user.userPreferences.profileTourComplete = true;
        Api.updateUser(user.userId, user);        
        if(showTours !== null){
            user.userPreferences.showTours = showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
        }
        this.history.push("/dashboard/profile");
    }
    handleTour(showTours = null) {
        this.setState({
            openModal: false,
            startTour: true,
            showTours: showTours
        });
        const { user } = this.store.getState();
        user.userPreferences.profileTourComplete = true;
        Api.updateUser(user.userId, user);
        history.push("/dashboard/profile");
    }
    onTourClose(){
        if(this.state.showTours !== null){
            const { user } = this.store.getState();
            user.userPreferences.profileTourComplete = true;
            user.userPreferences.showTours = this.state.showTours;
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: user }});
            this.history.push("/dashboard/profile");
        }
    }
    render() {
        const { classes, paymentData, subscription, platforms } = this.props;
        const { defaultTab } = this.state;
        // let { openModal, startTour } = this.state;
        const { user, authorized } = this.store.getState();
        // const startProfileTour = helper.getParam("startProfileTour");
        // if(startProfileTour && startProfileTour.length > 0 && startProfileTour !== "null"){
        //     openModal = true;
        // }
        if(this.props.isActiveTab !== true){
            return <></>;
        }
        const showLabels = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showLabels);

        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return (
            <div className={classes.tabs}>
                <NavPills
                    ref={this.navPills}
                    horizontal={{
                        tabsGrid: { className: (showLabels ? "showLabels" : "") },
                    }}
                    alignCenter
                    color="primary"
                    active={defaultTab}
                    onChange={this.onTabChange}
                    disableSwipeable={true}
                    tabs={[
                        {
                            tabButton: (showLabels ? "Personal" : ""),
                            tabImageIcon: <MaterialIcon path={mdiAccountEditOutline} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabProfileEdit isActiveTab={(defaultTab === 0)} store={this.store} history={this.history} />
                            ),
                            className: "tab-edit-profile",
                        },
                        {
                            tabButton: (showLabels ? "Home" : ""),
                            tabImageIcon: <MaterialIcon path={mdiHome} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabProfileSearch subscription={subscription} paymentData={paymentData} platforms={platforms} isActiveTab={(defaultTab === 1)} store={this.store} match={this.props.match} history={this.history} />
                            ),
                            className: "tab-search-profile",
                            hidden: !showBetaFeatures,
                        },
                        {
                            tabButton: (showLabels ? "About" : ""),
                            tabImageIcon: <MaterialIcon path={mdiAccountDetails} className={"MuiSvgIcon-root"} />,
                            tabContent: (
                                <DashboardTabProfileAbout subscription={subscription} paymentData={paymentData} isActiveTab={(defaultTab === 2)} store={this.store} history={this.history} />
                            ),
                            className: "tab-about-profile",
                            hidden: !showBetaFeatures,
                        },
                        {
                            tabButton: (showLabels ? "Style" : ""),
                            // hidden: !showBetaFeatures,
                            tabIcon: Palette,
                            tabContent: (
                                <DashboardTabProfileTheme subscription={subscription} paymentData={paymentData} isActiveTab={(defaultTab === 3)} store={this.store} match={this.props.match} history={this.history} />
                            ),
                            className: "tab-choose-color",
                            hidden: !showBetaFeatures,
                        }
                    ]}
                />
                {/* <ProfileTourModal
                    open={openModal}
                    onClose={this.handleTourPopup}
                    onClick={this.handleTour}
                    store={this.store}
                />
                {(startTour === true) ? <ProfileTour run={startTour} onClose={this.onTourClose} /> : <></>} */}
            </div>
        )
    }
}

DashboardTabProfile.defaultProps = {
    subscription: null,
    paymentData: null
}
DashboardTabProfile.propTypes = {
    subscription: PropTypes.object,
    paymentData: PropTypes.object.isRequired
}

export default withStyles(dashboardTabProfileStyle)(DashboardTabProfile);
