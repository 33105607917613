import {
    primaryColor,
    dangerColor,
    successColor,
    whiteColor,
    grayColor,
    roseColor,
    warningColor,
    infoColor,
    hexToRgb
} from "../../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../customCheckboxRadioSwitchStyle.jsx";

const setupProgressStyle = theme => ({
    ...customCheckboxRadioSwitch,
    main: {
        marginLeft: "0px",
        marginRight: "0px",
        "& .MuiGrid-item": {
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    },
    title: {
        fontWeight: "bold",
        margin: theme.spacing(1,0,2,0),
        display: "inline-block"
    },
    progressBar: {
        width: "100%",
        height: "15px",
        background: grayColor[17],
        borderRadius: "10px"
    },
    progressBarComplete: {
        display: "block",
        textAlign: "center",
        lineHeight: "15px",
        fontSize: "12px",
        color: whiteColor,
        borderRadius: "10px"
    },
    rose: {
        backgroundColor: roseColor[0]
    },
    info: {
        backgroundColor: infoColor[0]
    },
    success: {
        backgroundColor: successColor[0]
    },
    danger: {
        backgroundColor: dangerColor[0]
    },
    warning: {
        backgroundColor: warningColor[0]
    },
    primary: {
        backgroundColor: primaryColor[0]
    },    
    roseChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(roseColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: roseColor[0]
        }
    },
    infoChecked: {
        backgroundColor: infoColor[0],
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(infoColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: infoColor[0]
        }
    },
    successChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(successColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: successColor[0]
        }
    },
    dangerChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(dangerColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: dangerColor[0]
        }
    },
    warningChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(warningColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: warningColor[0]
        }
    },
    primaryChecked: {
        "& + $switchBar": {
            backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 1) !important"
        },
        "& $switchIcon": {
            borderColor: primaryColor[0]
        }
    },
    steps: {
        marginBottom: "10px",
        marginTop: "10px",
        listStyleType: "none",
        paddingLeft: "0px"
    },
    hideIcon: {
        float: "right",
        marginTop: "8px",
        cursor: "pointer"
    },
    statusIcon: {
        paddingRight: "5px"
    },
    checkIcon: {
        color: successColor[0] + " !important",
        verticalAlign: "middle"
    },
    clearIcon: {
        color: dangerColor[0] + " !important",
        verticalAlign: "middle"
    },
    step: {
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "middle",
        marginBottom: "5px",
        marginTop: "5px"
    }
});

export default setupProgressStyle;
