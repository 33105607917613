const contentTypeIconStyle = theme => ({
    icons: {
        display: "inline-block",
        verticalAlign: "top",
        marginRight: "0px",
        maxHeight: "22px",
        height: "22px",
        width: "22px",
        position: "absolute",
        top: "8px",
        left: "5px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "18px",
            height: "18px",
            width: "18px",
        },
        "& img": {
            verticalAlign: "top",
            height: "auto !important",
            width: "22px !important",
            margin: "0px auto",
            [theme.breakpoints.down("sm")]: {
                width: "13px !important",
            }
        },
    },
    icon: {
        fontSize: "20px",
        width: "22px",
        height: "auto",
        color: "rgba(0, 0, 0, 0.87)",
        verticalAlign: "top",
        [theme.breakpoints.down("sm")]: {
            width: "13px",
        },
    },
});

export default contentTypeIconStyle;
