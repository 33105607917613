import React from 'react';
// import loadImage from "blueimp-load-image";
import { Redirect, Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types";
import axios from 'axios';
import { helper } from "../../../../../../assets/js/utils/Element";
import Config from '../../../../../../../Config';
import Api from '../../../../../../assets/js/utils/Api';
import LoaderComponent from '../../../../../components/Loader';
import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomInput from "../../../../../components/CustomInput/CustomInput.jsx";
import ImageUpload from "../../../../../components/CustomUpload/ImageUpload.jsx";
import GridItem from "../../../../../components/Grid/GridItem.jsx";
import Button from "../../../../../components/CustomButtons/Button.jsx";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ContentItemLocation from "../../../../../components/Location/ContentItemLocation";
import DateTimePicker from "../../../../../components/CustomInput/DateTimePicker.jsx";
import CustomTagsInput from "../../../../../components/CustomInput/CustomTagsInput.jsx";
import ContentDeleteModal from "../../../../../components/Dashboard/Content/ContentDeleteModal";

import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
import { ReactComponent as ShoppingBagIcon } from "../../../../../../assets/icons/shopping_bag.svg";

//Tooltips modal
import EditWebLinkTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/EditWebLinkTooltipModal";
import UrlTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/UrlTooltipModal";
import TitleTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/TitleTooltipModal";
import PhotoTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/PhotoTooltipModal";
import ShowPictureTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/ShowPictureTooltipModal";
import SearchKeyWordsTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/SearchKeyWordsTooltipModal";
import PublicDescriptionTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/PublicDescriptionTooltipModal";
import LocationTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/LocationTooltipModal";
import TopicTooltipmodal from "../../../../../components/ToolTips/Dashboard/EditLink/TopicTooltipmodal";
import DraftTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/DraftTooltipModal";
import FeaturedTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/FeaturedTooltipModal";
import SearchableTooltipmodal from "../../../../../components/ToolTips/Dashboard/EditLink/SearchableTooltipmodal";
import ScheduledTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/ScheduledTooltipModal";
import ContentPublishedDateTimeTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/ContentPublishedDateTimeTooltipModal";
import ContentExpiredDateTimeTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/ContentExpiredDateTimeTooltipModal";
import AffiliateTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/AffiliateTooltipModal";
import AffiliateUrlTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/AffiliateUrlTooltipModal";
import DiscountCodeTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/DiscountCodeTooltipModal";
import PurchaseInfoTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/PurchaseInfoTooltipModal";
import SponsoredTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/SponsoredTooltipModal";
import PrivateNotesTooltipModal from "../../../../../components/ToolTips/Dashboard/EditLink/PrivateNotesTooltipModal";

import Rating from "../../../../../components/Rating/Rating";
import CustomSelectWithIcon from "../../../../../components/CustomInput/CustomSelectWithIcon";
import CustomSelect from "../../../../../components/CustomInput/CustomSelect";

import editPageStyle from "../../../../../../assets/jss/catalog/editPageStyle";

class LinkEdit extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        let contentId = "";
        let requestUserLocation = true;
        if(this.props.hasOwnProperty("match")){
            contentId = this.props.match.params.contentId;
        }
        let contentPlatformId = helper.getParam("contentPlatformId");
        if(contentPlatformId === null || contentPlatformId === "null"){
            contentPlatformId = "";
        }
        if(this.props.hasOwnProperty("contentPlatformId")){
            contentPlatformId = this.props.contentPlatformId;
        }
        const { authorized, user } = this.store.getState();
        let values = {
            contentId: contentId,
            title: '',
            topic: '',
            keywords: '',
            affiliateUrl: '',
            discountCode: '',
            privateNotes: '',
            publicDescription: '',
            isDraft: true, //Using inverse process
            isSearchable: true,
            isAffiliate: false,
            isFeatured: false,
            isSponsored: false,
            hasPurchaseInfo: false,
            googlePlacesId: "",
            latitude: 0.0,
            longitude: 0.0,
            location: "",
            contentPublishedDateTime: "",
            contentExpiredDateTime: "",
            sequenceDateTime: "",
            ownerId:"",
            coverMediaId: "",
            status: "Submitted",
            contentPlatformId: contentPlatformId,
            showPicture: true,
            rating: 0,
            type: "link",
        };
        let isLocation = false;
        if(this.props.hasOwnProperty("content") && this.props.content !== null){
            values = Object.assign(values,this.props.content); 
            values.isAffiliate = false;
            if(values.hasOwnProperty("location") && values.location.trim().length > 0){
                isLocation = true;
            }
            // if(values.hasOwnProperty("isDraft")){
            //     values.isDraft = !values.isDraft;
            // }
        }
        
        let isRecommendation= false;
        if(values.hasOwnProperty("rating") && values.rating > 0){
            isRecommendation = true;
        }
        let isScheduled = false;
        if(values.hasOwnProperty("contentPublishedDateTime") && values.contentPublishedDateTime.length > 0){
            isScheduled = true;
        }
        if(values.hasOwnProperty("contentExpiredDateTime") && values.contentExpiredDateTime.length > 0){
            isScheduled = true;
        }
        if(!values.hasOwnProperty("googlePlacesResponse")){
            values.googlePlacesResponse = {
                latitude: values.hasOwnProperty("latitude")?values.latitude:0.0,
                longitude: values.hasOwnProperty("longitude")?values.longitude:0.0,
                location: values.hasOwnProperty("location")?values.location:"",
                googlePlacesId: values.hasOwnProperty("googlePlacesId")?values.googlePlacesId:"",
                formatted_address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            };
        }
        if(values.hasOwnProperty("location") && values.location.length > 0){
            requestUserLocation = false;
        }
        let photo = null;
        if(values.hasOwnProperty("coverMedia")){
            photo = values.coverMedia.downloadUrlProfile;
        }
        if(!values.hasOwnProperty("showPicture")){
            values['showPicture'] = true;
        }
        
        if(!values.hasOwnProperty("keywords")){
            values.keywords = [];
        }else if(typeof(values.keywords) === "string"){
            let keywordsStr = values.keywords.trim();
            let keywordsLen = keywordsStr.length;
            if(keywordsLen <= 0){
                values.keywords = [];
            }else{
                values.keywords = values.keywords.split(',');
            }
        }
        this.state = {
            values: values,
            requestUserLocation: requestUserLocation,
            isLocation: isLocation,
            isScheduled: isScheduled,
            isRecommendation: isRecommendation,
            redirect: false,
            saving: false,
            contentLoading: false,
            photo: photo,
            validation: {
                title: '',
                keywords: '',
                affiliateUrl: '',
                isValid: false
            },
            cancelToken: null,
            platform: this.props.platform,
            tooltips: {
                editWebLink: false,
                url: false,
                title: false,
                photo: false,
                showPicture: false,
                keywords: false,
                publicDescription: false,
                location: false,
                topic: false,
                draft: false,
                featured: false,
                searchable: false,
                scheduled: false,
                contentPublishedDateTime: false,
                contentExpiredDateTime: false,
                affiliate: false,
                affiliateUrl: false,
                discountCode: false,
                purchaseInfo: false,
                sponsored: false, 
                privateNotes: false
            },
            tagInputValue: "",
            contentTypes: Api.getContentTypes(authorized, user),
            deleteMode: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleIsLocation = this.handleIsLocation.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSearchCheckbox = this.handleSearchCheckbox.bind(this)
        this.goBack = this.goBack.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.deleteCatalog = this.deleteCatalog.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
        this.onDeleteModalClose = this.onDeleteModalClose.bind(this);
    }
    componentDidMount(){
        if(!this.props.hasOwnProperty("content") || this.props.content === null){
            this.loadContent();
        }

        const { coverMediaId } = this.state.values;
        if(this.state.photo === null && typeof(coverMediaId) === "string" && coverMediaId.length > 0){
            this.getPhoto(coverMediaId);
        }

        // const editWizard = document.getElementById("editWebLinkWizard");
        // if(editWizard){
        //     setTimeout(function(){
        //         window.scrollTo(0,(editWizard.getBoundingClientRect().top-70));
        //     },300);
        // }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        const { values } = this.state;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(values[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "http://"+value;
            }
        }
        if(value === "http://" || value === "https://"){
            value = "";
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                [name]: value
            }
        });
    }
    handleChangePhoto(params){        
        if(params.file === null){
            this.deletePhoto();
            return;
        }
        
        this.setState({photo: params.file});
        // const that = this;
        // loadImage(params.file, img => {
        //     img.toBlob(blob => {
        //         const file = new File([blob], params.file.name, {type: params.file.type});
        //         that.setState({photo: file});
        //         //that.uploadPhoto(file);
        //     }, params.file.type);
        // }, {
        //     orientation: true
        // });
    }
    handleLocationChange(location){
        if(this.state.isLocation === false){
            return;
        }
        this.setState({
            values: {
                ...this.state.values,
                googlePlacesId: location.googlePlacesId,
                latitude: location.latitude,
                longitude: location.longitude,
                location: location.location,
                googlePlacesResponse: location
            }
        });
    };
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                values: {
                   ...this.state.values,
                   [name]: date ? parsedDate.toISOString() : ""
               }
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(event, name) {
        let featuredSequence = this.state.values.featuredSequence;
        if(name === "isFeatured"){
            featuredSequence = 100;
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                [name]: event.target.checked,
                featuredSequence: featuredSequence
            }
        });
    };
    handleSearchCheckbox(event , name) {
        let state = {
            values: {
                ...this.state.values,
                [name]: event.target.checked
            }
        }
        if(event.target.checked === false){
            state.values.contentPublishedDateTime = "";
            state.values.contentExpiredDateTime = "";  
        }
        this.setState(state);
    };
    handleIsLocation(event , name){
        let state = {
            [name]: event.target.checked
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                googlePlacesId: "",
                latitude: 0.0,
                longitude: 0.0,
                location: "",
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
            };  
            state['requestUserLocation'] = false ;
        }
        this.setState(state);
    }
    handleIsScheduled(event , name){
        let state = {
            [name]: event.target.checked
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                contentPublishedDateTime: "",
                contentExpiredDateTime: "",
            };  
        }
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.saving){
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            return;
        }
        this.setState({saving: true});
        const that = this;
        let values = Object.assign({}, this.state.values);
        let regularTagsStr = this.state.values.keywords.toString();
        values.keywords = regularTagsStr;
        if(values.sequenceDateTime === ""){
            values.sequenceDateTime = this.props.content.sequenceDateTime;
        }
        Api.updateContent(this.state.values.contentId, values).then((data) => {
            //Handle photo uploading
            if(that.state.photo !== null && typeof(this.state.photo) !== "string"){
                this.setState({
                    values: {
                        ...that.state.values,
                        sequenceDateTime: data.response[0].sequenceDateTime,
                    }
                });

                that.uploadPhoto(that.state.photo);
            }else{
                if(this.props.onEdit){
                    if(this.props.hardrefresh === true){
                        window.location.reload();
                    }
                    this.props.onEdit(true, this.state.values);
                    return;
                }
                this.setState({redirect: true, saving: false});
            }

        }).catch(err => {
            this.setState({...this.state, redirect: false, saving: false});
        });
    }
    deleteModal(){
        this.setState({deleteMode: true});
    }
    onDeleteModalClose(){
        this.setState({deleteMode: false});
    }
    deleteCatalog(){
        this.setState({
            deleteMode: false
        });
        Api.deleteContent(this.state.values.contentId).then((data) => {
            if(this.props.onDelete){
                this.props.onDelete();
            }else{
                this.setState({redirect: true, saving: false});
            }            
        }).catch(err => {
            console.log(err);
        });
    }
    loadContent(){
        const ApiUrl = Config.getApiUrl()+"data/content/"+this.state.values.contentId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        const that = this;
        this.setState({
            ...this.state,
            cancelToken: source,
            contentLoading: true
        });
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0){
                let response = res.data.response[0];
                if(!response.hasOwnProperty("googlePlacesResponse")){
                    response['googlePlacesResponse'] = {
                        latitude: response.hasOwnProperty("latitude")?response.latitude:0.0,
                        longitude: response.hasOwnProperty("longitude")?response.longitude:0.0,
                        location: response.hasOwnProperty("location")?response.location:"",
                        googlePlacesId: response.hasOwnProperty("googlePlacesId")?response.googlePlacesId:"",
                        formatted_address: "",
                        city: "",
                        state: "",
                        zipcode: "",
                        country: "",
                    };
                }
                
                const defaultValues = Object.assign({},that.state.values);
                response = Object.assign(defaultValues,response);
                if(!response.hasOwnProperty("showPicture")){
                    response['showPicture'] = true;
                }
                for(var attrib in response){
                    if(typeof(response[attrib]) === "string"){
                        response[attrib] = response[attrib].trim();
                    }
                }
                for(attrib in response.googlePlacesResponse){
                    if(typeof(response.googlePlacesResponse[attrib]) === "string"){
                        response.googlePlacesResponse[attrib] = response.googlePlacesResponse[attrib].trim();
                    }
                }
                // response.isDraft = !response.isDraft;
                response.isAffiliate = false;
                let state = {...that.state, values: response};
                let isLocation = false;
                let requestUserLocation = this.state.requestUserLocation;
                if(response.hasOwnProperty("location") && response.location.trim().length > 0){
                    isLocation = true;
                    requestUserLocation = false;
                }

                let isScheduled = false;
                if(response.hasOwnProperty("contentPublishedDateTime") && response.contentPublishedDateTime.length > 0){
                    isScheduled = true;
                }
                if(response.hasOwnProperty("contentExpiredDateTime") && response.contentExpiredDateTime.length > 0){
                    isScheduled = true;
                }
                let isRecommendation = false;
                if(response.hasOwnProperty("rating") && response.rating > 0){
                    isRecommendation = true;
                }

                if(!response.hasOwnProperty("keywords")){
                    response.keywords = [];
                }else{
                    let keywordsStr = response.keywords.trim();
                    let keywordsLen = keywordsStr.length;
                    if(keywordsLen <= 0){
                        response.keywords = [];
                    }else{
                        response.keywords = response.keywords.split(',');
                    }
                }
                if(response.hasOwnProperty("coverMedia")){
                    state = {...that.state, values: response, photo: response.coverMedia.downloadUrlProfile};
                }else if(response.coverMediaId.length > 0){
                    that.getPhoto(response.coverMediaId);
                }
                state['isLocation'] = isLocation;
                state['isScheduled'] = isScheduled;
                state['isRecommendation'] = isRecommendation;
                state['requestUserLocation'] = requestUserLocation;
                state['contentLoading'] = false;
                this.setState(state);
            }
        }).catch(err => {
            
        });
    }
    uploadPhoto(file){
        const that = this;
        const { contentId } = this.state.values;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        var requestData = {
            contentId: contentId,
            mediaType: file.type,
            isCover: true,
        };
        Api.createMedia(file, requestData, source, false).then((media) => {
            const values = {
                ...that.state.values,
                coverMediaId: media.mediaId,
                mediaProcessing: true
            };
            that.setState({
                cancelToken: null, 
                values: values,
            });
            let duplicateValues = Object.assign({}, this.state.values);
            let regularTagsStr = this.state.values.keywords.toString();
            duplicateValues.keywords = regularTagsStr;
            if(duplicateValues.url && duplicateValues.affiliateUrl && duplicateValues.url === duplicateValues.affiliateUrl){
                duplicateValues.affiliateUrl = "";
            }
            Api.updateContent(contentId, duplicateValues).then((data) => {
                Api.uploadMedia(file, media, source).then((data) => {
                    if(that.props.onEdit){
                        if(this.props.hardrefresh === true){
                            window.location.reload();
                        }
                        this.props.onEdit(true, values);
                        return;
                    }
                    that.setState({redirect: true, saving: false});
                });                
            }).catch(err => {
                this.setState({redirect: false, saving: false});
            });
            
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    deletePhoto(){
        if(this.state.values.coverMediaId.length <= 0){
            this.setState({photo: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, photo: null, saving: true});
        Api.deleteMedia(this.state.values.coverMediaId, source).then((data) => {
            that.setState({
                cancelToken: null, 
                values: {
                    ...that.state.values,
                    coverMediaId: ""
                },
                saving: false
            })
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    getPhoto(coverMediaId){
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, photo: null});
        Api.getMedia(coverMediaId, source).then((data) => {
            that.setState({
                cancelToken: null, 
                photo: data.downloadUrlProfile
            })
        }).catch(err => {
            
        });
    }
    onCancel(){
        if(this.props.onCancel !== null){
            this.props.onCancel();
            return;
        }
        this.goBack();
    }
    handleTags = regularTags => {
        let { keywords } = this.state.values;
        if(typeof(keywords) === "object" && regularTags.length > keywords.length){
            const lastIndex = regularTags[regularTags.length - 1];
            keywords = [lastIndex].concat(keywords);
        }else{
            keywords = regularTags;
        }
        this.setState({
            values: {
                ...this.state.values, 
                keywords: keywords,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let keywords = this.state.values.keywords;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                keywords: [tagValue, ...keywords],
            },
            tagInputValue: ""
        })
    }
    goBack(){
        if(this.props.onEdit){
            this.props.onEdit(true, this.state.values);
        }

        const { user } = this.store.getState();
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/profile/"+user.userId+"/catalog"} />
        }
    }
    validateForm(){
        let validation = {
            title: 'success',
            keywords: '',
            isValid: true
        };
        const { values } = this.state;
        // if(values.url.length <= 0){
        //     validation.url = "error";
        //     validation.isValid = false;
        // }
        if(values.title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        // if(values.hasPurchaseInfo){
        //     if(values.affiliateUrl.length <= 0){
        //         validation.affiliateUrl = "error";
        //         validation.isValid = false;
        //     }
        // }else{
        //     validation.affiliateUrl = "";
        // }
        this.setState({...this.state, validation: validation});
        return validation.isValid;
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    handleIsRecommendation(event , name){
        let state = {
            [name]: event.target.checked,
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                rating: 0,
            }
        }
        
        this.setState(state);
    }
    onRatingChange(event){
        let value = Number(event.target.value);
        this.setState({
            values: {
                ...this.state.values,
                rating: value
            }
        })
    }
    render(){
        if(this.state.redirect){
            this.goBack()
            return <></>;
        }
        const { authorized, topics, user } = this.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const { classes } = this.props;
        const { values, validation, photo, saving, contentLoading, isLocation, requestUserLocation, isScheduled, tooltips, isRecommendation, contentTypes, deleteMode } = this.state;
        
        let affiliateUrl = values.affiliateUrl;
        if(typeof(affiliateUrl) === "string" && affiliateUrl.length <= 0){
            affiliateUrl = "http://";
        }

        return (
            <Container className="catalog-edit-page-holder">
                <Grid item className={classes.container} xs={12} md={8} lg={8}>
                    <GridItem>
                        <h3 className={classes.title}>
                            Edit Web Link
                            {
                                user.userPreferences.showTips ?
                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('editWebLink', true)} />
                                :
                                <></>
                            }
                        </h3>
                    </GridItem>                
                    {
                        authorized && contentLoading === false ?
                            <form onSubmit={this.handleSubmit} noValidate>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.affiliateUrl === "success"}
                                        error={validation.affiliateUrl === "error"}
                                        id="outlined-affiliateUrl"
                                        labelText="Click-Through Url"                                    
                                        inputProps={{
                                            onChange: (e) => this.handleLinkChange(e, 'affiliateUrl'),
                                            value: values.affiliateUrl,
                                            name: "affiliateUrl"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl
                                        }}
                                        showInfoTooltip={user.userPreferences.showTips}
                                        onShowInfo={() => this.onTooltip('affiliateUrl', true)}
                                    />
                                    <p className={classes.linkPreview}>
                                        {affiliateUrl}
                                    </p>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.title === "success"}
                                        error={validation.title === "error"}
                                        id="outlined-title"
                                        labelText="Title"                                    
                                        inputProps={{
                                            value: values.title,
                                            required: true,
                                            onChange: (e) => this.handleChange(e, 'title'),
                                            name: "title",
                                            multiline: (values.title.length > 50 ? true: false),
                                            rows: (values.title.length > 50 ? (values.title.length > 120?3:2): 1)
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        showInfoTooltip={user.userPreferences.showTips}
                                        onShowInfo={() => this.onTooltip('title', true)}
                                    />                                
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.showPicture}
                                            onChange={ (e) => this.handleCheckbox(e, "showPicture")}
                                            value="showPicture"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label={values.showPicture ? "Include Picture with Search Result" : "No Picture"}
                                    />
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('showPicture', true)} />
                                        :
                                        <></>
                                    }
                                </GridItem>
                                {
                                    values.showPicture ?
                                        <GridItem xs={12} sm={8} md={8} lg={6} className={classes.imgGrid}>
                                            {/* {
                                                photo === null ? 
                                                <p className={classes.centerParagraph}>Do you want to add a photo?</p>
                                                : ""
                                            } */}
                                            <ImageUpload
                                                onChange={this.handleChangePhoto}
                                                file={photo}
                                                addButtonProps={{ round: true }}
                                                changeButtonProps={{ round: true }}
                                                removeButtonProps={{ round: true, color: "danger" }}
                                                showInfoTooltip={user.userPreferences.showTips}
                                                onShowInfo={() => this.onTooltip('photo', true)}
                                            />
                                        </GridItem>
                                    :
                                        <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        id="outlined-publicDescription"
                                        labelText="Public Description"                                    
                                        inputProps={{
                                            value: values.publicDescription,
                                            onChange: (e) => this.handleChange(e, 'publicDescription'),
                                            name: "publicDescription",
                                            multiline: true,
                                            rows: 3
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        showInfoTooltip={user.userPreferences.showTips}
                                        onShowInfo={() => this.onTooltip('publicDescription', true)}
                                    />                                
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={isLocation}
                                            onChange={ (e) => this.handleIsLocation(e, "isLocation")}
                                            value="isLocation"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Location"
                                    />
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('location', true)} />
                                        :
                                        <></>
                                    }
                                </GridItem>
                                
                                {
                                    <Grid>
                                        <GridItem xs={12} sm={8} md={8} lg={6} className={(isLocation !== true ? classes.hideLocation:"")+" "+classes.customGridItem  }>
                                            <ContentItemLocation onChange={this.handleLocationChange} requestUserLocation={requestUserLocation}  googlePlacesResponse={values.googlePlacesResponse} store={this.store} />
                                        </GridItem>
                                    </Grid>
                                }
                                {
                                    showBetaFeatures ?
                                        <>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={isRecommendation}
                                                        onChange={ (e) => this.handleIsRecommendation(e, "isRecommendation")}
                                                        value="isRecommendation"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                    }
                                                    classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                    }}
                                                    label="Recommendation"
                                                />
                                            </GridItem>
                                            {
                                                isRecommendation ?
                                                    <Rating onChange={this.onRatingChange} rating={values.rating} />
                                                :
                                                    <></>
                                            }
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <CustomSelect
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    selectProps={{
                                                        onChange: (e) => this.handleChange(e, 'topic'),
                                                        value: values.topic
                                                    }}
                                                    labelText={
                                                        <span>
                                                            Primary Topic
                                                        </span>
                                                    }
                                                    inputProps={{
                                                        name: "topic",
                                                        id: "outlined-topic",
                                                        className: classes.alignLeft
                                                    }}
                                                    items={topics}
                                                    itemLabel="topicName"
                                                    itemValue="topicName"
                                                />
                                            </GridItem>
                                        </>
                                    :
                                        <></>
                                }
                                {/* {
                                    showBetaFeatures ?
                                        <GridItem xs={12} sm={8} md={8} lg={6}>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={values.isDraft}
                                                    onChange={ (e) => this.handleCheckbox(e, "isDraft")}
                                                    value="isDraft"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label={(values.isDraft ? "Public": "Draft (Not available to public)")}
                                            />
                                            {
                                                user.userPreferences.showTips ?
                                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('draft', true)} />
                                                :
                                                <></>
                                            }
                                        </GridItem>
                                    :
                                    <></>
                                } */}
                                {/* <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isFeatured}
                                            onChange={ (e) => this.handleCheckbox(e, "isFeatured")}
                                            value="isFeatured"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label={values.isFeatured ? "Featured" : "Not Featured"}
                                    />
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('featured', true)} />
                                        :
                                        <></>
                                    }
                                </GridItem> */}
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isSearchable}
                                            onChange={ (e) => this.handleSearchCheckbox(e, "isSearchable")}
                                            value="isSearchable"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label={(values.isSearchable ? "Will appear in search results" : "Will not appear in search results")}
                                    />
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('searchable', true)} />
                                        :
                                        <></>
                                    }
                                </GridItem>
                                {
                                    values.isSearchable ?
                                        <GridItem xs={12} sm={8} md={8} lg={6}>
                                            <CustomTagsInput
                                                success={validation.keywords === "success"}
                                                error={validation.keywords === "error"}
                                                labelText= {
                                                    <span>
                                                        Search Key Words (What will people search to find this link?)
                                                    </span>
                                                }
                                                tagProps={{
                                                    value: values.keywords,
                                                    onChange: this.handleTags,
                                                }}
                                                inputProps={{
                                                    placeholder: "Add a search term",
                                                    onBlur: this.handleTagsChange,
                                                    onChange: this.handleTagInput,
                                                    value: this.state.tagInputValue,
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                showInfoTooltip={user.userPreferences.showTips}
                                                onShowInfo={() => this.onTooltip('keywords', true)}
                                                color={(user.userLevel >= 0 ? user.guestPreferences.primaryColor: "primary")}
                                            />                               
                                        </GridItem>
                                    :
                                        <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isAffiliate}
                                            onChange={ (e) => this.handleCheckbox(e, "isAffiliate")}
                                            value="isAffiliate"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Advanced Options"
                                    />
                                    {
                                        user.userPreferences.showTips ?
                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('affiliate', true)} />
                                        :
                                        <></>
                                    }
                                </GridItem>
                                {
                                    values.isAffiliate ?
                                    <>
                                        {
                                            showBetaFeatures ?
                                                <Grid>
                                                    <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem}>
                                                        <CustomSelectWithIcon 
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            selectProps={{
                                                                onChange: (e) => this.handleChange(e,"type"),
                                                                value: values.type
                                                            }}
                                                            labelText={"Content Type (Icon)"}
                                                            inputProps={{
                                                                name: "type",
                                                                id: "outlined-type",
                                                                className: classes.alignLeft
                                                            }}
                                                            items={contentTypes}
                                                            itemLabel="itemLabel"
                                                            itemValue="itemValue"
                                                        />
                                                    </GridItem>
                                                </Grid>
                                            :
                                                <></>
                                        }
                                        <Grid>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem}>
                                                <DateTimePicker
                                                    labelText="Published Date"
                                                    id="sequenceDateTime"
                                                    value={values.sequenceDateTime}
                                                    onChange={(date) => this.handleDateChange(date, 'sequenceDateTime')}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                        </Grid>
                                        {
                                            user.userLevel < 1 ?
                                                <GridItem className={classes.upgradePlan}>
                                                    Free accounts do not have access to these features -&nbsp;
                                                    <Link underline="always" to={"/dashboard/account/upgrade"}>
                                                        Upgrade Now!
                                                    </Link>
                                                </GridItem>
                                            :
                                                <></>
                                        }
                                        <Grid>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <CustomInput
                                                    id="outlined-discountCode"
                                                    labelText="Discount Code"                                    
                                                    inputProps={{
                                                        value: values.discountCode,
                                                        onChange: (e) => this.handleChange(e, 'discountCode'),
                                                        name: "discountCode",
                                                        disabled: user.userLevel < 1 ? true : false
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    showInfoTooltip={user.userPreferences.showTips}
                                                    onShowInfo={() => this.onTooltip('discountCode', true)}
                                                />                                
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={values.hasPurchaseInfo}
                                                        onChange={ (e) => this.handleCheckbox(e, "hasPurchaseInfo")}
                                                        value="hasPurchaseInfo"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                        disabled={user.userLevel < 1 ? true : false}
                                                    />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label={
                                                        <>
                                                            <ShoppingBagIcon className={classes.cartIcon+" MuiSvgIcon-root "+(values.hasPurchaseInfo?classes.cartIconActive:"")} />
                                                            {values.hasPurchaseInfo ? "Can be purchased at click-through link" : "Do not show shopping icon"}
                                                        </>
                                                    }
                                                />
                                                {
                                                    user.userPreferences.showTips ?
                                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('purchaseInfo', true)} />
                                                    :
                                                    <></>
                                                }
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={values.isSponsored}
                                                        onChange={ (e) => this.handleCheckbox(e, "isSponsored")}
                                                        value="isSponsored"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                        disabled={user.userLevel < 1 ? true : false}
                                                    />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label={values.isSponsored?"Disclose that I am being paid or sponsored for this search result":"I am not being paid or sponsored for this search result"}
                                                />
                                                {
                                                    user.userPreferences.showTips ?
                                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('sponsored', true)} />
                                                    :
                                                    <></>
                                                }
                                            </GridItem>
                                        <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={isScheduled}
                                                        onChange={ (e) => this.handleIsScheduled(e, "isScheduled")}
                                                        value="isScheduled"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                        disabled={user.userLevel < 1 ? true : false}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Scheduled"
                                            />
                                            {
                                                user.userPreferences.showTips ?
                                                    <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('scheduled', true)} />
                                                :
                                                <></>
                                            }
                                        </GridItem>
                                        {
                                            isScheduled ? 
                                            <div>
                                                <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem + ' ' + classes.marginTop + ' ' + classes.customInputLabel}>
                                                    <DateTimePicker
                                                        labelText={<p>Not Available Before  {user.userPreferences.showTips ?
                                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('contentPublishedDateTime', true)} />
                                                        : <></>}</p>}
                                                        id="contentPublishedDateTime"
                                                        value={values.contentPublishedDateTime}
                                                        onChange={(date) => this.handleDateChange(date, 'contentPublishedDateTime')}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem + ' ' + classes.marginTop + ' ' + classes.customInputLabel}>
                                                    <DateTimePicker
                                                        labelText={<p>Not Available After  {user.userPreferences.showTips ?
                                                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('contentExpiredDateTime', true)} />
                                                            : <></>}</p>}
                                                        id="contentExpiredDateTime"
                                                        value={values.contentExpiredDateTime}
                                                        onChange={(date) => this.handleDateChange(date, 'contentExpiredDateTime')}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />                            
                                                </GridItem>
                                                
                                            </div> : <></>
                                        }
                                        </Grid>
                                    </>
                                    : <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        id="outlined-privateNotes"
                                        labelText="Private Notes"                                    
                                        inputProps={{
                                            value: values.privateNotes,
                                            onChange: (e) => this.handleChange(e, 'privateNotes'),
                                            name: "privateNotes",
                                            multiline: true,
                                            rows: 3
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        showInfoTooltip={user.userPreferences.showTips}
                                        onShowInfo={() => this.onTooltip('privateNotes', true)}
                                    />                                
                                </GridItem>
                                {
                                    (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") ?
                                        <>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <CustomInput
                                                    id="outlined-lastIndexedDateTime"
                                                    labelText="Last Index Date"
                                                    inputProps={{
                                                        value: values.lastIndexedDateTime,
                                                        disabled: true
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />                                            
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <CustomInput
                                                    id="outlined-contentId"
                                                    labelText="Content Id"
                                                    inputProps={{
                                                        value: values.contentId,
                                                        disabled: true
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />                                            
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <CustomInput
                                                    id="outlined-ownerId"
                                                    labelText="Owner Id"
                                                    inputProps={{
                                                        value: values.ownerId,
                                                        disabled: true
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />                                            
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={values.isDeleted}
                                                        value="isDeleted"
                                                        disabled
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="Is Deleted"
                                                />
                                            </GridItem>
                                        </>
                                    :
                                    <></>
                                }
                                <GridItem className={classes.buttonsSection}>
                                    <Button type="button" color="white" onClick={() => this.onCancel()}>
                                        <ArrowBackIcon className={classes.arrow} /> Cancel
                                    </Button>
                                    <Button type="button" color="danger" onClick={() => this.deleteModal()} className={classes.buttonSpacing}>
                                        <Close className={classes.icons} /> Delete
                                    </Button>
                                    <Button type="submit" color="success" className={classes.buttonSpacing}>
                                        {
                                            saving === false ?
                                                <>
                                                    <SaveIcon className={classes.icons} /> Save
                                                </>
                                            :
                                                <LoaderComponent align="center" saving={true} color="white"/>
                                        }
                                    </Button>
                                </GridItem>
                            </form>
                        :
                            <LoaderComponent align="center" />
                    }
                </Grid>
                <ContentDeleteModal open={deleteMode} onClose={() => this.onDeleteModalClose()} onSuccess={() => this.deleteCatalog()} />
                {
                    tooltips.editWebLink ?
                        <EditWebLinkTooltipModal open={tooltips.editWebLink} store={this.store} onClose={() => this.onTooltip('editWebLink', false)} />
                    :
                    <></>
                }
                {
                    tooltips.url ?
                        <UrlTooltipModal open={tooltips.url} store={this.store} onClose={() => this.onTooltip('url', false)} />
                    :
                    <></>
                }
                {
                    tooltips.title ?
                        <TitleTooltipModal open={tooltips.title} store={this.store} onClose={() => this.onTooltip('title', false)} />
                    :
                    <></>
                }
                {
                    tooltips.photo ?
                        <PhotoTooltipModal open={tooltips.photo} store={this.store} onClose={() => this.onTooltip('photo', false)} />
                    :
                    <></>
                }
                {
                    tooltips.showPicture ?
                        <ShowPictureTooltipModal open={tooltips.showPicture} store={this.store} onClose={() => this.onTooltip('showPicture', false)} />
                    :
                    <></>
                }
                {
                    tooltips.keywords ?
                        <SearchKeyWordsTooltipModal open={tooltips.keywords} store={this.store} onClose={() => this.onTooltip('keywords', false)} />
                    :
                    <></>
                }
                {
                    tooltips.publicDescription ?
                        <PublicDescriptionTooltipModal open={tooltips.publicDescription} store={this.store} onClose={() => this.onTooltip('publicDescription', false)} />
                    :
                    <></>
                }
                {
                    tooltips.location ?
                        <LocationTooltipModal open={tooltips.location} store={this.store} onClose={() => this.onTooltip('location', false)} />
                    :
                    <></>
                }
                {
                    tooltips.topic ?
                        <TopicTooltipmodal open={tooltips.topic} store={this.store} onClose={() => this.onTooltip('topic', false)} />
                    :
                    <></>
                }
                {
                    tooltips.draft ?
                        <DraftTooltipModal open={tooltips.draft} store={this.store} onClose={() => this.onTooltip('draft', false)} />
                    :
                    <></>
                }
                {
                    tooltips.featured ?
                        <FeaturedTooltipModal open={tooltips.featured} store={this.store} onClose={() => this.onTooltip('featured', false)} />
                    :
                    <></>
                }
                {
                    tooltips.searchable ?
                        <SearchableTooltipmodal open={tooltips.searchable} store={this.store} onClose={() => this.onTooltip('searchable', false)} />
                    :
                    <></>
                }
                {
                    tooltips.scheduled ?
                        <ScheduledTooltipModal open={tooltips.scheduled} store={this.store} onClose={() => this.onTooltip('scheduled', false)} />
                    :
                    <></>
                }
                {
                    tooltips.contentPublishedDateTime ?
                        <ContentPublishedDateTimeTooltipModal open={tooltips.contentPublishedDateTime} store={this.store} onClose={() => this.onTooltip('contentPublishedDateTime', false)} />
                    :
                    <></>
                }
                {
                    tooltips.contentExpiredDateTime ?
                        <ContentExpiredDateTimeTooltipModal open={tooltips.contentExpiredDateTime} store={this.store} onClose={() => this.onTooltip('contentExpiredDateTime', false)} />
                    :
                    <></>
                }
                {
                    tooltips.affiliate ?
                        <AffiliateTooltipModal open={tooltips.affiliate} store={this.store} onClose={() => this.onTooltip('affiliate', false)} />
                    :
                    <></>
                }
                {
                    tooltips.affiliateUrl ?
                        <AffiliateUrlTooltipModal open={tooltips.affiliateUrl} store={this.store} onClose={() => this.onTooltip('affiliateUrl', false)} />
                    :
                    <></>
                }
                {
                    tooltips.discountCode ?
                        <DiscountCodeTooltipModal open={tooltips.discountCode} store={this.store} onClose={() => this.onTooltip('discountCode', false)} />
                    :
                    <></>
                }
                {
                    tooltips.purchaseInfo ?
                        <PurchaseInfoTooltipModal open={tooltips.purchaseInfo} store={this.store} onClose={() => this.onTooltip('purchaseInfo', false)} />
                    :
                    <></>
                }
                {
                    tooltips.sponsored ?
                        <SponsoredTooltipModal open={tooltips.sponsored} store={this.store} onClose={() => this.onTooltip('sponsored', false)} />
                    :
                    <></>
                }
                {
                    tooltips.privateNotes ?
                        <PrivateNotesTooltipModal open={tooltips.privateNotes} store={this.store} onClose={() => this.onTooltip('privateNotes', false)} />
                    :
                    <></>
                }
                <Helmet>
                    <title>Search My Social App Search Catalog Create</title>
                </Helmet>
            </Container>
        )
    }
}

LinkEdit.defaultProps = {
    content: null,
    onEdit: null,
    onContentCreated: null,
    onCancel: null,
    onDelete: null,
    onTooltip: null,
    hardrefresh: false,

}
LinkEdit.propTypes = {
    content: PropTypes.object,
    onDelete: PropTypes.func,
    onTooltip: PropTypes.func
}

export default withStyles(editPageStyle)(LinkEdit);