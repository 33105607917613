
import React from "react";
import { helper } from '../../../../../assets/js/utils/Element';
import Api from '../../../../../assets/js/utils/Api';
import axios from 'axios';
import PropTypes from "prop-types";
import Cookie from "../../../../../assets/js/utils/Cookie";

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../../../../components/Loader';
import CredentialsSuccessNotice from "../YoutubeLinkSteps/CredentialsSuccessNotice";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import EditNotice from "./EditNotice";
import youtubeStepsStyle from "../../../../../assets/jss/dashboard/platforms/youtubeStepsStyle.js";

class YoutubeLinkEditWizard extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);

        let saving = false;
        const credentialsMode = Cookie.read("platform_credentials");
        if(credentialsMode !== null && parseInt(credentialsMode) === 1){
            saving = true;
        }

        this.state = {
            saving: saving,
            cancelToken: null,
            successModal: false,
            user: null,
            youtubePlatform: this.props.youtubePlatform,
            platform: this.props.youtubePlatform,
            upgradeModal: false,
            loading: false
        }
    }
    componentDidMount(){
        const authCode = helper.getParam("code");
        if(authCode && authCode !== "null"){
            this.authorizePlatform(authCode);
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            youtubePlatform: props.youtubePlatform,
            // platform: props.platform,
        });
    }
    authorizePlatform(authCode){
        const { cancelToken } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        let platform = Object.assign({}, this.state.platform);
        platform.status = "Authorize Platform";
        platform.code = decodeURIComponent(authCode);

        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source, successModal: this.isCredentialsMode()});
        Api.updatePlatform(platform, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                if(this.isCredentialsMode()){
                    this.setState({platform: data.response[0], loading: false}, () => {
                        this.handleFinish();
                    });
                }else{
                    this.setState({platform: data.response[0], loading: false});
                }
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({loading: false});
            }
        });
    }
    handleFinish(allStates = null){
        let { platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelTitle")){
            platform.status = "Submit Platform";
        }
        if(allStates !== null){
            platform.allContentSearchable = allStates.robot.allContentSearchable;
            platform.attemptGeoTag = allStates.robot.attemptGeoTag;
        }
        
        if(platform.hasOwnProperty("error")){
            platform.error = false;
        }
        
        const { user } = this.store.getState();
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source, successModal: true});
        
        Api.updatePlatform(platform, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                user.contentPlatforms.map((platform, key) => {
                    if(platform.contentPlatformId === data.response[0].contentPlatformId){
                        user.contentPlatforms[key] = data.response[0];
                    }
                    return null;
                });
                this.setState({successModal: true, user: user, saving: false}); 
            }
        }).catch(err => {
            console.log(err);
        });    
    }
    onNoticeClose(){
        this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
        this.history.push("/dashboard/platforms");
        const { platform } = this.state;
        if(this.isCredentialsMode()){
            this.props.onWizardClose();
        }else{
            this.props.onWizardClose(platform);
        }
        Cookie.dispose("platform_id");
        Cookie.dispose("platform_credentials");
    }
    onWizardClose(){
        const { user } = this.store.getState();
        const { youtubePlatform, platform } = this.state;
        if(youtubePlatform !== null && platform !== null && platform.status !== youtubePlatform.status){
            const source = axios.CancelToken.source();
            youtubePlatform.status = "Reset Platform";
            this.setState({saving: true, cancelToken: source});
            Api.updatePlatform(youtubePlatform, source, user.userId).then((data) => { 
                if(data.numberOfResponses > 0){
                    this.props.onWizardClose(data.response[0]);
                    this.props.history.push("/dashboard/platforms");
                }
            }).catch(err => {
                console.log(err);
            });
        }else{
            this.props.onWizardClose(youtubePlatform);
            this.props.history.push("/dashboard/platforms");
        }
    }
    isCredentialsMode(){
        const credentialsMode = Cookie.read("platform_credentials");
        return (credentialsMode !== null && parseInt(credentialsMode) === 1);
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { saving, platform, loading, youtubePlatform, successModal, error } = this.state;
        if(!authorized){
            return <></>
        }

        if(successModal){
            if(this.isCredentialsMode()){
                return <CredentialsSuccessNotice store={this.store} onClose={this.onNoticeClose} open={successModal} error={error} saving={saving} />;
            }else{
                return <EditNotice store={this.store} onClose={this.onNoticeClose} open={successModal} error={error} saving={saving} />;
            }
            
        }

        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    {
                        saving ?
                            <LoaderComponent align="center" />
                        :
                        <>
                            <Button color="transparent" justIcon className={classes.closeButton} onClick={this.onWizardClose}>
                                <Close />
                            </Button>
                            <Wizard
                                validate
                                store={this.store}
                                steps={[
                                    { stepName: "Authorization", stepComponent: Step1, stepId: "account", props: {platform: platform, youtubePlatform: youtubePlatform, loading: loading} },
                                    { stepName: "Instructions", stepComponent: Step2, stepId: "robot", props: {platform: youtubePlatform} },
                                    { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                                ]}
                                title="Link Your YouTube Channel"
                                subtitle="We will automatically load your search engine!"
                                finishButtonText="Done!"
                                finishButtonClick={this.handleFinish}
                                color="primary"
                            />
                        </>
                    }
                    
                </GridItem>
            </GridContainer>
        );
    }
}

YoutubeLinkEditWizard.defaultProps = {
    onWizardClose: function(){ }
}

YoutubeLinkEditWizard.propTypes = {
    onWizardClose: PropTypes.func
}
export default withStyles(youtubeStepsStyle)(YoutubeLinkEditWizard);
