/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import TagsInput from "react-tagsinput";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialIcon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';

import tagsInputStyle from "../../../assets/jss/material-kit-pro-react/components/tagsInputStyle.jsx";

class CustomTagsInput extends React.Component {
    constructor(props){
        super(props);

        this.input = React.createRef();
        this.forceFocus = false;
        this.onChange = this.onChange.bind(this);
    }
    onChange(e){
        if(this.props.inputProps.onChange){
            this.props.inputProps.onChange(e);
        }
    }
    render(){
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            tagProps,
            showInfoTooltip,
            color
        } = this.props;

        var formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel
                    className={classes.labelRoot + " MuiInputLabel-shrink Mui-focused "}
                    htmlFor={id}
                    {...labelProps}
                    >
                    {labelText}
                    {
                        showInfoTooltip ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.props.onShowInfo} />
                        :
                        <></>
                    }
                    </InputLabel>
                ) : null}
                <TagsInput
                    {...tagProps}
                    className={"react-tagsinput "+classes.tagsInput+(tagProps.disabled?" react-tagsinput-disabled":"")}
                    inputProps={inputProps}
                    tagProps={{
                        className: "react-tagsinput-tag "+(color), 
                    }}
                />
                {
                    !tagProps.disabled ?
                        <p className={classes.tagsNote}>Press enter between search terms</p>
                    :
                        <></>
                }
            </FormControl>
        )
    }
}

CustomTagsInput.defaultProps = {
    loading: false,
    inputProps: {
        onChange: function(){ }
    },
    tagProps: {},
    showInfoTooltip: false,
    onShowInfo: function(){ },
    color: ""
}

CustomTagsInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    tagProps: PropTypes.object,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    color: PropTypes.string
};

export default withStyles(tagsInputStyle)(CustomTagsInput);
