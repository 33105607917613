import {
    title,
    whiteColor,
    primaryColor,
    hexToRgb,
    blackColor,
    grayColor
} from "../../../../assets/jss/material-kit-pro-react.jsx";

const buttonsViewStyle = theme => ({
    marginAuto: {
        margin: "0px auto"
    },
    gridContainer: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: "-4px",
            marginRight: "-4px"
        }
    },
    gridSpacing: {
        position: "relative",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: "6px",
        }
    },
    button: {
        width: "100%",
        marginLeft: "4px",
        marginRight: "4px"
    },
    gridItem: {
        margin: "2px auto",
        paddingLeft: "0px",
    },
    title: {
        ...title,
        fontSize: "20px",
        marginTop: "0px",
        marginBottom: theme.spacing(1),
        textAlign: "left",
        minHeight: "0px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
        },
    },
    hidePicture: {
        height: "auto !important",
        margin: "2px auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflow: "hidden",
        "& p": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "12px",
            paddingRight: "65px !important",
            paddingLeft: "65px !important",
            marginBottom: "0px",
            [theme.breakpoints.down("xs")]: {
                paddingRight: "45px !important",
                paddingLeft: "45px !important",
            }
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
    },
    buttonBase: {
        height: "100%",
        width: "100%"
    },
    buttonIcon: {
        "& p": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        },
        "&.rounded": {
            "& img": {
                borderRadius: "100%",
            },
            "& .loaderImage": {
                borderRadius: "100%",
            }
        },
        [theme.breakpoints.down('xs')]: {
            "& p": {
                paddingLeft: "45px",
                paddingRight: "45px",
            },
            "& img": {
                width: "36px",
                height: "36px",
                left: "5px"
            },
        }
    },
    options: {
        maxWidth: "35px",
        width: "100%",
        zIndex: 1,
        position: "absolute",
        top: "0px",
        bottom: "0px",
        right: "10px",
        height: "30px",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            right: "5px",
        }
    },
    optionsRow: {
        "& button": {
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb(whiteColor) +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb(whiteColor) +
                ", 0.2)"
        },
        [theme.breakpoints.down('xs')]: {
            "& button": {
                marginRight: "0px"
            },
        },
    },
    icon: {
        "&+button": {
            marginLeft: "5px"
        }
    },
    loaderImage: {
        maxWidth: "100%",
        backgroundColor: grayColor[23],
        display: "flex",
        alignItems: "center",
        position: "absolute",
        left: "10px",
        "& .sd_loader": {
            width: "50px !important",
            height: "50px !important",
            [theme.breakpoints.down('xs')]: {
                width: "36px !important",
                height: "36px !important",
            }
        },
        "& .MuiCircularProgress-root": {
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "20px !important",
                height: "20px !important",
            }
        }
    },
    dragHandlePadding: {
        paddingLeft: "20px"
    },
    dragContainer: {
        "& .smooth-dnd-container": {
            width: "100%",
            "& .smooth-dnd-draggable-wrapper": {
                overflow: "initial !important"
            },
            "& .drag-handle": {
                position: "absolute",
                top: "0px",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                color: theme.palette.custom.main,
                zIndex: "1",
                width: "20px",
                justifyContent: "center",
                cursor: "move",
                left: "0px"
            }
        }
    }
});

export default buttonsViewStyle;