
import React from "react";
import LoaderComponent from '../../../../components/Loader'
import Api from '../../../../../assets/js/utils/Api';
import Cookie from "../../../../../assets/js/utils/Cookie";
import axios from 'axios';
import PropTypes from "prop-types";

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import Notice from "./Notice";
import linktreeStepsStyle from "../../../../../assets/jss/dashboard/platforms/linktreeStepsStyle";

class LinktreeWizard extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);

        this.state = {
            saving: false,
            cancelToken: null,
            successModal: false,
            user: null,
            linktreeButtons: false
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleFinish(allStates){
        const linktreeButtons = allStates.robot.linktreeButtons;
        const values = {
            type: "linktree",
            linktreeUsername: allStates.account.username.replace(/\s/g, ""),
            linktreeButtons: linktreeButtons
        };
        
        const { user } = this.store.getState();
        const that = this;
        const source = axios.CancelToken.source(); 
        this.setState({saving: true, cancelToken: source, linktreeButtons: linktreeButtons});
        Api.createPlatform(values, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                const { user } = this.store.getState();
                if(!user.hasOwnProperty("contentPlatforms")){
                    user.contentPlatforms = [];
                }
                user.contentPlatforms.push(data.response[0]);
                that.setState({successModal: true, user: user});
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onNoticeClose(){
        this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
        const previewPageRedirect = Cookie.read("preview_redirect");
        if(previewPageRedirect === "1"){
            Cookie.dispose("preview_redirect");
            this.history.push("/dashboard/editmode/search");
        }else{
            this.history.push("/dashboard/platforms");
        }
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { successModal, linktreeButtons } = this.state;
        if(!authorized){
            return <></>
        }

        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    {
                        this.state.saving ?
                            <LoaderComponent align="center" />
                        :
                            <>
                                <Button color="transparent" justIcon className={classes.closeButton} onClick={this.props.onWizardClose}>
                                    <Close />
                                </Button>
                                <Wizard
                                    validate
                                    store={this.store}
                                    steps={[
                                        { stepName: "Account", stepComponent: Step1, stepId: "account" },
                                        { stepName: "Instructions", stepComponent: Step2, stepId: "robot" },
                                        { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                                    ]}
                                    title="Import Your Existing Linktr.ee Account"
                                    subtitle="We will import your existing linktr.ee links"
                                    finishButtonText="Done!"
                                    finishButtonClick={this.handleFinish}
                                />
                            </>
                    }                
                </GridItem>
                <Notice store={this.store} onClose={this.onNoticeClose} open={successModal} linktreeButtons={linktreeButtons} />
            </GridContainer>
        );
    }
}

LinktreeWizard.defaultProps = {
    onWizardClose: function(){ },
    onPlatformAdded: function() { }
}

LinktreeWizard.propTypes = {
    onWizardClose: PropTypes.func,
    onPlatformAdded: PropTypes.func
}
export default withStyles(linktreeStepsStyle)(LinktreeWizard);
