import React from 'react'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Redirect } from 'react-router-dom';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import PaymentIcon from "@material-ui/icons/Payment";
import Button from "../CustomButtons/Button.jsx";
import Danger from "../Typography/Danger.jsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MaterialIcon from "@mdi/react";
import { mdiLoading, mdiLockOutline } from '@mdi/js';
import InformationSafeTooltipModal from "../ToolTips/InformationSafeTooltipModal";

class StripeCard extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.stripe = this.props.stripe;
        this.submit = this.submit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
        this.onTooltip = this.onTooltip.bind(this);

        this.state = {
            error: false,
            errorMessage: "",
            loading: false,
            token: null,
            showAuthorize: true,
            tooltips: {
                informationSafe: false
            },
            userTitle: this.props.userTitle
        };
    }
    componentWillReceiveProps(props){
        if(props.authorize === true && props.loading === false && props.error === false){
            this.submit();
        }else if(props.userTitle !== this.state.userTitle){
            this.setState({
                userTitle: props.userTitle, 
                loading: props.loading, 
                errorMessage: props.errorMessage,
                error: props.error
            });
        }else{
            this.setState({
                loading: props.loading, 
                errorMessage: props.errorMessage, 
                error: props.error
            });
        }
    }
    getUserName(){
        const { userTitle } = this.state;
        if(userTitle !== null && userTitle.length > 0){
            return userTitle;
        }
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return "";
        }
        let userName = "";
        if(user.hasOwnProperty("firstName")){
            userName = user.firstName;
        }
        if(user.hasOwnProperty("lastName") && user.lastName.length > 0){
            userName += " "+user.lastName;
        }
        return userName;
    }
    submit() {
        const that = this;
        const { user } = this.store.getState();
        this.stripe.createToken({name: this.getUserName()}).then(res => {
            if(res.hasOwnProperty("error")){
                that.setState({
                    error: true,
                    loading: false,
                    errorMessage: res.error.message
                });
            }else if(res.hasOwnProperty("token")){
                const token = res.token;
                let blillingDetails = {
                    email: user.userEmail
                };
                const name = this.getUserName();
                if(name && name.length > 0){
                    blillingDetails['name'] = name;
                }
                that.stripe.createPaymentMethod("card", {
                    billing_details: blillingDetails
                }).then(data => {
                    that.setState({
                        error: false,
                        loading: false,
                        errorMessage: "",
                        token: token,
                        stripeData: data.paymentMethod,
                        showAuthorize: false
                    });
                    if(that.props.onChange){
                        that.props.onChange({
                            paymentToken: token.id,
                            paymentLast4Digits: token.card.last4,
                            paymentExpirationDate: token.card.exp_month+"/"+token.card.exp_year,
                            stripeData: data.paymentMethod,
                            hasError: false
                        });
                    }
                }).catch(err => {
                    let message = "Error creating payment method.";
                    if(typeof(err) === "object" && err.hasOwnProperty("message")){
                        message = err.message;
                    }
                    that.setState({
                        error: true,
                        loading: false,
                        errorMessage: message
                    });
                });
                                
            }else{
                that.setState({
                    error: true,
                    loading: false,
                    errorMessage: "Error validating card."
                });
            }
        }).catch(err => {
            that.setState({
                error: true,
                loading: false,
                errorMessage: err.message
            });
        });

        that.setState({
            error: true,
            loading: true,
        });
    }
    renderDebugInfo(){
        let debugInfo = [];
        const token = this.state.token;
        debugInfo.push(<li key="token">Card Token: {token.id}</li>);
        debugInfo.push(<li key="person">Person Token: {token.card.name}</li>);
        debugInfo.push(<li key="last4">Last 4 Digits of Card: {token.card.last4}</li>);
        debugInfo.push(<li key="expiration">Expiration Date of Card: {token.card.exp_month+"/"+token.card.exp_year}</li>);
        return debugInfo;
    }
    goBack(){
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/profile"} />
        }
    }
    onCardChange(){
        this.setState({showAuthorize: true});
        if(this.props.onCardChange){
            this.props.onCardChange();
        }
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    render(){
        const { classes, success, showPrivateIcon } = this.props;
        const { error, errorMessage, loading, token, showAuthorize, tooltips } = this.state;
        const cardError = error || this.props.error;
        const cardElement = classNames({
            [classes.cardElement]: true,
            [classes.cardError]: cardError,
            [classes.cardLoading]: loading,
        });
        classes.cardElement = cardElement;
        return (
            <div className="fs-block card_details">
                {
                    error ?
                        <Danger className={classes.error}>
                            {errorMessage}
                        </Danger>                        
                    :
                    <></>
                }
                <label className={classes.cardLabel}>
                    Card details
                    {
                        showPrivateIcon ?
                            <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                        :
                        <></>
                    }
                </label>
                <div className={classes.cardElementContainer}>
                    <CardElement className={classes.cardElement} onChange={this.onCardChange} />
                    
                    {loading ? (
                        <MaterialIcon path={mdiLoading} className={classes.feedback+" "+classes.animate+" MuiSvgIcon-root"} />
                    ) : cardError ? (
                        <Clear className={classes.feedback + " " + classes.labelRootError} />
                    ) : success ? (
                        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
                    ) : null}
                </div>
                <p className={"informationSafe "+classes.anchor} onClick={() => this.onTooltip('informationSafe', true)}>Your Information is Safe</p>
                {
                    this.props.renderCardOnly === false ?
                        <>
                            {
                                showAuthorize ?
                                    <>
                                        <Button color="primary" type="submit" round onClick={this.submit} className={classes.button}>
                                            <PaymentIcon className={classes.icons} />Authorize
                                        </Button>
                                        <p>Your credit card will not be charged yet</p>
                                    </>
                                :
                                <></>
                            }
                            {
                                loading ?
                                    <></>
                                :
                                <>
                                {
                                    (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") && token !== null ?
                                        <>
                                            <ul className={classes.debugInfo}>
                                                {this.renderDebugInfo()}
                                            </ul>
                                        </>
                                    :
                                    <></>
                                }
                                {
                                    token !== null && this.props.allowGoBack ?
                                        <Button color="primary" type="button" round onClick={this.goBack} className={classes.button}>
                                            <ArrowBackIcon className={classes.icons} />OK
                                        </Button>
                                    :
                                    <></>
                                }
                                </>
                            }
                        </>
                    :
                    <></>
                }
                {
                    tooltips.informationSafe ?
                        <InformationSafeTooltipModal open={tooltips.informationSafe} store={this.store} onClose={() => this.onTooltip('informationSafe', false)} />
                    :
                    <></>
                }
            </div>
        );
    }
}

StripeCard.defaultProps = {
    allowGoBack: true,
    error: false,
    success: false,
    renderCardOnly: false,
    authorize: false,
    onCardChange: function(){ },
    showPrivateIcon: false,
    userTitle: null,
    loading: false,
    errorMessage: ""
}
StripeCard.propTypes = {
    allowGoBack: PropTypes.bool,
    error: PropTypes.bool,
    success: PropTypes.bool,
    renderCardOnly: PropTypes.bool,
    authorize: PropTypes.bool,
    onCardChange: PropTypes.func,
    showPrivateIcon: PropTypes.bool,
    userTitle: PropTypes.string,
    loading: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default (injectStripe(StripeCard));