
const appStyle = theme => ({
    mainApp: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundImage: "none !important",
        "& + .user_cover_photo": {
            height: "100vh",
            width: "100vw",
            position: "fixed",
            left: "0px",
            right: "0px",
            bottom: "0px",
            top: "0px",
            objectFit: "cover"
        },
        "&:not(.layout_page_search)": {
            "& + .user_cover_photo": {
                display: "none !important"
            }
        },
        [theme.breakpoints.down("md")]: {
            paddingBottom: "50px",
            "&[class*='layout_page_dashboard']": {
                paddingBottom: "115px",
            }
        },
        "&[class*='layout_page_user_onboarding']": {
            paddingBottom: "0px",
            "& .custom_scroll_top": {
                display: "none"
            }
        },
        "&[class*='layout_page_signup'], &[class*='layout_page_login']": {
            paddingBottom: "0px",
            "& .custom_scroll_top": {
                display: "none"
            },
            "& footer": {
                display: "none"
            }
        },
        "&[class*='layout_page_search']": {
            background: "#E2E2E1",
            [theme.breakpoints.down("xs")]: {
                "& header.header_transparent .brand_name": {
                    fontSize: "14px"
                }
            }
        },
        "&.layout_page_home": {
            "& .brand_name": {
                display: "none",
            }
        },
        "&[class*='layout_page_dashboard_preview']": {
            "& .custom_scroll_top": {
                display: "none !important"
            }
        }
    }
});

export default appStyle;