import React from 'react'
import {Helmet} from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";

import enconsentPageStyle from "../../../assets/jss/help/enconsentStyle";

const Euconsent = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container+" "+classes.main}>
                <h5 className={classes.textCenter}>CONSENT TO THE TRANSFER OF PERSONAL DATA TO THE UNITED STATES</h5>
                <p><i>CloudPremise, LLC. d/b/aSmearch (“<b>CloudPremise</b>”) is headquartered in the United States of America and may use service providers that operate in the United States of America or in other countries.  In order to use features of the Services that involve importing your social media content from Third Party Services in the European Economic Area, United Kingdom, or Switzerland (“<b>European Area</b>”), your personal data must be transferred from such Third Party Services to the United States or America or other locations where privacy laws may not be as protective as those in your state, province, or country.  Please carefully review the consent form below before authorizing such transfers of your personal data.</i></p>
                <p>By ticking the below box “I AGREE”, I hereby consent to the transfer of my personal data as described in this consent form and I acknowledge and agree that:</p>
                <div className={classes.termsContentLists}>
                    <ul>
                        <li>
                            <p><u>Recipients of Personal Data</u>. If I choose to import social media content from Third Party Services located in the European Area to my User Account on the Platform, my personal data will be transferred out of the European Area from such Third Party Services to CloudPremise and its service providers located in the United States of America and in other countries outside of the European Area;</p>
                        </li>
                        <li>
                            <p><u>Purpose of Transfer</u>. The transfer of my personal data from such Third Party Services is necessary for using certain features of the Services involving processing of my social media content on the Platform;</p>
                        </li>
                        <li>
                            <p><u>Use of Personal Data</u>. CloudPremise and its service providers will receive and process my personal information in order to provide the Services and as otherwise described in the CloudPremise privacy policy, available at <Link href="https://searchmysocial.media/help/privacy">https://searchmysocial.media/help/privacy</Link>;</p>
                        </li>
                        <li>
                            <p><u>Type of Personal Data Transferred</u>. The categories of personal data that will be transferred include any personal data contained in my social media content, such as my user ID or social media handle, photographs of me, captions, comments, and other information;</p>
                        </li>
                        <li>
                            <p><u>Other Options or Alternatives</u>. There may be other products or services similar to the Services available in my location that would not require the transfer of my personal data as described in this consent form;</p>
                        </li>
                        <li>
                            <p><u>Security</u>. CloudPremise has put in place administrative, technical, organizational, and physical safeguards designed to protect my personal data, but security risk is inherent in all Internet and information technologies and CloudPremise cannot guarantee complete security of my personal data.  My personal data will be transferred to United States of America and other countries that may not provide safeguards for my personal data deemed “adequate” by a regulator in the European Area;</p>
                        </li>
                        <li>
                            <p><u>Withdrawal of Consent</u>. I may withdraw my consent to the transfer of my personal data as described in this consent form at any time by emailing datasecurity@smearch.com.  I understand that if I withdrawal my consent it may make certain features of the Services inoperable or unavailable; and</p>
                        </li>
                        <li>
                            <p><u>Questions</u>. I may direct questions or concerns regarding the transfer or other processing of my personal data as described in this consent form to CloudPremise LLC d/b/a Smearch, Attn: Smearch Privacy Agent, 1800 Wazee St, 3rd Floor, Denver, CO 80202, or <Link href="mailto:datasecurity@smearch.com">datasecurity@smearch.com</Link>.</p>
                        </li>
                    </ul>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(enconsentPageStyle)(Euconsent);
