import React from "react";
import LoaderComponent from '../../../../components/Loader'
import PropTypes from "prop-types";
import { createBrowserHistory } from 'history';
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PlacesIcon from '@material-ui/icons/LocationOn';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { ReactComponent as ShoppingBagIcon } from "../../../../../assets/icons/shopping_bag.svg";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import MaterialIcon from "@mdi/react";
import { mdiFoodVariant, mdiPodcast } from '@mdi/js';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import LinkCreate from "../contents/types/LinkCreate";
import RecommendationCreate from "../contents/types/RecommendationCreate";
import ButtonCreate from "../contents/types/ButtonCreate";
import PodcastCreate from "../contents/types/PodcastCreate";

import searchableContentStyle from "../../../../../assets/jss/dashboard/platforms/SearchableContentStyle.js";
const history = createBrowserHistory();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

class ContentTypes extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            platform: this.props.platform,
            addContent: (this.props.type !== null),
            contentId: null,
            type: this.props.type,
        }

        this.addNewContent = this.addNewContent.bind(this);
        this.openForm = this.openForm.bind(this);
        this.onFormClose = this.onFormClose.bind(this);
        this.onContentCreated = this.onContentCreated.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            platform: props.platform,
        })
    }
    componentDidMount(){
        const { match } = this.props;
        let hasContentType = match.params.hasOwnProperty("contentType");
        if(hasContentType){
            let contentType = match.params.contentType;
            this.openForm(contentType);
        }

    }
    onContentCreated(contentId){
        this.setState({contentId: contentId});
    }
    addNewContent(event, type = null){
        event.preventDefault();
        this.openForm(type);
    }
    openForm(type){
        if(type === "link" || type === "recommendation" || type === "podcast"){
            this.setState({
                addContent: true,
                type: type
            });
        }

        if(type === "link"){
            history.push("/dashboard/platform/"+this.state.platform.contentPlatformId+"/searchable/link");
        }else if(type === "recommendation"){
            history.push("/dashboard/platform/"+this.state.platform.contentPlatformId+"/searchable/recommendation");
        }else if(type === "podcast"){
            history.push("/dashboard/platform/"+this.state.platform.contentPlatformId+"/searchable/podcast");
        }else{
            history.push("/dashboard/platformwizard/searchable");
        }
    }
    onFormClose(){
        const { platform } = this.state;
        this.setState({
            addContent: false,
            type: null
        });
        
        if(platform !== null && this.props.type !== null){
            this.props.onWizardClose(null, null, false);
        }else{
            history.push("/dashboard/platformwizard/searchable");
        }
    }
    render() {
        const { authorized } = this.props.store.getState();        
        const { classes, paymentData, subscription } = this.props;
        const { type } = this.state;
        if(!authorized){
            return <></>
        }

        if(this.state.addContent){
            return (
                <Dialog
                    classes={{
                        root: classes.modalRoot+" "+classes.modalRootCustom,
                        paper: classes.modal + " " + classes.modalWebLink+" "+classes.customModalWebLink,
                        scrollPaper: classes.paperScrollPaperCustom,
                    }}
                    open={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.props.onWizardClose(null, null, false)}
                    aria-labelledby="weblink-edit-modal-slide-title"
                    aria-describedby="weblink-edit-modal-slide-description"
                >
                    <DialogContent
                        id="weblink-edit-modal-slide-description"
                        className={classes.modalBody+" "+classes.modalCustomBody}
                    >
                        <div className={classes.wizard} id="createWebLinkWizard">
                            <Button color="transparent" justIcon className={classes.formCloseButton} onClick={() => this.onFormClose()}>
                                <Close />
                            </Button>
                            {
                                type === "link" || type === "webLink" ?
                                    <LinkCreate 
                                        store={this.store} 
                                        onCreate={(platform, content, status) => this.props.onWizardClose(this.state.platform, content, true)}
                                        onCancel={() => this.onFormClose()}
                                        onContentCreated={this.onContentCreated}
                                        contentPlatformId={this.state.platform.contentPlatformId}
                                        subscription={subscription} 
                                        paymentData={paymentData}
                                        history={this.props.history}
                                        searchPreview={this.props.searchPreview}
                                    />
                                : type === "button" ?
                                    <ButtonCreate 
                                        store={this.store} 
                                        onCreate={(platform, content, status) => this.props.onWizardClose(this.state.platform, content, true)}
                                        onCancel={() => this.onFormClose()}
                                        onContentCreated={this.onContentCreated}
                                        contentPlatformId={this.state.platform.contentPlatformId}
                                        subscription={subscription} 
                                        paymentData={paymentData}
                                        history={this.props.history}
                                        searchPreview={this.props.searchPreview}
                                    />
                                : type === "recommendation" ?
                                    <RecommendationCreate 
                                        store={this.store} 
                                        onCreate={(platform, content, status) => this.props.onWizardClose(this.state.platform, content, true)}
                                        onCancel={() => this.onFormClose()}
                                        onContentCreated={this.onContentCreated}
                                        contentPlatformId={this.state.platform.contentPlatformId}
                                        subscription={subscription} 
                                        paymentData={paymentData}
                                        history={this.props.history}
                                        searchPreview={this.props.searchPreview}
                                    />
                                : type === "podcast" ?
                                    <PodcastCreate 
                                        store={this.store} 
                                        onCreate={(platform, content, status) => this.props.onWizardClose(this.state.platform, content, true)}
                                        onCancel={() => this.onFormClose()}
                                        onContentCreated={this.onContentCreated}
                                        contentPlatformId={this.state.platform.contentPlatformId}
                                        subscription={subscription} 
                                        paymentData={paymentData}
                                        history={this.props.history}
                                        searchPreview={this.props.searchPreview}
                                    />
                                :
                                    <></>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            )
        }
        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    {
                        this.state.platform === null ?
                            <LoaderComponent align="center" />
                        :
                            <>
                                {
                                    this.state.addContent === false ?
                                        <>
                                            <Button color="transparent" justIcon className={classes.closeButton} onClick={() => this.props.onWizardClose(this.state.platform)}>
                                                <Close />
                                            </Button>
                                            <div className={classes.mainContainer}>
                                                <h3 className={classes.title}>
                                                    What are you adding?
                                                </h3>
                                                <Grid className={classes.grid}>
                                                    <GridItem xs={12} sm={10} md={8} lg={6} className={classes.gridItem}>
                                                        <GridContainer className={classes.gridContainer}>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={(event) => this.addNewContent(event)}>
                                                                    <div className={classes.icons}><PlacesIcon className={classes.icon} /></div><span>Places</span>
                                                                </Link>
                                                            </GridItem>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={(event) => this.addNewContent(event, "link")}>
                                                                    <div className={classes.icons}><i className={classes.icon+" fa fa-link"} /></div><span>Link</span>
                                                                </Link>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridContainer className={classes.gridContainer}>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={(event) => this.addNewContent(event, "recommendation")}>
                                                                    <div className={classes.icons}><StarHalfIcon className={classes.icon} /></div><span>Recommendation</span>
                                                                </Link>
                                                            </GridItem>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={this.addNewContent}>
                                                                    <div className={classes.icons}><ShoppingBagIcon className={classes.icon} /></div><span>Shopping Item</span>
                                                                </Link>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridContainer className={classes.gridContainer}>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={this.addNewContent}>
                                                                    <div className={classes.icons}><MaterialIcon path={mdiFoodVariant} className={classes.icon}/></div><span>Recipe</span> 
                                                                </Link>
                                                            </GridItem>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"}>
                                                                    <div className={classes.icons}><CameraAltOutlinedIcon className={classes.icon} /></div><span>Photo</span> 
                                                                </Link>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridContainer className={classes.gridContainer}>
                                                            <GridItem xs={6} >
                                                                <Link to={"/"} onClick={(event) => this.addNewContent(event, "podcast")}>
                                                                    <div className={classes.icons}><MaterialIcon path={mdiPodcast} className={classes.icon}/></div><span>Podcast</span> 
                                                                </Link>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>
                                                </Grid>
                                            </div>
                                        </>
                                    :
                                        <></>
                                }
                            </>
                    }                
                </GridItem>
            </GridContainer>
        );
    }
}

ContentTypes.defaultProps = {
    onWizardClose: function(){ },
    type: null,
    searchPreview: false
}

ContentTypes.propTypes = {
    onWizardClose: PropTypes.func,
    type: PropTypes.string,
    searchPreview: PropTypes.bool
}
export default withStyles(searchableContentStyle)(ContentTypes);
