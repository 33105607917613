import React from 'react'
import Config from "../../../../Config";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Instruction from "../Instruction/Instruction.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import toolTipsModalStyle from "../../../assets/jss/tooltips/toolTipsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const InformationSafeTooltipModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }    
    render() {
        const { classes } = this.props;
        const cdnUrl = Config.getCdnUrl();
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="informationSafe-modal-slide-title"
                aria-describedby="informationSafe-modal-slide-description"
            >
                <DialogTitle
                    id="informationSafe-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Your Information is Safe</h4>                            
                </DialogTitle>
                <DialogContent
                    id="informationSafe-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <Instruction
                        title="We partner with Stripe"
                        text={
                            <span>
                                We use a third party payment payment processeor named <a href="https://stripe.com/" rel="noopener noreferrer" target="_blank">Stripe</a> to handle all of the financial data in our system.  Your credit card information is sent directly from your browser to Stripe, who then sends us an encrypted token that we use in our system.
                            </span>
                        }
                        image={cdnUrl+"/app/1200px-Stripe_Logo.png"}
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        title="Data Privacy is our top concern"
                        text={
                            <span>
                                We don't trust anyone either!  That is why we built this Company and website from the ground-up to be safe and secure.  Find out more about our practices <a href="/help/privacy" target="_blank">here!</a>
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />                    
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <div>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="info"
                            round
                        >
                            Sounds Good
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

InformationSafeTooltipModal.defaultProps = {
    open: false
}
InformationSafeTooltipModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(toolTipsModalStyle)(InformationSafeTooltipModal);