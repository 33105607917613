import {
    blackColor,
    container,
    title,
    infoColor,
    whiteColor,
    grayColor,
    roseColor,
    successColor
} from "../../material-kit-pro-react.jsx";

const userAboutStyle = theme => ({
    container: {
        ...container,
        padding: theme.spacing(2),
        textAlign: "center",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "0px",
        marginBottom: "0px",
        minHeight: "32px",
        textDecoration: "none"
    },
    darkTitle: {
        color: grayColor[6]+" !important"
    },
    buttonSpacing: {
        marginRight: "-2px",
    },
    gridContainerSpacing: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
        border: "1px dashed "+grayColor[1],
        padding: theme.spacing(2),
        cursor: "pointer",
        color: grayColor[1],
        marginLeft: "-15px",
        marginRight: "-15px",
        [theme.breakpoints.up("md")]: {
            maxWidth: "600px",
            marginLeft: "auto",
            marginRight: "auto"
        },
        "& p": {
            marginBottom: "0px"
        }
    },
    userAvatarContainer: {
        maxWidth: "none",
        flexBasis: "auto",
        paddingRight: "0px",
        paddingLeft: "0px",
        margin: "0px auto"
    },
    userAvatar: {
        maxWidth: "100px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "60px",
        }
    },
    iconButton: {
        color: blackColor,
        marginLeft: "5px",
        "& i": {
            fontSize: "30px !important",
        }
    },
    about: {
        textAlign: "center",
        display: "block"
    },
    socialIconsContainer: {
        "& ul": {
            padding: "0px",
            marginBottom: "0px"
        },
        "& li": {
            display: "inline-block",
            height: "51px",
            width: "51px",
            verticalAlign: "middle",
            "& svg": {
                width: "41px",
                height: "41px",
                fontSize: "20px",
                minWidth: "41px",
                margin: ".3125rem 1px",
            },
            "&:hover svg": {
                boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
            }
        },
    },
    blogLink: {
        display: "block",
    },
    topSearchTerms: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        "& label": {
            display: "block",
            width: "100%",
            textAlign: "center"
        },
        "& .react-tagsinput": {
            paddingLeft: "0px",
            borderBottomColor: "transparent",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px !important",
            width: "100%",
            textAlign: "center",
            "& .react-tagsinput-tag": {
                margin: "3px 3px 3px 0px",
                paddingTop: "5px",
                paddingBottom: "5px",
                "&:hover": {
                    paddingRight: "8px"
                }
            }
        }
    },
    topSearchTermsLabel: {
        color: infoColor[0],
        cursor: "pointer"
    },
    dark: {
        "& $title, p, $topSearchTermsLabel": {
            color: whiteColor
        },
        "& $gridContainerSpacing": {
            color: whiteColor,
            border: "1px dashed "+grayColor[2],
            "& input, & textarea": {
                color: whiteColor
            },
            "& svg:last-child": {
                fill: whiteColor
            },
            "& label": {
                color: whiteColor
            }
        }
    },
    iconsContainer: {
        textAlign: "right",
        width: "100%",
        "& button": {
            marginLeft: "5px"
        }
    },
    socialGridItem: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    responsiveIconsGrid: {
        paddingRight: "0px",
        flexBasis: "calc( 100% - 45px)",
        maxWidth: "none",
        flexGrow: 0,
    },
    socialLinkPreview: {
        fontSize: "12px",
        width: "100%",
        textAlign: "left"
    },
    socialLinksContent: {
        "& [class*='MuiGrid-container']": {
            marginLeft: "0px",
            marginRight: "0px"
        }
    },
    customIcon: {
        width: "41px",
        height: "41px",
        fontSize: "20px",
        minWidth: "41px",
        margin: ".3125rem 1px",
        verticalAlign: "bottom",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
        }
    },
    addSocialIcon: {
        "& >div": {
            marginRight: "0px !Important",
        },
        "& *, & *:before, & *:after": {
            border: "none !important"
        },
        "& label": {
            transform: "none !Important",
            fontWeight: "bold",
            color: theme.palette.custom.main+" !important",
            textDecoration: "underline"
        },
        "& svg": {
            display: "none"
        },
        "& [class*='MuiInputBase-root']": {
            marginTop: "0px",
            zIndex: "1"
        }
    },
    sortableList: {
        paddingLeft: "0px",
        "& li": {
            listStyleType: "none",
            paddingLeft: "30px",
        }
    },
    sortableListEnabled: {
        "& li > span": {
            height: "100px",
            cursor: "move"
        }
    },
    sortIcon: {
        position: "absolute",
        left: "0px",
        height: "65px",
        display: "flex",
        alignItems: "center",
        paddingRight: "5px"
    },
    searchTermsContainer: {
        width: "100%",
        textAlign: "left",
        "& div.react-tagsinput": {
            "& .react-tagsinput-tag": {
                display: "none !important"
            },
            "& input": {
                width: "100%"
            }
        }
    },
    searchTerms: {
        listStyleType: "none",
        "& li": {
            display: "inline-block"
        },
        "& .gray": {
            backgroundColor: grayColor[26],
        },
        "& .customInfo": {
            backgroundColor: infoColor[8],
        },
        "& .customRose": {
            backgroundColor: roseColor[5],
        },
        "& .customSuccess": {
            backgroundColor: successColor[8],
        }
    },
});

export default userAboutStyle;