import {
    container,
    main,
} from "../material-kit-pro-react.jsx";

const enconsentPageStyle = theme => ({
    main,
    container: {
        ...container,
        padding: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginRight: "15px",
            marginLeft: "15px",
            maxWidth: "100%",
            width: "auto"
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "calc(100% - 20px)"
        },
        "& h5": {
            fontWeight: "bold"
        },
    },
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    textCenter: {
        textAlign: "Center"
    },
    italic: {
        fontStyle: "italic"
    },
    marginLeft: {
        paddingLeft: "0.7em !important",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.1em !important"
        },
        "& >li ": {
            "& p": {
                paddingLeft: "1.1em !important"
            }
        }
    },
    termsContentLists: {
        "& p": {
            display: "inline-block",
            verticalAlign: "top",
            lineHeight: "1.8em",
            paddingLeft: "0.5em",
            wordBreak: "break-word"
        },
        "& ul": {
            listStyleType: "upper-roman",
            "& >li": {
                margin: "0px",
                padding: "0px",
                fontSize: "14px",
            }
        },
    }
});

export default enconsentPageStyle;