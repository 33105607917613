import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import Api from '../../../../assets/js/utils/Api';
import { helper } from "../../../../assets/js/utils/Element";
import LoaderComponent from '../../../components/Loader';
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../../components/Grid/GridItem';
import FormControl from '@material-ui/core/FormControl';
import Button from "../../../components/CustomButtons/Button.jsx";
import ImageUpload from "../../../components/CustomUpload/ImageUpload.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import loadImage from "blueimp-load-image";
import Link from "@material-ui/core/Link";
import ChangeUserLevelModal from "../../../components/Dashboard/ChangeUserLevelModal";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import paintIcon from "../../../../assets/img/paint-icon.png";
import christian from "../../../../assets/img/default-avatar.png";
import CustomColorPicker from "../../../components/CustomInput/CustomColorPicker.jsx";

//Tooltip modals
import PrimaryColorTooltipModal from "../../../components/ToolTips/Dashboard/ProfileTheme/PrimaryColorTooltipModal";
import FontFamilyTooltipModal from "../../../components/ToolTips/Dashboard/ProfileTheme/FontFamilyTooltipModal";
import CoverPhotoTooltipModal from "../../../components/ToolTips/Dashboard/EditProfile/CoverPhotoTooltipModal";


import MaterialIcon from "@mdi/react";
import { primaryColor as defaultPrimaryColor } from "../../../../assets/jss/material-kit-pro-react.jsx";
import { mdiInformationOutline } from '@mdi/js';

import {
    primaryColor,
    grayColor,
    infoColor,
    roseColor,
    successColor
} from "../../../../assets/jss/material-kit-pro-react.jsx";
import dashboardTabProfileThemeStyle from "../../../../assets/jss/dashboard/profile/dashboardTabProfileThemeStyle.js";

const DashboardTabProfileTheme = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);

        let coverPhoto = null;
        let coverMediaId = null;
        if(values.hasOwnProperty("coverMedia")){
            coverPhoto = values.coverMedia.downloadUrlProfile;
            coverMediaId = values.coverMedia.mediaId;
        }
        let isCustomColor = helper.isCustomColor(values.guestPreferences.primaryColor);
        let customColor = defaultPrimaryColor[0];
        if(isCustomColor){
            customColor = values.guestPreferences.primaryColor;
        }
        this.state = {
            values: values,
            cancelToken: null,
            fonts: Api.getFonts(),
            saving: false,
            coverPhoto: coverPhoto,
            coverPhotoDataUrl: null,
            coverMediaId: coverMediaId,
            tooltips: {
                primaryColor: false,
                fontFamily: false,
                coverPhoto: false,
            },
            editInfo: false,
            savingInfo: false,
            changeUserLevel: false,
            paymentData: this.props.paymentData,
            subscription: this.props.subscription,
            openColorPicker: false,
            anchorEl: null,
            customColor: customColor
        };

        this.coverPhotoObjectUrl = null;

        this.handleGuestPrefChange = this.handleGuestPrefChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTooltip = this.onTooltip.bind(this);
        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.editUserInfo = this.editUserInfo.bind(this);this.upgradeUserLevel = this.upgradeUserLevel.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription
        })
    }
    handleGuestPrefChange(event, name, value = null, customColor = false) {
        if(!this.state.editInfo){
            return;
        }
        if(value === null){
            value = event.target.value;
        }
        let state = {
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    [name]: value
                }                
            }
        };
        if(customColor){
            state['customColor'] = value;
        }
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.values);
        this.setState({saving: true, editInfo: false});
        if(user.userPreferences.hasOwnProperty("onboarding")){
            values.userPreferences.onboarding.step8Complete = true;
        }
        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                let newUser = Api.prepareMemberObject(data.response[0]);
                this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser, redirect: true }});
                this.history.push("/dashboard/profile/styling");
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    handleChangePhoto(type, params){
        if(params.file === null){
            this.deletePhoto(type);
            return;
        }

        const that = this;
        loadImage(params.file, img => {
            img.toBlob(blob => {
                this.coverPhotoObjectUrl = null;
                const file = new File([blob], params.file.name, {type: params.file.type});
                if(type === "avatarPhoto"){
                    that.setState({avatarPhoto: file, savingAvatarPhoto: true});
                }else{
                    that.setState({coverPhoto: file, savingCoverPhoto: true});
                }
                that.uploadPhoto(file, type);
            }, params.file.type);
        }, {
            orientation: true
        });
    }
    uploadPhoto(file, type){
        const { user } = this.props.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source});
        var requestData = {
            userId: user.userId,
            mediaType: file.type,
        };
        if(type === "avatarPhoto"){
            requestData['isAvatar'] = true;
        }else{
            requestData['isCover'] = true;
        }
        Api.createMedia(file, requestData, source).then((data) => {
            let newState = {
                cancelToken: null, 
                savingAvatarPhoto: false,
                savingCoverPhoto: false
            };
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = data.mediaId;
            }else{
                newState['coverMediaId'] = data.mediaId;
            }
            that.setState(newState);
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    deletePhoto(type){
        let mediaIdKey = "avatarMediaId";
        if(type === "coverPhoto"){
            mediaIdKey = "coverMediaId";
        }
        const mediaId = this.state[mediaIdKey];
        if(!mediaId || mediaId.length <= 0){
            if(type === "avatarPhoto"){
                this.setState({avatarPhoto: null});
                return;
            }
            this.setState({coverPhoto: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        if(type === "coverPhoto"){
            this.setState({cancelToken: source, coverPhoto: null, saving: true});
        }else{
            this.setState({cancelToken: source, avatarPhoto: null, saving: true});
        }
        
        Api.deleteMedia(mediaId, source).then((data) => {
            const { values } = this.state;
            let newState = {};
            if(type === "avatarPhoto"){
                newState['avatarMediaId'] = null;
                delete values['avatarMediaId'];
            }else{
                newState['coverMediaId'] = null;
                delete values['coverMediaId'];
            }
            newState['values'] = values;
            that.setState(newState, () => {
                Api.updateUser(values.userId, values, source).then((data) => {
                    if(data.numberOfResponses > 0){
                        let newUser = Api.prepareMemberObject(data.response[0]);
                        this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser, redirect: true }});
                        this.history.push("/dashboard/profile/styling");
                    }
                }).catch(err => {
                    console.log(err);
                });
            });
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    editUserInfo(editInfo = true){
        let state = {editInfo: editInfo};
        if(!editInfo){
            const { user } = this.store.getState();
            state['values'] = Api.prepareMemberObject(user);
        }
        this.setState(state);
    }
    upgradeUserLevel(e){
        e.preventDefault();
        this.changeUserLevel();
    }
    changeUserLevel(changeUserLevel = true){
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    colorPicker(e){
        if(!this.state.editInfo){
            return;
        }
        this.setState({
            openColorPicker: true,
            anchorEl: e.currentTarget,
            values: {
                ...this.state.values,
                guestPreferences: {
                    ...this.state.values.guestPreferences,
                    primaryColor: this.state.customColor
                }                
            }
        });
    }
    getPrimaryColor(){
        let color = primaryColor[0];
        const { values } = this.state;
        const { user } = this.store.getState();
        if(user.userLevel > 0){
            color = primaryColor[0];
            let isCustomColor = helper.isCustomColor(values.guestPreferences.primaryColor);
            if(isCustomColor){
                color = values.guestPreferences.primaryColor;
            }else{
                switch(values.guestPreferences.primaryColor){
                    case 'gray':
                        color = grayColor[26];
                        break;
                    case 'customInfo':
                        color = infoColor[8];
                        break;
                    case 'customRose':
                        color = roseColor[5];
                        break;
                    case 'customSuccess':
                        color = successColor[8];
                        break;
                    default:
                        color = primaryColor[0];
                        break;
                }
            }
        }
        return color;
    }
    renderLayoutPreview(layoutMode){
        const { classes } = this.props;
        const { values } = this.state;
        let coverPhoto = this.state.coverPhoto;
        const { fontFamily, theme, layoutTransparency, buttonStyle } = values.guestPreferences;
        let transparencyStyle = {backgroundColor: "rgb(255, 255, 255, "+(1-(layoutTransparency/100))+")"};
        const primaryColor = this.getPrimaryColor();
        if(layoutMode === "dark"){
            transparencyStyle = {backgroundColor: "rgb(102, 102, 102, "+(1-(layoutTransparency/100))+")"};
        }
        let backgroundStyle = {
            backgroundSize: "cover",
            backgroundPosition: "center",
        }
        if(coverPhoto){
            if(typeof(coverPhoto) === "object"){
                if(this.coverPhotoObjectUrl === null){
                    this.coverPhotoObjectUrl = URL.createObjectURL(coverPhoto);
                }else{
                    coverPhoto = this.coverPhotoObjectUrl;
                }
            }
            backgroundStyle.backgroundImage = 'url('+coverPhoto+')';
        }
        const { user } = this.store.getState();
        let defaultAvatar = christian;
        if(user !== null && user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlProfile;
        }
        let fontCss = {
            fontFamily: fontFamily
        };
        let buttonCss = {
            fontFamily: fontFamily,
            background: primaryColor
        };
        const preview = (
            <div className={"layout "+layoutMode+"-layout layout-"+buttonStyle} >
                <div className={(theme === layoutMode ? "active" : "")} onClick={(e) => this.handleGuestPrefChange(e,"theme", layoutMode)}>
                    <div className="layout-content" style={backgroundStyle}>
                        <div className={classes.profileAvatar}>
                            <img src={defaultAvatar} alt="..." />
                        </div>
                        <ul style={transparencyStyle}>
                            <li className={" search-input "+classes.searchInput} style={fontCss}>
                                Search
                            </li>
                            <li className="columns one-column">
                                <ul>
                                    <li style={buttonCss}></li>
                                    <li style={buttonCss}></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <p>
                    {
                        layoutMode === "light" ? 
                            "Light Theme"
                        :
                        "Dark Theme"
                    }
                </p>
            </div>
        );
        return preview;
    }
    onColorPickerClose(){
        this.setState({openColorPicker: false, anchorEl: null});
    }
    render() {
        const { classes } = this.props;
        const { fonts, values, saving, tooltips, coverPhoto, editInfo, changeUserLevel, paymentData, subscription, openColorPicker,
            anchorEl, customColor } = this.state;
        const { user, authorized } = this.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const { primaryColor, buttonStyle, fontFamily, fontSize, layoutTransparency } = values.guestPreferences;
        let isCustomColor = helper.isCustomColor(primaryColor);

        let buttonCss = {
            fontFamily: fontFamily
        };
        let imageBtnCss = {}
        if(isCustomColor === true && user.userLevel >= 1){
            buttonCss.background = primaryColor;
            imageBtnCss.background = primaryColor;
        }
        if(fontSize === "small"){
            buttonCss.fontSize = "11px"
        }else{
            buttonCss.fontSize = "14px"
        }
        let backgroundStyle = {
            backgroundSize: "cover",
            backgroundPosition: "center",
        }
        if(coverPhoto){
            backgroundStyle.backgroundImage = 'url('+coverPhoto+')';
        }

        if(this.props.isActiveTab !== true){
            return <></>;
        }
        let headLinks = [];
        if(values.guestPreferences.hasOwnProperty("fontFamily") && helper.canLoadGoogleFont(values.guestPreferences.fontFamily)){
            headLinks.push({
                rel: "stylesheet",
                href: "https://fonts.googleapis.com/css?family="+values.guestPreferences.fontFamily+":400,500,700&display=swap"
            });
        }
        return (
            <div className={classes.mainContainer}>
                <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem}>
                    <h4 className={classes.title}>
                        Choose Your Theme
                    </h4>
                </GridItem>
                <form onSubmit={ this.handleSubmit } noValidate className={classes.form}>
                    {
                        values.userLevel >= 1 ?
                            <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem}>
                                <div className={classes.imageUpload+" "+(showBetaFeatures ? "" : classes.hideRemoveButton )}>
                                    <label className={classes.imageUploadLabel}>
                                        Choose the background photo of your search page
                                    </label>
                                    <ImageUpload
                                        onChange={(params) => this.handleChangePhoto('coverPhoto', params)}
                                        file={coverPhoto}
                                        showLoading={this.state.savingCoverPhoto}
                                        addButtonProps={{
                                            round: true, className:"addImage", label: "Add Background Photo",
                                            color: (user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor)),
                                            style: imageBtnCss
                                        }}
                                        removeButtonProps={{
                                            round: true, className:"removeImage",
                                            color: "danger"
                                        }}
                                        changeButtonProps={{
                                            round: true, className:"changeImage",
                                            color: (user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor)),
                                            style: imageBtnCss
                                        }}
                                        showInfoTooltip={user.userPreferences.showTips}
                                        onShowInfo={() => this.onTooltip('coverPhoto', true)}
                                        disabled={(values.userLevel < 1)}
                                        background={true}
                                    />
                                </div>
                            </GridItem>
                        :
                        <></>
                    }
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem + " " + classes.marginBottom}>
                        {
                            (values.userLevel < 1) ?
                                <p>
                                    Free accounts do not have access to these features -&nbsp;
                                    <Link href="/dashboard/account/upgrade" underline="always" onClick={this.upgradeUserLevel}>
                                        Upgrade Now!
                                    </Link>
                                </p>
                            :
                                editInfo === true ?
                                    <div className={classes.saveButtons}>
                                        <Button color="white" onClick={() => this.editUserInfo(false)}>
                                            <ArrowBackIcon className={classes.arrow} /> Cancel
                                        </Button>
                                        <Button color="success" type="submit">
                                            <SaveIcon className={classes.icons} /> Save
                                        </Button>
                                    </div>
                                :
                                    editInfo === false && saving === false ?
                                        <div className={classes.editButton}>
                                            <Button color="blue" onClick={() => this.editUserInfo()}>Edit</Button>
                                        </div>
                                    :
                                    saving ? <LoaderComponent align="left" /> : <></>
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem}>
                        <FormControl fullWidth>
                            <label>
                                Choose your primary color for your search page buttons
                                {
                                    values.userPreferences.showTips ?
                                        <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={() => this.onTooltip('primaryColor', true)} />
                                    :
                                    <></>
                                }
                            </label>
                            <ul className={classes.primaryColor+" "+(editInfo ? "" : classes.disableColor)}>
                                <li className={(primaryColor === "primary"?"active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "primary")}>
                                    <span></span>
                                </li>
                                <li className={classes.grayColor+(primaryColor === "gray"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "gray")}>
                                    <span></span>
                                </li>
                                <li className={classes.customInfo+(primaryColor === "customInfo"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customInfo")}>
                                    <span></span>
                                </li>
                                <li className={classes.customRose+(primaryColor === "customRose"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customRose")}>
                                    <span></span>
                                </li>
                                <li className={classes.customSuccess+(primaryColor === "customSuccess"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e, 'primaryColor', "customSuccess")}>
                                    <span></span>
                                </li>
                                {
                                    (values.userLevel === 2) ?
                                        <li className={classes.colorPicker+(isCustomColor === true ? " active":"")}>
                                            <span style={{backgroundColor: customColor}} onClick={(e) => this.colorPicker(e)}>
                                                <img src={paintIcon} alt="color picker" />
                                            </span>
                                            <CustomColorPicker 
                                                open={openColorPicker} 
                                                onChange={(color) => this.handleGuestPrefChange(null, 'primaryColor', color, true)} 
                                                onClose={() => this.onColorPickerClose()}
                                                color={customColor}
                                                anchorEl={anchorEl}
                                            /> 
                                        </li>
                                    :
                                        <></>
                                }
                            </ul>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem}>
                        <label className={classes.customLabel}>
                            Choose the font for your search page
                        </label>
                        <ul className={classes.fontSize+" "+(editInfo ? "" : classes.disableFont)}>
                            <li className={"large"+(fontSize === "large"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e,"fontSize", "large")}>
                                <span style={{fontFamily: fontFamily}}>Aa</span>
                            </li>
                            <li className={"small"+(fontSize === "small"?" active":"")} onClick={(e) => this.handleGuestPrefChange(e,"fontSize", "small")}>
                                <span style={{fontFamily: fontFamily}}>Aa</span>
                            </li>
                        </ul>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true
                            }}
                            labelText="Font"
                            showInfoTooltip={values.userPreferences.showTips}
                            onShowInfo={() => this.onTooltip('fontFamily', true) }
                            selectProps={{
                                onChange: (e) => this.handleGuestPrefChange(e,"fontFamily"),
                                value: values.guestPreferences.fontFamily
                            }}
                            inputProps={{
                                name: "fontFamily",
                                id: "outlined-fontFamily",
                                disabled: !editInfo
                            }}
                            items={fonts}
                            itemLabel="fontName"
                            itemValue="fontFamily"
                        />
                        <p className={classes.fontPreview} style={{fontFamily: "'"+fontFamily+"'"}}>A quick brown fox jumps over the lazy dog</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem+" "+classes.buttonShapes+" "+(editInfo ? "" : classes.disableButton)}>
                        <label className={classes.customLabel}>
                            Choose Your Button Style
                        </label>
                        <div className={(buttonStyle === "rectangle"?"active":"")} onClick={(e) => this.handleGuestPrefChange(e,"buttonStyle", "rectangle")}>
                            <Button color={(user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor))} style={buttonCss}>
                                Rectangle
                            </Button>
                        </div>
                        <div className={(buttonStyle === "rounded"?"active":"")} onClick={(e) => this.handleGuestPrefChange(e,"buttonStyle", "rounded")}>
                            <Button color={(user.userLevel < 1 ? "primary" : (isCustomColor ? "" : primaryColor))} style={buttonCss} round>
                                Rounded
                            </Button>
                        </div>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem+" "+classes.layoutStyle+" "+(editInfo ? "" : classes.disableLayout)}>
                        {this.renderLayoutPreview("light")}
                        {this.renderLayoutPreview("dark")}
                    </GridItem>
                        
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem+" "+classes.rangerSlider+" "+(editInfo ? "" : classes.disableOpacity)}>
                        <label className={classes.customLabel}>
                            Set Layout transparency
                        </label>
                        <div className="slidecontainer">
                            <input type="range" min="0" max="100" value={layoutTransparency} className="slider" id="myRange" onChange={(e) => this.handleGuestPrefChange(e, "layoutTransparency")} />
                        </div>
                    </GridItem>
                        
                    <GridItem xs={12} sm={12} md={8} lg={6} className={classes.gridItem}>
                        {
                            editInfo === true ?
                                <div className={classes.saveButtons}>
                                    <Button color="white" onClick={() => this.editUserInfo(false)}>
                                        <ArrowBackIcon className={classes.arrow} /> Cancel
                                    </Button>
                                    <Button color="success" type="submit">
                                        <SaveIcon className={classes.icons} /> Save
                                    </Button>
                                </div>
                            :
                                (values.userLevel < 1) ?
                                    <p>
                                        Free accounts do not have access to these features -&nbsp;
                                        <Link href="/dashboard/account/upgrade" underline="always" onClick={this.upgradeUserLevel}>
                                            Upgrade Now!
                                        </Link>
                                    </p>
                                :
                                    editInfo === false && saving === false ?
                                        <div className={classes.editButton}>
                                            <Button color="blue" onClick={() => this.editUserInfo()}>Edit</Button>
                                        </div>
                                    :
                                    saving ? <LoaderComponent align="left" /> : <></>
                        }
                    </GridItem>
                </form>
                {
                    tooltips.primaryColor ?
                        <PrimaryColorTooltipModal open={tooltips.primaryColor} store={this.store} onClose={() => this.onTooltip('primaryColor', false)} />
                    :
                    <></>
                }
                {
                    tooltips.fontFamily ?
                        <FontFamilyTooltipModal open={tooltips.fontFamily} store={this.store} onClose={() => this.onTooltip('fontFamily', false)} />
                    :
                    <></>
                }
                {
                    tooltips.coverPhoto ?
                        <CoverPhotoTooltipModal open={tooltips.coverPhoto} store={this.store} onClose={() => this.onTooltip('coverPhoto', false)} />
                    :
                    <></>
                }
                {
                    changeUserLevel ?
                        <ChangeUserLevelModal upgrade={true} redirectUrl="/dashboard/profile/styling" subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                    :
                        <></>
                }
                <Helmet 
                    title={process.env.REACT_APP_TITLE}
                    link={headLinks}
                />
            </div>
        )
    }
}

DashboardTabProfileTheme.defaultProps = {
    testMode: false
};

DashboardTabProfileTheme.propTypes = {
    classes: PropTypes.object.isRequired,
    testMode: PropTypes.bool
};

export default withStyles(dashboardTabProfileThemeStyle)(DashboardTabProfileTheme);
