import React from 'react'
import { Helmet } from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"
import CookiesStyle from "../../../assets/jss/help/cookiesStyle";
import Link from "@material-ui/core/Link";

const Cookies = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
    }
    getSiteUrl(){
        return window.location.protocol+"//"+window.location.host;
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container+" "+classes.main}>
                <h5 className={classes.textCenter}>COOKIE POLICY</h5>
                <p>This Cookie Policy describes how CloudPremise, LLC, on behalf of its affiliates and subsidiaries (collectively, “<b>Company</b>,” “<b>us</b>,” “<b>we</b>,” or “<b>our</b>”) uses cookies and similar technologies through https://searchmysocial.media(the “<b>Site</b>”) to provide, improve, promote and protect the Site. Note that any capitalized terms not defined in this Cookie Policy have the meanings set forth in our Privacy Policy, located at https://searchmysocial.media/help/privacy, orTerms of Use, located at https://searchmysocial.media/help/terms.</p>
                <h5>What is a cookie?</h5>
                <p>A cookie is a small piece of text sent to your browser when you visit the Site. It serves a variety of functions, like enabling us to remember certain information you provide to us as you navigate between pages on the Site. Each cookie expires after a certain period of time depending on what we use it for.Cookies are useful because they help us make your experience on the Site more enjoyable.  They allow us to recognize your device (e.g. your laptop or mobile device) so that we can tailor your experience of the Site.</p>
                <h5>Why do we use cookies?</h5>
                <p>We use first party and third party cookies for several reasons, such as letting you navigate between pages efficiently, remembering your preferences, remembering your username and password, enabling you to keep items in your shopping basket, letting us analyze how well our website is performing, and improving your experience. Some cookies are required for technical reasons in order for our Site to operate. Other cookies enable us and the third parties we work with to track and target the interests of visitors to our Site. For example, we use cookies to tailor content and information that we may send or display to you and otherwise personalize your experience while interacting with our Site and to otherwise improve the functionality of the Services we provide. Finally, third parties serve cookies through our Site for advertising, analytics, and other purposes. This is described in more detail below.</p>
                <h5>What cookies do we use?</h5>
                <p className={classes.italic}>Essential</p>
                <p>These cookies are strictly necessary to provide you with the Site and the Services available through our Site and to use some of their features, such as access to secure areas. Because these cookies are strictly necessary to deliver the Site, you cannot refuse them without impacting how our Site functions. You may be able to block or delete essential cookies by changing your browser settings.</p>
                <div className="sd-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Cookies</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Search My Social</td>
                            </tr>
                            <tr>
                                <td>Stripe</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className={classes.italic}>Performance and Analytics, Personalization, and Security</p>
                <p>These cookies help us analyze how the Services are being accessed and used, enable us to track performance,and secure the Site. For example, we use cookies to get insights regarding users and Site performance, such as page speed or to help us customize our Site and Services for you in order to enhance your experience.</p>
                <div className="sd-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Cookies</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Google Analytics</td>
                            </tr>
                            <tr>
                                <td>Status Page</td>
                            </tr>
                            <tr>
                                <td>Youtube</td>
                            </tr>
                            <tr>
                                <td>Search My Social</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className={classes.italic}>Targeting or Advertising Cookies</p>
                <p>These cookies are used to make advertising messages more relevant to you and your interests. We sometimes use cookies delivered by third parties to track the performance of our advertisements. For example, these cookies remember which browsers have visited our Site. The information provided to third parties does not include personal information, but this information may be re-associated with personal information after we receive it. This process helps us manage and track the effectiveness of our marketing efforts.</p>
                <p className={classes.italic}>Social Media</p>
                <p>These cookies are used when you share information using a social media sharing button or “like” button on the Site or you link your account or engage with our content on or through a social networking site such as Facebook, Twitter, or Instagram.  The social network will record that you have done this.  This information may be linked to targeting/ advertising activities.</p>
                <h5>How do I manage cookies? </h5>
                <p>You can remove or disable cookies via your browser by referring to your browser's configuration documentation, since the steps for doing so are usually browser-specific. Removing or disabling cookies might adversely impact your ability to use our Services effectively. Enabling cookies ensures a smoother experience when you use our Site.</p>
                <p>Most advertising networks offer you a way to opt out of Interest Based Advertising. If you wish to limit third parties’ collection of information about your use of our Platform, you can opt-out of such at the <Link href="http://optout.aboutads.info/" target="_blank">Digital Advertising Alliance</Link> or <Link href="http://optout.networkadvertising.org/" target="_blank">Network Advertising Initiative</Link> in the U.S., the <Link href="https://youradchoices.ca/choices/" target="_blank">Digital Advertising Alliance of Canada</Link> in Canada, or the <Link href="http://www.youronlinechoices.eu/" target="_blank">European Digital Advertising Alliance</Link> in Europe.  Mobile app users may opt out of receiving behavioral-based advertising in mobile apps provided by participating members of the Digital Advertising Alliance by installing the <Link href="http://www.aboutads.info/appchoices" target="_blank">AppChoices mobile app</Link>.  PLEASE NOTE THAT OPTING-OUT OF BEHAVIORAL ADVERTISING DOES NOT MEAN THAT YOU WILL NOT RECEIVE ADVERTISING WHILE USING THE PLATFORM.  IT WILL, HOWEVER, EXCLUDE YOU FROM INTEREST-BASED ADVERTISING CONDUCTED THROUGH PARTICIPATING NETWORKS, AS PROVIDED BY THEIR POLICIES AND CHOICE MECHANISMS.</p>
                <h5>Cookie Policy updates</h5>
                <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. The date at the bottom of this Cookie Policy indicates when it was last updated.</p>
                <h5>Where can you get further information?</h5>
                <p>If you have any questions about our use of cookies or other technologies, please email us at <Link href="mailto:datasecurity@smearch.com">datasecurity@smearch.com</Link>.</p>
                <br />
                <p>Last Revised: August 23, 2020</p>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(CookiesStyle)(Cookies);
