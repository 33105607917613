import React from 'react'
import AuthApi from "../../../assets/js/utils/Auth";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import { Link } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import Config from "../../../../Config";
import Api from "../../../assets/js/utils/Api";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
// import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import CardHeader from "../../components/Card/CardHeader.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Warning from "../Typography/Warning";
import SignupCodeModal from "./SignupCodeModal";
import MaterialLink from "@material-ui/core/Link";
import { ReactComponent as AmazonIcon } from "../../../assets/icons/amazon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook2.svg";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Close from "@material-ui/icons/Close";
import LockIcon from "@material-ui/icons/Lock";

import loginModalStyle from "../../../assets/jss/auth/loginModalStyle.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
  
Transition.displayName = "Transition";

const LoginModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.onSignupCodeDialogClose = this.onSignupCodeDialogClose.bind(this);

        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",
            code: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: false,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            signupCode: false,
            verifyEmail: false
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        state[name] = value;
        this.setState(state);
    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const { email, password } = this.state;
        AuthApi.signIn(email, password, this);
        this.setState({loading: true, showError: false});
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const { email } = this.state;        
        this.setState({loading: true, showError: false});
        Auth.forgotPassword(email)
        .then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        })
        .catch(err => {
            let errorMessage = "";
            if(typeof(err) === "string"){
                errorMessage = err;
            }else{
                errorMessage = err.message;
            }
            this.setState({loading: false, showError: true, errorMessage: errorMessage});
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const { email, code, password } = this.state;
        this.setState({loading: true});
        const that = this;
        Auth.forgotPasswordSubmit(email, code, password)
        .then(data => {
            AuthApi.signIn(email, password, that);
        })
        .catch(err => {
            let errorMessage = "";
            if(typeof(err) === "string"){
                errorMessage = err;
            }else{
                errorMessage = err.message;
            }
            that.setState({showError: true, loading: false, errorMessage: errorMessage});
        });

    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    onValidateSuccess(data){
        if(data.numberOfResponses > 0){
            const user = Api.prepareMemberObject(data.response[0]);
            try{
                setTimeout(function(){
                    var fullName = "";
                    if(user.hasOwnProperty("firstName")){
                        fullName = user.firstName;
                    }
                    if(user.hasOwnProperty("lastName")){
                        fullName += " "+user.lastName;
                    }
                    
                    if('FS' in window){
                        window.FS.identify(user.userId, {
                            displayName: fullName.trim(),
                            email: user.userEmail,
                            userId: user.userId
                        });
                    }
                },1000);
            }catch(e){  }
            
            this.props.onClose();
            if(user.userId === user.username){
                this.history.push("/user/onboarding");
            }else{
                this.history.push("/dashboard/editmode/search");
            }
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: user
                }
            });
        }
    }
    onValidateFail(err){
        let errorMessage = "";
        if(typeof(err) === "string"){
            errorMessage = err;
        }else{
            errorMessage = err.message;
        }
        let verifyEmail = false;
        if(typeof(err) === "object" && err.code === "UserNotConfirmedException"){
            verifyEmail = true;
        }
        this.setState({
            loading: false,
            showError: true,
            errorMessage: errorMessage,
            verifyEmail: verifyEmail
        });
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
    }
    handleSignup(show = true){
        this.setState({signupCode: show});
    }
    onSignupCodeDialogClose(){
        this.setState({signupCode: false});
        this.history.push("/signup");
        this.props.onClose();
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, showError, errorMessage, forgotPassword, requestCode, verifyEmail } = this.state;
        const { /*requireLowerletter, requireUpperletter, requireNumber,*/ requireSymbol, requireLength, signupCode } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        if(authorized){
            return <></>
        }
        const returnUrl = "/login";
        const amazonUrl = Config.getAmazonLoginUrl(returnUrl);
        const googleUrl = Config.getGoogleLoginUrl(returnUrl);
        const facebookUrl = Config.getFacebookLoginUrl(returnUrl);

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + " " + classes.modalLogin
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="login-modal-slide-title"
                aria-describedby="login-modal-slide-description"
            >
                {/* <Card plain className={classes.modalLoginCard}> */}
                    <DialogTitle
                        id="login-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        >
                        {/* <CardHeader
                            plain
                            color="primary"
                            className={
                            classes.textCenter + " " + classes.cardLoginHeader
                            }
                        > */}
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <h5 className={classes.cardTitle + " " + classes.modalTitle}>Log in</h5>
                            {/* <div className={classes.socialLine}>
                            <Button
                                justIcon
                                link
                                className={classes.socialLineButton}
                            >
                                <i className="fab fa-facebook-square" />
                            </Button>
                            <Button
                                justIcon
                                link
                                className={classes.socialLineButton}
                            >
                                <i className="fab fa-twitter" />
                            </Button>
                            <Button
                                justIcon
                                link
                                className={classes.socialLineButton}
                            >
                                <i className="fab fa-google-plus-g" />
                            </Button>
                            </div> */}
                        {/* </CardHeader> */}
                    </DialogTitle>
                    {
                        loading ?
                            <LoaderComponent />
                        :
                        <>
                            <DialogContent
                                id="login-modal-slide-description"
                                className={classes.modalBody}
                                >
                                <form onSubmit={this.handleSubmit} noValidate>
                                    {/* <p
                                    className={
                                        classes.description + " " + classes.textCenter
                                    }
                                    >
                                    Or Be Classical
                                    </p> */}
                                    <CardBody className={classes.cardLoginBody}>
                                        {
                                            verifyEmail ?
                                                <GridItem className={"passwordCheck-notValid-customizable "+classes.errorsSpacing}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">Your email is not verified. Please click <Link to={"/signup/verification"} onClick={() => this.props.onClose()}>here</Link> to verify it.</span>
                                                </GridItem>
                                            :
                                            showError ?
                                                <GridItem className={"passwordCheck-notValid-customizable "+classes.errorsSpacing}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                </GridItem>
                                            :
                                            <></>
                                        }
                                        {
                                            requestCode === false ?
                                                <CustomInput
                                                    id="email"
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Email",
                                                        type: "email",
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        name: "email",
                                                        value: this.state.email,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                            <Email className={classes.inputIconsColor} />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            :
                                            <>
                                                <Warning>
                                                    <p>We just sent you a six digit verification code to your email address.  Enter it below to verify your account.</p>
                                                </Warning>
                                                <CustomInput
                                                    success={validation.code === "success"}
                                                    error={validation.code === "error"}
                                                    id="input-code"
                                                    labelText="Verification Code"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'code'),
                                                        name: "code",
                                                        type: "text"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                <CustomInput
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id="input-password"
                                                    labelText="New Password"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'password'),
                                                        name: "password",
                                                        type: "password"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id="input-passwordConfirm"
                                                    labelText="New Password Again"
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                        name: "passwordConfirm",
                                                        type: "password"
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </>
                                        }
                                        
                                        {
                                            forgotPassword === false ?
                                                <>
                                                <CustomInput
                                                    id="password"
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.passwordControl
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Password",
                                                        type: "password",
                                                        onChange: (e) => this.handleChange(e, 'password'),
                                                        name: "password",
                                                        value: this.state.password,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon className={classes.inputIconsColor}></LockIcon>
                                                            </InputAdornment>
                                                        ),
                                                        autoComplete: "off"
                                                    }}
                                                />
                                                <GridItem className={classes.forgotGridItem}>
                                                    <p className={classes.anchor} onClick={this.handleForgotPassword}>Forgot?</p>
                                                    <p className={classes.anchor} onClick={() => this.onSignupCodeDialogClose()}>Signup</p>
                                                </GridItem>
                                                </>
                                            :
                                            <></>
                                        }
                                    </CardBody>
                                </form>
                            </DialogContent>
                            <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                                {
                                    forgotPassword === false ?
                                        <Button round color="primary" onClick={this.handleSubmit}>Login</Button>
                                    :
                                    requestCode === true ?
                                        <Button round color="primary" onClick={this.handleSubmit}>Reset Password</Button>
                                    :
                                        <Button round color="primary" onClick={this.handleSubmit}>Email me a reset link!</Button>
                                    
                                }
                            </DialogActions>
                            <div className={classes.textCenter+" "+classes.socialLinks}>
                                <MaterialLink href={amazonUrl} underline="none">
                                    <Button color="amazon">
                                        <AmazonIcon className={"MuiSvgIcon-root amazon-icon"} />
                                        Continue with Login with Amazon
                                    </Button>
                                </MaterialLink>
                                <MaterialLink href={googleUrl} underline="none">
                                    <Button color="google" className={classes.googleButton}>
                                        <GoogleIcon className={"MuiSvgIcon-root google-icon"} />
                                        Continue with Google
                                    </Button>
                                </MaterialLink>
                                <MaterialLink href={facebookUrl} underline="none">
                                    <Button color="facebook">
                                        <FacebookIcon className={"MuiSvgIcon-root facebook-icon"} />
                                        Continue with Facebook
                                    </Button>
                                </MaterialLink>
                            </div>
                        </>
                    }
                    
                {/* </Card> */}
                <SignupCodeModal showSignup={this.onSignupCodeDialogClose} onClose={() => this.handleSignup(false)} open={signupCode} history={this.history}/>;
            </Dialog>
        )
    }
}

LoginModal.defaultProps = {
    open: false
}
LoginModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(loginModalStyle)(LoginModal);