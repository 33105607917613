/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { matchPath } from 'react-router';
import Config from "../../../../Config";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import MaterialLink from "@material-ui/core/Link";

// core components
import {
    infoColor,
} from "../../../assets/jss/material-kit-pro-react.jsx";
import headerStyle from "../../../assets/jss/material-kit-pro-react/components/headerStyle.jsx";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.primaryColor = null;
        this.state = {
            mobileOpen: this.props.open,
            color: this.props.color,
            user: this.getUser()
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
        this.logoutAsUser = this.logoutAsUser.bind(this);
    }
    handleDrawerToggle() {
        this.props.updateDrawer(!this.state.mobileOpen);
    }
    componentWillReceiveProps(props){
        this.primaryColor = null;
        this.setState({
            color: props.color,
            mobileOpen: props.open,
            user: this.getUser()
        });
        if (this.props.changeColorOnScroll) {
            const that = this;
            setTimeout(function(){ that.headerColorChange(); },100);
        }
    }
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
            this.headerColorChange();
        }
    }
    headerColorChange() {
        const { color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            this.setState({color: changeColorOnScroll.color});
        } else {
            this.setState({color: color});
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }
    logoutAsUser(){
        localStorage.removeItem("auth_identity");
        localStorage.removeItem("auth_username");
        // this.store.dispatch({type: 'UPDATE_STATE', state: {
        //     authIdentity: null,
        //     authUsername: null
        // }});
        window.location.reload();
    }
    getUser(){
        const { user, authorized, searchedUser, url } = this.store.getState();
        let searchUser = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(authorized && searchMatch !== null && searchMatch.params.hasOwnProperty("username") && searchMatch.params.username.toLowerCase() === user.username.toLowerCase()){
            searchUser = user;
        }
        
        if(searchedUser !== false && searchMatch !== null && searchMatch.params.hasOwnProperty("username")){
            searchUser = null;
            if(searchedUser !== null && searchMatch.params.username.toLowerCase() === searchedUser.username.toLowerCase()){
                searchUser = searchedUser;
            }
        }
        return searchUser;
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        this.primaryColor = palette.custom.main;
        return palette.custom.main;
    }
    render() {
        const { classes, links, brand, fixed, absolute, desktopLinks, bottomBarLinks, bottomBarColor } = this.props;
        const { color } = this.state;
        let buttonColor = "inherit";
        if(color === "transparent" && this.state.user !== null){
            buttonColor = infoColor[0];
        }
        const { authorized, user, authUsername } = this.store.getState();
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed,
            [classes.hasAdminBar]: (authorized === true && user.isAdmin)
        });
        const bottomBarclasses = classNames({
            [classes.container]: true,
            [classes[bottomBarColor]]: bottomBarColor,
            [classes.bottomBar]: true,
        });
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/app/icon_trans393x551.png";
        const frontAppUrl = Config.getFrontEndUrl();
        const url = window.location.pathname;
        return (
            <AppBar className={appBarClasses+" "+(color === "transparent"?"header_transparent":"")}>
                {
                    authorized === true && (user.isAdmin || authUsername !== null)?
                        <div className={classes.adminBar}>
                            Admin (Logged in as {
                                authUsername !== null ?
                                    (authUsername)
                                :
                                (user.username)
                            })
                            {
                                authUsername !== null ?
                                    <span className={classes.logout} onClick={this.logoutAsUser}>Exit</span>
                                :
                                <></>
                            }
                        </div>
                    :
                    <></>
                }
                <Toolbar className={classes.container}>
                    <Hidden smDown>
                        {
                            authorized ?
                                <MaterialLink href={frontAppUrl+"/"+user.username} className={classes.brand+" header-logo"}><img src={logoUrl} alt={brand} /></MaterialLink>
                            :
                                (url.indexOf("login") !== -1 || url === "/" || url === "") ?
                                    <MaterialLink href={frontAppUrl} className={classes.brand+" header-logo"}><img src={logoUrl} alt={brand} /></MaterialLink>
                                :
                                <Link to={"/"} className={classes.brand+" header-logo"}><img src={logoUrl} alt={brand} /></Link>
                        }
                    </Hidden>                        
                    <Button className={classes.title} style={{color: buttonColor}}>
                        {
                            authorized ?
                                <MaterialLink href={frontAppUrl+"/"+user.username} className="brand_name">{brand}</MaterialLink>
                            :
                                (url.indexOf("login") !== -1 || url === "/" || url === "") ?
                                    <MaterialLink href={frontAppUrl} className="brand_name">{brand}</MaterialLink>
                                :
                                    <Link to={"/"} className="brand_name">{brand}</Link>
                        }
                    </Button>
                    <Hidden smDown implementation="css" className={classes.hidden}>
                        <div className={classes.collapse}>{desktopLinks}</div>
                    </Hidden>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
                <Drawer
                    variant="temporary"
                    anchor={"right"}
                    open={this.state.mobileOpen}
                    classes={{
                        paper: classes.drawerPaper,
                        modal: classes.drawerModal
                    }}
                    onClose={this.handleDrawerToggle}
                >
                    <Button
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                        className={classes.closeButtonDrawer}
                        style={{color: buttonColor}}
                    >
                        <Close />
                    </Button>
                    <div className={classes.appResponsive}>{links}</div>
                </Drawer>
                {
                    authorized ?
                        <Hidden mdUp>
                            <Toolbar className={bottomBarclasses}>
                                <div className={classes.collapse}>{bottomBarLinks}</div>
                            </Toolbar>
                        </Hidden>
                    :
                    <></>
                }
            </AppBar>
        );
    }
}

Header.defaultProp = {
    color: "white",
    bottomBarColor: "info",
    open: false,
    updateDrawer: function(){ },
    desktopLinks: <></>
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    bottomBarColor: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    links: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    open: PropTypes.bool,
    updateDrawer: PropTypes.func,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    }),
    desktopLinks: PropTypes.any
};

export default withTheme(withStyles(headerStyle)(Header));
