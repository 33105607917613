import React from 'react';
// import loadImage from "blueimp-load-image";
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Api from '../../../../../../assets/js/utils/Api';
import axios from 'axios';
import { helper } from "../../../../../../assets/js/utils/Element";
import LoaderComponent from '../../../../../components/Loader';
import CustomTagsInput from "../../../../../components/CustomInput/CustomTagsInput.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomInput from "../../../../../components/CustomInput/CustomInput.jsx";
import ImageUpload from "../../../../../components/CustomUpload/ImageUpload.jsx";
import GridItem from "../../../../../components/Grid/GridItem.jsx";
import Button from "../../../../../components/CustomButtons/Button.jsx";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ContentItemLocation from "../../../../../components/Location/ContentItemLocation";
import DateTimePicker from "../../../../../components/CustomInput/DateTimePicker.jsx";
import Link from "@material-ui/core/Link";
import ChangeUserLevelModal from "../../../../../components/Dashboard/ChangeUserLevelModal";
import CustomSelectWithIcon from "../../../../../components/CustomInput/CustomSelectWithIcon";
import Rating from "../../../../../components/Rating/Rating";
import CustomSelect from "../../../../../components/CustomInput/CustomSelect";

import { ReactComponent as ShoppingBagIcon } from "../../../../../../assets/icons/shopping_bag.svg";

import createPageStyle from "../../../../../../assets/jss/catalog/createPageStyle";

class LinkCreate extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        let contentPlatformId = helper.getParam("contentPlatformId");
        if(contentPlatformId === null || contentPlatformId === "null"){
            contentPlatformId = "";
        }
        if(this.props.hasOwnProperty("contentPlatformId")){
            contentPlatformId = this.props.contentPlatformId;
        }
        const { authorized, user } = this.store.getState();
        
        this.state = {
            values: {
                title: '',
                topic: '',
                keywords: [],
                affiliateUrl: '',
                discountCode: '',
                privateNotes: '',
                publicDescription: '',
                isDraft: true, //Using inverse process
                isSearchable: true,
                isAffiliate: false,
                isFeatured: false,
                isSponsored: false,
                hasPurchaseInfo: false,
                googlePlacesId: "",
                latitude: 0.0,
                longitude: 0.0,
                location: "",
                contentPublishedDateTime: "",
                contentExpiredDateTime: "",
                sequenceDateTime: "",
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
                coverMediaId: "",
                status: "Submitted",
                contentPlatformId: contentPlatformId,
                mediaProcessing: false,
                showPicture: true,
                type: "link",
                rating: 0,
            },
            requestUserLocation: false,
            isLocation: false,
            isScheduled: false,
            isRecommendation: false,
            redirect: false,
            saving: false,
            photo: null,
            validation: {
                title: '',
                keywords: '',
                affiliateUrl: '',
                isValid: false
            },
            cancelToken: null,
            tagInputValue: "",
            changeUserLevel: false,
            paymentData: this.props.paymentData,
            subscription: this.props.subscription,
            contentTypes: Api.getContentTypes(authorized, user)
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangePhoto = this.handleChangePhoto.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleIsLocation = this.handleIsLocation.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSearchCheckbox = this.handleSearchCheckbox.bind(this)
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onAffilicateClear = this.onAffilicateClear.bind(this);
        this.upgradeUserLevel = this.upgradeUserLevel.bind(this);
        this.changeUserLevel = this.changeUserLevel.bind(this);
        this.updateUserLevel = this.updateUserLevel.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
    }

    componentWillReceiveProps(props){
        this.setState({
            paymentData: props.paymentData,
            subscription: props.subscription
        })
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentDidMount(){
        if(document.getElementById("createWebLinkWizard")){
            window.scrollTo(0,(document.getElementById("createWebLinkWizard").getBoundingClientRect().top-70));
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.values.url !== this.state.values.url){
            const that = this;
            helper.scrapeUrl(this.state.values.url).then(data => {
                if(that.state.values.title.length > 0){
                    data.title = that.state.values.title;
                }
                if(that.state.values.keywords.length > 0){
                    data.keywords = that.state.values.keywords;
                }
                if(that.state.photo !== null){
                    data.imageUrl = that.state.photo;
                }
                that.setState({
                    ...that.state,
                    values: {
                        ...that.state.values,
                        title: data.title,
                        // keywords: data.keywords
                    },
                    photo: data.imageUrl
                });
                if(data.imageUrl !== null){
                    //this.uploadRemotePhoto(data.imageUrl);
                }
            });
        }
    }
    onRatingChange(event){
        let value = Number(event.target.value);
        this.setState({
            values: {
                ...this.state.values,
                rating: value
            }
        })
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: event.target.value
            }
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                values: {
                   ...this.state.values,
                   [name]: date ? parsedDate.toISOString() : ""
               }
           });
        }catch(e){ console.log(e); }        
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        const { values } = this.state;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(values[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "http://"+value;
            }
        }
        if(value === "http://" || value === "https://"){
            value = "";
        }

        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                [name]: value,
            }
        });
    }
    handleChangePhoto(params){
        if(params.file === null){
            this.deletePhoto();
            return;
        }

        this.setState({photo: params.file});
        // const that = this;
        // loadImage(params.file, img => {
        //     img.toBlob(blob => {
        //         const file = new File([blob], params.file.name, {type: params.file.type});
        //         that.setState({photo: file});
        //         //that.uploadPhoto(file);
        //     }, params.file.type);
        // }, {
        //     orientation: true
        // });
    }
    handleLocationChange(location){
        this.setState({
            values: {
                ...this.state.values,
                googlePlacesId: location.googlePlacesId,
                latitude: location.latitude,
                longitude: location.longitude,
                location: location.location,
                googlePlacesResponse: location
            },
            isLocation: true
        });
    };
    handleCheckbox(event, name) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                [name]: event.target.checked
            }
        });
    };
    handleSearchCheckbox(event , name) {
        let state = {
            values: {
                ...this.state.values,
                [name]: event.target.checked
            }
        }
        if(event.target.checked === false){
            state.values.contentPublishedDateTime = "";
            state.values.contentExpiredDateTime = "";  
        }
        this.setState(state);
    };
    handleIsLocation(event , name){
        let state = {
            [name]: event.target.checked
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                googlePlacesId: "",
                latitude: 0.0,
                longitude: 0.0,
                location: "",
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                },
            };  
            state['requestUserLocation'] = false ;
        }
        this.setState(state);
    }
    handleIsScheduled(event , name){
        let state = {
            [name]: event.target.checked
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                contentPublishedDateTime: "",
                contentExpiredDateTime: "",
            };  
        }
        this.setState(state);
    }
    handleIsRecommendation(event , name){
        let state = {
            [name]: event.target.checked,
        }
        if(event.target.checked === false){
            state['values'] = {
                ...this.state.values,
                rating: 0,
            }
        }
        
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            return;
        }
        this.setState({saving: true});
        const that = this;
        // if(document.getElementById("createWebLinkWizard")){
        //     window.scrollTo(0,(document.getElementById("createWebLinkWizard").getBoundingClientRect().top-70));
        // }
        let values = Object.assign({}, this.state.values);
        let regularTagsStr = this.state.values.keywords.toString();
        values.keywords = regularTagsStr;
        
        Api.createContent(values).then((data) => {
            if(data.response.length <= 0){
                this.setState({saving: false});
                return;
            }

            this.setState({
                values: {
                    ...that.state.values,
                    contentId: data.response[0].contentId,
                    sequenceDateTime: data.response[0].sequenceDateTime,
                }
            });
            if(that.state.photo !== null){
                //Handle photo uploading
                that.uploadRemotePhoto(data.response[0].contentId);
            }else{
                this.setState({redirect: true, saving: false});
            }
        }).catch(err => {
            this.setState({redirect: false, saving: false});
        });
    }
    validateForm(){
        let validation = {
            title: 'success',
            keywords: '',
            affiliateUrl: 'success',
            isValid: true
        };

        const { values } = this.state;
        // if(values.url.length <= 0){
        //     validation.url = "error";
        //     validation.isValid = false;
        // }
        if(values.affiliateUrl.length <= 0){
            validation.affiliateUrl = "error";
            validation.isValid = false;
        }
        if(values.title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        // if(values.hasPurchaseInfo){
        //     if(values.affiliateUrl.length <= 0){
        //         validation.affiliateUrl = "error";
        //         validation.isValid = false;
        //     }
        // }else{
        //     validation.affiliateUrl = "";
        // }
        this.setState({...this.state, validation: validation});
        return validation.isValid;
    }
    uploadPhoto(file, contentId){
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        var requestData = {
            contentId: contentId,
            mediaType: file.type,
            isCover: true
        };
        Api.createMedia(file, requestData, source, false).then((media) => {
            const values = {
                ...that.state.values,
                coverMediaId: media.mediaId,
                mediaProcessing: true
            };
            that.setState({
                cancelToken: null, 
                values: values,
            });
            let duplicateValues = Object.assign({}, this.state.values);
            let regularTagsStr = this.state.values.keywords.toString();
            duplicateValues.keywords = regularTagsStr;
            
            Api.updateContent(contentId, duplicateValues).then((data) => {
                Api.uploadMedia(file, media, source).then((data) => {
                    this.setState({redirect: true, saving: false});
                });                
            }).catch(err => {
                this.setState({redirect: false, saving: false});
            });

        }).catch(err => {
            that.setState({saving: false});
        });
    }
    uploadRemotePhoto(contentId){
        const file = this.state.photo;
        if(typeof(file) !== "string"){
            return this.uploadPhoto(file, contentId);
        }

        const fileName = file.substr(file.lastIndexOf("/")+1).split("?")[0];
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        axios.get(file, {responseType: "blob"}).then(res => {
            let metadata = {
                type: 'image/'+ext
            };
            let file = new File([res.data], fileName, metadata);
            this.uploadPhoto(file, contentId);
        });
    }
    deletePhoto(){
        if(this.state.values.coverMediaId.length <= 0){
            this.setState({photo: null});
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, photo: null, saving: true});
        Api.deleteMedia(this.state.values.coverMediaId, source).then((data) => {
            that.setState({
                cancelToken: null, 
                values: {
                    ...that.state.values,
                    coverMediaId: ""
                },
                saving: false
            })
        }).catch(err => {
            that.setState({saving: false});
        });
    }
    handleTags = regularTags => {
        let { keywords } = this.state.values;
        if(typeof(keywords) === "object" && regularTags.length > keywords.length){
            const lastIndex = regularTags[regularTags.length - 1];
            keywords = [lastIndex].concat(keywords);
        }else{
            keywords = regularTags;
        }
        this.setState({
            values: {
                ...this.state.values, 
                keywords: keywords,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let keywords = this.state.values.keywords;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                keywords: [tagValue, ...keywords],
            },
            tagInputValue: ""
        })
    }
    goBack(){
        if(this.props.onCancel !== null){
            this.props.onCancel();
            return;
        }
        const { user } = this.store.getState();
        if(window.history.length > 1){
            window.history.back(-1);
        }else{
            return <Redirect to={"/profile/"+user.userId+"/catalog"} />
        }
    }
    onAffilicateClear(){
        this.setState({
            values: {
                ...this.state.values,
                affiliateUrl: ""
            },
        });
    }
    upgradeUserLevel(e){
        e.preventDefault();
        this.changeUserLevel();
    }
    changeUserLevel(changeUserLevel = true){
        this.setState({changeUserLevel: changeUserLevel});
    }
    updateUserLevel(){
        this.setState({changeUserLevel: false});
    }
    render(){
        const { authorized, topics, user } = this.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        const { classes } = this.props;
        const { values, validation, photo, saving, isLocation, requestUserLocation, isScheduled, 
            changeUserLevel, paymentData, subscription, contentTypes, isRecommendation } = this.state;
        let affiliateUrl = values.affiliateUrl;
        if(!affiliateUrl || affiliateUrl.length <= 0){
            affiliateUrl = "http://";
        }

        if(this.state.redirect){
            if(this.props.onCreate !== null){
                this.props.onCreate('', values);
            }else{
                this.goBack();
            }                    
        }
        return (
            <Container className="catalog-create-page-holder">
                <Grid item className={classes.container} xs={12} md={8} lg={8}>
                    <GridItem>
                        <h3 className={classes.title}>Add Web Link</h3>
                    </GridItem>
                    {
                        authorized ?
                            <form onSubmit={ this.handleSubmit } noValidate>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.affiliateUrl === "success"}
                                        error={validation.affiliateUrl === "error"}
                                        id="outlined-affiliateUrl"
                                        labelText="Click-Through Url"                                    
                                        inputProps={{
                                            onChange: (e) => this.handleLinkChange(e, 'affiliateUrl'),
                                            value: values.affiliateUrl,
                                            name: "affiliateUrl"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl
                                        }}
                                        // onClear={this.onAffilicateClear}
                                    />
                                    <p className={classes.linkPreview}>
                                        {affiliateUrl}
                                    </p>
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        success={validation.title === "success"}
                                        error={validation.title === "error"}
                                        id="outlined-title"
                                        labelText="Title"                                    
                                        inputProps={{
                                            value: values.title,
                                            required: true,
                                            onChange: (e) => this.handleChange(e, 'title'),
                                            name: "title"
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.showPicture}
                                            onChange={ (e) => this.handleCheckbox(e, "showPicture")}
                                            value="showPicture"
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot
                                        }}
                                        label={values.showPicture ? "Include Picture with Search Result" : "No Picture"}
                                    />
                                </GridItem>
                                {
                                    values.showPicture ?
                                        <GridItem xs={12} sm={8} md={8} lg={6} className={classes.imgGrid}>
                                            {/* {
                                                photo === null ? 
                                                <p className={classes.centerParagraph}>Do you want to add a photo?</p>
                                                : ""
                                            } */}
                                            <ImageUpload
                                                onChange={this.handleChangePhoto}
                                                file={photo}
                                                addButtonProps={{ round: true }}
                                                changeButtonProps={{ round: true }}
                                                removeButtonProps={{ round: true, color: "danger" }}
                                            />
                                        </GridItem>
                                    :
                                        <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        id="outlined-publicDescription"
                                        labelText="Public Description"                                    
                                        inputProps={{
                                            value: values.publicDescription,
                                            onChange: (e) => this.handleChange(e, 'publicDescription'),
                                            name: "publicDescription",
                                            multiline: true,
                                            rows: 2
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />                                
                                </GridItem> 
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={isLocation}
                                            onChange={ (e) => this.handleIsLocation(e, "isLocation")}
                                            value="isLocation"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Location"
                                    />
                                </GridItem>
                                
                                {
                                    <Grid>
                                        <GridItem xs={12} sm={8} md={8} lg={6} className={(isLocation !== true ? classes.hideLocation: "")+" "+classes.customGridItem }>
                                            <ContentItemLocation onChange={this.handleLocationChange} requestUserLocation={requestUserLocation}  googlePlacesResponse={values.googlePlacesResponse} store={this.store} />
                                        </GridItem>
                                    </Grid>
                                }
                                {
                                    showBetaFeatures ?
                                        <>
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={isRecommendation}
                                                        onChange={ (e) => this.handleIsRecommendation(e, "isRecommendation")}
                                                        value="isRecommendation"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                    }
                                                    classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                    }}
                                                    label="Recommendation"
                                                />
                                            </GridItem>
                                            {
                                                isRecommendation ?
                                                    <Rating onChange={this.onRatingChange} rating={this.state.values.rating} />
                                                :
                                                    <></>
                                            }
                                            <GridItem xs={12} sm={8} md={8} lg={6}>
                                                <CustomSelect
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    selectProps={{
                                                        onChange: (e) => this.handleChange(e, 'topic'),
                                                        value: values.topic
                                                    }}
                                                    labelText={
                                                        <span>
                                                            Primary Topic
                                                        </span>
                                                    }
                                                    inputProps={{
                                                        name: "topic",
                                                        id: "outlined-topic",
                                                        className: classes.alignLeft
                                                    }}
                                                    items={topics}
                                                    itemLabel="topicName"
                                                    itemValue="topicName"
                                                />
                                            </GridItem>
                                        </>
                                    :
                                        <></>
                                }
                                {/* {
                                    showBetaFeatures ?
                                        <GridItem xs={12} sm={8} md={8} lg={6}>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={values.isDraft}
                                                    onChange={ (e) => this.handleCheckbox(e, "isDraft")}
                                                    value="isDraft"
                                                    classes={{
                                                        switchBase: classes.switchBase,
                                                        checked: classes.switchChecked,
                                                        thumb: classes.switchIcon,
                                                        track: classes.switchBar
                                                    }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label={(values.isDraft ? "Public": "Draft (Not available to public)")}
                                            />
                                        </GridItem>
                                    :
                                    <></>
                                } */}
                                {/* <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isFeatured}
                                            onChange={ (e) => this.handleCheckbox(e, "isFeatured")}
                                            value="isFeatured"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label={values.isFeatured ? "Featured" : "Not Featured"}
                                    />
                                </GridItem> */}
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isSearchable}
                                            onChange={ (e) => this.handleSearchCheckbox(e, "isSearchable")}
                                            value="isSearchable"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label={(values.isSearchable ? "Will appear in search results" : "Will not appear in search results")}
                                    />
                                </GridItem>
                                {
                                    values.isSearchable ?
                                        <GridItem xs={12} sm={8} md={8} lg={6}>
                                            <CustomTagsInput
                                                success={validation.keywords === "success"}
                                                error={validation.keywords === "error"}
                                                labelText= {
                                                    <span>
                                                        Search Key Words (What will people search to find this link?)
                                                    </span>
                                                }
                                                tagProps={{
                                                    value: values.keywords,
                                                    onChange: this.handleTags,
                                                }}
                                                inputProps={{
                                                    placeholder: "Add a search term",
                                                    onBlur: this.handleTagsChange,
                                                    onChange: this.handleTagInput,
                                                    value: this.state.tagInputValue,
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                color={(user.userLevel >= 0 ? user.guestPreferences.primaryColor: "primary")}
                                            />                            
                                        </GridItem>
                                    :
                                        <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={values.isAffiliate}
                                            onChange={ (e) => this.handleCheckbox(e, "isAffiliate")}
                                            value="isAffiliate"
                                            classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                            }}
                                        />
                                        }
                                        classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                        }}
                                        label="Advanced Options"
                                    />
                                </GridItem>
                                {
                                    values.isAffiliate ?
                                    <>
                                        {
                                            showBetaFeatures ?
                                                <Grid>
                                                    <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem}>
                                                        <CustomSelectWithIcon 
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            selectProps={{
                                                                onChange: (e) => this.handleChange(e,"type"),
                                                                value: values.type
                                                            }}
                                                            labelText={"Content Type (Icon)"}
                                                            inputProps={{
                                                                name: "type",
                                                                id: "outlined-type",
                                                                className: classes.alignLeft
                                                            }}
                                                            items={contentTypes}
                                                            itemLabel="itemLabel"
                                                            itemValue="itemValue"
                                                        />
                                                    </GridItem>
                                                </Grid>
                                            :
                                                <></>
                                        }
                                        <Grid>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem}>
                                                <DateTimePicker
                                                    labelText="Published Date"
                                                    id="sequenceDateTime"
                                                    value={values.sequenceDateTime}
                                                    onChange={(date) => this.handleDateChange(date, 'sequenceDateTime')}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>
                                        </Grid>
                                        {
                                            user.userLevel < 1 ?
                                                <GridItem className={classes.upgradePlan}>
                                                    Free accounts do not have access to these features -&nbsp;
                                                    <Link underline="always" onClick={this.upgradeUserLevel} href={"/dashboard/account/upgrade"}>
                                                        Upgrade Now!
                                                    </Link>
                                                </GridItem>
                                            :
                                                <></>
                                        }
                                        <Grid>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem}>
                                                <CustomInput
                                                    id="outlined-discountCode"
                                                    labelText="Discount Code"                                    
                                                    inputProps={{
                                                        value: values.discountCode,
                                                        onChange: (e) => this.handleChange(e, 'discountCode'),
                                                        name: "discountCode",
                                                        disabled: user.userLevel < 1 ? true : false
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />                                
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={values.hasPurchaseInfo}
                                                        onChange={ (e) => this.handleCheckbox(e, "hasPurchaseInfo")}
                                                        value="hasPurchaseInfo"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                        disabled={user.userLevel < 1 ? true : false}
                                                    />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label={
                                                        <>
                                                            <ShoppingBagIcon className={classes.cartIcon+" MuiSvgIcon-root "+(values.hasPurchaseInfo?classes.cartIconActive:"")} />
                                                            {values.hasPurchaseInfo ? "Can be purchased at click-through link" : "Do not show shopping icon"}
                                                        </>
                                                    }
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        checked={values.isSponsored}
                                                        onChange={ (e) => this.handleCheckbox(e, "isSponsored")}
                                                        value="isSponsored"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                        disabled={user.userLevel < 1 ? true : false}
                                                    />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label={values.isSponsored?"Disclose that I am being paid or sponsored for this search result":"I am not being paid or sponsored for this search result"}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem} >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={isScheduled}
                                                            onChange={ (e) => this.handleIsScheduled(e, "isScheduled")}
                                                            value="isScheduled"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                            disabled={user.userLevel < 1 ? true : false}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="Scheduled"
                                                />
                                            </GridItem>
                                            {
                                                isScheduled ? 
                                                <div>
                                                    <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem + ' ' + classes.marginTop}>
                                                        <DateTimePicker
                                                            labelText="Not Available Before"
                                                            id="contentPublishedDateTime"
                                                            value={values.contentPublishedDateTime}
                                                            onChange={(date) => this.handleDateChange(date, 'contentPublishedDateTime')}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={8} md={8} lg={6} className={classes.customGridItem + ' ' + classes.marginTop}>
                                                        <DateTimePicker
                                                            labelText="Not Available After"
                                                            id="contentExpiredDateTime"
                                                            value={values.contentExpiredDateTime}
                                                            onChange={(date) => this.handleDateChange(date, 'contentExpiredDateTime')}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                        />                            
                                                    </GridItem>
                                                    
                                                </div> : <></>
                                            }
                                        </Grid>
                                    </>
                                    : <></>
                                }
                                <GridItem xs={12} sm={8} md={8} lg={6}>
                                    <CustomInput
                                        id="outlined-privateNotes"
                                        labelText="Private Notes"                                    
                                        inputProps={{
                                            value: values.privateNotes,
                                            onChange: (e) => this.handleChange(e, 'privateNotes'),
                                            name: "privateNotes",
                                            multiline: true,
                                            rows: 2
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />                                
                                </GridItem>
                                <GridItem className={classes.buttonsSection}>
                                    <Button type="button" color="white" onClick={ this.goBack}>
                                        <ArrowBackIcon className={classes.arrow} /> Cancel
                                    </Button>
                                    <Button type="submit" color="success" className={classes.buttonSpacing}>
                                        {
                                            saving === false ?
                                                <>
                                                    <SaveIcon className={classes.icons} /> Save
                                                </>
                                            :
                                                <LoaderComponent align="center" saving={true} color="white" />
                                        }
                                    </Button>
                                </GridItem>
                            </form>
                        :
                            <></>
                    }
                    {
                        changeUserLevel ?
                            <ChangeUserLevelModal upgrade={true} subscription={subscription} paymentData={paymentData} store={this.store} open={changeUserLevel} onSuccess={this.updateUserLevel} onClose={() => this.changeUserLevel(false)} history={this.history} />
                        :
                            <></>
                    }
                </Grid>
                <Helmet>
                    <title>Search My Social App Search Catalog Create</title>
                </Helmet>
            </Container>
        )
    }
}

LinkCreate.defaultProps = {
    onCreate: null,
    onContentCreated: null,
    onCancel: null
};
LinkCreate.propTypes = {
    onCreate: PropTypes.func,
    onContentCreated: PropTypes.func,
    onCancel: PropTypes.func
}

export default withStyles(createPageStyle)(LinkCreate);