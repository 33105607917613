import React from "react";
import Config from "../../../../../../Config";
import PropTypes from "prop-types";
import { helper } from '../../../../../assets/js/utils/Element';
import Api from '../../../../../assets/js/utils/Api';
import axios from 'axios';
import Cookie from '../../../../../assets/js/utils/Cookie';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import Button from "../../../../components/CustomButtons/Button.jsx";
import LoaderComponent from "../../../../components/Loader";
import youtubeIcon from "../../../../../assets/img/youtube-platform-icon.png";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import TokenErrorNotice from "./TokenErrorNotice";
import HandleChangeModal from "./HandleChangeModal";
import InformationSafeTooltipModal from "../../../../components/ToolTips/Dashboard/Platforms/Youtube/InformationSafeTooltipModal"

const style = theme => ({
    center: {
        textAlign: "center"
    },
    preview: {
        marginTop: theme.spacing(2),
        "& h4": {
            marginBottom: "0px",
            fontWeight: "bold"
        }
    },
    message: {
        marginTop: theme.spacing(3)
    },
    description: {
        fontSize: "12px",
        fontStyle: "italic"
    },
    youtubePlatform: {
        display: "inline-block",
        padding: "2px",
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1)
        },
        "& a": {
            display: "inline-block"
        },
        "& h4": {
            margin: "0px",
            color: "#fff",
            fontWeight: "bold",
            [theme.breakpoints.down("xs")]: {
                fontSize: "15px"
            }
        },
        "& button": {
            margin: "0px",
            minWidth: "250px",
            [theme.breakpoints.down("xs")]: {
                minWidth: "200px",
            }
        },
        "& div": {
            marginTop: "10px",
            marginBottom: "10px"
        },
        "& img": {
            width: "150px",
            display: "block",
            margin: "0px auto",
            [theme.breakpoints.down("xs")]: {
                width: "120px"
            }
        },
    }
});

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.checkingDetails = false;
        
        this.state = {
            username: "",
            usernameState: "",
            authCode: "",
            loading: this.props.loading,
            imageUrl: null,
            platform: this.props.platform,
            youtubePlatform: this.props.youtubePlatform,
            cancelToken: null,
            error: false,
            changePopupShown: false,
            handleChange: false,
            newChannelTitle: "",
            tooltips: {
                informationSafe: false
            }
        };

        this.onClose = this.onClose.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onYes = this.onYes.bind(this);
        this.onTryAgain = this.onTryAgain.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    componentDidMount(){
        const that = this;
        const authCode = helper.getParam("code");
        const { platform, youtubePlatform } = this.state;
        setTimeout(function(){
            that.props.onMount(that.state);
            if(platform === null && youtubePlatform === null){
                that.props.onIsValidChange(true);
            }
        },100);
        if(authCode && authCode !== "null"){
            this.setState({ 
                authCode: authCode
            });
        }else{
            const { youtubePlatform } = this.state;
            this.checkPlatformToken(youtubePlatform);
        }
    }
    componentDidUpdate(prevProps, prevState){
        const { platform, youtubePlatform } = this.state;
        if(platform !== prevState.platform){
            if(platform === null && youtubePlatform === null){
                this.props.onIsValidChange(true);
            }else{
                this.props.onIsValidChange(false);
            }
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            platform: props.platform,
            loading: props.loading,
        });

        const { youtubePlatform, changePopupShown } = this.state;
        if(changePopupShown){
            return;
        }
        const { platform } = props;
        if(youtubePlatform === null || platform === null || typeof(youtubePlatform) !== "object" || typeof(platform) !== "object"){
            return;
        }
        if(!platform.hasOwnProperty("pendingData") || !platform.pendingData.hasOwnProperty("channelTitle")){
            return;
        }
        if(youtubePlatform.channelTitle !== platform.pendingData.channelTitle){
            this.setState({
                changePopupShown: true,
                handleChange: true,
            })
        }
    }
    isTokenExpired(platform){
        if(!platform.hasOwnProperty("error")){
            return false;
        }
        if(platform.error === true){
            return true;
        }
        return false;
    }
    checkPlatformToken(platform){
        if(platform === null || typeof(platform) !== "object"){
            return;
        }
        if(this.isTokenExpired(platform)){
            this.setState({
                error: true,
            });
        }
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        if(this.state.loading || this.state.platform === null){
            return false;
        }
        return true;
    }
    onSuccess(){
        this.setState({error: false});
        const authUrl = Config.getGoogleAuthUrl();
        window.location.href = authUrl;
    }
    onClose(){
        this.setState({
            error: false,
            loading: false
        });
    }
    onYes(){
        this.setState({
            handleChange: false,
            loading: false
        });
    }
    onTryAgain(e = null){
        if(e !== null){
            e.preventDefault();
        }
        this.setState({handleChange: false});
        const { platform } = this.state;
        const authUrl = Config.getGoogleAuthUrl();
        if(platform !== null){
            Cookie.write("platform_id", platform.contentPlatformId);
        }
        window.location.href = authUrl;
    }
    onCancel(){
        this.setState({
            handleChange: false,
        });

        const { youtubePlatform } = this.state;
        if(youtubePlatform !== null){
            const { user } = this.store.getState();
            const source = axios.CancelToken.source();
            youtubePlatform.status = "Reset Platform";
            this.setState({platform: youtubePlatform});
            Api.updatePlatform(youtubePlatform, source, user.userId).then((data) => { 
                if(data.numberOfResponses > 0){
                    this.setState({platform: data.response[0]});
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    getChannelTitle(){
        const { youtubePlatform, platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelTitle")){
            return platform.pendingData.channelTitle;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("pendingData") && youtubePlatform.pendingData.hasOwnProperty("channelTitle")){
            return youtubePlatform.pendingData.channelTitle;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("channelTitle")){
            return youtubePlatform.channelTitle;
        }
        return null;
    }
    getChannelDescription(){
        const { youtubePlatform, platform } = this.state;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelDescription")){
            return platform.pendingData.channelDescription;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("pendingData") && youtubePlatform.pendingData.hasOwnProperty("channelDescription")){
            return youtubePlatform.pendingData.channelDescription;
        }
        if(youtubePlatform !== null && youtubePlatform.hasOwnProperty("channelDescription")){
            return youtubePlatform.channelDescription;
        }
        return null;
    }
    onTooltip(name, status){
        this.setState({
            tooltips: {
                ...this.state.tooltips,
                [name]: status
            }
        });
    }
    render() {
        const { loading, error, handleChange, youtubePlatform, platform, tooltips } = this.state;
        const authUrl = Config.getGoogleAuthUrl();
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={10} className={classes.center}>
                        {
                            loading === true ?
                                <LoaderComponent align="center" />
                            :
                                platform === null ?
                                    <div className={classes.youtubePlatform}>
                                        <img src={youtubeIcon} className="youtube-icon" alt="youtube"/>
                                        <div>
                                            <Link href={authUrl} underline="none">
                                                <Button color="blue">
                                                    <h4>Click to Link</h4>
                                                </Button>
                                            </Link>
                                        </div>
                                        <Link underline="always" href="/" onClick={(e) => {e.preventDefault(); this.onTooltip("informationSafe", true) }}>
                                            Your Information is Safe
                                        </Link>
                                    </div>
                                :
                                    platform !== null ?
                                        <div className={classes.preview}>
                                            <div>
                                                <h4>Channel Name:</h4>
                                                <p>{this.getChannelTitle()}</p>
                                            </div>
                                            <div>
                                                <h4>Channel Description:</h4>
                                                <p className={classes.description}>{this.getChannelDescription()}</p>
                                            </div>
                                            <p className={classes.message}>We have identified your YouTube channel! Click the Next button to finish the process.</p>
                                            <Link href={authUrl} onClick={(e) => this.onTryAgain(e)}>
                                                Wrong Account?
                                            </Link>
                                        </div>
                                    :
                                    <></>                                
                        }
                    </GridItem>
                </GridContainer>
                <TokenErrorNotice open={error} onSuccess={this.onSuccess} onClose={this.onClose} />
                {
                    handleChange && platform !== null && youtubePlatform.channelTitle && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("channelTitle") ?
                        <HandleChangeModal open={handleChange} previousTitle={youtubePlatform.channelTitle} newTitle={platform.pendingData.channelTitle} onYes={this.onYes} onTryAgain={this.onTryAgain} onCancel={this.onCancel} />
                    :
                        <></>
                }
                {
                    tooltips.informationSafe ?
                        <InformationSafeTooltipModal open={tooltips.informationSafe} store={this.store} onClose={() => this.onTooltip('informationSafe', false)} />
                    :
                    <></>
                }
            </div>
        );
    }
}

Step1.defaultProps = {
    onMount: function(){ },
    platform: null
}
Step1.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func,
  platform: PropTypes.object
};

export default withStyles(style)(Step1);
