import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// import EditCatalog from "../../catalog/Edit";
import Button from "../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import ContentTypesEdit from "./Searchable/ContentTypesEdit";

import webLinkEditWizardStyle from "../../../../assets/jss/dashboard/platforms/webLinkEditWizardStyle.js";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const WebLinkEditWizard = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    render() {
        const { classes } = this.props;
        // const { user, authorized } = this.store.getState();
        
        // let showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot+" "+classes.modalRootCustom,
                    paper: classes.modal + " " + classes.modalWebLink+" "+classes.customModalWebLink,
                    scrollPaper: classes.paperScrollPaperCustom,
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onWizardClose(false, null)}
                aria-labelledby="weblink-edit-modal-slide-title"
                aria-describedby="weblink-edit-modal-slide-description"
            >
                <DialogContent
                    id="weblink-edit-modal-slide-description"
                    className={classes.modalBody+" "+classes.modalCustomBody}
                    >
                    <div className={classes.wizard} id="editWebLinkWizard">   
                        <Button color="transparent" justIcon className={classes.closeButton} onClick={() => this.props.onWizardClose(false)}>
                            <Close />
                        </Button>       
                        <ContentTypesEdit
                            store={this.props.store}
                            content={this.props.content}
                            onEdit={(platform, content, afterCreate, afterEdit) => this.props.onWizardClose(null, content, false, true)}
                            onCancel={() => this.props.onWizardClose(false)}
                            match={this.props.match}
                            platform={this.props.platform}
                            onDelete={(platform, content, afterCreate, afterEdit, afterDelete) => this.props.onWizardClose(null, null, false, false, true)}
                            hardrefresh={this.props.hardrefresh}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

WebLinkEditWizard.defaultProps = {
    open: true,
    onWizardClose: function(){ },
}
WebLinkEditWizard.propTypes = {
    open: PropTypes.bool,
    onWizardClose: PropTypes.func,
};

export default withStyles(webLinkEditWizardStyle)(WebLinkEditWizard);
