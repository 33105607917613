import {
    whiteColor,
    blackColor,
    hexToRgb,
    primaryColor
} from "../../../assets/jss/material-kit-pro-react.jsx";

const streamViewStyle = theme => ({
    marginAuto: {
        margin: "0px auto"
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    center: {
        textAlign: "center"
    },
    gridList: {
        width: "100%",
        display: 'block',
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        padding: "0px"
    },
    gridTile: {
        position: "relative",
        height: "auto !important",
        maxHeight: "500px !important",
        overflow: "hidden",
        width: "350px !important",
        margin: "0px auto",
        [theme.breakpoints.down('xs')]: {            
            width: "calc(100% - 4px) !important",
            margin: "8px 2px",
            padding: "0px !important",
        },
        "& img": {
            transform: "none",
            height: "auto",
            left: "auto",
            top: "auto",
            width: "100%"
        }
    },
    featuredIcon: {
        position: "absolute",
        left: "5px",
        top: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            width: "18px",
            height: "18px",
        }
    },
    contentTypeIcon: {
        position: "absolute",
        left: "5px",
        top: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        minWidth: "30px",
        padding: "0px",
        margin: "0px",
        color: whiteColor,
        "&:hover": {
            color: whiteColor,
        },
        "& svg": {
            width: "100%",
            height: "100%",
        },
        "& i": {
            fontSize: "24px !Important",
            justifyContent: "center",
            alignItems: "center",
            display: "flex !Important",
            [theme.breakpoints.down('sm')]:{
                fontSize: "16px !important"
            }
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: "18px", 
            width: "18px",
            height: "18px",
        }
    },
    cartIcon: {
        position: "absolute",
        right: "5px",
        bottom: "5px",
        zIndex: 1,
        width: "30px",
        height: "30px",
        color: whiteColor,
        [theme.breakpoints.down('sm')]:{
            width: "18px",
            height: "18px",
        }
    },
    overlay: {
        background: "rgba(" +
            hexToRgb(blackColor) +
            ", 0.12)",
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "block",
        left: "0px",
        right: "0px",
        top: "0px",
        bottom: "0px",
        cursor: "pointer"
    },
    hidePicture: {
        height: "auto !important",
        margin: "8px 2px",
        padding: "0px !important",
        overflow: "hidden",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "18px",
        },
        width: "calc(100% - 4px) !important",
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(theme.palette.custom.main) +
            ", 0.12)",
        paddingRight: "0px"
    },
    buttonBase: {
        height: "100%",
        width: "100%"
    },
    buttonView: {
        width: "100% !important",
        height: "auto !important",
        minHeight: "0px !important",
        "& a": {
            background: primaryColor[0],
            color: whiteColor,
            padding: theme.spacing(2,1),
            cursor: "pointer",
            fontWeight: "bold",
            minHeight: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            fontSize: "12px",
            margin: "0px"
        },
        [theme.breakpoints.down('xs')]: {            
            width: "100% !important"
        },
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(primaryColor[0]) +
            ", 0.12)",
    },
    buttonIcon: {
        "& a": {
            paddingLeft: "65px",
            paddingRight: "65px",
        },
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover",
            position: "absolute",
            left: "10px",
        }
    },
    platformView: {
        width: "346px !important",
        height: "200px !important",
        margin: "0px auto",
        padding: "0px !important",
        marginBottom: "5px",
        marginTop: "2px",
        maxWidth: "100%"
    },
    platformButton: {
        width: "calc( 100% - 8px)",
        height: "100%",
        margin: "0px",
        padding: "19.6px 8px",
        textTransform: "none",
        fontWeight: "bold",
        borderStyle: "dashed",
        color: blackColor,
        "&:hover, &:focus": {
            color: blackColor,
            borderStyle: "dashed",
        }
    },
    platformClose: {
        position: "absolute !important",
        right: "7px",
        width: "28px",
        height: "28px",
        padding: "3px",
        cursor: "pointer",
        zIndex: "1",
        marginTop: "7px",
        [theme.breakpoints.down("xs")]: {
            right: "7px"
        }
    },
    platformButtonDark: {
        background: blackColor,
        color: whiteColor,
        borderColor: whiteColor,
        "&:hover, &:focus": {
            background: blackColor,
            color: whiteColor,
            borderColor: whiteColor,
        }
    },
    instagramButton: {
        "& img": {
            maxHeight: "18px",
            marginLeft: "5px"
        }
    },
    loaderImage: {
        maxWidth: "100%",
        position: "relative",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        minHeight: "200px",
        "& .sd_loader": {
            width: "80px !important",
            height: "80px !important",
            [theme.breakpoints.down('xs')]: {
                width: "50px !important",
                height: "50px !important",
            }
        },
        "& .MuiCircularProgress-root": {
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "20px !important",
                height: "20px !important",
            }
        }
    },
});

export default streamViewStyle;