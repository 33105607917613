/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import MaterialIcon from "@mdi/react";
import { mdiLoading, mdiInformationOutline } from '@mdi/js';

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../CustomButtons/Button.jsx";

import defaultImage from "../../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../../assets/img/placeholder.jpg";
import styles from "../../../assets/jss/material-kit-pro-react/components/customImageUploadStyle.jsx";

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        file: this.props.file,
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
        };
        this.fileInput = React.createRef();
    }
    componentDidMount(){
        if(this.state.file !== null){
            if(typeof(this.state.file) === "string"){
                this.setState({
                    imagePreviewUrl: this.state.file
                });
                return;
            }
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                imagePreviewUrl: reader.result
                });
            };
            reader.readAsDataURL(this.state.file);
        }
    }
    componentWillReceiveProps(props){
        if(props.file === null){
            this.setState({
                file: null,
                imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
            });
            return;
        }
        if(typeof(props.file) === "string"){
            this.setState({
                file: props.file,
                imagePreviewUrl: props.file
            });
            return;
        }
        let reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
            file: props.file,
            imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(props.file);
    }
    handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        if(e.target.files.length <= 0){
            return;
        }
        let file = e.target.files[0];
        if(this.props.onChange !== null){
            this.props.onChange({
                file: file,
            });
            return;
        }    

        reader.onloadend = () => {
        this.setState({
            file: file,
            imagePreviewUrl: reader.result
        });
        };
        reader.readAsDataURL(file);
    };
    handleSubmit = e => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    handleClick = () => {
        this.fileInput.current.click();
    };
    handleRemove = () => {
        if(this.props.onChange !== null){
            this.props.onChange({
                file: null,
            });
            return;
        }
        this.setState({
        file: null,
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
        });
        this.fileInput.current.value = null;
    };
    render() {
        var {
        avatar,
        addButtonProps,
        changeButtonProps,
        removeButtonProps,
        classes,
        showLoading,
        showInfoTooltip,
        disabled,
        background,
        } = this.props;
        return (
            <div className={classes.imageUpload+" fileinput text-center"}>
                <input
                type="file"
                onChange={this.handleImageChange}
                ref={this.fileInput}
                />
                <div className={"thumbnail" + ((avatar || background) ? " img-circle" : "")+(showLoading ? ' picture_loading':'')}>
                    {
                        disabled ?
                            <img src={this.state.imagePreviewUrl} alt="..." />
                        :
                        <>
                            <img className={classes.preview} src={this.state.imagePreviewUrl} alt="..." onClick={() => this.handleClick()} />
                            {
                                showLoading ?
                                    <MaterialIcon path={mdiLoading} className="loading_icon MuiSvgIcon-root" />
                                :
                                    <></>
                            }
                        </>
                    }
                    
                </div>
                <div className="ImageUploadButtons">
                    {
                        disabled !== true ?
                            this.state.file === null ? (
                                <Button {...addButtonProps} onClick={() => this.handleClick()}>
                                    {
                                        addButtonProps.hasOwnProperty("label") ?
                                            addButtonProps.label
                                        :
                                        avatar ? "Add Photo" : "Select image"
                                    }
                                </Button>
                            ) : (
                                <span>
                                <Button
                                    {...removeButtonProps}
                                    onClick={() => this.handleRemove()}
                                >
                                    <i className="fas fa-times" /> 
                                    {
                                        removeButtonProps.hasOwnProperty("label") ?
                                            removeButtonProps.label
                                        :
                                        "Remove"
                                    }
                                </Button>
                                {avatar ? <br /> : null}
                                <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                                    {
                                        changeButtonProps.hasOwnProperty("label") ?
                                            changeButtonProps.label
                                        :
                                        "Change"
                                    }
                                </Button>
                                </span>
                            )
                        :
                        <></>
                    }
                    {
                        showInfoTooltip ?
                            <MaterialIcon path={mdiInformationOutline} className="MuiSvgIcon-root info_tooltip_icon" onClick={this.props.onShowInfo} />
                        :
                        <></>
                    }
                </div>
            </div>
        );
    }
}

ImageUpload.defaultProps = {
    onChange: null,
    file: null,
    showLoading: false,
    showInfoTooltip: false,
    onShowInfo: function(){ },
    disabled: false,
    background: false
};

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    background: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
    onChange: PropTypes.func,
    showLoading: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    onShowInfo: PropTypes.func,
    disabled: PropTypes.bool
};

export default withStyles(styles)(ImageUpload);