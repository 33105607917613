import {
    whiteColor,
    grayColor,
    dangerColor,
    successColor,
    defaultFont,
    primaryColor,
} from "../material-kit-pro-react.jsx";

const paymentMethodPageStyle = theme => ({
    title: {
        margin: theme.spacing(1, 0, 1),
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2)
    },
    cardElementContainer: {
        position: "relative"
    },
    cardElement: {
        marginTop: "5px",        
        marginBottom: "5px",
        border: "1px solid "+grayColor[11],
        padding: "10px"
    },
    cardLabel: {
        marginTop: theme.spacing(1),
        ...defaultFont,
        display: "block",
        color: grayColor[12] + " !important",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "1.42857",
        letterSpacing: "unset",
    },
    icons: {
        width: "17px",
        height: "17px",
        color: whiteColor
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    error: {
        display: "block",
        marginTop: theme.spacing(2),
    },
    debugInfo: {
        wordBreak: "break-all",
        listStyleType: "none",
        paddingLeft: "0px"
    },
    cardError: {
        paddingRight: "20px"
    },
    cardSuccess: {
       paddingRight: "20px"
    },
    cardLoading: {
        paddingRight: "20px"
    },
    labelRootError: {
        color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
        color: successColor[0] + " !important"
    },
    feedback: {
        position: "absolute",
        bottom: "7px",
        right: "0",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none"
    },
    animate: {
        animation: "customSpin 1.3s infinite linear"
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer",
        marginBottom: theme.spacing(1)
    },
    optionalCard: {
        color: successColor[0],
        textAlign: "center !important"
    }
});

export default paymentMethodPageStyle;