import { grayColor, whiteColor } from "../../material-kit-pro-react.jsx";
import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const linktreeWizardModalStyle = theme => ({
    ...customCheckboxRadioSwitch,
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "130px !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            margin: theme.spacing(1),
            width: "100%",
            maxWidth: "100%"
        },
        "& .MuiSelect-selectMenu": {
            textAlign: "left",
            textTransform: "none",
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0",
        paddingLeft: "24px",
        minHeight: "16.43px",
        [theme.breakpoints.down("xs")]: {
            position: "absolute",
            right: "0px",
            zIndex: "1"
        }
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
        marginTop: "10px"
    },
    modalCloseButton: {
        "&, &:hover, &:focus": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "0px",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "0px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "16px",
            paddingLeft: "16px",
            paddingBottom: "0px",
            paddingTop: "24px"
        }
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto",
        justifyContent: "space-between",
        width: "100%",
        overflow: "hidden",
        textAlign: "center",
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
            width: "300px",
            margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
            color: whiteColor,
            top: "-10px",
            right: "10px",
            textShadow: "none",
            position: "relative"
        },
        "& $modalHeader": {
            borderBottom: "none",
            paddingTop: "24px",
            paddingRight: "24px",
            paddingBottom: "0",
            paddingLeft: "24px"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        },
        "& $modalFooter": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
            paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
            paddingTop: "0"
        },
        "& $modalTitle": {
            textAlign: "center",
            width: "100%",
            marginTop: "0.625rem"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    closeButton: {
        marginTop: "20px"
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    },
    optionsGridItem: {
        textAlign: "left",
        padding: "0px"
    },
    saveButton: {
        "& .sd_loader": {
            width: "18px",
            height: "18px",
            "& [class*='MuiCircularProgress-root']": {
                width: "18px !important",
                height: "18px !important",
                color: whiteColor
            }
        }
    },
    linktreeIcon: {
        maxWidth: "195px"
    },
    socialLinkPreview: {
        textAlign: "left"
    },
    formControl: {
        marginBottom: "8px"
    }
});

export default linktreeWizardModalStyle;
