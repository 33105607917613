import React from 'react';
import { createBrowserHistory } from 'history';
import withStyles from "@material-ui/core/styles/withStyles";
import HelpFaqs from "./Faqs";
import HelpVideos from "./Videos";
import HelpContact from "./Contact";
import HelpTerms from "./Terms";
import HelpPrivacy from "./Privacy";
import Cookies from "./Cookies"

import NavPills from "../../components/NavPills/NavPills.jsx";

import MaterialIcon from "@mdi/react";
import { mdiFrequentlyAskedQuestions, mdiLibraryVideo, mdiAccountQuestion, mdiAccountLock, mdiFileDocumentEditOutline, mdiCookie} from '@mdi/js';

import helpHomeStyle from "../../../assets/jss/dashboard/tabs/dashboardTabHelpStyle";
const history = createBrowserHistory();

const HelpHome = class extends React.Component {
    constructor(props){
        super(props);
        
        this.store = this.props.store;
        this.navPills = React.createRef();

        this.state = {
            defaultTab: this.props.subTab,
            match: this.props.match,
        };

        this.onTabChange = this.onTabChange.bind(this);
    }
    onTabChange(tab){
        if(tab === this.state.defaultTab){
            tab = 0;
        }
        this.setState({
            defaultTab: tab,
            match: {
                params: {}
            }
        });
        switch(tab){
            case 0:
                history.push("/help/terms");
                break;
            case 1:
                history.push("/help/privacy");
                break;
            case 2:
                history.push("/help/cookies");
                break;
            case 3:
                history.push("/help/faqs");
                break;
            case 4:
                history.push("/help/contact");
                break;
            case 5:
                history.push("/help/videos");
                break;
            case 6:
                history.push("/help/tours");
                break;
            default:
                break;
        }
        if(tab === 0){
            this.navPills.current.handleChange(null, tab, false);
        }
    }
    render() {
        const { classes } = this.props;
        const { defaultTab } = this.state;
        return (
            <div className={classes.container+" "+classes.main}>
                <div className={classes.tabs}>
                    <NavPills
                        ref={this.navPills}
                        alignCenter
                        color="primary"
                        active={defaultTab}
                        onChange={this.onTabChange}
                        tabs={[
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiFileDocumentEditOutline} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <HelpTerms store={this.store} />
                                ),
                            },
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiAccountLock} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <HelpPrivacy store={this.store} />
                                ),
                            },
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiCookie} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <Cookies store={this.store} />
                                ),
                            },
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiFrequentlyAskedQuestions} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <HelpFaqs store={this.store} />
                                ),
                            },                            
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiAccountQuestion} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <HelpContact store={this.store}/>
                                ),
                            },
                            {
                                tabButton: "",
                                tabImageIcon: <MaterialIcon path={mdiLibraryVideo} className={"MuiSvgIcon-root"} />,
                                tabContent: (
                                    <HelpVideos store={this.store} />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(helpHomeStyle)(HelpHome);
