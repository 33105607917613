// import { whiteColor } from "../../material-kit-pro-react.jsx";
import modalStyle from "../../material-kit-pro-react/modalStyle.jsx";
  
const fillAbountInfoModalStyle = theme => ({
    ...modalStyle(theme),
    modalScrollPaper: {
        
    },
    modalCustom: {
        maxWidth: "500px"
    },
    modalHeaderCustom: {
        paddingTop: "0px"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        right: "0px",
        top: "0px",
        margin: "0px",
        zIndex: 1
    },
    darkModalRoot: {
        // "& $modalCustom": {
        //     backgroundColor: "rgb(1, 1, 1)",
        //     boxShadow: "0px 0px 5px -1px "+whiteColor
        // },
        // "& p": {
        //     color: whiteColor
        // }
    },
    justifyContentCenter: {
        justifyContent: "space-between"
    }
});
  
export default fillAbountInfoModalStyle;
  