
import React from "react";
import { helper } from '../../../../../assets/js/utils/Element';
import Api from '../../../../../assets/js/utils/Api';
import Cookie from '../../../../../assets/js/utils/Cookie';
import axios from 'axios';
import PropTypes from "prop-types";

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import Notice from "./Notice";
import NoticeUpgrade from "./NoticeUpgrade";
import YoutubeApiModal from "../../../../components/Dashboard/YoutubeApiModal.jsx";
import instagramStepsStyle from "../../../../../assets/jss/dashboard/platforms/instagramStepsStyle.js";

class InstagramLinkWizard extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);
        this.handleYoutubeModal = this.handleYoutubeModal.bind(this);
        this.onWizardClose = this.onWizardClose.bind(this);

        this.state = {
            saving: false,
            cancelToken: null,
            successModal: false,
            user: null,
            platform: null,
            instagramPlatform: this.props.instagramPlatform,
            youtubePlatform: this.props.youtubePlatform,
            upgradeModal: false,
            youtubeApiModal: false,
            loading: false,
            allStates: null
        };
    }
    componentDidMount(){
        const authCode = helper.getParam("code");
        if(authCode && authCode !== "null"){
            this.createPlatform(authCode);
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            instagramPlatform: props.instagramPlatform,
            youtubePlatform: props.youtubePlatform
        });
    }
    createPlatform(authCode){
        const { cancelToken } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        var requestData = {
            code: decodeURIComponent(authCode),
            type: "instagramAPI",
            status: "Authorize Platform",
        };
        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source});
        Api.createPlatform(requestData, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                this.setState({platform: data.response[0], loading: false});
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({loading: false});
            }
        });
    }
    handleFinish(allStates){
        const instagramHandle = allStates.account.username.replace(/\s/g, "");
        let { platform } = this.state;
        const values = {
            type: "instagramAPI",
            robotFrequency: parseInt(allStates.robot.robotFrequency),
            allContentSearchable: allStates.robot.searchable,
            instagramHandle: instagramHandle,
            addHashtagsToKeywords: allStates.robot.hashtags,
            attemptGeoTag: allStates.robot.attemptGeoTag,
            onlySmearchTags: allStates.robot.onlySmearchTags,
            useFirstLink: allStates.robot.useFirstLink,
            includeFirstCommentHashtags: allStates.robot.includeFirstCommentHashtags,
            instagramBeta: allStates.confirmation.notifyMe,
            instagramAccessToken: allStates.account.instagramAccessToken,
            retrieveAllMedia: allStates.robot.retrieveAllMedia,
            addRecognition: allStates.robot.addRecognition,
            importIGTV: allStates.robot.importIGTV,
            importReels: allStates.robot.importReels,
            importGuides: allStates.robot.importGuides,
        };
        platform = Object.assign(platform, values);
        platform.status = "Submit Platform";
        // const { instagramPlatform } = this.state;
        
        const { user } = this.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        let upgrade = false;
        // if(instagramPlatform !== null && instagramPlatform.instagramHandle === instagramHandle){
        //     this.setState({saving: false, cancelToken: source, upgradeModal: true});
        //     upgrade = true;
        // }else{
            this.setState({saving: true, cancelToken: source, successModal: true});
        // }
        
        Api.updatePlatform(platform, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                const { user } = this.store.getState();
                this.updateUserOnboarding(data.response[0], allStates);
                if(upgrade){
                    that.setState({upgradeModal: true, user: user, saving: false});
                }else{
                    that.setState({successModal: true, user: user, saving: false});
                }
            }
        }).catch(err => {
            console.log(err);
        });    
    }
    updateUserOnboarding(platform, allStates){
        const { user } = this.store.getState();
        const { showOnHomeFeed } = allStates.robot;
        let updateUser = false;
        if(showOnHomeFeed){
            if(!user.guestPreferences.hasOwnProperty("contentPlatforms")){
                user.guestPreferences.contentPlatforms = [];
            }
            user.guestPreferences.contentPlatforms.push(platform.contentPlatformId);
            updateUser = true;
        }
        if(user.userPreferences.hasOwnProperty("onboarding") || updateUser){
            user.userPreferences.onboarding.step4Complete = true;
            if(!user.hasOwnProperty("contentPlatforms")){
                user.contentPlatforms = [];
            }
            user.contentPlatforms.push(platform);
            this.setState({user: user});
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // console.log(data);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    onNoticeClose(){
        const { youtubePlatform } = this.state;
        const allowYoutube = Cookie.read("platform_wizard_youtube");
        const { user } = this.store.getState();
        if(youtubePlatform === null && allowYoutube !== user.userId){
            this.setState({youtubeApiModal: true});
            return;
        }
        this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
        const previewPageRedirect = Cookie.read("preview_redirect");
        if(previewPageRedirect === "1"){
            Cookie.dispose("preview_redirect");
            this.history.push("/dashboard/editmode/search");
        }else{
            this.history.push("/dashboard/platforms");
        }
    }
    handleYoutubeModal(open){
        const { user } = this.store.getState();
        if(open === null){
            Cookie.write("platform_wizard_youtube", user.userId);
        }
        if(open === false || open === null){
            this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
            this.history.push("/dashboard/platforms");
            return;
        }
        this.setState({ youtubeApiModal: false });
        this.history.push("/dashboard/platformwizard/youtube");
    }
    onWizardClose(){
        const { user } = this.store.getState();
        const { platform } = this.state;
        if(platform !== null && platform.status === "Platform Authorized"){
            const source = axios.CancelToken.source();
            Api.deletePlatform(platform, source, user.userId).then((data) => { 
                //Success
            }).catch(err => {
                console.log(err);
            });
        }
        this.props.onWizardClose();
        const previewPageRedirect = Cookie.read("preview_redirect");
        if(previewPageRedirect === "1"){
            Cookie.dispose("preview_redirect");
            this.props.history.push("/dashboard/editmode/search");
        }else{
            this.props.history.push("/dashboard/platforms");
        }
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { successModal, saving, instagramPlatform, upgradeModal, youtubeApiModal, platform, loading } = this.state;
        if(!authorized){
            return <></>
        }

        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    <Button color="transparent" justIcon className={classes.closeButton} onClick={this.onWizardClose}>
                        <Close />
                    </Button>
                    <Wizard
                        validate
                        store={this.store}
                        steps={[
                            { stepName: "Authorization", stepComponent: Step1, stepId: "account", props: {platform: platform, instagramPlatform: instagramPlatform, loading: loading} },
                            { stepName: "Instructions", stepComponent: Step2, stepId: "robot" },
                            { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                        ]}
                        title="Link Your Instagram Account"
                        subtitle="We will automatically load your search engine!"
                        finishButtonText="Done!"
                        finishButtonClick={this.handleFinish}
                        color="primary"
                    />
                </GridItem>
                {
                    successModal ?
                        <Notice store={this.store} onClose={this.onNoticeClose} open={successModal} saving={saving} />
                    :
                        <></>
                }
                {
                    upgradeModal ?
                        <NoticeUpgrade store={this.store} onClose={this.onNoticeClose} open={upgradeModal} saving={saving} instagramPlatform={instagramPlatform} />
                    :
                        <></>
                }
                {
                    youtubeApiModal ?
                        <YoutubeApiModal open={youtubeApiModal} onClick={event => this.handleYoutubeModal(true)} onClose={event => this.handleYoutubeModal(false)} onNever={event => this.handleYoutubeModal(null)} />
                    :
                    <></>
                }
            </GridContainer>
        );
    }
}

InstagramLinkWizard.defaultProps = {
    onWizardClose: function(){ },
    youtubePlatform: null
}

InstagramLinkWizard.propTypes = {
    onWizardClose: PropTypes.func,
    youtubePlatform: PropTypes.object
}
export default withStyles(instagramStepsStyle)(InstagramLinkWizard);
