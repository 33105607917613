
import React from "react";
import {Helmet} from 'react-helmet';
import Api from '../../../assets/js/utils/Api';
import { helper } from "../../../assets/js/utils/Element";
import axios from 'axios';
import { uuid } from 'uuidv4';

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../components/Wizard/Wizard.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import PaymentProcessingModal from "../../components/Stripe/PaymentProcessingModal";
import LoaderComponent from "../../components/Loader";

import Step1 from "./OnboardingSteps/Step1.jsx";
import Step2 from "./OnboardingSteps/Step2.jsx";
import Step3 from "./OnboardingSteps/Step3.jsx";
import Step4 from "./OnboardingSteps/Step4.jsx";
import ForceResetPassword from "./ForceResetPassword.jsx";
import onboardingPageStyle from "../../../assets/jss/user/onboardingPageStyle.js";

class Onboarding extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onStepMount = this.onStepMount.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        const { user } = this.store.getState();

        this.state = {
            saving: false,
            cancelToken: null,
            allStates: {},
            subscription: null,
            errorMessage: null,
            open: false,
            updateUserInfo: false,
            stepId: 0,
            forcePasswordReset: (user.hasOwnProperty("cognitoForcePasswordReset") && user.cognitoForcePasswordReset)
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(authorized){
            this.getSubscription();
            this.updateReferalData();
        }
    }
    onStepMount(stepId, state, stepChanged = false){
        this.setState({
            stepId: (stepChanged ? stepId : this.state.stepId),
            allStates: state
        });
    }
    handleFinish(allStates){
        const account = allStates.account;
        if(account.userLevel === 0){
            this.finishOnboarding();
            return;
        }
        
        const source = axios.CancelToken.source(); 
        this.setState({
            cancelToken: source,
            allStates: allStates,
            saving: true,
            open: true,
            stepId: 3
        });
        if(allStates.hasOwnProperty("account") && allStates.account.savedMethod){
            this.handleSubscription();
            return;
        }

        if(allStates.account.coupon !== null && allStates.account.coupon.percent_off === 100){
            this.handleSubscription();
            return;
        }

        const cardDetails = {
            paymentToken: account.paymentToken,
            paymentLast4Digits: account.paymentLast4Digits,
            paymentExpirationDate: account.paymentExpirationDate,
            stripeData: account.stripeData,
        }
        Api.createPaymentMethod(cardDetails, source).then((data) => {
            this.handleSubscription();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    saving: false,
                    errorMessage: err.message
                });
            }
        });
    }
    isNonFree(checkCoupon = false){
        const { allStates } = this.state;
        if(!allStates.hasOwnProperty("account")){
            return false;
        }
        if(allStates.account.userLevel === 0){
            return false;
        }
        if(checkCoupon && allStates.account.coupon !== null && allStates.account.coupon.percent_off === 100){
            return false;
        }
        return true;
    }
    getSubscription(){
        const source = axios.CancelToken.source(); 
        this.setState({cancelToken: source});
        Api.getSubscription(source).then((data) => {
            this.setState({subscription: data});
        }).catch(err => {
            
        });
    }
    getPackagePrice(){
        const account = this.state.allStates.account;
        let price = account.userPackage.pricing.annualAmount;
        if(account.billingFrequency === "Monthly"){
            price = account.userPackage.pricing.monthlyAmount;
        }
        const coupon = this.getCoupon();
        if(coupon !== null){
            if(coupon.hasOwnProperty("percent_off") && coupon.percent_off !== null && coupon.percent_off > 0){
                price = price - ((coupon.percent_off/100)*price);
            }
        }
        return price;
    }
    handleSubscription(){
        const { subscription, allStates } = this.state;
        const account = allStates.account;
        const source = axios.CancelToken.source(); 
        this.setState({cancelToken: source});
        const requestData = {
            "userLevel": account.userLevel,
	        "billingFrequency": account.billingFrequency,
        };
        // const price = this.getPackagePrice();
        const coupon = this.getCoupon();
        if(coupon !== null){
            requestData['couponId'] = coupon.id;
        }
        if(subscription === null){
            Api.createSubscription(requestData, source).then((data) => {
                if(data.subscriptionStatus === "active"){
                    // this.fbPixelTrackPrice(price);
                    this.setState({subscription: data});
                    this.finishOnboarding();
                }else{
                    this.setState({subscription: data, saving: false});
                }
                
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        saving: false,
                        errorMessage: err.message,
                        subscription: null
                    });
                }
            });
        }else{
            Api.updateSubscription(requestData, source).then((data) => {
                if(data.subscriptionStatus === "active"){
                    // this.fbPixelTrackPrice(price);
                    this.setState({subscription: data});
                    this.finishOnboarding();
                }else{
                    this.setState({subscription: data, saving: false});
                }
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        saving: false,
                        errorMessage: err.message,
                        subscription: null
                    });
                }
            });
        }
    }
    fbPixelTrackPrice(price){
        try{
            if('fbq' in window){
                window.fbq('track', 'Subscribe', {
                    value: price,
                    currency: 'USD',
                });
            }
        }catch(e){ }
    }
    finishOnboarding(finishFree = false){
        const { allStates } = this.state;
        const { user } = this.store.getState();
        let values = Object.assign({}, user);

        values.firstName = allStates.profile.firstname;
        values.lastName = allStates.profile.lastname;
        values.username = allStates.profile.username.toLowerCase();
        values.userPreferences.showTips = allStates.profile.showTips;
        
        values.userLevel = allStates.account.userLevel;
        values.userPreferences.onboardingComplete = true;
        values.userPreferences.onboarding.step1Complete = true;
        const storageName = "searchmysocial_app_version";
        let currentVersion = localStorage.getItem(storageName);
        if(!currentVersion){
            currentVersion = 1.0
        }
        values.terms = {
            version: currentVersion, 
            dateAccepted: new Date().toISOString()
        }
        if(allStates.account.userLevel !== 0){
            values.paymentFrequency = allStates.account.billingFrequency;
            values.socialIcons = allStates.about.values.socialIcons;
            values.bio = allStates.about.values.bio;
            let topSearchTerms = allStates.about.topSearchTerms;
            let topSearchTermsStr = topSearchTerms.toString();
            values.topSearchTerms = topSearchTermsStr;
        }
        
        values.birthdate = allStates.about.birthdate;
        values.pronouns = allStates.about.pronouns;
        values.country = allStates.about.country;
        values.stateProvince = allStates.about.stateProvince;
        values.city = allStates.about.city;
        values.postalCode = allStates.about.postalCode;
        values.updateSalesforce = true;
        values.euTransferConsent = allStates.about.euTransferConsent;

        if(!values.userPreferences.hasOwnProperty("onboarding")){
            values.userPreferences['onboarding'] = {};
        }
        values.userPreferences.onboarding['about'] = true;
        values.userPreferences.onboarding['account'] = true;
        values.userPreferences.onboarding['profile'] = true;
        values.userPreferences.onboarding['confirm'] = true;

        let referralData = {};
        const refType = helper.getParam("refType");
        if(refType !== null && refType !== "null" && refType.length > 0){
            referralData['refType'] = refType;
        }
        const refLink = helper.getParam("refLink");
        if(refLink !== null && refLink !== "null" && refLink.length > 0){
            referralData['refLink'] = refLink;
        }
        const refUId = helper.getParam("refUId");
        if(refUId !== null && refUId !== "null" && refUId.length > 0){
            referralData['refUid'] = refUId;
        }
        if(referralData.hasOwnProperty("refType") || referralData.hasOwnProperty("refUId") || referralData.hasOwnProperty("refLink")){
            referralData['refQuery'] = window.location.search.substr(1);
            values.referralData = referralData;
        }

        values['cognitoRandomPassword'] = '';
        values['cognitoForcePasswordReset'] = false;

        const that = this;
        const source = axios.CancelToken.source(); 
        let saving = false;
        if(this.isNonFree() === false){
            saving = true;
        }
        this.setState({saving: saving, cancelToken: source, open: true, updateUserInfo: finishFree});
        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                const newUser = Api.prepareMemberObject(data.response[0]);
                if(newUser.hasOwnProperty("updateSalesforce")){
                    newUser['updateSalesforce'] = false;
                }
                try{
                    if('fbq' in window){
                        // const price = this.getPackagePrice();
                        const account = this.state.allStates.account;
                        const uniqueID = uuid();
                        window.fbq('track', 'CompleteRegistration', {
                            value: process.env.REACT_APP_FB_REG_VALUE,
                            currency: 'USD',
                            userLevel: account.userPackage.level,
                            userId: user.userId
                        }, {eventID: user.userId+":"+uniqueID});
                    }
                }catch(e){ }
                that.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser, redirect: true }});
                that.history.push("/dashboard/editmode/search?onboarding=1");
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    saving: false,
                    errorMessage: err.message
                });
            }
        });
    }
    onMethodChange(){
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source,
            subscription: null,
            open: false,
            saving: false,
            stepId: 1
        });
        Api.deleteSubscription({immediate: true}, source).then(data => {
            
        }).catch(err => {

        });
    }
    onModalClose(){
        this.setState({
            open: false
        });
    }
    getCoupon(){
        const { allStates } = this.state;
        if(!allStates.hasOwnProperty("account")){
            return null;
        }
        if(allStates.account.userLevel === 0){
            return null;
        }
        if(allStates.account.coupon !== null){
            return allStates.account.coupon;
        }
        return null;
    }
    onFree(){
        const source = axios.CancelToken.source();
        this.setState({
            updateUserInfo: true,
            cancelToken: source
        });
        Api.deleteSubscription({immediate: true}, source).then(data => {
            this.finishOnboarding(true);
        }).catch(err => {

        });
    }
    updateReferalData(){
        const { user } = this.store.getState();
        let values = Object.assign({}, user);
        let referralData = {};
        const refType = helper.getParam("refType");
        if(refType !== null && refType !== "null" && refType.length > 0){
            referralData['refType'] = refType;
        }
        const refLink = helper.getParam("refLink");
        if(refLink !== null && refLink !== "null" && refLink.length > 0){
            referralData['refLink'] = refLink;
        }
        const refUId = helper.getParam("refUId");
        if(refUId !== null && refUId !== "null" && refUId.length > 0){
            referralData['refUid'] = refUId;
        }
        if(referralData.hasOwnProperty("refType") || referralData.hasOwnProperty("refUId") || referralData.hasOwnProperty("refLink")){
            referralData['refQuery'] = window.location.search.substr(1);
            values.referralData = referralData;
        }else{
            return;
        }

        Api.updateUser(user.userId, values).then((data) => {
            if(data.numberOfResponses > 0){
                //Silent
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                //Silent
            }
        });
    }
    onPasswordReset(){
        this.setState({forcePasswordReset: false});
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { saving, open, errorMessage, subscription, updateUserInfo, stepId, forcePasswordReset } = this.state;
        if(!authorized){
            return <></>
        }
        const isNonFree = this.isNonFree();
        const coupon = this.getCoupon();
        if(forcePasswordReset){
            return(
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={4} className={classes.gridItemSpacing}>
                        <ForceResetPassword store={this.store} onPasswordReset={() => this.onPasswordReset()} />
                    </GridItem>
                </GridContainer>
            )
        }

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8} className={classes.gridItemSpacing}>
                    {
                        isNonFree === false && saving === true ?
                            <LoaderComponent align="center" />
                        :
                        <>
                            <Wizard
                                validate
                                store={this.store}
                                steps={[
                                    { stepName: "Profile", stepComponent: Step1, stepId: "profile" },
                                    { stepName: "Account", stepComponent: Step2, stepId: "account" },
                                    { stepName: "Personal", stepComponent: Step4, stepId: "about" },
                                    { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                                ]}
                                title="Welcome to Search My Social!"
                                subtitle="We are changing the way the world searches… one bio-link at a time!"
                                finishButtonText={(this.isNonFree(true) ? "Submit & Pay" : "Submit")}
                                finishButtonClick={this.handleFinish}
                                onStepMount={this.onStepMount}
                                currentStep={stepId}
                                color="primary"
                                nextButtonDisabled={true}
                            />
                            <PaymentProcessingModal onBoarding={true} updateUserInfo={updateUserInfo} onMethodChange={() => this.onMethodChange()} onFree={() => this.onFree()} open={open} saving={saving} errorMessage={errorMessage} subscription={subscription} onClose={this.onModalClose} coupon={coupon} />
                        </>
                    }
                </GridItem>
                
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </GridContainer>
        );
    }
}

export default withStyles(onboardingPageStyle)(Onboarding);
