/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { helper } from '../../../../assets/js/utils/Element'
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import DatePicker from "../../../components/CustomInput/DatePicker.jsx";
import Danger from "../../../components/Typography/Danger.jsx";
import Api from '../../../../assets/js/utils/Api'
import Cookie from '../../../../assets/js/utils/Cookie';
import { Redirect } from 'react-router-dom'
import MaterialIcon from "@mdi/react";
import { mdiLockOutline } from '@mdi/js';
import CustomTagsInput from "../../../components/CustomInput/CustomTagsInput.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import CustomCountry from "../../../components/CustomInput/CustomCountry";
import CustomCountryState from "../../../components/CustomInput/CustomCountryState";
import CustomSelectSocialIcons from "../../../components/CustomInput/CustomSelectSocialIcons";
import Button from "../../../components/CustomButtons/Button.jsx";
import { ReactSortable } from "react-sortablejs";
import Menu from "@material-ui/icons/Menu";

import { ReactComponent as PinterestIcon } from "../../../../assets/icons/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/facebook.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import instagramIcon from "../../../../assets/img/instagram-icon.png";
import tiktokIcon from "../../../../assets/img/tiktok-icon.png";
import snapchatIcon  from "../../../../assets/img/snapchat-icon.png";
import soundcloudIcon from "../../../../assets/img/soundcloud-icon.png";
import whatsappIcon from "../../../../assets/img/whatsapp-icon.png";
import twitchIcon from "../../../../assets/img/twitch-icon.png";
import patreonIcon from "../../../../assets/img/patreon-icon.png";
import spotifyIcon from "../../../../assets/img/spotify-icon.png";
import amazonLiveIcon from "../../../../assets/img/amazon-live-icon.png";
import amazonShopIcon from "../../../../assets/img/amazon-shop-icon.png";
import appleMusicIcon from "../../../../assets/img/apple-music-icon.png";
import itunesIcon from "../../../../assets/img/itunes-icon.png";
import googlePlayIcon from "../../../../assets/img/gogole-play-icon.png";
import mediumIcon from "../../../../assets/img/medium-icon.png";
import likeToKnowItIcon from "../../../../assets/img/like-to-know-icon.png";

import customSelectStyle from "../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { grayColor } from "../../../../assets/jss/material-kit-pro-react.jsx";

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    demographicsContent:{
        "& .private_icon": {
            cursor: "auto",
        },
        "& .MuiInputLabel-shrink": {
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        }
    },
    ...customSelectStyle,
    tagsFormControl: {
        paddingTop: "5px",
        marginBottom: "8px"
    },
    formControl: {
        marginBottom: "5px",
    },
    aboutSection: {
        borderBottom: "1px solid "+ grayColor[6],
        paddingBottom: "20px",
        marginBottom: "25px"
    },
    sortableList: {
        paddingLeft: "0px",
        "& li": {
            listStyleType: "none",
            paddingLeft: "30px",
        }
    },
    sortIcon: {
        position: "absolute",
        left: "12px",
        height: "75px",
        display: "flex",
        alignItems: "center",
        paddingRight: "5px",
        cursor: "move"
    },
    // socialLinksContent: {
    //     "& input": {
    //         textTransform: "lowercase"
    //     }
    // },
    // socialLinkPreview: {
    //     textTransform: "lowercase"
    // }
};

class Step4 extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        
        let birthPickerDate = user.birthdate;
        if(typeof(birthPickerDate) === "string" && birthPickerDate.trim().length > 0){
            try{
                birthPickerDate = birthPickerDate.replace(/-/g, '/');
                birthPickerDate = new Date(birthPickerDate).toString();
            }catch(e){
                console.log(e);
                birthPickerDate = "";
            }
        }

        let topSearchTerms;
        if(!user.hasOwnProperty("topSearchTerms")){
            topSearchTerms = [];
        }else{
            let topSearchTermsStr = user.topSearchTerms.trim();
            let topSearchTermslen = topSearchTermsStr.length;
            if(topSearchTermslen <= 0){
                topSearchTerms = [];
            }else{
                topSearchTerms = user.topSearchTerms.split(',');
            }
        }
        
        if(!values.hasOwnProperty("bio") || values.bio.length <= 0){
            values['bio'] = "";
        }
        let country = user.country ? user.country : "";
        if(country === "United States"){
            country = "US";
        }else if(country === "Canada"){
            country = "CA";
        }
        let socialIcons = Api.getSocialIcons();
        this.state = {
            values: values,
            birthdate: user.birthdate ? user.birthdate : "",
            birthPickerDate: birthPickerDate,
            birthdateState: user.birthdate ? "success":"",
            pronouns: user.pronouns ? user.pronouns : "",
            pronounsItems: [],
            countries: [],
            country: country,
            countryLabel: "",
            countryState: user.country ? "success":"",
            states: [],
            stateProvince: user.stateProvince ? user.stateProvince : "",
            stateProvinceLabel: "",
            stateProvinceState: user.stateProvince ? "success":"",
            city: user.city ? user.city : "",
            googlePlacesResponse: {
                latitude: 0.0,
                longitude: 0.0,
                location: user.city ? user.city : "",
                googlePlacesId: "",
                formatted_address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
            },
            postalCode: user.postalCode ? user.postalCode : "",
            ageLess: false,
            redirectPage: false,
            userLevel: 0,
            topSearchTerms: topSearchTerms,
            tagInputValue: "",
            socialIcons: socialIcons,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons),
            socialIconsValidation: {
                instagramUrl: "",
                facebookUrl: "",
                pinterestUrl: "",
                tiktokUrl: "",
                youtubeUrl: "",
                contactEmail: "",
                twitterUrl: "",
                linkedinUrl: "",
                snapchatUrl: "",
                soundcloudUrl: "",
                whatsappNumber: "",
                twitchUrl: "",
                patreonUrl: "",
                spotifyUrl: "",
                amazonLiveUrl: "",
                amazonShopUrl: "",
                appleMusicUrl: "",
                itunesUrl: "",
                googlePlayUrl: "",
                mediumUrl: "",
                likeToKnowItUrl: "",
            },
            euTransferConsent: user.euTransferConsent,
            euTransferConsentState: user.euTransferConsent ? "success":"",
            showStates: false
        }
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleBlogLinkChange = this.handleBlogLinkChange.bind(this);
        this.handleSocialIcon = this.handleSocialIcon.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.onSocialIconsSort = this.onSocialIconsSort.bind(this);
        this.handleBioChange = this.handleBioChange.bind(this);
        this.onNextStep = this.onNextStep.bind(this);
    }
    componentDidMount(){
        this.updateSocialIconsStatus();
        this.pronounsItems();

        const that = this;
        setTimeout(() => {
            that.props.onMount(that.state);
        },100);

        
    }
    componentWillReceiveProps(props){
        const { allStates } = props;
        if(!allStates || !allStates.hasOwnProperty("account")){
            return;
        }
        if(this.state.userLevel !== allStates.account.userLevel){
            this.setState({
                userLevel: allStates.account.userLevel
            });
        }
    }
    componentDidUpdate(prevProps, prevState){
        const { birthdateState, countryState, stateProvinceState, euTransferConsent, showStates } = this.state;
        const prevbirthdateState = prevState.birthdateState;
        const prevcountryState = prevState.countryState;
        const prevstateProvinceState = prevState.stateProvinceState;
        const preveuTransferConsent = prevState.euTransferConsent;
        const prevshowStates = prevState.showStates;
        if(birthdateState !== prevbirthdateState || countryState !== prevcountryState || stateProvinceState !== prevstateProvinceState || 
            euTransferConsent !== preveuTransferConsent || showStates !== prevshowStates){
                this.onNextStep();
        }
    }
    sendState() {
        return this.state;
    }
    onNextStep(returnStatus = false){
        const { birthdateState, countryState, stateProvinceState, euTransferConsent, showStates } = this.state;
        if (
            birthdateState === "success" &&
            countryState === "success" &&
            (stateProvinceState === "success" || !showStates) && 
            euTransferConsent
        ) {
            this.props.onIsValidChange(false);
        }else {
            this.props.onIsValidChange(true);
        }
    }
    isValidated() {
        if(this.state.birthdate){
            let birthdate = new Date(this.state.birthdate);
            const currentDate = moment(new Date());
            const startDate = moment(birthdate);
            const that = this;

            if(currentDate.diff(startDate, 'years') <= 13){
                window.scrollTo(0,100);
                that.setState({
                    ageLess: true
                })
                setTimeout(function(){
                    that.setState({
                        redirectPage: true
                    });
                },3000);

                Cookie.options.duration = 1;
                Cookie.write("stop_signup",1);
                Cookie.options.duration = false;

                return false;
            }
        }
        if (
            this.state.birthdateState === "success" &&
            this.state.countryState === "success" &&
            (this.state.stateProvinceState === "success" || !this.state.showStates) && 
            this.state.euTransferConsent
        ) {
            window.scrollTo(0,0);
            this.updateUserOnboarding();
            return true;
        }else {
            let state = {
                birthdateState: this.state.birthdateState,
                countryState: this.state.countryState,
                stateProvinceState: this.state.stateProvinceState,
            };
            let updateState = false;
            if (this.state.birthdateState !== "success") {
                state.birthdateState = "error";
                updateState = true;
            }
            if (this.state.countryState !== "success") {
                state.countryState = "error";
                updateState = true;
            }
            if (this.state.stateProvinceState !== "success") {
                state.stateProvinceState = "error";
                updateState = true;
            }
            if (!this.state.euTransferConsent) {
                state.euTransferConsentState = "error";
                updateState = true;
            }
            if(updateState){
                this.setState(state);
            }
        }
        return false;
    }
    change(event, stateName) {
        if(stateName === "postalCode"){
            if (event.target.value) {
                this.setState({ [stateName + "State"]: "success" });
            } else {
                this.setState({ [stateName + "State"]: "error" });
            }
        }

        this.setState({
            [stateName]: event.target.value
        });
    }
    handleBioChange(event, name){
        let value = event.target.value;
        this.setState({
            values: {
                ...this.state.values, 
                [name]: value,
            }
        });
    }
    handleDateChange(date, stateName) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            let birthdate = this.formatDateToString(parsedDate);
            
            let ageLess = false;
            const currentDate = moment(new Date());
            const startDate = moment(parsedDate);
            if(currentDate.diff(startDate, 'years') <= 13){
                ageLess = true;
            }
            parsedDate.toISOString();
            this.setState({
                [stateName]: date ? birthdate : "",
                [stateName + "State"]: (date? "success" : "error"),
                ageLess: ageLess,
                birthPickerDate: date ? parsedDate.toISOString() : "",
            });
        }catch(e){ 
            this.setState({
                [stateName + "State"]: "error"
            });
            //console.log(e); 
        }        
    }
    formatDateToString(date){
        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        var yyyy = date.getFullYear();
     
        return (MM + "-" + dd + "-" + yyyy);
    }
    onSelectChange(event, stateName, showTransferConsent = null, showStates = null, label = null){
        let value = event.target.value;
        if (value) {
            this.setState({ [stateName + "State"]: "success" });
        } else {
            this.setState({ [stateName + "State"]: "error" });
        }

        let euTransferConsent = this.state.euTransferConsent;
        if(showTransferConsent !== null){
            euTransferConsent = showTransferConsent;
        }

        let stateProvinceState = "";
        if(showStates === null){
            showStates = this.state.showStates;
        }
        if(showStates){
            stateProvinceState = "error";
        }
        let stateProvinceLabel = this.state.stateProvinceLabel;
        if(stateName === "stateProvince" && label !== null){
            stateProvinceLabel = label;
        }
        
        if(stateName === "country"){
            this.setState({
                [stateName]: value,
                stateProvince: "",
                stateProvinceState: stateProvinceState,
                euTransferConsent: euTransferConsent,
                showStates: showStates,
                countryLabel: label,
                stateProvinceLabel: null
            });
        }else{
            let state = {
                [stateName]: value,
            };
            if(stateName === "stateProvince"){
                state['stateProvinceLabel'] = stateProvinceLabel;
            }
            this.setState(state);
        }
    }
    onConsentChange(status){
        this.setState({
            euTransferConsent: status,
            euTransferConsentState: (status ? "success" : "error")
        });
    }
    onCountryMount(countryLabel, showTransferConsent){
        this.setState({
            showStates: true,
            countryLabel: countryLabel,
            euTransferConsent: (showTransferConsent === false)
        });
    }
    onStateMount(showStates, stateProvinceLabel){
        this.setState({
            showStates: showStates,
            stateProvinceLabel: stateProvinceLabel,
        });
    }
    clearInput(e, stateName){
        if(stateName === "city"){
            this.setState({
                googlePlacesResponse: {
                    latitude: 0.0,
                    longitude: 0.0,
                    location: "",
                    googlePlacesId: "",
                    formatted_address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    country: "",
                }
            })
        }
        this.setState({
            [stateName]: "",
            [stateName + "Input"]: "",
            [stateName + "State"]: "error"
        });
    }
    handleLocationChange(location){
        this.setState({
            city: location.location,
            googlePlacesResponse: location
        });
    };
    pronounsItems() {
        const that = this;
        Api.pronouns().then(data => {
            that.setState({
                pronounsItems: data,
            });
        });
    }
    handleTags = regularTags => {
        this.setState({
            topSearchTerms: regularTags,
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            topSearchTerms: [...topSearchTerms, tagValue],
            tagInputValue: ""
        })
    }
    handleBlogLinkChange(event, name) {
        let value = event.target.value;
        let { values } = this.state;
        const socialIcons = values.socialIcons;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(socialIcons.hasOwnProperty(name) && socialIcons[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "https://"+value;
            }
        }
        if(value === "https:/"){
            value = "https://";
        }
        values = {
            ...values,
            socialIcons: {
                ...values.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    getDomainName(name){
        let domain = "";
        switch(name){
            case "instagramUrl":
                domain = "instagram"
                break;
            case "facebookUrl":
                domain = "facebook"
                break;
            case "pinterestUrl":
                domain = "pinterest"
                break;
            case "tiktokUrl":
                domain = "tiktok"
                break;
            case "youtubeUrl":
                domain = "youtube"
                break;
            case "twitterUrl":
                domain = "twitter"
                break;
            case "linkedinUrl":
                domain = "linkedin"
                break;
            case "snapchatUrl":
                domain = "snapchat"
                break;
            case "twitchUrl":
                domain = "twitch"
                break;
            case "soundcloudUrl":
                domain = "soundcloud"
                break;
            case "patreonUrl":
                domain = "patreon"
                break;
            case "spotifyUrl":
                domain = "spotify"
                break;
            case "amazonLiveUrl":
                domain = "amazonlive"
                break;
            case "amazonShopUrl":
                domain = "amazonshop"
                break;
            case "appleMusicUrl":
                domain = "applemusic"
                break;
            case "itunesUrl":
                domain = "itunes"
                break;
            case "googlePlayUrl":
                domain = "googleplay"
                break;
            case "mediumUrl":
                domain = "medium"
                break;
            case "likeToKnowItUrl":
                domain = "liketoknow"
                break;
            default:
                domain  = "";
                break;
        }

        return domain;
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        
        let domain = this.getDomainName(name);
        if((name === "tiktokUsername" || name === "mediumUrl") && value.substr(0,1) === "@"){
            value = value.substr(1);
        }
        try{
            new URL(value); 
        }catch(e){
            if((name !== "contactEmail" && name !== "whatsappNumber")  && 
                value.indexOf("http://") === -1 && value.indexOf("https://") === -1
            ){
                value = helper.prepareSocialUrl(domain,value)
            }
        }

        let domainUrl = helper.prepareSocialUrl(domain,'');
        if(value === domainUrl){
            value = null;
        }
        this.setState({
            values: {
                ...this.state.values, 
                // [name]: value,
                socialIcons: {
                    ...this.state.values.socialIcons,
                    [name]: value,
                }
            }
        });
    }
    updateSocialIconsStatus(){
        const { socialIcons } = this.state;
        const { user } = this.store.getState();

        let socialIconsWithStatus = [];
        socialIcons.map((socialIcon) => {
            let status = false;
            let itemName = socialIcon.itemValue;
            if(user.socialIcons.hasOwnProperty(socialIcon.itemValue) === true && user.socialIcons[itemName] !== "" && user.socialIcons[itemName] !== null){
                status = true;
            }
            socialIconsWithStatus.push({
                itemValue: socialIcon.itemValue,
                itemLabel: socialIcon.itemLabel,
                status: status,
            });

            return null;
        })

        this.setState({
            socialIcons: socialIconsWithStatus
        })

    }
    handleSocialIcon(event){
        let value = event.target.value;
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === value)[0];
        socialIcon.status = true;
        const values = {
            ...this.state.values, 
            socialIcons: {
                ...this.state.values.socialIcons,
                [socialIcon.itemValue]: null,
            }
        };

        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        })
    }
    onClear(name){
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === name)[0];
        socialIcon.status = false;

        let socialIcons = this.state.values.socialIcons;
        if(socialIcons.hasOwnProperty(name)){
            delete socialIcons[name];
        }

        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                socialIcons: socialIcons
            },
            sortableSocialIcons: this.prepareSortableSocialIcons(socialIcons)
        });
    }
    renderSocialIcons(){
        const { classes } = this.props;
        const { socialIcons } = this.state;
        return(
            <div className={classes.addSocialIcon}>
                <CustomSelectSocialIcons 
                    formControlProps={{
                        fullWidth: true
                    }}
                    selectProps={{
                        onChange: (e) => this.handleSocialIcon(e),
                        value: ""
                    }}
                    labelText={"Add Social Icon"}
                    inputProps={{
                        name: "type",
                        id: "outlined-type",
                        className: classes.alignLeft,
                    }}
                    items={socialIcons}
                    itemLabel="itemLabel"
                    itemValue="itemValue"
                />
            </div>
        )
    }
    renderSocialFields(){
        const { classes } = this.props;
        const { sortableSocialIcons } = this.state;
        let socialIconsRendered = [];
        sortableSocialIcons.map((socialIcon, key) => {
            if(socialIcon.id === "blogUrl"){
                return null;
            }
            socialIconsRendered.push(
                <li key={key}>
                    <span className={classes.sortIcon+" social_icons_sort"}><Menu /></span>
                    {this.renderSocialField(socialIcon.id)}
                </li>
            );
            return null;
        });
        return socialIconsRendered;
    }
    renderSocialField(type){
        const { classes } = this.props;
        const { values, socialIconsValidation } = this.state;
        let socialField = "";
        switch(type){
            case "instagramUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                        <GridItem className={classes.socialGridItem}>
                            <Button justIcon className="instagram-icon">
                                <img src={instagramIcon} alt="instagram"/>
                            </Button>
                        </GridItem>
                        <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                            <CustomInput
                                id="outlined-instagramUsername"
                                labelText="Instagram Handle"
                                success={socialIconsValidation.instagramUrl === "success"}
                                error={socialIconsValidation.instagramUrl === "error"}
                                inputProps={{
                                    value: values.socialIcons.instagramUrl ? values.socialIcons.instagramUrl : "",
                                    onChange: (e) => this.handleLinkChange(e, 'instagramUrl'),
                                    name: "instagramUrl",
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.formControl
                                }}
                                showClear={true}
                                onClear= {() => this.onClear("instagramUrl")}
                            />                                        
                        </GridItem>
                        <p className={classes.socialLinkPreview}>
                            {
                                values.socialIcons.instagramUrl ?
                                    values.socialIcons.instagramUrl
                                :
                                    helper.prepareSocialUrl("instagram","")
                            }
                        </p>
                    </GridContainer>
                break;
            case "twitterUrl": 
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="twitter" justIcon><i className="fab fa-twitter" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitterUsername"
                            labelText="Twitter Username"
                            success={socialIconsValidation.twitterUrl === "success"}
                            error={socialIconsValidation.twitterUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitterUrl ? values.socialIcons.twitterUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'twitterUrl'),
                                name: "twitterUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("twitterUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitterUrl ?
                                values.socialIcons.twitterUrl
                            :
                                helper.prepareSocialUrl("twitter","")
                        }
                    </p>
                </GridContainer>
                break;
            case "youtubeUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button color="youtube" justIcon><i className="fab fa-youtube" /></Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-youtubeChannelUrl"
                            labelText="Youtube Channel Url"
                            success={socialIconsValidation.youtubeUrl === "success"}
                            error={socialIconsValidation.youtubeUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.youtubeUrl ? values.socialIcons.youtubeUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'youtubeUrl'),
                                name: "youtubeUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("youtubeUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.youtubeUrl ?
                                values.socialIcons.youtubeUrl
                            :
                                helper.prepareSocialUrl("youtube","")
                        }
                    </p>
                </GridContainer>
                break;
            case "tiktokUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={tiktokIcon} alt="tiktok"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-tiktokUsername"
                            labelText="Tiktok Username"
                            success={socialIconsValidation.tiktokUrl === "success"}
                            error={socialIconsValidation.tiktokUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.tiktokUrl ? values.socialIcons.tiktokUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'tiktokUrl'),
                                name: "tiktokUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("tiktokUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.tiktokUrl ?
                                values.socialIcons.tiktokUrl
                            :
                                helper.prepareSocialUrl("tiktok","")
                        }
                    </p>
                </GridContainer>
                break;
            case "facebookUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <FacebookIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-facebookUsername"
                            labelText="Facebook Username"
                            success={socialIconsValidation.facebookUrl === "success"}
                            error={socialIconsValidation.facebookUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.facebookUrl ? values.socialIcons.facebookUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'facebookUrl'),
                                name: "facebookUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("facebookUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.facebookUrl ?
                                values.socialIcons.facebookUrl
                            :
                                helper.prepareSocialUrl("facebook","")
                        }
                    </p>
                </GridContainer>
                break;
            case "pinterestUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <PinterestIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-pinterestUsername"
                            labelText="Pinterest Username"
                            success={socialIconsValidation.pinterestUrl === "success"}
                            error={socialIconsValidation.pinterestUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.pinterestUrl ? values.socialIcons.pinterestUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'pinterestUrl'),
                                name: "pinterestUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("pinterestUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.pinterestUrl ?
                                values.socialIcons.pinterestUrl
                            :
                                helper.prepareSocialUrl("pinterest","")
                        }
                    </p>
                </GridContainer>
                break;
            case "linkedinUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <LinkedinIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-linkedinUsername"
                            labelText="LinkedIn Username"
                            success={socialIconsValidation.linkedinUrl === "success"}
                            error={socialIconsValidation.linkedinUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.linkedinUrl ? values.socialIcons.linkedinUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'linkedinUrl'),
                                name: "linkedinUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("linkedinUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.linkedinUrl ?
                                values.socialIcons.linkedinUrl
                            :
                                helper.prepareSocialUrl("linkedin","")
                        }
                    </p>
                </GridContainer>
                break;
            case "contactEmail":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <MailIcon className={classes.customIcon+" MuiSvgIcon-root"} />
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-contactEmail"
                            labelText="Contact Email"
                            success={socialIconsValidation.contactEmail === "success"}
                            error={socialIconsValidation.contactEmail === "error"}
                            inputProps={{
                                value: values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "",
                                onChange: (e) => this.handleLinkChange(e, 'contactEmail'),
                                name: "contactEmail",
                                type:"email",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("contactEmail")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {"mailto:"+(values.socialIcons.contactEmail ? values.socialIcons.contactEmail : "")}
                    </p>
                </GridContainer>
                break;
            case "snapchatUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={snapchatIcon} alt="snapchat"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-snapchatUsername"
                            labelText="Snapchat Username"
                            success={socialIconsValidation.snapchatUrl === "success"}
                            error={socialIconsValidation.snapchatUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.snapchatUrl ? values.socialIcons.snapchatUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'snapchatUrl'),
                                name: "snapchatUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("snapchatUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.snapchatUrl ?
                                values.socialIcons.snapchatUrl
                            :
                                helper.prepareSocialUrl("snapchat","")
                        }
                    </p>
                </GridContainer>
                break;
            case "soundcloudUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={soundcloudIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-soundcloud"
                            labelText="SoundCloud"
                            success={socialIconsValidation.soundcloudUrl === "success"}
                            error={socialIconsValidation.soundcloudUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.soundcloudUrl ? values.socialIcons.soundcloudUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'soundcloudUrl'),
                                name: "soundcloudUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("soundcloudUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.soundcloudUrl ?
                                values.socialIcons.soundcloudUrl
                            :
                                helper.prepareSocialUrl("soundcloud","")
                        }
                    </p>
                </GridContainer>
                break;
            case "whatsappNumber":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={whatsappIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-whatsappNumber"
                            labelText="WhatsApp"
                            success={socialIconsValidation.whatsappNumber === "success"}
                            error={socialIconsValidation.whatsappNumber === "error"}
                            inputProps={{
                                value: values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "",
                                onChange: (e) => this.handleLinkChange(e, 'whatsappNumber'),
                                name: "whatsappNumber",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("whatsappNumber")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {(values.socialIcons.whatsappNumber ? values.socialIcons.whatsappNumber : "+00000000000")}
                    </p>
                </GridContainer>
                break;
            case "twitchUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={twitchIcon} alt="twitch"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-twitch"
                            labelText="Twitch"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.twitchUrl ? values.socialIcons.twitchUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'twitchUrl'),
                                name: "twitchUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("twitchUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.twitchUrl ?
                                values.socialIcons.twitchUrl
                            :
                                helper.prepareSocialUrl("twitch","")
                        }
                    </p>
                </GridContainer>
                break;
            case "patreonUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={patreonIcon} alt="patreon"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-patreon"
                            labelText="Patreon"
                            success={socialIconsValidation.twitchUrl === "success"}
                            error={socialIconsValidation.twitchUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.patreonUrl ? values.socialIcons.patreonUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'patreonUrl'),
                                name: "patreonUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("patreonUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.patreonUrl ?
                                values.socialIcons.patreonUrl
                            :
                                helper.prepareSocialUrl("patreon","")
                        }
                    </p>
                </GridContainer>
                break;
            case "spotifyUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={spotifyIcon} alt="spotify"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-spotify"
                            labelText="Spotify"
                            success={socialIconsValidation.spotifyUrl === "success"}
                            error={socialIconsValidation.spotifyUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.spotifyUrl ? values.socialIcons.spotifyUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'spotifyUrl'),
                                name: "spotifyUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("spotifyUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.spotifyUrl ?
                                values.socialIcons.spotifyUrl
                            :
                                helper.prepareSocialUrl("spotify","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonLiveUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonLiveIcon} alt="amazon live"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonLive"
                            labelText="Amazon Live"
                            success={socialIconsValidation.amazonLiveUrl === "success"}
                            error={socialIconsValidation.amazonLiveUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonLiveUrl ? values.socialIcons.amazonLiveUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'amazonLiveUrl'),
                                name: "amazonLiveUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("amazonLiveUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonLiveUrl ?
                                values.socialIcons.amazonLiveUrl
                            :
                                helper.prepareSocialUrl("amazonlive","")
                        }
                    </p>
                </GridContainer>
                break;
            case "amazonShopUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={amazonShopIcon} alt="amazon shop"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-amazonShop"
                            labelText="Amazon Shop"
                            success={socialIconsValidation.amazonShopUrl === "success"}
                            error={socialIconsValidation.amazonShopUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.amazonShopUrl ? values.socialIcons.amazonShopUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'amazonShopUrl'),
                                name: "amazonShopUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("amazonShopUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.amazonShopUrl ?
                                values.socialIcons.amazonShopUrl
                            :
                                helper.prepareSocialUrl("amazonshop","")
                        }
                    </p>
                </GridContainer>
                break;
            case "appleMusicUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={appleMusicIcon} alt="apple music"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-appleMusic"
                            labelText="Apple Music"
                            success={socialIconsValidation.appleMusicUrl === "success"}
                            error={socialIconsValidation.appleMusicUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.appleMusicUrl ? values.socialIcons.appleMusicUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'appleMusicUrl'),
                                name: "appleMusicUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("appleMusicUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.appleMusicUrl ?
                                values.socialIcons.appleMusicUrl
                            :
                                helper.prepareSocialUrl("applemusic","")
                        }
                    </p>
                </GridContainer>
                break;
            case "itunesUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={itunesIcon} alt="itunes"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-itunesUrl"
                            labelText="itunes"
                            success={socialIconsValidation.itunesUrl === "success"}
                            error={socialIconsValidation.itunesUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.itunesUrl ? values.socialIcons.itunesUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'itunesUrl'),
                                name: "itunesUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("itunesUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.itunesUrl ?
                                values.socialIcons.itunesUrl
                            :
                                helper.prepareSocialUrl("itunes","")
                        }
                    </p>
                </GridContainer>
                break;
            case "googlePlayUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={googlePlayIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-googlePlayUrl"
                            labelText="Google Play"
                            success={socialIconsValidation.googlePlayUrl === "success"}
                            error={socialIconsValidation.googlePlayUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.googlePlayUrl ? values.socialIcons.googlePlayUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'googlePlayUrl'),
                                name: "googlePlayUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("googlePlayUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.googlePlayUrl ?
                                values.socialIcons.googlePlayUrl
                            :
                                helper.prepareSocialUrl("googleplay","")
                        }
                    </p>
                </GridContainer>
                break;
            case "mediumUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={mediumIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-mediumUrl"
                            labelText="Medium"
                            success={socialIconsValidation.mediumUrl === "success"}
                            error={socialIconsValidation.mediumUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.mediumUrl ? values.socialIcons.mediumUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'mediumUrl'),
                                name: "mediumUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("mediumUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.mediumUrl ?
                                values.socialIcons.mediumUrl
                            :
                                helper.prepareSocialUrl("medium","")
                        }
                    </p>
                </GridContainer>
                break;
            case "likeToKnowItUrl":
                socialField = <GridContainer justify="flex-start" alignItems="flex-end">
                    <GridItem className={classes.socialGridItem}>
                        <Button justIcon className="instagram-icon">
                            <img src={likeToKnowItIcon} alt="google play"/>
                        </Button>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} className={classes.responsiveIconsGrid}>
                        <CustomInput
                            id="outlined-likeToKnowItUrl"
                            labelText="Like to Know It"
                            success={socialIconsValidation.likeToKnowItUrl === "success"}
                            error={socialIconsValidation.likeToKnowItUrl === "error"}
                            inputProps={{
                                value: values.socialIcons.likeToKnowItUrl ? values.socialIcons.likeToKnowItUrl : "",
                                onChange: (e) => this.handleLinkChange(e, 'likeToKnowItUrl'),
                                name: "likeToKnowItUrl",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                            showClear={true}
                            onClear= {() => this.onClear("likeToKnowItUrl")}
                        />
                    </GridItem>
                    <p className={classes.socialLinkPreview}>
                        {
                            values.socialIcons.likeToKnowItUrl ?
                                values.socialIcons.likeToKnowItUrl
                            :
                                helper.prepareSocialUrl("liketoknow","")
                        }
                    </p>
                </GridContainer>
                break;
            default :
                socialField = <></>;
                break;
        }
        return socialField;
    }
    onSocialIconsSort(socialIcons){
        const socialIconsOld = this.state.values.socialIcons;
        let socialIconsNewOrder = {};
        socialIcons.map((socialIcon) => {
            socialIconsNewOrder[socialIcon.id] = socialIconsOld[socialIcon.id];
            return null;
        });
        this.setState({
            sortableSocialIcons: socialIcons,
            values: {
                ...this.state.values,
                socialIcons: socialIconsNewOrder
            }
        });
    }
    prepareSortableSocialIcons(socialIcons){
        let sortableSocialIcons = [];
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            sortableSocialIcons.push({
                id: socialIcon,
                value: socialIcons[socialIcon],
                selected: false,
                chosen: false,
                filtered: false
            })
        }
        return sortableSocialIcons;
    }
    validateForm(){
        const { socialIcons } = this.state.values;
        if(typeof(socialIcons) !== "object"){
            return;
        }
        let isValid = true;
        let validation = this.state.socialIconsValidation;
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            validation[socialIcon] = "success";
            const value = socialIcons[socialIcon];
            if(!value || value === "" || value === "https://"){
                validation[socialIcon] = "error";
                isValid = false;
            }
        }
        this.setState({socialIconsValidation: validation});
        return isValid;
    }
    updateUserOnboarding(){
        const { user } = this.store.getState();
        if(user.userPreferences.hasOwnProperty("onboarding") && user.userPreferences.onboarding.hasOwnProperty("about") && 
            user.userPreferences.onboarding.about){
            return;
        }
        if(!user.userPreferences.hasOwnProperty("onboarding")){
            user.userPreferences['onboarding'] = {};
        }
        user.userPreferences.onboarding['about'] = true;
        Api.updateUser(user.userId, user).then((data) => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    render() {
        if(this.state.redirectPage){
            return <Redirect to='/logout' />
        }
        const { user } = this.store.getState();
        const { classes } = this.props;
        const { birthdateState, values, ageLess, pronouns, country, countryState, sortableSocialIcons, 
            stateProvince, stateProvinceState, postalCode, city, birthPickerDate, userLevel, pronounsItems, 
            euTransferConsentState, showStates } = this.state;
        const { socialIcons } = this.state.values;
        let blogUrl = "https://";
        if(socialIcons.hasOwnProperty("blogUrl") && socialIcons.blogUrl.length > 0){
            blogUrl = socialIcons.blogUrl;
        }
        return (
            <GridContainer justify="center" className={classes.demographicsContent+" fs-block"}>
                <GridItem xs={12} sm={12}>
                    {
                        userLevel !== 0 && false ? //No need for now
                            <div className={classes.aboutSection}>
                                <h4 className={classes.infoText}>What would you like displayed on your About Me Page?</h4>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={10} md={6}>
                                        <CustomInput
                                            id="outlined-blogUrl"
                                            labelText="Website Url"                                    
                                            inputProps={{
                                                value: blogUrl,
                                                onChange: (e) => this.handleBlogLinkChange(e, 'blogUrl'),
                                                name: "blogUrl",
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.formControl
                                            }}
                                        />
                                        <p className={classes.socialLinkPreview}>
                                            {blogUrl}
                                        </p>

                                        <div className={classes.socialLinksContent}>
                                            <div className={classes.socialLinks}>
                                                <label className={classes.socialLinksTitle}>
                                                    Enter your social links that will appear on your About Me page
                                                </label>
                                            </div>
                                            <ReactSortable
                                                tag="ul"
                                                handle=".social_icons_sort"
                                                direction="horizontal"
                                                dragoverBubble={true}
                                                animation={150}
                                                className={classes.sortableList} 
                                                list={sortableSocialIcons}
                                                setList={this.onSocialIconsSort}
                                            >
                                                {this.renderSocialFields()}
                                            </ReactSortable>
                                            {this.renderSocialIcons()}
                                        </div>

                                        <CustomInput
                                            labelText="Tell us a little about yourself"
                                            id="input-bio"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: values.bio,
                                                onChange: (e) => this.handleBioChange(e, 'bio'),
                                                multiline: true,
                                                rows: 4,
                                            }}
                                        />
                                        <CustomTagsInput
                                            labelText= {
                                                <span>
                                                    What should your audience search you for? <span className={classes.lineBreak}></span> (For example, "pizza")
                                                </span>
                                            }
                                            tagProps={{
                                                value: this.state.topSearchTerms,
                                                onChange: this.handleTags,
                                            }}
                                            inputProps={{
                                                placeholder: "Add a search term",
                                                onBlur: this.handleTagsChange,
                                                onChange: this.handleTagInput,
                                                value: this.state.tagInputValue,
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.tagsFormControl
                                            }}
                                            color={(user.userLevel >= 1 ? user.guestPreferences.primaryColor: "primary")}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        :
                            <></>
                    }
                    <div className={classes.informationSection}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={6}>
                                <h4 className={classes.infoText}>The following personal information is used to help us comply with local privacy and security regulations.</h4>
                                <DatePicker
                                    success={birthdateState === "success" ? "true" : ""}
                                    error={birthdateState  === "error" || ageLess}
                                    labelText={
                                        <span>
                                            Birthdate (MM-DD-YYYY) <small>(required)</small><MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                        </span>
                                    }
                                    disableFuture= {true}
                                    id="info-birthdate"
                                    value= {birthPickerDate}
                                    onChange={(date) => this.handleDateChange(date, 'birthdate')}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                {
                                    ageLess ?
                                        <Danger>This site is not for children. Come back when you are older!</Danger>
                                    :
                                    <></>
                                }
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    selectProps={{
                                        onChange: (e) => this.change(e, 'pronouns'),
                                        value: pronouns
                                    }}
                                    labelText={
                                        <span>
                                            Pronouns <MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                        </span>
                                    }
                                    inputProps={{
                                        name: "pronouns",
                                        id: "outlined-pronouns",
                                        className: classes.alignLeft
                                    }}
                                    items={pronounsItems}
                                />
                                <CustomCountry 
                                    success={countryState === "success"}
                                    error={countryState === "error"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    selectProps={{
                                        onChange: (e, showTransferConsent, showStates, label) => this.onSelectChange(e, 'country', showTransferConsent, showStates, label),
                                        value: country
                                    }}
                                    consentInputProps={{
                                        error: (euTransferConsentState === "error"),
                                        value: this.state.euTransferConsent
                                    }}
                                    onConsentChange={(status) => this.onConsentChange(status)}
                                    onMount={(countryLabel, showTransferConsent) => this.onCountryMount(countryLabel, showTransferConsent)}
                                    labelText={
                                        <span>
                                            Country <small>(required)</small><MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                        </span>
                                    }
                                    inputProps={{
                                        name: "country",
                                        id: "outlined-country",
                                        className: classes.alignLeft
                                    }}
                                />
                                {
                                    showStates ?
                                        <CustomCountryState 
                                            success={stateProvinceState === "success"}
                                            error={stateProvinceState === "error"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            country={country}
                                            selectProps={{
                                                onChange: (e, label) => this.onSelectChange(e, 'stateProvince', null, null, label),
                                                value: stateProvince
                                            }}
                                            onMount={(showStates, stateProvinceLabel) => this.onStateMount(showStates, stateProvinceLabel)}
                                            labelText={
                                                <span>
                                                    State/Province <small>(required)</small><MaterialIcon path={mdiLockOutline} className="MuiSvgIcon-root private_icon info_tooltip_icon" />
                                                </span>
                                            }
                                            inputProps={{
                                                name: "stateProvince",
                                                id: "outlined-stateProvince",
                                                className: classes.alignLeft
                                            }}
                                        />
                                    :
                                    <></>
                                }
                                <CustomInput
                                    id="info-city"
                                    labelText="City"
                                    inputProps={{
                                        value: city,
                                        onChange: event => this.change(event, "city"),
                                        name: "city",
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    showPrivateIcon={true}
                                />
                                <CustomInput
                                    id="info-postalcode"
                                    labelText= {
                                        <span>
                                            Postal Code
                                        </span>
                                    }
                                    inputProps={{
                                        value: postalCode,
                                        onChange: event => this.change(event, "postalCode"),
                                        name: "postalCode",
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    showPrivateIcon={true}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step4.defaultProps = {
    onMount: function(){ }
}
Step4.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step4);
