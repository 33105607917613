import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import contactConfirmationModalStyle from "../../../assets/jss/auth/contactConfirmationModalStyle.jsx";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ContactConfirmationModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                >
                <DialogTitle
                    id="contact-confirmation-modal"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <h4 className={classes.modalTitle}>Thank You - We will get back to you soon!</h4>                     
                </DialogTitle>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <Button
                        round
                        onClick={() => this.props.onClose()}
                    >
                        Ok
                    </Button>
                </DialogActions>
                
            </Dialog>
        )
    }
}

ContactConfirmationModal.defaultProps = {
    open: false
}
ContactConfirmationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(contactConfirmationModalStyle)(ContactConfirmationModal);