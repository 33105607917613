/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React from "react";
import { matchPath } from 'react-router';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link as RouterLink } from "react-router-dom";
import Config from '../../../../Config';
import Api from '../../../assets/js/utils/Api'
import { helper } from '../../../assets/js/utils/Element'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from '@material-ui/core/Link';

// @material-ui/icons
import Layers from "@material-ui/icons/Layers";
import LibraryAdd from "@material-ui/icons/LibraryAdd";
import HomeIcon from "@material-ui/icons/Home";
import Language from "@material-ui/icons/Language";
import FingerPrint from "@material-ui/icons/Fingerprint";
import AccountBox from "@material-ui/icons/AccountBox";
import { ReactComponent as LoginIcon } from "../../../assets/icons/signin.svg";
import { ReactComponent as SignupIcon } from "../../../assets/icons/signup.svg";
import { ReactComponent as SettingIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as HelpIcon } from "../../../assets/icons/help.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info_icon.svg";
import { ReactComponent as CirclesIcon } from "../../../assets/icons/circle.svg";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import FeedbackIcon from "@material-ui/icons/Comment";
import FeedbackModal from "../FeedbackModal";

import { ReactComponent as FeedViewIcon } from "../../../assets/icons/feed_view-2.svg";
import { ReactComponent as ListViewIcon } from "../../../assets/icons/list_view-1.svg";

import MaterialIcon from "@mdi/react";
import { mdiViewGrid, mdiEyeSettingsOutline, mdiGrid, mdiViewDashboard } from '@mdi/js';

// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown.jsx";
import Button from "../CustomButtons/Button.jsx";
import headerLinksStyle from "../../../assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import christian from "../../../assets/img/default-avatar.png";
import {
    grayColor,
    roseColor,
    primaryColor,
    infoColor,
    successColor,
} from "../../../assets/jss/material-kit-pro-react.jsx";

class HeaderLinks extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        const { authorized, user } = this.store.getState();
        let viewType = 1;
        if(authorized && user.hasOwnProperty("userPreferences")){
            const userPref = user.userPreferences;
            const defaultViewType = userPref.searchViewResults;
            viewType = 1;
            if(defaultViewType === "stream"){
                viewType = 0;
            }else if(defaultViewType === "list"){
                viewType = 1;
            }else if(defaultViewType === "grid"){
                viewType = 2;
            }else if(defaultViewType === "threecolumngrid"){
                viewType = 3;
            }else if(defaultViewType === "card"){
                viewType = 4;
            }
        }
        
        this.primaryColor = null;
        this.state = {
            color: this.props.color,
            user: this.getUser(),
            feedbackMode: false,
            viewType: viewType,
            cancelToken: null,
        };
        this.headerColorChange = this.headerColorChange.bind(this);
        this.handleViewOptions = this.handleViewOptions.bind(this);
    }
    componentWillReceiveProps(props){
        this.primaryColor = null;
        this.setState({
            color: props.color,
            mobileOpen: props.open,
            user: this.getUser()
        });
        if (this.props.changeColorOnScroll) {
            const that = this;
            setTimeout(function(){ that.headerColorChange(); },100);
        }
    }
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
            this.headerColorChange();
        }
    }
    headerColorChange() {
        const { color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            this.setState({color: changeColorOnScroll.color});
        } else {
            this.setState({color: color});
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }
    isInstagramBrowser(){
        if(navigator.userAgent.indexOf("Instagram") !== -1){
            return true;
        }
        return false;
    }
    getUser(){
        const { user, authorized, searchedUser, url } = this.store.getState();
        let searchUser = null;
        let searchMatch = matchPath(url, {
            path: "/:username",
        });
        if(authorized && searchMatch !== null && searchMatch.params.hasOwnProperty("username") && searchMatch.params.username.toLowerCase() === user.username.toLowerCase()){
            searchUser = user;
        }
        
        if(searchedUser !== false && searchMatch !== null && searchMatch.params.hasOwnProperty("username")){
            searchUser = null;
            if(searchedUser !== null && searchMatch.params.username.toLowerCase() === searchedUser.username.toLowerCase()){
                searchUser = searchedUser;
            }
        }
        return searchUser;
    }
    getPrimaryColor(){
        if(this.primaryColor !== null){
            return this.primaryColor;
        }
        const { palette } = this.props.theme;
        this.primaryColor = palette.custom.main;
        return palette.custom.main;
    }
    onSocialLogin(e, url){
        e.preventDefault();
        const queryString = window.location.search;
        if(queryString.length > 1){
            localStorage.setItem("searchmysocial_query_params", queryString);
        }
        this.props.onClose(false);
        window.location.href = url;
    }
    getDropdownMenu(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const loginUrl = Config.getLoginUrl();
        const signupUrl = Config.getSignupUrl();
        let menuItems = [];
        const frontAppUrl = Config.getFrontEndUrl();
        if(authorized){
            menuItems.push(<RouterLink to="/user/home" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Home</RouterLink>);
            menuItems.push(<Link href={frontAppUrl+"/"+user.username} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Search</Link>);
            menuItems.push(<RouterLink to="/profile/location" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Set Your Location</RouterLink>);
            menuItems.push(<RouterLink to="/profile/paymentmethod" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Mange Your Payment</RouterLink>);
            
            menuItems.push(<RouterLink to="/logout" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Logout</RouterLink>);
            return menuItems;
        }
        let target = "";
        if(this.isInstagramBrowser()){
            target = "_blank";
        }
        menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={(e) => {e.preventDefault(); this.props.onLogin()}}>Sign In</Link>);
        menuItems.push(<Link href={"/"} className={classes.dropdownLink} onClick={(e) => {e.preventDefault(); this.props.onSignup()}}>Sign Up</Link>);
        if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            menuItems.push(<Link href={loginUrl} target={target} className={classes.dropdownLink} >Sign In (Hosted)</Link>);
            menuItems.push(<Link href={signupUrl} target={target} className={classes.dropdownLink} >Sign Up (Hosted)</Link>);
        }
        return menuItems;
    }
    getProfileMenu(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let menuItems = [];
        const frontAppUrl = Config.getFrontEndUrl();
        menuItems.push(<Link href={frontAppUrl+"/"+user.username} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View Your Home Page</Link>);
        if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            //menuItems.push(<RouterLink to={"/profile"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View Your Profile Page</RouterLink>);
        }       
        menuItems.push(<Link href={frontAppUrl+"/"+user.username+"/about"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View Your About Me Page</Link>);
        menuItems.push(<RouterLink to="/dashboard/profile/info" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Edit Your Profile</RouterLink>);
        // if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
        //     menuItems.push(<RouterLink to="/dashboard/profile/featured" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Managed Featured Links</RouterLink>);
        // }
        // menuItems.push(<RouterLink to="/dashboard/profile/home" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Setup Home Page</RouterLink>);
        // menuItems.push(<RouterLink to="/dashboard/profile/about" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Setup About Me Page</RouterLink>);
        // menuItems.push(<RouterLink to="/dashboard/profile/styling" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Edit Themes</RouterLink>);
        return menuItems;
    }
    getDashboardMenu(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let menuItems = [];
        menuItems.push(<RouterLink to={"/dashboard/editmode/search"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Go to Dashboard</RouterLink>);
        menuItems.push(<RouterLink to={"/dashboard/platforms"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View/Edit Your Platforms</RouterLink>);
        if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            menuItems.push(<RouterLink to="/dashboard/robots" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View/Edit Your Robots</RouterLink>);
            menuItems.push(<RouterLink to="/dashboard/analytics" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View Analytics</RouterLink>);
        }
        return menuItems;
    }
    getCircleMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/circles"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Search Your Circles</RouterLink>);
        menuItems.push(<RouterLink to={"/circles"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>View Your Circles</RouterLink>);
        menuItems.push(<RouterLink to="/circles" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Edit Your Circles</RouterLink>);
        return menuItems;
    }
    getSettingsMenu(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let menuItems = [];
        menuItems.push(<RouterLink to={"/dashboard/account"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Account Settings</RouterLink>);
        menuItems.push(<RouterLink to={"/dashboard/settings"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Application Settings</RouterLink>);
        if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            menuItems.push(<RouterLink to={"/"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Notification Settings</RouterLink>);
            menuItems.push(<RouterLink to="/" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Login Settings</RouterLink>);
        }        
        return menuItems;
    }
    getInfoMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<Link href="https://searchmysocial.media/" target="_blank" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Search My Social</Link>);
        menuItems.push(<Link href="https://www.smearch.com/" target="_blank" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Smearch</Link>);
        menuItems.push(<RouterLink to="/help/privacy" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Privacy</RouterLink>);
        menuItems.push(<RouterLink to="/help/terms" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Terms</RouterLink>);
        menuItems.push(<RouterLink to="/help/cookies" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Cookie Policy</RouterLink>);
        menuItems.push(<Link href="https://searchmysocial.zendesk.com/hc/en-us/sections/360007565451-Release-Notes" target="_blank" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>What's New</Link>);
        menuItems.push(<Link href="https://status.smearch.com/" target="_blank" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>System Status</Link>);
        return menuItems;
    }
    getHelpMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/dashboard/help/faqs"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>FAQs</RouterLink>);
        menuItems.push(<RouterLink to={"/dashboard/help/videos"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Help Videos</RouterLink>);
        menuItems.push(<RouterLink to={"/dashboard/help/contact"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Contact us</RouterLink>);
        return menuItems;
    }
    getVisitorHelpMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/help/faqs"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>FAQs</RouterLink>);
        menuItems.push(<RouterLink to={"/help/videos"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Help Videos</RouterLink>);
        menuItems.push(<RouterLink to={"/help/contact"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>Contact us</RouterLink>);
        return menuItems;
    }
    getLoginMenu(){
        const { classes } = this.props;
        const { user } = this.store.getState();
        const returnUrl = "/login";
        let menuItems = [];
        if(Config.isHostedAuthEnabled()){
            const loginUrl = Config.getLoginUrl(returnUrl);
            menuItems.push(<Link href={loginUrl} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>With Password</Link>);
        }else{
            menuItems.push(<RouterLink to={"/login"} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>With Password</RouterLink>);
        }
        if(user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            menuItems.push(<RouterLink to={"/login"} className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>With Email Link</RouterLink>);
            menuItems.push(<RouterLink to="/login" className={classes.dropdownLink} onClick={() => this.props.onClose(false)}>With Text Link</RouterLink>);
        }
        
        
        const amazonUrl = Config.getAmazonLoginUrl(returnUrl);
        const googleUrl = Config.getGoogleLoginUrl(returnUrl);
        const facebookUrl = Config.getFacebookLoginUrl(returnUrl);

        menuItems.push(<Link href={amazonUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, amazonUrl)}}>With Amazon</Link>);
        menuItems.push(<Link href={googleUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, googleUrl)}}>With Google</Link>);
        menuItems.push(<Link href={facebookUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, facebookUrl)}}>With Facebook</Link>);
        if(user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
            menuItems.push(<Link href="/" className={classes.dropdownLink} onClick={(e) => {e.preventDefault(); this.props.onLoginOptions(false)}}>Why So Many Options?</Link>);
        }
        return menuItems;
    }
    getSignupMenu(){
        const returnUrl = "/signup";
        const amazonUrl = Config.getAmazonLoginUrl(returnUrl);
        const googleUrl = Config.getGoogleLoginUrl(returnUrl);
        const facebookUrl = Config.getFacebookLoginUrl(returnUrl);
        const { classes } = this.props;
        let menuItems = [];
        if(Config.isHostedAuthEnabled()){
            const signupUrl = Config.getSignupUrl(returnUrl);
            menuItems.push(<Link href={signupUrl} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>With Email</Link>);
        }else{
            menuItems.push(<RouterLink to={"/signup"} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>With Email</RouterLink>);
        }
        menuItems.push(<Link href={amazonUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, amazonUrl)}}>With Amazon</Link>);
        menuItems.push(<Link href={googleUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, googleUrl)}}>With Google</Link>);
        menuItems.push(<Link href={facebookUrl} className={classes.dropdownLink} onClick={(e) => {this.onSocialLogin(e, facebookUrl)}}>With Facebook</Link>);
        return menuItems;
    }
    getDesktopMenu(){
        let buttonColor = "inherit";
        if(this.state.color === "transparent" && this.state.user !== null){
            buttonColor = this.getPrimaryColor();
        }
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let defaultAvatar = christian;
        if(user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlIcon;
        }
        let menuItems = [];
        const frontAppUrl = Config.getFrontEndUrl();
        if(authorized){
            menuItems.push(<RouterLink to="/dashboard/editmode/search" className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                <Button justIcon color="transparent" className={classes.iconButton} style={{color: buttonColor}}>
                    <LibraryAdd />
                </Button>                
            </RouterLink>);
            if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
                menuItems.push(<RouterLink to="/circles" className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                    <Button justIcon color="transparent" className={classes.iconButton} style={{color: buttonColor}}>
                        <Language />
                    </Button>                
                </RouterLink>);
            }
            if(authorized && false){
                menuItems.push(
                    <div className={classes.dropdownGridItem}>
                        <CustomDropdown
                            buttonText={<MaterialIcon path={mdiEyeSettingsOutline} className="MuiSvgIcon-root" />}
                            caret={false}
                            buttonProps={{
                                justIcon: true,
                                block: true,
                                color: "transparent",
                                className: classes.iconButton,
                                style: {color: buttonColor} 
                            }}
                            onClick={this.handleViewOptions}
                            popperProps={{
                                className: classes.viewOptionsDropdown+" "+ (user.userPreferences.showBetaFeatures ? classes.viewOptionsDropdownBeta : "")
                            }}
                            selected={this.state.viewType}
                            dropPlacement="bottom"
                            dropdownList={this.getViewOptionsDropdown()}
                        />
                    </div>
                );
            }
            menuItems.push(<Link href={frontAppUrl+"/"+user.username+"/about"} className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                <Button justIcon color="transparent" className={classes.iconButton}>
                    <img src={defaultAvatar} alt={user.username} className={classes.userAvatar} />
                </Button>                
            </Link>);
            return menuItems;
        }
        let target = "";
        if(this.isInstagramBrowser()){
            target = "_blank";
        }
        if(Config.isHostedAuthEnabled()){
            const signupUrl = Config.getSignupUrl("/signup");
            const loginUrl = Config.getLoginUrl("/login");
            menuItems.push(<Link href={loginUrl} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}} style={{color: buttonColor}}>
                <LoginIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign In
            </Link>);
            menuItems.push(<Link href={signupUrl} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}} style={{color: buttonColor}}>
                <SignupIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign Up
            </Link>);
        }else{
            menuItems.push(<RouterLink to={"/login"} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}} style={{color: buttonColor}}>
                <LoginIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign In
            </RouterLink>);
            menuItems.push(<RouterLink to={"/signup"} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}} style={{color: buttonColor}}>
                <SignupIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign Up
            </RouterLink>);
        }
        return menuItems;
    }
    getBottomBarMenu(){
        let buttonColor = "inherit";
        if(this.state.color === "transparent" && this.state.user !== null){
            buttonColor = this.getPrimaryColor();
        }
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let defaultAvatar = christian;
        if(user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlIcon;
        }
        let menuItems = [];
        const frontAppUrl = Config.getFrontEndUrl();
        if(authorized){
            const cdn = Config.getCdnUrl();
            // const logoUrl = cdn+"/app/icon_trans393x551.png";
            menuItems.push(<Link href={frontAppUrl+"/"+user.username} className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                <Button justIcon color="transparent" className={classes.iconButton}>
                    <HomeIcon />
                </Button>                
            </Link>);
            if(authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures){
                menuItems.push(<RouterLink to="/circles" className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                    <Button justIcon color="transparent" className={classes.iconButton}>
                        <Language />
                    </Button>                
                </RouterLink>);
            }
            menuItems.push(<RouterLink to="/dashboard/editmode/search" className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                <Button justIcon color="transparent" className={classes.iconButton}>
                    <LibraryAdd />
                </Button>                
            </RouterLink>);
            if(authorized && false){
                menuItems.push(
                    <div className={classes.dropdownGridItem}>
                        <CustomDropdown
                            buttonText={<MaterialIcon path={mdiEyeSettingsOutline} className="MuiSvgIcon-root" />}
                            caret={false}
                            buttonProps={{
                                justIcon: true,
                                block: true,
                                color: "transparent",
                                className: classes.iconButton,
                            }}
                            onClick={this.handleViewOptions}
                            popperProps={{
                                className: classes.viewOptionsDropdown+" "+ (user.userPreferences.showBetaFeatures ? classes.viewOptionsDropdownBeta : "")
                            }}
                            selected={this.state.viewType}
                            dropPlacement="bottom"
                            dropdownList={this.getViewOptionsDropdown()}
                        />
                    </div>
                );
            }
            menuItems.push(<Link href={frontAppUrl+"/"+user.username+"/about"} className={classes.dropdownLink+" "+classes.dropdownLinkIcon}>
                <Button justIcon color="transparent" className={classes.iconButton}>
                    <img src={defaultAvatar} alt={user.username} className={classes.userAvatar} />
                </Button>                
            </Link>);
            return menuItems;
        }
        let target = "";
        if(this.isInstagramBrowser()){
            target = "_blank";
        }
        if(Config.isHostedAuthEnabled()){
            const signupUrl = Config.getSignupUrl("/signup");
            const loginUrl = Config.getLoginUrl("/login");
            menuItems.push(<Link href={loginUrl} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>
                <LoginIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign In
            </Link>);
            menuItems.push(<Link href={signupUrl} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>
                <SignupIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign Up
            </Link>);
        }else{
            menuItems.push(<RouterLink to={"/login"} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>
                <LoginIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign In
            </RouterLink>);
            menuItems.push(<RouterLink to={"/signup"} target={target} className={classes.dropdownLink} onClick={(e) => {this.props.onClose(false)}}>
                <SignupIcon className={classes.customIcon+" MuiSvgIcon-root"} />Sign Up
            </RouterLink>);
        }
        return menuItems;
    }
    handleViewOptions(param, key){
        const { authorized, user} = this.store.getState();
        let cancelToken = this.state.cancelToken;
        if(authorized){
            user.userPreferences.searchViewResults = "stream";
            if(key === 1){
                user.userPreferences.searchViewResults = "list";
            }
            if(key === 2){
                user.userPreferences.searchViewResults = "grid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 3){
                user.userPreferences.searchViewResults = "threecolumngrid";
            }
            if(key === 4){
                user.userPreferences.searchViewResults = "card";
            }
            Api.updateUser(user.userId, user).then((data) => {
                if(data.numberOfResponses > 0){
                    // this.store.dispatch({type: 'UPDATE_STATE',state: {user: data.response[0] }});
                }
            });
        }

        this.setState({
            viewType: key,
            cancelToken: cancelToken
        });

        setTimeout(function(){
            helper.checkElementInViewPort();
        },500);
    }
    getViewOptionsDropdown(){
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const authorizedUser = this.store.getState().user;
        var showBetaFeatures = (authorized && authorizedUser.hasOwnProperty("userPreferences") && authorizedUser.userPreferences.showBetaFeatures);
        let options = [
            <Button type="button" color="transparent" justIcon>
                <FeedViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <ListViewIcon className={classes.customIcon+" MuiSvgIcon-root"} />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiViewGrid} className="MuiSvgIcon-root" />
            </Button>,
            <Button type="button" color="transparent" justIcon>
                <MaterialIcon path={mdiGrid} className="MuiSvgIcon-root" />
            </Button>
        ];
        if(showBetaFeatures){
            options.push(
                <Button type="button" color="transparent" justIcon>
                    <MaterialIcon path={mdiViewDashboard} className="MuiSvgIcon-root" />
                </Button>
            );
        }
        return options;
    }
    render(){
        const { authorized, user } = this.store.getState();
        const { feedbackMode } = this.state;
        let defaultAvatar = christian;
        if(user.hasOwnProperty("avatarMedia")){
            defaultAvatar = user.avatarMedia.downloadUrlIcon;
        }
        const { classes, dropdownHoverColor, desktop, bottomBar } = this.props;
        const chromeExtensionUrl = Config.getChromeExtensionUrl();

        if(desktop){
            return (
                <List className={classes.list + " " + classes.mlAuto}>
                    {
                        this.getDesktopMenu().map((menuItem, key) => {
                            return (
                                <ListItem className={classes.listItem} key={key}>
                                    {menuItem}
                                </ListItem>
                            )
                        })
                    }
                </List>
            );
        }

        if(bottomBar && !authorized){
            return null;
        }
        if(bottomBar){
            return (
                <List className={classes.list + " " + classes.mlAuto}>
                    {
                        this.getBottomBarMenu().map((menuItem, key) => {
                            return (
                                <ListItem className={classes.listItem} key={key}>
                                    {menuItem}
                                </ListItem>
                            )
                        })
                    }
                </List>
            );
        }

        return (
            <List className={classes.list + " " + classes.mlAuto}>
                {
                    authorized ?
                        <>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    navDropdown
                                    hoverColor={dropdownHoverColor}
                                    buttonText="Profile"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent"
                                    }}
                                    popperProps={{
                                        className: classes.customPopper,
                                    }}
                                    buttonIconImage={
                                        <img src={defaultAvatar} alt={user.username} className={classes.userAvatarIcon} />
                                    }
                                    dropdownList={this.getProfileMenu()}
                                />
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    navDropdown
                                    hoverColor={dropdownHoverColor}
                                    buttonText="Dashboard"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent"
                                    }}
                                    popperProps={{
                                        className: classes.customPopper,
                                    }}
                                    buttonIcon={LibraryAdd}
                                    dropdownList={this.getDashboardMenu()}
                                />
                            </ListItem>
                            {
                                (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures) ?
                                    <ListItem className={classes.listItem}>
                                        <CustomDropdown
                                            noLiPadding
                                            navDropdown
                                            hoverColor={dropdownHoverColor}
                                            buttonText="Circle of Trust"
                                            buttonProps={{
                                                className: classes.navLink,
                                                color: "transparent"
                                            }}
                                            popperProps={{
                                                className: classes.customPopper,
                                            }}
                                            buttonIconImage={<CirclesIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                                            dropdownList={this.getCircleMenu()}
                                        />
                                    </ListItem>
                                :
                                <></>
                            }
                            <ListItem className={classes.listItem}>
                                <CustomDropdown
                                    noLiPadding
                                    navDropdown
                                    hoverColor={dropdownHoverColor}
                                    buttonText="Settings"
                                    buttonProps={{
                                        className: classes.navLink,
                                        color: "transparent"
                                    }}
                                    popperProps={{
                                        className: classes.customPopper,
                                    }}
                                    buttonIconImage={<SettingIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                                    dropdownList={this.getSettingsMenu()}
                                />
                            </ListItem>
                        </>
                    :
                    <>
                        <ListItem className={classes.listItem}>
                            <CustomDropdown
                                noLiPadding
                                navDropdown
                                hoverColor={dropdownHoverColor}
                                buttonText="Sign In"
                                buttonProps={{
                                    className: classes.navLink,
                                    color: "transparent"
                                }}
                                popperProps={{
                                    className: classes.customPopper,
                                }}
                                buttonIcon={FingerPrint}
                                dropdownList={this.getLoginMenu()}
                            />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <CustomDropdown
                                noLiPadding
                                navDropdown
                                hoverColor={dropdownHoverColor}
                                buttonText="Sign Up"
                                buttonProps={{
                                    className: classes.navLink,
                                    color: "transparent"
                                }}
                                popperProps={{
                                    className: classes.customPopper,
                                }}
                                buttonIcon={AccountBox}
                                dropdownList={this.getSignupMenu()}
                            />
                        </ListItem>
                    </>
                }    
                <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding
                        navDropdown
                        hoverColor={dropdownHoverColor}
                        buttonText="Info"
                        buttonProps={{
                            className: classes.navLink,
                            color: "transparent"
                        }}
                        popperProps={{
                            className: classes.customPopper,
                        }}
                        buttonIconImage={<InfoIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                        dropdownList={this.getInfoMenu()}
                    />
                </ListItem>
                {
                    authorized ?
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            navDropdown
                            hoverColor={dropdownHoverColor}
                            buttonText="Help"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent"
                            }}
                            popperProps={{
                                className: classes.customPopper,
                            }}
                            buttonIconImage={<HelpIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                            dropdownList={this.getHelpMenu()}
                        />
                    </ListItem>
                    :
                    <ListItem className={classes.listItem}>
                        <CustomDropdown
                            noLiPadding
                            navDropdown
                            hoverColor={dropdownHoverColor}
                            buttonText="Help"
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent"
                            }}
                            popperProps={{
                                className: classes.customPopper,
                            }}
                            buttonIconImage={<HelpIcon className={classes.customIcon+" MuiSvgIcon-root"} />}
                            dropdownList={this.getVisitorHelpMenu()}
                        />
                    </ListItem>
                }
                {
                    authorized ?
                        <>
                            {
                                chromeExtensionUrl !== null && chromeExtensionUrl.length > 0 ?
                                    <ListItem className={classes.listItem}>
                                        <Button color="transparent" className={classes.navLink}>
                                            <DownloadIcon />
                                            <Link href={chromeExtensionUrl} target="_blank" className={classes.navLink+" "+classes.navLinkWithIcon} onClick={() => this.props.onClose(false)}>Download our Chrome Extension!</Link>
                                        </Button>
                                    </ListItem>
                                :
                                <></>
                            }
                            <ListItem className={classes.listItem}>
                                <Button color="transparent" className={classes.navLink} onClick={() => this.props.onFeedback()}>
                                    <FeedbackIcon />Feedback? Tell us!
                                </Button>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <Button color="transparent" className={classes.navLink}>
                                    <FingerPrint />
                                    <RouterLink to="/logout" className={classes.navLink+" "+classes.navLinkWithIcon} onClick={() => this.props.onClose(false)}>Logout</RouterLink>
                                </Button>                        
                            </ListItem>
                        </>
                    :
                    <></>
                }
            </List>
        );
    }
}
HeaderLinks.defaultProps = {
    hoverColor: "primary",
    onClose: function(){ },
    onLogin: function(){ },
    onSignup: function(){ },
    onSignupCode:function(){},
    onLoginOptions: function(){ },
    onFeedback: function(){ },
    desktop: false,
    bottomBar: false,
    color: "info"
};

HeaderLinks.propTypes = {
    onClose: PropTypes.func,
    onLogin: PropTypes.func,
    onSignup: PropTypes.func,
    onSignupCode:PropTypes.func,
    onLoginOptions: PropTypes.func,
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ]),
    desktop: PropTypes.bool,
    bottomBar: PropTypes.bool,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    }),
    onFeedback: PropTypes.func
};

export default withTheme(withStyles(headerLinksStyle)(HeaderLinks));
