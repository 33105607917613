import {
    primaryColor,
    hexToRgb,
    youtubeColor,
    grayColor,
    blackColor
} from "../../material-kit-pro-react.jsx";
import { whiteColor } from "../../material-dashboard-pro-react.jsx";

const dashboardTabPlatformsStyle = theme => ({
    title: {
        margin: theme.spacing(1, 0, 1),
        fontWeight:"bold",
    },
    navLink: {
        color: "inherit",
        position: "relative",
        padding: "0px",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        "&:hover,&:focus": {
            color: "inherit"
        },
        "& .fab,& .far,& .fal,& .fas,& .material-icons": {
            position: "relative",
            top: "2px",
            marginTop: "-4px",
            marginRight: "4px",
            marginBottom: "0px",
            fontSize: "1.25rem"
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 30px)",
            textAlign: "left",
            "& > span:first-child": {
                justifyContent: "flex-start"
            }
        },
        "& svg": {
            marginRight: "5px",
            width: "20px",
            height: "20px"
        }
    },
    navLinkJustIcon: {
        "& .fab,& .far,& .fal,& .fas,& .material-icons": {
            marginRight: "0px"
        },
        "& svg": {
            marginRight: "0px"
        }
    },
    navLinkWithIcon: {
        padding: "0px"
    },
    dropdownLink: {
        "&,&:hover,&:focus": {
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            padding: "0.75rem 1.25rem 0.75rem 0.75rem",
            alignItems: "center"
        },
        "& i": {
            marginRight: "5px"
        },
        "&:hover": {
            "& svg, svg *": {
                fill: whiteColor
            }
        }
    },
    platformsGridContainer: {
        margin: "0px"
    },
    platformsGridItem: {
        width: "auto",
        alignSelf: "center",
        paddingRight: "0px",
        paddingLeft: "0px",
    },
    platformLink: {
        paddingRight: "5px",
        display: "inline-block",
        margin: "0px 5px",
        "& a": {
            display: "inline-block",
        },
        "& button": {
            padding: "0px",
            minWidth: "0px",
            lineHeight: "0px",
            maxWidth: "100%",
            height: "50px",
            width: "50px",
            color: grayColor[15],
            "&:hover": {
                color: grayColor[15],
            },
            "& i": {
                lineHeight: "50px !important",
                fontSize: "25px !important"
            },
            "& svg": {
                height: "30px",
                width: "30px",
                display: "block"
            },
            "& img": {
                width: "25px",
                display: "block",
                "&.youtube-icon": {
                     width: "30px",
                }
            },
            "& .youtube-icon": {
                color: youtubeColor
            }
        },
        "& .active button": {
            backgroundColor: primaryColor[0],
            boxShadow:
                "0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.14), 0 7px 10px -5px rgba(" +
                hexToRgb(primaryColor[0]) +
                ", 0.4)",
            color: whiteColor,
            "&:hover": {
                color: whiteColor,
            },
        },
        "& .LinkedIcon path": {
            fill: whiteColor,
        }
    },
    customIcon: {
        marginRight: "5px",
        maxWidth: "18px",
        maxHeight: "18px"
    },
    customTooltipIcon: {
        display: "block",
        marginLeft: "auto !important",
        marginRight: "auto !important"
    },
    showLabels: {
        "& $platformLink": {
            paddingRight: "0px",
            "& button": {
                display: "block",
                fontSize: "8px",
                width: "55px",
                height: "55px",
                padding: "5px 10px",
                minWidth: "0px",
                margin: "0px",
                lineHeight: "24px",
                paddingTop: "10px",
                "& .MuiButton-label": {
                    display: "inline-flex",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }
            }
        }
    }
});

export default dashboardTabPlatformsStyle;