import React from 'react'
import Config from "../../../../../../Config";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import Api from '../../../../../assets/js/utils/Api';
import Button from "../../../CustomButtons/Button.jsx";
import Instruction from "../../../Instruction/Instruction.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import toolTipsModalStyle from "../../../../../assets/jss/tooltips/toolTipsModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const TitleTooltipModal = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        
        this.state = {
            showTips: user.userPreferences.showTips,
        };
        this.handlePrefChange = this.handlePrefChange.bind(this);
    }
    handlePrefChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.checked,
        });
        
        const { user } = this.store.getState();
        user.userPreferences.showTips = event.target.checked;
        Api.updateUser(user.userId, user);
    }
    
    render() {
        const { classes } = this.props;
        const { showTips } =this.state;
        const cdnUrl = Config.getCdnUrl();
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="loginoptions-modal-slide-title"
                aria-describedby="firstName-modal-slide-description"
            >
                <DialogTitle
                    id="firstName-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Title</h4>                            
                </DialogTitle>
                <DialogContent
                    id="firstName-modal-slide-description"
                    className={classes.modalBody}
                    >
                    <Instruction
                        title="1. Step"
                        text={
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                            </span>
                        }
                        image={cdnUrl+"/app/icon_trans393x551.png"}
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />
                    <Instruction
                        title="2. Step"
                        text={
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                            </span>
                        }
                        className={classes.instructionNoticeModal}
                        imageClassName={classes.imageNoticeModal}
                    />                    
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    <div>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="info"
                            round
                        >
                            Sounds Good
                        </Button>
                    </div>
                    <div className={classes.checkboxAndRadio + " " +classes.checkboxAndRadioHorizontal + " " +classes.customCheckBox}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={showTips}
                                onChange={(e) => this.handlePrefChange(e, "showTips")}
                                value="1"
                                name="showTips"
                                icon={<Check className={classes.uncheckedIcon}/>}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                }}
                            />
                            }
                            classes={{ label: classes.label, root: classes.labelRoot }}
                            label="Show Tool Tips"
                        />
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}

TitleTooltipModal.defaultProps = {
    open: false
}
TitleTooltipModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(toolTipsModalStyle)(TitleTooltipModal);