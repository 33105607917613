import React from "react";
import PropTypes from "prop-types";
// core components
import axios from 'axios';
import Config from '../../../../../../Config';
import withStyles from "@material-ui/core/styles/withStyles";

import LinkEdit from "../contents/types/LinkEdit";
// import EditCatalog from "../../../catalog/Edit";
import RecommendationEdit from "../contents/types/RecommendationEdit";
import InstagramEdit from "../contents/types/InstagramEdit";
import YoutubeEdit from "../contents/types/YoutubeEdit";
import ButtonEdit from "../contents/types/ButtonEdit";
import PodcastEdit from "../contents/types/PodcastEdit";
import InstagramTvEdit from "../contents/types/InstagramTvEdit";

import searchableContentStyle from "../../../../../assets/jss/dashboard/platforms/SearchableContentStyle.js";

class ContentTypes extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        let contentId = "";
        if(this.props.hasOwnProperty("match")){
            contentId = this.props.match.params.contentId;
        }
        
        this.state = {
            contentId: contentId,
            type: null,
            platform: this.props.platform
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            platform: props.platform
        })
    }
    componentDidMount(){
        if(!this.props.hasOwnProperty("content") || this.props.content === null){
            this.loadContent();
        }else{
            if(this.props.content.type){
                this.setState({
                    type: this.props.content.type
                })
            }
        }
    }
    loadContent(){
        const ApiUrl = Config.getApiUrl()+"data/content/"+this.state.contentId;
        let headers = Config.getApiHeaders();
        const source = axios.CancelToken.source(); 
        axios({
            url: ApiUrl,
            method: "GET",
            headers: headers,
            cancelToken: source.token
        }).then(res => {
            if(res.data.numberOfResponses > 0){
                let response = res.data.response[0];
                if(response.type){
                    this.setState({
                        type: response.type,
                    })
                }

            }
        }).catch(err => {
            
        });
    }
    returEditContent(){
        let { type, platform } = this.state;
        const { authorized, user } = this.props.store.getState();
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);    

        if(platform.type === "instagramAPI"){
            type = "instagram_post";
        }else if(platform.type === "youtubeAPI"){
            type = "youtube";
        }

        let renderedContent = null;
        switch(type){
            case "link":
                renderedContent = <LinkEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            case "button":
                renderedContent = <ButtonEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            case "instagram_post":
                renderedContent = <InstagramEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            case "recommendation":
                if(showBetaFeatures){
                    renderedContent = <RecommendationEdit 
                        store={this.props.store}
                        content={this.props.content}
                        onEdit={(platform, content) => this.props.onEdit(null, content)}
                        onCancel={this.props.onCancel}
                        match={this.props.match}
                        platform={this.props.platform}
                        onDelete={this.props.onDelete}
                        hardrefresh={this.props.hardrefresh}
                    />;
                }
                break;
            case "youtube":
                renderedContent = <YoutubeEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            case "podcast":
                renderedContent = <PodcastEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            case "instagram_tv":
                renderedContent = <InstagramTvEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
            default:
                renderedContent = <LinkEdit 
                    store={this.props.store}
                    content={this.props.content}
                    onEdit={(platform, content) => this.props.onEdit(null, content)}
                    onCancel={this.props.onCancel}
                    match={this.props.match}
                    platform={this.props.platform}
                    onDelete={this.props.onDelete}
                    hardrefresh={this.props.hardrefresh}
                />;
                break;
        }
        return renderedContent;
    }
    render() {
        const { authorized } = this.props.store.getState();
        if(!authorized){
            return <></>
        }
        return (
            <>
                {
                    this.returEditContent()
                }
            </>
        )
    }
}

ContentTypes.defaultProps = {
    content: null,
    onDelete: null

}
ContentTypes.propTypes = {
    content: PropTypes.object,    
    onDelete: PropTypes.func,
}

export default withStyles(searchableContentStyle)(ContentTypes);
