import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customSelectStyle from "../../../../../assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "../../../../../assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = theme => ({
    ...customCheckboxRadioSwitch,
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
        "& span": {
            display: "block",
            fontSize: "14px"
        }
    },
    ...customSelectStyle,
    checkboxLabelCustom: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        },
        "& label": {
            color: "inherit"
        },
        "& span": {
            marginLeft: "20px",
        }
    },
    checkboxLabel: {
        "& > label": {
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(2),
            }
        }
    }
});

class Step3 extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.state = {
            allStates: this.props.allStates,
            ownedByMe: false,
            isPublic: false,
            notifyMe: false,
            ownedByMeState: "",
            isPublicState: ""
        };
    }
    componentWillReceiveProps(props){
        this.setState({allStates: props.allStates});
    }
    sendState() {
        return this.state;
    }
    isValidated() {
        return true;
    }
    getUsername(){
        const { instagramPlatform, platform } = this.state.allStates.account;
        if(platform !== null && platform.hasOwnProperty("pendingData") && platform.pendingData.hasOwnProperty("instagramHandle")){
            return platform.pendingData.instagramHandle;
        }
        if(instagramPlatform !== null && instagramPlatform.hasOwnProperty("pendingData") && instagramPlatform.pendingData.hasOwnProperty("instagramHandle")){
            return instagramPlatform.pendingData.instagramHandle;
        }
        if(instagramPlatform !== null && instagramPlatform.hasOwnProperty("instagramHandle")){
            return instagramPlatform.instagramHandle;
        }
        return null;
    }
    render() {
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        const { allStates } = this.state;
        const showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                <h4 className={classes.infoText}>Did we get it right?  <span>(You can always change these settings later)</span></h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        {
                            allStates.hasOwnProperty("account") ?
                                <CustomInput
                                    labelText="Instagram Handle"
                                    inputProps={{
                                        value: "https://instagram.com/"+this.getUsername(),
                                        disabled: true
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                />
                            :
                            <></>
                        }
                        
                        {
                            allStates.hasOwnProperty("robot") ?
                                <>
                                    {/* <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.showOnHomeFeed}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label={(
                                                allStates.robot.showOnHomeFeed ?
                                                    "Show Instagram Content on Home Feed"
                                                :
                                                "Do NOT show Instagram Content on Home Feed"
                                            )}
                                        />
                                    </div> */}
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.searchable}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Make All Content Immediately Searchable"
                                        />
                                    </div>
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.hashtags}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Add all hashtags from the post caption to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.useFirstLink}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Automatically use the first link found as the Click-Through Url"
                                        />
                                    </div>
                                    <div className={classes.checkboxLabel+" "+classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.includeFirstCommentHashtags}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to add hashtags from the first comment to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.addRecognition}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio,
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to add recognized media to your search result keywords"
                                        />
                                    </div>
                                    <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={allStates.robot.attemptGeoTag}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                disabled={true}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                            />
                                            }
                                            classes={{ label: classes.label, root: classes.labelRoot }}
                                            label="Attempt to Geo-Tag the posts"
                                        />
                                    </div>
                                    {
                                        showBetaFeatures ?
                                            <>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.retrieveAllMedia}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to retrieve all post media"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importGuides}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to Import Guides"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importReels}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to Import Reels"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.importIGTV}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio,
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Attempt to import IGTV"
                                                    />
                                                </div>
                                                <div className={classes.checkboxAndRadio +" " +classes.checkboxAndRadioHorizontal }>
                                                    <FormControlLabel
                                                        control={
                                                        <Checkbox
                                                            checked={allStates.robot.onlySmearchTags}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            disabled={true}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                                disabled: classes.disabledCheckboxAndRadio
                                                            }}
                                                        />
                                                        }
                                                        classes={{ label: classes.label, root: classes.labelRoot }}
                                                        label="Only upload posts with the #smearch or #searchme hashtags"
                                                    />
                                                </div>
                                            </>
                                        :
                                        <></>
                                    }
                                    {/* <div className={classes.checkboxLabelCustom} >
                                        <label>
                                            How Often Should this Robot look for new content?
                                            <span>{allStates.robot.robotFrequencyLabel}</span>
                                        </label>
                                    </div>                                     */}
                                </>
                            :
                            <></>
                        }                        
                    </GridItem>
                </GridContainer>
                </GridItem>        
            </GridContainer>
        );
    }
}

Step3.defaultProps = {
    onMount: function(){ }
}
Step3.propTypes = {
  classes: PropTypes.object,
  onMount: PropTypes.func
};

export default withStyles(style)(Step3);
