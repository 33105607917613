import React from 'react';
import Api from '../../../../assets/js/utils/Api';
import axios from 'axios';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from '../../../components/Grid/GridItem';
import Button from "../../../components/CustomButtons/Button.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Danger from "../../../components/Typography/Danger.jsx";
import LoginUserModal from "../../../components/Dashboard/LoginUserModal";
import LoginAsOptOutModal from "../../../components/Dashboard/LoginAsOptOutModal";

import dashboardTabAdminLoginStyle from "../../../../assets/jss/dashboard/admin/dashboardTabAdminLoginStyle.js";

const DashboardTabAdminLogin = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;

        this.state = {
            defaultUsername: "",
            username: "",
            saving: false,
            validation: {
                username: ''
            },
            validatingUsername: false,
            loginAsUser: false,
            cancelTokenUsername: null,
            user: null,
            loginAsOptOut: false
        };

        this.validateUsernameTimeout = null;
        this.loginAsUserSuccess = this.loginAsUserSuccess.bind(this);
        this.loginAsUser = this.loginAsUser.bind(this);
        this.loginAsOptOut = this.loginAsOptOut.bind(this);
        this.onClear = this.onClear.bind(this);
    }
    componentDidUpdate(prevProps, prevState){
        const state = this.state;
        const that = this;
        if(state.username !== state.defaultUsername && state.username !== prevState.username){
            if(this.validateUsernameTimeout !== null){
                clearTimeout(this.validateUsernameTimeout);
            }
            this.validateUsernameTimeout = setTimeout(function(){
                that.validateUsername();
            },500);
        }
    }
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    validateUsername(){
        if(this.state.cancelTokenUsername){
            this.state.cancelTokenUsername.cancel('Cancelled');
        }
        if(this.state.username.length <= 0){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: "error"
                },
                validatingUsername: false,
                user: null
            });
            return;
        }
        if(this.state.username === this.state.defaultUsername){
            this.setState({
                validation: {
                    ...this.state.validation,
                    username: ""
                },
                validatingUsername: false,
                user: null
            });
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            validatingUsername: true,
            cancelTokenUsername: source,
            user: null
        });        
        Api.checkUsername(this.state.username, source).then((users) => {
            this.setState({
                cancelTokenUsername: null,
                validatingUsername: false,
                validation: {
                    ...this.state.validation,
                    username: "success"
                },
                user: users[0]
            });
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({
                    cancelTokenUsername: null,
                    validatingUsername: false,
                    validation: {
                        ...this.state.validation,
                        username: "error"
                    },
                    user: null
                });
            }
        });
    }
    loginAsUser(login = true){
        if(login){
            const{ user } = this.state;
            if(user.hasOwnProperty("guestPreferences") && user.guestPreferences.loginAsOptOut){
                this.setState({loginAsOptOut: true});
                return true;
            }
        }
        this.setState({loginAsUser: login});
    }
    loginAsOptOut(){
        this.setState({loginAsOptOut: false});
    }
    loginAsUserSuccess(){
        this.setState({loginAsUser: false});

        const{ user } = this.state;
        localStorage.setItem("auth_identity", user.userId);
        localStorage.setItem("auth_username", user.username);
        // this.store.dispatch({type: 'UPDATE_STATE', state: {
        //     authIdentity: user.userId,
        //     authUsername: user.username
        // }});
        window.location.reload();
    }
    onClear(){
        this.setState({
            username: "",
            validation: {
                ...this.state.validation,
                username: ""
            },
        })
    }
    
    render() {
        const { classes } = this.props;
        const{ validation, loginAsUser, loginAsOptOut } = this.state;
        return (
            <GridItem className={classes.main} xs={12} sm={12} md={10} lg={8}>
                <h4 className={classes.title}>Login As</h4>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={8} sm={9} md={9} lg={9}>
                        <CustomInput
                            id="outlined-myusername"
                            success={validation.username === "success"}
                            error={validation.username === "error"}
                            labelText="Username"
                            loading={this.state.validatingUsername}
                            inputProps={{
                                value: this.state.username,
                                onChange: event => this.handleChange(event, "username"),
                                name: "myusername",
                            }}
                            onClear={this.onClear}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={3} md={3} lg={3} className={classes.changeButton}>
                        <Button color="info" round onClick={() => this.loginAsUser()} disabled={validation.username !== "success"}>OK</Button>
                    </GridItem>
                </GridContainer>
                {
                    validation.username === "error" ?
                        <Danger>This username is not found</Danger>
                    :
                    <></>
                }
                {
                    loginAsUser ?
                        <LoginUserModal store={this.store} open={loginAsUser} username={this.state.username} onSuccess={this.loginAsUserSuccess} onClose={() => this.loginAsUser(false)} />
                    :
                        <></>
                }
                {
                    loginAsOptOut ?
                        <LoginAsOptOutModal store={this.store} open={loginAsOptOut} onClose={() => this.loginAsOptOut()} />
                    :
                        <></>
                }
            </GridItem>
        )
    }
}

export default withStyles(dashboardTabAdminLoginStyle)(DashboardTabAdminLogin);