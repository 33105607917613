import Config from '../../../../Config';
import Cookie from './Cookie';
import { helper } from './Element'
import axios from 'axios';

const ApiHelper = {
    countries: [],
    countryStates: [],
    getDefaultMemberObject(){
        return  {
            blogUrl: "",
            contactEmail: "",
            createdById: "",
            createdDateTime: "",
            facebookUsername: "",
            firstName: "",
            instagramUsername: "",
            isDeleted: false,
            lastModifiedById: "",
            lastModifiedDateTime: "",
            lastName: "",
            ownerId: "",
            pinterestUsername: "",
            twitterUsername: "",
            youtubeChannelUrl: "",
            tiktokUsername: "",
            linkedinUsername: "",
            userEmail: "",
            userId: "",
            username: "",
            bio: "",
            lastLoggedInDateTime: "",
            userPreferences: {
                showSidebarControl: false,
                showBetaFeatures: false,
                searchViewResults: "list",
                searchFilterLinks: false,
                searchFilterInstagram: false,
                searchFilterYoutube: false,
                onboardingComplete: false,
                dashboardTourComplete: false,
                profileTourComplete: false,
                platformTourComplete: false,
                showTours:false,
                allowBeta: false,
                onboarding: {
                    step1Complete: false,
                    step2Complete: false,
                    step3Complete: false,
                    step4Complete: false
                },
                showTips: false,
                fullstoryOptOut: false,
                showLinktree: true,
                showInstagram: true,
                showYouTube: true,
                showLabels: true
            },
            guestPreferences: {
                featuredViewOptions: "list",
                linkBehavior: "showPreview",
                fontFamily: "Gotham-Book",
                primaryColor: "primary",
                loginAsOptOut: false,
                maxFeatured: "6",
                defaultLayout: "list",
                showButtons: false,
                contentPlatforms: [],
                theme: "light",
                layoutTransparency: 0,
                buttonStyle: "rectangle",
                fontSize: "large",
                filters: {
                    instagramFilter: true,
                    linksFilter: true,
                    locationFilter: true,
                    youtubeFilter: true,
                    shoppableFilter: true
                }
            },
            contentPlatforms: [],
            socialIcons: {},
            userLevel: 0,
            paymentFrequency: "",
            isAdmin: false,
            isSuspended: false,
            birthdate: "",
            buttons: [],
            statLifetimeViews: 0,
            statLifetimeSearches: 0,
            statLifetimeContentViews: 0,
            statLifetimeContentClicks: 0,
            euTransferConsent: false,
            country: "US",
            isPrivate: false
        };
    },
    prepareMemberObject(member){
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject,member);
        if(!memberObject.userPreferences.hasOwnProperty("dashboardTourComplete")){
            memberObject.userPreferences['dashboardTourComplete'] = false;
        }
        if(!memberObject.userPreferences.hasOwnProperty("profileTourComplete")){
            memberObject.userPreferences['profileTourComplete'] = false;
        }
        if(!memberObject.userPreferences.hasOwnProperty("platformTourComplete")){
            memberObject.userPreferences['platformTourComplete'] = false;
        }
        if(!memberObject.userPreferences.hasOwnProperty("fullstoryOptOut")){
            memberObject.userPreferences['fullstoryOptOut'] = false;
        }
        if(!memberObject.userPreferences.hasOwnProperty("showLinktree")){
            memberObject.userPreferences['showLinktree'] = true;
        }
        if(!memberObject.userPreferences.hasOwnProperty("showInstagram")){
            memberObject.userPreferences['showInstagram'] = true;
        }
        if(!memberObject.userPreferences.hasOwnProperty("showYouTube")){
            memberObject.userPreferences['showYouTube'] = true;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("loginAsOptOut")){
            memberObject.guestPreferences['loginAsOptOut'] = false;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("maxFeatured")){
            memberObject.guestPreferences['maxFeatured'] = "6";
        }
        if(!memberObject.userPreferences.hasOwnProperty("allowBeta")){
            memberObject.userPreferences['allowBeta'] = false;
        }
        if(!memberObject.userPreferences.hasOwnProperty("showLabels")){
            memberObject.userPreferences['showLabels'] = true;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("defaultLayout")){
            memberObject.guestPreferences['defaultLayout'] = "list";
        }
        if(!memberObject.guestPreferences.hasOwnProperty("showButtons")){
            memberObject.guestPreferences['showButtons'] = false;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("contentPlatforms")){
            memberObject.guestPreferences['contentPlatforms'] = [];
        }
        if(!memberObject.guestPreferences.hasOwnProperty("filters")){
            memberObject.guestPreferences['filters'] = {
                instagramFilter: true,
                linksFilter: true,
                locationFilter: true,
                youtubeFilter: true,
                shoppableFilter: true
            };
        }
        if(memberObject.isAdmin === true){
            memberObject.userPreferences['allowBeta'] = true;
        }
        if(!memberObject.userPreferences.hasOwnProperty("onboarding")){
            memberObject.userPreferences['onboarding'] = {
                step1Complete: false,
                step2Complete: false,
                step3Complete: false,
                step4Complete: false
            };
        }
        if(!memberObject.userPreferences.hasOwnProperty("showTips")){
            memberObject.userPreferences['showTips'] = false;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("fontFamily")){
            memberObject.guestPreferences['fontFamily'] = "Gotham-Book";
        }
        if(!memberObject.guestPreferences.hasOwnProperty("primaryColor")){
            memberObject.guestPreferences['primaryColor'] = "primary";
        }
        if(!memberObject.guestPreferences.hasOwnProperty("theme")){
            memberObject.guestPreferences['theme'] = "light";
        }
        if(!memberObject.guestPreferences.hasOwnProperty("fontSize")){
            memberObject.guestPreferences['fontSize'] = "large";
        }
        if(!memberObject.guestPreferences.hasOwnProperty("layoutTransparency")){
            memberObject.guestPreferences['layoutTransparency'] = 0;
        }
        if(!memberObject.guestPreferences.hasOwnProperty("buttonStyle")){
            memberObject.guestPreferences['buttonStyle'] = "rectangle";
        }
        for(var attrib in memberObject){
            try{
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[attrib] = memberObject[attrib].trim();
                }                
            }catch(e){ }
        }
        return memberObject;
    },
    getRadiusArray(){
        const radiusArray = [1,2,3,4,5,10,15,20,25,35,50,75,100,250];
        return radiusArray;
    },
    getCookies(){
        const cookies = Cookie.getAll();
        return encodeURIComponent(JSON.stringify(cookies));
    },
    getTopics(){
        const topics = ['Food', 'Travel', 'Parenting', 'Fitness'];
        return topics;
    },
    getSettings(loadNew = false){
        return new Promise(function(resolve, reject){
            const settings = localStorage.getItem("searchmysocial_settings");
            if(settings !== null && loadNew === false){
                return resolve(JSON.parse(settings));
            }
            const ApiUrl = Config.getApiUrl()+"public/settings";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    localStorage.setItem("searchmysocial_settings", JSON.stringify(res.data.response));
                    return resolve(res.data.response);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendActivity(requestData, cancelTokenSource = null){
        //Handle 0 - 10;
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData, cancelTokenSource);
        }

        //Handle 0 - 20;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData, cancelTokenSource);
        }

        //Handle 0 - 30;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData, cancelTokenSource);
        }

        //Handle 0 - 40;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData, cancelTokenSource);
        }

        //Handle 0 - 50;
        this.sendActivityPartial(requestData.splice(0,10));
        if(requestData.length <= 10){
            return this.sendActivityPartial(requestData, cancelTokenSource);
        }
    },
    sendActivityPartial(requestData, cancelTokenSource = null){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/activity";
            let headers = Config.getApiHeaders();
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source();
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendAdClickedActivity(){
        let sendUrlClicked = false;
        const gclid = helper.getParam("gclid");
        if(gclid && gclid.length > 0 && gclid !== "null"){
            sendUrlClicked = true;
        }
        const fbclid = helper.getParam("fbclid");
        if(fbclid && fbclid.length > 0 && fbclid !== "null"){
            sendUrlClicked = true;
        }
        if(sendUrlClicked){
            var requestData = [];
            requestData.push({
                'activityType': "url_clicked",
                'url': window.location.href,
                'data': Cookie.getAdsData()
            });
            
            this.sendActivity(requestData);
        }
    },
    updateUser(userId, requestData, cancelTokenSource = null){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/"+userId+"?buttons=true&contentPlatforms=true";
            let headers = Config.getApiHeaders();
            if(requestData.hasOwnProperty("username")){
                requestData.username = requestData.username.toLowerCase();
            }
            let data = Object.assign({}, requestData);
            if(data.hasOwnProperty("avatarMedia")){
                delete data["avatarMedia"];
            }
            if(data.hasOwnProperty("coverMedia")){
                delete data["coverMedia"];
            }
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source();
            }
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    createContent(requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content";
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            data.isDraft = !data.isDraft;
            for(var attrib in data.googlePlacesResponse){
                if(typeof(data.googlePlacesResponse[attrib]) === "string" && data.googlePlacesResponse[attrib].length <= 0){
                    data.googlePlacesResponse[attrib] = " ";
                }
            }
            axios.post(ApiUrl, JSON.stringify(data), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateContent(contentId, requestData, updateFeaturedSequence = false){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content/"+contentId;
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            data.isDraft = !data.isDraft;
            // if(data.isDraft === false && (( !data.hasOwnProperty("coverMediaId") || data.coverMediaId.length <= 0) || (!data.hasOwnProperty("keywords") || data.keywords.length <= 0))){
            //     data.isDraft = true;
            // }
            for(var attrib in data.googlePlacesResponse){
                if(typeof(data.googlePlacesResponse[attrib]) === "string" && data.googlePlacesResponse[attrib].length <= 0){
                    data.googlePlacesResponse[attrib] = " ";
                }
            }
            if(updateFeaturedSequence === false && requestData.hasOwnProperty("featuredSequence")){
                delete data['featuredSequence'];
            }
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteContent(contentId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/content/"+contentId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    createMedia(file, requestData, cancelTokenSource, uploadMedia = true){
        const that = this;
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/media";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    if(uploadMedia === false){
                        resolve(res.data.response[0]);
                        return;
                    }
                    that.uploadMedia(file, res.data.response[0], cancelTokenSource).then((data) => {
                        resolve(data);
                    }).catch(err => {
                        reject(err);
                    });
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    uploadMedia(file, data, cancelTokenSource){
        return new Promise(function(resolve, reject){
            let headers = {};
            headers['Content-Type'] = file.type;
            axios.put(data.uploadUrl, file, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteMedia(mediaId, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/media/"+mediaId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject("Invalid response from api.");
            });
        });
    },
    getMedia(mediaId, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/media/"+mediaId;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createPaymentMethod(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    deletePaymentMethod(cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/paymentmethod";
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject("Invalid response from api.");
            });
        });
    },
    checkUsername(username, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/username/"+username;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response);
                }else{
                    reject({message: "Username is available."});
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createPlatform(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/contentPlatform";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    updatePlatform(requestData, cancelTokenSource, userId, runNow = false){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/contentPlatform/"+requestData.contentPlatformId;
            if(runNow){
                ApiUrl += "?run_now=true"
            }
            let headers = Config.getApiHeaders();
            axios.put(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deletePlatform(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/contentPlatform/"+requestData.contentPlatformId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getPlatforms(requestData, cancelTokenSource, userId){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/user/platform/"+userId;
            axios({
                url: ApiUrl+"?"+helper.serialize(requestData),
                method: "GET",
                headers: Config.getApiHeaders(),
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getContent(requestData, cancelTokenSource, userId = null){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/content/";
            if(userId !== null){
                ApiUrl += "user/"+userId;
            }
            axios({
                url: ApiUrl+"?"+helper.serialize(requestData),
                method: "GET",
                headers: Config.getApiHeaders(),
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });        
    },
    sendContactRequest(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/support/email";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendEmails(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/job";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteUser(userId){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/"+userId;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getCountries(cancelTokenSource = null){
        const that = this;
        return new Promise(function(resolve, reject){
            if(that.countries.length > 0){
                return resolve(that.countries);
            }
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source(); 
            }
            const ApiUrl = Config.getApiUrl()+"data/settings/countries";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getOldStates(country){
        let array = [];
        if(country === "United States"){
            array = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
        }
        if(country === "Canada"){
            array = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'];
        };
        return array;
    },
    getStates(country, cancelTokenSource = null){
        const that = this;
        return new Promise(function(resolve, reject){
            if(that.countryStates.hasOwnProperty(country)){
                return resolve(that.countryStates[country]);
            }
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source(); 
            }
            const ApiUrl = Config.getApiUrl()+"data/settings/countries/"+country;
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    that.countryStates[country] = res.data.response;
                    return resolve(res.data.response);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    pronouns() {
        return new Promise(function(resolve, reject){
            let array = ["he/him/his","she/her/hers","they/them/theirs","other/ask me"];
            resolve(array);
        });
    },
    getFonts(){
        return [
            {
                fontName: "Gotham Book (Default)",
                fontFamily: "Gotham-Book"
            },
            {
                fontName: "Roboto",
                fontFamily: "Roboto"
            },
            {
                fontName: "Raleway",
                fontFamily: "Raleway"
            },
            {
                fontName: "Nanum Gothic",
                fontFamily: "Nanum Gothic"
            },
            {
                fontName: "Courier Prime",
                fontFamily: "Courier Prime"
            },
            {
                fontName: "Belleza",
                fontFamily: "Belleza"
            },
            {
                fontName: "Sorts Mill Goudy",
                fontFamily: "Sorts Mill Goudy"
            },
            {
                fontName: "Questrial",
                fontFamily: "Questrial"
            },
            {
                fontName: "Julius Sans One",
                fontFamily: "Julius Sans One"
            },
            {
                fontName: "Coming Soon",
                fontFamily: "Coming Soon"
            }            
        ];
    },
    getSubscription(cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/subscription";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    createSubscription(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/subscription";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    if(typeof(res.data) === "object" && res.data.hasOwnProperty("message")){
                        reject(res.data.message);
                    }else{
                        reject("Invalid response from api.");
                    }
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    updateSubscription(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/subscription";
            let headers = Config.getApiHeaders();
            let data = Object.assign({}, requestData);
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    getInstagramAccessToken(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/instagram/code";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    getInstagramDetails(requestData){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/instagram?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
            }).then(res => {
                if(res.data.hasOwnProperty("username")){
                    return resolve(res.data);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteSubscription(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/subscription?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    checkCoupon(couponCode, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/coupon/"+couponCode;
            let headers = Config.getApiHeaders();
            axios.get(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    return resolve(res.data.response[0]);
                }else{
                    reject("Invalid response from api.");
                }
                resolve(res.data);
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    resetFeaturedContent(requestData, cancelTokenSource, userId, queryParams = null){
        return new Promise(function(resolve, reject){
            let ApiUrl = Config.getApiUrl()+"data/content/user/"+userId+"/unfeature";
            if(queryParams !== null && typeof(queryParams) === "object"){
                ApiUrl += "?"+helper.serialize(queryParams);
            }
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getContentTypes(authorized, user){
        var showBetaFeatures = (authorized && user.hasOwnProperty("userPreferences") && user.userPreferences.showBetaFeatures);
        if(showBetaFeatures){
            return [
                {
                    itemValue: "link",
                    itemLabel: "Web Link"
                },
                {
                    itemValue: "instagram_post",
                    itemLabel: "Instagram Post"
                },
                {
                    itemValue: "recipe",
                    itemLabel: "Recipe"
                },
                {
                    itemValue: "recommendation",
                    itemLabel: "Recommendation"
                },
                {
                    itemValue: "place",
                    itemLabel: "Place"
                },
                {
                    itemValue: "photo",
                    itemLabel: "Photo"
                },
                {
                    itemValue: "shopping_items",
                    itemLabel: "Shopping Item(s)"
                },
                {
                    itemValue: "workout",
                    itemLabel: "Workout Routine"
                },
                {
                    itemValue: "youtube",
                    itemLabel: "Youtube Video"
                },
                {
                    itemValue: "tiktok",
                    itemLabel: "TikTok"
                },
                {
                    itemValue: "button",
                    itemLabel: "Button"
                },
                {
                    itemValue: "instagram_guide",
                    itemLabel: "Instagram Guides"
                },
                {
                    itemValue: "instagram_reel",
                    itemLabel: "Instagram Reels"
                },
                {
                    itemValue: "instagram_tv",
                    itemLabel: "Instagram Tv"
                },
                {
                    itemValue: "podcast",
                    itemLabel: "Podcast"
                },
                {
                    itemValue: "instagram_video",
                    itemLabel: "Instagram Video"
                }
            ];
        }else{
            return [
                {
                    itemValue: "link",
                    itemLabel: "Web Link"
                },
                {
                    itemValue: "instagram_post",
                    itemLabel: "Instagram Post"
                },
                {
                    itemValue: "youtube",
                    itemLabel: "Youtube Video"
                },
            ];
        }
    },
    getSocialIcons(){        
        return [
            {
                itemValue: "instagramUrl",
                itemLabel: "Instagram Username"
            },
            {
                itemValue: "facebookUrl",
                itemLabel: "Facebook Url"
            },
            {
                itemValue: "pinterestUrl",
                itemLabel: "Pinterest Url"
            },
            {
                itemValue: "tiktokUrl",
                itemLabel: "Tiktok Username"
            },
            {
                itemValue: "youtubeUrl",
                itemLabel: "Youtube Url"
            },
            {
                itemValue: "contactEmail",
                itemLabel: "Contact Email"
            },
            {
                itemValue: "twitterUrl",
                itemLabel: "Twitter Handle"
            },
            {
                itemValue: "linkedinUrl",
                itemLabel: "Linkedin Username"
            },
            {
                itemValue: "snapchatUrl",
                itemLabel: "Snapchat Username"
            },
            {
                itemValue: "soundcloudUrl",
                itemLabel: "SoundCloud"
            },
            {
                itemValue: "whatsappNumber",
                itemLabel: "WhatsApp"
            },
            {
                itemValue: "twitchUrl",
                itemLabel: "Twitch"
            },
            {
                itemValue: "patreonUrl",
                itemLabel: "Patreon"
            },
            {
                itemValue: "spotifyUrl",
                itemLabel: "Spotify"
            },
            {
                itemValue: "amazonLiveUrl",
                itemLabel: "Amazon Live"
            },
            {
                itemValue: "amazonShopUrl",
                itemLabel: "amazon Shop"
            },
            {
                itemValue: "appleMusicUrl",
                itemLabel: "Apple Music"
            },
            {
                itemValue: "itunesUrl",
                itemLabel: "iTunes"
            },
            {
                itemValue: "googlePlayUrl",
                itemLabel: "Google Play"
            },
            {
                itemValue: "mediumUrl",
                itemLabel: "Medium"
            },
            {
                itemValue: "likeToKnowItUrl",
                itemLabel: "Like to Know It"
            },
        ];
    }
}

export default ApiHelper;
export { ApiHelper };