import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
import downgradeStep1Style from "../../../../assets/jss/dashboard/userLevelWizard/downgradeStep1Style";

const DowngradeStep2 = class extends React.Component {
    render(){
        const { classes, userPackage } = this.props;
        return(
            <div>
                <div className={classes.center}>
                    {
                        userPackage.level === 0 ?
                            <p>We got it Your account level will be downgraded to a free account at the end of your current subscription.</p>
                        :
                        <p>We got it! Your account level has been adjusted.</p>
                    }
                </div>
                <div className={classes.center+" "+classes.marginTopStep2}>
                    <Button onClick={() => this.props.onClose()} color="info" round>Close</Button>
                </div>
            </div>
        )
    }
}

DowngradeStep2.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};
export default withStyles(downgradeStep1Style)(DowngradeStep2);