import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';


import ratingStyle from "../../../assets/jss/material-kit-pro-react/components/ratingStyle.jsx";

class RatingComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: this.props.rating,
        };

    }
    componentWillReceiveProps(props){
        this.setState({
            rating: props.rating,
        });
    }
    render() {
        const { classes } = this.props;
        const { rating } = this.state;
        
        return (                
            <div className={classes.rating}>
                <Rating
                    name="customized-empty"
                    defaultValue={2}
                    value={rating}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    onChange={(event) => this.props.onChange(event)}
                    precision={0.5}
                />
            </div>
        );
    }
 
}

export default withStyles(ratingStyle)(RatingComponent);
