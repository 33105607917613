import React from 'react'
import { helper } from '../../../../assets/js/utils/Element'
import Api from '../../../../assets/js/utils/Api';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from '@material-ui/core/styles/withTheme';
import Link from "@material-ui/core/Link";
import GridContainer from "../../Grid/GridContainer.jsx";
import Grid from '@material-ui/core/Grid';
import GridItem from "../../Grid/GridItem.jsx";
import Button from "../../CustomButtons/Button.jsx";
import CustomInput from "../../CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import CustomSelectSocialIcons from "../../CustomInput/CustomSelectSocialIcons";
import { ReactSortable } from "react-sortablejs";
import Menu from "@material-ui/icons/Menu";
import CustomTagsInput from "../../CustomInput/CustomTagsInput.jsx";
import SocialIcon from "./SocialIcon";

import userAboutStyle from "../../../../assets/jss/dashboard/editmode/userAboutStyle";
let _that = null;

class UserAbout extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        const user = this.props.user;
        let socialIcons = Api.getSocialIcons();
        let values = Object.assign({}, user);
        if(!values.hasOwnProperty("bio") || values.bio.length <= 0){
            values['bio'] = "";
        }
        values['topSearchTerms'] = this.prepareSearchTerms(values);

        _that = this;

        this.state = {
            socialIcons: socialIcons,
            user: Object.assign({}, user),
            values: values,
            editName: false,
            editWebsite: false,
            editBio: false,
            editSocialIcons: false,
            editSearchTerms: false,
            validation: {
                firstName: user.firstName.trim().length > 0 ? "success":"",
                lastName: user.lastName.trim().length > 0 ? "success":""
            },
            remainingSocialIcons: socialIcons,
            selectedSocialIcons: socialIcons,
            sortableSocialIcons: this.prepareSortableSocialIcons(user.socialIcons),
            socialIconsValidation: {
                instagramUrl: "",
                facebookUrl: "",
                pinterestUrl: "",
                tiktokUrl: "",
                youtubeUrl: "",
                contactEmail: "",
                twitterUrl: "",
                linkedinUrl: "",
                snapchatUrl: "",
                soundcloudUrl: "",
                whatsappNumber: "",
                twitchUrl: "",
                patreonUrl: "",
                spotifyUrl: "",
                amazonLiveUrl: "",
                amazonShopUrl: "",
                appleMusicUrl: "",
                itunesUrl: "",
                googlePlayUrl: "",
                mediumUrl: "",
                likeToKnowItUrl: "",
            },
        };

        this.handleSocialIcon = this.handleSocialIcon.bind(this);
        this.onSocialIconsSort = this.onSocialIconsSort.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
        this.handleTagInput = this.handleTagInput.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleTags = this.handleTags.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        let values = Object.assign({}, props.user);
        values.firstName = state.values.firstName;
        values.lastName = state.values.lastName;
        values.socialIcons = state.values.socialIcons;
        values.bio = state.values.bio;
        values.topSearchTerms = state.values.topSearchTerms;
        const socialIcons = _that.updateSocialIconsStatus(values.socialIcons, true);
        const newState = {
            user: props.user,
            values: values
        };
        newState['socialIcons'] = socialIcons;
        newState['remainingSocialIcons'] = socialIcons;
        newState['selectedSocialIcons'] = socialIcons;
        newState['sortableSocialIcons'] = _that.prepareSortableSocialIcons(values.socialIcons);
        return newState;
    }
    prepareSearchTerms(values){
        let topSearchTerms = null;
        if(!values.hasOwnProperty("topSearchTerms")){
            topSearchTerms = [];
        }else{
            let topSearchTermsStr = "";
            if(typeof(values.topSearchTerms) === "string"){
                topSearchTermsStr = values.topSearchTerms.trim();
                let topSearchTermslen = topSearchTermsStr.length;
                if(topSearchTermslen <= 0){
                    topSearchTerms = [];
                }else{
                    topSearchTerms = values.topSearchTerms.split(',');
                }
            }
        };
        return topSearchTerms;
    }
    handleChange(event, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]: event.target.value
            }
        });
    }
    handleBioChange(event, name){
        let value = event.target.value;
        this.setState({
            values: {
                ...this.state.values, 
                [name]: value,
            }
        });
    }
    onClearInput(name){
        let state = {
            values: {
                ...this.state.values, 
                [name]: "",
            }
        };
        if(name === "firstName"){
            state['validation'] = {
                ...this.state.validation,
                firstName: "error"
            }
        }
        this.setState(state);
    }
    getDomainName(name){
        return helper.getSocialDomainName(name);
    }
    handleLinkChange(event, name) {
        let value = event.target.value;
        
        let domain = this.getDomainName(name);
        if((name === "tiktokUsername" || name === "mediumUrl") && value.substr(0,1) === "@"){
            value = value.substr(1);
        }
        try{
            new URL(value); 
        }catch(e){
            if((name !== "contactEmail" && name !== "whatsappNumber")  && 
                value.indexOf("http://") === -1 && value.indexOf("https://") === -1
            ){
                value = helper.prepareSocialUrl(domain,value)
            }
        }

        let domainUrl = helper.prepareSocialUrl(domain,'');
        if(value === domainUrl){
            value = null;
        }
        
        const values = {
            ...this.state.values, 
            socialIcons: {
                ...this.state.values.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    handleBlogLinkChange(event, name) {
        let value = event.target.value;
        let { values } = this.state;
        const socialIcons = values.socialIcons;
        try{
            new URL(value); //Check if valid url
        }catch(e){ //Fallback
            if(socialIcons.hasOwnProperty(name) && socialIcons[name].length === 0 && value.indexOf("http://") === -1 && value.indexOf("https://") === -1){
                value = "https://"+value;
            }
        }
        if(value === "https:/"){
            value = "https://";
        }
        
        values = {
            ...values, 
            socialIcons: {
                ...values.socialIcons,
                [name]: value,
            }
        };
        this.setState({
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    onClear(name){
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === name)[0];
        socialIcon.status = false;

        let socialIcons = this.state.values.socialIcons;
        if(socialIcons.hasOwnProperty(name)){
            delete socialIcons[name];
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                socialIcons: socialIcons
            },
            sortableSocialIcons: this.prepareSortableSocialIcons(socialIcons)
        });
    }
    prepareSortableSocialIcons(socialIcons){
        let sortableSocialIcons = [];
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            sortableSocialIcons.push({
                id: socialIcon,
                value: socialIcons[socialIcon],
                selected: false,
                chosen: false,
                filtered: false
            })
        }
        return sortableSocialIcons;
    }
    getUserName(){
        const user = this.state.user;
        let userName = "";
        if(user.hasOwnProperty("firstName")){
            userName = user.firstName;
        }
        if(user.hasOwnProperty("lastName") && user.lastName.length > 0){
            userName += " "+user.lastName;
        }
        return userName;
    }
    onSearchTerm(e, searchTerm){
        e.preventDefault();
        this.props.onSearchTerm(searchTerm);
    }
    renderSocialIcon(type){
        const socialIconsValues = this.state.user.socialIcons;
        return <SocialIcon renderType="icon" type={type} socialIcons={socialIconsValues} />;
    }
    prepareBlogUrl(url){
        let result = url.replace(/(^\w+:|^)\/\//, '');
        return result;
    }
    updateSocialIconsStatus(userSocialIcons = null, returnIcons = false){
        const { socialIcons } = this.state;
        const { user } = this.store.getState();
        if(userSocialIcons === null){
            userSocialIcons = user.socialIcons;
        }

        let socialIconsWithStatus = [];
        socialIcons.map((socialIcon) => {
            let status = false;
            let itemName = socialIcon.itemValue;
            if(userSocialIcons.hasOwnProperty(socialIcon.itemValue) === true && userSocialIcons[itemName] !== "" && userSocialIcons[itemName] !== null){
                status = true;
            }
            socialIconsWithStatus.push({
                itemValue: socialIcon.itemValue,
                itemLabel: socialIcon.itemLabel,
                status: status,
            });

            return null;
        });

        if(returnIcons){
            return socialIconsWithStatus;
        }

        this.setState({
            socialIcons: socialIconsWithStatus
        })

    }
    handleSocialIcon(event){
        let value = event.target.value;
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === value)[0];
        socialIcon.status = true;
        const values = {
            ...this.state.values, 
            socialIcons: {
                ...this.state.values.socialIcons,
                [socialIcon.itemValue]: null,
            }
        };

        this.setState({
            ...this.state,
            values: values,
            sortableSocialIcons: this.prepareSortableSocialIcons(values.socialIcons)
        });
    }
    renderSocialFields(){
        const { classes } = this.props;
        const { sortableSocialIcons } = this.state;
        let socialIconsRendered = [];
        sortableSocialIcons.map((socialIcon, key) => {
            if(socialIcon.id === "blogUrl"){
                return null;
            }
            socialIconsRendered.push(
                <li key={key}>
                    <span className={classes.sortIcon+" social_icons_sort"}><Menu /></span>
                    {this.renderSocialField(socialIcon.id)}
                </li>
            );
            return null;
        });
        return socialIconsRendered;
    }
    renderSocialField(type){
        const { values, socialIconsValidation } = this.state;
        return <SocialIcon renderType="field" type={type} onClear={(name) => this.onSocialIconClear(name)} values={values} socialIconsValidation={socialIconsValidation} handleLinkChange={(e, name) => this.handleLinkChange(e, name)} />;
    }
    renderSocialIconsField(){
        const { classes } = this.props;
        const { socialIcons, editSocialIcons, values } = this.state;
        let socialIconsCopy = Object.assign({}, values.socialIcons);
        if(socialIconsCopy.hasOwnProperty("blogUrl")){
            delete socialIconsCopy['blogUrl'];
        }
        const defaultOpen = (editSocialIcons && Object.keys(socialIconsCopy).length <= 0);
        return(
            <div className={classes.addSocialIcon}>
                <CustomSelectSocialIcons 
                    formControlProps={{
                        fullWidth: true
                    }}
                    selectProps={{
                        onChange: (e) => this.handleSocialIcon(e),
                        value: ""
                    }}
                    labelText={"+ Add Another Social icon"}
                    inputProps={{
                        name: "type",
                        id: "outlined-type",
                        className: classes.alignLeft,
                    }}
                    items={socialIcons}
                    itemLabel="itemLabel"
                    itemValue="itemValue"
                    defaultOpen={(defaultOpen)}
                    color={this.props.primaryColor}
                />
            </div>
        )
    }
    renderSocialIcons(){
        const socialIcons =  this.state.user.socialIcons;
        let socialIconsRendered = [];
        for (const socialIcon in socialIcons){
            const icon = this.renderSocialIcon(socialIcon);
            if(icon === null || socialIcon === "blogUrl"){
                continue;
            }
            socialIconsRendered.push(<li key={socialIcon}>
                {icon}
            </li>)
        }
        return socialIconsRendered;
    }
    onSocialIconClear(name){
        let socialIcon = this.state.socialIcons.filter(x => x.itemValue === name)[0];
        socialIcon.status = false;

        let socialIcons = this.state.values.socialIcons;
        if(socialIcons.hasOwnProperty(name)){
            delete socialIcons[name];
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values, 
                socialIcons: socialIcons
            },
            sortableSocialIcons: this.prepareSortableSocialIcons(socialIcons)
        });
    }
    getTermsStyle(){
        const { primaryColor } = this.props;
        const { palette } = this.props.theme;
        let termsStyle = {
            backgroundColor: primaryColor, 
            color: palette.custom.buttonText
        };
        return termsStyle;
    }
    editName(status = true){
        let state = {
            editName: status,
            editWebsite: false,
            editBio: false,
            editSearchTerms: false,
            editSocialIcons: false
        };
        if(!status){
            state['values'] = {
                ...this.state.values,
                firstName: this.state.user.firstName,
                lastName: this.state.user.lastName,
            }
        }
        this.setState(state);
    }
    editWebsite(status = true){
        const { user, values } = this.state;
        let state = {
            editWebsite: status,
            editName: false,
            editBio: false,
            editSearchTerms: false,
            editSocialIcons: false
        };
        if(!status){
            state['values'] = {
                ...values,
                socialIcons: {
                    ...values.socialIcons,
                    blogUrl: user.socialIcons.blogUrl ? user.socialIcons.blogUrl : "",
                }
            }
        }
        this.setState(state);
    }
    editBio(status = true){
        const { user, values } = this.state;
        let state = {
            editBio: status,
            editName: false,
            editWebsite: false,
            editSearchTerms: false,
            editSocialIcons: false
        };
        if(!status){
            state['values'] = values;
            if(!user.hasOwnProperty("bio") || user.bio.length <= 0){
                values['bio'] = "";
            }else{
                values['bio'] = user.bio;
            }
        }
        this.setState(state);
    }
    editSearchTerms(status = true){
        let state = {
            editSearchTerms: status,
            editName: false,
            editWebsite: false,
            editBio: false,
            editSocialIcons: false
        };
        if(!status){
            const user = this.store.getState();
            state['values'] = {
                ...this.state.values
            };
            state['values']['topSearchTerms'] = this.prepareSearchTerms(user);
        }
        this.setState(state);
    }
    editSocialIcons(status = true){
        let state = {
            editSocialIcons: status,
            editName: false,
            editWebsite: false,
            editBio: false,
            editSearchTerms: false,
        };
        if(!status){
            const { user } = this.store.getState();
            state['values'] = {
                ...this.state.values,
                socialIcons: user.socialIcons
            };
        }
        this.setState(state);
    }
    validateSocialIconsForm(){
        const { socialIcons } = this.state.values;
        if(typeof(socialIcons) !== "object"){
            return;
        }
        let isValid = true;
        let validation = this.state.socialIconsValidation;
        for (const socialIcon in socialIcons){
            if(socialIcon === "blogUrl"){
                continue;
            }
            validation[socialIcon] = "success";
            const value = socialIcons[socialIcon];
            if(!value || value === "" || value === "https://"){
                validation[socialIcon] = "error";
                isValid = false;
            }
        }
        this.setState({socialIconsValidation: validation});
        return isValid;
    }
    validateNameForm(){
        const { values } = this.state;
        const validation = {
            ...this.state.validation,
            firstName: "success",
            // lastName: "success"
        };
        let isValid = true;
        if(values.firstName.length <= 0){
            isValid = false;
            validation.firstName = "error";
        }
        // if(values.lastName.length <= 0){
        //     isValid = false;
        //     validation.lastName = "error";
        // }
        this.setState({validation: validation});
        return isValid;
    }
    onSuccess(type = null){
        if(type === "socialIcons"){
            const isValid = this.validateSocialIconsForm();
            if(!isValid){
                return;
            }
        }else if(type === "name"){
            const isValid = this.validateNameForm();
            if(!isValid){
                return;
            }
        }
        this.setState({
            editSocialIcons: false,
            editName: false,
            editWebsite: false,
            editBio: false,
            editSearchTerms: false,
        });
        const { values } = this.state;
        const valuesCopy = Object.assign({}, values);
        let regularTagsStr = valuesCopy.topSearchTerms.toString();
        valuesCopy.topSearchTerms = regularTagsStr;
        this.props.onUserUpdate({values: valuesCopy});
    }
    onSocialIconsSort(socialIcons){
        const socialIconsOld = this.state.values.socialIcons;
        let socialIconsNewOrder = {};
        socialIcons.map((socialIcon) => {
            socialIconsNewOrder[socialIcon.id] = socialIconsOld[socialIcon.id];
            return null;
        });
        if(socialIconsOld.hasOwnProperty("blogUrl")){
            socialIconsNewOrder['blogUrl'] = socialIconsOld.blogUrl;
        }
        this.setState({
            sortableSocialIcons: socialIcons,
            values: {
                ...this.state.values,
                socialIcons: socialIconsNewOrder
            }
        });
    }
    handleTags = regularTags => {
        let { topSearchTerms } = this.state.values;
        if(typeof(topSearchTerms) === "object" && regularTags.length > topSearchTerms.length){
            const lastIndex = regularTags[regularTags.length - 1];
            topSearchTerms = [lastIndex].concat(topSearchTerms);
        }else{
            topSearchTerms = regularTags;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: topSearchTerms,
            },
            tagInputValue: ""
        });
    };
    handleTagInput(event){
        this.setState({
            tagInputValue: event.target.value
        })
    }
    handleTagsChange(event){
        let tagValue = event.target.value;
        tagValue = tagValue.trim();
        let topSearchTerms = this.state.values.topSearchTerms;
        if(!tagValue){
            return;
        }
        this.setState({
            values: {
                ...this.state.values, 
                topSearchTerms: [tagValue, ...topSearchTerms],
            },
            tagInputValue: ""
        })
    }
    renderSearchTerms(){
        const { values } = this.state;
        if(!values.hasOwnProperty("topSearchTerms")){
            return null;
        }
        const { user } = this.props;
        const color = (user.userLevel >= 1 ? user.guestPreferences.primaryColor: "primary");
        const termsStyle = this.getTermsStyle();
        let searchTerms = [];
        values.topSearchTerms.map((term, key) => {
            searchTerms.push(
                <li key={key}>
                    <span className={"react-tagsinput-tag "+(color)} style={termsStyle}>
                        {term}
                        <a href="/" onClick={(e) => this.removeSearchTerm(e, term)}>{" "}</a>
                    </span>
                </li>
            );
            return null;
        });
        return searchTerms;
    }
    removeSearchTerm(e, term){
        e.preventDefault();
        const { topSearchTerms } = this.state.values;
        const index = topSearchTerms.indexOf(term);
        if(index > -1){
            topSearchTerms.splice(index, 1);
        }
        this.setState({
            values: {
                ...this.state.values,
                topSearchTerms: topSearchTerms
            }
        })
    }
    render(){
        const { classes } = this.props;
        const { user, values, editName, editWebsite, editBio, editSocialIcons, editSearchTerms, validation, sortableSocialIcons } = this.state;
        const socialIcons =  values.socialIcons;
        const { theme } = user.guestPreferences;
        const termsStyle = this.getTermsStyle();
        let topSearchTerms = values.topSearchTerms;
        if(typeof(topSearchTerms) === "string"){
            topSearchTerms = [];
        }

        let blogUrl = "https://";
        if(socialIcons.hasOwnProperty("blogUrl") && socialIcons.blogUrl.length > 0){
            blogUrl = socialIcons.blogUrl;
        }
        let socialIconsRendered = null;
        if(!editSocialIcons){
            socialIconsRendered = this.renderSocialIcons();
        }

        let template = "classic"
        if(user !== null && user.hasOwnProperty("guestPreferences") && user.guestPreferences.hasOwnProperty("template") && user.guestPreferences.template){
            template = user.guestPreferences.template;            
        }

        return (
            <Grid item className={classes.container+" "+(theme === "dark" ? classes.dark : classes.light)} xs={12} md={8} lg={8}>
                {
                    editName ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                            <GridItem className={classes.userAvatarContainer}>
                                <CustomInput
                                    success={validation.firstName === "success"}
                                    error={validation.firstName === "error"}
                                    id="outlined-firstName"
                                    labelText="First Name"                                    
                                    inputProps={{
                                        value: values.firstName,
                                        onChange: (e) => this.handleChange(e, 'firstName'),
                                        name: "firstName",
                                    }}
                                    // onClear={(e) => this.onClearInput("firstName")}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    color={this.props.primaryColor}
                                />
                                <CustomInput
                                    // success={validation.lastName === "success"}
                                    // error={validation.lastName === "error"}
                                    id="outlined-lastName"
                                    labelText="Last Name"                                    
                                    inputProps={{
                                        value: values.lastName,
                                        onChange: (e) => this.handleChange(e, 'lastName'),
                                        name: "lastName",
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    color={this.props.primaryColor}
                                />
                                <div className={classes.iconsContainer}>
                                    <Button justIcon onClick={() => this.editName(false)} color="danger">
                                        <Close />
                                    </Button>
                                    <Button justIcon onClick={() => this.onSuccess("name")} color="success">
                                        <Save />
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    :
                    <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing} onClick={() => this.editName()}>
                        <GridItem className={classes.userAvatarContainer}>
                            {
                                (user.hasOwnProperty("guestPreferences") && user.guestPreferences.theme === "dark") ?

                                    <h3 className={classes.title + " " + classes.darkTitle}>{this.getUserName()}</h3>
                                :
                                <h3 className={classes.title}>{this.getUserName()}</h3>
                            }
                        </GridItem>
                    </GridContainer>
                }
                {
                    editWebsite ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                            <CustomInput
                                id="outlined-blogUrl"
                                labelText="Website Url"                                    
                                inputProps={{
                                    value: blogUrl,
                                    onChange: (e) => this.handleBlogLinkChange(e, 'blogUrl'),
                                    name: "blogUrl",
                                }}                                    
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                color={this.props.primaryColor}
                            />
                            <div className={classes.iconsContainer}>
                                <Button justIcon onClick={() => this.editWebsite(false)} color="danger">
                                    <Close />
                                </Button>
                                <Button justIcon onClick={() => this.onSuccess()} color="success">
                                    <Save />
                                </Button>
                            </div>
                        </GridContainer>
                    :
                    <GridContainer justify="center" alignItems="center" className={classes.gridContainerSpacing} onClick={() => this.editWebsite()}>
                        {
                            socialIcons.hasOwnProperty("blogUrl") && socialIcons.blogUrl.length > 0 ?
                                <Link underline="always" className={classes.blogLink} style={{color: this.props.primaryColor}} target="_blank" href={helper.prepareExternalUrl(socialIcons.blogUrl)}>
                                    {this.prepareBlogUrl(socialIcons.blogUrl)}
                                </Link>
                            :
                            <p>Click to add your Website</p>
                        }
                    </GridContainer>
                }
                {
                    template !== "simple" ?
                        editSocialIcons ?
                            <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                                <GridItem className={classes.userAvatarContainer+" "+classes.socialLinksContent}>
                                    <ReactSortable
                                        tag="ul"
                                        handle=".social_icons_sort"
                                        direction="horizontal"
                                        dragoverBubble={true}
                                        animation={150}
                                        className={classes.sortableList+" "+classes.sortableListEnabled} 
                                        list={sortableSocialIcons}
                                        setList={this.onSocialIconsSort}
                                    >
                                        {this.renderSocialFields()}
                                    </ReactSortable>
                                    {this.renderSocialIconsField()}
                                    <div className={classes.iconsContainer}>
                                        <Button justIcon onClick={() => this.editSocialIcons(false)} color="danger">
                                            <Close />
                                        </Button>
                                        <Button justIcon onClick={() => this.onSuccess("socialIcons")} color="success">
                                            <Save />
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        :
                            <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing} onClick={() => this.editSocialIcons()}>
                                <GridItem className={classes.userAvatarContainer+" "+classes.socialIconsContainer}>
                                    {
                                        socialIconsRendered.length > 0 ?
                                            <ul>
                                                {socialIconsRendered}
                                            </ul>
                                        :
                                        <p>Click to add your Social Icons</p>
                                    }
                                </GridItem>
                            </GridContainer>
                    :
                    <></>
                }
                {
                    editBio ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.about+" "+classes.gridContainerSpacing}>
                            <CustomInput
                                id="outlined-bio"
                                labelText="Bio"                                    
                                inputProps={{
                                    value: values.bio,
                                    onChange: (e) => this.handleBioChange(e, 'bio'),
                                    name: "bio",
                                    multiline: true,
                                    rows: 4,
                                }}
                                onClear={() => this.onClearInput('bio')}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                color={this.props.primaryColor}
                            />
                            <div className={classes.iconsContainer}>
                                <Button justIcon onClick={() => this.editBio(false)} color="danger">
                                    <Close />
                                </Button>
                                <Button justIcon onClick={() => this.onSuccess()} color="success">
                                    <Save />
                                </Button>
                            </div>
                        </GridContainer>
                    :
                        <GridContainer justify="flex-start" alignItems="center" onClick={() => this.editBio()} className={classes.about+" "+classes.gridContainerSpacing}>
                            {
                                user.bio && user.bio.length > 0 ?
                                    <p>{user.bio}</p>
                                :
                                <p>Click to add your bio</p>
                            }
                        </GridContainer>
                }
                {
                    editSearchTerms ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.gridContainerSpacing}>
                            <div className={classes.searchTermsContainer}>
                                <CustomTagsInput
                                    tagProps={{
                                        value: values.topSearchTerms,
                                        onChange: this.handleTags,
                                    }}
                                    inputProps={{
                                        placeholder: "Add a search term",
                                        onBlur: this.handleTagsChange,
                                        onChange: this.handleTagInput,
                                        value: this.state.tagInputValue,
                                    }}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    color={(user.userLevel >= 1 ? user.guestPreferences.primaryColor: "primary")}
                                />
                                <ul className={"react-tagsinput "+classes.searchTerms}>
                                    {this.renderSearchTerms()}
                                </ul>
                            </div>
                            <div className={classes.iconsContainer}>
                                <Button justIcon onClick={() => this.editSearchTerms(false)} color="danger">
                                    <Close />
                                </Button>
                                <Button justIcon onClick={() => this.onSuccess()} color="success">
                                    <Save />
                                </Button>
                            </div>
                        </GridContainer>
                    :
                    topSearchTerms && topSearchTerms.length > 0 ?
                        <GridContainer justify="flex-start" alignItems="center" className={classes.topSearchTerms+" "+classes.gridContainerSpacing} onClick={() => this.editSearchTerms()}>
                            <label className={classes.topSearchTermsLabel}>
                                <span>Some of my top search terms</span>
                            </label>
                            <div className="react-tagsinput">
                                <span>
                                    {
                                        topSearchTerms.map((content, key) => {
                                            return (
                                                <Link onClick={(e) => this.onSearchTerm(e, content)} href={"/"+user.username+"?q="+content} key={key}>
                                                    <span className="react-tagsinput-tag rose" key={key} style={termsStyle}>{content}</span>
                                                </Link>
                                            )
                                        })
                                    }
                                </span>
                            </div>
                        </GridContainer>
                    :
                    <GridContainer justify="center" alignItems="center" className={classes.topSearchTerms+" "+classes.gridContainerSpacing} onClick={() => this.editSearchTerms()}>
                        <p>Click to Add Search Terms</p>
                    </GridContainer>
                }
            </Grid>
        )
    }
}

UserAbout.defaultProps = {
    onSearchTerm: function(){ }
}
UserAbout.propTypes = {
    classes: PropTypes.object,
    onSearchTerm: PropTypes.func
};
  
export default withTheme(withStyles(userAboutStyle)(UserAbout));