const socialIconStyle = theme => ({
    buttonSpacing: {
        marginRight: "-2px",
    },
    socialGridItem: {
        maxWidth: "none",
        width: "auto",
        flexBasis: "auto",
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    responsiveIconsGrid: {
        paddingRight: "0px",
        flexBasis: "calc( 100% - 45px)",
        maxWidth: "none",
        flexGrow: 0,
    },
    socialLinkPreview: {
        fontSize: "12px",
        width: "100%",
        textAlign: "left"
    },
    customIcon: {
        width: "41px",
        height: "41px",
        fontSize: "20px",
        minWidth: "41px",
        margin: ".3125rem 1px",
        verticalAlign: "bottom",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0px 14px 26px -12px rgba(89, 89, 89, 0.42), 0px 4px 23px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(204, 204, 204, 0.2)"
        }
    },
});

export default socialIconStyle;