
import React from "react";
import Api from '../../../../../assets/js/utils/Api';
import LoaderComponent from '../../../../components/Loader';
import axios from 'axios';
import PropTypes from "prop-types";
import Cookie from "../../../../../assets/js/utils/Cookie";
import { helper } from '../../../../../assets/js/utils/Element';

// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Wizard from "../../../../components/Wizard/Wizard.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import EditNotice from "./EditNotice";
import CredentialsSuccessNotice from "../InstagramLinkSteps/CredentialsSuccessNotice";
import instagramStepsStyle from "../../../../../assets/jss/dashboard/platforms/instagramStepsStyle.js";

class InstagramLinkEditWizard extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleFinish = this.handleFinish.bind(this);
        this.onNoticeClose = this.onNoticeClose.bind(this);
        this.onTokenSuccess = this.onTokenSuccess.bind(this);

        let saving = false;
        const credentialsMode = Cookie.read("platform_credentials");
        if(credentialsMode !== null && parseInt(credentialsMode) === 1){
            saving = true;
        }

        this.state = {
            saving: saving,
            cancelToken: null,
            successModal: false,
            user: null,
            instagramPlatform: this.props.instagramApiPlatform,
            platform: this.props.instagramApiPlatform,
            upgradeModal: false,
            loading: false
        };
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    componentWillReceiveProps(props){
        this.setState({
            instagramPlatform: props.instagramApiPlatform
        });
    }
    componentDidMount(){
        const authCode = helper.getParam("code");
        if(authCode && authCode !== "null"){
            this.authorizePlatform(authCode);
        }
    }
    getInstagramAccessToken(authCode){
        var requestData = {};
        requestData.code = authCode;
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source});
        Api.getInstagramAccessToken(requestData, source).then((data) => {
            this.onTokenSuccess(data.instagramAccessToken);
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.onNoticeClose();  
            }
        });
    }
    authorizePlatform(authCode){
        const { cancelToken } = this.state;
        const { user } = this.store.getState();
        if(cancelToken){
            cancelToken.cancel('Request Cancelled');
        }
        let platform = Object.assign({}, this.state.platform);
        platform.status = "Authorize Platform";
        platform.code = decodeURIComponent(authCode);

        const source = axios.CancelToken.source();
        this.setState({loading: true, cancelToken: source, successModal: this.isCredentialsMode()});
        Api.updatePlatform(platform, source, user.userId).then((data) => {
            if(data.numberOfResponses > 0){
                if(this.isCredentialsMode()){
                    this.setState({instagramPlatform: data.response[0], loading: false}, () => {
                        this.handleFinish();
                    });
                }else{
                    this.setState({platform: data.response[0], loading: false});
                }
            }
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({loading: false});
            }
        });
    }
    handleFinish(allStates = null){
        // const instagramHandle = allStates.account.username.replace(/\s/g, "");
        let values = {};
        if(allStates !== null){
            values = {
                type: "instagramAPI",
                robotFrequency: parseInt(allStates.robot.robotFrequency),
                allContentSearchable: allStates.robot.searchable,
                // instagramHandle: instagramHandle,
                addHashtagsToKeywords: allStates.robot.hashtags,
                attemptGeoTag: allStates.robot.attemptGeoTag,
                onlySmearchTags: allStates.robot.onlySmearchTags,
                useFirstLink: allStates.robot.useFirstLink,
                includeFirstCommentHashtags: allStates.robot.includeFirstCommentHashtags,
                instagramBeta: allStates.confirmation.notifyMe,
                instagramAccessToken: allStates.account.instagramAccessToken,
                retrieveAllMedia: allStates.robot.retrieveAllMedia,
                addRecognition: allStates.robot.addRecognition,
                importIGTV: allStates.robot.importIGTV,
                importReels: allStates.robot.importReels,
                importGuides: allStates.robot.importGuides,
            };
        }
        const { instagramPlatform } = this.state;
        if(instagramPlatform === null){
            this.setState({error: true, successModal: true}); 
            return;
        }
        values = Object.assign(instagramPlatform, values);
        if(values.hasOwnProperty("pendingData") && values.pendingData.hasOwnProperty("instagramHandle")){
            values.status = "Submit Platform";
        }
        if(values.status === "Authorize Platform"){
            values.status = "Submit Platform";
        }
        values.error = false;

        const { user } = this.store.getState();
        const that = this;
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source, successModal: this.isCredentialsMode()});        
        Api.updatePlatform(values, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                user.contentPlatforms.map((platform, key) => {
                    if(platform.contentPlatformId === data.response[0].contentPlatformId){
                        user.contentPlatforms[key] = data.response[0];
                    }
                    return null;
                });
                that.setState({successModal: true, user: user, saving: false, instagramPlatform: data.response[0]});  
            }
        }).catch(err => {
            console.log(err);
        });    
    }
    onNoticeClose(){
        const { instagramPlatform, error } = this.state;
        this.store.dispatch({type: 'UPDATE_STATE', state: {user: this.state.user }});
        if(error && instagramPlatform === null){
            this.history.push("/dashboard/platforms");
        }else{
            this.history.push("/dashboard/platforms");
            if(this.isCredentialsMode()){
                this.props.onWizardClose();
            }else{
                this.props.onWizardClose(instagramPlatform);
            }
        }
        Cookie.dispose("platform_id");
        Cookie.dispose("platform_credentials");
    }
    onTokenSuccess(instagramAccessToken){
        const credentialsMode = Cookie.read("platform_credentials");
        if(credentialsMode === null || parseInt(credentialsMode) !== 1){
            return;
        }
        const { instagramPlatform } = this.state;
        if(instagramPlatform === null){
            return;
        }
        Cookie.dispose("platform_id");
        Cookie.dispose("platform_credentials");

        const that = this;
        const { user } = this.store.getState();
        instagramPlatform.instagramAccessToken = instagramAccessToken;
        const source = axios.CancelToken.source();
        this.setState({saving: true, cancelToken: source});        
        Api.updatePlatform(instagramPlatform, source, user.userId).then((data) => { 
            if(data.numberOfResponses > 0){
                that.props.onWizardClose(instagramPlatform);
            }else{
                this.setState({error: true, cancelToken: null, successModal: true, saving: false}); 
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onWizardClose(){
        const { user } = this.store.getState();
        const { instagramPlatform, platform } = this.state;
        if(instagramPlatform !== null && platform !== null && platform.status !== instagramPlatform.status){
            const source = axios.CancelToken.source();
            instagramPlatform.status = "Reset Platform";
            this.setState({saving: true, cancelToken: source});
            Api.updatePlatform(instagramPlatform, source, user.userId).then((data) => { 
                if(data.numberOfResponses > 0){
                    this.props.onWizardClose(data.response[0]);
                    this.props.history.push("/dashboard/platforms");
                }
            }).catch(err => {
                console.log(err);
            });
        }else{
            this.props.onWizardClose(instagramPlatform);
            this.props.history.push("/dashboard/platforms");
        }
    }
    isCredentialsMode(){
        const credentialsMode = Cookie.read("platform_credentials");
        return (credentialsMode !== null && parseInt(credentialsMode) === 1);
    }
    render() {
        const { authorized } = this.props.store.getState();
        const { classes } = this.props;
        const { successModal, saving, error, instagramPlatform, platform, loading } = this.state;
        if(!authorized){
            return <></>
        }
        if(successModal){
            if(this.isCredentialsMode()){
                return <CredentialsSuccessNotice store={this.store} onClose={this.onNoticeClose} open={successModal} error={error} saving={saving} />;
            }else{
                return <EditNotice store={this.store} onClose={this.onNoticeClose} open={successModal} error={error} saving={saving} />;
            }
        }

        return (
            <GridContainer justify="center" className={classes.gridContainer}>
                <GridItem xs={12} sm={12} md={10} className={classes.gridItemSpacing}>
                    {
                        saving ?
                            <LoaderComponent align="center" />
                        :
                        <>
                            <Button color="transparent" justIcon className={classes.closeButton} onClick={() => this.onWizardClose(instagramPlatform)}>
                                <Close />
                            </Button>
                            <Wizard
                                validate
                                store={this.store}
                                steps={[
                                    { stepName: "Authorization", stepComponent: Step1, stepId: "account", props: {platform: platform, instagramPlatform: instagramPlatform, loading: loading} },
                                    { stepName: "Instructions", stepComponent: Step2, stepId: "robot", props: {platform: instagramPlatform} },
                                    { stepName: "Confirm", stepComponent: Step3, stepId: "confirmation" }
                                ]}
                                title="Link Your Instagram Account"
                                subtitle="We will automatically load your search engine!"
                                finishButtonText="Done!"
                                finishButtonClick={this.handleFinish}
                                color="primary"
                            />
                        </>
                    }
                    
                </GridItem>
                {/* {
                    successModal ?
                        <EditNotice store={this.store} onClose={this.onNoticeClose} open={successModal} error={error} saving={saving} />
                    :
                        <></>
                } */}
            </GridContainer>
        );
    }
}

InstagramLinkEditWizard.defaultProps = {
    onWizardClose: function(){ },
}

InstagramLinkEditWizard.propTypes = {
    onWizardClose: PropTypes.func,
    instagramApiPlatform: PropTypes.object
}
export default withStyles(instagramStepsStyle)(InstagramLinkEditWizard);
