import React from 'react'

import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import userLocationStyle from "../../../assets/jss/location/userLocationStyle";
class UserLocation extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        const storeState = this.store.getState();

        const radiusArray = [1,2,3,4,5,10,15,20,25,35,50,75,100,250];
        this.state = {
            isLocationEnabled: storeState.isLocationEnabled,
            changeZipCode: false,
            changeRadius: false,
            radiusArray: radiusArray,
            mapGetDirection: null,
            lat: storeState.lat,
            lng: storeState.lng,
            address: storeState.address,
            radius: storeState.radius,
            radiusType: storeState.radiusType,
        };

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.requestUserLocation = this.requestUserLocation.bind(this);
        this.initGoogleAutocomplete = this.initGoogleAutocomplete.bind(this);
    }
    componentDidMount(){
        const that = this;
        if(navigator.permissions && this.state.address.length <= 0){            
            navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
                if(PermissionStatus.state === 'granted' || (PermissionStatus.state === 'prompt' && that.state.isLocationEnabled)){
                    try{
                        that.requestUserLocation();
                    }catch(e){ }
                }
            })
        }else if(this.state.address.length <= 0 && that.state.isLocationEnabled){
            try{
                that.requestUserLocation();
            }catch(e){ }
        }
    }
    componentDidUpdate(){
        if(this.state.changeZipCode){
            this.initGoogleAutocomplete();
        }
    }
    handleCheckbox(event, name) {
        let state = [];
        state[name] = event.target.checked;
        if(name === "isLocationEnabled"){
            this.store.dispatch({type: 'UPDATE_STATE',state: state});
        }else{
            this.setState(state);
        }        
    }
    handleLocationChange(event, name) {
        let state = [];
        state[name] = event.target.value;
        this.setState(state);
    }
    handleChange(name){
        let state = [];
        state[name] = (this.state[name] ? false: true);
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
    }
    loadDefaultMap(){
        const mapGetDirection = new window.google.maps.Map(document.getElementById("location_map"),  {
            zoom: 4,
            center: new window.google.maps.LatLng(56.130366,-106.34677099999999),
            navigationControl: true,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            maxZoom: 14
        });
        this.setState({mapGetDirection: mapGetDirection});
    }
    requestUserLocation(e){
        const that = this;
        if(this.state.mapGetDirection === null){
            this.loadDefaultMap();
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;
                that.state.mapGetDirection.setCenter({lat:lat, lng:lng});
                that.state.mapGetDirection.setZoom(9);
                var myoverlay = new window.google.maps.OverlayView();
                myoverlay.draw = function () {
                    this.getPanes().markerLayer.id = 'markerLayer';
                };
                myoverlay.setMap(that.state.mapGetDirection);
                if(!position.address) {
                  var service = new window.google.maps.places.PlacesService(that.state.mapGetDirection);
                  var request = {
                    location: new window.google.maps.LatLng(lat,lng), 
                    radius: 500
                  };
                                                                                          
                  service.search(request, function(results, status) {
                    if (status  ===  'OK') {
                      var index = 0;
                      var radian = 3.141592653589793/ 180;
                      var my_distance = 1000; 
                      for (var i = 0; i < results.length; i++){
                        var R = 6371; // km
                        var lat2 = results[i].geometry.location.lat();
                        var lon2 = results[i].geometry.location.lng(); 
                        var dLat = (lat2-lat) * radian;
                        var dLon = (lon2-lng) * radian;
                        var lat1 = lat * radian;
                        lat2 = lat2 * radian;
    
                        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                        var d = R * c;
                        if(d < my_distance) {
                          index = i;
                          my_distance = d;
                        }
                      }          
                    //   var location = (results[index].vicinity) ? results[index].vicinity :'';
                    //   const state = {
                    //     address: location,
                    //     lat: lat,
                    //     lng: lng,
                    //     isLocationEnabled: true,
                    //   };
                    //   that.setState(state);
                      that.loadPlaceDetails(results[index].place_id);
                    }
                  });    
                }else{
                    const delimiter = (position.address && position.address.street !==  '' && position.address.city !==  '') ? ', ' : '';   
                    const location= (position.address) ? (position.address.street + delimiter + position.address.city) : '';
                    const state = {
                        address: location,
                        lat: lat,
                        lng: lng,
                        isLocationEnabled: true,
                    };
                    that.setState(state);
                    // that.store.dispatch({type: 'UPDATE_STATE',state: state});
                }
            },function(){
                //Failed to get location.
            });
        }else{
            //Location not available due to old browser.
        }
    }
    initGoogleAutocomplete(){
        try{
            const that = this;
            var mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('location'));
            window.google.maps.event.addListener(mapAutoComplete, 'place_changed', function() {
                var place = mapAutoComplete.getPlace();
                if (!place.geometry) {
                        return;
                }
                that.populatePlace(place, document.getElementById('location').value.replace(/"/g, ""));
            });
        }catch(e){ console.log(e); }
    }
    loadPlaceDetails(placeId){
        if(this.state.mapGetDirection === null){
            this.loadDefaultMap();
        }
        var request = {
            placeId: placeId,
            fields: ['name', 'formatted_address', 'place_id', 'geometry', 'address_components']
        };
        const that = this;
        var service = new window.google.maps.places.PlacesService(this.state.mapGetDirection);
        service.getDetails(request, function(place, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                if(document.getElementById('location')){
                    document.getElementById('location').value = place.formatted_address.replace(/"/g, "");
                }                
                that.populatePlace(place, place.formatted_address.replace(/"/g, ""));
            }
        });
    }
    populatePlace(place, location){
        const locationState = {
            address: location,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        };
        this.setState(locationState);
        // this.store.dispatch({type: 'UPDATE_STATE',state: locationState});
    }

    render(){
        const { classes } = this.props;
        const { isLocationEnabled, changeZipCode, changeRadius, radiusArray, address, radius, radiusType } = this.state;
        return(
            <div>
                <GridItem>
                    <h3 className={classes.title}>
                        Set Your Default Location
                    </h3>
                </GridItem>
                <div id="location_map" style={{display: "none"}}></div>
                <form onSubmit={this.handleSubmit}>
                    <GridItem xs={12} sm={8} md={8} lg={8} className={classes.spacing}>
                        <label htmlFor="isLocationEnabled" className={classes.label}>Current Location</label>
                        <Switch
                            checked={isLocationEnabled}
                            onChange={(e) => this.handleCheckbox(e,"isLocationEnabled")}
                            value="isLocationEnabled"
                            id="isLocationEnabled"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                        />                    
                    </GridItem>
                    {
                        isLocationEnabled ?
                        <div>
                            <GridItem xs={12} sm={10} md={8} lg={8} className={classes.spacing}>
                                <Button type="button" color="primary" round onClick={this.requestUserLocation}>
                                    Request Location Services
                                </Button>
                            </GridItem>
                            {
                                (process.env.REACT_APP_DEBUG === true || process.env.REACT_APP_DEBUG === "true") ?
                                    <GridItem xs={12} sm={10} md={8} lg={8} className={classes.spacing}>
                                        <p className={classes.locationData}><b>Location:</b> {address}</p>
                                        <p className={classes.locationData}><b>Latitude:</b> {this.state.lat}</p>
                                        <p className={classes.locationData}><b>Longitude:</b> {this.state.lng}</p>
                                    </GridItem>
                                :
                                    <></>
                            }
                        </div>
                        :
                        <></>
                    }

                    <GridItem xs={12} sm={10} md={8} lg={8} className={classes.spacing}> 
                        <GridContainer alignItems="center">
                            <GridItem xs={7} sm={7} md={8} lg={8}>
                                {
                                    address.length > 0 ?
                                        <p>{address}</p>
                                    :
                                        <p>Zip or City/State</p>
                                }
                            </GridItem>
                            <GridItem xs={5} sm={5} md={4} lg={4}>
                                <Button
                                    color="primary"
                                    className={classes.iconButton}
                                    aria-label="Change"
                                    component="span"
                                    round
                                    onClick={() => this.handleChange("changeZipCode")}
                                    >
                                    Change
                                </Button>
                            </GridItem>
                        </GridContainer>                                
                    </GridItem>
                    {
                        changeZipCode ?
                            <GridItem xs={12} sm={10} md={8} lg={8} className={classes.spacing}> 
                                <GridContainer alignItems="center">
                                    <GridItem xs={8} sm={8} md={8} lg={8}> 
                                        <CustomInput
                                            id="location"
                                            labelText="Enter Zip or City/State"                                            
                                            inputProps={{
                                                placeholder: "",
                                                value: address,
                                                onChange: (e) => this.handleLocationChange(e,"address")
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={3} sm={3} md={3} lg={3}>
                                        <Button
                                            color="primary"
                                            className={classes.iconButton}
                                            aria-label="OK"
                                            component="span"
                                            round
                                            onClick={() => this.store.dispatch({type: 'UPDATE_STATE',state: {
                                                address: this.state.address,
                                                lat: this.state.lat,
                                                lng: this.state.lng
                                            }})}
                                            >
                                            OK
                                        </Button>
                                    </GridItem>
                                </GridContainer>                                
                            </GridItem>
                        :
                            <></>
                    }

                    <GridItem xs={12} sm={10} md={8} lg={8}> 
                        <GridContainer alignItems="center">
                            <GridItem xs={7} sm={7} md={8} lg={8}>
                                <p>{radius} {radiusType}</p>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={4} lg={4}>
                                <Button
                                    color="primary"
                                    className={classes.iconButton}
                                    aria-label="Change"
                                    component="span"
                                    round
                                    onClick={() => this.handleChange("changeRadius")}
                                    >
                                    Change
                                </Button>
                            </GridItem>
                        </GridContainer>                                
                    </GridItem>
                    {
                        changeRadius ?
                            <GridItem xs={12} sm={10} md={8} lg={6}> 
                                <GridContainer alignItems="center">
                                    <GridItem xs={12} sm={4} md={5} lg={5}> 
                                        <FormControl className={classes.selectFormControl} fullWidth>
                                            <InputLabel
                                                htmlFor="outlined-radius"
                                                className={classes.selectLabel}
                                            >
                                                Miles Radius
                                            </InputLabel>                                
                                            <Select
                                                onChange={(e) => this.handleLocationChange(e,"radius")}
                                                value={radius}
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                    classes: { paper: classes.selectPaper }
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                inputProps={{
                                                    name: "radius",
                                                    id: "outlined-radius"
                                                }}
                                            >
                                                {radiusArray.map((radius) => (
                                                    <MenuItem
                                                        key={radius} 
                                                        value={radius}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                    >
                                                        {radius}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={5} lg={5}>
                                    <FormControl className={classes.selectFormControl} fullWidth>
                                            <InputLabel
                                                htmlFor="outlined-radiusType"
                                                className={classes.selectLabel}
                                            >
                                                Radius Type
                                            </InputLabel>                                
                                            <Select
                                                onChange={(e) => this.handleLocationChange(e,"radiusType")}
                                                value={radiusType}
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                    classes: { paper: classes.selectPaper }
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                inputProps={{
                                                    name: "radiusType",
                                                    id: "outlined-radiusType"
                                                }}
                                            >
                                                {["Miles","KM"].map((type) => (
                                                    <MenuItem
                                                        key={type} 
                                                        value={type}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                    >
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2} lg={2}>
                                        <Button
                                            color="primary"
                                            className={classes.iconButton}
                                            aria-label="OK"
                                            component="span"
                                            round
                                            onClick={() => this.store.dispatch({type: 'UPDATE_STATE',state: {
                                                radius: this.state.radius,
                                                radiusType: this.state.radiusType
                                            }})}
                                            >
                                            OK
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                
                            </GridItem>
                        :
                            <></>
                    }
                </form>
            </div>
        )
    }
}

export default withStyles(userLocationStyle)(UserLocation);