import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import linktreeIcon from "../../../assets/img/linktree-platform-icon.png";
import userPlatformAddModalStyle from "../../../assets/jss/dashboard/userPlatformAddModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LinktreeModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="linktree-slide-title"
                aria-describedby="linktree-slide-description"
            >   
            <DialogContent
                id="linktree-slide-description"
                className={classes.modalBody}
            >
                <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    {" "}
                    <Close className={classes.modalClose} />
                </Button>
                <img src={linktreeIcon} alt="linktree" className={classes.linktreeIcon}/>
                <p>Would you like to import your Linktr.ee links? This is a great way to quickly setup your account.</p>
            </DialogContent>
            <DialogActions
            className={classes.modalFooter + " " + classes.justifyContentCenter}
            >
                <Button onClick={() => this.props.onNever()} color="white">No Thanks</Button>
                <Button onClick={() => this.props.onClick()} color="blue">Yes!</Button>
            </DialogActions>
            
        </Dialog>
        );
    }
};

LinktreeModal.defaultProps = {
    open: false,
    onNever: function(){ }
};
LinktreeModal.propTypes = {
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    open: PropTypes.bool,
    onNever: PropTypes.func,
};
export default withStyles(userPlatformAddModalStyle)(LinktreeModal);
