import {
    primaryColor,
    infoColor,
    roseColor,
    grayColor,
    successColor,
    primaryBoxShadow,
    infoBoxShadow,
    successBoxShadow,
    roseBoxShadow,
    blackColor,
    hexToRgb,
    whiteColor
} from "../../material-kit-pro-react.jsx";

const dashboardTabProfileThemeStyle = theme => ({
    mainContainer: {
        marginTop: "0px"
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight:"bold",
        textAlign: "center"
    },
    gridItem: {
        paddingLeft: "1px",
        paddingRight: "1px"
    },
    fontPreview: {
        fontSize: "18px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "16px"
        }
    },
    primaryColor: {
        paddingLeft: "0px",
        paddingRight: "0px",
        marginTop: theme.spacing(1),
        "& li": {
            "& span": {
                width: "50px",
                height: "50px",
                display: "block",
                background: primaryColor[0],
                ...primaryBoxShadow,
                [theme.breakpoints.down("sm")]: {
                    width: "40px",
                    height: "40px",
                },
                [theme.breakpoints.down("xs")]: {
                    width: "30px",
                    height: "30px",
                },
            },            
            display: "inline-block",
            marginRight: "10px",
            cursor: "pointer",
            border: "2px solid transparent",
            padding: "4px",
            [theme.breakpoints.down("sm")]: {
                marginRight: "5px",
                padding: "2px",
            },
            "&.active": {
                border: "2px solid "+blackColor,
            }
        }
    },
    grayColor: {
        "& span": {
            background: grayColor[26]+" !important",
            boxShadow:
                "0 4px 20px 0px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.14), 0 7px 10px -5px rgba(" +
                hexToRgb(grayColor[26]) +
                ", 0.4)"
        }
    },
    infoColor: {
        "& span": {
            background: infoColor[0]+" !important",
            ...infoBoxShadow
        }
    },
    roseColor: {
        "& span": {
            background: roseColor[0]+" !important",
            ...roseBoxShadow,
        }
    },
    successColor: {
        "& span": {
            background: successColor[0]+" !important",
            ...successBoxShadow
        }
    },
    customInfo: {
        "& span": {
            background: infoColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(infoColor[8]) +
                ", 0.2)"
        }
    },
    customRose: {
        "& span": {
            background: roseColor[5]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(roseColor[5]) +
                ", 0.2)"
        }
    },
    customSuccess: {
        "& span": {
            background: successColor[8]+" !important",
            boxShadow:
                "0 12px 20px -10px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.28), 0 4px 20px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 7px 8px -5px rgba(" +
                hexToRgb(successColor[8]) +
                ", 0.2)"
        }
    },
    imageUpload: {
        textAlign: "center",
        "& button.removeImage": {
            // display: "none !important"
        },
        "& img": {
            maxWidth: "200px"
        },
        "& .ImageUploadButtons, & .thumbnail": {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
        },
        "& .fileinput": {
            display: "block"
        },
        "& .info_tooltip_icon": {
            display: "block",
            margin: "0px auto"
        }
    },
    hideRemoveButton: {
        "& button.removeImage": {
            display: "none !important"
        },
    },
    imageUploadLabel: {
        marginBottom: theme.spacing(1),
        display: "inline-block",
    },
    customLabel: {
        margin: theme.spacing(1, 1, 2, 0),
    },
    form: {
        marginBottom: "0px"
    },
    disableColor: {
        "& li": {
            cursor: "default"
        }
    },
    saveButtons: {
        display: "flex",
        justifyContent: "space-between",
    },
    editButton: {
        display: "flex",
        justifyContent: "flex-end"
    },
    editButtonBottom: {
        marginTop: "10px"
    },
    marginBottom: {
        marginBottom: "10px"
    },
    upgradePlan: {
        marginTop: theme.spacing(1)
    },
    colorPicker: {
        position: "relative",
        "& img": {
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            lineHeight: "30px",
            margin: "auto",
        },
        "& .color-picker-input": {
            position: "absolute",
            bottom: "5px",
            zIndex: "-1",
            visibility: "hidden",
        }
    },
    buttonShapes: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        textAlign: "center",
        "& label": {
            display: "block",
            marginRight: "0px",
            marginBottom: theme.spacing(2),
        },
        "& div": {
            marginBottom: theme.spacing(1),
            padding: "6px",
            maxWidth: "100%",
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
            "&.active": {
                border: "2px solid "+blackColor,
                padding: "4px"
            }
        },
        "& button": {
            margin: "0px",
            width: "100%"
        }
    },
    disableButton: {
        "& button": {
            cursor: "default",
        }
    },
    fontSize: {
        listStyle: "none",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "center",
        marginBottom: "0px",
        marginTop: theme.spacing(2),
        "& li": {
            display: "inline-block",
            verticalAlign: "middle",
            margin: theme.spacing(0, 1),
            cursor: "pointer",
            padding: "6px",
            "& span": {
                display: "block",
            },
            "&.large":{
                "& span": {
                    width: "40px",
                    height: "40px",
                    lineHeight: "40px",
                    fontSize: "30px",
                }
            },
            "&.small":{
                "& span": {
                    width: "20px",
                    height: "20px",
                    lineHeight: "20px"
                }
            },
            "&.active": {
                border: "2px solid "+blackColor,
                padding: "4px"
            }
        }
    },
    disableFont: {
        "& li": {
            cursor: "default"
        }
    },
    layoutStyle: {
        display: "flex",
        justifyContent: "space-around",
        marginBottom: theme.spacing(3),
        "& .layout": {
            cursor: "pointer",
            width: "42%",
            textAlign: "center",
            [theme.breakpoints.down("xs")]: {
                width: "48%"
            },
            "& >div": {
                padding: "6px",
                "&.active": {
                    padding: "4px",
                    border: "2px solid "+blackColor
                }
            },
            "& .layout-content":{
                padding: "8px",
                border: "2px solid "+blackColor,
                position: "relative",
                paddingTop: "43px",
                "& >ul": {
                    backgroundColor: whiteColor,
                    border: "2px solid "+blackColor,
                    borderRadius: "30px",
                    padding: '20px',
                    "& .columns":{
                        width: "100%",
                        display: "block",
                        "& ul": {
                            display: "flex",
                            justifyContent: "space-between",
                        }
                    },
                    "& .three-column": {
                        "& li": {
                            width: "36px",
                            height: "36px",
                            border: "2px solid "+blackColor,
                            borderRadius: "5px",
                            backgroundColor: "#999999",
                        }
                    },
                    "& .two-column": {
                        marginTop: "20px",
                        "& li": {
                            width: "48%",
                            height: "30px",
                            border: "2px solid "+blackColor,
                            backgroundColor: "#ad02f1",
                        }
                    },
                    "& .one-column": {
                        marginTop: "20px",
                        "& ul": {
                            display: "block !important"
                        },
                        "& li": {
                            marginTop: "20px",
                            width: "100%",
                            height: "30px",
                            border: "2px solid "+blackColor,
                            backgroundColor: "#ad02f1",
                            // borderRadius: "3px"
                        }
                    }
                },
                "& .search-input": {
                    backgroundColor: "#fff",
                    // borderRadius: "3px"
                }
            },
            "&.light-layout": {
                "& .layout-content": {
                    backgroundColor: "#acacac"
                }
            },
            "&.dark-layout": {
                "& .layout-content>ul": {
                    backgroundColor: "#666666",
                    "& .three-column": {
                        "& li": {
                            backgroundColor: "#4a4a4a",
                        }
                    },
                },
                "& .search-input": {
                    backgroundColor: "#4a4a4a !important",
                    color: whiteColor
                }
            },
            "&.layout-rounded": {
                "& .search-input, & .one-column li": {
                    borderRadius: "30px !important"
                }
            }
        },
        "& ul": {
            listStyle: "none",
            padding: "0px",
            margin: "0px",
        }
    },
    disableLayout: {
        "& .layout": {
            cursor: "default"
        }
    },
    rangerSlider: {
        margin: theme.spacing(4, 0),
        textAlign: "center",
        "& .slidecontainer": {
            maxWidth: "400px",
            width: "100%",
            margin: theme.spacing(3, "auto", 0)
        },
        "& .slider": {
            "-webkit-appearance": "none",
            width: "100%",
            height: "15px",
            borderRadius: "5px",
            background: "#d3d3d3",
            outline: "none",
            opacity: "1",
            "-webkit-transition": ".2s",
            transition: "opacity .2s",
            "&::-webkit-slider-thumb": {
                "-webkit-appearance": "none",
                appearance: "none",
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            },
            "&::-moz-range-thumb": {
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                background: primaryColor[0],
                cursor: "pointer",
            }
        },
    },
    disableOpacity: {
        "& .slider": {
            cursor: "default",
            "&::-webkit-slider-thumb": {
                cursor: "default",
            },
            "&::-moz-range-thumb": {
                cursor: "default",
            }
        }
    },
    profileAvatar: {
        position: "absolute",
        left: "0px",
        right: "0px",
        margin: "0px",
        top: "5px",
        "& img": {
            width: "70px",
            height: "70px",
            borderRadius: "100%",
            objectFit: "cover"
        }
    },
    searchInput: {
        marginTop: "30px",
        border: "2px solid "+blackColor,
        userSelect: "none"
    }
});

export default dashboardTabProfileThemeStyle;