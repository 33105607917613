import {
    
} from "../../material-kit-pro-react.jsx";

import dashboardSubTabs from "../tabs/dashboardSubTabs";

const dashboardTabSettingsStyle = theme => ({
    ...dashboardSubTabs(theme),
    tabImageIcon: {
        width: "30px",
        height: "30px",
        objectFit: "scale-down",
        borderRadius: "100%",
        margin: "0px !important",
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "20px",
            height: "20px",
            margin: "0px !important",
        }
    },
});

export default dashboardTabSettingsStyle;