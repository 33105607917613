import Joyride from "react-joyride";
import React from "react";
import PropTypes from "prop-types";
import TourTooltip from "./TourTooltip";

class Tour extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            run: false,
            steps: [
                {
                    target: ".tab-preview-profile",
                    disableBeacon: true,
                    content: "Click here to easily edit your page settings."
                },
                {
                    target: ".tab-my-profile",
                    disableBeacon: true,
                    content: "Click here to edit your profile settings."
                },
                {
                    target: ".tab-my-add-manage",
                    content: "Click here to manage your content."
                },
                // {
                //     target: ".tab-my-analytics",
                //     content:
                //     "Click here to see your analytics"
                // },
                // {
                //     target: ".tab-my-circles",
                //     content: "Click here to manage your circles"
                // },
                // {
                //     target: ".tab-my-robots",
                //     content: "Click here to create and program bots to help manage your account automatically"
                // },
                {
                    target: ".tab-my-account",
                    content: "Click here to change your account level and manage your payment information"
                },
                {
                    target: ".tab-my-settings",
                    content: "Click here to manage all other application settings"
                },
                {
                    target: ".my-search-link",
                    content: "This is your unique search engine link that is globally available.  Paste it on your Instagram bio link, your blog, or even your email signature.  Anywhere you want your fans to be able to find your content!"
                },
                {
                    target: ".my-search-link-copy",
                    content: "Click this button to easily copy the link"
                },
                {
                    target: ".tab-my-help",
                    content: "Click here if you have any other questions or want to contact us.  We are here to help get your personal search engine up and running!"
                }
            ]
        };
    }

    render() {
        const { steps } = this.state;
        const that = this;
        return (
        <Joyride
            steps={steps}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            run={this.props.run}
            tooltipComponent={TourTooltip}
            callback={function(data){
                if(data.type === "tour:end"){
                    that.props.onClose();
                }
            }}
        />
        );
    }
}

Tour.defaultProps = {
    onClose: function(){ }
};
Tour.propTypes = {
    onClose: PropTypes.func,
};

export default Tour;
