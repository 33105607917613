import customCheckboxRadioSwitch from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const downgradeStep1Style = theme => ({
    ...customCheckboxRadioSwitch,
    center: {
        textAlign: "center",
        "& .card_details > label": {
            display: "none !important"
        }
    },
    bold: {
        fontWeight: "bold"
    },
    overflowHidden: {
        overflow: "hidden"
    },
    nextButton: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    centerStep2: {
        textAlign: "center",
        "& p": {
            marginBottom: "0px"
        }
    },
    marginTopStep2: {
        marginTop: theme.spacing(2)
    }
});

export default downgradeStep1Style;
