import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../../components/Loader";
import platformDeleteModalStyle from "../../../assets/jss/dashboard/platformDeleteModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PlatformDeleteModal = class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            secondsWait: false
        }
    }
    componentWillReceiveProps(props){
        if(props.wait){
            const that = this;
            that.setState({ 
                secondsWait: true
            });
            setTimeout(function(){
                that.setState({ 
                    secondsWait: false
                });
                that.props.onSuccess(true);
            },3500);
        }
    }
    getPlatformLabel(){
        const { platform } = this.props;
        let label = "Instagram";
        if(platform.type === "youtubeAPI"){
            label = "YouTube";
        }
        return label;
    }
    render() {
        const { classes } = this.props;
        const { secondsWait } = this.state;
        return (
            <Dialog
                classes={{
                root: classes.modalRoot,
                paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="platformDelete-slide-title"
                aria-describedby="platformDelete-slide-description"
            >
                {
                    secondsWait === true ?
                        <DialogTitle
                            id="youtubeLink-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                            >
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>Please give us a few minutes while we clean things up.</h4> 
                        </DialogTitle>
                    :
                    <></>
                }
                <DialogContent
                    id="platformDelete-slide-description"
                    className={classes.modalBody}
                    >
                    
                    {
                        secondsWait ?
                            <LoaderComponent align="center" color="success" />
                        :
                        <p>
                            Are you sure you want to delete this content platform?  All {this.getPlatformLabel()} search links will be removed from your Search My Social account.  This will NOT delete or change anything in your {this.getPlatformLabel()} accounts.
                        </p>
                    }
                </DialogContent>
                {
                    secondsWait === false ?
                        <DialogActions
                            className={classes.modalFooter + " " + classes.justifyContentCenter}
                            >
                            <Button onClick={() => this.props.onClose()} color="white">
                                <ArrowBack />
                                Cancel
                            </Button>
                            <Button className={classes.rightButton} onClick={() => this.props.onSuccess(false)} color="danger">
                                <Close />
                                Remove It
                            </Button>                    
                        </DialogActions>
                    :
                    <></>
                }
            </Dialog>
        );
    }
};

PlatformDeleteModal.defaultProps = {
    open: false,
    wait: false
};
PlatformDeleteModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    platform: PropTypes.object.isRequired,
    wait: PropTypes.bool
};
export default withStyles(platformDeleteModalStyle)(PlatformDeleteModal);
