import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import upgradeStep1Style from "../../../../assets/jss/dashboard/userLevelWizard/upgradeStep1Style";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UpgradeStep2 = class extends React.Component {
    renderDiscount(coupon){
        if(!coupon.hasOwnProperty("percent_off") || coupon.percent_off === null){
            return null;
        }
        let discount = "";
        switch(coupon.duration){
            case "repeating":
                discount = coupon.percent_off+"% Discount for "+coupon.duration_in_months+" Months";
                break;
            case "once":
                discount = coupon.percent_off+"% Discount this month";
                break;
            default:
                discount = coupon.percent_off+"% Discount Forever";
                break;
        }
        return discount;
    }
    render(){
        const { classes, upgradeDetails, userPackage, coupon, discountCode } = this.props;
        if(upgradeDetails === null){
            return <></>
        }

        return(
            <div>
                <div className={classes.centerStep2}>
                    <p>You have chosen to upgrade your account to:</p>
                    <span className={classes.bold}>{userPackage.name}</span>
                </div>
                {
                    upgradeDetails.paymentLast4Digits.length > 0 ?
                        <div className={"fs-block "+classes.centerStep2+" "+classes.marginTopStep2}>
                            <p>Your Payment Method</p>
                            <span className={classes.bold}>xxxx-xxxx-xxxx-{upgradeDetails.paymentLast4Digits}</span>
                        </div>
                    :
                    <></>
                }
                {
                    coupon !== null ?
                        <>
                            <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                                <p>The following discount code will be applied</p>
                                <span className={classes.bold}>{discountCode}</span>
                            </div>
                            <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                                <p>Your discount is:</p>
                                <p className={classes.bold}>{this.renderDiscount(coupon)}</p>
                            </div>
                        </>
                    :
                    <></>
                }
                <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                    {
                        coupon !== null ?
                            upgradeDetails.billingType.toLowerCase() === "monthly" ?
                                <>
                                    <p>You have chosen monthly billing. After your discount code expires your recurring monthly charge will be:</p>
                                    <span className={classes.bold}>{userPackage.pricing.monthlyDescription}</span>
                                </>
                            :
                                <>
                                    <p>You have chosen annual billing. After your discount code expires your recurring annually charge will be:</p>
                                    <span className={classes.bold}>{userPackage.pricing.annualDescription}</span>
                                </>
                        :
                            upgradeDetails.billingType.toLowerCase() === "monthly" ?
                                <>
                                    <p>You have chosen monthly billing.  Your recurring monthly charge will be:</p>
                                    <span className={classes.bold}>{userPackage.pricing.monthlyDescription}</span>
                                </>
                            :
                                <>
                                    <p>You have chosen annual billing.  Your recurring annually charge will be:</p>
                                    <span className={classes.bold}>{userPackage.pricing.annualDescription}</span>
                                </>
                    }
                </div>
                {
                    coupon !== null && coupon.percent_off === 100 ?
                        <p className={classes.bold+" "+classes.centerStep2+" "+classes.marginTopStep2}>Your Credit Card will not be charged until a balance is due.</p>
                    :
                    <></>
                }
                <div className={classes.centerStep2+" "+classes.marginTopStep2}>
                    <p>The first payment will be automatically adjusted for any previous payments under a different account level.</p>
                </div>
                <div className={classes.overflowHidden+" "+classes.marginTopStep2}>
                    <Button onClick={() => this.props.onBack()}  color="white">
                        <ArrowBackIcon className={classes.arrow} /> Back
                    </Button>
                    <Button color="success" onClick={() => this.props.onSuccess()} className={classes.nextButton}>
                        <SaveIcon className={classes.icons} /> Confirm
                    </Button>
                </div>
            </div>
        )
    }
}

UpgradeStep2.defaultProps = {
    upgradeDetails: null
};

UpgradeStep2.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    upgradeDetails: PropTypes.object,
    userPackage: PropTypes.object
};
export default withStyles(upgradeStep1Style)(UpgradeStep2);