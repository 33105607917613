import {
    whiteColor,
    blackColor,
    hexToRgb
} from "../material-dashboard-pro-react.jsx";

const forcePasswordResetStyle = {
    wizardContainer: {
        
    },
    card: {
        display: "inline-block",
        position: "relative",
        width: "100%",
        margin: "25px 0",
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        transition: "all 300ms linear",
        minHeight: "410px",
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    wizardHeader: {
        textAlign: "center",
        padding: "25px 0 35px"
    },
    title: {
        margin: "0",
        marginBottom: "15px"
    },
    subtitle: {
        margin: "5px 0 0"
    },
    emailTitle: {
        marginBottom: "5px"
    },
    resetContainer: {
        textAlign: "center"
    },
    form: {

    },
    passwordErrors: {
        textAlign: "left"
    },
    confirmButton: {
        "& .sd_loader": {
            width: "18px",
            height: "18px",
            "& .MuiCircularProgress-root": {
                width: "18px !important",
                height: "18px !important",
            },
            "& svg": {
                marginRight: "0px"
            }
        }
    }
};

export default forcePasswordResetStyle;
