import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import deleteAccountModal from "../../../assets/jss/dashboard/deleteAccountModal";
import LoaderComponent from '../Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DeleteAccountModal = class extends React.Component {
  render() {
    const { classes, deletingUser } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.props.onClose()}
        aria-labelledby="deleteaccount-slide-title"
        aria-describedby="deleteaccount-slide-description"
      >
        <DialogTitle
          id="deleteaccount-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Delete Account</h4>
        </DialogTitle>
        <DialogContent
          id="deleteaccount-slide-description"
          className={classes.modalBody}
        >
          <p>Are you sure you want to close your account?  Your search engine will be removed and your content will be removed.</p>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button onClick={() => this.props.onSuccess()} color="danger" round>Delete Me</Button>
          <Button onClick={() => this.props.onClose()} color="info" round>Nevermind</Button>
        </DialogActions>
        {
            deletingUser ?
                <LoaderComponent align="center" />
            :
                <></>
        }
        
      </Dialog>
    );
  }
};

DeleteAccountModal.defaultProps = {
  open: false,
  deletingUser: false
};
DeleteAccountModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  deletingUser: PropTypes.bool
};
export default withStyles(deleteAccountModal)(DeleteAccountModal);
