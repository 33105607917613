import {
    container,
    main,
    mainRaised,
    whiteColor,
    blackColor,
    hexToRgb,
    grayColor,
    primaryColor
} from "../material-kit-pro-react.jsx";

const dashboardHomePageStyle = theme => ({
    main: {
        ...main,
        padding: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            marginLeft: "110px",
            marginRight: "15px",            
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "auto",
            background: "transparent",
            padding: "0px"
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            padding: "0px 15px 8px 15px",
            "& > .MuiGrid-container": {
                marginLeft: "0px",
                marginRight: "0px",
            }
        },
    },
    mainRaised: {
        ...mainRaised,
        [theme.breakpoints.down('sm')]: {
            marginTop: "-140px"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "-200px"
        }
    },
    title: {
        margin: theme.spacing(1, 1, 1, 0),
        fontWeight:"bold",
    },
    container: {
        ...container,
        "& ul.tab_home": {
            listStyleType: "none",
            padding: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            "& li": {
                paddingLeft: "0px",
                height: "62px",
                lineHeight: "62px",
                [theme.breakpoints.down("sm")]: {
                    lineHeight: "initial",
                    height: "auto",
                }
            },
            [theme.breakpoints.down("sm")]: {
                marginTop: "0px",
                "& li + li": {
                    marginTop: theme.spacing(2)
                }
            }
        },
        [theme.breakpoints.up("sm")]: {
            padding: "0px"
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0px"
        },
        "& .navPillsContentWrapper": {
            "& > div": {
                overflowY: "hidden"
            },
            "& .navPillsTabContent": {
                [theme.breakpoints.up("md")]: {
                    minHeight: "441px",
                },
            },            
            "& .react-swipeable-view-container > div > div": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        },
        "& .react-swipeable-view-container": {
            "& > div": {
                overflowY: "hidden !important"
            }
        }
    },
    navPillsTabsGrid: {
        position: "fixed",
        left: "0px",
        top: "145px",
        width: "96px",
        padding: theme.spacing(2,0,2,0),
        background: whiteColor,
        boxShadow: "0 4px 20px 0px rgba(" +hexToRgb(blackColor) +", 0.14)",  
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            position: "static",
            boxShadow: "none",
            padding: theme.spacing(1),
        },
        "& .MuiTabs-root": {
            marginTop: "0px",
            [theme.breakpoints.down("sm")]: {
                minHeight: "0px"
            },
            "& .MuiTabs-flexContainer": {
                [theme.breakpoints.down("sm")]: {
                    display: "flex"
                },             
            }
        },
        "& .MuiTab-root": {
            minWidth: "0px",
            width: "80px",
            margin: "0px auto",
            [theme.breakpoints.down("sm")]: {
                float: "left !important",
                width: "50px",
                height: "51px"
            },
            [theme.breakpoints.down("xs")]: {
                float: "left !important",
                width: "40px",
                height: "41px"
            },
            "& svg": {
                margin: "5px 0px !important",
                width: "30px",
                height: "30px",
                [theme.breakpoints.down("sm")]: {
                    width: "24px",
                    height: "24px",
                },
                [theme.breakpoints.down("xs")]: {
                    width: "20px",
                    height: "20px",
                    margin: "0px !important",
                }
            },
            "&:first-child": {
                display: "none !important"
            }
        },
        "&.showLabels": {
            "& .MuiTab-root": {
                [theme.breakpoints.up("md")]: {
                    height: "60px",
                    fontSize: "8px",
                    lineHeight: "8px",
                    paddingTop: "5px",
                    "&+.MuiTab-root": {
                        marginTop: "2px"
                    },
                    "& svg": {
                        marginTop: "0px !important"
                    }
                },
                height: "65px",
                fontSize: "8px",
                [theme.breakpoints.down("sm")]: {
                    height: "65px",
                    fontSize: "8px"
                },
                [theme.breakpoints.down("xs")]: {
                    height: "52px",
                    fontSize: "7px"
                },
            }
        }
    },
    navPillsContentGrid:{        
        [theme.breakpoints.down("sm")]: {
            background: whiteColor,
            marginTop: "15px",
            padding: "15px"
        },
        "& > div":{
            marginTop: "0px"
        }
    },
    materialIcon: {
        margin: "5px 0px !important"
    },
    tabImageIcon: {
        width: "30px",
        height: "30px",
        objectFit: "cover",
        borderRadius: "100%",
        margin: "5px 0px !important",
        [theme.breakpoints.down("sm")]: {
            width: "24px",
            height: "24px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "20px",
            height: "20px",
            margin: "0px !important",
        }
    },
    homeTab: {
        
    },
    wizard: {
        [theme.breakpoints.down("xs")]: {
            padding: "0px",
            background: "transparent",
            marginLeft: "0px",
            marginRight: "0px",
            maxWidth: "none",
            "& .MuiGrid-root": {
                background: "transparent",
                padding: "0px",
                margin: "0px"
            },
            "& .navPillsGridItem": {
                display: "none"
            },
            "& .platformWizardTitle": {
                display: "none"
            },
            "& .platformWizardDropdown": {
                display: "none"
            },
            "& .wizardContainer": {
                marginLeft: "10px",
                marginRight: "10px",
                "& > div": {
                    marginTop: "0px",
                    marginBottom: "0px",
                    paddingBottom: "10px"
                },
                "& .wizardHeader": {
                    paddingBottom: "0px",
                    paddingTop: theme.spacing(2)
                },
                "& .wizardContent": {
                    paddingTop: "0px",
                    marginTop: "10px",
                    minHeight: "265px"
                }
            }
        }
    },
    inputAdornment: {
        cursor: "pointer",
        height: "32px",
        width: "32px"
    },
    searchLink: {
        width: "100%",
        padding: "0px",
        "& input": {
            visibility: "hidden !important"
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: "110px",
            marginRight: "15px",
            width: "calc( 100% - 125px)"
        },
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            left: "0px",
            right: "0px",
            bottom: "41px",
            marginLeft: "0px",
            marginRight: "0px",
            background: whiteColor,
            zIndex: 99,
            width: "100%",
            paddingLeft: "15px",
            paddingRight: "15px",
            "& .MuiFormControl-root": {
                marginBottom: "10px"
            }
        }
    },
    hideBetaFeatures: {
        "& .navPillsContentWrapper": {
            "& .navPillsTabContent": {
                [theme.breakpoints.up("md")]: {
                    minHeight: "313px",
                },
            }
        },
    },
    progressSetup: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            backgroundColor: whiteColor,
            marginLeft: "-15px",
            marginRight: "-15px",
            paddingLeft: "15px",
            paddingRight: "15px"
        },
        [theme.breakpoints.down("xs")]: {
            margin: "8px 15px 8px 15px",
        }
    },
    suspended: {
        background: whiteColor,
        textAlign: "center",
        color: grayColor[1],
        padding: theme.spacing(2),
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2)",
        "& div": {
            marginBottom: theme.spacing(1),
            display: "block"
        }
    },
    notice: {
        width: "100%",
        padding: "0px",
        zIndex: "1105",
        marginTop: "-15px"
    },
    overlayUsernameLink: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        position: "absolute",
        bottom: "25px",
        left: "0px",
        whiteSpace: "nowrap",
        color: primaryColor[0]+" !important",
        textDecoration: "underline",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        width: "calc(100% - 65px)",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            bottom: "17px",
            left: "1em",
        }
    },
    profilePreview: {
        padding: "0px !important",
        "& $navPillsContentGrid": {
            background: "transparent",
            padding: "0px",
            display: "none"
        },
        "& .navPillsTabContent": {
            display: "none"
        }
    },
    styleBox: {
        position: "fixed",
        bottom: "-200px",
        zIndex: "98",
        width: "500px",
        transition: "all 0.3s ease",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        [theme.breakpoints.up("md")]: {
            left: "174px",
            bottom: "-310px"
        }
    },
    openStyleBox: {
        bottom: "108px",
        [theme.breakpoints.up("md")]: {
            bottom: "0px",
        }
    },
    styleBoxButtons: {
        backgroundColor: primaryColor[0],
        display: "inline-block",
        borderRadius: "4px 4px 0px 0px",
        "& button": {
            margin: "0px",
            border: "0px",
            transition: "all 0.3s ease",
            borderRadius: "4px 4px 0px 0px",
            display: "inline-flex",
            "& svg": {
                height: "17px",
                width: "17px"
            }
        }
    },
    openStyleBoxButtons: {
        "& button": {
            boxShadow: "none !important",
            [theme.breakpoints.up("md")]: {
                marginBottom: "0px"
            }
        }
    },
    styleBoxIconButtons: {
        height: "32px",
        width: "32px",
        minWidth: "32px",
        borderRadius: "4px !important",
        margin: "3px 3px !important",
        padding: "8px 6px"
    },
    previewTabContent: {
        background: "#E2E2E1",
        overflow: "hidden",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            marginLeft: "-15px",
            marginRight: "-15px",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "15px",
        }
    }
});

export default dashboardHomePageStyle;