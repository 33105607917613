import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../../../../components/Loader";

import instagramNoticeStyle from "../../../../../assets/jss/dashboard/platforms/InstagramLink/instagramNoticeStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const Notice = class extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            secondsWait: true
        }
        this.store = this.props.store;        
    }
    componentDidMount(){
        const that = this;
        setTimeout(function(){
            that.setState({ 
                secondsWait: false
            });
        },3000);
    }
    render() {
        const { classes, saving } = this.props;
        const { secondsWait } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.props.onClose()}
                aria-labelledby="youtubeLink-modal-slide-title"
                aria-describedby="youtubeLink-modal-slide-description"
            >
                <DialogTitle
                    id="youtubeLink-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        {
                            secondsWait || saving ?
                                <h4 className={classes.modalTitle+" "+classes.textCenter}>Please wait while we import your YouTube data.</h4> 
                            :
                            <></>
                        }
                </DialogTitle>
                <DialogContent
                    id="youtubeLink-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            secondsWait || saving ?
                                <LoaderComponent align="center" color="success" />
                            :
                            <>
                            <p>We need a few minutes to import your YouTube content. You can find your imported content under the YouTube icon on the Content Platforms page.</p>
                            </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false && secondsWait === false ?
                            <Button
                                onClick={() => this.props.onClose()}
                                color="info"
                                round
                            >
                                OK
                            </Button>
                        :
                        <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

Notice.defaultProps = {
    open: false
}
Notice.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(instagramNoticeStyle)(Notice);