import React from 'react'
import {Helmet} from 'react-helmet'
import withStyles from "@material-ui/core/styles/withStyles"
import GridItem from "../../components/Grid/GridItem.jsx";
import Accordion from "../../components/Accordion/Accordion.jsx";

import faqsStyle from "../../../assets/jss/help/faqsStyle";

const Faqs = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container+" "+classes.main}>
                <h5 className={classes.title}>Frequently Asked Questions</h5>
                <GridItem xs={12} sm={12} md={12} lg={10} className={classes.customAccordionIcon}>
                    <Accordion
                        activeColor="rose"
                        collapses={[
                            {
                            title: "How do I share my Search My Social profile with my followers?",
                            content:
                                "It’s super easy to direct your followers to your Search My Social page! Just copy the link from your Search Me or About Me page, and paste it into your bio link! There is also a copy button on every page of your dashboard."
                            },
                            {
                            title: "How does my search engine find my content?",
                            content:
                                "The best way to add content to your search engine is to link your Instagram account.  We will automatically import your posts after which you can update them with any relevant keywords, location, or click-through links. You can also add any website manually through our web-links option."
                            }
                        ]}
                    />
                </GridItem>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

export default withStyles(faqsStyle)(Faqs);
